/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { Row, Col, Container, Card } from 'react-bootstrap'
import { Trans, Translation } from 'react-i18next'
import { BlueButton } from './layout/BlueButton'
import { Separator } from './layout/Separator'
import { globalSettingsList } from '../actions/settings'
import { default_theme as theme } from '../emotion/theme'
import xoip_logo from '../images/xoip-logo.svg'
import { customerList } from '../actions/customer'
import GlobalStyles from './GlobalStyles'
import logo from '../images/xoip-logo.svg'

class LegacyRedirectLanding extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(customerList.ensureCustomerLoaded())
        dispatch(globalSettingsList.ensureGlobalSettingsLoaded())
    }
    
    render() {
        const {history, customer, helpdesk_email, helpdesk_phone} = this.props
        const home_page = window.location.protocol + "//" + window.location.hostname
        return (
            <div css={page_style}>
              <GlobalStyles />
              <div css={container}>
                <div css={header}>
                  <img src={ xoip_logo } alt="" css={ image } />
                  <div css={h1}>
                    <Trans>Welcome to the new XOIP!</Trans>
                  </div>
                  <div css={h2}>
                    <Trans>New interface, new logo</Trans>
                  </div>
                </div>

                <Separator variant="h40" />
                
                <div css={content}>

                  <div css={p_style}>
                    <Trans>Hi</Trans> {customer.display_name},
                  </div>

                  <div css={p_style}>
                    <Trans i18nKey="legacy_redirect__xoip_is_building">
                      XOIP is building a new and improved platform. Faster, safer and ready for the future. You are about to log in on that new platfrom.
                    </Trans>
                  </div>

                  <div css={p_style}>
                    <Trans i18nKey="legacy_redirect__what_changes">
                      What changes? Your services remain the same as they were, but the user interface is completely renewed.
                    </Trans>
                  </div>

                  <div css={p_style}>
                    <Trans i18nKey="legacy_redirect__from_now_on">
                      From now on you must access your account on the new XOIP here:
                    </Trans>
                  </div>

                  <div css={new_login_style}>
                    <a href={home_page}>{home_page}</a>
                  </div>

                  <div css={p_style}>
                    <Trans i18nKey="legacy_redirect__the_first_time">
                      The first time you log in on the new platform, you will be asked to set a new password.
                    </Trans>
                  </div>

                  <div css={p_style}>
                    <Trans i18nKey="legacy_redirect__please_note">
                      PLEASE NOTE: you cannot use this new password to log in on www.xoip.com anymore. If you go back there and try to log in with your new password, you will see an error message. Your old password will still work there, but it will bring you back to this landing page each time.
                    </Trans>
                  </div>

                  <div css={p_style}>
                    <Trans i18nKey="legacy_redirect__we_apologize">
                      We apologize for any inconvenience you may experience. We hope you will appreciate the new user interface.
                    </Trans>
                  </div>

                  <div css={p_style}>
                    <Trans i18nKey="legacy_redirect__we_hope_you"
                           values={{helpdesk_email: helpdesk_email,
                                    helpdesk_phone: helpdesk_phone}}
                           components={[<a css={helpdesk_contact_style} href={`mailto:${helpdesk_email}`} >{helpdesk_email}</a>,
                                        <a css={helpdesk_contact_style} href={`tel:${helpdesk_phone}`} >{helpdesk_phone}</a>
                                       ]}
                           defaults="For questions or problems you can contact <0>{{helpdesk_email}}</0> or call the XOIP helpdesk at <1>{{helpdesk_phone}}</1>."
                    />
                  </div>

                  <div css={p_style}>
                    <Trans i18nKey="legacy_redirect__the_xoip_team">
                      The XOIP team
                    </Trans>
                  </div>
                  
                  <div css={button_style}>
                    <BlueButton onClick={() => history.push("/")}>
                      <Trans>Continue</Trans>
                    </BlueButton>
                  </div>
                  
                </div>
                <div css={footer_style}>
                  <div>
                    <img css={logo_style} src={ logo } alt="logo" />
                  </div>
                  <div css={footer_contact_style}>
                    <div>
                      <Trans>Email</Trans>: <span css={footer_helpdesk_contact_style}>{helpdesk_email}</span>
                    </div>
                    <div>
                      <Trans i18nKey="call_short">Call</Trans>: <span css={footer_helpdesk_contact_style}>{helpdesk_phone}</span>
                    </div>
                  </div>
                </div>
                
                
              </div>
            </div>
        )
    }
    
}

function mapStateToProps(state, props) {
    return {
        customer: customerList.getCustomer(),
        helpdesk_email: globalSettingsList.getSetting("helpdesk_email"),
        helpdesk_phone: globalSettingsList.getSetting("helpdesk_phone")
    }
}

export default withRouter(connect(mapStateToProps)(LegacyRedirectLanding))

const image = css`
height: 46px;
`

const page_style = css`
background-color: ${theme.colors.nearly_white};
line-height: 1.5;
`

const container = css`
padding-top: 62px;
padding-bottom: 177px;
max-width: 800px;
margin: auto;
font-size: 18px;
`

const h1 = css`
font-size: 33px;
font-weight: 500;
margin-top: 20px;
margin-bottom: 20px;
`

const h2 = css`
font-size: 22px;
`

const header = css`
text-align: center;
`

const content = css`
padding: 70px 90px 50px 90px;
background-color: ${theme.colors.card_background};
border: 1px solid ${theme.colors.card_border};
border-radius: 4px;

`

const p_style = css`
margin-bottom: 20px;
`

const new_login_style = css`
color: #4178bd;
font-size: 22px;
font-weight: 500;
text-align: center;
margin-bottom: 20px;
`

const button_style = css`
text-align: center;
margin-top: 10px;
`

const helpdesk_contact_style = css`
font-weight: 600;
`

const footer_style = css`
margin-top: 30px;
color: ${theme.colors.dark_grey};
display: flex;
align-items: flex-start;
`

const logo_style = css`
height: 34px;
`

const footer_contact_style = css`
display: flex;
flex-direction: column;
font-size: 16px;
line-height: 18px;
margin-left: 13px;
`

const footer_helpdesk_contact_style = css`
font-weight: 600;
`
