/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { filter, isObjectLike, replace, map, keys, has, get, split } from 'lodash'
import AdminMainLayout from './AdminMainLayout'
import Table from 'react-bootstrap/Table'
import { Col, Row, Container, Button } from 'react-bootstrap'
import { globalSettingsList } from '../../actions/settings'
import { Separator } from '../../components/layout/Separator'
import { AdminEditableSetting } from './AdminEditableSetting'

class AdminGlobalSettings extends Component {
 
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(globalSettingsList.ensureGlobalSettingsLoaded())
    }

    renderEditableSettings = () => {
        const { settings } = this.props
        return (
            <div>
              <h2>Editable settings</h2>
              {map(get(settings, "editable_settings"), (editable_setting) =>
                  <AdminEditableSetting editable_setting_id={editable_setting.id} />
              )}
            </div>
        )
    }
    
    render() {
        const {settings} = this.props

        const read_only_setting_keys = filter(keys(settings), (setting_name) => setting_name !== 'editable_settings')
        
        return (
            <AdminMainLayout active_key="settings"
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'settings', label: 'Global Settings', url: '/admin/global_settings'}]}>
              <Container fluid>

                { this.renderEditableSettings() }

                <h2>Non-editable settings</h2>
                <Table borderless responsive>
                  { map(read_only_setting_keys, function(k) {
                      let v = settings[k]
                      if ( k === 'id' ) {
                          return null
                      }
                      if ( v === true ) {
                          v = "yes"
                      } else if ( v === false ) {
                          v = "no"
                      } else if ( isObjectLike(v) ) {
                          v = JSON.stringify(v)
                      }
                      const lines = split(v, "\n")
                      return (
                          <tr>
                            <td>
                              {k}
                            </td>
                            <td>
                              { map(lines, (line) => <div>{line}</div>) }
                            </td>
                          </tr>
                      )
                  }) }
                </Table>
                
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        settings: globalSettingsList.getGlobalSettings()
    }
}
export default withRouter(connect(mapStateToProps)(AdminGlobalSettings))

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`
