/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { default_theme as theme } from '../../emotion/theme'
import { head, get, map, keys, split, size } from 'lodash'
import { CardHeader } from '../../components/layout/CardHeader'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { Separator } from '../../components/layout/Separator'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { Formik, Form, FieldArray, Field } from 'formik'
import ReactAudioPlayer from 'react-audio-player'
import Loading from '../../components/Loading'
import { FormikTextarea } from '../../components/form/TextareaField'
import { FormikFileUploadField } from '../../components/form/FileUploader'
import { FormikInputField } from '../../components/form/InputField'
import AdminMainLayout from './AdminMainLayout'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import Card from '../../components/layout/Card'
import { Col, Row, Container, Navbar, Nav } from 'react-bootstrap'
import { Form as BootstrapForm } from 'react-bootstrap'
import * as Yup from 'yup'
import {showSuccess, showError} from '../../actions/Error'
import Button from 'react-bootstrap/Button'
import CardInfoText from '../../components/layout/CardInfoText'
import { BlueButton } from '../../components/layout/BlueButton'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { handleSubmitResult } from '../../actions/form'
import { adminAnsweringMessageList } from '../actions/admin_answering_message'
import { adminCustomerVoicePhoneNumberList } from '../actions/admin_customer_phone_number'
import { adminCustomerList } from '../actions/admin_customer'
import { SUPPORTED_AUDIO_FILE_FORMATS } from '../../components/form/AudioUploadField'

const validationSchema = Yup.object().shape({
})

export const AdminAnsweringMessageForCustomerPhoneNumber = ({match}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const customer_phone_number_id = get(match, ["params", "customer_phone_number_id"], null)
    const customer_phone_number = useSelector(() => adminCustomerVoicePhoneNumberList.getObject(customer_phone_number_id))
    const customer_id = get(customer_phone_number, "customer")
    const customer = useSelector(() => adminCustomerList.getObject(customer_id))
    const answering_message_id = get(match, ["params", "answering_message_id"], null)
    const answering_message = useSelector(() => answering_message_id && adminAnsweringMessageList.getObject(answering_message_id))
    const is_busy = useSelector(() => adminAnsweringMessageList.getIsSavingObject())
    const is_loading = useSelector(() => adminAnsweringMessageList.isLoading())
    const initial_values = Object.assign({}, answering_message)

    useEffect(() => {
        dispatch(adminAnsweringMessageList.ensureObjectLoaded(answering_message_id))
    }, [answering_message_id])

    useEffect(() => {
        dispatch(adminCustomerVoicePhoneNumberList.ensureObjectLoaded(customer_phone_number_id))
    }, [customer_phone_number_id])

    useEffect(() => {
        dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
    }, [customer_id])
    
    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(adminAnsweringMessageList.invalidateList())
            showSuccess("Saved", "Answering message saved")
            history.push(`/admin/customer_phone_number/${customer_phone_number_id}/answering_messages`)
        }
        values.customer_phone_number = customer_phone_number_id
        if ( answering_message_id ) {
            values.id = answering_message_id
            return dispatch(adminAnsweringMessageList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminAnsweringMessageList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const renderFilePlaceholder = () => {
        return (
            <div>
              Upload welcome message file
            </div>
        )
    }

    const renderForm = (formik_props) => {

        return (
            <Card variant="white_wide_padding">
              <Row>
                <Col>
                  <BootstrapForm.Group>
                    <FormikCheckboxField label="Is active" name="is_active" />
                  </BootstrapForm.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <BootstrapForm.Group>

                    <div css={file_style}>
                      { get(formik_props, ["values", "answering_message_file_info", "visible_filename"]) &&
                        <>
                          <div>{formik_props.values.answering_message_file_info.visible_filename}</div>
                          <Separator variant="w10" />

                          <ReactAudioPlayer src={get(formik_props.values, ["answering_message_file_info", "original_download_url"])} controls />
                        </>
                      }
                      <Separator variant="w10" />
                      <FormikFileUploadField name="answering_message_file_info"
                                             css_classes={ {container: file_uploader_container_style,
                                                            dropzone_container: dropzone_container_style} }
                                             formik_props={formik_props}
                                             file_types={SUPPORTED_AUDIO_FILE_FORMATS}
                                             upload_relative_url="file_info/"
                                             renderPlaceHolder={renderFilePlaceholder}
                      />
                    </div>
                  </BootstrapForm.Group>
                </Col>
              </Row>

              { get(answering_message, "legacy_ref") &&
                <Row>
                  <Col>
                    Legacy filename: {answering_message.legacy_ref}
                  </Col>
                </Row>
              }
              
              <Row>
                <Col>
                  <BlueButton type="submit">Save</BlueButton>
                </Col>
              </Row>
            </Card>
        )
    }

    return (
        <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                       {name: 'customer', label: get(customer, ["user", "display_name"]), url: `/admin/customer/${customer_id}`},
                                       {name: 'customer_phone_number', label: get(customer_phone_number, "number"), url: `/admin/customer/${get(customer_phone_number, "customer")}/voice_settings`},
                                       {name: 'customer_phone_number_answering_messages', label: "Welcome messages", url: `/admin/customer_phone_number/${customer_phone_number_id}/answering_messages`},
                                       {name: 'customer_phone_number_)answering_message',
                                        label: answering_message_id ? get(answering_message, "id"): "New",
                                        url: `/admin/customer_phone_number/${customer_phone_number_id}/answering_message/${get(answering_message, "id")}`}]}>
          <Container fluid>
            { is_busy && <BusyMask /> }
            { is_loading && <Loading /> }

            { answering_message_id &&
              <CardHeader title={`Edit answering message for ${get(customer_phone_number, "number")}`} />
            }
            { ! answering_message_id &&
              <CardHeader title={`New answering message for ${get(customer_phone_number, "number")}`} />
            }
            
            <Separator variant="h30" />
            
            { ! is_loading && 
              <Formik
                initialValues={initial_values}
                onSubmit={onSave}
                enableReinitialize={true}
                validationSchema={validationSchema}
              >
                {formik_props => {
                    const { values } = formik_props
                    return (
                        <Form>
                          <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                          <Row>
                            <Col>
                              <Card>
                                { renderForm(formik_props) }
                              </Card>
                            </Col>
                          </Row>
                        </Form>
                    )}
                }
              </Formik>
            }
          </Container>
        </AdminMainLayout>
    )
}

const dropzone_container_style = css`
width: 100%;
`
    
const file_uploader_container_style = css`
width: 100%
height: 38px;
border: solid 1px ${theme.colors.light_middle_grey};
background-color: ${theme.colors.white};
border-radius: 4px;
padding: 1px 14.5px;
display: flex;
align-items: center;
text-align: center;
`

const file_style = css`
display: flex;
`
