/** @jsxImportSource @emotion/react */
import React from 'react'
import { Row } from 'react-bootstrap'
import { jsx, css } from '@emotion/react'

export const RowWithGutter = ({children, ...props}) => {

    return (
        <Row css={style} {...props}>
          {children}
        </Row>
    )
    
}

const style = css`

  .col {
    margin-top: 0px;
  }
  .col>* {
    margin-top: 20px;
  }

`
