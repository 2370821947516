/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { default_theme as theme } from '../../emotion/theme'
import moment from 'moment'
import { head, get, map, keys, split, size } from 'lodash'
import { ButtonBar } from '../../components/layout/ButtonBar'
import { CardHeader } from '../../components/layout/CardHeader'
import CardInfoText from '../../components/layout/CardInfoText'
import { FormikFileSetField } from '../../components/form/FileSetField'
import { LanguageFlag } from '../../components/LanguageFlag'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { Separator } from '../../components/layout/Separator'
import CurrencyValue from '../../components/CurrencyValue'
import { Formik, Form, FieldArray, Field } from 'formik'
import Loading from '../../components/Loading'
import { FormikTextarea } from '../../components/form/TextareaField'
import { FormikInputField } from '../../components/form/InputField'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import AdminMainLayout from './AdminMainLayout'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import Card from '../../components/layout/Card'
import { Col, Row, Container, Navbar, Nav } from 'react-bootstrap'
import { Form as BootstrapForm } from 'react-bootstrap'
import * as Yup from 'yup'
import {showSuccess, showError} from '../../actions/Error'
import { BlueButton } from '../../components/layout/BlueButton'
import { handleSubmitResult } from '../../actions/form'
import { adminIncomingFaxByEmailQueueList } from '../actions/admin_incoming_fax_by_email_queue'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'

const validationSchema = Yup.object().shape({
    'from_email': Yup.string().required("Required"),
    'to_addresses': Yup.string().required("Required"),
    'subject': Yup.string().required("Required")
})

class AdminIncomingEmailTest extends Component {

    constructor(props) {
        super(props)
        this.state = {sending: false, res: null}
    }

    onSendEmail = (values, formik_funcs) => {
        const { history, dispatch, customer_id } = this.props
        const that = this
        this.setState({sending: true})
        const on_ok = function(json) {
            that.setState({sending: false, res: JSON.stringify(json)})
            dispatch(adminIncomingFaxByEmailQueueList.invalidateList())
            showSuccess("Saved", "Email created")
            history.push('/admin/incoming_fax_emails')
        }
        dispatch(adminIncomingFaxByEmailQueueList.sendTestEmail(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }

    render() {
        const { dispatch, initial_values, is_saving } = this.props
        const that = this
        
        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'incoming_email_test', label: 'Incoming Email Test', url: '/admin/incoming_email_test'}]}>
              <Container fluid>
                <Row>
                  <Col md="8">
                    <CardHeader title={`Simulate an incoming email`} />
                    <CardInfoText>
                      This page can be used to test the handling for emails to XOIP which represents faxes to be sent.
                      It completely bypasses the email servers, but in every other respect acts as if an email was received at the xoip inbox.
                    </CardInfoText>
                    <Separator variant="h20" />

                    { is_saving && <BusyMask /> }
                    
                    <WrappingBusyMask is_loading={false}>

                      <Formik
                        initialValues={initial_values}
                        onSubmit={that.onSendEmail}
                        enableReinitialize={true}
                        validationSchema={validationSchema}
                    >
                        {formik_props => {
                            const { values } = formik_props
                            return (
                                <Form>
                                  <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                                  <Row>
                                    <Col md="4">
                                      <BootstrapForm.Group>
                                        <BootstrapForm.Label>From email address</BootstrapForm.Label>
                                        <br/>
                                        <FormikInputField name="from_email" placeholder="From address" />
                                      </BootstrapForm.Group>

                                      <BootstrapForm.Group>
                                        <BootstrapForm.Label>Destination email addresses (comma separated)</BootstrapForm.Label>
                                        <br/>
                                        <FormikInputField name="to_addresses" placeholder="To address" />
                                      </BootstrapForm.Group>

                                      <BootstrapForm.Group>
                                        <BootstrapForm.Label>Subject</BootstrapForm.Label>
                                        <br/>
                                        <FormikInputField name="subject" placeholder="Email subject" />
                                      </BootstrapForm.Group>

                                      <BootstrapForm.Group>
                                        <BootstrapForm.Label>Attachments</BootstrapForm.Label>
                                        <FormikFileSetField name="attachments"
                                                            file_types = ".pdf"
                                                            formik_props={formik_props}
                                                            dispatch={dispatch} />
                                      </BootstrapForm.Group>

                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <ButtonBar>
                                        <BlueButton type="submit">
                                          Send
                                        </BlueButton>
                                      </ButtonBar>
                                    </Col>
                                  </Row>
                                </Form>
                            )
                        }}
                      </Formik>
                    </WrappingBusyMask>
                  </Col>
                </Row>
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {

    return {
        is_saving: adminIncomingFaxByEmailQueueList.getIsSavingObject(),
        initial_values: {
            to_addresses: 'xoipstaging+0031555666@gmail.com'
        }
    }
}

export default withRouter(connect(mapStateToProps)(AdminIncomingEmailTest))

const breadcrumb_item = css`
display: flex;
align-items: center;
`
