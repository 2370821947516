/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { get, map, size } from 'lodash'
import { ButtonBar } from '../../components/layout/ButtonBar'
import Timestamp from '../../components/Timestamp'
import { adminCustomerList } from '../actions/admin_customer'
import { adminCustomerFaxFromEmailAddressListForApproval } from '../actions/admin_customer_fax_from_email_address'
import Loading from '../../components/Loading'
import AdminMainLayout from './AdminMainLayout'
import {showSuccess, showError} from '../../actions/Error'
import { Col, Row, Container, Tabs, Tab, Button } from 'react-bootstrap'
import Card from '../../components/layout/Card'
import { CardHeader } from '../../components/layout/CardHeader'
import { Form as BootstrapForm } from 'react-bootstrap'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikTextarea } from '../../components/form/TextareaField'
import { BlueLinkButton } from '../../components/layout/BlueLinkButton'
import { BlueButton } from '../../components/layout/BlueButton'
import { Separator } from '../../components/layout/Separator'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { handleSubmitResult } from '../../actions/form'

class AdminFaxFromEmailAddressApprovalForm extends Component {

    constructor(props) {
        super(props)
        this.state = {in_decline_mode: false}
    }
    
    prepareToDeclineEmailAddress = () => {
        this.setState({in_decline_mode: true})
    }

    onCancelDeclineEmailAddress = () => {
        this.setState({in_decline_mode: false})
    }

    onUpdateEmailAddressApproval = (values, formik_funcs) => {
        const { dispatch, email_address } = this.props
        const that = this
        values = Object.assign({}, email_address, values)
        const on_ok = function(json) {
            dispatch(adminCustomerFaxFromEmailAddressListForApproval.invalidateObject(values.id))
            dispatch(adminCustomerFaxFromEmailAddressListForApproval.invalidateList())
            if ( values.is_approved ) {
                showSuccess("Approved", "Approved")
            } else {
                showError("Rejected", "Rejected")
            }
            that.setState({in_decline_mode: false})
        }
        
        dispatch(adminCustomerFaxFromEmailAddressListForApproval.patchObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }

    renderUnapprovedEmailAddressDeclineForm = () => {
        const { email_address } = this.props
        const that = this
        return (
            <Formik
                initialValues={{'is_approved':false}}
                onSubmit={this.onUpdateEmailAddressApproval}
                enableReinitialize={true}
            >
              {formik_props => {
                   const { values } = formik_props
                   return (
                       <Form>
                         <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                         <Row>
                           <Col>
                             <BootstrapForm.Group>
                               <BootstrapForm.Label>Rejection reason (optional, visible to the customer)</BootstrapForm.Label>
                               <FormikTextarea name="rejection_reason" rows={3} placeholder="Optional rejection reason" />
                             </BootstrapForm.Group>
                           </Col>
                         </Row>
                         <Row>
                           <Col css={unapproved_card__buttons}>
                             <ButtonBar>
                               <BlueButton type="submit">
                                 Decline
                               </BlueButton>
                               <Button variant="danger" onClick={that.onCancelDeclineEmailAddress}>
                                 Cancel
                               </Button>
                             </ButtonBar>
                           </Col>
                         </Row>
                       </Form>
                   )}
              }
            </Formik>
        )
    }

    render() {
        const { email_address } = this.props
        const { in_decline_mode } = this.state
        const that = this
        if (! email_address ) {
            return null
        }
        return (
            <div>
              { in_decline_mode && that.renderUnapprovedEmailAddressDeclineForm(email_address) }

              { !in_decline_mode && 
                <div css={unapproved_card__buttons}>
                  <ButtonBar>
                    { email_address.is_approved !== true && 
                      <Button variant="success" onClick={() => that.onUpdateEmailAddressApproval({is_approved:true})}>
                        Approve
                      </Button>
                    }
                    { email_address.is_approved !== false && 
                      <Button variant="danger" onClick={that.prepareToDeclineEmailAddress}>
                        Decline
                      </Button>
                    }
                  </ButtonBar>
                </div>
              }
            </div>
        )
    }

}

function mapStateToProps(state, props) {
    const { email_address } = props
    return {
        email_address
    }
}

export default connect(mapStateToProps)(AdminFaxFromEmailAddressApprovalForm)

const unapproved_card__header = css`
font-size: 24px;
font-weight: 500;
`

const unapproved_card__email = css`
font-size: 18px;
font-weight: 500;
`

const unapproved_card__buttons = css`
margin-top: 15px;
`
