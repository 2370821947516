/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css, Global } from '@emotion/react'
import { Trans, Translation } from 'react-i18next'
import { default_theme as theme } from '../../emotion/theme'
import { keys, sortBy, filter, size, join, split, get, remove, map, includes } from 'lodash'
import { Separator } from '../layout/Separator'
import { SHORT_DAY_NAMES, SHORT_DAY_INDEXES_IN_DISPLAY_ORDER } from '../../actions/date_helpers'

export const DayPickerField = ({formik_props, field_name, manage_value_as_comma_separated_string, ...props}) => {

    let selected_days = get(formik_props.values, field_name, null)

    if ( manage_value_as_comma_separated_string ) {
        selected_days = split(selected_days, ",")
    }
    if ( ! selected_days ) {
        selected_days = []
    }

    const onToggle = (day_index) => {
        const is_selected = includes(selected_days, day_index)
        if ( is_selected ) {
            remove(selected_days, (x) => x == day_index)
        } else {
            selected_days.push(day_index)
        }

        selected_days = sortBy(selected_days)
        if (manage_value_as_comma_separated_string ) {
            formik_props.setFieldValue(field_name, join(selected_days, ","))
        } else {
            formik_props.setFieldValue(field_name, selected_days)
        }
    }

    return (
        <div css={container_style}>
          {map(SHORT_DAY_INDEXES_IN_DISPLAY_ORDER, function(day_name_index, index) {
              const day_name = SHORT_DAY_NAMES[day_name_index]
              const is_first = index === 0
              const is_last = index === size(SHORT_DAY_NAMES)-1
              
              const is_selected = includes(selected_days, day_name_index)

              return <div key={index}
                          css={[day_selector_style,
                                is_first ? day_selector_first_style : null,
                                is_last ? day_selector_last_style : null,
                                is_selected ? day_selector_style_selected_style : null]}
                          onClick={() => onToggle(day_name_index)}
                     >
                 <Trans>{day_name}</Trans>
               </div>
              
          })}
        </div>
    )
}

const day_selector_style = css`
height: 30px;
width: 56px;
color: ${theme.colors.dark_grey};
border: 1px solid ${theme.colors.light_middle_grey};
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
`

const day_selector_style_selected_style = css`
color: #fff;
background-color: ${theme.colors.primary_blue};
`

const day_selector_first_style = css`
border-radius: 4px 0px 0px 4px;
`

const day_selector_last_style = css`
border-radius: 0px 4px 4px 0px;
`

const container_style = css`
display: flex;
flex-wrap: wrap;
`
