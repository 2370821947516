/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap'
import { BlueButton } from './layout/BlueButton'
import { Separator } from './layout/Separator'
import { Trans, Translation } from 'react-i18next'

export const PaySubscriptionsWithCreditsResult = ({onClose}) => {

    return (
        <Modal show={true} onHide={onClose}>
          <Modal.Header closeButton>
            <Modal.Title><Trans>Subscription payment</Trans></Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              <Trans>Your payment was successful, and your subscription has been paid</Trans>
            </div>
            <Separator variant="h10" />
            <div>
              <BlueButton onClick={onClose}>Close</BlueButton>
            </div>
          </Modal.Body>
        </Modal>
    )
    
}
