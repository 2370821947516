
// 0 must be sunday to match the api start of week implementation
export const SHORT_DAY_NAMES = {1: "Mon",
                                2: "Tue",
                                3: "Wed",
                                4: "Thu",
                                5: "Fri",
                                6: "Sat",
                                0: "Sun",
                               }

export const SHORT_DAY_INDEXES_IN_DISPLAY_ORDER = ["1","2","3","4","5","6","0"]
