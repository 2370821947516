/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../emotion/theme'
import { Trans, Translation } from 'react-i18next'
import { map, get, size, forEach } from 'lodash'
import CurrencyValue from './CurrencyValue'
import { Separator } from './layout/Separator'
import { FormikInputField } from './form/InputField'
import { InlineIcon } from './layout/InlineIcon'
import { FormikFileUploadField } from './form/FileUploader'
import { FormikPhoneNumberInputField } from './form/PhoneNumberInputField'
import FieldInfoText from './layout/FieldInfoText'
import { NewSubscriptionCostSupplement } from './NewSubscriptionCostSupplement'
import { globalSettingsList } from '../actions/settings'

export const NumberPortingForm = ({
  product,
  formik_props,
  field_name,
  paymentCostCalculation,
}) => {
  const dispatch = useDispatch()
  dispatch(globalSettingsList.ensureGlobalSettingsLoaded())

  const is_number_set = Boolean(get(formik_props, ['values', field_name]))
  const [invoiceFileInfo, setInvoiceFileInfo] = useState(null)

  const helpdesk_email = globalSettingsList.getSetting('helpdesk_email')

  const onRemoveInvoiceFile = () => {
    formik_props.setFieldValue('invoice_file', undefined)
    setInvoiceFileInfo(null)
  }

  const onInvoiceFileUploaded = (file_info) => {
    setInvoiceFileInfo(file_info)
    formik_props.setFieldValue('invoice_file', file_info.id)
  }

  const renderNumberSelectionFields = () => {
    return (
      <Translation>
        {(t) => (
          <div>
            <div>
              <Trans>
                XOIP can transfer your number from your current telecom provider
                to XOIP.
              </Trans>
              &nbsp;
              <Trans>This may take up to 10 days.</Trans>
            </div>

            <Separator variant="h30" />

            <div>
              <Trans>Enter the number you want transferred to XOIP</Trans>
              <Separator variant="h5" />
              <div css={phone_number_field_container}>
                <FormikPhoneNumberInputField
                  name={field_name}
                  onlyCountries={['nl']}
                  formik_props={formik_props}
                  placeholder={t('Your number')}
                />
              </div>
            </div>

            {paymentCostCalculation &&
              paymentCostCalculation.total_supplement_cost_excluding_vat_euros >
                0 && (
                <>
                  {map(
                    paymentCostCalculation.supplements,
                    (supplement_info) => (
                      <>
                        <Separator variant="h20" />
                        <NewSubscriptionCostSupplement
                          label={supplement_info.label}
                          cost={
                            supplement_info.price_per_year_excluding_vat_euros
                          }
                          cost_period="year"
                        />
                      </>
                    )
                  )}
                </>
              )}

            <Separator variant="h40" />
          </div>
        )}
      </Translation>
    )
  }

  const renderInvoiceFilePlaceholder = () => {
    return (
      <div css={invoice_file_placeholder_style}>
        <Trans>Upload invoice</Trans>
      </div>
    )
  }

  const renderInvoiceFields = () => {
    const invoice_file = get(formik_props, ['values', 'invoice_file'])
    return (
      <Translation>
        {(t) => (
          <div>
            <Trans>
              Upload the latest invoice from your current telecom provider
            </Trans>
            <Separator variant="h15" />

            {get(invoiceFileInfo, 'visible_filename') && (
              <div css={invoice_filename_style}>
                <InlineIcon icon_name="status-success" />
                {invoiceFileInfo.visible_filename}
                <Separator variant="w15" />
                <div
                  css={remove_invoice_file_style}
                  onClick={onRemoveInvoiceFile}
                >
                  <InlineIcon icon_name="remove" />
                </div>
              </div>
            )}

            {!invoice_file && (
              <>
                <FormikFileUploadField
                  name="invoice_file"
                  css_classes={{
                    container: invoice_file_uploader_container_style,
                    dropzone_container: dropzone_container_style,
                  }}
                  upload_relative_url="file_info/"
                  formik_props={formik_props}
                  onFileUploaded={onInvoiceFileUploaded}
                  renderPlaceHolder={renderInvoiceFilePlaceholder}
                />

                <FieldInfoText>
                  <Separator variant="h10" />
                  <Trans
                    i18nKey="number_porting__you_can_also_upload_the_invoice"
                    values={{ helpdesk_email: helpdesk_email }}
                    components={[
                      <a
                        css={helpdesk_menu__link}
                        href={`mailto:${helpdesk_email}`}
                      >
                        {helpdesk_email}
                      </a>,
                    ]}
                    defaults="You can also upload the invoice later or email it to <0>{{helpdesk_email}}</0>."
                  />
                  <Trans></Trans>
                  <br />(<Trans>Please add your number in the subject.</Trans>)
                </FieldInfoText>
              </>
            )}
          </div>
        )}
      </Translation>
    )
  }

  return (
    <Translation>
      {(t) => (
        <div>
          <NewSubscriptionCostSupplement
            label="Number porting"
            cost={product.number_porting_setup_price_excluding_vat_euros}
          />
          <Separator variant="h15" />

          {renderNumberSelectionFields()}
          {is_number_set && renderInvoiceFields()}
        </div>
      )}
    </Translation>
  )
}

const invoice_file_placeholder_style = css``

const invoice_file_uploader_container_style = css`
  width: 150px;
  height: 38px;
  background-color: ${theme.colors.very_light_grey};
  border-radius: 4px;
  padding: 1px 14.5px;
  display: flex;
  align-items: center;
  text-align: center;
`

const dropzone_container_style = css``

const helpdesk_menu__link = css`
  color: ${theme.colors.primary} !important;
`

const remove_invoice_file_style = css`
  cursor: pointer;
  margin-top: 5px;
`

const invoice_filename_style = css`
  display: flex;
  align-items: center;
`
const phone_number_field_container = css`
  width: 250px;
`
