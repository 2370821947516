/** @jsxImportSource @emotion/react */
import React, {useContext} from 'react'
import { jsx, css } from '@emotion/react'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import AccordionContext from 'react-bootstrap/AccordionContext'
import { Trans, Translation } from 'react-i18next'
import { InlineIcon } from './InlineIcon'
import { BlueLinkButton } from './BlueLinkButton'

export function AccordionCaretToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext)

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey)
    )

    const isCurrentEventKey = currentEventKey === eventKey

    return (
        <BlueLinkButton onClick={decoratedOnClick} auto_disable={false}>
          { ! isCurrentEventKey && <InlineIcon icon_name="caret-right" variant="blue"/> }
          { isCurrentEventKey && <InlineIcon icon_name="caret-down" variant="blue"/> }
          
          <Trans>More Options</Trans>
        </BlueLinkButton>
    )
}

const toggle_style = css`
cursor: pointer;
`
