/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { Link } from 'react-router-dom'
import WrappingBusyMask from './WrappingBusyMask'
import { useDispatch, useSelector } from 'react-redux'
import { map, get, size, forEach } from 'lodash'
import GlobalStyles from './GlobalStyles'
import CurrencyValue from './CurrencyValue'
import { CreditTopupCard } from './CreditTopupCard'
import { Trans, Translation } from 'react-i18next'
import { NewSubscriptionCard } from './NewSubscriptionCard'
import { default_theme as theme } from '../emotion/theme'
import { Separator } from './layout/Separator'
import { Row, Col, Container, Popover, OverlayTrigger, Button } from 'react-bootstrap'
import xoip_logo from '../images/xoip-logo.svg'
import { topupList } from '../actions/topup'
import { InlineIcon } from './layout/InlineIcon'
import { molliePaymentList } from '../actions/mollie_payment'
import { subscriptionList } from '../actions/customer_subscription'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    product: Yup.string().required("Required")
})

export const NewAccountLanding = ({...props}) => {

    const dispatch = useDispatch()
    const topups = topupList.getVisibleObjects()
    const is_ready =  topupList.isReady()
    const is_saving = useSelector(() => molliePaymentList.getIsSavingObject() || subscriptionList.getIsSavingObject())
    
    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(topupList.fetchListIfNeeded())
        }
        fetchStaticObjects()
    }, [] )   
    
    const renderHeader = () => {
        return (
            <div>
              <div>
                <div css={logo_row_style}>
                  <img src={ xoip_logo } alt="" css={ image_style } />
                </div>
                <h2 css={ h2_style }>
                  <Trans>Account created!</Trans>
                </h2>
                <Row>
                  <Col md={6} css={sub_heading_style}>
                    <div>
                      <Trans>
                        Get a number and receive faxes in your email
                      </Trans>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
        )
    }

    const renderTopups = () => {
        return (
            <WrappingBusyMask is_loading={!is_ready}>
              <Row>
                <Col>

                  <h2 css={ h2_style }>
                    <Trans>I just want to send faxes</Trans>
                  </h2>
                  <Trans
                    i18nKey="account_landing__to_be_able_to_send"
                    components={[<CurrencyValue value={0.3} use_span={true} />,
                                 <a target="_blank" href="/fax_sending_rates" css={internal_fax_link_style} />]}
                    defaults="To send faxes you must first top up your XOIP balance. Faxes cost <0></0> per page for most destinations."
                  />
                  
                  <h3 css={h3_style}>
                    Purchase credits
                  </h3>
                  <div css={topup_list_style}>
                    { map(topups, (topup, index) => <CreditTopupCard key={index} topup={topup} />) }
                  </div>
                </Col>
              </Row>
            </WrappingBusyMask>
        )
    }

    return (
        <div css={main_style}>
          <Container fluid>
            <GlobalStyles />
            { renderHeader() }

            <WrappingBusyMask is_loading={is_saving}>
              
              <NewSubscriptionCard />
              
              <Separator variant="h50" />
              
              { renderTopups() }
              
              <Separator variant="h20" />
              
              <Link to="/">
                <Trans>Let me in, I will purchase credits later</Trans>
                <InlineIcon icon_name="chevron-right" />
              </Link>

              <Separator variant="h50" />
            </WrappingBusyMask>
            
          </Container>
        </div>
    )

}

const main_style = css`
margin-top: 50px;
margin-bottom: 100px;
margin-left: 15px;
margin-right: 15px;
`



const logo_row_style = css`
display: flex;
justify-content: space-between;
`

const image_style = css`
height: 42px;
`

const sub_heading_style = css`
font-size: 16px;
line-height: 19px;
display: flex;

`

const h2_style = css`
font-size: 28px
width: 100%;
`

const h3_style = css`
font-size: 24px
width: 100%;
`


const topup_list_style = css`
display: flex;
flex-wrap: wrap;
margin-right: -30px;
`


const internal_fax_link_style = css`

`
