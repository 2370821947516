import { ItemList } from '../orm'
import { map, get, head } from 'lodash'

class SubscriptionNumberChoices extends ItemList {
    getEntityKey() {
        return "subscription_number_choices"
    }

    searchNumber({phone_number, product_id}) {
        return this.saveNewObject({action: 'search',
                                   number: phone_number,
                                   reservation_token: this.getReservationToken(),
                                   product: product_id})
    }

    reserveNumber({phone_number, product_id}) {
        return this.saveNewObject({action: 'reserve_single_number',
                                   number: phone_number,
                                   reservation_token: this.getReservationToken(),
                                   product: product_id})
    }

    findAndReserve({product_id, phone_number_prefix_ids, country, number_type, city, customer, on_done}) {
        // customer is required if logged in as admin
        const reservation_token = this.getReservationToken()
        return async(dispatch, getState) => {
            dispatch(this.saveNewObject({action: 'find_and_reserve',
                                         product: product_id,
                                         reservation_token,
                                         phone_number_prefixes: phone_number_prefix_ids,
                                         country,
                                         number_type,
                                         customer,
                                         city})).then(on_done)
        }
    }

    getAllocationMethod() {
        return get(this.getReservation(), "allocation_method")
    }

    getReservedNumbersAsOptions() {
        return map(this.getReservedNumbers(), function(item) { return {label: item.number, value: item.id}})
    }

    getReservedNumbers() {
        return get(this.getReservation(), "phone_numbers")
    }

    getReservationToken() {
        return get(this.getReservation(), "reservation_token")
    }
    
    getReservation() {
        const reservation = get(this.getAllObjects(), "find_and_reserve")
        return reservation
    }
    
}

export const subscriptionNumberChoiceList = new SubscriptionNumberChoices("subscription_number_choices__default")
