/** @jsxImportSource @emotion/react */
import React from 'react'
import { css } from '@emotion/react'
import { customerList } from '../actions/customer'
import ProfileMenuHeader from './ProfileMenuHeader'
import ProfileMenuContent from './ProfileMenuContent'

const ProfileMenu = () => {
  const is_ready = customerList.isReady() && !customerList.isLoading()

  if (!is_ready) {
    return null
  }

  return (
    <div css={popup_menu_style}>
      <ProfileMenuHeader />
      <hr />
      <ProfileMenuContent />
    </div>
  )
}

export default ProfileMenu

const popup_menu_style = css`
  padding-top: 16px;
  padding-bottom: 19px;
  min-width: 243px;
  padding-right: 20px;
  padding-left: 20px;
  position: absolute;
  right: 80px;
  top: -10px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.15);
  font-weight: 400;
`
