/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { get, map, filter } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import { default_theme as theme } from '../emotion/theme'
import { InlineIcon } from './layout/InlineIcon'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import { Button } from 'react-bootstrap'

export const LowTrafficWarningWidget = ({ subscription }) => {
  const required_minutes =
    subscription.product_minimum_paid_minutes_before_cancellation
  const required_num_days =
    subscription.product_cancel_if_below_minimum_traffic_after_num_days

  const remaining_minutes = Math.ceil(
    subscription.product_minimum_paid_minutes_before_cancellation -
      subscription.num_minutes_traffic_since_low_traffic_counter_reset_at
  )
  const remaining_days =
    required_num_days -
    moment().diff(subscription.low_traffic_counter_reset_at, 'days')

  const tooltipId = `tooltip_LowTrafficWarningWidget_${get(subscription, 'id')}`

  const tooltip = (
    <Tooltip id={tooltipId}>
      <Trans
        i18nKey="low_traffic_warning_tooltip"
        values={{
          required_minutes: required_minutes,
          required_num_days: required_num_days,
          remaining_minutes: remaining_minutes,
          remaining_days: remaining_days,
        }}
        components={[<span>{required_minutes}</span>]}
      />
    </Tooltip>
  )

  return (
    <>
      {subscription.is_in_low_traffic_warning_zone && (
        <span css={icon_style}>
          <OverlayTrigger overlay={tooltip}>
            <span>
              <FontAwesomeIcon icon={faExclamationTriangle} css={red_style} />
            </span>
          </OverlayTrigger>
        </span>
      )}
    </>
  )
}

const icon_style = css`
  margin-left: 4px;
  font-size: 13px;
`

const red_style = css`
  color: ${theme.colors.primary_red};
`
