/** @jsxImportSource @emotion/react */
import React,{ useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { jsx } from '@emotion/react'
import { head, get } from 'lodash'
import { adminUserList } from '../actions/admin_user'
import { Error } from '../../components/layout/Error'
import { PhoneNumber } from '../../components/PhoneNumber'
import { CardHeader } from '../../components/layout/CardHeader'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { Link, useHistory } from 'react-router-dom'
import AdminMainLayout from './AdminMainLayout'
import { AdminQuickLink } from './AdminQuickLink'
import AdminTableFilter from './AdminTableFilter'
import AdminUserForm from './AdminUserForm'
import AdminTableHeader from './AdminTableHeader'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import { adminAnsweringMessageList } from '../actions/admin_answering_message'
import { adminCustomerVoicePhoneNumberList } from '../actions/admin_customer_phone_number'
import { adminCustomerList } from '../actions/admin_customer'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { Button, Container } from 'react-bootstrap'
import { InlineIcon } from '../../components/layout/InlineIcon'

export const AdminAnsweringMessagesForCustomerPhoneNumber = ({match}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const customer_phone_number_id = get(match, ["params", "customer_phone_number_id"], null)
    const answering_messages = useSelector(() => adminAnsweringMessageList.getVisibleObjects())
    const customer_phone_number = useSelector(() => adminCustomerVoicePhoneNumberList.getObject(customer_phone_number_id))
    const customer_id = get(customer_phone_number, "customer")
    const customer = useSelector(() => adminCustomerList.getObject(customer_id))
    const is_loading = useSelector(() => adminAnsweringMessageList.isLoading())

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(adminCustomerVoicePhoneNumberList.ensureObjectLoaded(customer_phone_number_id))
            dispatch(adminAnsweringMessageList.updateListFilter({customer_phone_number: customer_phone_number_id}))
            dispatch(adminAnsweringMessageList.fetchListIfNeeded())
        }
        fetchStaticObjects()
    }, [customer_phone_number_id])

    useEffect(() => {
        dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
    }, [customer_id])

    const updateOnChange = (value) => {
        dispatch(adminAnsweringMessageList.updateListFilter({customer_phone_number: customer_phone_number_id}))
        dispatch(adminAnsweringMessageList.fetchListIfNeeded())
    }

    const onAdd = () => {
        history.push(`/admin/customer_phone_number/${customer_phone_number_id}/answering_message/`)
    }

    const onEdit = (item) => {
        history.push(`/admin/customer_phone_number/${customer_phone_number_id}/answering_message/${item.id}`)
    }
    
    const headers = {
        is_active: { name: "Active",
                     renderValue: (item) => (
                         <>
                         { item.is_active === true && <InlineIcon icon_name='status-success' /> }
                         { item.is_active !== true && <InlineIcon icon_name='status-error' /> }
                         </>
                     )
                   },
        file_name: { name: "Asterisk File name",
                     renderValue: (item) => get(item, ["answering_message_file_info", "original_filename"])
                   },
        legacy_file_name: { name: "Legacy file name",
                     renderValue: (item) => get(item, ["answering_message_file_info", "visible_filename"])
                          },
        action: { name: "",
                  renderValue: (item) => <InlineIcon icon_name="eye" onClick={(evt) => onEdit(item)} />
                }
    }
    
    return (
        <AdminMainLayout title=''
                         breadcrumbs={[{name: 'admin_home'},
                                       {name: 'customer', label: get(customer, ["user", "display_name"]), url: `/admin/customer/${customer_id}`},
                                       {name: 'customer_phone_number', label: get(customer_phone_number, "number"), url: `/admin/customer/${get(customer_phone_number, "customer")}/voice_settings`},
                                       {name: 'customer_phone_number_answering_messages', label: "Welcome messages", url: `/admin/customer_phone_number/${customer_phone_number_id}/answering_messages`},
                                      ]}>
          <Container fluid>

            <Error>
              This page is out of date now with the recent changes to forwarding in the customer site.
              For now you can still upload answering messages, but using them requires updating the customer json manually.
            </Error>
            
            <CardHeader title={<div>Answering messages for <PhoneNumber phone_number={get(customer_phone_number, "number")} /></div>}
                        right_child={<div>
                                       <AdminTableFilter item_list={adminAnsweringMessageList} updateOnChange={updateOnChange} />
                                       <div>
                                         <WhiteButton variant="outline-primary" onClick={ onAdd }>
                                           Add Welcome Message
                                         </WhiteButton>
                                       </div>

                                     </div>
                                    } />
            
            <AdminTableHeader >
              
            </AdminTableHeader>
            
            <CommonTable
              is_loading={ is_loading }
              empty_message={`No answering messages`}
              headers={ headers }
              items={ answering_messages }
              item_list={adminAnsweringMessageList}
            />
          </Container>
        </AdminMainLayout>
    )
    
}
