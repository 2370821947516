/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react'
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { jsx, css } from '@emotion/react'
import AdminTableFilter from './AdminTableFilter'
import {get, head, map, size, includes, replace} from 'lodash'
import { withRouter } from 'react-router-dom'
import AdminMainLayout from './AdminMainLayout'
import AdminTableHeader from './AdminTableHeader'
import { CardHeader } from '../../components/layout/CardHeader'
import { AdminQuickLink } from './AdminQuickLink'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import CardInfoText from '../../components/layout/CardInfoText'
import { Container, Row, Col } from 'react-bootstrap'
import Timestamp from '../../components/Timestamp'
import CommonTable from '../../components/CommonTable'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import FieldInfoText from '../../components/layout/FieldInfoText'
import { InlineIconBar } from '../../components/layout/InlineIconBar'
import {showSuccess, showError} from '../../actions/Error'
import { Formik, Form, FieldArray, Field } from 'formik'
import { Separator } from '../../components/layout/Separator'
import Card from '../../components/layout/Card'
import { Button } from 'react-bootstrap'
import {default_theme as theme} from "../../emotion/theme"
import { adminCustomerList } from '../actions/admin_customer'

const LEFT_WIDTH = 6

export const AdminGlobalSearch = ({}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const [searchTerm, setSearchTerm] = useState(null)

    const customerResults = useSelector(() => adminCustomerList.getVisibleObjects())
    
    useEffect(() => {

        if ( ! searchTerm ) {
            return
        }
        
        async function fetchStaticObjects() {
            // dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
            // dispatch(adminCustomerListForReseller.updateListFilter({reseller: customer_id}))
            dispatch(adminCustomerList.updateListFilter({any_field: searchTerm}))
            dispatch(adminCustomerList.fetchListIfNeeded())
        }
        fetchStaticObjects()
    }, [searchTerm])

    const renderResultTable = ({title, item_list, headers}) => {

        return (
            <Card variant="white_wide_padding">
              <WrappingBusyMask is_loading={item_list.isLoading()}>
                <h2>{title}</h2>
                <CommonTable
                  is_loading={ item_list.isLoading() }
                  empty_message={`There are no results.`}
                  headers={ headers }
                  items={ item_list.getVisibleObjects() }
                  item_list={item_list}
                />
              </WrappingBusyMask>
            </Card>
        )
    }
    
    const renderCustomerResults = () => {
        return renderResultTable({title:"Customers", item_list: adminCustomerList,
                                  headers: { short_id: {name: "ID" },
                                             display_name: { name: "Name", renderValue: (item) => item.user.display_name },
                                             email: { name: 'Email', renderValue: (item) => <AdminQuickLink name="customer" value={item.id} label={item.user.email} /> },}
                                 })
    }
    
    const onSearch = (values) => {
        setSearchTerm(values.any_field)
    }
    
    return (
        <AdminMainLayout active_key="global_search"
                         breadcrumbs={[{name: 'admin_home'},
                                       {name: 'global_search', label: 'Global search', url: '/admin/global_search'}]}>
          <AdminTableHeader title="Global Search">
          </AdminTableHeader>
          <Container fluid>
            <CardInfoText>
              Note: This page is a dev feature, it's not ready to use yet
              <br/>
              Search any field across the system.
              <Row>
                <Col>
                  <AdminTableFilter updateOnChange={onSearch} />
                </Col>
              </Row>
            </CardInfoText>
        
            { searchTerm &&
              <>
                { renderCustomerResults() }
              </>
            }
            
          </Container>
        </AdminMainLayout>
        
    )
    
}
