/** @jsxImportSource @emotion/react */
import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Trans, Translation } from 'react-i18next'
import { jsx, css } from '@emotion/react'

export class Duration extends Component {
    
    render() {
        const { seconds, format, hide_seconds_if_empty } = this.props
        let time_parts = convert_seconds_to_parts(seconds)
        const formatted_hours = format_hours({parts:time_parts, format: format || 'ms', hide_seconds_if_empty})
        
        return (
            <div css={hours_style} >
              {formatted_hours}
            </div>
        )
    }
}

function format_hours({parts, format, hide_seconds_if_empty}) {
    let {hours, minutes, seconds} = parts

    if ( format === 'long' ) {
        return (
            <>
              { hours > 0 &&
                <>
                  {hours}&nbsp;<Trans>hours</Trans>
                  &nbsp;
                </>
              }
              {minutes}&nbsp;<Trans>min</Trans>

              { (hide_seconds_if_empty !== true || seconds > 0 ) &&
                <>
                  seconds&nbsp;<Trans>seconds</Trans>
                </>
              }
            </>
        )
    
    } else if ( format == 'ms' ) {
        let str = ""
        if ( hours > 0 ) {
            str += hours + "h "
        }
        str += minutes + "m "

        if ( hide_seconds_if_empty !== true || seconds > 0 ) {
            str += seconds + "s "
        }
        return str
        
    } else if (format == "m:s" ) {
        if (hours   < 10 && hours >= 0) {hours   = "0"+hours}
        if (seconds < 10 && seconds >= 0) {seconds = "0"+seconds}
        if (minutes < 10 && minutes >= 0) {minutes = "0"+minutes}
        return minutes + ':' + seconds
        
    } else if (format == "h:m" ) {
        if (hours   < 10 && hours >= 0) {hours   = "0"+hours}
        if (seconds < 10 && seconds >= 0) {seconds = "0"+seconds}
        if (minutes < 10 && minutes >= 0) {minutes = "0"+minutes}
        return hours + ':' + minutes
    }
}

function convert_seconds_to_parts(raw_seconds) {
    if ( ! seconds ) {
        seconds = 0
    }
    var sec_num = parseInt(raw_seconds, 10) || 0
    var hours = Math.floor(sec_num / 3600) || 0
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60) || 0
    var seconds = sec_num - (hours * 3600) - (minutes * 60) || 0
    return {hours: hours, minutes:minutes, seconds:seconds}
}

const hours_style = css`
position: relative;

&:hover {
    text-decoration: underline;
}
`

const hours_negative = css`
color: $warning-color;
&:hover {
    color: $color-36;
    text-decoration: underline;
}
`
