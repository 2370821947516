/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { get } from 'lodash'
import LoadingOverlay from './layout/loading_overlay/LoadingOverlay'
import { Trans, Translation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { ButtonBar } from './layout/ButtonBar'
import CurrencyValue from './CurrencyValue'
import { Separator } from './layout/Separator'
import { Button } from 'react-bootstrap'

export const PaySubscriptionsWithCreditsConfirmation = ({isSaving, customer, subscription, onConfirm, onCancel}) => {

    return (
        <Modal show={true} onHide={onCancel}>
          <Modal.Header closeButton>
            <Modal.Title><Trans>Subscription payment</Trans></Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <LoadingOverlay
                active={isSaving}
                spinner
                text={<Trans>Please wait a moment...</Trans>}
            >
              <div>
                { ! isSaving &&
                  <div>
                  <Trans
                      i18nKey="paysubscriptions__credits_confirmation"
                      components={[<CurrencyValue use_span={true} value={get(subscription, "amount_owing_excluding_vat_euros")} />,
                                   <CurrencyValue use_span={true} value={get(customer, "credits_excluding_vat_euros")} />]}
                      defaults="<0></0> will be deducted from your XOIP credit balance of <1></1> (VAT was already applied to your credit purchases)."
                  />
                  <Separator variant="h10" />
                  <div>
                    <Trans>Do you want to proceed?</Trans>
                  </div>
                  </div>
                }
                { isSaving && <br/> }
              </div>
            </LoadingOverlay>
          </Modal.Body>
          
          <Modal.Footer>
            <ButtonBar>
              <Button variant="danger" onClick={onCancel}>
                <Trans>Cancel</Trans>
              </Button>
              <Separator variant="w5" />
              <Button variant="success" onClick={onConfirm}>
                <Trans>Confirm</Trans>
              </Button>
            </ButtonBar>
          </Modal.Footer>
        </Modal>
    )
    
}
