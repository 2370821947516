/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import moment from 'moment'
import { head, get, map, keys, split, size } from 'lodash'
import { ButtonBar } from '../../components/layout/ButtonBar'
import { CardHeader } from '../../components/layout/CardHeader'
import CardInfoText from '../../components/layout/CardInfoText'
import { LanguageFlag } from '../../components/LanguageFlag'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { Separator } from '../../components/layout/Separator'
import CurrencyValue from '../../components/CurrencyValue'
import { Formik, Form, FieldArray, Field } from 'formik'
import Loading from '../../components/Loading'
import { adminAsteriskIncomingFaxStatuses } from '../actions/admin_dropdown_options'
import { FormikTextarea } from '../../components/form/TextareaField'
import { FormikInputField } from '../../components/form/InputField'
import { FormikDropdownField } from '../../components/form/Dropdown'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import AdminMainLayout from './AdminMainLayout'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import Card from '../../components/layout/Card'
import { getBase64 } from '../../actions/file_info'
import { Col, Row, Container, Navbar, Nav } from 'react-bootstrap'
import { Form as BootstrapForm } from 'react-bootstrap'
import * as Yup from 'yup'
import {showSuccess, showError} from '../../actions/Error'
import { BlueButton } from '../../components/layout/BlueButton'
import { handleSubmitResult } from '../../actions/form'
import { asteriskAuthTokenList } from '../actions/asterisk_auth_token'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { Error } from '../../components/layout/Error'

const validationSchema = Yup.object().shape({
    'raw_destination_phone_number': Yup.string().required("Required")
})

class AdminIncomingFaxTest extends Component {

    constructor(props) {
        super(props)
        this.state = {sending: false, res: null}
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(asteriskAuthTokenList.updateListFilter({server_name: 'asterisk'}))
        dispatch(asteriskAuthTokenList.fetchListIfNeeded())
        dispatch(adminAsteriskIncomingFaxStatuses.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(asteriskAuthTokenList.fetchListIfNeeded())
    }
    
    onSendFax = (values, formik_funcs) => {
        const { dispatch, customer_id } = this.props
        const that = this
        this.setState({sending: true})
        const on_ok = function(json) {
            that.setState({sending: false, res: JSON.stringify(json)})
            showSuccess("Saved", "File sent")
        }
        asteriskAuthTokenList.sendTestFax(values).then(on_ok)
    }

    onSelectedFile = (el, formik_props) => {
        const file = el.currentTarget.files[0]
        getBase64(file).then(function(data) {
            data = data.slice("data:application/pdf;base64,".length)
            formik_props.setFieldValue("content_as_base64", data)
        })
    }

    render() {
        const { initial_values, is_ready, transaction, transaction_id,
                customer, customer_id, auth_token, status_options } = this.props
        const { sending, res } = this.state
        const that = this
        const title = `${get(transaction, ["description"])}`

        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'incoming_fax_test', label: 'Incoming Fax Test', url: '/admin/incoming_fax_test'}]}>
              <Container fluid>
                <Row>
                  <Col md="8">
                    <CardHeader title={`Simulate an incoming fax`} />
                    <CardInfoText>
                      This page can be used to test the handling for faxes arriving for a XOIP customer from somewhere in the world. It completely bypasses the Asterisk servers.
                    </CardInfoText>
                    <Separator variant="h20" />

                    { res && 
                      <div css={res_style}>
                        {res}
                      </div>
                    }
                    
                    <WrappingBusyMask is_loading={!is_ready}>

                      <Formik
                        initialValues={initial_values}
                        onSubmit={that.onSendFax}
                        enableReinitialize={true}
                        validationSchema={validationSchema}
                    >
                        {formik_props => {
                            const { values } = formik_props
                            return (
                                <Form>
                                  <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                                  <Row>
                                    <Col md="4">
                                      <BootstrapForm.Group>
                                        <BootstrapForm.Label>Destination phone number</BootstrapForm.Label>
                                        <br/>
                                        <FormikInputField name="raw_destination_phone_number" placeholder="Destination phone number" />
                                      </BootstrapForm.Group>

                                      <BootstrapForm.Group>
                                        <BootstrapForm.Label>Fax file</BootstrapForm.Label>
                                        <br/>
                                        { formik_props.errors.file &&
                                          <Error>
                                            { formik_props.errors.file}
                                          </Error>
                                        }
                                        <input type="file" name="raw_file" onChange={(el) => that.onSelectedFile(el, formik_props)} />
                                      </BootstrapForm.Group>

                                      <BootstrapForm.Group>
                                        <BootstrapForm.Label>Failover mode (simulate email already sent by Asterisk)</BootstrapForm.Label>
                                        <br/>
                                        <FormikCheckboxField name="fax_email_already_sent" formik_props={formik_props} />
                                      </BootstrapForm.Group>
                                      

                                      <Row>
                                        <Col>
                                          <ButtonBar>
                                            <BlueButton type="submit">
                                              Send
                                            </BlueButton>
                                          </ButtonBar>
                                        </Col>
                                      </Row>
                                      
                                    </Col>
                                    <Col md="1">&nbsp;</Col>
                                    <Col md="4">
                                      <Row>
                                        <Col>
                                          <BootstrapForm.Group>
                                            <BootstrapForm.Label>Source phone number</BootstrapForm.Label>
                                            <br/>
                                            <FormikInputField name="raw_source_phone_number" placeholder="Received at" />
                                          </BootstrapForm.Group>
                                        </Col>
                                      </Row>
                                      
                                      <Row>
                                        <Col>
                                          <BootstrapForm.Group>
                                            <BootstrapForm.Label>Received at (as if arriving at the asterisk server)</BootstrapForm.Label>
                                            <br/>
                                            <FormikInputField name="received_at" placeholder="Received at" />
                                          </BootstrapForm.Group>
                                        </Col>
                                      </Row>
                                      
                                      <Row>  
                                        <Col>
                                          <BootstrapForm.Group>
                                            <BootstrapForm.Label>Content type</BootstrapForm.Label>
                                            <br/>
                                            <FormikInputField name="content_type" placeholder="Content type" />
                                          </BootstrapForm.Group>
                                        </Col>
                                      </Row>
                                      
                                      <Row>  
                                        <Col>
                                          <BootstrapForm.Group>
                                            <BootstrapForm.Label>Actual number of pages received</BootstrapForm.Label>
                                            <br/>
                                            <FormikInputField name="num_pages_received" placeholder="Actual number of pages received" />
                                          </BootstrapForm.Group>
                                        </Col>
                                      </Row>
                                      
                                      <Row>  
                                        <Col>
                                          <BootstrapForm.Group>
                                            <BootstrapForm.Label>Status</BootstrapForm.Label>
                                            <br/>
                                            <FormikDropdownField name="status"
                                                                 options={status_options}
                                                                 placeholder="Status"
                                                                 formik_props={formik_props} />
                                          </BootstrapForm.Group>
                                        </Col>
                                      </Row>

                                      <Row>  
                                        <Col>
                                          <BootstrapForm.Group>
                                            <BootstrapForm.Label>Error message from asterisk</BootstrapForm.Label>
                                            <br/>
                                            <FormikInputField name="last_error" placeholder="Error message" />
                                          </BootstrapForm.Group>
                                        </Col>
                                      </Row>
                                      
                                      <Row>  
                                        <Col>
                                          <BootstrapForm.Group>
                                            <BootstrapForm.Label>Asterisk internal reference</BootstrapForm.Label>
                                            <br/>
                                            <FormikInputField name="asterisk_ref" placeholder="Asterisk ref" />
                                          </BootstrapForm.Group>
                                        </Col>
                                      </Row>

                                      <Row>  
                                        <Col>
                                          <BootstrapForm.Group>
                                            <BootstrapForm.Label>Asterisk server name</BootstrapForm.Label>
                                            <br/>
                                            <FormikInputField name="server_name" placeholder="Asterisk server name" />
                                          </BootstrapForm.Group>
                                        </Col>
                                      </Row>

                                      <Row>  
                                        <Col>
                                          <BootstrapForm.Group>
                                            <BootstrapForm.Label>Asterisk auth token</BootstrapForm.Label>
                                            <br/>
                                            <FormikInputField name="auth_token" placeholder="Asterisk auth token" />
                                          </BootstrapForm.Group>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Form>
                            )
                        }}
                      </Formik>
                    </WrappingBusyMask>
                  </Col>
                </Row>
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {

    const auth_token = head(asteriskAuthTokenList.getVisibleObjects())
    
    return {
        auth_token,
        initial_values: {
            raw_source_phone_number: "+31 1234 123",
            server_name: get(auth_token, "server_name"),
            auth_token: get(auth_token, "token"),
            received_at: moment().format(),
            content_type: "application/pdf",
            num_pages_received: 1,
            asterisk_ref: 'TEST'
        },
        is_ready: asteriskAuthTokenList.isReady(),
        status_options: adminAsteriskIncomingFaxStatuses.getAsOptions()
    }
}

export default connect(mapStateToProps)(AdminIncomingFaxTest)

const breadcrumb_item = css`
display: flex;
align-items: center;
`

const res_style = css`
font-size: 12px;
border: 1px solid #efefef;
`
