/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import MainLayout from './MainLayout'
import CallHistoryCard from './CallHistoryCard'
import { Row, Col, Container } from 'react-bootstrap'
import { customerList } from '../actions/customer'
import { UpgradePathNotifications } from './UpgradePathNotifications'
import { Numbers } from './Numbers'

class CallHistory extends Component {
  componentDidMount() {
    const { dispatch } = this.props
    dispatch(customerList.ensureCustomerLoaded())
  }

  render() {
    const active_key = this.props.active_key || 'home'
    return (
      <MainLayout active_key={active_key} breadcrumbs={[{ name: active_key }]}>
        <Container fluid>
          <UpgradePathNotifications display_style="call_history" />

          <UpgradePathNotifications display_style="voice_numbers" />

          <Numbers />

          <CallHistoryCard />
        </Container>
      </MainLayout>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    customer: customerList.getCustomer(),
  }
}

export default withRouter(connect(mapStateToProps)(CallHistory))
