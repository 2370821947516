export const ATTACH_FAX_TO_CONFIRMATION_EMAIL_OPTIONS = [ {value: 'no', label: "Don't Attach"},
                                                          {value: 'first_page', label: 'Only first Page'},
                                                          {value: 'all_pages', label: 'All Pages'} ]

export const SEND_MULTIPLE_FILES_OPTIONS = [ {value: "one_fax", label: 'One Fax'},
                                             {value: "fax_per_file", label: 'Fax per File'} ]

export const INCOMING_FAX_BY_EMAIL_INITIAL_VALUES_DEFAULT = {
}

export const OUTGOING_FAX_INITIAL_VALUES_DEFAULT = {
    send_multiple_files_choice: 'one_fax',
    attach_fax_to_confirmation_email: 'no',
    fax_header: 'www.xoip.com'
}
