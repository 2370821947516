import { ItemList } from '../../orm'
import { map } from 'lodash'

class AdminPhoneNumberPrefixSetList extends ItemList {
    getEntityKey() {
        return "admin/phone_number_prefix_set"
    }

}

export const adminPhoneNumberPrefixSetList = new AdminPhoneNumberPrefixSetList("admin_phone_number_prefix_set__default")
