import { ItemList } from '../../orm'
import { get, map, union } from 'lodash'

class AdminSubscriptionStatusList extends ItemList {
    getEntityKey() {
        return "admin/subscription_status"
    }

    getAsOptions(args) {
        const { empty_option_label } = args || {}
        let items = get(args, "items", this.getVisibleObjects())

        items = map(items, function(item) {return {label: item.display_name, value: item.id}})
        if ( empty_option_label ) {
            items = union([{label: empty_option_label, value: null}], items)
        }
        return items
    }
}

export const adminSubscriptionStatusList = new AdminSubscriptionStatusList("admin_subscription_status__default")
