/** @jsxImportSource @emotion/react */
import React, { Component, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { Button } from 'react-bootstrap'
import { BaseButton } from './BaseButton'

export const BlueLinkButton = ({ onClick, children, ...props }) => {
  return (
    <BaseButton
      variant="link"
      extra_css={style}
      auto_disable={false}
      onClick={onClick}
      {...props}
    >
      {children}
    </BaseButton>
  )
}

const style = css`
  padding-left: 0px;
  color: ${theme.colors.primary_dark};
  font-weight: 500;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  text-decoration: none;

  &:hover {
    background-color: transparent;
    color: ${theme.colors.primary_dark};
  }

  &:focus {
    background-color: transparent;
    color: ${theme.colors.primary_dark};
    box-shadow: none;
    border: 1px solid transparent;
  }

  &:not(:disabled):not(.disabled):active {
    background-color: transparent;
    color: ${theme.colors.primary_dark};
  }

  &:disabled {
    background-color: transparent;
    border: 1px solid #ffffff;
    color: ${theme.colors.primary_dark};
  }
`
