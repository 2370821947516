/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import FieldInfoText from '../../components/layout/FieldInfoText'
import { jsx, css } from '@emotion/react'
import { FormikInputField } from '../../components/form/InputField'
import { default_theme as theme } from '../../emotion/theme'
import { head, get, map, keys, filter, size } from 'lodash'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { adminCallForwardingCostStructureVariantList } from '../actions/admin_call_forwarding_cost_structure_variant'
import { adminPhoneNumberPrefixSetList } from '../actions/admin_phone_number_prefix_set'
import { Separator } from '../../components/layout/Separator'
import { FieldArray } from 'formik'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { BlueLinkButton } from '../../components/layout/BlueLinkButton'

export const AdminCallForwardingCostOptions = ({formik_props, RIGHT_WIDTH, LEFT_WIDTH}) => {

    const dispatch = useDispatch()
    const call_forwarding_cost_structure_variant_options = useSelector(() => adminCallForwardingCostStructureVariantList.getAsOptions())
    const phone_number_prefix_set_options = useSelector(() => adminPhoneNumberPrefixSetList.getAsOptions())

    useEffect(() => {
        dispatch(adminCallForwardingCostStructureVariantList.updatePaginationNumItemsPerPage(1000))
        dispatch(adminCallForwardingCostStructureVariantList.fetchListIfNeeded())
        dispatch(adminPhoneNumberPrefixSetList.updatePaginationNumItemsPerPage(1000))
        dispatch(adminPhoneNumberPrefixSetList.fetchListIfNeeded())
    }, [])

    const onAddCustomNumberForwardingRate = (formik_props, arrayHelpers) => {
        const index = size(get(formik_props, ["values", "ab_call_forwarding_costs"]))
        arrayHelpers.insert(index, {source_phone_number_prefix_set: null,
                                    call_forwarding_cost_structure_variant: null,
                                    sequence: index})
    }

    const onDeleteCustomNumberForwardingRate = (index, arrayHelpers) => {
        arrayHelpers.remove(index)
    }

    const renderFreeForwardingMinutes = () => {
        return (
            <div>
              
              Free forwarding minutes
              <FieldInfoText>
                The number of minutes allowed per month for forwarding calls to numbers in the selected prefix set.
              </FieldInfoText>
              
              <Row>
                <Col md={4}>
                  <FormikInputField
                    name="monthly_num_free_voice_forwarding_minutes"
                    type="text"
                    placeholder="Minutes per month"
                  />
                </Col>

                <Col>
                  <FormikDropdownField name="prefix_set_qualifying_for_free_forwarding_minutes"
                                       formik_props={formik_props}
                                       placeholder="Forwarding destination"
                                       options={phone_number_prefix_set_options} />
                </Col>
              </Row>
              <Separator variant="h30" />
            </div>
        )
    }

    const renderVoicemailCosts = () => {
        return (
            <div>

              <Row>
                <Col md={4}>
                  Start rate
                </Col>
                <Col>
                  Per minute
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormikInputField
                      name="voicemail_start_rate_excluding_vat_euros"
                      type="text"
                      placeholder="Start rate"
                  />
                </Col>
                <Col md={4}>
                  <FormikInputField
                      name="voicemail_rate_per_minute_excluding_vat_euros"
                      type="text"
                      placeholder="Per minute"
                  />
                </Col>
              </Row>
              <Separator variant="h30" />
            </div>
        )
    }

    const renderAbCallForwardingCosts = () => {
        return (
            <FieldArray name="ab_call_forwarding_costs"
                        render={arrayHelpers => (
                            <>
                              {map(get(formik_props.values, "ab_call_forwarding_costs"), (ab_call_forwarding_cost, index) => {
                                  return (
                                      <div>
                                        Custom A-number rate {index+1}
                                        <Row>
                                          <Col md="5">
                                            <FieldInfoText>
                                              For calls from these A-numbers
                                            </FieldInfoText>
                                            <FormikDropdownField name={`ab_call_forwarding_costs.${index}.source_phone_number_prefix_set`}
                                                                 formik_props={formik_props}
                                                                 placeholder="A number prefix set"
                                                                 options={phone_number_prefix_set_options} />
                                          </Col>
                                          <Col md="5">
                                            <FieldInfoText>
                                              Charge this forwarding rate to C-number
                                            </FieldInfoText>
                                            <FormikDropdownField name={`ab_call_forwarding_costs.${index}.call_forwarding_cost_structure_variant`}
                                                                 formik_props={formik_props}
                                                                 placeholder="Select outgoing call forwarding rate table"
                                                                 options={call_forwarding_cost_structure_variant_options} />
                                          </Col>
                                          <Col>
                                            <InlineIcon icon_name="delete" onClick={(evt) => onDeleteCustomNumberForwardingRate(index, arrayHelpers)} />
                                          </Col>
                                        </Row>
                                        <Separator variant="h30" />
                                      </div>
                                  )
                              })}

                              <div>
                                <BlueLinkButton auto_disable={false} onClick={() => onAddCustomNumberForwardingRate(formik_props, arrayHelpers)}>
                                  <InlineIcon icon_name="add_no_circle" variant="blue" />
                                  Add a custom A-number rate
                                </BlueLinkButton>
                              </div>
                              
                              <Separator variant="h50" />
                            </>
                        )} 
            />
        )
    }
    
    return (
        <div>
          <Row>
            <Col md={LEFT_WIDTH} css={section_name_style}>
              Forwarding rates
            </Col>
            <Col md={RIGHT_WIDTH}>
              Default rates
              <FieldInfoText>
                Charge this forwarding rate to C-number, unless a custom A number rate exists
              </FieldInfoText>
              <FormikDropdownField name="call_forwarding_cost_structure_variant"
                                   formik_props={formik_props}
                                   empty_selection_label="No call forwarding rate"
                                   placeholder="Select outgoing call forwarding rate table"
                                   options={call_forwarding_cost_structure_variant_options} />
              <Separator variant="h20" />

              { renderAbCallForwardingCosts() }
            </Col>
          </Row>

          <Row>
            <Col md={LEFT_WIDTH} css={section_name_style}>
              Free forwarding
            </Col>
            <Col md={RIGHT_WIDTH}>
              { renderFreeForwardingMinutes() }
            </Col>
          </Row>

          <Row>
            <Col md={LEFT_WIDTH} css={section_name_style}>
              Voicemail rates
            </Col>
            <Col md={RIGHT_WIDTH}>
              { renderVoicemailCosts() }
            </Col>
          </Row>


        </div>
    )
}

const section_name_style = css`
font-weight: 500;
font-size: 18px;
`

    

