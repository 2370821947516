/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { head, get, map, keys } from 'lodash'
import { adminUserList } from '../actions/admin_user'
import { Formik, Form, FieldArray, Field } from 'formik'
import Loading from '../../components/Loading'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import { FormikInputField } from '../../components/form/InputField'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { FormikRadioGroupField } from '../../components/form/RadioGroupField'
import { adminCustomerList } from '../actions/admin_customer'
import { adminCustomerPhoneNumberList } from '../actions/admin_customer_phone_number'
import Card from '../../components/layout/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Form as BootstrapForm } from 'react-bootstrap'
import * as Yup from 'yup'
import {showSuccess, showError} from '../../actions/Error'
import Button from 'react-bootstrap/Button'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { handleSubmitResult } from '../../actions/form'

const BAUD_RATE_OPTIONS = [ {value: 4800, label: '4800'},
                            {value: 9600, label: '9600'},
                            {value: 14400, label: '14400'} ]

const CALLERID_TRANSFER_OPTIONS = [ {value: '', label: 'Default'},
                                    {value: 'ocid', label: 'OCID'},
                                    {value: 'xoip', label: 'XOIP'} ]

const validationSchema = Yup.object().shape({
})


class AdminCustomerPhoneNumbers extends Component {

    constructor(props) {
        super(props)
        this.state = {active_tab: 'voice'}
    }
    
    componentDidMount() {
        const { dispatch, customer_id, customer_phone_number_id } = this.props
        dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
        dispatch(adminCustomerPhoneNumberList.updateListFilter({customer_id: customer_id}))
        dispatch(adminCustomerPhoneNumberList.ensureObjectLoaded(customer_phone_number_id))
    }

    componentDidUpdate(prev_props) {
        const { dispatch, customer_id, customer_phone_number_id, customer_phone_number_filter } = this.props
        if ( prev_props.customer_id != customer_id && customer_phone_number_filter.customer_id != customer_id ) {
            dispatch(adminCustomerPhoneNumberList.updateListFilter({customer_id: customer_id}))
        }
        dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
        dispatch(adminCustomerPhoneNumberList.ensureObjectLoaded(customer_phone_number_id))
    }

    onSave = (values, formik_funcs) => {
        const { onSubmit, dispatch, customer_phone_number_id } = this.props

        const on_ok = function(json) {
            dispatch(adminCustomerPhoneNumberList.invalidateList())
            showSuccess("Saved", "Phone number saved")
        }
        if ( customer_phone_number_id ) {
            values.id = customer_phone_number_id
            return dispatch(adminCustomerPhoneNumberList.saveObject(values))
                .then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminCustomerPhoneNumberList.saveNewObject(values))
                .then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }
    
    switchTab = (new_tab) => {
        this.setState({active_tab: new_tab})
    }

    renderNav() {
        const { customer_id, customer_phone_number_id } = this.props
        const { active_tab } = this.state
        return (
            <Navbar expand="lg" css={main} activekey={active_tab}>
              <Nav variant="pills">
                <Nav.Item key="voice">
                  <Nav.Link active={active_tab==='voice'} onClick={() => this.switchTab('voice')}>Voice</Nav.Link>
                </Nav.Item>
                <Nav.Item key="fax">
                  <Nav.Link active={active_tab==='fax'} onClick={() => this.switchTab('fax')}>Fax</Nav.Link>
                </Nav.Item>
              </Nav>
            </Navbar>
        )
    }

    renderVoice(formik_props) {
        const { cpn } = this.props
        return (
            <Container>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      Created
                    </Col>
                    <Col>
                      <Timestamp value={cpn.created_at} format="datetime" />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      Updated
                    </Col>
                    <Col>
                      <Timestamp value={cpn.modified_at} format="datetime" />
                    </Col>
                  </Row>
                </Col>
                
                <Col>
                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Caller Id</BootstrapForm.Label>

                    <FormikRadioGroupField
                        formik_props={formik_props}
                        name="callerid_transfer"
                        options={CALLERID_TRANSFER_OPTIONS}
                    />
                  </BootstrapForm.Group>
                </Col>

                <Col>
                  <BootstrapForm.Group>
                    <FormikCheckboxField
                        name="voice_mail_instruct"
                        label="Instruct"
                    />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group>
                    <FormikCheckboxField
                        name="voice_mail_review"
                        label="Review"
                    />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group>
                    <FormikCheckboxField
                        name="voice_mail_envelope"
                        label="Envelope"
                    />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Master Fix</BootstrapForm.Label>
                    <FormikInputField
                        name="voice_mail_master_fix"
                        type="text"
                        placeholder="Master fix"
                    />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Master Pin</BootstrapForm.Label>
                    <FormikInputField
                        name="voice_mail_master_pin"
                        type="text"
                        placeholder="Master Pin"
                    />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Enable greeting message</BootstrapForm.Label>
                    <FormikInputField
                        name="voice_mail_greet_msg_enabled"
                        type="text"
                        placeholder="Greeting message"
                    />
                  </BootstrapForm.Group>
                  

                  
                </Col>
              </Row>
              <Row>
                <Button type="submit">Save</Button>
              </Row>
            </Container>
        )
    }

    renderFax(formik_props) {
        const { cpn } = this.props
        return (
            <Container>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      Created
                    </Col>
                    <Col>
                      <Timestamp value={cpn.created_at} format="datetime" />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      Updated
                    </Col>
                    <Col>
                      <Timestamp value={cpn.modified_at} format="datetime" />
                    </Col>
                  </Row>
                </Col>
                
                <Col>
                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Baud Rate</BootstrapForm.Label>
                    <FormikRadioGroupField
                        formik_props={formik_props}
                        name="fax_baud"
                        placeholder="Baud"
                        options={BAUD_RATE_OPTIONS}
                    />
                  </BootstrapForm.Group>
                </Col>
              </Row>
              <Row>
                <Button type="submit">Save</Button>
              </Row>
            </Container>
        )
    }

    render() {
        const { initial_values, is_loading, is_busy, customer, customer_id, customer_phone_number, customer_phone_number_id } = this.props
        const { active_tab } = this.state
        const that = this
        const title = `${customer_phone_number && customer_phone_number.number} - ${customer && customer.user && customer.user.display_name}`

        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'customers', label: 'Customers', url: '/admin/customers'},
                                           {name: 'customer', label: get(customer, ["user", "display_name"], ''), url: `/admin/customer/${customer_id}`},
                                           {name: 'customer_phone_number', label: get(customer_phone_number, ["number"], ''), url: `/admin/customer/${customer_id}/phone_number/${customer_phone_number_id}`}
            ]}>
              <div>
                { is_busy && <BusyMask /> }
                { is_loading && <Loading /> }
                <h1>{title}</h1>
                { this.renderNav() }

                { customer_phone_number && customer_phone_number.id && 
                  
                  <Formik
                      initialValues={initial_values}
                      onSubmit={that.onSave}
                      enableReinitialize={true}
                      validationSchema={validationSchema}
                      >
                    {props => {
                         const { values } = props
                         return (
                             <Form>
                               <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                               <Row>
                                 <Col>
                                   <Card>
                                     { is_loading && <Loading /> }
                                     { active_tab === 'voice' && that.renderVoice(props) }
                                     { active_tab === 'fax' && that.renderFax(props) }
                                   </Card>
                                 </Col>
                               </Row>
                             </Form>
                         )}
                    }
                  </Formik>
                }
              </div>
            </AdminMainLayout>
        )
    }
    
}

function mapStateToProps(state, props) {
    const customer_id = get(props, ["match", "params", "customer_id"], null)
    const customer_phone_number_id = get(props, ["match", "params", "customer_phone_number_id"], null)
    const customer = adminCustomerList.getObject(customer_id)
    const customer_phone_number = adminCustomerPhoneNumberList.getObject(customer_phone_number_id)
    return {
        customer_id,
        customer,
        customer_phone_number,
        cpn: customer_phone_number, // for convenience
        customer_phone_number_id,
        is_loading: adminCustomerPhoneNumberList.isLoading() || adminCustomerList.isLoading(),
        is_busy: adminCustomerPhoneNumberList.getIsSavingObject(),
        initial_values: customer_phone_number,
    }
}

export default connect(mapStateToProps)(AdminCustomerPhoneNumbers)

const main = css`
background-color: ${theme.colors.card_background};
`
