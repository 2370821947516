/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { jsx, css } from '@emotion/react'
import { Table, Row, Col } from 'react-bootstrap'
import {showSuccess, showError} from '../../actions/Error'
import { ButtonBar } from '../../components/layout/ButtonBar'
import { head, get } from 'lodash'
import { confirmModal } from '../../actions/ui'
import { Link } from 'react-router-dom'
import AdminMainLayout from './AdminMainLayout'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { FormikInputField } from '../../components/form/InputField'
import { Separator } from '../../components/layout/Separator'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import CurrencyValue from '../../components/CurrencyValue'
import Timestamp from '../../components/Timestamp'
import { adminFaxCostStructureList } from '../actions/admin_fax_cost_structure'
import { adminFaxCostStructureVariantList } from '../actions/admin_fax_cost_structure_variant'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { Button, Container } from 'react-bootstrap'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFilter from './AdminTableFilter'

class AdminFaxCostStructures extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminFaxCostStructureList.fetchListIfNeeded())
        dispatch(adminFaxCostStructureVariantList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(adminFaxCostStructureList.fetchListIfNeeded())
    }

    onEditFaxCostStructure = (evt, item) => {
        const { history } = this.props
        history.push({
            pathname: '/admin/fax_cost_structure/'+item.id,
        })
    }

    onDeleteFaxCostStructure = (item) => {
        const { dispatch, history } = this.props
        const on_ok = function(json) {
            showSuccess("Deleted", "Cost has been deleted")
        }

        const onConfirmed = () => {
            return dispatch(adminFaxCostStructureList.deleteObject(item.id)).then(on_ok)
        }
        
        const text = <span>Are you sure you want to delete cost for {item.country_name} (variant {item.variant_name}) ?</span>
        return dispatch(confirmModal({text:text,
                                      onConfirmed: onConfirmed}))
        
    }

    onAddFaxCostStructure = () => {
        const { history } = this.props
        history.push('/admin/fax_cost_structure')
    }
    
    filterFaxCostStructures = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminFaxCostStructureList.updateListFilter(filter_values))
    }


    getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'cost_per_page_excluding_vat_euros':
                return (
                    <CurrencyValue value={item.cost_per_page_excluding_vat_euros} />
                )
            case 'action':
                return (
                    <ButtonBar>
                      <InlineIcon icon_name="eye" onClick={(evt) => this.onEditFaxCostStructure(evt, item)} />
                      <Separator variant="w5" />
                      <InlineIcon icon_name="delete" onClick={(evt) => this.onDeleteFaxCostStructure(item)} />
                    </ButtonBar>
                )
                
            default:
                return undefined
        }
    }

    renderFilter = (formik_props) => {
        const { fax_cost_structure_variant_options } = this.props
        return (
            <Col md="12">
              <div css={filter_field_row_style}>

                <FormikInputField name="any_field"
                                  formik_props={formik_props}
                                  placeholder="Prefix, country" />
                <Separator variant="w5" />
                
                <FormikDropdownField name="variant"
                                     formik_props={formik_props}
                                     options={fax_cost_structure_variant_options}
                                     empty_selection_label="Any variant"
                                     placeholder="Variant" />
                <Separator variant="w5" />
                
                <Separator variant="w20" />
                  <Button variant="outline-primary" onClick={ this.onAddFaxCostStructure }>
                    Add fax cost
                  </Button>
              </div>
            </Col>
        )
    }

    render() {
        const { is_loading, headers, fax_cost_structures } = this.props
        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'fax_cost_structures', label: 'Fax Costs', url: '/admin/fax_cost_structures'}]}>
              <Container fluid>
                <AdminTableHeader title="Fax cost structures">
                  <AdminTableFilter updateOnChange={ this.filterFaxCostStructures }
                                    form="ADMIN_FAX_COST_STRUCTURES_FILTER_FORM"
                                    renderFilter={ this.renderFilter }
                  />
                </AdminTableHeader>
                <CommonTable
                    is_loading={ is_loading }
                    empty_message={`There are no fax costs.`}
                    headers={ headers }
                    items={ fax_cost_structures }
                    item_list={adminFaxCostStructureList}
                    getCellValue={ this.getCellValue }
                />
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const fax_cost_structures = adminFaxCostStructureList.getVisibleObjects()
    return {
        fax_cost_structures,
        is_loading: adminFaxCostStructureList.isLoading(),
        headers: {
            destination_phone_number_prefix: {name: 'Phone number prefix'},
            country_name: {name: 'Country'},
            cost_per_page_excluding_vat_euros: { name: 'Cost per page (exVAT)' },
            variant_name: { name: 'Cost variant' },
            action: { name: '', column_size: 1 },
        },
        fax_cost_structure_variant_options: adminFaxCostStructureVariantList.getAsOptions()
    }
}

export default connect(mapStateToProps)(AdminFaxCostStructures)

const filter_field_row_style = css`
display: flex;
`
