/** @jsxImportSource @emotion/react */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { Trans } from 'react-i18next'

export const Pluralize = ({ singular, plural, count }) => {

    if ( count === 1 ) {
        return <span>{count}&nbsp;<Trans>{singular}</Trans></span>
    } else {
        return <span>{count}&nbsp;<Trans>{plural || (singular + "s")}</Trans></span>
    }
}
