/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import { default_theme as theme } from '../../emotion/theme'
import { head, get, map, keys, split, size, filter } from 'lodash'
import { adminVoxboneStatusList } from '../actions/admin_voxbone_status'
import {showSuccess, showError} from '../../actions/Error'
import { ButtonBar } from '../../components/layout/ButtonBar'
import { Separator } from '../../components/layout/Separator'
import Card from '../../components/layout/Card'
import Loading from '../../components/Loading'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import { BlueButton } from '../../components/layout/BlueButton'
import { Col, Row, Container, Navbar, Nav } from 'react-bootstrap'

const LEFT_WIDTH = "4"
const RIGHT_WIDTH = "8"

class AdminVoxboneStatus extends Component {

    componentDidMount() {
        const { phone_number_id, dispatch } = this.props
        if ( phone_number_id ) {
            dispatch(adminVoxboneStatusList.filterOnPhoneNumber(phone_number_id))
        }
    }

    componentDidUpdate() {
        const { phone_number_id, dispatch, voxbone_status_filter } = this.props
        if ( phone_number_id ) {
            dispatch(adminVoxboneStatusList.filterOnPhoneNumber(phone_number_id))
        }
    }
    
    onCheckStatus = () => {
        const { phone_number_id, dispatch, voxbone_status } = this.props

        const on_done = (voxbone_status) => {
            if ( voxbone_status ) {
                dispatch(adminVoxboneStatusList.invalidateObject(get(voxbone_status, "id")))
            }
            dispatch(adminVoxboneStatusList.invalidateList())
            dispatch(adminVoxboneStatusList.fetchListIfNeeded()).then(() => showSuccess("Status retrieved"))
        }
        
        dispatch(adminVoxboneStatusList.checkStatus(phone_number_id)).then(on_done)
    }

    onForwardFaxesToThisServer = () => {

        if ( ! window.confirm("Are you sure you want to send all faxes arriving at this number to this server?") ) {
            return
        }
        
        const { phone_number_id, dispatch, voxbone_status } = this.props

        const on_done = (voxbone_status) => {
            if ( voxbone_status ) {
                dispatch(adminVoxboneStatusList.invalidateObject(get(voxbone_status, "id")))
            }
            dispatch(adminVoxboneStatusList.invalidateList())
            dispatch(adminVoxboneStatusList.fetchListIfNeeded()).then(() => showSuccess("Fax forwarding updated"))
        }
        
        dispatch(adminVoxboneStatusList.forwardToThisServer(phone_number_id)).then(on_done)
    }

    renderStatus() {
        const { is_saving, is_ready, is_loading, voxbone_status } = this.props
        return (
            <div>
              { is_saving && <Loading title={"Fetching..."} /> }
              { is_ready && 
                <div>
                  <div>
                    <Row>
                      <Col md={LEFT_WIDTH}>
                        Status
                      </Col>
                      <Col>
                        { voxbone_status.is_active_for_phone_number &&
                          <div>IS part of voxbone</div>
                        }
                        { !voxbone_status.is_active_for_phone_number &&
                          <div>NOT part of voxbone</div>
                        }
                      </Col>
                      <Separator variant="h10" />
                    </Row>

                    <Row>
                      <Col md={LEFT_WIDTH}>
                        Status last refreshed at
                      </Col>
                      <Col md={RIGHT_WIDTH}>
                        <div>
                          <Timestamp value={voxbone_status.modified_at} />
                        </div>
                      </Col>
                      <Separator variant="h10" />
                    </Row>

                    { voxbone_status.is_active_for_phone_number &&
                      <Row>
                        <Col md={LEFT_WIDTH}>
                          Fax forwarding
                        </Col>
                        <Col md={RIGHT_WIDTH}>
                          { get(voxbone_status, "is_fax_forwarding_to_this_server") &&
                            <div>
                              Faxes are forwarding to this server
                            </div>
                          }
                          { !get(voxbone_status, "is_fax_forwarding_to_this_server") &&
                            <div>
                              { get(voxbone_status, "fax_forwarding_url") &&
                                <div>
                                  Forwarding to a different server
                                </div>
                              }
                              { ! get(voxbone_status, "fax_forwarding_url") &&
                                <div>
                                  No forwarding
                                </div>
                              }
                              <br/>
                              { voxbone_status.is_active_for_phone_number && 
                                <BlueButton onClick={this.onForwardFaxesToThisServer} auto_disable={false}>
                                  Forward faxes to this server
                                </BlueButton>
                              }
                            </div>
                          }
                        </Col>
                        <Separator variant="h10" />
                      </Row>
                    }

                    { voxbone_status.is_active_for_phone_number && get(voxbone_status, "fax_forwarding_url") && 
                      <Row>
                        <Col md={LEFT_WIDTH}>
                          Fax forwarding url
                        </Col>
                        <Col md={RIGHT_WIDTH}>
                          <div css={fax_forwarding_url_style}>
                            {get(voxbone_status, "fax_forwarding_url")}
                          </div>
                        </Col>
                        <Separator variant="h10" />
                      </Row>
                    }

                    { voxbone_status.raw_status_check_result && 
                      <Row>
                        <Col md={LEFT_WIDTH}>
                          Low level status result
                        </Col>
                        <Col md={RIGHT_WIDTH}>
                          { voxbone_status.raw_status_check_result && 
                            <div css={raw_status_check_result_style}>
                              <pre>
                                {voxbone_status.raw_status_check_result}
                              </pre>
                            </div>
                          }
                        </Col>
                        <Separator variant="h10" />
                      </Row>
                    }

                    
                  </div>
                </div>
              }
            </div>
        )
    }

    renderUnknownStatus() {
        return (
            <div>
                <div>
                  Unknown voxbone status
                  <br/>
                  <BlueButton onClick={this.onCheckStatus} auto_disable={false}>
                    Check Voxbone
                  </BlueButton>
                </div>
            </div>

        )
    }
    
    render() {
        const { voxbone_status, is_loading, is_ready } = this.props

        return (
            <Card variant="white_wide_padding" title="Voxbone Provider Widget">
              <WrappingBusyMask is_loading={is_loading || !is_ready}>
                <Separator variant="h20" />
                { ! voxbone_status && this.renderUnknownStatus() }
                
                { voxbone_status &&
                  <div>
                    <BlueButton onClick={this.onCheckStatus} auto_disable={false}>
                      Refresh Voxbone Status
                    </BlueButton>
                    <Separator variant="h20" />
                    { this.renderStatus() }
                  </div>
                }
              </WrappingBusyMask>
            </Card>
        )
    }
}

function mapStateToProps(state, props) {
    const { phone_number_id } = props
    const voxbone_statuses_list = adminVoxboneStatusList.getAllObjects()
    const voxbone_status = head(filter(voxbone_statuses_list, (x) => (x.phone_number === phone_number_id)))

    return {
        phone_number_id,
        voxbone_status,
        voxbone_status_filter: adminVoxboneStatusList.getFilter(),
        is_ready: phone_number_id && get(adminVoxboneStatusList.getFilter(), "phone_numbers") && adminVoxboneStatusList.isReady(),
        is_loading: adminVoxboneStatusList.isLoading(),
        is_saving: adminVoxboneStatusList.getIsSavingObject()
    }
}

export default connect(mapStateToProps)(AdminVoxboneStatus)

const breadcrumb_item = css`
display: flex;
align-items: center;
`

const fax_forwarding_url_style = css`
font-size: 12px;
`

const raw_status_check_result_style = css`
font-size: 12px;
`
