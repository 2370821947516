/** @jsxImportSource @emotion/react */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../emotion/theme'
import Card from './layout/Card'
import { BlueButton } from './layout/BlueButton'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const TryXOIPCard = ({
  hasActiveFaxSubscription,
  hasActiveVoiceSubscription,
  onAddFaxSubscription,
  onAddVoiceSubscription,
}) => {
  const history = useHistory()

  return (
    <>
      {!hasActiveVoiceSubscription && (
        <Card variant="white" with_padding_below={false}>
          <div css={card_style}>
            <div>
              <div css={card_header_style}>
                <Trans i18nKey={'try_xoip_card_voice_title'}>
                  Try XOIP Voice
                </Trans>
              </div>
              <div>
                <Trans i18nKey={'try_xoip_card_voice_text'}>
                  Online phone numbers for flexible forwarding. You control what
                  happens to incoming calls.
                </Trans>
              </div>
            </div>
            <BlueButton onClick={onAddVoiceSubscription}>
              <Trans>Get a Voice Number</Trans>
            </BlueButton>
          </div>
        </Card>
      )}
      {!hasActiveFaxSubscription && (
        <Card variant="white" with_padding_below={false}>
          <div css={card_style}>
            <div>
              <div css={card_header_style}>
                <Trans i18nKey={'try_xoip_card_fax_title'}>Try XOIP Fax</Trans>
              </div>
              <div>
                <Trans i18nKey={'try_xoip_card_fax_text'}>
                  Send and receive faxes as PDF files. Get your own online fax
                  number.
                </Trans>
              </div>
            </div>

            <BlueButton onClick={onAddFaxSubscription}>
              <Trans>Get a Fax Subscription</Trans>
            </BlueButton>
          </div>
        </Card>
      )}
    </>
  )
}

export default TryXOIPCard

const card_style = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  height: 100%;
`

const card_header_style = css`
  font-size: 16px;
  font-weight: 600;
`

const button_bar_style = css`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  & > button {
    min-width: 170px;
    flex: 1;
  }
`
