import { ItemList } from '../orm'
import { head } from 'lodash'

class AggregatedTransactionList extends ItemList {
    getEntityKey() {
        return "transaction/totals"
    }

    invalidateTotals() {
        const totals = this.getTotals()
        if ( totals ) {
            return this.invalidateObject(totals.id)
        }
    }

    ensureTotalsLoaded() {
        return this.fetchListIfNeeded()
    }
    
    getTotals() {
        return head(this.getVisibleObjects())
    }
    
}

export const aggregatedTransactions = new AggregatedTransactionList("aggregated_transaction__default")
