/** @jsxImportSource @emotion/react */
import React from 'react'
import { useHistory } from 'react-router-dom/'
import { jsx, css, Global } from '@emotion/react'
import { default_theme as theme } from '../emotion/theme'
import { Modal } from 'react-bootstrap'
import { Trans } from 'react-i18next'
import { globalSettingsList } from '../actions/settings'
import { customerList } from '../actions/customer'
import ProfileMenuHeader from './ProfileMenuHeader'
import ProfileMenuContent from './ProfileMenuContent'
import CreditLabelAndButton from './CreditLabelAndButton'
import { BlueButton } from './layout/BlueButton'

const ProfileMenuMobile = ({ onHideContextMenu }) => {
  const history = useHistory()
  const customer = customerList.getCustomer()
  const has_fax_in = customer.has_active_fax_in_subscription

  const onSendFax = () => {
    history.push('/send_a_fax')
  }

  return (
    <Modal show={true} size="xl" onHide={onHideContextMenu}>
      <Modal.Header css={context_menu_modal_header} closeButton>
        <ProfileMenuHeader />
      </Modal.Header>

      <Modal.Body css={context_menu_modal_body}>
        <div css={credit_label}>
          <div>XOIP credit</div>
          <CreditLabelAndButton />
        </div>

        <div css={profile_menu_style}>
          <ProfileMenuContent />
        </div>
        {!has_fax_in && (
          <div>
            <BlueButton
              autoDisable={false}
              size={'xs'}
              onClick={() => onSendFax()}
            >
              <Trans i18nKey="send_a_fax_button">Send a Fax</Trans>
            </BlueButton>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default ProfileMenuMobile

const context_menu_modal_body = css`
  display: flex;
  flex-direction: column;
  padding: 0px;

  & > div {
    padding: 15px 25px;
    border-bottom: 1px solid ${theme.colors.light_middle_grey};
  }
`
const context_menu_modal_header = css`
  display: flex;
  align-items: start;
  padding-left: 25px;
  padding-right: 25px;

  & > button.btn-close {
    margin-top: 0;
  }
`
const credit_label = css`
  display: flex;
  align-items: center;
  gap: 16px;
  // padding: 15px 25px;
  border-bottom: 1px solid ${theme.colors.light_middle_grey};
`
const context_menu_section = css`
  padding: 15px 25px;
  border-bottom: 1px solid ${theme.colors.light_middle_grey};
`
const profile_menu_style = css`
  // padding-left: 25px;
`
