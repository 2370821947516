import { ItemList } from '../orm'
import { v4 as uuidv4 } from 'uuid'

class ProductCostCalculator extends ItemList {
    getEntityKey() {
        return "product_cost_calculator/calculate"
    }

    calculatePaymentCost(values) {
        const id = uuidv4()
        values.calculation_id = id
        return this.saveNewObject(values)
    }

}

export const productCostCalculator = new ProductCostCalculator("product_cost_calculator__default")
