/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { default_theme as theme } from '../../emotion/theme'
import moment from 'moment'
import { head, get, map, keys, split, size, compact } from 'lodash'
import { ButtonBar } from '../../components/layout/ButtonBar'
import { CardHeader } from '../../components/layout/CardHeader'
import FieldInfoText from '../../components/layout/FieldInfoText'
import CardInfoText from '../../components/layout/CardInfoText'
import { FormikFileSetField } from '../../components/form/FileSetField'
import { LanguageFlag } from '../../components/LanguageFlag'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { Separator } from '../../components/layout/Separator'
import CurrencyValue from '../../components/CurrencyValue'
import { Formik, Form, FieldArray, Field } from 'formik'
import Loading from '../../components/Loading'
import { getBase64 } from '../../actions/file_info'
import { FormikTextarea } from '../../components/form/TextareaField'
import { FormikInputField } from '../../components/form/InputField'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import AdminMainLayout from './AdminMainLayout'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import Card from '../../components/layout/Card'
import { Col, Row, Container, Navbar, Nav } from 'react-bootstrap'
import { Form as BootstrapForm } from 'react-bootstrap'
import * as Yup from 'yup'
import {showSuccess, showError} from '../../actions/Error'
import { BlueButton } from '../../components/layout/BlueButton'
import { handleSubmitResult } from '../../actions/form'
import { adminIncomingFaxByEmailQueueList } from '../actions/admin_incoming_fax_by_email_queue'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { asteriskAuthTokenList } from '../actions/asterisk_auth_token'
import { Error } from '../../components/layout/Error'

const validationSchema = Yup.object().shape({
    'from_email': Yup.string().required("Required"),
    'legacy_to_address': Yup.string().required("Required"),
    'subject': Yup.string().required("Required")
})

class AdminIncomingLegacyForwardedEmailTest extends Component {

    constructor(props) {
        super(props)
        this.state = {sending: false, res: null}
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(asteriskAuthTokenList.updateListFilter({server_name: 'asterisk'}))
        dispatch(asteriskAuthTokenList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(asteriskAuthTokenList.fetchListIfNeeded())
    }

    onSendEmail = (values, formik_funcs) => {
        const { history, dispatch, customer_id } = this.props
        const that = this
        this.setState({sending: true})
        const on_ok = function(json) {
            that.setState({sending: false, res: JSON.stringify(json)})
            showSuccess("Saved", "Email forwarded")
            history.push('/admin/incoming_fax_emails')
        }

        values['filenames'] = compact([get(values, 'filename_1'), get(values, 'filename_2'), get(values, 'filename_3')])
        values['contents_as_base64'] = compact([get(values, 'content_as_base64_1'), get(values, 'content_as_base64_2'), get(values, 'content_as_base64_3')])
        
        asteriskAuthTokenList.sendLegacyForwardedEmail(values).then(on_ok)
    }

    onSelectedFile = (el, file_index, formik_props) => {
        const file = el.currentTarget.files[0]
        getBase64(file).then(function(data) {
            data = data.slice("data:application/pdf;base64,".length)
            formik_props.setFieldValue(`content_as_base64_${file_index}`, data)
        })
    }

    render() {
        const { dispatch, initial_values, is_ready } = this.props
        const { sending, res } = this.state
        const that = this
        
        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'incoming_legacy_forwarded_email_test', label: 'Incoming Legacy Forwarded Email Test', url: '/admin/incoming_legacy_forwarded_email_test'}]}>
              <Container fluid>
                <Row>
                  <Col md="8">
                    <CardHeader title={`Simulate an incoming email`} />
                    <CardInfoText>
                      This page can be used to test the api which forwards emails to XOIP which represents faxes to be sent, that were sent to legacy by mistake, and
                      have been forwarded by legacy to the new email server.
                      <br/>
                      It completely bypasses the email servers, but in every other respect acts as if an email was received at the XOIP inbox.
                    </CardInfoText>
                    <Separator variant="h20" />

                    { res && 
                      <div css={res_style}>
                        {res}
                      </div>
                    }
                    
                    <WrappingBusyMask is_loading={!is_ready}>

                      <Formik
                        initialValues={initial_values}
                        onSubmit={that.onSendEmail}
                        enableReinitialize={true}
                        validationSchema={validationSchema}
                    >
                        {formik_props => {
                            const { values } = formik_props
                            return (
                                <Form>
                                  <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                                  <Row>
                                    <Col md="8">
                                      <BootstrapForm.Group>
                                        <BootstrapForm.Label>From email address</BootstrapForm.Label>
                                        <br/>
                                        <FormikInputField name="from_email" placeholder="From address" />
                                      </BootstrapForm.Group>

                                      <BootstrapForm.Group>
                                        <BootstrapForm.Label>Destination email address</BootstrapForm.Label>
                                        <FieldInfoText>
                                          Use the original legacy from email address here. It will be unpacked and reformatted to be correct for the new server. (Everything after the @ is irrelevant)
                                        </FieldInfoText>
                                        <br/>
                                        <FormikInputField name="legacy_to_address" placeholder="To address" />
                                      </BootstrapForm.Group>

                                      <BootstrapForm.Group>
                                        <BootstrapForm.Label>Subject</BootstrapForm.Label>
                                        <br/>
                                        <FormikInputField name="subject" placeholder="Email subject" />
                                      </BootstrapForm.Group>

                                      <BootstrapForm.Group>
                                        <Row>
                                          <Col>
                                            <BootstrapForm.Label>Fax file 1</BootstrapForm.Label>
                                            <br/>
                                            { formik_props.errors.raw_file_1 &&
                                              <Error>
                                                { formik_props.errors.raw_file_1}
                                              </Error>
                                            }
                                            <input type="file" name="raw_file_1" onChange={(el) => that.onSelectedFile(el, 1, formik_props)} />
                                          </Col>
                                          <Col>
                                            <BootstrapForm.Group>
                                              <BootstrapForm.Label>Filename 1</BootstrapForm.Label>
                                              <br/>
                                              <FormikInputField name="filename_1" placeholder="Filename" />
                                            </BootstrapForm.Group>
                                          </Col>
                                        </Row>
                                      </BootstrapForm.Group>

                                      <BootstrapForm.Group>
                                        <Row>
                                          <Col>
                                            <BootstrapForm.Label>Fax file 2 (optional)</BootstrapForm.Label>
                                            <br/>
                                            { formik_props.errors.raw_file_2 &&
                                              <Error>
                                                { formik_props.errors.raw_file_2}
                                              </Error>
                                            }
                                            <input type="file" name="raw_file_2" onChange={(el) => that.onSelectedFile(el, 2, formik_props)} />
                                          </Col>
                                          <Col>
                                            <BootstrapForm.Group>
                                              <BootstrapForm.Label>Filename 2</BootstrapForm.Label>
                                              <br/>
                                              <FormikInputField name="filename_2" placeholder="Filename" />
                                            </BootstrapForm.Group>
                                          </Col>
                                        </Row>
                                      </BootstrapForm.Group>

                                      <BootstrapForm.Group>
                                        <Row>
                                          <Col>
                                            <BootstrapForm.Label>Fax file 3 (optional)</BootstrapForm.Label>
                                            <br/>
                                            { formik_props.errors.raw_file_3 &&
                                              <Error>
                                                { formik_props.errors.raw_file_3}
                                              </Error>
                                            }
                                            <input type="file" name="raw_file_3" onChange={(el) => that.onSelectedFile(el, 3, formik_props)} />
                                          </Col>
                                          <Col>
                                            <BootstrapForm.Group>
                                              <BootstrapForm.Label>Filename 3</BootstrapForm.Label>
                                              <br/>
                                              <FormikInputField name="filename_3" placeholder="Filename" />
                                            </BootstrapForm.Group>
                                          </Col>
                                        </Row>
                                      </BootstrapForm.Group>
                                      
                                      <Row>
                                        <Col>
                                          <ButtonBar>
                                            <BlueButton type="submit">
                                              Send
                                            </BlueButton>
                                          </ButtonBar>
                                        </Col>
                                      </Row>
                                      
                                    </Col>

                                    <Col md="1">&nbsp;</Col>
                                    <Col md="3">

                                      <Row>  
                                        
                                      </Row>
                                      
                                      <Row>  
                                        <Col>
                                          <BootstrapForm.Group>
                                            <BootstrapForm.Label>Content type</BootstrapForm.Label>
                                            <br/>
                                            <FormikInputField name="content_type" placeholder="Content type" />
                                          </BootstrapForm.Group>
                                        </Col>
                                      </Row>

                                      <Row>  
                                        <Col>
                                          <BootstrapForm.Group>
                                            <BootstrapForm.Label>Asterisk server name</BootstrapForm.Label>
                                            <br/>
                                            <FormikInputField name="server_name" placeholder="Asterisk server name" />
                                          </BootstrapForm.Group>
                                        </Col>
                                      </Row>

                                      <Row>  
                                        <Col>
                                          <BootstrapForm.Group>
                                            <BootstrapForm.Label>Asterisk auth token</BootstrapForm.Label>
                                            <br/>
                                            <FormikInputField name="auth_token" placeholder="Asterisk auth token" />
                                          </BootstrapForm.Group>
                                        </Col>
                                      </Row>
                                      
                                    </Col>
                                    
                                  </Row>
                                  
                                </Form>
                            )
                        }}
                      </Formik>
                    </WrappingBusyMask>
                  </Col>
                </Row>
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {

    const auth_token = head(asteriskAuthTokenList.getVisibleObjects())
    
    return {
        is_saving: adminIncomingFaxByEmailQueueList.getIsSavingObject(),
        initial_values: {
            legacy_to_address: '+0031555666@legacy_inbox.xoip.com',
            server_name: get(auth_token, "server_name"),
            filename_1: 'somefax.pdf',
            auth_token: get(auth_token, "token"),
            received_at: moment().format(),
            content_type: "application/pdf"
        },
        is_ready: asteriskAuthTokenList.isReady()
        
    }
}

export default withRouter(connect(mapStateToProps)(AdminIncomingLegacyForwardedEmailTest))

const breadcrumb_item = css`
display: flex;
align-items: center;
`

const error_style = css`
  color: ${theme.colors.error}
}
`

const res_style = css`
font-size: 12px;
border: 1px solid #efefef;
`
