/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx } from '@emotion/react'
import { head, get } from 'lodash'
import { adminUserList } from '../actions/admin_user'
import { Link } from 'react-router-dom'
import AdminMainLayout from './AdminMainLayout'
import { AdminQuickLink } from './AdminQuickLink'
import AdminTableFilter from './AdminTableFilter'
import AdminUserForm from './AdminUserForm'
import AdminTableHeader from './AdminTableHeader'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import { adminIncomingAsteriskFaxQueueList } from '../actions/admin_incoming_asterisk_faxes'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { Button, Container } from 'react-bootstrap'

class AdminIncomingAsteriskFaxQueue extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminIncomingAsteriskFaxQueueList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(adminIncomingAsteriskFaxQueueList.fetchListIfNeeded())
    }

    getCellValue = (header_key, item, index) => {
        const { customer_id } = this.props
        switch( header_key ) {
            case 'created_at':
                return <Timestamp value={item.created_at} format="datetime" />
            case 'status':
                return <AdminQuickLink name="fax_item" value={item.fax_item} label={item.status} />
            case 'raw_destination_phone_number':
                return <AdminQuickLink name="customer" value={item.to_customer} label={item.raw_destination_phone_number} />
            default:
                return undefined
        }
    }

    render() {
        const { is_loading, faxes, headers } = this.props
        return (
            <AdminMainLayout title=''
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'incoming_asterisk_fax_queue', label: 'Incoming asterisk faxes', url: '/admin/incoming_fax_emails'}]}>
              <Container fluid>

                <AdminTableHeader title="Incoming faxes from asterisk">
                  <AdminTableFilter item_list={adminIncomingAsteriskFaxQueueList} />
                </AdminTableHeader>
                
                <CommonTable
                    is_loading={ is_loading }
                    empty_message={`No faxes`}
                    headers={ headers }
                    items={ faxes }
                    item_list={adminIncomingAsteriskFaxQueueList}
                    getCellValue={ this.getCellValue }
                />
              </Container>
            </AdminMainLayout>
            
        )
    }
    
}

function mapStateToProps(state, props) {
    const faxes = adminIncomingAsteriskFaxQueueList.getVisibleObjects()
    return {
        is_loading: adminIncomingAsteriskFaxQueueList.isLoading(),
        faxes,
        headers: {
            created_at: { name: 'Received at' },
            asterisk_fax_status: { name: 'Asterisk status' },
            asterisk_fax_error: {name: 'Asterisk error' },
            raw_destination_phone_number: { name: 'To' },
            raw_source_phone_number: { name: 'From' },
            status: { name: 'Status' },
            last_error: { name: 'Error' },
            num_pages_received: { name: 'Pages received' }
        }
    }
}

export default connect(mapStateToProps)(AdminIncomingAsteriskFaxQueue)
