/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { head, get } from 'lodash'
import { Link } from 'react-router-dom'
import { CardHeader } from './layout/CardHeader'
import { Modal } from 'react-bootstrap'
import BusyMask from './BusyMask'
import InvoiceTable from './InvoiceTable'
import CurrencyValue from './CurrencyValue'
import Timestamp from './Timestamp'
import { invoiceList } from '../actions/invoice'
import Card from './layout/Card'
import CommonTable from './CommonTable'
import { InlineIcon } from './layout/InlineIcon'
import MainLayout from './MainLayout'
import BillingDetailsCard from './BillingDetailsCard'
import BillingContactCard from './BillingContactCard'
import { Row, Col, Container } from 'react-bootstrap'
import { Separator } from './layout/Separator'

class Invoices extends Component {
  render() {
    return (
      <MainLayout
        active_key="invoices"
        enable_breadlinks={true}
        breadcrumbs={[
          { name: 'home' },
          { name: 'invoices', label: 'Invoices', url: '/invoices' },
        ]}
      >
        <Container fluid>
          <Separator variant="h20" />

          <Row>
            <Col md={9}>
              <InvoiceTable itemList={invoiceList} />
            </Col>
            {false && (
              <Col md={3}>
                <Row>
                  <Col>
                    <BillingDetailsCard />
                  </Col>
                </Row>
                <Separator variant="h40" />
                <Row>
                  <Col>
                    <BillingContactCard />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Container>
      </MainLayout>
    )
  }
}

export default Invoices
