/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'
import { Separator } from '../layout/Separator'
import moment from 'moment'
import { map, get, size } from 'lodash'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { useField } from 'formik'
import FormError from './FormError'
import FormWarning from './FormWarning'
import TimezoneSelect from "react-timezone-select"

export const FormikTimezonePicker = ({ name, formik_props }) => {

    const value = get(formik_props.values, name)

    const onChange = function(new_timezone) {
        formik_props.setFieldValue(name, new_timezone.value)
    }
    
    return (
        <TimezoneSelect css={style}
                        value={value}
                        onChange={onChange}
        />
    )
}

const style = css`
width: 400px;
`

