import { ItemList } from '../orm'
import { join, size, map, compact, uniqBy, filter, includes } from 'lodash'
import { numberPrefixTypes } from './dropdown_options'
import { convertCityPrefixToNationalFormat } from './city'

class PhoneNumberPrefixList extends ItemList {
    getEntityKey() {
        return "phone_number_prefix"
    }

    getCountryOptions(local_filter) {
        const prefixes = this.getFilteredObjects(local_filter)
        return uniqBy(compact(map(prefixes, function(prefix) {
            return { value: prefix.country,
                     label: prefix.country_name }
        })), (x) => x.value)
    }
    
    getNumberTypeOptions(local_filter) {
        const number_types = this.getFilteredObjects(local_filter)
        const prefix_number_types = map(number_types, (prefix) => prefix.number_type)
        return uniqBy(filter(numberPrefixTypes.getAsOptions(), (number_type) => includes(prefix_number_types, number_type.value)), (x) => x.value)
    }

    getCityOptions(local_filter) {
        const prefixes = this.getFilteredObjects(local_filter)
        return filter(uniqBy(compact(map(prefixes, function(prefix) {
            let label = convertCityPrefixToNationalFormat(prefix.prefix) + " - " + prefix.city_name
            return {label: label, value: prefix.city}
        })), (x) => x.value), (x) => x.value)
    }

    getFilteredObjects(local_filter) {
        if ( ! local_filter ) {
            return this.getVisibleObjects()
        }

        return filter(this.getVisibleObjects(), (prefix) => ((!local_filter.country || prefix.country === local_filter.country) &&
                                                             (!local_filter.number_type || prefix.number_type === local_filter.number_type) &&
                                                             (!local_filter.city || prefix.city === local_filter.city)))
                      
    }
    
}

export const phoneNumberPrefixList = new PhoneNumberPrefixList("phone_number_prefix_default")
