/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get, filter, head } from 'lodash'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { StandaloneCheckboxField } from './form/CheckboxField'
import { customerList } from '../actions/customer'
import { Row, Col } from 'react-bootstrap'
import { default_theme as theme } from '../emotion/theme'
import FieldInfoText from './layout/FieldInfoText'
import { Separator } from './layout/Separator'
import { Trans, Translation } from 'react-i18next'
import {showSuccess, showError} from '../actions/Error'

const LEFT_WIDTH = 3
const RIGHT_WIDTH = 6

export const NotificationForm = ({customer, left_width=LEFT_WIDTH, ...props}) => {

    const dispatch = useDispatch()
    const itemList = props.itemList || customerList
    const is_saving = useSelector(() => itemList.getIsSavingObject())
    
    const onToggleInsufficientCreditNotification = () => {
        customer.notification_insufficient_credit = !customer.notification_insufficient_credit
        const on_ok = function(json) {
            showSuccess("Profile saved", "Notification updated")
        }
        return dispatch(itemList.saveObject(customer, on_ok))
    }
    
    return (
        <div>
          <Row>
            <Col md={left_width} css={section_name_style}>
              <Trans>Notifications</Trans>
            </Col>
            <Col md={RIGHT_WIDTH}>
              <StandaloneCheckboxField onChange={() => onToggleInsufficientCreditNotification()}
                                       label="Insufficient credit notification"
                                       is_saving={is_saving}
                                       variant="large"
                                       checked={customer.notification_insufficient_credit} />
            </Col>
          </Row>
          
        </div>
    )

}

const  section_name_style = css`
font-weight: 500;
font-size: 18px;
`

