/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useDispatch, useSelector } from 'react-redux'
import ReactTimeout from 'react-timeout'
import { Link } from 'react-router-dom'
import { LoadingIcon } from './LoadingIcon'
import { useHistory } from "react-router-dom"
import { map, get, size, forEach, includes } from 'lodash'
import { historyPushRetainQueryParams } from '../actions/ui'
import { TermsAndConditionsField } from './TermsAndConditionsField'
import BusyMask from './BusyMask'
import { Modal } from 'react-bootstrap'
import { isLoggedIn } from '../actions/auth'
import { Trans, Translation } from 'react-i18next'
import { globalSettingsList } from '../actions/settings'
import { Formik, Form } from 'formik'
import { FormikFatIconCheckboxField } from './form/CheckboxField'
import { BaseButton } from './layout/BaseButton'
import { handleSubmitResult } from '../actions/form'
import { clearAuthentication, setAuthToken } from '../actions/auth'
import { FormikDropdownField } from './form/Dropdown'
import { FormikGeneralFormErrors } from './form/GeneralFormErrors'
import { FormikInputField } from './form/InputField'
import { InputWithButton } from './layout/InputWithButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { BlueButton } from './layout/BlueButton'
import { BlueLinkButton } from './layout/BlueLinkButton'
import { InlineLink } from './layout/InlineLink'
import { WhiteButton } from './layout/WhiteButton'
import Timestamp from './Timestamp'
import { getBestLanguageCode } from '../actions/language'
import { default_theme as theme } from '../emotion/theme'
import { FormLabelValue } from './form/FormLabelValue'
import FieldInfoText from './layout/FieldInfoText'
import { Separator } from './layout/Separator'
import Card from './layout/Card'
import {showSuccess, showError} from '../actions/Error'
import { newCustomerSignupSendEmailVerificationRequest, newCustomerSignupVerifyEmailToken } from '../actions/new_customer_signup'
import { Row, Col, Container, Popover, OverlayTrigger, Button } from 'react-bootstrap'
import { subscriptionList } from '../actions/customer_subscription'
import * as Yup from 'yup'

const TIMER_CODE_SENT_MILLISECONDS=2*1000

export const personalValidationFields = {
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required")
}

export const personalValidationSchema = Yup.object().shape(personalValidationFields)

export const validationSchema = Yup.object().shape(Object.assign({}, personalValidationFields, {
    password: Yup.string().required("Required").min(8, 'Too short'),
    confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
    agreed_to_terms_and_conditions: Yup.boolean().required("Required"),
}))

export const NewCustomerSignupForm = ReactTimeout(({formik_props, setTimeout, ...props}) => {

    const is_loading = false
    const is_saving = false
    const history = useHistory()
    const dispatch = useDispatch()
    const [emailThatWasVerified, setEmailThatWasVerified] = useState(null)
    const [personalDetailsAreValid, setPersonalDetailsAreValid] = useState(false)
    const [emailVerificationCodeSent, setEmailVerificationCodeSent] = useState(false)
    const [showEmailAlreadyRegisteredRes, setShowEmailAlreadyRegisteredRes] = useState(null)
    const [showEmailInUseRes, setShowEmailInUseRes] = useState(null)
    const [verificationCodeSentTimer, setVerificationCodeSentTimer] = useState(null)
    const is_logged_in = useSelector(() => isLoggedIn())
    const external_info_urls = useSelector(() => globalSettingsList.getSetting('external_info_urls'))

    const email = get(formik_props, ["values", "email"])
    const emailIsVerified = size(emailThatWasVerified) > 0 && emailThatWasVerified === email

    const is_sending_verification_request = useSelector(() => Boolean(verificationCodeSentTimer) || newCustomerSignupSendEmailVerificationRequest.getIsSavingObject())

    const ready_to_submit = emailIsVerified && get(formik_props, ["values", "agreed_to_terms_and_conditions"]) && get(formik_props, ["values", "password"])

    const language_code = getBestLanguageCode()
    if (get(formik_props, ["values", "language_code"]) !== language_code) {
        formik_props.setFieldValue("language_code", language_code)
    }

    useEffect(() => {
        if ( emailVerificationCodeSent ) {
            setEmailVerificationCodeSent(false)
        }
    }, [email])
    
    useEffect(() => {
        if ( is_logged_in ) {
            historyPushRetainQueryParams(history, "/")
        }
    }, [is_logged_in])
    
    useEffect(() => {
        personalValidationSchema.isValid(formik_props.values).then( function(valid) {
            setPersonalDetailsAreValid(valid)
        })
    }, [formik_props.values])

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(globalSettingsList.fetchListIfNeeded())
        }
    }, [])
    
    const onSendVerificationCode = () => {

        const on_ok = () => {
            setEmailVerificationCodeSent(true)
            const timeout_ref = setTimeout(() => setVerificationCodeSentTimer(null),
                                           TIMER_CODE_SENT_MILLISECONDS)
            setVerificationCodeSentTimer(timeout_ref)
        }
        
        dispatch(newCustomerSignupSendEmailVerificationRequest.saveNewObject(get(formik_props, "values")))
            .then((res) => handleSubmitResult({res, on_ok}))
    }

    const onVerifyCode = () => {

        const email = get(formik_props, ["values", "email"])

        const on_ok = (json) => {
            if ( json.status === 'success' ) {
                setEmailThatWasVerified(email)
            } else if ( json.status === 'duplicate' ) {
                setShowEmailAlreadyRegisteredRes(json)
            } else if ( json.status === 'in_use' ) {
                setShowEmailInUseRes(json)
            } else {
                formik_props.setFieldError("verification_code", "Incorrect code")
            }
        }

        dispatch(newCustomerSignupVerifyEmailToken.saveNewObject(get(formik_props, "values")))
            .then((res) => handleSubmitResult({res, on_ok}))
    }

    const onAutoLogin = (token, user) => {
        setAuthToken(token, user)
        history.push("/")
    }
    
    const onStartOver = () => {
        setShowEmailAlreadyRegisteredRes(null)
        setShowEmailInUseRes(null)
        dispatch(clearAuthentication())
        formik_props.setFieldValue("first_name", "")
        formik_props.setFieldValue("last_name", "")
        formik_props.setFieldValue("email", "")
        formik_props.setFieldValue("verification_code", "")
        setEmailThatWasVerified(null)
        setEmailVerificationCodeSent(false)
    }
    
    const renderPersonalForm = () => {

        return (
            <div>
              <Translation>{ (t) => (
                  <>
                    <div css={field_label_style}>
                      <Trans>Your name</Trans>
                    </div>
                    <FieldInfoText>
                      <Trans>So we can address you in our emails, invoices etc.</Trans>
                    </FieldInfoText>

                    <Separator variant="h10" />
                    <Row>
                      <Col md={6}>
                        <FormikInputField
                          name="first_name"
                          type="text"
                          placeholder={t("First name")}
                        />
                      </Col>
                      <Col md={6}>
                        <FormikInputField
                          name="last_name"
                          type="text"
                          placeholder={t("Surname")}
                        />
                      </Col>
                    </Row>
                  </>
              )}</Translation>
            </div>
        )
        
    }

    const renderSendingVerificationRequest = () => {
        return (
            <LoadingIcon />
        )
    }

    const renderVerificationCodeNotSentYet = () => {
        return (
            <Row>
              <Col>
                <Separator variant="h10" />
                <BlueButton onClick={onSendVerificationCode} auto_disable={false}>
                  <Trans>Send Verification Code</Trans>
                </BlueButton>

                { is_sending_verification_request && renderSendingVerificationRequest() }
              </Col>
            </Row>          
        )
    }

    const renderVerificationCodeSent = () => {
        const verification_code_entered = size(get(formik_props, ["values", "verification_code"])) > 0
        return (
            <Translation>{ (t) => (
                <>
                  <Row>
                    <Col>
                      <Separator variant="h10" />
                      <div css={field_small_label_style}>
                        <Trans>Verification code</Trans>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div css={verification_code_row}>
                        <FormikInputField
                          name="verification_code"
                          type="text"
                          placeholder={t("Verification code")}
                        />

                        <Separator variant="w5" />
                        <WhiteButton auto_disable={false} onClick={onSendVerificationCode} extra_css={resend_button_style}>
                          <Trans>Resend</Trans>
                        </WhiteButton>
                        { is_sending_verification_request && renderSendingVerificationRequest() }
                      </div>
                    </Col>
                  </Row>

                  { verification_code_entered &&
                    <>
                      <Separator variant="h15" />
                      <Row>
                        <Col>
                          <BaseButton onClick={onVerifyCode} auto_disable={false} css={green_button_style}>
                            <Trans>Verify the code</Trans>
                          </BaseButton>
                        </Col>
                      </Row>
                    </>
                  }
                </>
            )}</Translation>
        )
    }

    const renderEmailUnverifiedPanel = () => {
        return (
            <Translation>{ (t) => (
                <div css={email_verify_style}>
                  <Container fluid>
                    <Row>
                      <Col>
                        <div css={field_label_style}>
                          <Trans>Verify your email address</Trans>
                        </div>
                        <FieldInfoText>
                          <Trans i18nKey="new_account__before_we_move_on"
                                 defaults="Before we move on, we need to make sure that your email address is correct. Please click the button below. We will send an email to this address containing a Verification Code that you can enter below." />
                        </FieldInfoText>
                        <Separator variant="h10" />
                        
                        { ! emailVerificationCodeSent && renderVerificationCodeNotSentYet() }
                        { emailVerificationCodeSent && renderVerificationCodeSent() }
                        
                      </Col>
                    </Row>
                  </Container>
                </div>
            )}</Translation>
        )
    }

    const renderEmailVerifiedPanel = () => {
        return (
            <>
            </>
        )
    }

    const renderEmailForm = () => {
        return (
            <div>
              <Translation>{ (t) => (
                  <>
                    <div css={field_label_style}>
                      <Trans>Account login</Trans>
                    </div>
                    <FieldInfoText>
                      <Trans>You will use your email address to login in. XOIP will also send emails, such as your incoming faxes or voicemails, to this email address.</Trans>
                    </FieldInfoText>

                    <Separator variant="h10" />
                    <Row>
                      <Col md={6}>
                        <FormikInputField
                          name="email"
                          type="email"
                          auto_scale={true}
                          placeholder={t("Email address")}
                        />
                      </Col>
                      <Col md={6} css={email_verified_row_style}>
                        { personalDetailsAreValid && emailIsVerified && 
                          <>
                            <div css={email_verified_style}>
                              <FontAwesomeIcon icon={faCheckCircle} />
                              <Separator variant="w5" />
                              <Trans>Verified</Trans>
                            </div>
                          </>
                        }
                      </Col>
                    </Row>
                    { personalDetailsAreValid && !emailIsVerified && renderEmailUnverifiedPanel() }
                    { personalDetailsAreValid && emailIsVerified && renderEmailVerifiedPanel() }
                  </>
                  
              )}</Translation>
            </div>
        )
    }

    const renderPasswordForm = () => {
        return (
            <div>
              <Translation>{ (t) => (
                  <>
                    <div css={field_label_style}>
                      <Trans>Password</Trans>
                    </div>

                  <Separator variant="h10" />
                    <Row>
                      <Col md={6}>
                        <FormikInputField
                          name="password"
                          type="password"
                          placeholder={t("Enter a password")}
                        />
                      </Col>
                      <Col md={6}>
                        <FormikInputField
                          name="confirm_password"
                          type="password"
                          placeholder={t("Confirm password")}
                        />
                      </Col>
                    </Row>
                    
                  </>
              )}</Translation>
            </div>
        )
    }

    const renderEmailInUse = () => {
        return (
            <Modal show={true}>

              <Modal.Header>
                <Modal.Title>
                  <Trans>Already in use</Trans>
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Trans i18nKey="signup__an_account_is_using_this_email" />
                <Separator variant="h20" />
                
                <div css={button_bar_style}>
                  <BlueLinkButton onClick={onStartOver}>
                    <Trans>Continue</Trans>
                  </BlueLinkButton>
                </div>
                
              </Modal.Body>
              
            </Modal>
        )        
    }

    const renderEmailAlreadyRegistered = ({token, user}) => {
        return (
            <Modal show={true}>

              <Modal.Header>
                <Modal.Title>
                  <Trans>Already registered</Trans>
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Trans i18nKey="signup__an_account_already_exists" />
                <Separator variant="h20" />

                <div css={button_bar_style}>
                  <BlueLinkButton onClick={onStartOver}>
                    <Trans>No, start over</Trans>
                  </BlueLinkButton>

                  <Button variant="success" onClick={() => onAutoLogin(token, user)}>
                    <Trans>Yes, Continue</Trans>
                  </Button>
                </div>
                
              </Modal.Body>
              
            </Modal>
        )
    }

    return (
        <Form>
          <Container fluid>

            { showEmailAlreadyRegisteredRes && renderEmailAlreadyRegistered(showEmailAlreadyRegisteredRes) }
            { showEmailInUseRes && renderEmailInUse(showEmailInUseRes) }

            <Row>
              <Col md={12}>
                <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />

                <Card variant="white">
                  <Row>
                    <Col md={8}>

                      <div css={header_style}>
                        <h3 css={h3_style}>
                          <Trans>Create your account</Trans>
                        </h3>
                      </div>

                      <Separator variant="h10" />
                      <FieldInfoText>
                        <Trans>Do you already have an account?</Trans>
                        &nbsp;
                        <Link to="/" css={login_link_style}>
                          <Trans>Login</Trans>
                        </Link>
                      </FieldInfoText>
                      
                      <Separator variant="h30" />

                      { renderPersonalForm() }
                      <Separator variant="h50" />
                      { renderEmailForm() }
                      { emailIsVerified &&
                        <>
                          <Separator variant="h50" />
                          { renderPasswordForm() }
                          <Separator variant="h50" />
                          <TermsAndConditionsField renderCheckbox={() => <FormikFatIconCheckboxField name="agreed_to_terms_and_conditions" formik_props={formik_props} />} />
                        </>
                      }

                      { ready_to_submit &&
                        <>
                          <Separator variant="h15" />
                          <BlueButton type="submit" auto_disable={false}>
                            <Trans>Create your account</Trans>
                          </BlueButton>
                        </>
                      }

                    </Col>
                  </Row>
                </Card>

              </Col>
            </Row>
          </Container>  
        </Form>
    )

})

const main_style = css`
margin-top: 50px;
margin-bottom: 100px;
margin-left: 15px;
margin-right: 15px;
`

const h2_style = css`
font-size: 28px
width: 100%;
`

const sub_heading_style = css`
font-size: 16px;
line-height: 19px;
`

const image_style = css`
height: 42px;
`

const h3_style = css`
font-size: 24px;
margin-bottom: 20px;
width: 100%;
`

const header_style = css`
display: flex;
justify-content: space-between;

`

const field_label_style = css`
font-weight: bold;
font-size: 18px;
margin-bottom: 10px;
`

const field_small_label_style = css`
font-size: 16px;
font-weight: bold;
margin-bottom: 10px;
`

const email_verified_style = css`
color: ${theme.colors.dark_green};
display: flex;
align-items: center;
`

const green_button_style = css`
background-color: ${theme.colors.dark_green};
border: 1px solid ${theme.colors.dark_green};
color: #fff;
font-weight: 500;


&:hover {
background-color: ${theme.colors.dark_green};
}

`

const email_verify_style = css`
background-color: ${theme.colors.nearly_white};
margin-top: 25px;
padding-top: 20px;
padding-bottom: 20px;
`

const verification_code_row = css`
display: flex;
`

const email_verified_row_style = css`
display: flex;
`

const terms_row = css`
display: flex;
font-weight: 500;
`

const resend_button_style = css`
max-height: 40px;
`

const i_agree_with_style = css`
align-items: baseline;
line-height: 26px;
`

const button_bar_style = css`
display: flex;
justify-content: space-between;
`

const email_with_button_style = css`
width: 100%;
`

const login_link_style = css`
font-weight: 500;
`
