/** @jsxImportSource @emotion/react */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../emotion/theme'
import { Trans } from 'react-i18next'
import { BlueLinkButton } from './layout/BlueLinkButton'
import { InlineIcon } from './layout/InlineIcon'

const AddNumberCard = ({
  hasActiveVoiceAndFaxSubscription,
  hasActiveFaxSubscription,
  hasActiveVoiceSubscription,
  onAddVoiceSubscription,
  onAddFaxSubscription,
}) => {
  return (
    <div css={add_aubscription_card_style}>
      {hasActiveVoiceAndFaxSubscription && (
        <>
          <div css={card_header_style}>
            <Trans>Add a new number</Trans>
          </div>
          <div css={button_group_style}>
            <BlueLinkButton
              onClick={onAddVoiceSubscription}
              auto_disable={false}
            >
              <div css={link_container_style}>
                <Trans>Voice number</Trans>
                <div css={chevron_container}>
                  <InlineIcon
                    icon_name="chevron-right"
                    size_variant="h14"
                    variant="blue"
                  />
                </div>
              </div>
            </BlueLinkButton>

            <BlueLinkButton onClick={onAddFaxSubscription} auto_disable={false}>
              <div css={link_container_style}>
                <Trans>Fax number</Trans>
                <div css={chevron_container}>
                  <InlineIcon
                    icon_name="chevron-right"
                    size_variant="h14"
                    variant="blue"
                  />
                </div>
              </div>
            </BlueLinkButton>
          </div>
        </>
      )}
      {!hasActiveVoiceAndFaxSubscription && hasActiveVoiceSubscription && (
        <BlueLinkButton onClick={onAddVoiceSubscription} auto_disable={false}>
          <div css={link_container_style}>
            <Trans>Add a new number</Trans>
            <div css={chevron_container}>
              <InlineIcon
                icon_name="chevron-right"
                size_variant="h14"
                variant="blue"
              />
            </div>
          </div>
        </BlueLinkButton>
      )}
      {!hasActiveVoiceAndFaxSubscription && hasActiveFaxSubscription && (
        <BlueLinkButton onClick={onAddFaxSubscription} auto_disable={false}>
          <div css={link_container_style}>
            <Trans>Add a new fax number</Trans>
            <div css={chevron_container}>
              <InlineIcon
                icon_name="chevron-right"
                size_variant="h14"
                variant="blue"
              />
            </div>
          </div>
        </BlueLinkButton>
      )}
    </div>
  )
}

export default AddNumberCard

const add_aubscription_card_style = css`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  width: 100%;
  background-color: ${theme.colors.very_light_grey};
  border: 1px #bdbdbd dashed;
  border-radius: 8px;
  padding: 16px 16px;
`

const card_header_style = css`
  font-size: 16px;
  font-weight: 600;
`

const button_group_style = css`
  display: flex;
  flex-direction: column;
  gap: 0;
`

const link_container_style = css`
  display: flex;
  height: 22px;
`
const chevron_container = css`
  margin-left: 2px;
`
