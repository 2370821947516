/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../emotion/theme'
import { Trans } from 'react-i18next'

export const CallHistoryTitle = ({customer, lowercase, ...props }) => {

    let title
    const has_voice = customer.has_active_voice_in_subscription
    const has_fax = customer.has_active_fax_out_subscription || customer.has_active_fax_in_subscription

    if ( has_voice && has_fax ) {
        title = <Trans>Traffic</Trans>
        
    } else if ( has_voice ) {
        title = <Trans>Voice messages</Trans>
        
    } else if ( has_fax ) {
        title = <Trans>Fax messages</Trans>
        
    } else {
        title = <Trans>No subscriptions</Trans>
    }
    
    return (
        <span css={(lowercase === true) ? container_lowercase_style : null}>
          {title}
        </span>
    )
}

const container_lowercase_style = css`
text-transform: lowercase;
`
