import { ItemList } from '../../orm'
import { map } from 'lodash'

class AdminFaxCostStructureVariantList extends ItemList {
    getEntityKey() {
        return "admin/fax_cost_structure_variant"
    }


}

export const adminFaxCostStructureVariantList = new AdminFaxCostStructureVariantList("admin_fax_cost_structure_variant")
