/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/react'
import { connect } from 'react-redux'
import { CONFIRMATION_MODAL_KEY } from '../actions/ui'
import { Modal } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import { clearActiveModal, getActiveModal, getModalParams } from '../actions/ui'
import { ButtonBar } from './layout/ButtonBar'
import { Separator } from './layout/Separator'
import { Trans, Translation } from 'react-i18next'

class ConfirmationModal extends Component {
    onClose = () => {
        const { dispatch, is_active } = this.props
        if ( is_active ) {
            dispatch(dispatch(clearActiveModal()))
        }
    }

    onConfirm = () => {
        const { onConfirmed } = this.props
        this.onClose()
        if ( onConfirmed ) {
            onConfirmed()
        }
    }
    
    render() {
        const { is_active, text, heading, can_cancel, cancel_text, confirm_text } = this.props
        if ( ! is_active ) {
            return null
        }

        return (
            <Modal show={true}
                   title={heading}
                   onHide={this.onClose}
            >

              { heading && 
                <Modal.Header closeButton={can_cancel}>
                  <Modal.Title>
                    <Trans>{heading}</Trans>
                  </Modal.Title>
                </Modal.Header>
              }

              <Modal.Body>
                {text}
              </Modal.Body>
              
              <Modal.Footer>
                
                <ButtonBar>
                  { can_cancel && 
                    <Button variant="danger" onClick={this.onClose}>
                      <Trans>{cancel_text}</Trans>
                    </Button>
                  }
                  
                  <Button variant="success" onClick={this.onConfirm}>
                    <Trans>{confirm_text}</Trans>
                  </Button>
                  
                </ButtonBar>
              </Modal.Footer>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    const { text, onConfirmed, heading, can_cancel, confirm_text, cancel_text } = getModalParams(state)
    const is_active = getActiveModal(state) === CONFIRMATION_MODAL_KEY
    return {
        text,
        onConfirmed,
        is_active,
        can_cancel: can_cancel !== false,
        confirm_text: confirm_text || "Confirm",
        cancel_text: cancel_text || "Cancel",
        heading: heading || "Confirm"
    }
}

export default connect(mapStateToProps)(ConfirmationModal)

const confirm_text = css`

padding: 10px;

`
