import { ItemList } from '../../orm'
import { map } from 'lodash'

class AdminProduct extends ItemList {
    getEntityKey() {
        return "admin/product"
    }

    
    
}

export const adminProductList = new AdminProduct("admin_product__default")
