/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { map, has, get } from 'lodash'
import { ButtonBar } from '../../components/layout/ButtonBar'
import { WhiteButton } from '../../components/layout/WhiteButton'
import AdminMainLayout from './AdminMainLayout'
import CommonTable from '../../components/CommonTable'
import { PrettyValue } from '../../components/PrettyValue'
import CurrencyValue from '../../components/CurrencyValue'
import Pagination from '../../components/Pagination'
import Timestamp from '../../components/Timestamp'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { FormikInputField } from '../../components/form/InputField'
import { countryList } from '../../actions/country'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFooter from './AdminTableFooter'
import AdminTableFilter from './AdminTableFilter'
import CardInfoText from '../../components/layout/CardInfoText'
import { Table, Row, Col } from 'react-bootstrap'
import { Container, Button } from 'react-bootstrap'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { InlineIconBar } from '../../components/layout/InlineIconBar'
import { adminOutgoingFaxPhoneNumberPrefixList } from '../actions/admin_outgoing_fax_phone_number_prefix'
import { Separator } from '../../components/layout/Separator'

class AdminOutgoingFaxPhoneNumberPrefixes extends Component {
 
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminOutgoingFaxPhoneNumberPrefixList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch, filter } = this.props
        dispatch(adminOutgoingFaxPhoneNumberPrefixList.fetchListIfNeeded())
    }

    onEdit = (evt, item) => {
        const { history } = this.props
        history.push('/admin/outgoing_fax_number_prefix/' + item.id)
    }

    onAdd = () => {
        const { history } = this.props
        history.push('/admin/outgoing_fax_number_prefix/')
    }

    onFilter = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminOutgoingFaxPhoneNumberPrefixList.updateListFilter(filter_values))
    }

    getCellValue = (header_key, item, index) => {
        const { number_types_by_value } = this.props
        switch( header_key ) {
            case 'created_at':
                return <Timestamp value={item.created_at} format='date' />
            case 'action':
                return (
                    <InlineIconBar>
                      <InlineIcon icon_name="eye" onClick={(evt) => this.onEdit(evt, item)} />
                    </InlineIconBar>
                )
            default:
                return undefined
        }
    }

    renderFilter = (formik_props) => {
        const { country_options, city_options, number_type_options } = this.props
        return (
            <Col md="12">
              <div css={filter_field_row_style}>
                <FormikInputField name="any_field"
                                  formik_props={formik_props}
                                  show_search_icon={true}
                                  placeholder="Search" />


                <Separator variant="w20" />
                <WhiteButton onClick={ this.onAdd }>
                  Add Number Prefix
                </WhiteButton>

              </div>
            </Col>
        )
    }

    render() {
        const {
            prefix_headers,
            prefixes,
            is_loading,
            prefix_filter
        } = this.props
        
        return (
            <AdminMainLayout active_key="numbers"
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'outgoing_fax_number_prefixes', label: 'Outgoing fax number prefixes', url: '/admin/outgoing_fax_number_prefixes'}]}>
              <AdminTableHeader title="Outgoing fax number prefixes">
                <div css={ css`width:100%;`}>
                  <AdminTableFilter updateOnChange={ this.onFilter }
                                    is_loading={ is_loading }
                                    filter={prefix_filter}
                                    renderFilter={ this.renderFilter } />
                </div>
                
              </AdminTableHeader>
              <Container fluid>
                <Row>
                  <Col md="6">
                    <CardInfoText>
                      Manage how faxes are handled that are sent to destinations in this list.
                      <br/>
                      Note that individual numbers can override this list, they can be edited in the phone number list.
                    </CardInfoText>
                  </Col>
                </Row>
                <Separator variant="h10" />
                <CommonTable
                    is_loading={ is_loading }
                    empty_message="There are no prefixes."
                    headers={ prefix_headers }
                  items={ prefixes }
                  item_list={adminOutgoingFaxPhoneNumberPrefixList}
                    getCellValue={ this.getCellValue }
                    sortOnHeader={ this.sortOnHeader }
                />
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const prefixes = adminOutgoingFaxPhoneNumberPrefixList.getVisibleObjects() || []

    return {
        prefixes,
        prefix_filter: adminOutgoingFaxPhoneNumberPrefixList.getFilter(),
        prefix_headers: {
            created_at: { name: "Created at" },
            destination_prefix: { name: "Destination prefix" },
            outgoing_fax_service_provider_name: { name: "Outgoing Fax Service Provider" },
            action: { name: '' }
        },
        is_loading: adminOutgoingFaxPhoneNumberPrefixList.isLoading()
    }
}
export default withRouter(connect(mapStateToProps)(AdminOutgoingFaxPhoneNumberPrefixes))

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const filter_field_row_style = css`
display: flex;
`
