/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import { default_theme as theme } from '../emotion/theme'
import { Modal, Row, Col } from 'react-bootstrap'
import { get, size, map, includes } from 'lodash'
import BusyMask from './BusyMask'
import { Timestamp } from './Timestamp'
import { faxItemList } from '../actions/fax_item'
import { faxByEmailCancelVerification, faxByEmailApprovalVerification } from '../actions/fax_by_email_verification'
import { outgoingFaxQueueList } from '../actions/outgoing_fax_queue'
import { InlineIcon } from './layout/InlineIcon'
import { Container } from 'react-bootstrap'
import { Separator } from './layout/Separator'
import { Error } from './layout/Error'
import FieldInfoText from './layout/FieldInfoText'
import { showSuccess, showError } from '../actions/Error'
import { Pluralize } from './Pluralize'
import { ButtonBar } from './layout/ButtonBar'
import { BlueButton } from './layout/BlueButton'
import { RedButton } from './layout/RedButton'
import { FaxVerificationExpiredMessage } from './FaxVerificationExpiredMessage'

const SUMMARY_STATUS_MAPPING = {
    'SUBMITTED': { 'status': <Trans>Pending</Trans>, 'icon_name': 'status-in-progress', is_enem_error: false  },
    'NEW': { 'status': <Trans>Pending</Trans>, 'icon_name': 'status-in-progress', is_enem_error: false },
    'ARREAR': { 'status': <Trans>Pending</Trans>, 'icon_name': 'status-error', 'message': <Trans>Internal error</Trans>, is_enem_error: false },
    'FAX_REQ_SENT': { 'status': <Trans>Pending</Trans>, 'icon_name': 'status-in-progress', is_enem_error: false },
    'INTERNAL_FAILURE': { 'status': <Trans>Error</Trans>, 'icon_name': 'status-error', 'message': <Trans>Internal error</Trans>, is_enem_error: true },
    'DELIVERY_FAILED': { 'status': <Trans>Error</Trans>, 'icon_name': 'status-error', is_enem_error: true },
    'DELIVERY_SUCCESS': { 'status': <Trans>Delivered</Trans>, 'icon_name': 'status-success', is_enem_error: false },
    'CANCELLED': { 'status': <Trans>Cancelled</Trans>, 'icon_name': 'status-error', is_enem_error: false },
    'FE_FROM_ADDRESS': { 'status': <Trans>Error</Trans>, 'icon_name': 'status-error', 'message': <Trans>Invalid source</Trans>, is_enem_error: false },
    'FE_BAD_TOKEN': { 'status': <Trans>Error</Trans>, 'icon_name': 'status-error', 'message': <Trans>Invalid token</Trans>, is_enem_error: false },
    'FE_BAD_DESTINATION': { 'status': <Trans>Error</Trans>, 'icon_name': 'status-error', 'message': <Trans>Invalid destination</Trans>, is_enem_error: false },
    'FE_BAD_ATTACHMENT': { 'status': <Trans>Error</Trans>, 'icon_name': 'status-error', 'message': <Trans>Invalid file</Trans>, is_enem_error: false },
    'DELIVERED': { 'status': <Trans>Delivered</Trans>, 'icon_name': 'status-success', is_enem_error: false },
    'SEND_PENDING_VERIFICATION': { 'status': <Trans>Waiting for your confirmation</Trans>, 'icon_name': 'status-waiting-input', is_enem_error: false },
    'VERIFICATION_FAILED': { 'status': <Trans>Failed to verify</Trans>, 'icon_name': 'status-error', is_enem_error: false },
    'EXPIRED': { 'status': <Trans>Confirmation expired</Trans>, 'icon_name': 'status-expired', is_enem_error: false },
    'INVALID_INCOMING_EMAIL': { 'status': <Trans>Invalid email</Trans>, 'icon_name': 'status-error', is_enem_error: false },
    'LEGACY_SUBMITTED': { 'status': <Trans>Delivered</Trans>, 'icon_name': 'status-success', is_enem_error: false }
}

class OutgoingFaxItemDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {show_internal_status:false}
    }
    
    componentDidMount() {
        const { dispatch, fax_item_id } = this.props
        dispatch(faxItemList.ensureObjectLoaded(fax_item_id))
    }

    componentDidUpdate() {
        const { dispatch, fax_item_id } = this.props
        dispatch(faxItemList.ensureObjectLoaded(fax_item_id))
    }

    renderFilename(outgoing_fax_queue_item) {
        const f = outgoing_fax_queue_item.fax_file
        if ( ! f ) {
            return ""
        }
        return (
            <div>
              <div css={filename_style}>
                {f.derived_from_num_files > 1 &&
                 map(f.derived_from_file_info_set.files, (derived_f) => <div>{derived_f.visible_filename}</div>)
                }
                {f.derived_from_num_files <= 1 && f.visible_filename}
              </div>
              <div css={filename_info_style}>
                <Pluralize count={f.page_count} singular='page' />
              </div>
            </div>
            
        )
    }

    onApproveFax = () => {
        const { dispatch, fax_item  } = this.props
        const on_ok = function(json) {
            if ( json.status == 'approved' ) {
                showSuccess('Approved')
            }
            dispatch(faxItemList.invalidateObject(fax_item.id))
        }
        
        dispatch(faxByEmailApprovalVerification.verify(fax_item.id)).then(on_ok)
    }

    onCancelFax = () => {
        const { dispatch, fax_item  } = this.props

        const on_ok = function(json) {
            if ( json.status == 'cancelled' ) {
                showSuccess('Cancelled')
            }
            dispatch(faxItemList.invalidateObject(fax_item.id))
        }
        
        dispatch(faxByEmailCancelVerification.verify(fax_item.id)).then(on_ok)
    }

    toggleShowInternalStatus = () => {
        const { show_internal_status } = this.state
        this.setState({show_internal_status:!show_internal_status}) 
    }
    
    getBestFromName() {
        const { fax_item  } = this.props
        const display_name = get(fax_item, ["from_customer", "display_name"], null)
        const fax_triggered_from_email_address = get(fax_item, ["outgoing_fax_queue", "fax_triggered_from_email_address"], null)

        if ( fax_triggered_from_email_address ) {
            return fax_triggered_from_email_address
        } else if ( display_name !== null ) {
            return display_name
        }
        return null
    }

    getBestToName() {
        const { fax_item } = this.props
        if ( fax_item.to_customer ) {
            return fax_item.to_customer.email
        }
        if ( fax_item.to_phone_number ) {
            return fax_item.to_phone_number.number
        }
    }

    getBestDetailsSummaryStatusName(outgoing_fax_queue_item) {
        return get(SUMMARY_STATUS_MAPPING[outgoing_fax_queue_item.status_name], "status") ||
            get(outgoing_fax_queue_item, "status_name")
    }

    getBestDetailsSummaryIcon(outgoing_fax_queue_item) {
        return get(SUMMARY_STATUS_MAPPING[outgoing_fax_queue_item.status_name], "icon_name", null)
    }

    isEnemError(outgoing_fax_queue_item) {
        return get(SUMMARY_STATUS_MAPPING[outgoing_fax_queue_item.status_name], "is_enem_error", false)
    }
    
    onPrintConfirmation = (outgoing_fax_queue_item) => {
        return outgoingFaxQueueList.downloadItemConfirmation(outgoing_fax_queue_item.id)
    }

    renderInternalFaxStatus(outgoing_fax_queue_item) {
        const { fax_item } = this.props
        const outgoing_fax_queue = get(fax_item, "outgoing_fax_queue")
        
        return (
            <Modal show={true}
                   onHide={this.toggleShowInternalStatus}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <Trans>Internal fax details</Trans>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  Last check at <Timestamp format="datetime" value={outgoing_fax_queue_item.last_status_check_at} />
                </div>
                <div>
                  <pre>
                    {outgoing_fax_queue_item.last_status_result}
                  </pre>
                  <Separator variant="h10" />
                </div>

                { get(outgoing_fax_queue_item, "last_error") && 
                  <div>
                    Last item error: <Error>{outgoing_fax_queue_item.last_error}</Error>
                    <Separator variant="h10" />
                  </div>
                }
                { get(outgoing_fax_queue_item, "internal_last_error") && 
                  <div>
                    Item internal error: <Error>{outgoing_fax_queue_item.internal_last_error}</Error>
                    <Separator variant="h10" />
                  </div>
                }
                
                <Separator variant="h10" />
                <div>
                  Service provider: {outgoing_fax_queue_item.outgoing_fax_service_provider_name}
                </div>
                
                <Separator variant="h10" with_top_border={true}/>
                <h3>History</h3>
                { map(outgoing_fax_queue_item.attempts, (attempt) => (
                    <>
                      <Row>
                        <Col>Sequence</Col>
                        <Col> {attempt.sequence}</Col>
                      </Row>
                      <Row>
                        <Col>Status</Col>
                        <Col> {attempt.status_name}</Col>
                      </Row>
                      <Row>
                        <Col>Service provider</Col>
                        <Col> {attempt.outgoing_fax_service_provider_name}</Col>
                      </Row>
                      <Row>
                        <Col>Last check at</Col>
                        <Col> <Timestamp value={attempt.last_status_check_at} format="at" /></Col>
                      </Row>
                      <Row>
                        <Col>Last result</Col>
                        <Col> {attempt.last_status_result}</Col>
                      </Row>
                      { attempt.internal_last_error && 
                        <Row>
                          <Col>Error</Col>
                          <Col>{attempt.internal_last_error}</Col>
                        </Row>
                      }
                    <Separator variant="h10" with_top_border={true}/>
                    </>
                ))
                }

              </Modal.Body>
            </Modal>
        )
    }
    
    renderSummary(outgoing_fax_queue_item) {
        const { fax_item } = this.props
        const { show_internal_status } = this.state
        return (
            <div>
              <div css={summary_row_style}>
                <div css={summary_direction}>
                  <InlineIcon icon_name="outgoing" variant="black"/>
                  <span><Trans>Outgoing</Trans></span>
                </div>
                <Separator variant="w10" />

                <div css={summary_row_style}>
                  { this.getBestFromName() }
                  <Separator variant="w10" />
                  <InlineIcon icon_name="arrow-right" variant="black" disable_margins={true} />
                  <Separator variant="w10" />
                  { this.getBestToName() }
                </div>
                
              </div>

              <div css={summary_row_style}>
                <Trans>Sent</Trans>:&nbsp;
                <Timestamp value={outgoing_fax_queue_item.created_at} format="at" />
              </div>

              <div css={summary_row_style}>
                <span onClick={this.toggleShowInternalStatus}>ID</span>: {outgoing_fax_queue_item.short_id}
                { show_internal_status && this.renderInternalFaxStatus(outgoing_fax_queue_item) }
              </div>

              <Separator variant="h10" />

              <div css={summary_row_style}>
                <Trans>Status</Trans>:&nbsp;
                <Timestamp value={outgoing_fax_queue_item.last_status_check_at || outgoing_fax_queue_item.created_at} format="at" />
                &nbsp;-&nbsp;
                <InlineIcon icon_name={this.getBestDetailsSummaryIcon(outgoing_fax_queue_item)} />
                {this.getBestDetailsSummaryStatusName(outgoing_fax_queue_item)}
                {this.isEnemError(outgoing_fax_queue_item) && outgoing_fax_queue_item.friendly_last_status_result &&
                 <div css={error_item_container}>
                   <Error>{outgoing_fax_queue_item.friendly_last_status_result}</Error>
                 </div>
                }
              </div>
              
            </div>
        )
    }

    renderFile(outgoing_fax_queue_item, is_last) {
        const that = this
        return (
            <div key={outgoing_fax_queue_item.id}>
              <div css={filename_row_style}>
                <div>
                  { this.renderFilename(outgoing_fax_queue_item) }
                </div>
                { includes(["DELIVERY_SUCCESS", "DELIVERED"], outgoing_fax_queue_item.status_name) &&
                  <div css={print_style} onClick={() => that.onPrintConfirmation(outgoing_fax_queue_item)}>
                    <Trans>
                      Download fax confirmation (pdf)
                    </Trans>
                    <Separator variant="w5" />
                    <InlineIcon icon_name="download" variant="blue" />
                  </div>
                }
              </div>
              <Container fluid>
                { this.renderSummary(outgoing_fax_queue_item) }
              </Container>
              { ! is_last && 
                <Separator variant="h50" />
              }
            </div>
        )
    }

    renderOverallStatus() {
        const { fax_item  } = this.props
        if ( ! fax_item.outgoing_fax_queue_last_error ) {
            return null
        }
        return (
            <div>
              <Separator variant="h10" />
              <Trans>Overall status</Trans>: {fax_item.outgoing_fax_queue_status_name}
              { fax_item.outgoing_fax_queue_last_error !== null && <Error>{fax_item.outgoing_fax_queue_last_error}</Error> }
            </div>
        )
    }

    renderStatusSpecificMessage(fax_item) {
        if ( fax_item.status === 'EXPIRED' ) {
            return (
                <div>
                  <Separator variant="h20" />
                  <Separator variant="h20" with_top_border={true} />
                  <Container fluid>
                    <Trans>Confirmation expired</Trans>
                    <FieldInfoText>
                      <FaxVerificationExpiredMessage />
                    </FieldInfoText>
                  </Container>
                  <Separator variant="h30" />
                </div>
            )
        } else {
            return null
        }
    }

    renderActions(fax_item) {
        const { is_verifying } = this.props
        if ( fax_item.status !== 'SEND_PENDING_VERIFICATION' ) {
            return null
        }
        return (
            <div css={actions_style}>
              { is_verifying && <BusyMask /> }
              <Separator variant="h20"  />
              <Separator variant="h20" with_top_border={true} />
              <div css={actions_header_style}>
                <Trans>Confirm to send this fax</Trans>
              </div>
              <Separator variant="h5"  />
              <ButtonBar item_style_extra={actions_item_style}>
                <BlueButton onClick={this.onApproveFax}>
                  <Trans>Send fax</Trans>
                </BlueButton>
                <RedButton onClick={this.onCancelFax} variant="warning">
                  <Trans>Cancel fax</Trans>
                </RedButton>
              </ButtonBar>
              <Separator variant="h10" />
            </div>
        )
    }
    
    render() {
        const that = this
        const { fax_item  } = this.props
        
        return (
            <div>
              { size(fax_item.outgoing_fax_queue_items) === 0 &&
                <div>
                  { this.renderOverallStatus() }
                  <Separator variant="h10" />
                </div>
              }
              <div>
                {map(fax_item.outgoing_fax_queue_items, (outgoing_fax_queue_item, index) => {
                    const is_last = index === size(fax_item.outgoing_fax_queue_items)-1
                    return that.renderFile(outgoing_fax_queue_item, is_last)
                })}
              </div>
              { this.renderStatusSpecificMessage(fax_item) }
              { this.renderActions(fax_item) }
            </div>
        )
    }
}

function mapStateToProps(state, props) {

    const { fax_item_id } = props
    const fax_item = faxItemList.getObject(fax_item_id)
    
    return {
        fax_item_id,
        fax_item,
        is_verifying: faxByEmailApprovalVerification.getIsSavingObject() || faxByEmailCancelVerification.getIsSavingObject(),
        detailed_summary_headers: {
            date: { name: 'Date', column_size: 1 },
            status: { name: 'Status', column_size: 1 },
            message: { name: 'Message', column_size: 1 }
        }
    }
}

export default connect(mapStateToProps)(OutgoingFaxItemDetails)

const filename_row_style = css`
background-color: ${theme.colors.very_light_grey};
border-radius: 4px;
width: 100%;
padding-left: 15px;
margin-bottom: 20px;
padding-top: 10px;
padding-bottom: 10px;
display: flex;
justify-content: space-between;
`

const print_style = css`
cursor: pointer;
color: ${theme.colors.primary_dark};
display: flex;
align-items: flex-end;
font-weight: 500;
`

const filename_style = css`
font-weight: 500;
font-size: 18px;
overflow: hidden;
text-overflow: ellipsis;
`

const filename_info_style = css`

`

const summary_direction = css`
color: #000;
font-weight: 500;
display: flex;
align-items: center;
`

const summary_row_style = css`
display: flex;
align-items: center;
`

const error_item_container = css`
margin-left: 10px;
`

const actions_style = css`
padding-left: 15px;
`

const actions_item_style = css`
margin-right: 10px;
`

const actions_header_style = css`
font-weight: 500;
font-size: 18px;
`
