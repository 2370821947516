import { ItemList } from '../../orm'
import { download } from '../../actions/lib'

class AdminFaxItemList extends ItemList {
    getEntityKey() {
        return "admin/fax_item"
    }

    download(filter) {
        const url = `admin/fax_item/download_excel/` 
        const params = null 
        const post_params = filter
        return download(url, params, post_params)
    }    
    
}

export const adminFaxItemList = new AdminFaxItemList("admin_fax_item__default")
