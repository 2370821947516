/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { Separator } from './Separator'

export default class Card extends Component {
  render() {
    const {
      title,
      mini_title,
      variant,
      with_padding_below,
      with_thick_padding_above,
      inner_css,
    } = this.props
    const variant_style = variants[variant]

    return (
      <div
        css={[
          variant_style,
          with_thick_padding_above ? thick_padding_above_style : null,
        ]}
      >
        {title && <div>{title}</div>}
        {mini_title && <h5>{mini_title}</h5>}
        <div css={inner_css || card}>{this.props.children}</div>

        {with_padding_below !== false && <Separator variant="h30" />}
      </div>
    )
  }
}

const variants = {
  white: css`
    border: 1px solid ${theme.colors.card_border};
    border-radius: 8px;
    background-color: ${theme.colors.card_background};
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 15px;
  `,

  white_wide_padding: css`
    border: 1px solid ${theme.colors.card_border};
    border-radius: 8px;
    background-color: ${theme.colors.card_background};
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
  `,

  white_wide_and_top_padding: css`
    border: 1px solid ${theme.colors.card_border};
    border-radius: 8px;
    background-color: ${theme.colors.card_background};
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
  `,

  white_popup: css`
    border: 1px solid ${theme.colors.card_border};
    background-color: ${theme.colors.card_background};
    padding-left: 20px;
    padding-top: 20px;
    margin-top: 15px;
    border-radius: 4px;
    box-shadow: 0 0 1.5px 0 rgba(0, 0, 0, 0.12),
      0 1.5px 1.5px 0 rgba(0, 0, 0, 0.24);
  `,

  margin_top: css`
    margin-top: 15px;
  `,
}

const thick_padding_above_style = css`
  padding-top: 33px;
`

const card = css(
  theme.mq({
    // paddingBottom: '20px',
    paddingLeft: '0px',
    // paddingRight: ['20px', '0px'],
  })
)
