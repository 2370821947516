/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { Link } from 'react-router-dom'
import { head, split } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons'
import { adminFaxItemList } from '../actions/admin_fax_item'
import { FaxItemDetailsModal } from '../../components/FaxItemDetailsModal'

export const AdminQuickLink = ({name, value, customer, label, ...props}) => {

    let url = undefined
    const display_value = label === undefined ? head(split(value, "-")) : label

    const [displayingFaxItem, setDisplayingFaxItem] = useState(null)
    let clickAction = null
    
    if ( value ) {
        switch(name) {
            case "transaction":
                url =`/admin/transaction/${value}`
                break
            case "product":
                url =`/admin/product/${value}`
                break
            case "phone_number":
                url =`/admin/number/${value}`
                break
            case "customer":
                url =`/admin/customer/${value}`
                break
            case "customer_subscription":
                url =`/admin/customer/${customer}/subscription/${value}`
                break
            case "fax_item":
                clickAction = () => setDisplayingFaxItem(value)
                break
            default:
                console.error(`Unknown link name ${name}`)
                break
        }
    }

    const icon = (
        <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
    )


    const renderFaxItemModal = () => {
        return <FaxItemDetailsModal fax_item_id={displayingFaxItem}
                                    faxItemList={adminFaxItemList}
                                    onClose={() => setDisplayingFaxItem(null)}
               />
    }
          
    return (
        <span css={container_style}>
          <span css={value_style}>
            {display_value}
          </span>
          { value &&
            <>
              { clickAction &&
                <span css={link_style} onClick={clickAction}>
                  {icon}
                </span>
              }
              { ! clickAction && 
                <Link to={url} css={link_style}>
                  {icon}
                </Link>
              }
            </>
          }
          { displayingFaxItem && renderFaxItemModal() }
        </span>
    )
    
}

const container_style = css`
display: flex;
`

const value_style = css`
margin-right: 5px;
`

const link_style = css`
color: ${theme.colors.dark_middle_grey};
cursor: pointer;
`
