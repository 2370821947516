/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import { CardHeader } from '../../components/layout/CardHeader'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { FormikInputField } from '../../components/form/InputField'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { jsx, css } from '@emotion/react'
import { adminFaxItemList } from '../actions/admin_fax_item'
import { Button, Row, Col } from 'react-bootstrap'
import { adminCustomerList } from '../actions/admin_customer'
import { adminFaxTrafficChannelOptions } from '../actions/admin_dropdown_options'
import { adminResellerList } from '../actions/admin_reseller'
import { FormikDatePicker } from '../../components/form/DatetimePicker'
import { AdminQuickLink } from './AdminQuickLink'
import { InlineIcon } from '../../components/layout/InlineIcon'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFilter from './AdminTableFilter'
import { Separator } from '../../components/layout/Separator'
import FaxTraffic from '../../components/FaxTraffic'
import { RadioGroupFieldStandalone } from '../../components/form/RadioGroupFieldStandalone'
import { MESSAGE_FILTER_DIRECTION_OPTIONS } from '../../components/CallHistoryCard'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import { AdminTableFilterPopup } from './AdminTableFilterPopup'

class AdminFaxTraffic extends Component {

    componentDidMount() {
        const { dispatch, customer_id } = this.props
        if ( customer_id ) {
            dispatch(adminFaxItemList.updateListFilter({customer: customer_id}))
            dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
        } else {
            dispatch(adminFaxItemList.updateListFilter({customer: null}))
        }
        dispatch(adminFaxItemList.fetchListIfNeeded())

        dispatch(adminFaxTrafficChannelOptions.updatePaginationNumItemsPerPage(1000))
        dispatch(adminFaxTrafficChannelOptions.fetchListIfNeeded())
        dispatch(adminResellerList.updatePaginationNumItemsPerPage(1000))
        dispatch(adminResellerList.fetchListIfNeeded())
    }
    
    componentDidUpdate() {
        const { dispatch, customer_id, admin_fax_item_list_filter } = this.props
        if ( admin_fax_item_list_filter.customer !== customer_id  ) {
            dispatch(adminFaxItemList.updateListFilter({customer: undefined}))
        }
        if ( customer_id ) {
            dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
        }
        dispatch(adminFaxItemList.fetchListIfNeeded())
    }
    
    onFilterChanged = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminFaxItemList.updateListFilter(filter_values))
    }

    onChangeMessageFilterDirection = (value) => {
        const { dispatch } = this.props
        dispatch(adminFaxItemList.updateListFilter({direction: value}))
        dispatch(adminFaxItemList.fetchListIfNeeded())
    }

    onDownload = () => {
        const { admin_fax_item_list_filter, transactionList } = this.props
        adminFaxItemList.download(admin_fax_item_list_filter)
    }

    renderFilter = (formik_props) => {
        const { customer_id, customer, admin_fax_item_list_filter, channel_options, reseller_options } = this.props
        const that = this

        return (
            <AdminTableFilterPopup popup_fields={(
                <Row>
                  <Col>
                    <Row>
                      <Col md="5">
                        <FormikDatePicker extra_css={filter_date_picker_style} name="date_from" placeholder="Created after" can_clear={true} formik_props={formik_props} />
                      </Col>
                      <Col md="5">
                        <FormikDatePicker extra_css={filter_date_picker_style} name="date_to" placeholder="Created before" can_clear={true} formik_props={formik_props} />
                      </Col>
                    </Row>
                    <Separator variant="h10" />
                    <Row>
                      <Col>
                        <RadioGroupFieldStandalone
                          name="messsage_filter_direction"
                          value={admin_fax_item_list_filter.direction || 'all'}
                          onChange={this.onChangeMessageFilterDirection}
                          options={MESSAGE_FILTER_DIRECTION_OPTIONS}
                        />
                      </Col>
                    </Row>
                    <Separator variant="h10" />
                    <Row>
                      <Col>
                        <FormikDropdownField name="channel"
                                             formik_props={formik_props}
                                             options={channel_options}
                                             empty_selection_label="Any channel"
                                             placeholder="Channel" />
                      </Col>
                    </Row>

                    <Separator variant="h10" />
                    <Row>
                      <Col>
                        <FormikDropdownField name="reseller"
                                             options={reseller_options}
                                             placeholder="Reseller"
                                             extra_css={reseller_filter_style}
                                             formik_props={formik_props} />
                      </Col>
                    </Row>
                    
                    <Row>
                      <Separator variant="h10" />
                      <Col>
                        <WhiteButton onClick={this.onDownload} auto_disable={false}>
                          <div css={download_button_style}>
                            <InlineIcon icon_name="download" onClick={that.onDownload} />
                            <Separator variant="w5" />
                            Excel
                          </div>
                        </WhiteButton>
                      </Col>
                    </Row>

                    
                  </Col>
                </Row>
            )}
                                   fixed_fields={(
                                       <>
                                         <Row>
                                           <Col>
                                             <FormikInputField name="any_field"
                                                               placeholder="Search"
                                                               show_search_icon={true}
                                             />
                                           </Col>
                                         </Row>
                                       </>
                                   )}
              
              />
        )
    }

    postRenderCell = ({header_key, item, rendered}) => {

        switch( header_key ) {
            case 'fax_from':
                if ( item.direction === "outgoing" ) {
                    return <AdminQuickLink name="customer" value={item.from_customer.id} label={rendered} />
                } else {
                    return rendered
                }
            case 'fax_to':
                if ( item.direction === "incoming" ) {
                    return <AdminQuickLink name="customer" value={item.to_customer.id} label={rendered} />
                } else {
                    return rendered
                }
            default: 
                return rendered
        }
    }

    render() {
        const { is_ready, fax_headers, customer_id, customer } = this.props

        let title = null
        if ( customer_id ) {
            title = `Fax traffic for ${customer && customer.user.display_name}`
        } else {
            title = "All fax traffic"
        }
        
        return (
            <div>
              <AdminTableHeader title={title}>
                <AdminTableFilter updateOnChange={ this.onFilterChanged }
                                  form="ADMIN_FAX_LOG_TABLE_FILTER_FORM"
                                  renderFilter={ this.renderFilter }
                />
              </AdminTableHeader>
                
              <WrappingBusyMask is_loading={!is_ready}>
                <FaxTraffic faxItemList={adminFaxItemList}
                            ellipsify_filename_enabled={true}
                            fax_headers={fax_headers}
                            postRenderCell={this.postRenderCell}
                />
              </WrappingBusyMask>
            </div>
        )
    }
    
}

function mapStateToProps(state, props) {
    const { customer_id } = props
    const customer = customer_id && adminCustomerList.getObject(customer_id)

    const fax_headers = {
        direction: { name: '', td_style: direction_cell_style },
        short_id: { name: <Trans>Job ID</Trans>, td_style: id_cell_style },
        channel: { name: <Trans>Channel</Trans>, td_style: id_cell_style },
        created_at: { name: <Trans>Date/Time</Trans>, td_style: created_at_cell_style },
        fax_from: { name: <Trans>From</Trans>, td_style: from_cell_style },
        fax_to: { name: <Trans>To</Trans>, td_style: to_cell_style },
        filename: { name: <Trans>File</Trans>, td_style: filename_cell_style },
        item_id: { name: <Trans>Fax ID</Trans>, td_style: fax_id_cell_style },
        num_pages: { name: <Trans>Pages</Trans>, td_style: num_pages_cell_style },
        status: { name: '', td_style: status_style },
        action: { name: '', td_style: action_style }
    }
    
    return {
        customer_id,
        customer,
        fax_headers,
        is_ready: adminFaxItemList.isReady() && (!customer_id || adminFaxItemList.getFilter().customer === customer_id),
        admin_fax_item_list_filter: adminFaxItemList.getFilter(),
        is_loading: adminFaxItemList.isLoading(),
        channel_options: adminFaxTrafficChannelOptions.getAsOptions(),
        reseller_options: adminResellerList.getAsOptions({empty_option_label:'All resellers'}),        
    }
}


export default connect(mapStateToProps)(AdminFaxTraffic)


const fax_log_right_child = css`
display: flex;
`

const direction_cell_style = css`
vertical-align: top;
width: 20px;
`

const id_cell_style = css`
vertical-align: top;
width: 20px;
`

const created_at_cell_style = css`
vertical-align: top;
width: 150px;
`

const from_cell_style = css`
vertical-align: top;
width: 15%;
`

const to_cell_style = css`
vertical-align: top;
width: 15%;
`

const status_style = css`
vertical-align: top;
width: 20px;
`

const action_style = css`
vertical-align: top;
width: 20px;
`

const filename_cell_style = css`
max-width: 1px;
width: auto;			
`

const fax_id_cell_style = css`
vertical-align: top;
width: 40px;
`

const num_pages_cell_style = css`
vertical-align: top;
width: 20px;
`

const download_button_style = css`
display: flex;
justify-content: space-between;
`


const filter_date_picker_style = css`
margin-right: 2px;
`

const reseller_filter_style = css`
width: 400px;
`

