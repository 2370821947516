/** @jsxImportSource @emotion/react */
import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { head, get, isEqual } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import MainLayout from './MainLayout'
import { Row, Col, Button, Accordion } from 'react-bootstrap'
import { WhiteButton } from './layout/WhiteButton'
import { InputWithButton } from './layout/InputWithButton'
import Card from './layout/Card'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormLabel } from './form/FormLabel'
import { FormLabelValue } from './form/FormLabelValue'
import { FormikInputField } from './form/InputField'
import { CardHeader } from './layout/CardHeader'
import { FormikRadioGroupField } from './form/RadioGroupField'
import { loggedInUser } from '../actions/auth'
import FieldInfoText from './layout/FieldInfoText'
import { Form as BootstrapForm } from 'react-bootstrap'
import { incomingFaxByEmailSettingsList } from '../actions/incoming_fax_by_email_settings'
import { outgoingFaxSettingsList } from '../actions/outgoing_fax_settings'
import {showSuccess, showError} from '../actions/Error'
import { Separator } from './layout/Separator'
import { SeparatorWithLine } from './layout/SeparatorWithLine'
import CardParagraphSubHeading from './layout/CardParagraphSubHeading'
import CardInfoText from './layout/CardInfoText'
import { InlineIcon } from './layout/InlineIcon'
import { BlueLinkButton } from './layout/BlueLinkButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Yup from 'yup'
import radio_selected from '../images/radio_selected.svg'
import radio_unselected from '../images/radio_unselected.svg'
import {
    ATTACH_FAX_TO_CONFIRMATION_EMAIL_OPTIONS,
    SEND_MULTIPLE_FILES_OPTIONS,
    INCOMING_FAX_BY_EMAIL_INITIAL_VALUES_DEFAULT,
    OUTGOING_FAX_INITIAL_VALUES_DEFAULT,
} from '../actions/form_vars'

class CommonIncomingFaxByEmailSettingsFormFields extends Component {

    onSelectSecurityOption = (value, formik_props) => {
        formik_props.setFieldValue("incoming.email_security_type", value)
    }
    
    renderSecurityOptions(formik_props) {

        const selected_value = get(formik_props, ["values", "incoming", "email_security_type"], "none")
        
        return (
            <Translation>{ (t) => (
                <div>
                  <Row css={security_option_row} onClick={() => this.onSelectSecurityOption("verification_email", formik_props)}>
                    <Col>
                      <Row>
                        <Col css={radio_button_cell_style}>
                          <img src={(selected_value === "verification_email" && radio_selected) || radio_unselected} />
                          <Separator variant="w10" />
                          <Trans>Verification email</Trans>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FieldInfoText>
                            <Trans>Before sending the fax, XOIP sends a verification email with a confirmation link back to the registered email address so that only the owner of the email address is able to send the fax.</Trans>
                          </FieldInfoText>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Separator variant="h20" />

                  <Row css={security_option_row} onClick={() => this.onSelectSecurityOption("subject_token", formik_props)}>
                    <Col>
                      <Row>
                        <Col css={radio_button_cell_style}>
                          <img src={(selected_value === "subject_token" && radio_selected) || radio_unselected} />
                          <Separator variant="w10" />
                          <Trans>Validation code</Trans>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FieldInfoText>
                            <Trans>Faxes are only sent when the subject field of the sent email contains the correct validation code. Enable this feature to enter your validation code.</Trans>
                            <Separator variant="h10" />
                          </FieldInfoText>
                          { selected_value === "subject_token" && 
                            <Row>
                              <Col md="6">
                                <InputWithButton
                                  input={(
                                      <FormikInputField name="incoming.email_fax_token"
                                                        type="text"
                                                        auto_scale={true}
                                                        placeholder={t("Enter your validation code")}
                                      />
                                  )}
                                  button={(
                                      <WhiteButton type="submit" disabled={!formik_props.dirty} onClick={formik_props.onSubmit}>
                                        <Trans>Save</Trans>
                                      </WhiteButton>
                                  )}
                                />
                              </Col>
                            </Row>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Separator variant="h20" />

                  <Row css={security_option_row} onClick={() => this.onSelectSecurityOption("none", formik_props)}>
                    <Col>
                      <Row>
                        <Col css={radio_button_cell_style}>
                          <img src={(selected_value === "none" && radio_selected) || radio_unselected} />
                          <Separator variant="w10" />
                          <Trans>No verification</Trans>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
            )}</Translation>              
        )
    }
    
    render() {
        const { formik_props } = this.props
        return (
            <Translation>{ (t) => (
                <div>
                  <FormLabelValue>
                    <Trans>Default fax header</Trans>
                    <Row>
                      <Col css={fixed_width_input}>
                        <InputWithButton
                          input={(
                              <FormikInputField name="outgoing.fax_header"
                                                type="text"
                                                auto_scale={true}
                                                placeholder={t("Fax header")}
                              />
                          )}
                          button={(
                              <WhiteButton type="submit"
                                           disabled={!formik_props.dirty}
                                           onClick={formik_props.onSubmit}>
                                <Trans>Save</Trans>
                              </WhiteButton>
                          )}
                        />
                        <FieldInfoText><Trans>Max</Trans> 32 <Trans>Characters</Trans></FieldInfoText>
                      </Col>
                    </Row>
                  </FormLabelValue>

                  <Separator variant="h20" />

                  <Row>
                    <Col css={fixed_width_input}>
                      <FormLabelValue>
                        <Trans>Send multiple files as</Trans>
                        <FormikRadioGroupField
                          auto_scale={true}
                          formik_props={formik_props}
                          name="outgoing.send_multiple_files_choice"
                          options={SEND_MULTIPLE_FILES_OPTIONS}
                        />
                      </FormLabelValue>
                    </Col>
                  </Row>

                  <Separator variant="h20" />

                  <Row>
                    <Col css={fixed_width_input}>
                      <FormLabelValue>
                        <Trans>Attach the fax to your confirmation email</Trans>
                        <FormikRadioGroupField
                          auto_scale={true}
                          formik_props={formik_props}
                          name="outgoing.attach_fax_to_confirmation_email"
                          options={ATTACH_FAX_TO_CONFIRMATION_EMAIL_OPTIONS}
                        />
                      </FormLabelValue>
                    </Col>
                  </Row>

                  <Separator variant="h20" />

                  <Row>
                    <Col>
                      <FormLabelValue>
                        <Trans>Enhanced Security</Trans>
                        <Separator variant="h10" />
                        { this.renderSecurityOptions(formik_props) }
                      </FormLabelValue>
                    </Col>
                  </Row>
                </div>
            )}</Translation>
        )
    }
    
}

function mapStateToProps(state, props) {
    
}

export default CommonIncomingFaxByEmailSettingsFormFields

const security_option_row = css`
cursor: pointer;
`

const fixed_width_input = css`
max-width: 450px;
`

const radio_button_cell_style = css`
display: flex;
`
