/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Trans, Translation } from 'react-i18next'
import { get } from 'lodash'
import classNames from 'classnames'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { FormikInputField } from '../../components/form/InputField'
import AdminTableFilter from './AdminTableFilter'
import { AdminTableFilterPopup } from './AdminTableFilterPopup'
import CommonTable from '../../components/CommonTable'
import Timestamp from '../../components/Timestamp'
import { jsx, css } from '@emotion/react'
import AdminMainLayout from './AdminMainLayout'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { Row, Col, Container } from 'react-bootstrap'
import { Separator } from '../../components/layout/Separator'
import { CardHeader } from '../../components/layout/CardHeader'
import { adminGeneratedReportList } from '../actions/admin_generated_report'
import { fileInfoList } from '../../actions/file_info'

export const AdminGeneratedReports = ({ ...props }) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const is_loading = useSelector(() => adminGeneratedReportList.isLoading())
    const generated_reports = useSelector(() => adminGeneratedReportList.getVisibleObjects())

    const headers = {
        short_id: { name: 'ID' },
        report_type: { name: 'Type' },
        status: { name: 'Status' },
        created_at: { name: 'Queued at' },
        started_at: { name: 'Started at' },
        completed_at: { name: 'Completed at' },
        action: { name: 'Download' }
    }
    
    useEffect(() => {
        dispatch(adminGeneratedReportList.fetchListIfNeeded())
    }, [])

    const onFilterChanged = (filter_values) => {
        dispatch(adminGeneratedReportList.updateListFilter(filter_values))
    }
    
    const renderFilter = () => {
        return (
            null
        )
    }

    const onDownload = (generatedReport) => {
        fileInfoList.download({fileInfo: generatedReport.file_info})
    }

    const getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'created_at':
                return <Timestamp value={item.created_at} format="datetime" />
            case 'started_at':
                return <Timestamp value={item.started_at} format="datetime" />
            case 'completed_at':
                return <Timestamp value={item.completed_at} format="datetime" />
            case 'action':
                return (
                    <div>
                      <WhiteButton onClick={() => onDownload(item)}>
                        Download
                      </WhiteButton>
                    </div>
                )
            default:
                return undefined
        }
    }
    
    return (
        <AdminMainLayout active_key={'generated_reports'}
                         breadcrumbs={[{name: 'admin_home'},
                                       {name: 'generated_reports', label: 'Generated reports', url: '/admin/generated_reports'}]}>
              <Container fluid>
                <Row>
                  <Col md={12}>
                    <CardHeader left_child={null}
                                align_with_common_table={true}
                                title={<Trans>Generated Reports</Trans>}
                                right_child={
                                    (
                                        <>
                                          { renderFilter() }
                                        </>
                                    )
                                }
                    />
                    <Separator variant="h15" />

                    <CommonTable
                        is_loading={ is_loading }
                        empty_message={`There are no generated reports.`}
                        headers={ headers }
                        items={ generated_reports }
                        item_list={adminGeneratedReportList}
                        getCellValue={ getCellValue }
                    />
                    
                  </Col>
                </Row>
              </Container>
            </AdminMainLayout>
        )
    
}

const filter_style = css`
display: flex;
align-items: center;
`

const filter_date_picker_style = css`
margin-right: 2px;
`
