/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/react'
import AdminMainLayout from './AdminMainLayout'
import AdminCommonCdrs from './AdminCommonCdrs'
import { Container, Button, Col, Row } from 'react-bootstrap'
import { adminCdrList } from '../actions/admin_cdr'

class AdminCdrs extends Component {

    render() {
        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'cdrs', label: 'Cdrs', url: '/admin/cdrs'}]}>
              <Container fluid>
                <AdminCommonCdrs itemList={adminCdrList} />
              </Container>
            </AdminMainLayout>
        )
    }
}

export default AdminCdrs
