/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { head, get } from 'lodash'
import { Link } from 'react-router-dom'
import { CardHeader } from './layout/CardHeader'
import { Modal } from 'react-bootstrap'
import { Trans, Translation } from 'react-i18next'
import BusyMask from './BusyMask'
import CurrencyValue from './CurrencyValue'
import Timestamp from './Timestamp'
import { transactionList } from '../actions/transaction'
import Card from './layout/Card'
import CommonTransactionTotals from './CommonTransactionTotals'
import CardInfoText from './layout/CardInfoText'
import CardH2Text from './layout/CardH2Text'
import CommonTable from './CommonTable'
import { InlineIcon } from './layout/InlineIcon'
import { WhiteButton } from './layout/WhiteButton'
import MainLayout from './MainLayout'
import QuickLinksCard from './QuickLinksCard'
import CreditInformationCard from './CreditInformationCard'
import { Row, Col, Container } from 'react-bootstrap'
import { Separator } from './layout/Separator'
import { DatePicker } from './form/DatetimePicker'
import TableFilter from './layout/TableFilter'
import { Button } from 'react-bootstrap'
import { aggregatedTransactions } from '../actions/aggregated_transactions'

class Transactions extends Component {
  constructor(props) {
    super(props)
    this.state = { showing_transaction_details: null }
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(transactionList.fetchListIfNeeded())
    dispatch(aggregatedTransactions.ensureTotalsLoaded())
  }

  componentDidUpdate(prev_props) {
    const { dispatch } = this.props
    dispatch(transactionList.fetchListIfNeeded())
    dispatch(aggregatedTransactions.ensureTotalsLoaded())
  }

  onViewTransaction = (evt, item) => {
    this.setState({ showing_transaction_details: item })
  }

  onHideTransaction = () => {
    this.setState({ showing_transaction_details: null })
  }

  onFilterChanged = (filter_values) => {
    const { dispatch } = this.props
    dispatch(transactionList.updateListFilter(filter_values))
    dispatch(aggregatedTransactions.invalidateTotals())
    dispatch(aggregatedTransactions.updateListFilter(filter_values))
  }

  onDateFromChanged = (value) => {
    const { dispatch } = this.props
    dispatch(
      transactionList.updateListFilter({ executed_at_from: value.format() })
    )
    dispatch(aggregatedTransactions.invalidateTotals())
    dispatch(
      aggregatedTransactions.updateListFilter({
        executed_at_from: value.format(),
      })
    )
  }

  onDateToChanged = (value) => {
    const { dispatch } = this.props
    dispatch(
      transactionList.updateListFilter({ executed_at_to: value.format() })
    )
    dispatch(aggregatedTransactions.invalidateTotals())
    dispatch(
      aggregatedTransactions.updateListFilter({
        executed_at_to: value.format(),
      })
    )
  }

  onDownload = () => {
    const { filter } = this.props
    transactionList.download(filter)
  }

  getCellValue = (header_key, item, index) => {
    const that = this
    switch (header_key) {
      case 'executed_at':
        return <Timestamp value={item.executed_at} format="datetime" />
      case 'customer_credit_amount_excluding_vat_euros':
        return (
          <CurrencyValue
            value={item.customer_credit_amount_excluding_vat_euros}
          />
        )
      case 'action':
        return (
          <InlineIcon
            icon_name="eye"
            onClick={(evt) => that.onViewTransaction(evt, item)}
          />
        )
      default:
        return undefined
    }
  }

  renderTotalAmounts() {
    const { totals } = this.props
    return (
      <div>
        <CardHeader title={<Trans>Total amounts</Trans>} />
        <Card variant="white">
          <div css={total_amounts_container_style}>
            <CommonTransactionTotals totals={totals} />
          </div>
        </Card>
      </div>
    )
  }

  renderTransactionDetailsModal(transaction) {
    return (
      <Modal show={true} size="lg" onHide={this.onHideTransaction}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans>Transaction details</Trans>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <Row>
              <Col>
                <Trans>Xoip Id</Trans>
              </Col>
              <Col>{transaction.short_ref}</Col>
            </Row>
            <Row>
              <Col>
                <Trans>Reference</Trans>
              </Col>
              <Col>{transaction.external_ref}</Col>
            </Row>
            <Row>
              <Col>
                <Trans>Executed at</Trans>
              </Col>
              <Col>
                <Timestamp value={transaction.executed_at} format="datetime" />
              </Col>
            </Row>
            <Row>
              <Col>
                <Trans>Description</Trans>
              </Col>
              <Col>{transaction.description}</Col>
            </Row>
            <Row>
              <Col>
                <Trans>Payment system</Trans>
              </Col>
              <Col>{transaction.payment_system}</Col>
            </Row>
            {transaction.vat_percentage > 0 && (
              <div>
                <Row>
                  <Col>
                    <Trans>Amount ex VAT</Trans>
                  </Col>
                  <Col css={transaction_modal_currency_value_row_style}>
                    <CurrencyValue
                      value={transaction.amount_excluding_vat_euros}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Trans>VAT</Trans>
                  </Col>
                  <Col css={transaction_modal_currency_value_row_style}>
                    {transaction.vat_percentage}%
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Trans>Amount inc. VAT</Trans>
                  </Col>
                  <Col css={transaction_modal_currency_value_row_style}>
                    <CurrencyValue
                      value={transaction.amount_including_vat_euros}
                    />
                  </Col>
                </Row>
              </div>
            )}
            {transaction.vat_percentage === 0 && (
              <Row>
                <Col>
                  <Trans>Amount</Trans>
                </Col>
                <Col css={transaction_modal_currency_value_row_style}>
                  <CurrencyValue
                    value={transaction.amount_excluding_vat_euros}
                  />
                </Col>
              </Row>
            )}
            {false && (
              <Row>
                <Col>
                  <Trans>From</Trans>
                </Col>
                <Col>{transaction.debtor_display_name}</Col>
              </Row>
            )}
            <Row>
              <Col>
                <Trans>To</Trans>
              </Col>
              <Col>{transaction.creditor_display_name}</Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  renderFilter() {
    const { customer_id, customer, admin_invoice_list_filter } = this.props
    const that = this

    let title = null
    title = <Trans>Credit history</Trans>

    const filter = (
      <Translation>
        {(t) => (
          <div css={header_right_child}>
            <DatePicker
              onChange={this.onDateFromChanged}
              placeholder={t('From date')}
            />

            <DatePicker
              onChange={this.onDateToChanged}
              placeholder={t('To date')}
            />

            <TableFilter
              label=""
              placeholder={t('Search')}
              updateOnChange={this.onFilterChanged}
            />
            <Separator variant="w20" />
            <WhiteButton onClick={this.onDownload} auto_disable={false}>
              <div css={download_button_style}>
                <InlineIcon icon_name="download" onClick={that.onDownload} />
                <Separator variant="w5" />
                Excel
              </div>
            </WhiteButton>
          </div>
        )}
      </Translation>
    )

    return <CardHeader title={title} right_child={filter} />
  }

  render() {
    const { is_loading, headers, transactions } = this.props
    const { showing_transaction_details } = this.state
    return (
      <MainLayout
        active_key="transactions"
        enable_breadlinks={true}
        breadcrumbs={[
          { name: 'home' },
          { name: 'transactions', label: Transactions, url: '/transactions' },
        ]}
      >
        <Container fluid>
          <Separator variant="h20" />

          {showing_transaction_details &&
            this.renderTransactionDetailsModal(showing_transaction_details)}

          <Row>
            <Col md={9}>
              {this.renderFilter()}
              <CommonTable
                is_loading={is_loading}
                empty_message={<Trans>There are no transactions.</Trans>}
                headers={headers}
                items={transactions}
                item_list={transactionList}
                renderCell={this.renderCell}
                getCellValue={this.getCellValue}
                sortOnHeader={this.sortOnHeader}
              />
            </Col>
            <Col md={3}>
              <Row>
                <Col>
                  <CreditInformationCard show_quick_links={false} />
                </Col>
              </Row>
              <Separator variant="h40" />
              {false && (
                <Row>
                  <Col>{this.renderTotalAmounts()}</Col>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </MainLayout>
    )
  }
}

function mapStateToProps(state, props) {
  const transactions = transactionList.getVisibleObjects()
  const totals = aggregatedTransactions.getTotals()
  return {
    transactions,
    totals,
    is_loading: transactionList.isLoading(),
    filter: transactionList.getFilter(),
    headers: {
      short_ref: { name: <Trans>Id</Trans> },
      customer_credit_amount_excluding_vat_euros: {
        name: <Trans>Amount</Trans>,
      },
      executed_at: { name: <Trans>When</Trans> },
      description: { name: <Trans>Description</Trans> },
      action: { name: '' },
    },
  }
}

export default connect(mapStateToProps)(Transactions)

const transaction_modal_currency_value_row_style = css`
  display: flex;
`

const header_right_child = css`
  display: flex;
  align-items: center;
`

const download_button_style = css`
  display: flex;
  justify-content: space-between;
`

const total_amounts_container_style = css``
