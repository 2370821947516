/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { jsx, css } from '@emotion/react'
import { map, get } from 'lodash'
import { default_theme as theme } from '../emotion/theme'
import { Container, Row, Col, Button, ButtonGroup } from 'react-bootstrap'
import CommonTable from './CommonTable'
import Card from './layout/Card'
import { CardHeader } from './layout/CardHeader'
import CardInfoText from './layout/CardInfoText'
import CardH2Text from './layout/CardH2Text'
import { Separator } from './layout/Separator'
import CurrencyValue from './CurrencyValue'

export default class CommonTransactionTotals extends Component {

    render() {
        const { totals, credit_info_text, debit_info_text } = this.props
        const left_column_width = 6
        const right_column_width = 4
        return (
            <div>
              <CardInfoText>
                <Trans>The amounts below adjust with the filter settings on the table</Trans>
              </CardInfoText>


              <Separator variant="h20" />
              <CardH2Text><Trans>Credit</Trans></CardH2Text>
              <CardInfoText>
                { credit_info_text || <Trans>Your subscription payments and top ups</Trans> }
              </CardInfoText>
              <Separator variant="h10" />
              <Row>
                <Col md={left_column_width}>
                  <Trans>Total ex. VAT:</Trans>
                </Col>
                <Col md={right_column_width}>
                  <CurrencyValue value={get(totals, "customer_credit_excluding_vat_euros")} align_right={true} />
                </Col>
              </Row>

              <Row>
                <Col md={left_column_width}>
                  {get(totals, "vat_credit_percentage", 0).toFixed(0)}% <Trans>VAT</Trans>:
                </Col>
                <Col md={right_column_width}>
                  <CurrencyValue value={get(totals, "vat_credit_euros")} align_right={true} />
                </Col>
              </Row>

              <Row>
                <Col md={left_column_width}>
                  <Trans>Total inc. VAT:</Trans>
                </Col>
                <Col md={right_column_width}>
                  <CurrencyValue value={get(totals, "customer_credit_excluding_vat_euros") + get(totals, "vat_credit_euros")} align_right={true} />
                </Col>
              </Row>

              <Separator variant="h30" />
              
              <CardH2Text><Trans>Debit</Trans></CardH2Text>
              <CardInfoText>
                { debit_info_text || <Trans>Your outgoing faxes and calls</Trans> }
                
              </CardInfoText>
              <Separator variant="h10" />
              <Row>
                <Col md={left_column_width}>
                  <CurrencyValue value={-get(totals, "customer_debit_excluding_vat_euros")} />
                </Col>
              </Row>
            </div>
        )
    }
    
}
