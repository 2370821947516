import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import 'moment/locale/nl'

const translationNL = require('./locales/nl/translation.json')
const translationEN = require('./locales/en/translation.json')

const resources = {
  nl: {
    translation: translationNL
  },
  en: {
    translation: translationEN
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
      falblackLng: 'en',
      resources,
      keySeparator: false,
      interpolation: {
          escapeValue: false
      },
      react: {
          wait: false,
          omitBoundRerender: false
      }
  })

export default i18n
