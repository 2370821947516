/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { includes, map, has, get, union, pull, filter } from 'lodash'
import { ButtonBar } from '../../components/layout/ButtonBar'
import { WhiteButton } from '../../components/layout/WhiteButton'
import AdminMainLayout from './AdminMainLayout'
import CommonTable from '../../components/CommonTable'
import CurrencyValue from '../../components/CurrencyValue'
import Pagination from '../../components/Pagination'
import Timestamp from '../../components/Timestamp'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { FormikInputField } from '../../components/form/InputField'
import { countryList } from '../../actions/country'
import { cityList } from '../../actions/city'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFooter from './AdminTableFooter'
import AdminTableFilter from './AdminTableFilter'
import CardInfoText from '../../components/layout/CardInfoText'
import { Table, Row, Col } from 'react-bootstrap'
import { Container, Button } from 'react-bootstrap'
import { GrayButton } from '../../components/layout/GrayButton'
import { BlueButton } from '../../components/layout/BlueButton'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { InlineIconBar } from '../../components/layout/InlineIconBar'
import { adminPhoneNumberPrefixList } from '../actions/admin_phone_number_prefix'
import { adminNumberTypes } from '../actions/admin_dropdown_options'
import { Separator } from '../../components/layout/Separator'
import FormikOnChange from '../../components/form/FormikAutoSave'
import { RadioGroupField } from '../../components/form/RadioGroupField'
import check_unticked from '../../images/check_unticked.png'
import check_ticked from '../../images/check_ticked.png'

class AdminPhoneNumberPrefixSelector extends Component {

    constructor(props) {
        super(props)
        this.state = {selected_prefix_ids: []}
    }
    
    componentDidMount() {
        const { dispatch, initially_selected_prefix_ids } = this.props
        dispatch(adminPhoneNumberPrefixList.clearListFilter())
        dispatch(adminPhoneNumberPrefixList.fetchListIfNeeded())
        dispatch(countryList.updatePaginationNumItemsPerPage(1000))
        dispatch(cityList.updatePaginationNumItemsPerPage(1000))
        dispatch(countryList.fetchListIfNeeded())
        dispatch(cityList.fetchListIfNeeded())
        dispatch(adminNumberTypes.fetchListIfNeeded())

        this.setState({selected_prefix_ids: initially_selected_prefix_ids})
    }

    componentDidUpdate() {
        const { dispatch, filter } = this.props
        dispatch(adminPhoneNumberPrefixList.fetchListIfNeeded())
        dispatch(countryList.fetchListIfNeeded())
        dispatch(cityList.fetchListIfNeeded())
    }

    onFilter = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminPhoneNumberPrefixList.updateListFilter(filter_values))
    }

    onCancel = () => {
        const { onCancel } = this.props
        onCancel()
    }

    onSave = () => {
        const { onSave, prefixes_by_id } = this.props
        const { selected_prefix_ids } = this.state
        const selected_prefixes = filter(selected_prefix_ids, (id) => prefixes_by_id[id])
        onSave(selected_prefixes)
    }

    onCountryChanged = (country_id) => {
        const { dispatch } = this.props
        dispatch(cityList.filterOnCountry(country_id))
    }

    onSelectItem = (item) => {
        const { selected_prefix_ids } = this.state
        this.setState({selected_prefix_ids: union(selected_prefix_ids, [item.id])})
    }

    onUnselectItem = (item) => {
        const { selected_prefix_ids } = this.state
        pull(selected_prefix_ids, item.id)
        this.setState({selected_prefix_ids: selected_prefix_ids})
    }

    getCellValue = (header_key, item, index) => {
        const { number_types_by_value } = this.props
        const { selected_prefix_ids } = this.state
        const that = this
        switch( header_key ) {
        case 'checkbox':
            const is_selected = includes(selected_prefix_ids, item.id)
            return <div css={checkbox_cell_style}
                        onClick={function() { return is_selected ? that.onUnselectItem(item) : that.onSelectItem(item) }}>
                     { is_selected && <img src={check_ticked} /> }
                     { !is_selected && <img src={check_unticked} /> }
                   </div>
            case 'number_type':
                return get(number_types_by_value, [item.number_type, "name"])
            default:
                return undefined
        }
    }

    render() {
        const {
            prefixes, is_loading, prefix_filter,
            country_options, city_options, number_type_options
        } = this.props
        const that = this

        return (
            <Formik
              initialValues={{}}
              onSubmit={this.onSave}
              enableReinitialize={true}
            >
              {formik_props => {
                  const { values } = formik_props

                  const prefix_headers = {
                      checkbox: { render: () => <div>Select</div> },
                      prefix: { render: () => <FormikInputField name="prefix"
                                                                formik_props={formik_props}
                                                                show_search_icon={true}
                                                                placeholder="Prefix" /> },
                      number_type: { render: () => <FormikDropdownField name="number_type"
                                                                        formik_props={formik_props}
                                                                        options={number_type_options}
                                                                        empty_selection_label="Any number prefix"
                                                                        placeholder="Number&nbsp;type" /> },
                      country_name: { render: () => <FormikDropdownField name="country"
                                                                         formik_props={formik_props}
                                                                         options={country_options}
                                                                         on_change={that.onCountryChanged}
                                                                         empty_selection_label="Any country"
                                                                         placeholder="Country" /> },
                      city_name: { render: () => <FormikDropdownField name="city"
                                            formik_props={formik_props}
                                            options={city_options}
                                            empty_selection_label="Any city"
                                            placeholder="City" /> }
                  }
                  return (
                      <Form>
                        <FormikOnChange onChange={(values) => that.onFilter(values, formik_props)} />     
                        <Container fluid>
                          <CommonTable
                            is_loading={ is_loading }
                            empty_message="There are no phone number prefixes."
                            headers={ prefix_headers }
                            items={ prefixes }
                            getCellValue={ this.getCellValue }
                            sortOnHeader={ this.sortOnHeader }
                          />
                        </Container>
                        <AdminTableFooter>
                          <div css={footer_style}>
                            <Pagination listKey={ adminPhoneNumberPrefixList.listKey }
                                        filters={ prefix_filter } />
                            <ButtonBar style_extra={footer_buttons_style}>
                              <GrayButton onClick={this.onCancel}>Cancel</GrayButton>
                              <BlueButton onClick={this.onSave}>Submit</BlueButton>
                            </ButtonBar>
                          </div>
                      </AdminTableFooter>
                      </Form>
                  )
              }}
            </Formik>
        )
    }
}

function mapStateToProps(state, props) {
    const { initially_selected_prefix_ids } = props
    const prefixes = adminPhoneNumberPrefixList.getVisibleObjects() || []

    const country_options = countryList.getAsOptionsWithPriority()
    const city_options = cityList.getAsOptions({show_prefix_in_label:true})
    const number_type_options = adminNumberTypes.getAsOptions()

    return {
        prefixes,
        prefixes_by_id: adminPhoneNumberPrefixList.getObjectsById(),
        prefix_filter: adminPhoneNumberPrefixList.getFilter(),
        initially_selected_prefix_ids,
        number_type_options,
        number_types_by_value: adminNumberTypes.getObjectsById(),
        country_options,
        city_options,
        city_filter: cityList.getFilter(),
        is_loading: adminPhoneNumberPrefixList.isLoading()
    }
}
export default withRouter(connect(mapStateToProps)(AdminPhoneNumberPrefixSelector))

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const filter_field_row_style = css`
display: flex;
`

const checkbox_cell_style = css`
width: 34px;
cursor: pointer;
`

const footer_style = css`
display: flex;
justify-content: space-between;

`

const footer_buttons_style = css`
margin-top: 20px;
`
