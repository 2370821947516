import { ItemList } from '../../orm'

class AdminAsteriskServerLogList extends ItemList {
    getEntityKey() {
        return "admin/asterisk_server_log"
    }
}

class AdminAsteriskServerLogRequester extends ItemList {
    getEntityKey() {
        return "admin/asterisk_server_log/request_server_data"
    }

    requestNewData({server_name, filter_values}) {
        return this.saveNewObject(Object.assign({}, {server_name: server_name}, filter_values))
    }
}


export const adminAsteriskServerLogList = new AdminAsteriskServerLogList("admin_asterisk_server_log__default")
export const adminAsteriskServerLogRequestor = new AdminAsteriskServerLogRequester("admin_asterisk_server_log_requestor__default")
