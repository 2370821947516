/** @jsxImportSource @emotion/react */
import React,{ useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { Trans, Translation } from 'react-i18next'
import { Separator } from './layout/Separator'
import { Row, Col, Container } from 'react-bootstrap'
import { UpgradePathWidgetProductTable } from './UpgradePathWidgetProductTable'
import { UpgradePathWidgetSubscriptionNumberSelector } from './UpgradePathWidgetSubscriptionNumberSelector'
import { head, size, map, filter, get } from 'lodash'
import { default_theme as theme } from '../emotion/theme'
import NestedWrappingBusyMask from './NestedWrappingBusyMask'
import { WhiteButton } from './layout/WhiteButton'
import { upgradePathList } from '../actions/upgrade_path'
import { InlineIcon } from './layout/InlineIcon'
import { customerSubscriptionUpgradePathListFreeVoiceToPaid } from '../actions/customer_subscription'

export const UpgradePathNotificationFreeToPaidUpsellVoice = ({upgrade_path, display_style, onHideNotification, onDidRender}) => {

    const dispatch = useDispatch()
    const is_loading = useSelector(() => upgradePathList.isLoading())
    const is_saving = useSelector(() => upgradePathList.getIsSavingObject())
    const [collapsed, setCollapsed] = useState(upgradePathList.isUpgradePathNotificationFreeToPaidUpsellCollapsed())

    useEffect(() => {
        dispatch(upgradePathList.filterCustomerSubscriptionListForUpgrades({customerSubscriptionItemList: customerSubscriptionUpgradePathListFreeVoiceToPaid}))
        
    }, [])

    const onCollapse = () => {
        setCollapsed(true)
        upgradePathList.collapseUpgradePathNotificationFreeToPaidUpsell()
    }

    const onExpand = () => {
        setCollapsed(false)
        upgradePathList.expandUpgradePathNotificationFreeToPaidUpsell()
    }
    
    const renderTitle = () => {
        return (
            <>
              <Trans i18nKey="upgrade_path_free_to_paid_upsell_fax__important_announcement">
                Important announcement:
              </Trans>

              <Separator variant="h5" />

              <h3 css={h3_style}>
                <Trans i18nKey="upgrade_path_free_to_paid_upsell_voice__free_numbers_are_being">
                  Rate conversion for 084/087 numbers. Take action to keep your number(s)
                </Trans>
              </h3>

              <Separator variant="h15" />
            </>
        )
    }
    
    const renderBlurb = () => {
        return (
            <div>
              <div>
                  <Trans i18nKey="upgrade_path_free_to_paid_upsell_fax__due_to_problems">
		      Due to regulation the caller-pays model for 084 and 087 numbers will end. If you want to continue using your number(s), you must choose a new rate model.
                </Trans>
              </div>

              <Separator variant="h15" />
              
              <Separator variant="h15" />
              
              <div>
                  <Trans i18nKey="upgrade_path_free_to_paid_upsell_fax__numbers_without_a_paid_subscription">
		      Numbers that have not been converted are automatically cancelled on the expiration date. Your XOIP account and any XOIP credit remain valid.
                </Trans>
              </div>

            </div>
        )
    }

    const renderDismissButton = () => {
        return (
            <div css={dismiss_button_container_style}>
              <WhiteButton onClick={() => onHideNotification(upgrade_path)}>
                <NestedWrappingBusyMask is_loading={is_saving}>
                  <Trans i18nKey="upgrade_path__dont_show">
                    Don't show this again
                  </Trans>
                </NestedWrappingBusyMask>
              </WhiteButton>
            </div>
        )
    }

    const renderCollapseLink = () => {
        return (
            <Row>
              <Col>
                <div onClick={onCollapse} css={close_style}>
                  <InlineIcon icon_name="caret-up" variant="blue" />
                  Close
                </div>
              </Col>
            </Row>
        )
    }
    
    const renderUnCollapseLink = () => {
        return (
            <Row>
              <Col>
                <div onClick={onExpand} css={close_style}>
                  <InlineIcon icon_name="caret-down" variant="blue" />
                  <Trans>See details</Trans>
                </div>
              </Col>
            </Row>
        )
    }
    
    const render_for_call_history = (upgrade_path) => {
        return (
            <div css={container_style}>

              <Row>
                <Col>
                  {false && renderDismissButton()}
                  <Separator variant="h10" />
                </Col>
              </Row>
              
              <Row>
                <Col md={12}>
                  { renderTitle() }
                </Col>
              </Row>

              { ! collapsed && 
                <Row>
                  <Col md={6}>
                    <div>
                      {renderBlurb()}
                      <Separator variant="h15" />
                      <UpgradePathWidgetSubscriptionNumberSelector customerSubscriptionItemList={customerSubscriptionUpgradePathListFreeVoiceToPaid} />

                      <Separator variant="h15" />

                      { renderCollapseLink() }
                      
                    </div>
                  </Col>
                  <Col>
                    <UpgradePathWidgetProductTable defaultProductType="voice" />
                  </Col>
                </Row>
              }

              { collapsed && renderUnCollapseLink() }

            </div>
        )
    }

    // This message disabled as of xoip-1602
    return null
    // if ( display_style === "voice_numbers" ) {
    //     onDidRender()
    //     return render_for_call_history(upgrade_path)
    // } else {
    //     return null
    // }
    
}

const container_style = css`
background-color: ${theme.colors.very_light_grey};
width: 100%;
margin-bottom: 40px;
padding: 20px 20px 18px;
border-radius: 10px;
color: ${theme.colors.black};
box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.2);
`

const close_style = css`
color: ${theme.colors.primary_dark};
cursor: pointer;
width: fit-content;
`

const h3_style = css`
margin-top: 0px;
margin-bottom: 0px;
`

const dismiss_button_container_style = css`
`

