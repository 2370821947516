import { ItemList } from '../orm'

class VatList extends ItemList {
    getEntityKey() {
        return "vat/lookup_vat_number"
    }

    validate({vat_number}) {
        const values = {vat_number}
        return this.saveNewObject(values)
    }

}

export const vatList = new VatList("vat_list__default")
