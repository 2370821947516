/** @jsxImportSource @emotion/react */
import React from 'react'
import { useHistory } from 'react-router-dom'
import { PhoneNumber } from './PhoneNumber'
import { LowTrafficWarningWidget } from './LowTrafficWarningWidget'
import { get } from 'lodash'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../emotion/theme'
import { BlueLinkButton } from './layout/BlueLinkButton'
import { Trans, Translation } from 'react-i18next'
import { InlineIcon } from './layout/InlineIcon'

// import './SubscriptionCard.css' // Import the CSS file

const SubscriptionCard = ({ subscription, onEditFaxSubscription }) => {
  const history = useHistory()

  const product_name = get(subscription, 'product_name')
  const phoneNumber = get(subscription, 'phone_number_number')
  const isVoiceNumber = get(subscription, 'product_can_receive_voice', false)
  const name = get(subscription, ['customer_phone_number', 'phone_number_name'])
  const email = get(subscription, [
    'customer_phone_number',
    'phone_number_email',
  ])

  const onEditVoiceCallPlan = (subscription) => {
    history.push(`/voice/${subscription.id}/call_plan`)
  }

  const isPhoneNumber = get(subscription, 'product_can_receive_voice', false)

  return (
    <div css={card_container_style}>
      <div css={card_header_style}>
        <div css={subscription_style}>
          <InlineIcon
            variant={'black'}
            size_variant={'h14'}
            icon_name={isVoiceNumber ? 'phone' : 'fax'}
          />
          <div css={subscription_name_style}>{product_name}</div>
        </div>

        <BlueLinkButton
          css={link_style}
          onClick={() =>
            isVoiceNumber
              ? onEditVoiceCallPlan(subscription)
              : onEditFaxSubscription(subscription)
          }
          auto_disable={false}
        >
          <div>
            <Trans>Edit</Trans>
          </div>
        </BlueLinkButton>
      </div>
      <div>
        <div css={number_style}>
          <PhoneNumber phone_number={phoneNumber} />
          <LowTrafficWarningWidget subscription={subscription} />
        </div>
        <div css={truncate_style}>{isVoiceNumber ? name : email}</div>
      </div>
    </div>
  )
}

export default SubscriptionCard

const card_container_style = css`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 16px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`

const card_header_style = css`
  display: flex;
  justify-content: space-between;
  align-items: end;
`
const subscription_style = css`
  display: flex;
  align-items: center;
`
const subscription_name_style = css`
  text-transform: uppercase;
  font-size: 12px;
  white-space: nowrap;
`
const number_style = css`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
`
const truncate_style = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const link_style = css`
  font-size: 14px;
`
