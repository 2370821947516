/** @jsxImportSource @emotion/react */
import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { confirmModal } from '../../actions/ui'
import {showSuccess, showError} from '../../actions/Error'
import CommonTable from '../../components/CommonTable'
import { Trans, Translation } from 'react-i18next'
import AdminMainLayout from './AdminMainLayout'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFilter from './AdminTableFilter'
import { FormikInputField } from '../../components/form/InputField'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { Container, Col, Row } from 'react-bootstrap'
import { Separator } from '../../components/layout/Separator'
import { cityList } from '../../actions/city'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { ButtonBar }  from '../../components/layout/ButtonBar'

export const AdminCities = ({}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const cities = useSelector(() => cityList.getVisibleObjects())
    const is_loading = cityList.isLoading()
    const headers = {
        name: { name: "Name" },
        country_name: { name: "Country" },
        action: { name: "",
                  renderValue: (item) =>
                  <ButtonBar>
                    <InlineIcon icon_name="eye" onClick={(evt) => onEdit(item)} />
                    <InlineIcon icon_name="delete" onClick={(evt) => onDelete(item)} />
                  </ButtonBar>
                }
    }

    const onAdd = () => {
        history.push(`/admin/city/`)
    }

    const onEdit = (item) => {
        history.push(`/admin/city/${item.id}`)
    }

    const onDelete = (item) => {
        const on_ok = function(json) {
            if ( json.errors ) {
                showError("Failed", `Could not delete this city because ${JSON.stringify(json.errors)}`)
            } else {
                showSuccess("Deleted", "City has been deleted")
                history.push("/admin/cities")
            }
        }

        const onConfirmed = () => {
            return dispatch(cityList.deleteObject(item.id)).then(on_ok)
        }
        
        const text = (<Trans>Are you sure you want to delete this city?</Trans>)
        return dispatch(confirmModal({text:text, onConfirmed: onConfirmed}))
        
    }

    const updateOnChange = (values) => {
        dispatch(cityList.updateListFilter(values))
        dispatch(cityList.fetchListIfNeeded())
    }
    
    useEffect(() => {
        dispatch(cityList.fetchListIfNeeded())
    }, [])

    const renderFilter = (formik_props) => {
        return (
            <Col md="12">
              <div css={filter_field_row_style}>
                <FormikInputField name="any_field"
                                  formik_props={formik_props}
                                  show_search_icon={true}
                                  placeholder="Search" />


                <Separator variant="w20" />
                <WhiteButton onClick={ onAdd }>
                  Add City
                </WhiteButton>

              </div>
            </Col>
        )
    }
    
    return (
        <AdminMainLayout active_key="cities"
                         breadcrumbs={[{name: 'admin_home'},
                                       {name: 'cities', label: 'Cities', url: '/admin/cities'}]}>
          <AdminTableHeader title="Cities">
            <div css={ css`width:100%;`}>
              <AdminTableFilter item_list={cityList} updateOnChange={updateOnChange} renderFilter={renderFilter} />
            </div>
            
          </AdminTableHeader>
          <Container fluid>
            <Separator variant="h10" />
            <CommonTable
              is_loading={ is_loading }
              empty_message="There are no cities."
              headers={ headers }
              items={ cities }
              item_list={cityList}
            />
          </Container>
        </AdminMainLayout>
    )
    
}

const filter_field_row_style = css`
display: flex;
`
