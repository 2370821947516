/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { Trans, Translation } from 'react-i18next'
import { jsx, css } from '@emotion/react'
import { useField } from 'formik'
import { map, size } from 'lodash'
import { default_theme as theme } from '../../emotion/theme'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import { DropdownStandalone } from './Dropdown'
import { MobileOnly } from '../layout/MobileOnly'
import { DesktopOnly } from '../layout/DesktopOnly'

export const RadioGroupFieldStandalone = ({
  value,
  options,
  onChange,
  auto_scale,
  ...props
}) => {
  let was_selected = false
  const bootstrap_optimum_item_width = 12 / (size(options) || 1)

  const renderAsSegmentedControl = () => {
    return (
      <ButtonGroup
        css={[
          button_group_style,
          (auto_scale && auto_scaled_container) || null,
        ]}
      >
        {map(options, (option, index) => {
          const is_selected = '' + option.value === '' + value
          const has_unselected_left_option = was_selected
          const left_sibling_is_unselected = index > 0 && !was_selected
          const res = (
            <div
              key={index}
              className={
                (auto_scale && 'md-' + bootstrap_optimum_item_width) || ''
              }
              css={[
                option_style,
                (auto_scale && auto_scaled_item_style) || null,
                (left_sibling_is_unselected &&
                  unselected_option_style__has_unselected_left_option) ||
                  null,
                (is_selected && selected_option_style) ||
                  unselected_option_style,
              ]}
              onClick={(evt) => onChange(option.value)}
            >
              <Trans>{option.label}</Trans>
            </div>
          )
          was_selected = is_selected
          return res
        })}
      </ButtonGroup>
    )
  }

  const renderAsDropdown = () => {
    return (
      <DropdownStandalone
        default_value={value}
        options={options}
        value={value}
        on_change={onChange}
      />
    )
  }

  return (
    <div>
      <MobileOnly>
        {auto_scale && renderAsDropdown()}
        {!auto_scale && renderAsSegmentedControl()}
      </MobileOnly>

      <DesktopOnly>{renderAsSegmentedControl()}</DesktopOnly>
    </div>
  )
}

const button_group_style = css`
  background-color: ${theme.colors.very_light_grey};
  border-radius: 4px;
  display: inline-flex;

  /* Media query for phone screens */
  @media (max-width: 480px) {
    display: flex;
    flex-wrap: wrap;
  }
`

const auto_scaled_container = css`
  width: 100%;
`

const option_style = css`
  // height: 30px;
  border: 0px;
  font-size: 14px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px
  padding-bottom: 8px
  margin-left: 2px;
  margin-right: 2px;

  /* Media query for phone screens */
  @media (max-width: 480px) {
    flex-grow: 1;
  }
`

const selected_option_style = css`
  border-radius: 4px;
  background-color: #ffffff;
  // height: 26px;
  padding-top: 8px
  padding-bottom: 8px
  margin-top: 2px;
  margin-bottom: 2px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 2px 0px;
  border-left: 1px solid #ffffff;

  &:focus,
  &:hover,
  &:active,
  &:focus:active {
    background-color: #ffffff;
    color: #000000;
    border: 0px;
    border-left: 1px solid #ffffff;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 2px 0px;
  }
`

const unselected_option_style = css`
  cursor: pointer;
  box-shadow: 0px;
  border-radius: 0px;
  border-left: 1px solid ${theme.colors.very_light_grey};
  &:focus,
  &:hover,
  &:active,
  &:focus:active {
    text-decoration: underline;
  }
`

const unselected_option_style__has_unselected_left_option = css`
  border-left: 1px solid #d1d1d6;
`

const auto_scaled_item_style = css`
  width: 100%;
`
