/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { head, get, map, keys, split, size } from 'lodash'
import { FormikDropdownField } from '../../components/form/Dropdown'
import AdminCreditInformationCard from './AdminCreditInformationCard'
import { adminTransactionList } from '../actions/admin_transaction'
import { ButtonBar } from '../../components/layout/ButtonBar'
import { adminCustomerList } from '../actions/admin_customer'
import { BlueButton } from '../../components/layout/BlueButton'
import { adminTransactionTypes } from '../actions/admin_dropdown_options'
import { CardHeader } from '../../components/layout/CardHeader'
import { LanguageFlag } from '../../components/LanguageFlag'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { Separator } from '../../components/layout/Separator'
import FieldInfoText from '../../components/layout/FieldInfoText'
import CurrencyValue from '../../components/CurrencyValue'
import { Formik, Form, FieldArray, Field } from 'formik'
import Loading from '../../components/Loading'
import { FormikTextarea } from '../../components/form/TextareaField'
import { FormikInputField } from '../../components/form/InputField'
import AdminMainLayout from './AdminMainLayout'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import Card from '../../components/layout/Card'
import { Col, Row, Container, Navbar, Nav } from 'react-bootstrap'
import { Form as BootstrapForm } from 'react-bootstrap'
import * as Yup from 'yup'
import {showSuccess, showError} from '../../actions/Error'
import Button from 'react-bootstrap/Button'
import CardInfoText from '../../components/layout/CardInfoText'
import { handleSubmitResult } from '../../actions/form'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'

const validationSchema = Yup.object().shape({
    'amount_excluding_vat_euros': Yup.number().required("Required"),
    'description': Yup.string().required("Required"),
    'transaction_type': Yup.string().required("Required")
})

class AdminCustomerTransaction extends Component {

    componentDidMount() {
        const { dispatch, customer_id } = this.props
        dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
        dispatch(adminTransactionTypes.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch, customer_id } = this.props
        dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
    }

    onCreateTransaction = (values, formik_funcs) => {
        const { history, dispatch, customer_id } = this.props

        const on_ok = function(json) {
            dispatch(adminCustomerList.invalidateObject(customer_id))
            showSuccess("Saved", "Transaction created")
            history.push(`/admin/customer/${customer_id}/transactions`)
        }

        values = Object.assign({}, values, {customer: customer_id})
        dispatch(adminTransactionList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }

    render() {
        const { initial_values, is_loading, is_busy, transaction, transaction_id,
                customer, customer_id, transaction_type_options } = this.props
        const that = this
        const title = `${get(transaction, ["description"])}`

        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'customers', label: 'Customers', url: '/admin/customers'},
                                           {name: 'customer', label: `${get(customer, ["user", "display_name"], '')} ${get(customer, ["short_id"])}`, url: `/admin/customer/${customer_id}`},
                                           {name: 'new_transaction', label: 'New transaction', url: `/admin/customer/${customer_id}/create_transaction`}]}>
              <Container fluid>

                <Row>
                  <Col md="8">
                    
                    <CardHeader title={`Add transaction for ${get(customer, ["user", "display_name"], '')}`} />
                    <Separator variant="h20" />
                    
                    <Formik
                        initialValues={initial_values}
                        onSubmit={that.onCreateTransaction}
                        enableReinitialize={true}
                        validationSchema={validationSchema}
                    >
                      {formik_props => {
                           const { values } = formik_props
                           return (
                               <Form>
                                 <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                                 <Row>
                                   <Col>
                                     <BootstrapForm.Group>
                                       <BootstrapForm.Label>Euro amount, inc VAT. (use a negative amount to decrease the customer credit balance)</BootstrapForm.Label>
                                       <FormikInputField name="amount_excluding_vat_euros" placeholder="Amount (Euros, ex VAT)" />
                                     </BootstrapForm.Group>

                                     { get(values, "amount_excluding_vat_euros", 0) > 0 &&
                                       <BootstrapForm.Group>
                                         <BootstrapForm.Label>Tick if this transaction should generate an invoice</BootstrapForm.Label>
                                         <FieldInfoText>
                                           If True, VAT will be logged against the transaction if applicable for this customer
                                         </FieldInfoText>
                                         <FormikCheckboxField name="create_invoice" />
                                       </BootstrapForm.Group>
                                     }
                                     
                                     <BootstrapForm.Group>
                                       <BootstrapForm.Label>Reason</BootstrapForm.Label>
                                       <FormikDropdownField formik_props={formik_props} 
                                                            type="text" 
                                                            name="transaction_type" 
                                                            options={transaction_type_options} />
                                     </BootstrapForm.Group>
                                     
                                     <BootstrapForm.Group>
                                       <BootstrapForm.Label>Description</BootstrapForm.Label>
                                       <FormikTextarea rows="2" name="description" placeholder="Description" />
                                     </BootstrapForm.Group>
                                   </Col>
                                 </Row>
                                 <Row>
                                   <Col>
                                     <ButtonBar>
                                       <BlueButton type="submit">
                                         Save
                                       </BlueButton>
                                     </ButtonBar>
                                   </Col>
                                 </Row>
                               </Form>
                           )}
                      }
                    </Formik>
                  </Col>
                  <Col md="4">
                    <AdminCreditInformationCard customer_id={customer_id} />
                  </Col>
                </Row>
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const customer_id = get(props, ["match", "params", "customer_id"], null)
    const customer = adminCustomerList.getObject(customer_id)
    return {
        customer_id,
        customer,
        transaction_type_options: adminTransactionTypes.getAsOptions(),
        is_loading: adminTransactionList.isLoading(),
        is_busy: adminTransactionList.getIsSavingObject(),
        initial_values: {description: "",
                         transaction_type: "",
                         amount_excluding_vat_euros: ""}
    }
}

export default connect(mapStateToProps)(AdminCustomerTransaction)

const breadcrumb_item = css`
display: flex;
align-items: center;
`
