import facepaint from 'facepaint'

export const breakpoint_mobile_pixels = 768
const breakpoint_mobile = `${breakpoint_mobile_pixels}px`

export const default_theme = {
  mq: facepaint([`@media(max-width: ${breakpoint_mobile})`]),

  colors: {
    white: '#FFFFFF',
    black: '#000000',
    primary: '#599DC2',
    primary_hover: '#15aed3',
    gray1: '#3c4858',
    gray2: '#e6e6e6',
    gray3: '#eeeeee',
    gray4: '#bfbfbf',
    gray5: '#7b7979',
    error: '#FF0000',
    green: '#36801e',
    red: '#b32626',
    warning_red: '#ec2a1f',
    dark_red: '#9a1919',
    secondary: '#282828',
    //secondary: '#3c4858',
    secondary_hover: '#bfbfbf',
    light: '#f8f9fa',
    logo_1: '#e56325',
    logo_2: '#9bcb3c',
    logo_3: '#feba18',
    logo_4: '#15aed3',

    card_border: '#d1d1d6',
    card_background: '#ffffff',
    page_background: '#fbfbfb',

    primary_blue: '#4178BE',
    primary_dark: '#026090',

    secondary_blue: '#007aff',

    primary_orange: '#ff9500',
    primary_green: '#23b73d',
    primary_red: '#d3222a',

    secondary_orange: '#ffcc00',

    dark_green: '#0f9e28',

    very_light_grey: '#efeff4',
    light_grey: '#e5e5ea',
    light_middle_grey: '#d1d1d6',
    middle_grey: '#8e8e93',
    dark_middle_grey: '#8e8e93',
    nearly_white: '#f7f7f8',

    dark_grey: '#727272',
    menu_black: '#212529',
  },
}
