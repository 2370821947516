/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { map, has, get, replace, split } from 'lodash'
import { Modal } from 'react-bootstrap'
import {showSuccess, showError} from '../../actions/Error'
import { ButtonBar } from '../../components/layout/ButtonBar'
import FieldInfoText from '../../components/layout/FieldInfoText'
import { BlueButton } from '../../components/layout/BlueButton'
import { WhiteButton } from '../../components/layout/WhiteButton'
import AdminMainLayout from './AdminMainLayout'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikInputField } from '../../components/form/InputField'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import CommonTable from '../../components/CommonTable'
import CurrencyValue from '../../components/CurrencyValue'
import Pagination from '../../components/Pagination'
import Timestamp from '../../components/Timestamp'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFooter from './AdminTableFooter'
import AdminTableFilter from './AdminTableFilter'
import { Row, Col, Table } from 'react-bootstrap'
import { Container, Button } from 'react-bootstrap'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { InlineIconBar } from '../../components/layout/InlineIconBar'
import { adminBulkCustomerMigrationQueueList } from '../actions/admin_bulk_customer_migration_queue'
import { Separator } from '../../components/layout/Separator'
import * as Yup from 'yup'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { handleSubmitResult } from '../../actions/form'
import { globalSettingsList } from '../../actions/settings'
import { adminSyncLogStatuses, adminBulkCustomerMigrationStates } from '../actions/admin_dropdown_options'

class AdminBulkCustomerMigrationQueue extends Component {

    constructor(props) {
        super(props)
        this.state = {show_sync_form: false}
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminBulkCustomerMigrationQueueList.fetchListIfNeeded())
        dispatch(globalSettingsList.ensureGlobalSettingsLoaded())
        
        dispatch(adminSyncLogStatuses.fetchListIfNeeded())
        dispatch(adminBulkCustomerMigrationStates.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(adminBulkCustomerMigrationQueueList.fetchListIfNeeded())
    }

    getCellValue = (header_key, item, index) => {
        const that = this
        switch( header_key ) {
            case 'modified_at':
                return (
                    <Timestamp value={item.modified_at} format='datetime' />
                )
            case 'sync_description':
                return (
                    <div>
                      {item.sync_description}
                      {item.sync_extended_note && map(split(item.sync_extended_note, "\n"), (x) => <div>{x}</div>)}
                    </div>
                )
            default:
                return undefined
        }
    }

    renderFilter = (formik_props) => {
        const { sync_log_statuses, bulk_customer_migration_states } = this.props
        return (
            <>
              <FormikDropdownField options={bulk_customer_migration_states}
                                   placeholder="Queue status"
                                   empty_selection_label="All"
                                   formik_props={formik_props} name="queue_status" />
              <FormikDropdownField options={sync_log_statuses}
                                   placeholder="Sync log status"
                                   empty_selection_label="All"
                                   formik_props={formik_props} name="sync_log_status" />
              <FormikInputField placeholder="Email"
                                formik_props={formik_props}
                                name="email" />
              <FormikInputField placeholder="Description"
                                formik_props={formik_props}
                                name="any_field" />
            </>

        )
    }

    onFilter = (values) => {
        const { dispatch } = this.props
        dispatch(adminBulkCustomerMigrationQueueList.updateListFilter(values))
    }

    render() {
        const {
            headers,
            bulk_customer_migrations,
            is_loading
        } = this.props
        
        return (
            <AdminMainLayout active_key="sync_logs"
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'bulk_customer_migration_queue', label: 'Bulk Customer Migration', url: '/admin/bulk_customer_migration_queue'}]}>
              <AdminTableHeader title="Bulk Customer Migration Queue">
                <div css={ css`width:100%;`}>
                  <AdminTableFilter updateOnChange={ this.onFilter }
                                    renderFilter={this.renderFilter}
                  />
                </div>
              </AdminTableHeader>
              <Container fluid>
                <CommonTable is_loading={ is_loading }
                             empty_message="There are no bulk migrations."
                             headers={ headers }
                             items={ bulk_customer_migrations }
                             item_list={ adminBulkCustomerMigrationQueueList }
                             getCellValue={ this.getCellValue }
                             sortOnHeader={ this.sortOnHeader }
                />
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const bulk_customer_migrations = adminBulkCustomerMigrationQueueList.getVisibleObjects() || []
    const sync_log_statuses = adminSyncLogStatuses.getAsOptions()
    const bulk_customer_migration_states = adminBulkCustomerMigrationStates.getAsOptions()
    return {
        bulk_customer_migrations,
        headers: {
            email: { name: "Email" },
            modified_at: { name: 'Modified' },
            status: { name: 'Queue status' },
            sync_status: { name: 'Sync status' },
            sync_description: { name: 'Description' },
            sync_progress: { name: 'Progress' },
            sync_last_error:  { name: 'Error' },
        },
        is_loading: adminBulkCustomerMigrationQueueList.isLoading(),
        sync_log_statuses,
        bulk_customer_migration_states
    }
}
export default withRouter(connect(mapStateToProps)(AdminBulkCustomerMigrationQueue))

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`
