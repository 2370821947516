/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { map, get, size, forEach } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import WrappingBusyMask from './WrappingBusyMask'
import { Formik, Form } from 'formik'
import GlobalStyles from './GlobalStyles'
import {
  NewCustomerSignupForm,
  validationSchema,
} from './NewCustomerSignupForm'
import { newCustomerSignupCreateCustomer } from '../actions/new_customer_signup'
import {
  LanguageFlagSelector,
  getActiveLanguageCode,
} from './LanguageFlagSelector'
import { default_theme as theme } from '../emotion/theme'
import { Separator } from './layout/Separator'
import { showSuccess, showError } from '../actions/Error'
import {
  announceUserLoggedIn,
  getPostAuthenticatedAction,
  clearPostAuthenticatedAction,
} from '../actions/auth'
import { Row, Col, Container, Popover, Button } from 'react-bootstrap'
import xoip_logo from '../images/xoip-logo.svg'
import { handleSubmitResult } from '../actions/form'

export const NewCustomerSignup = ({ ...props }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const initial_values = {}

  const languageCode = useSelector((state) =>
    get(state, ['auth', 'language_code'], 'nl')
  )
  const post_authenticated_action = getPostAuthenticatedAction()

  const is_saving = useSelector(() =>
    newCustomerSignupCreateCustomer.getIsSavingObject()
  )

  const onCreateAccount = (values, formik_props) => {
    const on_ok = (json) => {
      if (json.status !== 'success') {
        showError(JSON.stringify(json.errors))
      } else {
        dispatch(announceUserLoggedIn(json.token, json.user))

        if (post_authenticated_action.action == 'go') {
          clearPostAuthenticatedAction()
          history.push(post_authenticated_action.value)
        } else {
          history.push('/subscription/signup')
        }
      }
    }
    dispatch(newCustomerSignupCreateCustomer.saveNewObject(values)).then(
      (res) => handleSubmitResult({ res, on_ok })
    )
  }

  const renderHeader = () => {
    const that = this
    return (
      <Container fluid>
        <Row>
          <Col md={12}>
            <div>
              <div>
                <div css={logo_row_style}>
                  <img src={xoip_logo} alt="" css={image_style} />
                  <LanguageFlagSelector />
                </div>
                <h2 css={h2_style}>
                  <Trans>Thank you for choosing XOIP!</Trans>
                </h2>
                <div css={sub_heading_style}>
                  <Trans>
                    Let's get you signed up. It won't take more than two
                    minutes.
                  </Trans>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <div css={main_style}>
      <GlobalStyles />
      {renderHeader()}
      <Separator variant="h40" />

      <Formik
        initialValues={initial_values}
        onSubmit={onCreateAccount}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
        {(formik_props) => {
          const { values } = formik_props
          return (
            <WrappingBusyMask is_loading={is_saving}>
              <NewCustomerSignupForm formik_props={formik_props} />
            </WrappingBusyMask>
          )
        }}
      </Formik>
    </div>
  )
}

const main_style = css`
  margin-top: 50px;
  margin-bottom: 100px;
  margin-left: 15px;
  margin-right: 15px;
`

const h2_style = css`
  font-size: 28px;
  width: 100%;
  margin-top: 10px;
`

const sub_heading_style = css`
  font-size: 16px;
  line-height: 19px;
  display: flex;
  justify-content: space-between;
`

const image_style = css`
  height: 42px;
`

const logo_row_style = css`
  display: flex;
  justify-content: space-between;
`
