/** @jsxImportSource @emotion/react */
import React, {Component} from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../emotion/theme'

export const PhoneNumber = ({ phone_number, enable_inline_spacing, ...props }) => {
    
    if ( ! phone_number ) {
        return null
    }
    return (
        <>
          { enable_inline_spacing && <span>&nbsp;</span> }
          <span>+{phone_number.substr(2)}</span>
          { enable_inline_spacing && <span>&nbsp;</span> }
        </>
    )
}
