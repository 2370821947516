/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { default_theme as theme } from '../../emotion/theme'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { get, map, size, keys } from 'lodash'
import { ButtonBar } from '../../components/layout/ButtonBar'
import Timestamp from '../../components/Timestamp'
import { confirmModal } from '../../actions/ui'
import { Trans, Translation } from 'react-i18next'
import { adminPhoneNumberPrefixList, adminPhoneNumberPrefixListForSet } from '../actions/admin_phone_number_prefix'
import { adminProductList } from '../actions/admin_product'
import { countryList } from '../../actions/country'
import { cityList } from '../../actions/city'
import { Formik, Form, Field } from 'formik'
import { FormLabelValue } from '../../components/form/FormLabelValue'
import { FormikDropdownField } from '../../components/form/Dropdown'
import FieldInfoText from '../../components/layout/FieldInfoText'
import { PrettyValue } from '../../components/PrettyValue'
import { LabelValue } from '../../components/layout/LabelValue'
import { FormikInputField } from '../../components/form/InputField'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { GrayButton } from '../../components/layout/GrayButton'
import Loading from '../../components/Loading'
import AdminMainLayout from './AdminMainLayout'
import BusyMask from '../../components/BusyMask'
import Modal from '../../components/Modal'
import {showSuccess, showError} from '../../actions/Error'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import { Col, Row, Container, Tabs, Tab, Button } from 'react-bootstrap'
import Card from '../../components/layout/Card'
import { CardHeader } from '../../components/layout/CardHeader'
import { Form as BootstrapForm } from 'react-bootstrap'
import { FormikTextarea } from '../../components/form/TextareaField'
import { BlueLinkButton } from '../../components/layout/BlueLinkButton'
import { PhoneNumber } from '../../components/PhoneNumber'
import { Separator } from '../../components/layout/Separator'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import AdminAssociatedProductList from './AdminAssociatedProductList'
import { handleSubmitResult } from '../../actions/form'
import { adminPhoneNumberStatuses, adminNumberPrefixTypes, adminPhoneNumberPrefixAllocationOptions } from '../actions/admin_dropdown_options'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    prefix: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
    number_type: Yup.string().required("Required"),
    number_of_digits_in_phone_number: Yup.string().required("Required"),
})

class AdminNumber extends Component {

    componentDidMount() {
        const { dispatch, prefix_id, product_ids } = this.props
        dispatch(adminPhoneNumberPrefixList.fetchListIfNeeded())
        dispatch(adminPhoneNumberPrefixList.ensureObjectLoaded(prefix_id))
        dispatch(countryList.updatePaginationNumItemsPerPage(1000))
        dispatch(cityList.updatePaginationNumItemsPerPage(1000))
        dispatch(countryList.fetchListIfNeeded())
        dispatch(cityList.fetchListIfNeeded())
        dispatch(adminNumberPrefixTypes.fetchListIfNeeded())
        dispatch(adminPhoneNumberStatuses.fetchListIfNeeded())
        dispatch(adminPhoneNumberPrefixAllocationOptions.fetchListIfNeeded())
        if ( product_ids ) {
            dispatch(adminProductList.ensureObjectsLoaded(product_ids))
        }
    }

    componentDidUpdate() {
        const { dispatch, prefix, prefix_id, city_filter, product_ids } = this.props
        dispatch(adminPhoneNumberPrefixList.ensureObjectLoaded(prefix_id))
        dispatch(countryList.fetchListIfNeeded())
        if ( get(prefix, "country") && city_filter.country !== prefix.country ) {
            dispatch(cityList.filterOnCountry(prefix.country))
        }
        dispatch(cityList.fetchListIfNeeded())
        if ( product_ids ) {
            dispatch(adminProductList.ensureObjectsLoaded(product_ids))
        }
    }

    onSave = (values, formik_funcs) => {
        const { history, dispatch, prefix_id } = this.props
        const that = this
        const on_ok = function(json) {
            dispatch(adminPhoneNumberPrefixList.invalidateList())
            dispatch(adminPhoneNumberPrefixListForSet.invalidateList())
            showSuccess("Saved", "Number prefix saved")
            if ( ! prefix_id ) {
                history.push(`/admin/number_prefix/${json.id}`)
            }
        }
        if ( prefix_id ) {
            values.id = prefix_id
            return dispatch(adminPhoneNumberPrefixList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminPhoneNumberPrefixList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    onCountryChanged = (country) => {
        const { dispatch } = this.props
        dispatch(cityList.filterOnCountry(country.value))
    }

    onDeletePrefix = () => {
        const { dispatch, history, prefix_id } = this.props
        const on_ok = function(json) {
            showSuccess("Deleted", "Prefix has been deleted")
            history.push("/admin/number_prefixes")
        }

        const onConfirmed = () => {
            return dispatch(adminPhoneNumberPrefixList.deleteObject(prefix_id)).then(on_ok)
        }
        
        const text = (<Trans>Are you sure you want to delete this prefix?</Trans>)
        return dispatch(confirmModal({text:text,
                                      onConfirmed: onConfirmed}))
        
    }

    renderUsageTotal(value, label) {
        const { phone_number_statuses } = this.props
        return (
            <div css={usage_total_row_style} key={label}>
              <div css={usage_total_style}>
                <PrettyValue value={value} />
              </div>
              { get(phone_number_statuses, [label, "name"], label) }
            </div>
        )
    }

    renderUsageTotals(formik_props) {
        const { prefix } = this.props
        if ( ! get(prefix, "num_phone_numbers_by_status") ) {
            return null
        }
        return (
            <Card variant="white_wide_padding">

              <FormikCheckboxField name="active" label="Active" />
              
              <FieldInfoText>
                Numbers in inactive prefixes will not be provisioned to customers.
              </FieldInfoText>
              <Separator variant="h30"/>

              <div>
                { this.renderUsageTotal(prefix.num_phone_numbers_by_status['total'], 'numbers') }
                { map(keys(prefix.num_phone_numbers_by_status), (status) => status !== 'total' ? this.renderUsageTotal(prefix.num_phone_numbers_by_status[status], status) : null) }
              </div>
            </Card>
        )
    }

    renderForm(formik_props) {
        const { number, country_options, city_options, number_type_options, allocation_options } = this.props
        return (
            <Card variant="white_wide_padding">
              <FormLabelValue spacing="h10">
                Prefix
                <FormikInputField name="prefix" />
              </FormLabelValue>

              <FormLabelValue spacing="h10">
                Number of digits in full phone number
                <FormikInputField name="number_of_digits_in_phone_number" />
              </FormLabelValue>
              
              <FormLabelValue spacing="h10">
                Country
                <FormikDropdownField name="country"
                                     formik_props={formik_props}
                                     options={country_options}
                                     on_change={this.onCountryChanged}
                                     placeholder="Select country" />
              </FormLabelValue>

              <FormLabelValue spacing="h10">
                Type
                <FormikDropdownField name="number_type"
                                     formik_props={formik_props}
                                     options={number_type_options}
                                     placeholder="Select type" />
              </FormLabelValue>

              { get(formik_props, ["values", "number_type"]) == 'geographic' &&
                <FormLabelValue spacing="h10">
                  City
                  <FormikDropdownField name="city"
                                       formik_props={formik_props}
                                       options={city_options}
                                       placeholder="Select city" />
                </FormLabelValue>
              }

              <FormLabelValue spacing="h10">
                Allocation method (used when creating new subscriptions)
                <FormikDropdownField name="allocation_method"
                                     formik_props={formik_props}
                                     options={allocation_options}
                                     placeholder="Select allocation method" />
              </FormLabelValue>

              <GrayButton type="submit">
                Save
              </GrayButton>
              
            </Card>
        )
    }

    renderAssociatedProducts(formik_props) {
        const { products } = this.props
        return <AdminAssociatedProductList products={products} label="prefix" />
    }
    
    render() {
        const { prefix_id, is_busy, is_loading, prefix, initial_values, product_ids } = this.props
        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'number_prefixes', label: 'Number prefixes', url: '/admin/number_prefixes'},
                                           {name: 'prefix', label: get(prefix, "prefix"), url: `/admin/number_prefix/${prefix_id}`}]}>
              { is_busy && <BusyMask /> }
              <WrappingBusyMask is_loading={is_loading}>
                <Container fluid>

                  <Row>
                    <Col md="8">
                      <div css={header_style}>
                        <h1>
                          { prefix_id &&
                            <div>
                              { get(prefix, "prefix")} - {get(prefix, ["country_name"], "None")} - { get(prefix, ["city_name"], "None") }
                            </div>
                          }
                          { !prefix_id && "New prefix" }
                        </h1>
                        { prefix_id && size(product_ids) === 0 && 
                          <WhiteButton onClick={this.onDeletePrefix} extra_css={delete_number_button_style}>Delete number prefix</WhiteButton>
                        }
                      </div>
                    </Col>
                    
                  </Row>
                  
                  <Formik
                    initialValues={initial_values}
                    onSubmit={this.onSave}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                  >
                    {formik_props => {
                        const { values } = formik_props
                        return (
                            <Form>
                              <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                              <Row>
                                  <Col md="4">
                                    { prefix_id && this.renderUsageTotals(formik_props) }
                                  </Col>
                                <Col md="4">
                                  { this.renderForm(formik_props) }
                                </Col>
                                <Col md="4">
                                  { prefix_id && this.renderAssociatedProducts(formik_props) }
                                </Col>
                              </Row>
                            </Form>
                        )
                    }}
                  </Formik>
                </Container>
              </WrappingBusyMask>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const prefix_id = get(props, ["match", "params", "phone_number_prefix_id"], null)
    const prefix = prefix_id && adminPhoneNumberPrefixList.getObject(prefix_id)
    const country_options = countryList.getAsOptionsWithPriority()
    const city_options = cityList.getAsOptions({show_prefix_in_label:true})
    const product_ids = get(prefix, "products_in_order")
    const products = product_ids && adminProductList.getObjects(product_ids)
    const is_loading = prefix_id && (adminPhoneNumberPrefixList.isLoading() ||
                                     (size(product_ids)>0 && adminProductList.isLoading()) ||
                                     adminPhoneNumberStatuses.isLoading() ||
                                     adminPhoneNumberPrefixAllocationOptions.isLoading()
                                    )

    
    return {
        prefix_id,
        prefix,
        country_options,
        city_options,
        number_type_options: adminNumberPrefixTypes.getAsOptions(),
        phone_number_statuses: adminPhoneNumberStatuses.getObjectsById(),
        allocation_options: adminPhoneNumberPrefixAllocationOptions.getAsOptions(),
        city_filter: cityList.getFilter(),
        is_loading,
        is_busy: adminPhoneNumberPrefixList.getIsSavingObject(),
        initial_values: prefix || {prefix: '',
                                   number_type: 'geographic',
                                   country: '',
                                   city: '',
                                   active: true,
                                   call_rate_per_minute_euros: ''},
        product_ids,
        products
    }
}
export default withRouter(connect(mapStateToProps)(AdminNumber))

const header_style = css`
display: flex;
justify-content: space-between;
`

const usage_total_row_style = css`
font-size: 16px;
line-height: 18px;
display: flex;
align-items: baseline;
margin-bottom: 15px;
`

const usage_total_style = css`
font-weight: 500;
font-size: 18px;
margin-right: 5px;
`

const delete_number_button_style = css`
color: ${theme.colors.dark_middle_grey};
`
