/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { map, get, remove, includes, size, some } from 'lodash'
import { jsx, css } from '@emotion/react'
import Card from './layout/Card'
import { MobileOnly } from './layout/MobileOnly'
import { DesktopOnly } from './layout/DesktopOnly'
import { CardHeader } from './layout/CardHeader'
import CommonTable from './CommonTable'
import { cdrList } from '../actions/cdr'
import { faxItemList } from '../actions/fax_item'
import FaxTraffic from './FaxTraffic'
import VoiceTraffic from './VoiceTraffic'
import { RadioGroupFieldStandalone } from './form/RadioGroupFieldStandalone'
import { Container, Navbar, Nav, Col, Row } from 'react-bootstrap'
import OutgoingFaxItemDetails from './OutgoingFaxItemDetails'
import Timestamp from './Timestamp'
import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InlineIcon } from './layout/InlineIcon'
import { InlineIconBar } from './layout/InlineIconBar'
import { CallHistoryTitle } from './CallHistoryTitle'
import { customerList } from '../actions/customer'
import qs from 'query-string'
import { Trans } from 'react-i18next'
import { BlueButton } from './layout/BlueButton'

export const MESSAGE_FILTER_DIRECTION_OPTIONS = [
  { value: 'all', label: <Trans>All</Trans> },
  { value: 'incoming', label: <Trans>Incoming</Trans> },
  { value: 'outgoing', label: <Trans>Outgoing</Trans> },
]

class CallHistoryCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      message_filter: { type: 'voice', direction: 'all' },
      showing_fax_item_details: null,
      hasIncomingFax: false,
      hasOutgoingFax: false,
    }
  }

  componentDidMount() {
    const { message_filter } = this.state
    const { location, dispatch } = this.props
    if (size(location.search) > 0) {
      const filter_from_location = qs.parse(location.search)
      this.updateFilter(filter_from_location)
    } else {
      const saved_filter = cdrList.getSavedFilter()
      if (saved_filter) {
        this.updateFilter(saved_filter)
      } else {
        this.updateFilter(message_filter)
      }
    }

    dispatch(faxItemList.fetchListIfNeeded())

    this.setState({
      hasIncomingFax: some(faxItemList, (item) => item.incoming_fax !== null),
      hasOutgoingFax: some(faxItemList, (item) => item.incoming_fax == null),
    })
  }

  updateFilter(filter) {
    const { dispatch, history, location } = this.props
    this.setState({ message_filter: filter })

    const filter_from_location = get(location, ['query', 'active_filter'])
    const saved_filter = cdrList.getSavedFilter()

    if (filter.type === 'voice') {
      dispatch(cdrList.updateListFilter(filter))
      dispatch(cdrList.fetchListIfNeeded())
    } else if (filter.type === 'fax') {
      dispatch(faxItemList.updateListFilter(filter))
      dispatch(faxItemList.fetchListIfNeeded())
    }

    if (saved_filter != filter) {
      cdrList.setSavedFilter(filter)
    }

    if (filter_from_location !== filter) {
      history.push({ search: qs.stringify(filter) })
    }
  }

  onChangeMessageFilterType = (value) => {
    const { message_filter } = this.state
    message_filter.type = value
    this.updateFilter(message_filter)
  }

  onChangeMessageFilterDirection = (value) => {
    const { message_filter } = this.state
    message_filter.direction = value
    this.updateFilter(message_filter)
  }

  renderMessageFilterWidget(message_filter, message_filter_direction_options) {
    return (
      <>
        {/* <DesktopOnly> */}
        <RadioGroupFieldStandalone
          name="message_filter_direction"
          value={message_filter.direction}
          onChange={this.onChangeMessageFilterDirection}
          options={message_filter_direction_options}
        />
        {/* </DesktopOnly> */}
      </>
    )
  }

  renderFilter() {
    const { message_filter, hasIncomingFax, hasOutgoingFax } = this.state
    const { customer, history } = this.props
    if (!customer.id) {
      return null
    }

    let title
    const message_filter_type_options = []
    const has_voice = customer.has_active_voice_in_subscription
    const has_fax =
      customer.has_active_fax_out_subscription ||
      customer.has_active_fax_in_subscription

    if (has_voice && has_fax) {
      message_filter_type_options.push({
        value: 'voice',
        label: <Trans>Voice Calls</Trans>,
      })
      message_filter_type_options.push({
        value: 'fax',
        label: <Trans>Fax Messages</Trans>,
      })
    } else if (has_voice) {
      message_filter_type_options.push({
        value: 'voice',
        label: <Trans>Voice Calls</Trans>,
      })
      if (message_filter.type !== 'voice') {
        message_filter.type = 'voice'
        this.updateFilter(message_filter)
      }
    } else if (has_fax) {
      message_filter_type_options.push({
        value: 'fax',
        label: <Trans>Fax Messages</Trans>,
      })
      if (message_filter.type !== 'fax') {
        message_filter.type = 'fax'
        this.updateFilter(message_filter)
      }
    }

    const message_filter_direction_options = [
      { value: 'all', label: <Trans>All</Trans> },
    ]
    if (message_filter.type === 'fax') {
      if (hasIncomingFax) {
        message_filter_direction_options.push({
          value: 'incoming',
          label: <Trans>Incoming</Trans>,
        })
      }
      if (hasOutgoingFax) {
        message_filter_direction_options.push({
          value: 'outgoing',
          label: <Trans>Outgoing</Trans>,
        })
      }
    }

    return (
      <CardHeader
        title={<CallHistoryTitle customer={customer} />}
        left_child={
          <div css={filter_bar_styles}>
            {size(message_filter_type_options) > 1 ? (
              <RadioGroupFieldStandalone
                name="message_filter_type"
                value={message_filter.type}
                onChange={this.onChangeMessageFilterType}
                options={message_filter_type_options}
              />
            ) : null}
            {size(message_filter_direction_options) > 2 &&
              this.renderMessageFilterWidget(
                message_filter,
                message_filter_direction_options
              )}
          </div>
        }
        right_child={
          <>
            {get(customer, 'has_active_fax_out_subscription') && (
              <BlueButton
                auto_disable={false}
                onClick={() => history.push('/send_a_fax')}
              >
                <Trans i18nKey="send_a_fax_button">Send a fax</Trans>
              </BlueButton>
            )}
          </>
        }
      />
    )
  }

  render() {
    const { message_filter } = this.state
    return (
      <div>
        {this.renderFilter()}
        <div css={table_container_style}>
          {message_filter.type === 'voice' && (
            <VoiceTraffic cdrList={cdrList} />
          )}
          {message_filter.type === 'fax' && (
            <FaxTraffic faxItemList={faxItemList} />
          )}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    customer: customerList.getCustomer(),
    faxItemList: state.faxItemList,
  }
}

export default withRouter(connect(mapStateToProps)(CallHistoryCard))

const fax_queue_item_heading = css`
  font-weight: bold;
`

const fax_queue_item_info = css`
  font-size: 12px;
`

const fax_status_row = css`
  display: flex;
`

const table_container_style = css`
  margin-top: 15px;
`
const filter_bar_styles = css`
  display: flex;
  gap: 20px;

  /* Media query for pc screens */
  @media (max-width: 1024px) {
  }
  /* Media query for tablet screens */
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
  /* Media query for phone screens */
  @media (max-width: 480px) {
    flex-direction: column;
    gap: 10px;
  }
`
