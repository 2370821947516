/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/react'
import ReactCountryFlag from "react-country-flag"
import { Trans, Translation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Separator } from './layout/Separator'
import { globalSettingsList } from '../actions/settings'

export const FaxVerificationExpiredMessage = ({ ...props }) => {

    return (
        <div>
          <Trans>
            The fax you sent from your email earlier has now expired. Please send the fax again.
          </Trans>
          <Separator variant="h10" />

          <Trans>
            After you sent your email to fax you have 15 minutes to click the confirmation link in the verification reply-email you receive from XOIP. After that time XOIP will immediately and securely delete your file from the XOIP servers, so that potentially private information in the file will not be stored for longer than necessary.
          </Trans>
          <Separator variant="h10" />

          <Trans>To change your security options, for example to send faxes without requiring this confirmation, </Trans>
          <Link to="/send_a_fax"><Trans>log in</Trans></Link>,
          &nbsp;<Trans>and open 'More options' under 'Email to fax'</Trans>
          
        </div>
    )

}
