/** @jsxImportSource @emotion/react */
import React,{ useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { Row, Col, Container } from 'react-bootstrap'
import NestedWrappingBusyMask from './NestedWrappingBusyMask'
import { Trans, Translation } from 'react-i18next'
import { BlueButton } from './layout/BlueButton'
import { Separator } from './layout/Separator'
import { DropdownStandalone } from './form/Dropdown'
import { default_theme as theme } from '../emotion/theme'
import { map, size, get, head } from 'lodash'
import { SubscriptionOptionWithExpiryDate } from './SubscriptionOptionWithExpiryDate'

export const UpgradePathWidgetSubscriptionNumberSelector = ({customerSubscriptionItemList}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const subscriptions = useSelector(() => customerSubscriptionItemList.getVisibleObjects())
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(get(head(subscriptions), "id"))
    
    const subscriptions_as_options = map(subscriptions, function(subscription)  {
        return { value: subscription.id,
                 label: <SubscriptionOptionWithExpiryDate subscription={subscription} />
               }
    })

    useEffect(() => {
        setSelectedSubscriptionId(get(head(subscriptions), "id"))
    }, [size(subscriptions)])

    const onChangeSelectedSubscriptionId = (new_subscription_id) => {
        setSelectedSubscriptionId(new_subscription_id)
    }

    const onMoveToANewSubscription = () => {
        history.push(`/subscription_upgrade/${selectedSubscriptionId}`)
    }

    return (
        <div>
          <h3>
            <Trans i18nKey="upgrade_path_free_to_paid_upsell_fax__i_want_to_keep">
              I want to keep my number(s)
            </Trans>
          </h3>

          <Row>
            <Col>
              <NestedWrappingBusyMask is_loading={!selectedSubscriptionId}>
                { selectedSubscriptionId && 
                  <DropdownStandalone options={subscriptions_as_options}
                                      default_value={selectedSubscriptionId}
                                      value={selectedSubscriptionId}
                                      on_change={onChangeSelectedSubscriptionId}
                  />
                }
              </NestedWrappingBusyMask>
            </Col>
          </Row>
          <Separator variant="h10" />
          <Row>
            <Col>
              { selectedSubscriptionId && 
                <BlueButton onClick={onMoveToANewSubscription} extra_css={move_to_new_subscription_button_style}>
                  <Trans i18nKey="upgrade_path_free_to_paid_upsell_fax__move_to_a_new_subscription_button">
                    Move to a new subscription
                  </Trans>
                </BlueButton>
              }
            </Col>
          </Row>
        </div>
    )
    
}

const move_to_new_subscription_button_style = css`
width: auto;
`
