/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/react'
import { useField } from 'formik'
import FormError from './FormError'
import FormWarning from './FormWarning'
import { default_theme as theme } from '../../emotion/theme'

export const FormikTextarea = ({ rows, placeholder, ...props }) => {
    const [field, meta] = useField(props)
    const extra_css = (meta && meta.touched && meta.error && error_class) || null

    return (
        <div>
          <textarea {...field} rows={rows || 10} css={[default_style, extra_css]} placeholder={placeholder} />
          <FormError meta={meta} />
          <FormWarning meta={meta} />
        </div>
    )
}

const default_style = css`
width: 100%;
padding: 5px;
border: 1px solid ${theme.colors.light_middle_grey};
`

const error_class = css`
  border: 1px solid ${theme.colors.error};
`
