import { ItemList } from '../orm'

class FaxFromEmailAddressList extends ItemList {
    getEntityKey() {
        return "customer_fax_from_email_address"
    }
}

export const faxFromEmailAddressList = new FaxFromEmailAddressList("fax_from_email_address__default")


class FaxFromEmailAddressVerify extends ItemList {
    getEntityKey() {
        return "customer_fax_from_email_address/verify"
    }

    verify({verification_id, verification_token}) {
        return this.saveNewObject({verification_id: verification_id, verification_token: verification_token})
    }
    
}

export const faxFromEmailAddressVerify = new FaxFromEmailAddressVerify("fax_from_email_address_verify__default")
