/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'

export const FormHintLabel = ({ children, ...props }) => {

    return (
        <span css={label_style}>
          {children}
        </span>
    )

}

const label_style = css`
font-size: 12px;
`
