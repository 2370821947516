import { get, has, isEmpty, size } from 'lodash'
import { http } from '../lib'
import cookie from 'react-cookies'
import i18n from '../i18n'
import { SubmissionError } from '../orm/error'

export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN"
export const CLEAR_AUTH_TOKEN = "CLEAR_AUTH_TOKEN"
export const USER_LOGGED_IN = "USER_LOGGED_IN"
export const ANNOUNCE_LOGGING_IN_USER = "ANNOUNCE_LOGGING_IN_USER"
export const ANNOUNCE_USER_NOT_LOGGED_IN = "ANNOUNCE_USER_NOT_LOGGED_IN"
export const ANNOUNCE_SENDING_TOKEN_EMAIL = "ANNOUNCE_SENDING_TOKEN_EMAIL"
export const ANNOUNCE_TOKEN_EMAIL_SENT = "ANNOUNCE_TOKEN_EMAIL_SENT"
export const ANNOUNCE_TOKEN_EMAIL_NOT_SENT = "ANNOUNCE_TOKEN_EMAIL_NOT_SENT"
export const ANNOUNCE_SETTING_PASSWORD = "ANNOUNCE_SETTING_PASSWORD"
export const ANNOUNCE_PASSWORD_SET = "ANNOUNCE_PASSWORD_SET"
export const ANNOUNCE_PASSWORD_NOT_SET = "ANNOUNCE_PASSWORD_NOT_SET"
export const ANNOUNCE_USER_LOGGED_OUT = "ANNOUNCE_USER_LOGGED_OUT"
export const ANNOUNCE_UPDATING_USER = "ANNOUNCE_UPDATING_USER"
export const ANNOUNCE_USER_UPDATED = "ANNOUNCE_USER_UPDATED"
export const ANNOUNCE_USER_NOT_UPDATED = "ANNOUNCE_USER_NOT_UPDATED"
export const ANNOUNCE_LANGUAGE_CHANGED = "ANNOUNCE_LANGUAGE_CHANGED"
export const FORCE_LANGUAGE_CODE_PRELOGIN = "FORCE_LANGUAGE_CODE_PRELOGIN"
export const LANGUAGE_UPDATED = "LANGUAGE_UPDATED"

export function announceUserNotLoggedIn() {
    return {
        type: ANNOUNCE_USER_NOT_LOGGED_IN
    }
}

export function announceUserLoggedIn(token, user) {
    setAuthToken(token, user)
    return {
        type: USER_LOGGED_IN,
        token,
        user
    }
}

export function announceLoggingInUser() {
    return {
        type: ANNOUNCE_LOGGING_IN_USER
    }
}

export function announceSendingTokenEmail() {
    return {
        type: ANNOUNCE_SENDING_TOKEN_EMAIL
    }
}

export function announceTokenEmailSent() {
    return {
        type: ANNOUNCE_TOKEN_EMAIL_SENT
    }
}

export function announceTokenEmailNotSent() {
    return {
        type: ANNOUNCE_TOKEN_EMAIL_NOT_SENT
    }
}

export function announceSettingPassword() {
    return {
        type: ANNOUNCE_SETTING_PASSWORD
    }
}

export function announcePasswordSet(user) {
    cookie.remove('user', { path: '/' })
    cookie.save('user', user, {path: '/' })
    return {
        type: ANNOUNCE_PASSWORD_SET
    }
}

export function isSettingPassword(state) {
    return get(state, ["auth", "setting_password"], false)
}

export function getLastSettingPasswordError(state) {
    return get(state, ["auth", "last_setting_password_error"], null)
}


function announcePasswordNotSet(reason) {
    return {
        type: ANNOUNCE_PASSWORD_NOT_SET,
        reason: reason
    }
}

export function announceUserLoggedOut() {
    return {
        type: ANNOUNCE_USER_LOGGED_OUT
    }
}

export function announceUpdatingUser() {
    return {
        type: ANNOUNCE_UPDATING_USER
    }
}

export function announceUserUpdated(user) {
    cookie.remove('user', { path: '/' })
    cookie.save('user', user, {path: '/' })
    return {
        type: ANNOUNCE_USER_UPDATED
    }
}

export function announceUserNotUpdated() {
    return {
        type: ANNOUNCE_USER_NOT_UPDATED
    }
}


export function login(email, password) {                                                                           
    return async(dispatch, getState) => {
        dispatch(announceLoggingInUser())                                                                       
        let [json, success] = await http.post(getState(), 'login/login', { email: email, password: password })                                                                                            
        if (success.status === 200 && has(json, 'token')) {
            dispatch(announceUserLoggedIn(json.token, json.user || {}))
            dispatch(checkForceLanguageChangePreLogin(json.user))
        } else {
            dispatch(announceUserNotLoggedIn())
            return { 'errors': {_error: "Failed to login with your credentials" } }
        }                                        
        if (!json.success) {
            return { 'errors': {_error: json.message } }
        }
        return json
    }
}

export function auto_login(user_id, otp) {
    return async(dispatch, getState) => {
        try {                                                                                                       
            dispatch(announceLoggingInUser())                                                                       
            let [json, success] = await http.post(getState(), 'login/auto_login', { user: user_id, otp: otp })                                                                                            
            if (success.status === 200 && has(json, 'token')) {
                dispatch(announceUserLoggedIn(json.token, json.user || {}))                                         
            } else {
                dispatch(announceUserNotLoggedIn())
                return { 'errors': 'Failed to login' }
            }                                        
            if (!json.success) {
                return { 'errors': json.message }
            }
            return json
        } catch (e) {
            return {'errors': e}
        }                                                                                                          
    }
}

export function isLoggedIn() {
    const token = authToken()
    return ! isEmpty(token) && size(token) > 0
}

export function authToken() {
    return cookie.load('apitoken')
}

export function setAuthToken(token, user) {
    cookie.save('user', user, { path: '/'})
    cookie.save('apitoken', token, { path: '/' })
}

export function setPostAuthenticatedAction(action, value) {
    cookie.save('post_authenticated_action', action, { path: '/'})
    cookie.save('post_authenticated_action_value', value, { path: '/'})
}

export function getPostAuthenticatedAction() {
    return {action: cookie.load('post_authenticated_action'),
            value: cookie.load('post_authenticated_action_value')
           }
}

export function clearPostAuthenticatedAction() {
    cookie.remove('post_authenticated_action', { path: '/' })
    cookie.remove('post_authenticated_action_value', { path: '/' })
}

export function clearAuthentication() {
    cookie.remove('apitoken', { path: '/' })
    cookie.remove('user', { path: '/' })
    return {
        type: CLEAR_AUTH_TOKEN
    }
}

export function sendTokenEmail(user) {
    return async(dispatch, getState) => {
        dispatch(announceSendingTokenEmail())
        let success
        [success] = await http.post(getState(), 'user/send_otp_email', { user: user })
        if (success.status === 200) {
            dispatch(announceTokenEmailSent())
        } else {
            dispatch(announceTokenEmailNotSent())
        }
    }
}

export function sendForgotPasswordEmail(email) {
    return async(dispatch, getState) => {
        dispatch(announceSendingTokenEmail())
        let success
        [success] = await http.post(getState(), 'login/send_otp_email', { email: email, reset_password: true })
        if (success.status === 200) {
            dispatch(announceTokenEmailSent())
        } else {
            dispatch(announceTokenEmailNotSent())
        }
    }
}

export function loggedInUser() {
    return cookie.load('user')
}

export function setPassword({password, confirm_password}, on_done) {
    return async(dispatch, getState) => {
        dispatch(announceSettingPassword())

        if ( password !== confirm_password ) {
            dispatch(announcePasswordNotSet("password_doesnt_match"))
        } else {
            let [json, success] = await http.post(getState(), 'user/set_password', {password})
            if (success.status === 200 && has(json, 'status') && json['status'] === 'success') {            
                dispatch(announcePasswordSet(json.user))
                on_done()
            } else {
                dispatch(announcePasswordNotSet(json.reason))
            }
        }
    }
}

export function retrieveActiveLanguage() {
    cookie.load('language_code')
}

export function storeActiveLanguage(language_code) {
    return async(dispatch, getState) => {
        if ( ! language_code ) {
            return
        }
        if ( i18n.language !== language_code ) {
            i18n.changeLanguage(language_code)
            dispatch({type: LANGUAGE_UPDATED, language_code: language_code})
        }
    }
}

export function updateUserActiveLanguage(language_code, onDone) {
    return async(dispatch, getState) => {
        dispatch(storeActiveLanguage(language_code))
        if ( authToken() ) {
            let [json, success] = await http.post(getState(), 'user/set_language', {language_code: language_code})
            if (success.status === 200 && has(json, 'status') && json['status'] === 'success' ) {
                dispatch(storeActiveLanguage(language_code))
                if ( onDone ) {
                    onDone()
                }
            } else {
                throw new SubmissionError({_error: 'Unable to set language'})
            }
        } else {
            dispatch({
                type: FORCE_LANGUAGE_CODE_PRELOGIN,
                language_code: language_code
            })
        }
    }
}

export function checkForceLanguageChangePreLogin(user) {
    return async(dispatch, getState) => {
        const state = getState()
        const forced_language_code = get(state, ["auth", "language_code_prelogin"])
        if ( forced_language_code ) {
            dispatch(updateUserActiveLanguage(forced_language_code))
        }
        dispatch({
            type: FORCE_LANGUAGE_CODE_PRELOGIN,
            language_code: null
        })
    }
}

// export function setLanguage(language_code, on_done) {
//     return async(dispatch, getState) => {

//         if ( !language_code ) {
//             return
//         }
//         i18n.changeLanguage(language_code)

//         dispatch({type: ANNOUNCE_LANGUAGE_CHANGED,
//                   language_code: language_code})

//         if ( on_done && authToken() ) {
//             let [json, success] = await http.post(getState(), 'user/set_language', {language_code: language_code})
//             if (success.status === 200 && has(json, 'status') && json['status'] === 'success' ) {
//                 on_done()
//             } else {
//                 throw new SubmissionError({_error: 'Unable to set language'})
//             }
//         }
//     }
// }

export function updateUser(values, on_done) {
    return async(dispatch, getState) => {
        dispatch(announceUpdatingUser())
        let [json, success] = await http.post(getState(), 'user/update_user', values)
        if (success.status === 200 && has(json, 'status') && json['status'] === 'success') {            
            dispatch(announceUserUpdated(json.user))
            on_done()
        } else {
            dispatch(announceUserNotUpdated())
            throw new SubmissionError({_error: 'Unable to update user'})
        }
    }
}


// export function refreshLoggedInUserCookieFromCustomer(customer) {
//     const user = loggedInUser()
//     if ( user ) {
//         user.language_code = customer.language_code
//         cookie.save('user', user, {path: '/' })

//         if ( i18n.language !== user.language_code ) {
//             i18n.changeLanguage(user.language_code)
//         }
//     }
// }
