/** @jsxImportSource @emotion/react */
import React from 'react'
import { jsx, css, Global } from '@emotion/react'
import { get } from 'lodash'
import { default_theme as theme } from '../emotion/theme'
import { customerList } from '../actions/customer'

const ProfileMenuHeader = () => {
  const customer = customerList.getCustomer()

  return (
    <div>
      <div css={profile_menu__name_style}>{customer.display_name}</div>
      <div css={profile_menu__info_style}>{customer.email}</div>
      {customer.company && (
        <div css={profile_menu__info_style}>
          {get(customer, ['company', 'name'])}
        </div>
      )}
    </div>
  )
}

export default ProfileMenuHeader

const profile_menu__name_style = css`
  color: ${theme.colors.menu_black};
  font-size: 16px;
  font-weight: 600;
`

const profile_menu__info_style = css`
  color: ${theme.colors.dark_grey};
  font-size: 14px;
  font-weight: 400;
`
