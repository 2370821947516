/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { useField } from 'formik'
import moment from 'moment'
import { get, size, head } from 'lodash'
import {showSuccess, showError} from '../../actions/Error'
import { Duration } from '../Duration'
import { Trans, Translation } from 'react-i18next'
import { default_theme as theme } from '../../emotion/theme'
import { Separator } from '../layout/Separator'
import { Modal } from 'react-bootstrap'
import FormError from './FormError'
import { BlueButton } from '../layout/BlueButton'
import { GrayButton } from '../layout/GrayButton'
import { ButtonBar } from '../layout/ButtonBar'
import { BlueLinkButton } from '../layout/BlueLinkButton'
import FormWarning from './FormWarning'
import NestedWrappingBusyMask from '../NestedWrappingBusyMask'
import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrophoneAlt, faStop } from '@fortawesome/free-solid-svg-icons'
import loading_icon from '../../images/loading.gif'
import { fileInfoList } from '../../actions/file_info'
import ReactAudioPlayer from 'react-audio-player'

const RECORDING_BUFFER_DURATION_MILLISECONDS = 10

export const FormikAudioRecordingField = ({ onRecorded, onCancel, max_duration_seconds, ...props }) => {

    const createChunker = () => {
        let chunks = []
        return {
            addChunk: (chunk) => {
                chunks.push(chunk)
            },
            getChunks: () => chunks,
            reset: () => chunks = []
        }
    }
    
    const [showRecordingWidget, setShowRecordingWidget] = useState(false)
    const [isRecording, setIsRecording] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [recordingDurationSeconds, setRecordingDurationSeconds] = useState(0)
    const [recordingStartedAt, setRecordingStartedAt] = useState(null)
    const [mediaRecorder, setMediaRecorder] = useState(null)
    const recordingTimer = useRef(null)
    const chunker = useRef(createChunker())
    const [provisionalRecording, setProvisionalRecording] = useState(null)
    const [name, setName] = useState(null)
    
    const dispatch = useDispatch()

    useEffect(() => {
        return stopRecordingTimer
    }, [])

    const onSetName = (evt) => {
        setName(evt.currentTarget.value)
    }

    const onAudioRecorded = () => {
        const chunks = chunker.current.getChunks()
        if ( ! size(chunks) ) {
            return
        }

        const mediaType = chunks[0].type
        const blob = new window.Blob(chunks, { type: mediaType })
        const audioUrl = window.URL.createObjectURL(blob);
        setProvisionalRecording({blob, audioUrl})
    }

    const onAudioAccepted = () => {
        
        const on_ok = ({successes, failures}) => {
            setIsUploading(false)
            if ( size(failures) > 0 ) {
                showError("Server error", "Failed to upload")
                return
            }
            const fileInfo = head(successes)
            setShowRecordingWidget(false)
            onRecorded({fileInfo, name})
        }
        
        setIsUploading(true)
        const { blob } = provisionalRecording
        fileInfoList.uploadFiles({files: [blob], privacy_wipe_enabled: false}).then(on_ok)
    }

    const onAudioDataReceived = (e) => {
        if (e.data && e.data.size > 0) {
            chunker.current.addChunk(e.data)
        }
    }

    const stopRecordingTimer = () => {
        if ( recordingTimer.current ) {
            window.clearInterval(recordingTimer.current)
            recordingTimer.current = null
        }
    }
    
    const onStartRecording = async() => {
        if ( mediaRecorder ) {
            mediaRecorder.stop()
        }
        chunker.current.reset()
        if (! navigator.mediaDevices) {
            showError("Failed", "Media device unavailable")
            return
        }
        
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
        const newMediaRecorder = new window.MediaRecorder(stream)
        newMediaRecorder.ondataavailable = onAudioDataReceived
        const startTime = moment()
        setRecordingStartedAt(startTime)
        setMediaRecorder(newMediaRecorder)
        newMediaRecorder.start(RECORDING_BUFFER_DURATION_MILLISECONDS)
        setIsRecording(true)

        stopRecordingTimer()
        const timer = window.setInterval(() => {
            var duration = moment.duration(moment().diff(startTime))

            const duration_seconds = duration.seconds()
            if ( duration_seconds > max_duration_seconds ) {
                onStopRecording({save:true})
            } else {
                setRecordingDurationSeconds(duration_seconds)
            }

            
        }, 1000)
        recordingTimer.current = timer
    }

    const onStopRecording = ({save}) => {
        stopRecordingTimer()
        if ( mediaRecorder ) {
            mediaRecorder.stop()
        }
        setIsRecording(false)
        setMediaRecorder(null)
        if ( save ) {
            onAudioRecorded()
        }
    }

    const onCancelRecording = () => {
        onStopRecording({save: false})
        setShowRecordingWidget(false)
        onCancel()
    }

    const onRecordAgain = () => {
        if ( provisionalRecording ) {
            setProvisionalRecording(null)
            setRecordingDurationSeconds(0)
        }
        setShowRecordingWidget(true)
    }

    const renderWidgetReadyToRecord = () => {
        return (
            <div>
              <div onClick={onStartRecording} css={hover_button_style}>
                <div css={[circle_icon, green_icon]}>
                  <FontAwesomeIcon icon={faMicrophoneAlt} />
                </div>
                <Trans>Press to start recording</Trans>
              </div>
            </div>
        )
    }

    const renderWidgetReadyToPlay = () => {

        return (
            <div>

              <ReactAudioPlayer src={provisionalRecording.audioUrl}
                                controls
              />
              
              <Separator variant="h20" />

              <div css={name_row_style}>
                <div css={name_row_label_style}>
                  Save as:
                </div>
                <Form.Control onChange={onSetName} name="name" type="text">
                </Form.Control>
              </div>

              <Separator variant="h20" />

              <ButtonBar>
                
                <GrayButton onClick={onAudioAccepted}>
                  <Trans>Use this recording</Trans>
                </GrayButton>

                <BlueLinkButton onClick={onRecordAgain}>
                  <Trans>Record again</Trans>
                </BlueLinkButton>
              </ButtonBar>
              
            </div>
        )
    }

    const renderWidgetRecording = () => {
        return (
            <div>
              <div onClick={() => onStopRecording({save: true})} css={hover_button_style}>
                <div css={[circle_icon, red_icon]}>
                  <FontAwesomeIcon icon={faStop} />
                </div>
                <Duration seconds={recordingDurationSeconds} format="m:s" />
              </div>
            </div>
        )
    }

    return (
        <Modal show={true}
               onHide={onCancelRecording}
        >

          <Modal.Header closeButton>
            <Modal.Title>
              <Trans>New recording</Trans>
            </Modal.Title>
          </Modal.Header>
          
          <Modal.Body>
            <NestedWrappingBusyMask is_loading={isUploading}>
              { ! isRecording && ! provisionalRecording && renderWidgetReadyToRecord() }
              { ! isRecording && provisionalRecording && renderWidgetReadyToPlay() }
              { isRecording && renderWidgetRecording() }
            </NestedWrappingBusyMask>
          </Modal.Body>
        </Modal>
    )

}

const microphone_icon_style = css`
margin-right: 10px;
`

const hover_button_style = css`
cursor: pointer;
display: flex;
align-items: center;
&:hover {
font-weight: bold;
}
`

const circle_icon = css`
width: 44px;
height: 44px;
margin-right: 10px;
border-radius: 22px;
color: white;
display: flex;
align-items: center;
justify-content: center;
`

const red_icon = css`
background-color: ${theme.colors.red}
`

const green_icon = css`
background-color: ${theme.colors.dark_green};
`

const name_row_style = css`
display: flex;
`

const name_row_label_style = css`
min-width: 75px;
`
