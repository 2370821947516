import { ItemList } from '../../orm'

class AdminUpgradePathList extends ItemList {
    getEntityKey() {
        return "admin/upgrade_path"
    }

    recalculateForCustomer({customer_id}) {
        return this.saveNewObject({customer_id: customer_id, action: "recalculate"})
    }

    forceFreeToPaidUpsellFaxUpgrade({subscription_id, send_email_to_customer}) {
        return this.saveNewObject({customer_subscription: subscription_id,
                                   upgrade_type: 'free_to_paid_upsell_fax',
                                   send_email_to_customer})
    }

    forceFreeToPaidUpsellVoiceUpgrade({subscription_id, send_email_to_customer}) {
        return this.saveNewObject({customer_subscription: subscription_id,
                                   upgrade_type: 'free_to_paid_upsell_voice',
                                   send_email_to_customer})
    }

    forceFreeToPaidUpsellVoiceOptionalUpgrade({subscription_id}) {
        return this.saveNewObject({customer_subscription: subscription_id,
                                   upgrade_type: 'free_to_paid_upsell_voice_optional',
                                   send_email_to_customer: true})
    }
    
}

export const adminUpgradePathList = new AdminUpgradePathList("admin_upgrade_path__default")
