/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { map, get, size, filter, keys } from 'lodash'
import { adminCustomerList } from '../actions/admin_customer'
import Timestamp from '../../components/Timestamp'
import AdminCreationFooter from './AdminCreationFooter'
import { FormikInputField } from '../../components/form/InputField'
import { FormikTextarea } from '../../components/form/TextareaField'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { default_theme as theme } from '../../emotion/theme'
import { vatTypeOptions } from '../../actions/dropdown_options'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormLabelValue } from '../../components/form/FormLabelValue'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { Col, Row } from 'react-bootstrap'
import * as Yup from 'yup'
import Button from 'react-bootstrap/Button'

const validationSchema = Yup.object().shape({
    'user': Yup.object().shape({
        'first_name': Yup.string().required("Customer first name is required"),
        'last_name': Yup.string().required("Customer last name is required"),
        'email': Yup.string().email('Customer email must be valid').required("Customer email is required")
    }),
    'vat_percentage': Yup.number().required("VAT percentage is required")
})

const DEFAULT_VAT_PERCENTAGE = 21

class AdminCustomerForm extends Component {
    
    componentDidMount() {
        const { dispatch, customer_id } = this.props
        dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
        dispatch(vatTypeOptions.fetchListIfNeeded())
    }

    onChangeVatType = (formik_props, new_value) => {
        if ( new_value === 'eu_vat_exempt' ) {
            formik_props.setFieldValue('vat_percentage', 0)
        } else {
            formik_props.setFieldValue('vat_percentage', DEFAULT_VAT_PERCENTAGE)
        }
    }

    renderPersonalDetails(formik_props) {
        const { customer, vatTypes } = this.props

        const vat_type_name = get(vatTypes, [get(customer, "vat_type"), "name"], "Unknown")
        
        return (
            <div>
              <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
              <Row>
                <Col>
                  <FormLabelValue>
                    <span>Notes</span>
                    <FormikTextarea
                        rows={3}
                        name="admin_notes"
                        placeholder="Internal notes"
                    />
                  </FormLabelValue>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormLabelValue>
                    <span>Vat Type (auto-detected)</span>
                    <div>{vat_type_name}</div>
                  </FormLabelValue>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormLabelValue>
                    <span>VAT percentage (auto calculated)</span>
                    <div>{customer.vat_percentage}%</div>
                    {/* <FormikInputField */}
                    {/*     name="vat_percentage" */}
                    {/*     type="text" */}
                    {/*     placeholder="VAT percentage (0 if VAT not applicable)" */}
                    {/* /> */}
                  </FormLabelValue>
                </Col>
              </Row>
            </div>
        )
    }
    
    render() {
        const { onSendInvite,
                error,
                customer,
                initialValues,
                onSubmit
        } = this.props
        
        return (
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
            >
              {formik_props => {
                   const { values } = formik_props
                   return (
                       <div>
                         <Form>
                           { this.renderPersonalDetails(formik_props) }

                           <Button type="submit">
                             Save
                           </Button>
                         </Form>
                       </div>
                   )}
              }
            </Formik>
        )
    } 
}

function mapStateToProps(state, props) {
    const { onSubmit, customer_id, onSendInvite } = props
    let customer = adminCustomerList.getObject(customer_id) || {}
    customer.status = customer.active ?
                      {value: true, label: 'Active'} :
                      {value: false, label: 'Inactive'}

    return {
        onSubmit,
        customer,
        onSendInvite,
        initialValues: customer || {'user': {'email': ''}},
        vatTypes: vatTypeOptions.getObjectsById(),
    }
}
export default withRouter(connect(mapStateToProps)(AdminCustomerForm))

const form_layout = css`
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
margin: 10px;
padding: 10px;
@media(max-width: 1300px) {
align-items: center;
}
`

const row_container = css`
width: 50%;
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: flex-start;
@media(max-width: 1300px) {
    width: 700px;
}
`

const column_container = css`
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: flex-start;
width: 100%;
@media(max-width: 1300px) {
    flex-wrap: nowrap;
    height: auto;
}
`

const field_container = css`
width: 100%;
display: flex;
flex-direction: column;
padding-left: 30px;
padding-right: 30px;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 30px;
flex-direction: column;
padding-top: 20px;

p {
margin: 0;
padding: 0;
color: ${theme.colors.error};
}
`

const footer = css`
display: flex;
flex-direction: column;
width: 100%;
`

const footer_row = css`
display: flex;
flex-direction: row;
text-align: center;
justify-content: space-between;
width: 100%;
padding-top:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`

const row = css`
display: flex; 
justify-content: space-between;
`

const cell = css`
width: 45%;
`

const label_center = css`
color: ${theme.colors.secondary};
font-weight: 600;
text-align: center;
width: 100%;
`

const multi_value_row = css`
display: flex;
justify-content: space-between;
margin: 10px;
padding: 10px;
box-shadow: 0 0 1.5px 0 rgba(0, 0, 0, 0.12), 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.24);
`

const button_group = css`
width: 100%;
display: flex;
justify-content: end;
`

const multi_value_section = css`
margin-top: 20px;
`
