import { ItemList } from '../orm'
import { size, map, get } from 'lodash'
import { postBlob } from '../lib/http'
import { download } from './lib'

class FileInfoList extends ItemList {
    getEntityKey() {
        return "file_info"
    }

    download({fileInfo}) {
        download(fileInfo.authenticated_download_url)
    }

    uploadFiles({files, on_file_upload_progress, privacy_wipe_enabled}) {

        const promise = new Promise(async(resolve, reject) => {

            let files_processing = size(files)
            const successes = []
            const failures = []

            map(files, (file) => {
                if ( on_file_upload_progress ) {
                    on_file_upload_progress(file, "pending")
                }
            })
            
            const onFileProcessed = (file, errorMessage, json) => {
                const file_object = Object.assign(file, get(json, "file_info"))
                if ( ! errorMessage ) {
                    file.id = json.file_info.id
                    successes.push(file_object)
                    if ( on_file_upload_progress ) {
                        on_file_upload_progress(file, "success")
                    }
                } else {
                    failures.push(file_object)
                    if ( on_file_upload_progress ) {
                        on_file_upload_progress(file, "failed")
                    }
                }
                
                files_processing -= 1
                if ( files_processing === 0 ) {
                    resolve( {successes, failures} )
                }
            }
            
            map(files, async(file) => {
                const blobData = new window.FormData()
                blobData.append("filename", file.name)
                blobData.append("content_type", file.type)
                blobData.append("file", file)
                blobData.append("privacy_wipe_enabled", privacy_wipe_enabled)

                postBlob("file_info", blobData)
                    .then( ( [json, response, isSuccessful] ) => {
                        if ( isSuccessful ) {
                            onFileProcessed(file, null, json)
                        } else {
                            onFileProcessed(file, "Error", json)
                        }
                    })
                    .catch(err => {
                        onFileProcessed(file, err)
                    })
            })
            
        })
        return promise
    }
}

export const fileInfoList = new FileInfoList("file_info__default")



class Base64FileInfoList extends ItemList {
    getEntityKey() {
        return "file_info/as_base64"
    }
}

export const base64FileInfoList = new Base64FileInfoList("base_64_file_info__default")

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })
}
