/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { head, get } from 'lodash'
import { Link } from 'react-router-dom'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import CurrencyValue from '../../components/CurrencyValue'
import Timestamp from '../../components/Timestamp'
import { adminIncomingVoiceMessageList } from '../actions/admin_incoming_voice_message'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { Button, Container } from 'react-bootstrap'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { LanguageFlag } from '../../components/LanguageFlag'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFilter from './AdminTableFilter'
import { PhoneNumber } from '../../components/PhoneNumber'

class AdminIncomingVoiceMessages extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminIncomingVoiceMessageList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(adminIncomingVoiceMessageList.fetchListIfNeeded())
    }

    getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'start_datetime':
                return <Timestamp value={item.start_datetime} format="datetime" />
            case 'end_datetime':
                return <Timestamp value={item.end_datetime} format="datetime" />
            case 'source_phone_number':
                return <PhoneNumber phone_number={get(item, ["source_phone_number", "number"])} />
            case 'destination_phone_number':
                return <PhoneNumber phone_number={get(item, ["destination_phone_number", "number"])} />
            case 'to_customer':
                return get(item, ["to_customer", "user", "display_name"])
            default:
                return undefined
        }
    }

    filterIncomingVoiceMessages = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminIncomingVoiceMessageList.updateListFilter(filter_values))
    }

    render() {
        const { is_loading, headers, incoming_voice_messages } = this.props
        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'incoming_voice_messages', label: 'IncomingVoiceMessages', url: '/admin/incoming_voice_messages'}]}>
              <Container fluid>
                <AdminTableHeader title="IncomingVoiceMessages">
                  <AdminTableFilter
                      updateOnChange={ this.filterIncomingVoiceMessages }
                      form="admin_incomingVoiceMessages_filter_form"
                  />
                </AdminTableHeader>
                <CommonTable
                    is_loading={ is_loading }
                    empty_message={`There are no incoming_voice_messages.`}
                    headers={ headers }
                    items={ incoming_voice_messages }
                    item_list={adminIncomingVoiceMessageList}
                    renderCell={ this.renderCell }
                    getCellValue={ this.getCellValue }
                    sortOnHeader={ this.sortOnHeader }
                />
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const incoming_voice_messages = adminIncomingVoiceMessageList.getVisibleObjects()
    return {
        incoming_voice_messages,
        is_loading: adminIncomingVoiceMessageList.isLoading(),
        headers: {
            short_id: { name: 'Id' },
            status: { name: 'Status' },
            destination_phone_number: { name: 'Destination' },
            source_phone_number: { name: 'Source' },
            raw_source_phone_number: { name: 'Source (unformatted)' },
            to_customer: { name: 'To customer' },
            start_datetime: { name: 'Start' },
            // end_datetime: { name: 'End' },
            duration_milliseconds: { name: 'Duration (milliseconds)' },
        }
    }
}

export default connect(mapStateToProps)(AdminIncomingVoiceMessages)
