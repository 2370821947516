import { ItemList } from '../../orm'

class AdminIncomingFaxByEmailQueueList extends ItemList {
    getEntityKey() {
        return "admin/incoming_fax_by_email_queue"
    }

    sendTestEmail(values) {
        return this.saveNewObject(values)
    }
}

export const adminIncomingFaxByEmailQueueList = new AdminIncomingFaxByEmailQueueList("admin_incoming_fax_by_email_queue_default")
