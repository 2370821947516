/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { jsx, css } from '@emotion/react'
import { BlueLinkButton } from './BlueLinkButton'
import { Separator } from './Separator'

export const AbbreviatedSection = ({children, render_collapsed, render_expanded_header}) => {

    const [expanded, setExpanded] = useState(false)

    return (
        <>
          { ! expanded &&
            <>
              { render_collapsed && render_collapsed() }
              { ! render_collapsed &&
                <div css={collapsed_container_style}>
                  {children}
                </div>
              }
              <BlueLinkButton onClick={() => setExpanded(true)}>
                Show
              </BlueLinkButton>
            </>
          }
          { expanded &&
            <>
              <div css={expanded_header_style}>
                { render_expanded_header && render_expanded_header() }
                <Separator variant="w10" />
                <BlueLinkButton onClick={() => setExpanded(false)}>
                  Hide
                </BlueLinkButton>
              </div>
              {children}
            </>
          }
        </>
    )
    
}

const collapsed_container_style = css`
max-height: 100px;
overflow: hidden;
border-bottom: 2px dashed white;
background: black;
`

const expanded_container_style = css`
`

const expanded_header_style = css`
display: flex;
margin-bottom: 10px;
`
