import { ItemList } from '../orm'

class CallForwardingCostCalculatorList extends ItemList {
    getEntityKey() {
        return "call_forwarding_cost_calculator/calculate_for_numbers"
    }

    fetchForNumbers({subscription_id, numbers}) {
        return async(dispatch, getState) => {
            dispatch(this.updateListFilter({subscription: subscription_id, numbers: numbers}))
            dispatch(this.fetchListIfNeeded())
        }
    }

    getForNumber({subscription_id, number}) {
        const id = subscription_id + "|" + number
        return this.getObject(id)
    }
}

export const callForwardingCostCalculatorList = new CallForwardingCostCalculatorList("callForwardingCostCalculatorList__default")
