/** @jsxImportSource @emotion/react */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { Route, Switch } from 'react-router-dom'
import { ResellerLanding } from './ResellerLanding'

export const ResellerRouter = () => {

    return (
        <Switch>
          <Route path="/reseller" exact={ true } component={ ResellerLanding } />
          <Route path="/reseller/:active_tab" exact={ true } component={ ResellerLanding } />
        </Switch>
    )
    
}
