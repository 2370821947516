import { ItemList } from '../orm'

class FaxByEmailVerification extends ItemList {
    getEntityKey() {
        return "fax_by_email_verification"
    }

    verify(token, action) {
        return this.saveNewObject({token: token, action: action})
    }
}

export const faxByEmailVerificationList = new FaxByEmailVerification("fax_by_email_verification__default")


class FaxByEmailApprovalVerification extends ItemList {
    getEntityKey() {
        return "fax_by_email_verification/approve"
    }

    verify(id) {
        return this.saveNewObject({fax_item_id_to_verify:id})
    }
}

export const faxByEmailApprovalVerification = new FaxByEmailApprovalVerification("fax_by_email_approval_verification__default")

class FaxByEmailCancelVerification extends ItemList {
    getEntityKey() {
        return "fax_by_email_verification/cancel"
    }

    verify(id) {
        return this.saveNewObject({fax_item_id_to_verify:id})
    }
}

export const faxByEmailCancelVerification = new FaxByEmailCancelVerification("fax_by_email_approval_verification__default")
