/** @jsxImportSource @emotion/react */
import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { default_theme as theme } from '../emotion/theme'
import { jsx, css } from '@emotion/react'
import { customerList } from '../actions/customer'
import { Button } from 'react-bootstrap'
import { BlueButton } from './layout/BlueButton'
import CurrencyValue from './CurrencyValue'
import { Separator } from './layout/Separator'
import { Trans, Translation } from 'react-i18next'
import { globalSettingsList } from '../actions/settings'
import { topupList } from '../actions/topup'
import CreditBadge from './CreditBadge'

const CreditLabelAndButton = (props) => {
  const { onLeavingPage } = props
  const dispatch = useDispatch()
  const history = useHistory()

  const customer = customerList.getCustomer()
  const is_ready = customerList.isReady()
  const credits_excluding_vat_euros =
    customer.credits_excluding_vat_euros || null
  const button_style =
    credits_excluding_vat_euros > 0
      ? credit_button_available_style
      : credit_button_empty_style

  useEffect(() => {
    dispatch(customerList.ensureCustomerLoaded())
    dispatch(globalSettingsList.fetchListIfNeeded())
  }, [dispatch])

  const buyMoreCredit = useCallback(() => {
    if (onLeavingPage) {
      onLeavingPage()
    }
    topupList.startBuyMoreCredit({ history })
  }, [history, onLeavingPage])

  return (
    <>
      <div css={credit_button_group}>
        {is_ready && <CreditBadge size={'xs'} />}

        <Separator variant="w10" />
        {is_ready && (
          <BlueButton onClick={buyMoreCredit} auto_disable={false} size={'xs'}>
            <Trans>Buy Credit</Trans>
          </BlueButton>
        )}
      </div>
    </>
  )
}

export default CreditLabelAndButton

const credit_button_group = css`
  display: flex;
`

const credit_button_available_style = css`
  background-color: ${theme.colors.dark_green};
  color: #fff;
  border-radius: 20px;
`

const credit_button_empty_style = css`
  background-color: ${theme.colors.primary_red};
  color: #fff;
  border-color: ${theme.colors.primary_red};
  border-radius: 20px;

  &:hover {
    border-color: ${theme.colors.primary_red};
    background-color: ${theme.colors.primary_red};
  }
`
const currency_button_content_style = css`
  text-align: center;
`
