/** @jsxImportSource @emotion/react */
import React, { Component, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'

export const BlueInlineLink = ({ onClick, children, ...props }) => {
    return (
        <span css={style} onClick={onClick}>
          {children}
        </span>
    )
}

const style = css`
padding-left: 5px;
padding-right: 5px;
color: ${theme.colors.primary_dark};
font-weight: 500;
margin-top: 0px;
padding-top: 0px;
cursor: pointer;

&:hover {
  text-decoration: underline;
}
`
