/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import { default_theme as theme } from '../emotion/theme'
import { Modal } from 'react-bootstrap'
import { get, size, map, includes } from 'lodash'
import BusyMask from './BusyMask'
import { Timestamp } from './Timestamp'
import { faxItemList } from '../actions/fax_item'
import { faxByEmailCancelVerification, faxByEmailApprovalVerification } from '../actions/fax_by_email_verification'
import { InlineIcon } from './layout/InlineIcon'
import { Container } from 'react-bootstrap'
import { Separator } from './layout/Separator'
import { Error } from './layout/Error'
import FieldInfoText from './layout/FieldInfoText'
import { showSuccess, showError } from '../actions/Error'
import { Pluralize } from './Pluralize'
import { ButtonBar } from './layout/ButtonBar'
import { BlueButton } from './layout/BlueButton'
import { FaxVerificationExpiredMessage } from './FaxVerificationExpiredMessage'

const SUMMARY_STATUS_MAPPING = {
    'processed': { 'status': <Trans>Processed</Trans>, 'icon_name': 'status-success', is_enem_error: false  },
    'processed_by_asterisk': { 'status': <Trans>Processed</Trans>, 'icon_name': 'status-success', is_enem_error: false  },
    'processing': { 'status': <Trans>Processing</Trans>, 'icon_name': 'status-in-progress', is_enem_error: false  },
    'error': { 'status': <Trans>Error</Trans>, 'icon_name': 'status-error', is_enem_error: true, 'message': <Trans>Internal error</Trans>, is_enem_error: true }
}

class IncomingFaxItemDetails extends Component {

    componentDidMount() {
        const { dispatch, fax_item_id } = this.props
        dispatch(faxItemList.ensureObjectLoaded(fax_item_id))
    }

    componentDidUpdate() {
        const { dispatch, fax_item_id } = this.props
        dispatch(faxItemList.ensureObjectLoaded(fax_item_id))
    }

    getBestFromName() {
        const { fax_item  } = this.props
        if ( fax_item.from_customer ) {
            return fax_item.from_customer.email
        }
        if ( fax_item.from_phone_number ) {
            return fax_item.from_phone_number.number
        }
        return null
    }

    getBestToName() {
        const { fax_item } = this.props
        return (
            <>
              { fax_item.to_phone_number &&
                <>
                  <Separator variant="w10" />
                  <InlineIcon icon_name="arrow-right" variant="black" disable_margins={true} />
                  <Separator variant="w10" />
                  { fax_item.to_phone_number.number }
                </>
              }
              { fax_item.to_customer &&
                <>
                  <Separator variant="w10" />
                  <InlineIcon icon_name="arrow-right" variant="black" disable_margins={true} />
                  <Separator variant="w10" />
                  { fax_item.to_customer.email }
                </>
              }
              
            </>
        )
    }

    getBestDetailsSummaryStatusName(incoming_fax) {
        const status = get(incoming_fax, "status")
        return get(SUMMARY_STATUS_MAPPING, [status, "status"], status)
    }

    getBestDetailsSummaryIcon(incoming_fax_queue_item) {
        const status = get(incoming_fax_queue_item, "status")
        return get(SUMMARY_STATUS_MAPPING, [status, "icon_name"])
    }

    renderSummary(incoming_fax) {
        const { fax_item } = this.props
        if ( ! incoming_fax ) {
            return <div>Missing details</div>
        }
        return (
            <div>
              <div css={summary_row_style}>
                  <div css={summary_direction}>
                    <InlineIcon icon_name="incoming" variant="black" />
                    <span><Trans>Incoming</Trans></span>
                  </div>

                <Separator variant="w10" />

                <div css={summary_row_style}>
                  { this.getBestFromName() }
                  { this.getBestToName() }
                </div>
                
              </div>

              <div css={summary_row_style}>
                <Trans>Received</Trans>:&nbsp;
                <Timestamp value={incoming_fax.created_at} format="at" />
              </div>

              <Separator variant="h10" />

              <div css={summary_row_style}>
                <Trans>Status</Trans>:&nbsp;
                <InlineIcon icon_name={this.getBestDetailsSummaryIcon(incoming_fax)} />
                {this.getBestDetailsSummaryStatusName(incoming_fax)}
                { incoming_fax.last_error &&
                  <div css={error_item_container}>
                    <Error>{incoming_fax.last_error} </Error>
                  </div>
                }
                { incoming_fax.status === 'processed_by_asterisk' &&
                  <div>
                    (<Trans>Failover mode</Trans>)
                  </div>
                }
              </div>
              
            </div>
        )
    }

    render() {
        const that = this
        const { fax_item  } = this.props
        
        return (
            <div>
              <div>
                { this.renderSummary(fax_item.incoming_fax) }
              </div>
            </div>
        )
    }
}

function mapStateToProps(state, props) {

    const { fax_item_id } = props
    const fax_item = faxItemList.getObject(fax_item_id)
    
    return {
        fax_item_id,
        fax_item
    }
}

export default connect(mapStateToProps)(IncomingFaxItemDetails)

const filename_row_style = css`
background-color: ${theme.colors.very_light_grey};
border-radius: 4px;
width: 100%;
padding-left: 15px;
margin-bottom: 20px;
padding-top: 10px;
padding-bottom: 10px;
display: flex;
justify-content: space-between;
`

const summary_direction = css`
color: #000;
font-weight: 500;
display: flex;
align-items: center;
`

const summary_row_style = css`
display: flex;
align-items: center;
`

const error_item_container = css`
margin-left: 10px;
`

const actions_style = css`
padding-left: 15px;
`

const actions_item_style = css`
margin-right: 10px;
`

const actions_header_style = css`
font-weight: 500;
font-size: 18px;
`
