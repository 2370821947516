/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css,  Global } from '@emotion/react'
import { connect } from 'react-redux'
import { size, head, get, map, keys, slice } from 'lodash'
import {showSuccess, showError} from '../../actions/Error'
import { Formik, Form, FieldArray, Field } from 'formik'
import Loading from '../../components/Loading'
import { PhoneNumber } from '../../components/PhoneNumber'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { FormLabelValue } from '../../components/form/FormLabelValue'
import { BlueButton } from '../../components/layout/BlueButton'
import { Row, Col, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import Card from '../../components/layout/Card'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import { adminAsteriskRoutingLookupList } from '../actions/admin_asterisk_routing_lookup'
import { Button, Container } from 'react-bootstrap'
import * as Yup from 'yup'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { handleSubmitResult } from '../../actions/form'
import { Separator } from '../../components/layout/Separator'
import { FormikInputField } from '../../components/form/InputField'
import FieldInfoText from '../../components/layout/FieldInfoText'

const WIDTH_LEFT = 2

const validationSchema = Yup.object().shape({
    number: Yup.string().required("Required"),
})

class AdminAsteriskNewRoutingLookup extends Component {

    onSave = (values, formik_funcs) => {
        const { history, dispatch, prefix_id } = this.props
        const that = this
        const on_ok = function(json) {
            dispatch(adminAsteriskRoutingLookupList.invalidateList())
            showSuccess("Saved", "Lookup created")
            history.push(`/admin/asterisk_routing_lookups`)
        }
        return dispatch(adminAsteriskRoutingLookupList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }
    
    render() {
        const { is_saving, initial_values, validationSchema } = this.props

        return (
            <AdminMainLayout title="New Asterisk Routing Lookup"
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'asterisk_routing_lookups', label: 'Asterisk routing lookups', url: '/admin/asterisk_routing_lookups'},
                                           {name: 'asterisk_routing_lookup', label: 'New lookup', url: `/admin/asterisk_routing_lookup/`}]}>
              <Container fluid>

                <Card variant="white">
                
                <Formik
                    initialValues={initial_values}
                    onSubmit={this.onSave}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                  >
                    {formik_props => {
                        const { values } = formik_props
                        return (
                            <Form>
                              <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />

                              <FormLabelValue spacing="h10">
                                Phone number
                                <FieldInfoText>
                                  This number must be a full number, it is not a wildcard search.
                                </FieldInfoText>
                                <FormikInputField name="number" />
                              </FormLabelValue>

                              <FormLabelValue spacing="h10">
                                Email when done
                                <FieldInfoText>
                                  An email address to notify once the results are received (it can 5 to 10 minutes for all results to be received)
                                </FieldInfoText>
                                <FormikInputField name="email_results_to" />
                              </FormLabelValue>

                              <Button type="submit">
                                Create
                              </Button>
                              
                            </Form>
                        )
                    }}
                  </Formik>
                </Card>
                
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        is_saving: adminAsteriskRoutingLookupList.getIsSavingObject(),
        initial_values: {}
    }
}

export default connect(mapStateToProps)(AdminAsteriskNewRoutingLookup)
