import { ItemList } from '../../orm'
import { AnsweringMessageList } from '../../actions/answering_message'

class AdminAnsweringMessageList extends AnsweringMessageList {
    getEntityKey() {
        return "admin/answering_message"
    }
}


export const adminAnsweringMessageList = new AdminAnsweringMessageList("admin_answering_message__default")
