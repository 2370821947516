/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import MainLayout from './MainLayout'
import { Trans, Translation } from 'react-i18next'
import { Row, Col, Button, ButtonGroup } from 'react-bootstrap'
import CommonTable from './CommonTable'
import Card from './layout/Card'
import { head, get, startsWith, split, includes } from 'lodash'
import { Form as BootstrapForm } from 'react-bootstrap'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikInputField } from './form/InputField'
import * as Yup from 'yup'
import {showSuccess, showError} from '../actions/Error'
import { confirmModal } from '../actions/ui'
import FieldInfoText from './layout/FieldInfoText'
import { WhiteButton } from './layout/WhiteButton'
import { BlueLinkButton } from './layout/BlueLinkButton'
import { ButtonBar } from './layout/ButtonBar'
import { Separator } from './layout/Separator'
import { FormikGeneralFormErrors } from './form/GeneralFormErrors'
import { handleSubmitResult } from '../actions/form'

const validationSchema = Yup.object().shape({
})

class CommonFaxFromEmailAddressForm extends Component {

    constructor(props) {
        super(props)
        this.state = {is_wildcard: null}
    }
    
    cancelAddingRecord = () => {
        const { onCancel } = this.props
        onCancel()
    }

    onSaveRecord = (values, formik_funcs) => {
        const { dispatch, history, address, faxFromEmailAddressList, customer_id } = this.props
        const that = this
        const on_ok = function(json) {
            dispatch(faxFromEmailAddressList.invalidateList())
            that.cancelAddingRecord()
            showSuccess(<Trans>Saved</Trans>, <Trans>Email address saved</Trans>)
        }
        if ( customer_id ) {
            // only appropriate for admin components, the api for
            // non-admin components will override this value to the
            // logged in user.
            values.customer = customer_id
        }

        if ( address ) {
            values.id = address.id
            return dispatch(faxFromEmailAddressList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(faxFromEmailAddressList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    isWildcard(email) {
        return (startsWith(email, "*" ) || startsWith(email, "@")) && includes(email, "@")
    }
    
    getDomain(email) {
        if ( ! includes(email, "@") ) {
            return email
        }
        return split(email, "@")[1]
    }

    onChangeEmailAddress = (evt, formik_props) => {
        const value = evt.target.value
        const { is_wildcard } = this.state
        const new_is_wildcard = this.isWildcard(value)
        if ( is_wildcard != new_is_wildcard ) {
            this.setState({is_wildcard: new_is_wildcard})
        }
        formik_props.setFieldValue('email_address', value)
    }
    
    render() {
        const { address, key, initial_values } = this.props
        const state_is_wildcard = this.state.is_wildcard

        return (
            <Formik
                initialValues={initial_values}
                onSubmit={this.onSaveRecord}
                validationSchema={validationSchema}
                enableReinitialize={true}
                key={key}
            >
              {formik_props => {
                  const { values } = formik_props
                  const is_wildcard = this.isWildcard(get(values, "email_address")) || state_is_wildcard === true
                  const domain = is_wildcard && this.getDomain(get(values, "email_address"))
                  
                  return (

                      <Translation>{ (t) => (
                          <Form>
                            <div css={container_style}>
                              <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                              <Row>
                                <Col>
                                  <BootstrapForm.Group>
                                    {null}
                                    <FormikInputField name="email_address"
                                                      type="text"
                                                      onChange={(el) => this.onChangeEmailAddress(el, formik_props)}
                                                      placeholder={t("Enter email address")}
                                    />
                                  </BootstrapForm.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <BootstrapForm.Group>
                                    {null}
                                    <div>
                                      <FormikInputField name="fax_header"
                                                        type="text"
                                                        placeholder={t("Set a fax header for faxes sent from this email address")}
                                      />
                                      <FieldInfoText>
                                        <Trans>Max</Trans> 32 <Trans>Characters</Trans>. <Trans>When left empty, the default fax header under more options is applied</Trans>
                                      </FieldInfoText>
                                    </div>
                                    
                                  </BootstrapForm.Group>
                                </Col>
                              </Row>

                              { is_wildcard && 
                                <Row>
                                  <Col>
                                    <div css={wildcard_field_style}>
                                      <FormikInputField name="wildcard_verification_email_address_prefix"
                                                        type="text"
                                                        placeholder={t("email address")}
                                      />
                                      @{domain}
                                    </div>
                                    <FieldInfoText>
                                      <Trans>
                                        To apply for this wildcard domain you must first verify at least one email address in this domain.
                                        We will send an email with a verification code to the address you fill out here
                                      </Trans>.
                                    </FieldInfoText>
                                  </Col>
                                </Row>
                              }
                              <Row>
                                <Separator variant="h20" />
                                <Col>
                                  <ButtonBar>
                                    <WhiteButton type="submit">
                                      <Trans>Save</Trans>
                                    </WhiteButton>
                                    <BlueLinkButton onClick={this.cancelAddingRecord}>
                                      <Trans>Cancel</Trans>
                                    </BlueLinkButton>
                                  </ButtonBar>
                                  <Separator variant="h10" />
                                </Col>
                              </Row>
                            </div>
                          </Form>
                      )}</Translation>
                  )
              }}
            </Formik>
        )
    }
    
}

function mapStateToProps(state, props) {
    const { customer_id, address, faxFromEmailAddressList } = props

    return {
        customer_id,
        address,
        faxFromEmailAddressList,
        initial_values: { email_address: get(address, "email_address"),
                          fax_header: get(address, "fax_header"),
                          wildcard_verification_email_address_prefix: get(address, "wildcard_verification_email_address_prefix")
                        }
    }
}

export default connect(mapStateToProps)(CommonFaxFromEmailAddressForm)

const container_style = css`
background-color: #efeff4;
padding: 10px;
`

const wildcard_field_style = css`
display: flex;
align-items: center;
`
