import { ItemList } from '../../orm'

class AdminBulkCustomerMigrationQueueList extends ItemList {
    getEntityKey() {
        return "admin/bulk_customer_migration_queue"
    }
    
}

export const adminBulkCustomerMigrationQueueList = new AdminBulkCustomerMigrationQueueList("admin_bulk_customer_migration_queue__default")
