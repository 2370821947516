import { ItemList } from '../orm'
import cookie from 'react-cookies'

class CdrList extends ItemList {
    getEntityKey() {
        return "cdr"
    }

    getSavedFilter() {
        return cookie.load('cdr_list_filter')
    }

    setSavedFilter(filter) {
        cookie.save('cdr_list_filter', filter, { path: '/'})
    }

}

export const cdrList = new CdrList("cdr__default")
