/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { map, get, size, forEach } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import { NewSubscriptionCard } from './NewSubscriptionCard'
import { CardHeader } from './layout/CardHeader'
import { default_theme as theme } from '../emotion/theme'
import { Separator } from './layout/Separator'
import MainLayout from './MainLayout'
import { Row, Col, Container, Popover, OverlayTrigger, Button } from 'react-bootstrap'
import * as Yup from 'yup'
import { } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const NoVoiceSubscriptions = ({...props}) => {

    const renderTick = () => {
        return <div css={tick_style}>√</div>
    }
    
    return (
        <MainLayout active_key="subscriptions"
                    breadcrumbs={[{name: 'home'},
                                  {name: 'subscriptions', label: 'Subscriptions', url: '/subscriptions'},
                                  {name: 'new_voice_subscription', label: 'Add a voice number', url: '/subscription/voice'}]}>
          <Container fluid>

            <Row>
              <Col md={12}>
                <CardHeader title="Receive calls when and where you want" />
              </Col>
            </Row>

            <Separator variant="h10" />

            <Row>
              <Col md={8}>
                <Trans i18nKey="novoicesubscriptions__get_a_local">
                  Get a local or national telephone number from XOIP and control incoming calls with simple and clear configuration options. You can also keep your existing number.
                </Trans>
              </Col>
            </Row>

            <Separator variant="h20" />
            
            <Row>
              <Col md={4}>
                <div css={row_with_tick_style}>
                  { renderTick() }
                  <h3 css={h3_style}>
                    <Trans>
                      Forward calls to fixed or mobile phones
                    </Trans>
                  </h3>
                </div>
                <div css={row_with_no_tick_style}>
                  <Trans i18nKey="novoicesubscriptions__you_can_forward">
                    You can forward an incoming call to any fixed line or mobile number. Depending on your subscription this is for free or for  affordable dial-out rates.
                  </Trans>
                </div>

                <Separator variant="h20" />

                <div css={row_with_tick_style}>
                  { renderTick() }
                  <h3 css={h3_style}>
                    <Trans>
                      Receive voicemails in your email
                    </Trans>
                  </h3>
                </div>
                <div css={row_with_no_tick_style}>
                  <Trans i18nKey="novoicesubscriptions__voice_messages_that_people">
                    Voicemail messages that people leave will be forwarded to your email inbox. No need to call in to listen to your voicemail messages anymore.
                    Even better, you can now forward voicemails to your colleagues or team mates!
                  </Trans>
                </div>
                
              </Col>

              <Col md={{ span:4, offset:2 }}>
                <div css={row_with_tick_style}>
                  { renderTick() }
                  <h3 css={h3_style}>
                    <Trans>
                      Set up for your office hours
                    </Trans>
                  </h3>
                </div>
                <div css={row_with_no_tick_style}>
                  <Trans i18nKey="novoicesubscriptions__do_you_want_to_automatically">
                    Do you want to automatically send an incoming call to your secretary during office hours, but to your mobile or straight to voicemail outside office hours? You can. And so much more.
                  </Trans>
                </div>

                <Separator variant="h20" />

                <div css={row_with_tick_style}>
                  { renderTick() }
                  
                  <h3 css={h3_style}>
                    <Trans>
                      Forward to a call list
                    </Trans>
                  </h3>
                </div>
                <div css={row_with_no_tick_style}>
                  
                  <Trans i18nKey="novoicesubscriptions__you_can_route_an_incoming_call">
                    You can route an incoming call to a list of up to five phone numbers. You can set it up to call them all at once, or one-by-one.
                  </Trans>
                </div>
              </Col>
              
            </Row>

            <Separator variant="h10" />

            <NewSubscriptionCard product_filter={{can_receive_voice: true,
                                                  is_fax_related: null}} />
            
          </Container>
        </MainLayout>

    )
}

const row_with_tick_style = css`
display: flex;
`

const row_with_no_tick_style = css`
margin-left: 17px;
`

const tick_style = css`
color: ${theme.colors.primary_orange};
margin-right: 5px;
font-weight: bold;
font-size: 20px;
`

const h3_style = css`
margin: 0px;
font-size: 18px;
line-height: 21px;
`
