/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import {showSuccess, showError} from '../../actions/Error'
import { head, get, truncate } from 'lodash'
import { Modal } from 'react-bootstrap'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { adminOutboundEmailStatuses } from '../actions/admin_dropdown_options'
import { adminUserList } from '../actions/admin_user'
import { handleSubmitResult } from '../../actions/form'
import { Link } from 'react-router-dom'
import { AdminQuickLink } from './AdminQuickLink'
import { Formik, Form } from 'formik'
import { FormikInputField } from '../../components/form/InputField'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFilter from './AdminTableFilter'
import CardInfoText from '../../components/layout/CardInfoText'
import { Separator } from '../../components/layout/Separator'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import { adminOutboundEmailList } from '../actions/admin_outbound_email'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { Button, Container, Row, Col, Table } from 'react-bootstrap'
import { InlineIcon } from '../../components/layout/InlineIcon'
import sanitizeHtml from 'sanitize-html'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'

class AdminOutboundEmailList extends Component {

    constructor(props) {
        super(props)
        this.state = {show_preview_email: null,
                      show_preview_extra_info: false,
                      show_resending_email: false}
    }
    
    componentDidMount() {
        const { dispatch, customer_id, admin_outbound_email_list_filter } = this.props
        if ( admin_outbound_email_list_filter.customer != customer_id ) {
            dispatch(adminOutboundEmailList.updateListFilter({customer: customer_id}))
        }
        dispatch(adminOutboundEmailList.fetchListIfNeeded())
        dispatch(adminOutboundEmailStatuses.updatePaginationNumItemsPerPage(1000))
        dispatch(adminOutboundEmailStatuses.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch, customer_id, admin_outbound_email_list_filter } = this.props
        if ( admin_outbound_email_list_filter.customer != customer_id ) {
            dispatch(adminOutboundEmailList.updateListFilter({customer: customer_id}))
        }
        dispatch(adminOutboundEmailList.fetchListIfNeeded())
    }

    onShowPreview = (email) => {
        this.setState({show_preview_email: email})
    }

    onHidePreview = () => {
        this.setState({show_preview_email: null})
    }

    onShowPreviewExtraInfo = () => {
        this.setState({show_preview_extra_info: true})
    }

    onHidePreviewExtraInfo = () => {
        this.setState({show_preview_extra_info: false})
    }
    
    onShowResendEmail = () => {
        this.setState({show_resending_email: true})
    }

    onHideResendEmail = () => {
        this.setState({show_resending_email: false})
    }

    onResendEmail = (values, formik_funcs, email) => {
        const { dispatch } = this.props
        const that = this

        const on_ok = function(json) {
            showSuccess("Saved", `Email queued for sending to ${values.to_address}`)
            dispatch(adminOutboundEmailList.invalidateList())
            dispatch(adminOutboundEmailList.fetchListIfNeeded())
            that.onHidePreview()
            that.onHidePreviewExtraInfo()
        }
        
        that.onHideResendEmail()
        dispatch(adminOutboundEmailList.resendEmail({id: email.id, to_address: values.to_address}))
            .then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }

    renderResendEmail = (email) => {
        const that = this
        const initial_values = email
        return (
            <Formik
              initialValues={initial_values}
              onSubmit={(values, formik_funcs) => that.onResendEmail(values, formik_funcs, email)}
              enableReinitialize={true}
            >
              {formik_props => {
                  const { values } = formik_props
                  return (
                      <Form>
                        <h3>Resend this email to:</h3>
                        <FormikInputField name="to_address" />
                        <FormikGeneralFormErrors />
                        <Button type="submit">
                          Send
                        </Button>
                        <Button variant="error" onClick={that.onHideResendEmail}>
                          Cancel
                        </Button>
                      </Form>
                  )
              }}
            </Formik>
        )        
    }
    
    getCellValue = (header_key, item, index) => {
        const that = this
        const { customer_id } = this.props
        switch( header_key ) {
            case 'created':
                return <Timestamp value={item.created} format="datetime" />
            case 'subject':
                return <div>{truncate(item.subject, 50)}</div>
            case 'to_address':
                return <AdminQuickLink name="customer" value={item.customer} label={item.to_address} />
            case 'status':
                let res
                switch( item.status ) {
                    case 'sent': res = <InlineIcon icon_name="status-waiting-input" />
                        break
                    case 'queued': res = <InlineIcon icon_name="status-in-progress" />
                        break
                    case 'error': res = <InlineIcon icon_name="status-error" />
                        break
                    case 'remote_bounced': res = <InlineIcon icon_name="status-error" />
                        break
                    case 'bounce': res = <InlineIcon icon_name="status-error" />
                        break
                    case 'delivered': res = <InlineIcon icon_name="status-success" />
                        break
                    default:
                        res = item.status
                }
                return res
            case 'preview':
                return <InlineIcon icon_name="eye" onClick={(evt) => that.onShowPreview(item)} />
            default:
                return undefined
        }
    }

    filterEmails = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminOutboundEmailList.updateListFilter(filter_values))
    }

    tryRenderPreviewExtraInfo = (email) => {
        const { show_preview_extra_info, show_resending_email } = this.state

        if ( ! show_preview_extra_info ) {
            return (
                <InlineIcon icon_name="info" onClick={this.onShowPreviewExtraInfo} />
            )
        }
        
        return (
            <>
              <Card variant="white">
                <InlineIcon icon_name="cancel" onClick={this.onHidePreviewExtraInfo} />

                <CardInfoText>
                  The content in this email preview has been run through a pre-processor that removes everything that could interfere with this page, including some styling.
                  <br/>
                  Therefore the email will not look exactly how the client sees it, and some links, buttons and images might not function correctly.
                </CardInfoText>

                <h3>Detailed status</h3>
                <Table bordered responsive>
                  <tr>
                    <td md={2}>Status</td>
                    <td>{email.status}</td>
                  </tr>
                  { email.error && 
                    <tr>
                      <td md={2}>Error</td>
                      <td>{email.last_error_message}</td>
                    </tr>
                  }
                  { email.email_server_response && 
                    <tr>
                      <td md={2}>Email server response</td>
                      <td>{email.email_server_response}</td>
                    </tr>
                  }
                </Table>

                <Separator variant="h50" with_top_border={true}/>

                { ! show_resending_email &&
                  <Button variant="secondary" onClick={() => this.onShowResendEmail()}>
                    Resend or Forward this email
                  </Button>
                }
                { show_resending_email && this.renderResendEmail(email) }
                
              </Card>

            </>
            
        )
    }

    renderPreviewModal(email) {
        const that = this

        const cleaned_html = sanitizeHtml(email.html_content, {
            allowedClasses: false, // allow all
            allowedAttributes: false // allow all
        })
        
        return (
            <Modal show={true}
                   size="lg"
                   onHide={that.onHidePreview} >
              <Modal.Header closeButton>
                <Modal.Title>
                  Email Preview - {email.id}
                  
                </Modal.Title>

              </Modal.Header>

              <Modal.Body>

                { this.tryRenderPreviewExtraInfo(email) }
                
                <h2>{email.subject}</h2>
                <Separator variant="h50" with_top_border={true}/>
                
                <div>
                  <div dangerouslySetInnerHTML={{__html: cleaned_html}} />
                </div>
              </Modal.Body>
            </Modal>
        )
    }

    renderFilter = (formik_props) => {
        const { email_status_options } = this.props
        return (
            <Row>
              <Col>
                <FormikDropdownField name="status"
                                     formik_props={formik_props}
                                     options={email_status_options}
                                     placeholder="Status"
                />
              </Col>
              <Col>
                <FormikInputField name="any_field"
                                  placeholder="Search"
                                  show_search_icon={true}
                />
              </Col>
            </Row>
        )
    }

    render() {
        const { is_loading, emails, headers, is_saving } = this.props
        const { show_preview_email } = this.state
        const that = this
        return (
            <div>
              <WrappingBusyMask is_loading={is_saving}>
                <AdminTableHeader title="Outbound emails">
                  <div css={ css`width:100%;`}>
                    <AdminTableFilter updateOnChange={ this.filterEmails }
                                      renderFilter={that.renderFilter}
                    />
                  </div>
                </AdminTableHeader>
                <CommonTable
                  is_loading={ is_loading }
                  empty_message={`No emails`}
                  headers={ headers }
                  items={ emails }
                  item_list={adminOutboundEmailList}
                  getCellValue={ this.getCellValue }
                />
                { show_preview_email && this.renderPreviewModal(show_preview_email) }
              </WrappingBusyMask>
            </div>
        )
    }
    
}

function mapStateToProps(state, props) {
    const { customer_id } = props
    const emails = adminOutboundEmailList.getVisibleObjects()
    return {
        is_loading: adminOutboundEmailList.isLoading(),
        is_saving:  adminOutboundEmailList.getIsSavingObject(),
        emails,
        headers: {
            short_id: {name: 'ID'},
            subject: {name: 'Subject'},
            to_address: {name: 'To'},
            from_address: {name: 'From'},
            num_attachments: {name: 'Attachments'},
            created: {name: 'Created'},
            status: {name: 'Status'},
            preview: {name: 'Preview'},
        },
        customer_id,
        admin_outbound_email_list_filter: adminOutboundEmailList.getFilter(),
        email_status_options: adminOutboundEmailStatuses.getAsOptions({empty_option_label: 'Any status'})
    }
}

export default connect(mapStateToProps)(AdminOutboundEmailList)
