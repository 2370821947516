/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Trans, Translation } from 'react-i18next'
import { jsx, css } from '@emotion/react'
import { replace, head } from 'lodash'
import MainLayout from './MainLayout'
import { Row, Col, Button, Accordion } from 'react-bootstrap'
import Card from './layout/Card'
import { CardHeader } from './layout/CardHeader'
import { Separator } from './layout/Separator'
import { SeparatorWithLine } from './layout/SeparatorWithLine'
import CardParagraphSubHeading from './layout/CardParagraphSubHeading'
import CardInfoText from './layout/CardInfoText'
import { InlineIcon } from './layout/InlineIcon'
import CommonFaxFromEmailAddresses from './CommonFaxFromEmailAddresses'
import DefaultIncomingFaxByEmailSettingsForm from './DefaultIncomingFaxByEmailSettingsForm'
import { faxFromEmailAddressList, faxFromEmailAddressVerify } from '../actions/fax_from_email_address'
import { globalSettingsList } from '../actions/settings'
import { BlueLinkButton } from './layout/BlueLinkButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AccordionCaretToggle } from './layout/AccordionCaretToggle'

class EmailToFaxCard extends Component {

    renderMoreOptionsToggle() {
        return (
            <Accordion>
              <AccordionCaretToggle eventKey="0" />
              <Accordion.Collapse eventKey="0">
                <div>
                  <Separator variant="h10" />
                  <DefaultIncomingFaxByEmailSettingsForm />
                </div>
              </Accordion.Collapse>
            </Accordion>
        )
    }
    
    render() {
        const { outgoing_fax_inbox_email_pattern } = this.props
        return (
            <div>
              <CardHeader title={<Trans>Email to Fax</Trans>} />
              <Card variant="white_wide_padding">
                
                <Separator variant="h10" />
                <div>
                  <CardParagraphSubHeading><Trans>How to send a fax from your email address</Trans>:</CardParagraphSubHeading>
                  <CardInfoText>
                    <ul>
                      <li><Trans>Attach one or more pdf files to an email</Trans></li>
                      <li>
                        <Trans>Send to</Trans> {replace(outgoing_fax_inbox_email_pattern, "PHONE_NUMBER", "<faxnumber>")}
                        <br/>
                        (<Trans>eg</Trans> {replace(outgoing_fax_inbox_email_pattern, "PHONE_NUMBER", "00312081002")})
                      </li>
                      <li>
                        <Trans>Send from a valid email address below.</Trans>
                      </li>
                    </ul>
                  </CardInfoText>

                  <SeparatorWithLine />

                  <Row>
                    <Col>
                      <CardParagraphSubHeading>
                        <Trans>Only these email addresses can be used to send faxes from</Trans>
                      </CardParagraphSubHeading>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CommonFaxFromEmailAddresses faxFromEmailAddressList={faxFromEmailAddressList} />
                    </Col>
                  </Row>

                  <SeparatorWithLine />
                  
                  { this.renderMoreOptionsToggle() }
                  
                </div>
              </Card>
            </div>
        )
    }
    
}

function mapStateToProps(state, props) {
    return {
        outgoing_fax_inbox_email_pattern: globalSettingsList.getSetting("outgoing_fax_inbox_email_pattern")
    }
}

export default withRouter(connect(mapStateToProps)(EmailToFaxCard))

