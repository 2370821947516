/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { get, map, size } from 'lodash'
import { ButtonBar } from '../../components/layout/ButtonBar'
import Timestamp from '../../components/Timestamp'
import { Trans, Translation } from 'react-i18next'
import { adminCustomerList } from '../actions/admin_customer'
import { adminCustomerFaxFromEmailAddressListForApproval } from '../actions/admin_customer_fax_from_email_address'
import Loading from '../../components/Loading'
import AdminMainLayout from './AdminMainLayout'
import BusyMask from '../../components/BusyMask'
import Modal from '../../components/Modal'
import {showSuccess, showError} from '../../actions/Error'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import AdminFaxTraffic from './AdminFaxTraffic'
import AdminInvoices from './AdminInvoices'
import AdminOutboundEmailList from './AdminOutboundEmailList'
import AdminCustomerMigration from './AdminCustomerMigration'
import { AdminCustomerReseller } from './AdminCustomerReseller'
import { AdminCustomerUpgradePath } from './AdminCustomerUpgradePath'
import AdminCustomerTransactions from './AdminCustomerTransactions'
import { AdminCustomerCdrs } from './AdminCustomerCdrs'
import { Col, Row, Container, Tabs, Tab, Button } from 'react-bootstrap'
import Card from '../../components/layout/Card'
import { CardHeader } from '../../components/layout/CardHeader'
import { Form as BootstrapForm } from 'react-bootstrap'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikTextarea } from '../../components/form/TextareaField'
import AdminCustomerSubscriptions from './AdminCustomerSubscriptions'
import AdminFaxNumbersCard from './AdminFaxNumbersCard'
import AdminVoiceNumbersCard from './AdminVoiceNumbersCard'
import AdminCustomerFaxSettings from './AdminCustomerFaxSettings'
import AdminCustomerDetails from './AdminCustomerDetails'
import AdminFaxFromEmailAddressApprovalForm from './AdminFaxFromEmailAddressApprovalForm'
import { BlueLinkButton } from '../../components/layout/BlueLinkButton'
import { Separator } from '../../components/layout/Separator'
import { default_theme as theme } from '../../emotion/theme'

class AdminCustomer extends Component {

    componentDidMount() {
        const { dispatch, customer_id } = this.props
        dispatch(adminCustomerList.fetchListIfNeeded())
        dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
        dispatch(adminCustomerFaxFromEmailAddressListForApproval.updateListFilter({customer:customer_id,
                                                                                   is_wildcard_email_address: true,
                                                                                   requires_approval: true}))
    }

    componentDidUpdate() {
        const { dispatch, customer, customer_id, admin_fax_from_email_address_list_filter } = this.props
        dispatch(adminCustomerList.fetchListIfNeeded())
        if ( customer_id != admin_fax_from_email_address_list_filter.customer ) {
            dispatch(adminCustomerFaxFromEmailAddressListForApproval.updateListFilter({customer:customer_id}))
        }
        dispatch(adminCustomerFaxFromEmailAddressListForApproval.fetchListIfNeeded())
    }

    setTab = (tab_name) => {
        const { history, customer_id } = this.props
        history.push(`/admin/customer/${customer_id}/${tab_name}`)
    }

    onViewCustomerProfile = () => {
        const { history, customer_id } = this.props
        history.push(`/admin/customer_profile/${customer_id}`)
    }

    renderTabContainer() {
        const { customer, customer_id, item_id } = this.props
        let active_tab = get(this.props, ["match", "params", "active_tab"], 'client_details')

        if ( active_tab === 'subscription' ) {
            active_tab = 'subscriptions'
        }
            
        const that = this
        return (
            <Col>
              <Tabs activeKey={active_tab} defaultActiveKey={active_tab} id="tabs" onSelect={that.setTab} css={customer_submenu}>
                <Tab eventKey="client_details" title="Client details">
                  <Separator variant="h15" />
                  { active_tab === "client_details" && <AdminCustomerDetails customer_id={customer_id} /> }
                </Tab>
                <Tab eventKey="subscriptions" title="Subscriptions">
                  <Separator variant="h15" />
                  { active_tab === "subscriptions" && <AdminCustomerSubscriptions customer_id={customer_id} customer_subscription_id={item_id} /> }
                </Tab>
                <Tab eventKey="voice_settings" title="Voice">
                  <Separator variant="h15" />
                  { active_tab === "voice_settings" && <AdminVoiceNumbersCard customer_id={customer_id} /> }
                </Tab>
                <Tab eventKey="numbers" title="Fax-In">
                  <Separator variant="h15" />
                  { active_tab === "numbers" && <AdminFaxNumbersCard customer_id={customer_id} /> }
                </Tab>
                <Tab eventKey="fax_settings" title="Fax-Out">
                  <Separator variant="h15" />
                  { active_tab === "fax_settings" && <AdminCustomerFaxSettings customer_id={customer_id} /> }
                </Tab>
                <Tab eventKey="traffic" title="Traffic">
                  <Separator variant="h15" />
                  { active_tab === "traffic" && <AdminFaxTraffic customer_id={customer_id} /> }
                </Tab>
                <Tab eventKey="transactions" title="Transactions">
                  <Separator variant="h15" />
                  { active_tab === "transactions" && <AdminCustomerTransactions customer_id={customer_id} /> }
                </Tab>
                <Tab eventKey="cdrs" title="CDR">
                  <Separator variant="h15" />
                  { active_tab === "cdrs" && <AdminCustomerCdrs customer_id={customer_id} /> }
                </Tab>
                <Tab eventKey="invoices" title="Invoices">
                  <Separator variant="h15" />
                  { active_tab === "invoices" && <AdminInvoices customer_id={customer_id} /> }
                </Tab>
                <Tab eventKey="outbound_emails" title="Outbound Emails">
                  <Separator variant="h15" />
                  { active_tab === "outbound_emails" && <AdminOutboundEmailList customer_id={customer_id} /> }
                </Tab>
                <Tab eventKey="migration" title={`Migration ${customer.is_legacy ? "(legacy)" : "(prod)"}`}>
                  <Separator variant="h15" />
                  { active_tab === "migration" && <AdminCustomerMigration customer_id={customer_id} /> }
                </Tab>
                <Tab eventKey="reseller" title="Reseller">
                  <Separator variant="h15" />
                  { active_tab === "reseller" && <AdminCustomerReseller customer_id={customer_id} /> }
                </Tab>
                <Tab eventKey="upgrade_paths" title="Upgrade paths">
                  <Separator variant="h15" />
                  { active_tab === "upgrade_paths" && <AdminCustomerUpgradePath customer_id={customer_id} /> }
                </Tab>
              </Tabs>
            </Col>
        )
    }

    renderUnapprovedEmailAddresses() {
        const { unapproved_email_addresses } = this.props
        const that = this
        if ( size(unapproved_email_addresses) === 0 ) {
            return null
        }
        return (
            <Card>
              <div css={unapproved_email_addresses_style}>
                <Row>
                  <Col>
                    { map(unapproved_email_addresses, function(unapproved_email_address, index) {
                          return (
                              <div key={index}>
                                <div css={unapproved_card__header}>Domain approval requested</div>
                                <div>
                                  This customer wants to add the following domain names to his/her list of valid email-to-fax-addresses:
                                </div>
                                <div css={unapproved_card__email}> 
                                  {unapproved_email_address.email_address}
                                </div>
                                <AdminFaxFromEmailAddressApprovalForm email_address={unapproved_email_address} />
                              </div>
                          )
                      })
                    }
                  </Col>
                </Row>
              </div>
            </Card>
        )
    }

    renderCustomerInfo() {
        const { customer } = this.props
        return (
            <div css={customer_info_style}>
              <h1>{get(customer, ["user", "display_name"])}</h1>
              <div css={customer_info_date_style}>
                Since <Timestamp value={get(customer, 'created_at')} format="date" />

                { ! get(customer, ["user", "is_active"]) &&
                  <div css={cancelled_at_style}>
                    Cancelled
                    { get(customer, ["user", "cancelled_at"]) &&
                      <>
                        <Separator variant="w5" />
                        <Timestamp value={get(customer, ["user", "cancelled_at"])} format="date" />
                      </>
                    }
                  </div>
                }
              </div>
            </div>
        )
    }

    render() {
        const { customer_id, is_busy, is_loading, customer } = this.props
        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'customers', label: 'Customers', url: '/admin/customers'},
                                           {name: 'customer', label: `${get(customer, ["user", "display_name"], '')} ${get(customer, ["short_id"])}`, url: `/admin/customer/${customer_id}`}]}>
              { is_busy && <BusyMask /> }
              <WrappingBusyMask is_loading={is_loading}>
                { !is_loading &&
                  <Container fluid>
                    { this.renderUnapprovedEmailAddresses() }
                    { this.renderCustomerInfo() }
                    <Separator variant="h30" />
                    <Row>
                      { this.renderTabContainer() }
                    </Row>
                  </Container>
                }
              </WrappingBusyMask>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const customer_id = get(props, ["match", "params", "customer_id"], null)
    const item_id = get(props, ["match", "params", "item_id"], null)
    const customer = customer_id && adminCustomerList.getObject(customer_id)
    const unapproved_email_addresses = adminCustomerFaxFromEmailAddressListForApproval.getVisibleObjects()

    return {
        customer_id,
        customer,
        is_loading: adminCustomerList.isLoading() || !customer || !customer.id,
        is_busy: adminCustomerList.getIsSavingObject(),
        admin_fax_from_email_address_list_filter: adminCustomerFaxFromEmailAddressListForApproval.getFilter(),
        unapproved_email_addresses,
        item_id
    }
}
export default withRouter(connect(mapStateToProps)(AdminCustomer))

const customer_title_style = css`
display: flex;
`

const customer_title_since_style = css`
font-size: 16px;
display: flex;
align-items: flex-end;
padding-bottom: 3px;
font-weight: normal;
`

const unapproved_email_addresses_style = css`
background-color: #fff3cd;
border: 1px solid #ffeeba;
padding: 20px;
color: #856404;
`

const unapproved_card__header = css`
font-size: 24px;
font-weight: 500;
`

const unapproved_card__email = css`
font-size: 18px;
font-weight: 500;
`

const unapproved_card__buttons = css`
margin-top: 15px;
`

const customer_info_style = css`
display: flex;
align-items: baseline;
`

const customer_info_date_style = css`
display: flex;
align-items: flex-end;
padding-bottom: 5px;
margin-left: 10px;
`
const customer_submenu = css`
a {
  margin-bottom: 7px !important;
  margin-right: 4px;
  border: none !important;
  border-radius: 4px; 
}
a:hover {
  background-color: ${theme.colors.very_light_grey};
  color: #0788ca;
}
a.active {
  background-color: #e5e5ea !important;
  color: #0788ca !important;
}

.nav-link {
color: black;
}

`

const cancelled_at_style = css`
display: flex;
color: ${theme.colors.red};
margin-left: 10px;
`
