import { ItemList } from '../orm'
import { includes, trim, get, head, filter, startsWith } from 'lodash'

export class AnsweringMessageList extends ItemList {
    getEntityKey() {
        return "answering_message"
    }

    isStandardGreeting(answering_message) {
        return includes(['standard_greeting_en', 'standard_greeting_nl'],
                        get(answering_message, "answering_message_type"))
    }

    getBestAnsweringMessageForId({customer_phone_number, answering_message_id}) {
        const answeringMessages = this.getVisibleObjects()

        if ( startsWith(answering_message_id, "__" ) ) {
            const answering_message_type = trim(answering_message_id, "_")
            return this.getStandardAnsweringMessageData({customer_phone_number, answering_message_type})
        }
        return head(filter(answeringMessages, (am) => am.id === answering_message_id))
    }
    
    getStandardAnsweringMessageData({customer_phone_number, answering_message_type}) {
        if ( answering_message_type === 'standard_greeting_en' ) {
            return { id: '__standard_greeting_en__',
                     type: 'standard_greeting_en',
                     name: 'Play standard greeting - English',
                     answering_message_file_info_details: get(customer_phone_number, `answering_message_standard_greeting_en_file_info_details`)}
        } else if ( answering_message_type === 'standard_greeting_nl' ) {
            return { id: '__standard_greeting_nl__',
                     type: 'standard_greeting_nl',
                     name: 'Play standard greeting - Dutch',
                     answering_message_file_info_details: get(customer_phone_number, `answering_message_standard_greeting_nl_file_info_details`)}
        } else {
            console.error(`Unknown answering message type ${answering_message_type}`)
            return null
        }
    }
    
}


export const answeringMessageList = new AnsweringMessageList("answering_message__default")
