/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css, Global } from '@emotion/react'
import { Trans, Translation } from 'react-i18next'
import { default_theme as theme } from '../../emotion/theme'
import Select from 'react-select'
import { union, map, get, head, filter } from 'lodash'
import { useField } from 'formik'
import FormError from './FormError'
import FormWarning from './FormWarning'
import { Dropdown as BootstrapDropdown } from 'react-bootstrap'
import { InlineIcon } from '../layout/InlineIcon'
import { Separator } from '../layout/Separator'
import { Dropdown as SemanticDropdown } from 'semantic-ui-react'
import loading_icon from '../../images/loading.gif'

export const SemanticDropdownStandalone = ({options, placeholder, disabled, default_value, on_change, is_saving, allowAdditions, onAddItem, ...props}) => {

    options = map(options, function(option) { return { key: option.value, value: option.value, text: <Trans>{option.label}</Trans> } })

    const localOnChange = (e, {value}) => {
        on_change(value)
    }

    return (
        <div css={semantic_dropdown_container_style}>
          <Global styles={semantic_global_styles} />
          <SemanticDropdown css={css}
                            className="xoip_semantic_dropdown"
                            placeholder={placeholder || 'Select'}
                            fluid
			    disabled={disabled===true}
                            selection
                            onChange={localOnChange}
                            options={options}
                            value={default_value}
                            allowAdditions={allowAdditions}
                            onAddItem={onAddItem}
          />
          { is_saving && <div css={loading_image_style}><img src={loading_icon} alt="loading"/></div> }
        </div>
    )
}

export const BootstrapDropdownStandalone = ({options, default_value, on_change}) => {

    const onChange = (option) => {
        on_change(option.value)
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div
          css={bootstrap_dropdown_toggle_style}
          ref={ref}
          onClick={(e) => {
              e.preventDefault();
              onClick(e);
          }}
        >
          <div css={bootstrap_dropdown_toggle_content_style}>
            <div>
              {children}
            </div>
            <div>
              <InlineIcon icon_name="caret-down" variant="black" />
            </div>
          </div>
        </div>
    ))
    
    return (
        <div css={bootstrap_dropdown_standalone_style}>
          <BootstrapDropdown css={bootstrap_dropdown_inner_style}>
            <BootstrapDropdown.Toggle variant="success" as={CustomToggle}>
              {default_value}
            </BootstrapDropdown.Toggle>

            <BootstrapDropdown.Menu css={bootstrap_dropdown_standalone_style}>
              {map(options, (option, index) => 
                  <BootstrapDropdown.Item key={index} onClick={() => onChange(option)}>
                    {option.label}
                  </BootstrapDropdown.Item>
              )}
            </BootstrapDropdown.Menu>
          </BootstrapDropdown>
        </div>
    )
} 

export const DropdownStandalone = ({variant, ...props}) => {
    variant = variant || "semantic"
    if ( variant === 'semantic' ) {
        return SemanticDropdownStandalone({...props})
    } else if ( variant === 'bootstrap' ) {
        return BootstrapDropdownStandalone({...props})
    } else {
        return "Error"
    }
}

export const FormikDropdownField = ({ label, formik_props, variant, options, placeholder, on_change, empty_selection_label, onAddItem, extra_css, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props)

    variant = variant || "semantic"

    let default_value
    if ( variant === 'bootstrap' ) {
        default_value = get(head(filter(options, (option) => option.value === field.value)),
                            "label", <div css={bootstrap_placeholder_style}>{placeholder || "Select an option"}</div>)
    }
    if ( variant === 'semantic' ) {
        default_value = field.value
    }

    if ( empty_selection_label ) {
        options = union([{ value: null, label: empty_selection_label }], options)
    }
    
    const onChange = (value) => {
        formik_props.setFieldValue(field.name, value)
        if ( on_change ) {
            on_change(value)
        }
    }

    const handleAddition = (e, {value} ) => {
        onAddItem(value)
    }

    const allow_additions = onAddItem !== undefined

    return (
        <div css={extra_css}>
          { variant === 'semantic' && <SemanticDropdownStandalone options={options} default_value={default_value} on_change={onChange} placeholder={placeholder} allowAdditions={allow_additions} onAddItem={handleAddition} /> }
          { variant === 'bootstrap' && <BootstrapDropdownStandalone options={options} default_value={default_value} on_change={onChange} placeholder={placeholder} /> }
          { meta.error && meta.touched ?
            <FormError meta={ meta } />
            :
            <FormWarning meta={ meta } />
          }
        </div>
    )

}

const bootstrap_dropdown_toggle_style = css`
cursor: pointer;
width: 100%;
`

const bootstrap_dropdown_toggle_content_style = css`
display: flex;
align-items: center;
justify-content: space-between;
`

const bootstrap_dropdown_standalone_style = css`
width: 100%;
border: 1px solid ${theme.colors.light_middle_grey};
padding: 8px;
`

const bootstrap_dropdown_inner_style = css`
width: 100%;
`

const bootstrap_placeholder_style = css`
color: ${theme.colors.dark_middle_grey};
`

const semantic_global_styles = css`

.xoip_semantic_dropdown.ui.dropdown {
min-height: 38px;
max-height: 38px;
padding-top: 10px;
min-width: 150px;
}

.xoip_semantic_dropdown.ui.dropdown .dropdown.icon {
top: 8px;
}

.xoip_semantic_dropdown.ui.dropdown:not(.button)>.default.text {
  color: #000000;
}

`

const loading_image_style = css`
opacity: 1;
width: 25px;
position: absolute;
top: 5px;
right: 9px;
`

const semantic_dropdown_container_style = css`
position: relative;
`
