/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { Trans, Translation } from 'react-i18next'
import { map, get, size, forEach } from 'lodash'
import { Separator } from '../../components/layout/Separator'
import { FormikInputField } from '../../components/form/InputField'
import { Formik, Form, Field } from 'formik'
import {showSuccess, showError} from '../../actions/Error'
import { InlineIcon } from '../../components/layout/InlineIcon'
import FieldInfoText from '../../components/layout/FieldInfoText'
import { adminEditableSettingList } from '../actions/admin_editable_setting'
import { GrayButton } from '../../components/layout/GrayButton'
import { FormLabelValue } from '../../components/form/FormLabelValue'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { handleSubmitResult } from '../../actions/form'
import { globalSettingsList } from '../../actions/settings'
import { Col, Row, Container } from 'react-bootstrap'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    value: Yup.string().required("Required"),
})

export const AdminEditableSetting = ({editable_setting_id}) => {
    const dispatch = useDispatch()

    const editableSetting = useSelector(() => adminEditableSettingList.getObject(editable_setting_id))

    useEffect(() => {
        dispatch(adminEditableSettingList.ensureObjectLoaded(editable_setting_id))
    }, [editable_setting_id])

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(globalSettingsList.refreshSettings())
            showSuccess("Saved", `Setting ${editableSetting.name} saved`)
        }
        values.id = editable_setting_id
        return dispatch(adminEditableSettingList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }
    
    return (
        <Translation>{ (t) => (
            <div>

              <Formik initialValues={editableSetting}
                      onSubmit={onSave}
                      enableReinitialize={true}
                      validationSchema={validationSchema}
              >
                {formik_props => {
                    const { values } = formik_props
                    return (
                        <Form>
                          <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                          <FormLabelValue spacing="h10">
                            <div>
                              {get(editableSetting, "name")}
                              <FieldInfoText>
                                {get(editableSetting, "description")}
                              </FieldInfoText>
                            </div>

                            <Row>
                              <Col md="11">
                                <FormikInputField name="value" />
                              </Col>
                              <Col>
                                <GrayButton type="submit">
                                  Save
                                </GrayButton>
                              </Col>
                            </Row>
                          </FormLabelValue>
                          
                          <Separator variant="h15" />

                          
                        </Form>
                    )
                }}
              </Formik>

            </div>
        )}</Translation>
    )
    
}
