/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css, Global } from '@emotion/react'
import { includes, isEmpty } from 'lodash'
import ImagePreview from './ImagePreview'
import { Pluralize } from './Pluralize'
import PdfPreview from './PdfPreview'
import { Button } from 'react-bootstrap'
import { InlineIcon } from './layout/InlineIcon'
import placeholder from '../images/placeholder_dark.png'
import { Modal } from 'react-bootstrap'
import { formatBytes } from '../lib/converters'
import { Separator } from './layout/Separator'

class MediaPreviewPopup extends Component {

    constructor(props) {
        super(props)
        this.state = {is_preview_visible: false, current_page_number: 1}
    }

    componentDidMount() {
        const { initially_visible } = this.props
        if ( initially_visible === true ) {
            this.setState({is_preview_visible: true})
        }
    }

    showPreview = () => {
        this.setState({is_preview_visible: true})
    }

    hidePreview = () => {
        this.setState({is_preview_visible: false})
    }

    onNextPage = () => {
        const { file } = this.props
        const { current_page_number } = this.state
        let new_page_number = current_page_number+1
        if ( new_page_number > file.page_count ) {
            new_page_number = file.page_count
        }
        this.setState({current_page_number: new_page_number})
    }

    onPrevPage = () => {
        const { current_page_number } = this.state
        let new_page_number = current_page_number-1
        if ( new_page_number < 1 ) {
            new_page_number = 1
        }
        this.setState({current_page_number: new_page_number})
    }

    renderTitle({supports_navigation}) {
        const { file } = this.props
        return (
            <div css={title_filename}>
              <div css={title_filename_style}>
                <div css={title_filename_centerable_wrapper_style}>
                  {file.visible_filename}
                </div>
              </div>
              <div css={title_subheading_style}>
                <Pluralize singular="page" count={file.page_count} />
            ,&nbsp;
            {formatBytes(file.size_bytes)}
              </div>
            </div>
        )
    }
    
    render() {
        const { file, render_preview_trigger } = this.props
        const supports_navigation = file.content_type === "application/pdf"
        const { is_preview_visible, current_page_number } = this.state
        return (
            <div css={container}>
              <Global styles={global_styles} />
              { !isEmpty(file) && render_preview_trigger && render_preview_trigger(this.showPreview) }
              { !isEmpty(file) && !render_preview_trigger && (
                <Button variant="outline-secondary" onClick={this.showPreview}>
                  Preview
                </Button>
              )}
              
              { is_preview_visible &&
                <Modal show={true}
                       size="xl"
                       onHide={this.hidePreview} >

                  <Modal.Header closeButton css={modal_header_style}>
                    <Modal.Title>
                      { this.renderTitle({supports_navigation}) }
                    </Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <div className="render-preview" css={image_container}>
                      { includes(file.content_type, "image/") &&
                        <ImagePreview imageFile={file} />
                      }
                      { file.content_type === "application/pdf" &&
                        <PdfPreview pdf_file={file} page_number={current_page_number} renderNavigation={CustomNavigation} />
                      }
                    </div>
                  </Modal.Body>
                  
                </Modal>
              }
            </div>
        )
    }
}

export default MediaPreviewPopup

const CustomNavigation = props => {
    const { page, pages, handlePrevClick, handleNextClick } = props
    if ( pages <= 1 ) {
        return null
    }
    return (
        <div css={[custom_navigation, title_subheading_style]}>
          { page <= 1 && 
            <InlineIcon icon_name="caret-left" variant="gray" as_block={true} size_variant="h18" />
          }
          { page > 1 && 
            <InlineIcon icon_name="caret-left" onClick={handlePrevClick} as_block={true} variant="black" size_variant="h18" />
          }
          <Separator variant="w10" />
          {page}
          <Separator variant="w14" />
          { page >= pages && 
            <InlineIcon icon_name="caret-right" variant="gray" as_block={true} size_variant="h18"/>
          }
          { page < pages && 
            <InlineIcon icon_name="caret-right" onClick={handleNextClick} as_block={true} variant="black" size_variant="h18" />
          }
        </div>
    )
}

const global_styles = css`
.modal-content .close {
position: absolute;
top: 20px;
right: 30px;
}
`

const container = css`
position: relative;
`

const image_container = css`
overflow: auto;
`

const image_class = css`
max-height: 400px;
max-width: 400px;

`

const title_filename_style = css`
font-weight: 500;
width: 90%;

`

const title_filename_centerable_wrapper_style = css`
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
width: 100%;
`

const title_subheading_style = css`
font-weight: 400;
display: flex;
font-size: 16px;
`

const title_filename = css`
`

const custom_navigation = css`
position: relative;
margin: 0 50% 10px;
`

const modal_header_style = css`
display: block;
`

