/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, Translation } from 'react-i18next'
import { Row, Col, Button, Modal, Container } from 'react-bootstrap'
import { showSuccess, showError } from '../actions/Error'
import { jsx, css } from '@emotion/react'
import { get } from 'lodash'
import { subscriptionList } from '../actions/customer_subscription'
import { customerPhoneNumberList } from '../actions/customer_phone_number'
import { customerSubscriptionList } from '../actions/customer_subscription'

import { handleSubmitResult } from '../actions/form'
import Form from 'react-bootstrap/Form'
import { Formik, FieldArray, Field } from 'formik'
import { FormikInputField } from './form/InputField'
import { FormikGeneralFormErrors } from './form/GeneralFormErrors'
import * as Yup from 'yup'
import { ButtonBar } from './layout/ButtonBar'
import { BlueButton } from './layout/BlueButton'
import { WhiteButton } from './layout/WhiteButton'
import { MultipleEmailsSupportedInfoWidget } from './MultipleEmailsSupportedInfoWidget'

function FaxSubscriptionEditModal({ show, subscription, onClose }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(customerPhoneNumberList.fetchListIfNeeded())
  }, [])

  const number_being_edited = subscription.customer_phone_number

  const emailSchema = Yup.array().of(Yup.string().email())

  const validationSchema = Yup.object().shape({
    phone_number_email: Yup.string()
      .required('Email is required')
      .test('Validate Email', 'Email must be valid', (value) => {
        const emails = value.split(',').map((email) => email.trim())
        return emailSchema.isValidSync(emails)
      }),
    phone_number_name: Yup.string().required('Name is required'),
  })

  const onSave = (values, formik_funcs) => {
    const on_ok = (json) => {
      dispatch(customerPhoneNumberList.invalidateList())
      dispatch(customerSubscriptionList.invalidateObject(subscription.id))
      dispatch(customerSubscriptionList.ensureObjectLoaded(subscription.id))

      showSuccess(<Trans>Saved</Trans>, <Trans>Fax number saved</Trans>)
      onClose()
    }
    values.id = number_being_edited.id
    return dispatch(customerPhoneNumberList.saveObject(values)).then((res) =>
      handleSubmitResult({ res, formik_funcs, on_ok })
    )
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans>Edit Fax Subscription</Trans>
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          phone_number_name: number_being_edited.phone_number_name || '',
          phone_number_email: number_being_edited.phone_number_email || '',
        }}
        onSubmit={onSave}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {(formik_props) => {
          return (
            <Form>
              <FormikGeneralFormErrors />
              <Modal.Body css={modal_body}>
                <div>
                  <div>
                    <Trans>Fax number</Trans>
                  </div>
                  <div css={fax_number_style}>
                    {subscription?.phone_number_number}
                  </div>
                </div>
                <Form.Group controlId="subscriptionName">
                  <Form.Label>
                    <Trans>Name</Trans>
                  </Form.Label>
                  <FormikInputField name="phone_number_name" type="text" />
                </Form.Group>
                <Form.Group controlId="subscriptionEmail">
                  <Form.Label>
                    <Trans>Incoming faxes go to</Trans>
                    <span css={space_left}>
                      <MultipleEmailsSupportedInfoWidget />
                    </span>
                  </Form.Label>
                  <FormikInputField name="phone_number_email" type="email" />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <ButtonBar>
                  <WhiteButton variant="secondary" onClick={onClose}>
                    <Trans>Cancel</Trans>
                  </WhiteButton>
                  <BlueButton
                    variant="primary"
                    onClick={formik_props.submitForm}
                  >
                    <Trans>Save</Trans>
                  </BlueButton>
                </ButtonBar>
              </Modal.Footer>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default FaxSubscriptionEditModal

const modal_body = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const fax_number_style = css`
  font-size: 18px;
  font-weight: 600;
`
const space_left = css`
  margin-left: 6px;
`
