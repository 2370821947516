/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { default_theme as theme } from '../emotion/theme'
import { connect } from 'react-redux'
import { map, size, head, get, forEach } from 'lodash'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import MainLayout from './MainLayout'
import { MobileOnly } from './layout/MobileOnly'
import { DesktopOnly } from './layout/DesktopOnly'
import { BlueLinkButton } from './layout/BlueLinkButton'
import { Row, Col, Container } from 'react-bootstrap'
import { InlineIcon } from './layout/InlineIcon'
import SendAFaxCard from './SendAFaxCard'
import FaxNumbersCard from './FaxNumbersCard'
import { PendingAssignmentNumbersCard } from './PendingAssignmentNumbersCard'
import EmailToFaxCard from './EmailToFaxCard'
import { customerList } from '../actions/customer'
import { Separator } from './layout/Separator'
import { CardHeader } from './layout/CardHeader'
import Card from './layout/Card'
import CardParagraphSubHeading from './layout/CardParagraphSubHeading'
import { Trans, Translation } from 'react-i18next'
import { customerSubscriptionNumberPendingList } from '../actions/customer_subscription'
import { UpgradePathNotifications } from './UpgradePathNotifications'
import FaxInPromoCard from './FaxInPromoCard'

class SendAFaxDashboard extends Component {
  componentDidMount() {
    const { dispatch } = this.props
    dispatch(
      customerSubscriptionNumberPendingList.filter_waiting_for_number_assignment(
        { can_receive_faxes_only: true }
      )
    )
  }

  onSeeAvailableFaxNumberSubscriptions = () => {
    const { history } = this.props
    history.push('/subscription/signup/')
  }

  renderDoYouWantToReceiveFaxes = () => {
    return (
      <div>
        <CardHeader title={<Trans>Do you want to receive faxes?</Trans>} />
        <Card variant="white_wide_padding" with_padding_below={false}>
          <Separator variant="h10" />
          <div>
            <CardParagraphSubHeading>
              <Trans>Get a fax number and receive faxes in your email</Trans>
              <br />
              <Separator variant="h10" />
              <BlueLinkButton
                onClick={this.onSeeAvailableFaxNumberSubscriptions}
              >
                <div css={link_container_style}>
                  <Trans>See available fax number subscriptions</Trans>
                  <Separator variant="w3" />
                  <InlineIcon
                    icon_name="chevron-right"
                    size_variant="h14"
                    variant="blue"
                  />
                </div>
              </BlueLinkButton>
            </CardParagraphSubHeading>
          </div>
        </Card>
        <Separator variant="h30" />
      </div>
    )
  }

  render() {
    const { customer, number_pending_subscriptions } = this.props
    return (
      <MainLayout
        active_key="fax"
        enable_breadlinks={true}
        breadcrumbs={[
          { name: 'home' },
          { name: 'fax', label: 'Fax', url: '/send_a_fax' },
        ]}
      >
        {customer.has_active_fax_out_subscription && (
          <Row>
            <Col>
              <Col>
                <UpgradePathNotifications display_style="send_a_fax_dashboard" />
              </Col>
            </Col>
          </Row>
        )}

        <Row>
          {customer.has_active_fax_out_subscription && (
            <Col md={6}>
              <MobileOnly>
                <SendAFaxCard />
              </MobileOnly>

              <DesktopOnly>
                <Container fluid css={left_side_container}>
                  <SendAFaxCard />
                </Container>
              </DesktopOnly>
            </Col>
          )}
          {!customer.has_active_fax_out_subscription && (
            <Separator variant="w15" />
          )}
          <Col md={6}>
            <Container fluid css={right_side_container}>
              <MobileOnly>
                <Separator variant="h40" />
              </MobileOnly>
              {!customer.has_fax_in_subscription && <FaxInPromoCard />}

              {customer.has_active_fax_out_subscription && (
                <Row>
                  <MobileOnly>
                    <Separator variant="w20" />
                  </MobileOnly>
                  <Col>
                    <EmailToFaxCard />
                  </Col>
                </Row>
              )}
            </Container>
          </Col>
        </Row>
      </MainLayout>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    customer: customerList.getCustomer(),
    number_pending_subscriptions:
      customerSubscriptionNumberPendingList.getVisibleObjects(),
  }
}

export default withRouter(connect(mapStateToProps)(SendAFaxDashboard))

const left_side_container = css`
  padding-right: 0px;
`

const right_side_container = css`
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-left: 0px;
`

const link_container_style = css`
  display: flex;
  align-items: center;
`
