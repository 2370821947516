/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { Modal } from 'react-bootstrap'
import WrappingBusyMask from './WrappingBusyMask'
import { PhoneNumber } from './PhoneNumber'
import { Duration } from './Duration'
import { Trans } from 'react-i18next'
import { Separator } from './layout/Separator'
import { Timestamp } from './Timestamp'
import { InlineIcon } from './layout/InlineIcon'
import { cdrTypes } from '../actions/dropdown_options'


export const VoiceDetailsModal = ({cdr_id, cdrList, onClose}) => {

    const dispatch = useDispatch()
    const cdr = useSelector(() => cdrList.getObject(cdr_id))
    const cdr_types = useSelector(() => cdrTypes.getObjectsById())

    useEffect(() => {
        dispatch(cdrTypes.fetchListIfNeeded())
    }, [])
    
    useEffect(() => {
        dispatch(cdrList.ensureObjectLoaded(cdr_id))
    }, [cdr_id])

    const renderContent = () => {
        return (
            <div>
              
              <div css={summary_row_style}>
                <Trans>Received</Trans>:&nbsp;
                <Timestamp value={cdr.created_at} format="at" />
              </div>

              <div css={summary_row_style}>
                <Trans>Type</Trans>:&nbsp;
                {get(cdr_types, [cdr.cdr_type, "name"], cdr.cdr_type)}
              </div>
              <Separator variant="h10" />

              <div css={summary_row_style}>
                <Trans>From</Trans>:&nbsp;
                <PhoneNumber phone_number={cdr.source_number} />
              </div>
              <Separator variant="h10" />

              <div css={summary_row_style}>
                <Trans>To</Trans>:&nbsp;
                <PhoneNumber phone_number={cdr.destination_number} />
              </div>
              <Separator variant="h10" />

              <div css={summary_row_style}>
                <Trans>Forwarded to</Trans>:&nbsp;
                <PhoneNumber phone_number={cdr.final_number} />
              </div>
              <Separator variant="h10" />

              <div css={summary_row_style}>
                <Trans>Voice message email sent to</Trans>:&nbsp;
                {get(cdr, ["incoming_voice_message", "voice_message_sent_to_email_address"])}
              </div>
              <Separator variant="h10" />

              <div css={summary_row_style}>
                <Trans>Duration</Trans>:&nbsp;
                <Duration seconds={cdr.duration_seconds} />
              </div>
              <Separator variant="h10" />

              <div css={summary_row_style}>
                <Trans>External reference</Trans>:&nbsp;
                {cdr.external_ref || "-"}
              </div>
              <Separator variant="h10" />
              
            </div>
        )
    }
    
    return (
        <Modal show={true}
               size="lg"
               onHide={onClose} >

          <WrappingBusyMask is_loading={cdrList.isLoading()}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Trans>Voice call details</Trans> : {cdr.short_id}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              { renderContent() }
            </Modal.Body>
          </WrappingBusyMask>
          
        </Modal>
    )
    
}

const summary_row_style = css`
display: flex;
align-items: center;
`

const error_item_container = css`
margin-left: 10px;
`

