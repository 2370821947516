import { ItemList } from '../orm'

class ProductList extends ItemList {
    getEntityKey() {
        return "product"
    }

}

export const productList = new ProductList("product__default")
export const productListForUpgradePath = new ProductList("product__for_upgrade_path")
export const productListForUpgradePathFreeToPaid = new ProductList("product__for_upgrade_path_free_to_paid")
