import { ItemList } from '../orm'
import { filter, get } from 'lodash'
import { download } from './lib'

export class OutgoingFaxQueueList extends ItemList {
    getEntityKey() {
        return "outgoing_fax_queue"
    }

    saveProvisional(values) {
        values.status_name = 'PROVISIONAL'
        values.destination_phone_numbers = filter(values.destination_phone_numbers, (x) => x.number != null)
        if ( values.id ) {
            return this.saveObject(values)
        } else {
            return this.saveNewObject(values)
        }
    }

    addToQueue(values, on_done, formik_funcs) {
        values.status_name = 'NEW'
        return this.saveObject(values, on_done, formik_funcs)
    }

    downloadItemConfirmation(outgoing_fax_queue_item_id) {
        const url = `outgoing_fax_confirmation/${outgoing_fax_queue_item_id}/download/`
        return download(url)
    }
    
}

export const outgoingFaxQueueList = new OutgoingFaxQueueList("outgoing_fax_queue__default")
