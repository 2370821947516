import { ItemList } from '../../orm'

class AdminGeneratedReportList extends ItemList {
    getEntityKey() {
        return "admin/generated_report"
    }

    downloadReport(report) {
        return 
    }
    
}

export const adminGeneratedReportList = new AdminGeneratedReportList("admin_generated_report__default")
