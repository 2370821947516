/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react'
import { jsx, css } from '@emotion/react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, Translation } from 'react-i18next'
import { compact, map, size, head, get, forEach } from 'lodash'
import { default_theme as theme } from '../emotion/theme'
import { Separator } from './layout/Separator'
import { InlineIcon } from './layout/InlineIcon'
import { PhoneNumber } from './PhoneNumber'
import { customerSubscriptionNumberPendingList } from '../actions/customer_subscription'
import { customerSubscriptionManualReservationForPaymentList } from '../actions/customer_subscription_manual_reservation'

export const PendingAssignmentNumbersCard = ({itemList, can_receive_faxes_only, can_receive_voice_only, can_receive_voice_or_fax, render_inline}) => {

    const dispatch = useDispatch()
    const number_pending_subscriptions = useSelector(() => itemList.getVisibleObjects())
    const reservation_ids = map(number_pending_subscriptions, (x) => x.customer_subscription_manual_reservation)
    const reservations = useSelector(() => customerSubscriptionManualReservationForPaymentList.getObjects(reservation_ids))
    
    useEffect(() => {
        map(reservations, (reservation) => forEach(number_pending_subscriptions,
                                                   function(sub) {
                                                       if ( sub.customer_subscription_manual_reservation === reservation.id ) {
                                                           sub.reservation_details = reservation
                                                       }
                                                   }))
    }, [reservations])

    useEffect(() => {
        dispatch(customerSubscriptionManualReservationForPaymentList.ensureObjectsLoaded(reservation_ids))
    }, [reservation_ids])

    if ( !size(number_pending_subscriptions) ) {
        return null
    }
    
    return (
        <>

          { size(number_pending_subscriptions) > 0 &&
            <>
              <div css={render_inline ? number_pending_subscriptions_inline_style : number_pending_subscriptions_style}>
                { map(number_pending_subscriptions, (subscription, index) => (
                    <div key={index} css={[number_pending_subscription_row_common_style,
                                           render_inline ? number_pending_subscription_row_inline_style : number_pending_subscription_row_style]}>
                      <InlineIcon icon_name="info" variant="orange" />
                      { subscription.status_name == 'needs_number' && subscription.reservation_details && 
                        <>
                          <Trans>Your requested number is being processed</Trans>
                          &nbsp;
                          (
                          <PhoneNumber phone_number={get(subscription, ["reservation_details", "phone_number_prefix", "prefix"])} />
                          {get(subscription, "reservation_details.phone_number_prefix.city_name") &&
                           <span>, {get(subscription, ["reservation_details", "phone_number_prefix", "city_name"])}</span>}
                          )
                        </>
                      }

                      { subscription.status_name == 'porting' && subscription.reservation_details && 
                        <Trans i18nKey="pending_assignment_numbers_card__your_number_is_being_ported"
                               values={{ number: subscription.reservation_details.number}}
                               components={[<PhoneNumber enable_inline_spacing={true} phone_number={get(subscription, ["reservation_details", "number"])} />]}
                               defaults={"Your number <0></0> is being transferred to XOIP"}
                        />
                      }
                    </div>
                ))}
              </div>
            </>
          }
        </>
    )
    
    
}

const number_pending_subscriptions_inline_style = css`
`

const number_pending_subscriptions_style = css`
border-bottom: 1px solid ${theme.colors.light_middle_grey};
`

const number_pending_subscription_row_inline_style = css`

padding-left: 20px;
padding-right: 20px;

&:not(:last-child) {
  border-bottom: 1px solid ${theme.colors.light_middle_grey};
  padding-bottom: 10px;
  padding-top: 5px;
}

&:last-child {
  padding-top: 10px;
  padding-bottom: 5px;
}

`

const number_pending_subscription_row_style = css`
border-top: 1px solid ${theme.colors.light_middle_grey};
height: 50px;
padding-left: 20px;
padding-right: 20px;
`

const number_pending_subscription_row_common_style = css`
background: ${theme.colors.white};
display: flex;
align-items: center;
`

