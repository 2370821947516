import { ItemList } from '../orm'

class CustomerSubscriptionManualReservationList extends ItemList {
    getEntityKey() {
        return "customer_subscription_manual_reservation"
    }
    
}

export const customerSubscriptionManualReservationList = new CustomerSubscriptionManualReservationList("customer_subscription_manual_reservation__default")
export const customerSubscriptionManualReservationForPaymentList = new CustomerSubscriptionManualReservationList("customer_subscription_manual_reservation_for_payment__default")
