/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import { Formik, Form } from 'formik'
import { Separator } from './layout/Separator'
import { FormikFatIconCheckboxField } from './form/CheckboxField'
import { getBestLanguageCode } from '../actions/language'
import { globalSettingsList } from '../actions/settings'

export const TermsAndConditionsField = ({renderCheckbox}) => {

    const dispatch = useDispatch()
    const language_code = getBestLanguageCode()
    const external_info_urls = useSelector(() => globalSettingsList.getSetting('external_info_urls'))

    useEffect(() => {
        dispatch(globalSettingsList.fetchListIfNeeded())
    }, [])

    return (
        <div>
          <Translation>{ (t) => (
              <div css={terms_row}>
                { renderCheckbox() }
                <Separator variant="w5" />

                <div css={i_agree_with_style}>
                  <Trans>I have read and agree with the</Trans>
                  &nbsp;
                  <a target="_blank" href={get(external_info_urls, ["terms_and_conditions", language_code])}>
                    <Trans>XOIP Services Agreement</Trans>
                  </a>
                  <Trans>,</Trans>
                  &nbsp;
                  <a target="_blank" href={get(external_info_urls, ["privacy_policy", language_code])}>
                    <Trans>Privacy Policy</Trans>
                  </a>
                  &nbsp;
                  <Trans>and</Trans>
                  &nbsp;
                  <a target="_blank" href={get(external_info_urls, ["data_processing_agreement", language_code])}>
                    <Trans>Data Processing Agreement</Trans>
                  </a>.
                </div>
              </div>
          )}</Translation>
        </div>
    )
    
}

const terms_row = css`
display: flex;
font-weight: 500;
`

const i_agree_with_style = css`
align-items: baseline;
line-height: 26px;
`
