/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import {get, head, map, size, includes, replace} from 'lodash'
import { withRouter } from 'react-router-dom'
import { CardHeader } from '../../components/layout/CardHeader'
import { Container, Row, Col } from 'react-bootstrap'
import Timestamp from '../../components/Timestamp'
import CommonTable from '../../components/CommonTable'
import { adminInvoiceList } from '../actions/admin_invoice'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { FormLabel } from '../../components/form/FormLabel'
import FieldInfoText from '../../components/layout/FieldInfoText'
import InvoiceTable from '../../components/InvoiceTable'
import {showSuccess, showError} from '../../actions/Error'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikInputField } from '../../components/form/InputField'
import { confirmModal } from '../../actions/ui'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { Error } from '../../components/layout/Error'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { Separator } from '../../components/layout/Separator'
import AdminCreditInformationCard from './AdminCreditInformationCard'
import { LANGUAGES } from '../../actions/language'
import Card from '../../components/layout/Card'
import { LabelValue } from '../../components/layout/LabelValue'
import { GrayButton } from '../../components/layout/GrayButton'
import { BlueButton} from '../../components/layout/BlueButton'
import { Trans, Translation } from 'react-i18next'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { handleSubmitResult } from '../../actions/form'
import { Button } from 'react-bootstrap'
import AdminCustomerMigrationSubscriptions from './AdminCustomerMigrationSubscriptions'
import * as Yup from 'yup'
import { adminCustomerList } from '../actions/admin_customer'
import {adminCustomerMigrationList} from "../actions/admin_customer_migration"
import {adminCustomerMigrationLogList} from "../actions/admin_customer_migration_log"
import {adminTransactionList} from "../actions/admin_transaction"
import { adminWhenToSendWelcomeEmailOptions } from '../actions/admin_dropdown_options'
import {
    INCOMING_FAX_BY_EMAIL_INITIAL_VALUES_DEFAULT,
    OUTGOING_FAX_INITIAL_VALUES_DEFAULT
} from "../../actions/form_vars"
import CurrencyValue from "../../components/CurrencyValue"
import {default_theme as theme} from "../../emotion/theme"

const LEFT_WIDTH = 6

class AdminCustomerMigration extends Component {

    componentDidMount() {
        const { dispatch, customer_id, customer_migration } = this.props
        dispatch(adminCustomerList.ensureObjectLoaded(customer_id))        
        dispatch(adminCustomerMigrationList.updateListFilter({customer: customer_id}))
        dispatch(adminCustomerMigrationList.fetchListIfNeeded())
        dispatch(adminWhenToSendWelcomeEmailOptions.fetchListIfNeeded())

        if ( customer_migration ) {
            dispatch(adminCustomerMigrationLogList.updateListFilter({customer_migration: customer_migration.id}))
            dispatch(adminCustomerMigrationLogList.fetchListIfNeeded())
        }
    }

    componentDidUpdate() {
        const { dispatch, customer_id, customer_migration, customer_migration_log_filter } = this.props
        dispatch(adminCustomerList.ensureObjectLoaded(customer_id))        
        if ( customer_migration && customer_migration_log_filter.customer_migration !== customer_migration.id ) {
            dispatch(adminCustomerMigrationLogList.updateListFilter({customer_migration: customer_migration.id}))
            dispatch(adminCustomerMigrationLogList.fetchListIfNeeded())
        }
    }

    getCustomerMigrationLogCellValue = (header_key, item, index) => {
        const { customer_id } = this.props
        switch( header_key ) {
            case 'created_at':
                return <Timestamp value={item.created_at} format="datetime" />
            case 'note':
            return <pre css={customer_migration_log_note_style} dangerouslySetInnerHTML={{__html: replace(item.note, /,/g, "<br/>")}} />
        }
        return undefined 
    }

    onMigrate = (values, formik_funcs) => {

        const { dispatch, customer_id, customer_migration } = this.props
        const that = this

        const on_ok = function(json) {
            showSuccess("Saved", "Customer queued for migration")
            dispatch(adminCustomerMigrationList.invalidateList())

            if ( customer_id ) {
                dispatch(adminCustomerMigrationList.invalidateObject(json.id))
            }
            dispatch(adminCustomerMigrationList.fetchListIfNeeded())
        }

        values.customer = customer_id
        values.joomla_migration_status = 'to_migrate'
        values.joomla_error_message = null

        if ( customer_migration ) {
            values.id = customer_migration.id
            return dispatch(adminCustomerMigrationList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminCustomerMigrationList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    renderMigrateForm() {
        const { customer_migration, is_loading, when_to_send_welcome_email_options } = this.props
        const initial_values = {when_to_send_welcome_email: get(customer_migration, "when_to_send_welcome_email", 'with_first_email')}
        const welcome_email_already_sent = get(customer_migration, "welcome_email_sent_at", null)
        const migrated_at_least_once = get(customer_migration, "id") && get(customer_migration, "joomla_migration_status") !== "inactive"
        
        return (
            <Formik
              initialValues={initial_values}
              onSubmit={this.onMigrate}
              enableReinitialize={true}
            >
              {formik_props => {
                  const { values } = formik_props
                  return (
                          <Form>
                            <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                            <Row>
                              <Col md={LEFT_WIDTH}>

                                { ! welcome_email_already_sent &&
                                  <>
                                    <FormLabel>Migration welcome email</FormLabel>
                                    <FormikDropdownField options={when_to_send_welcome_email_options}
                                                         formik_props={formik_props}
                                                         name="when_to_send_welcome_email" />
                                    
                                    { get(values, "welcome_email") === 'no_welcome_email' &&
                                      <FieldInfoText>
                                        No welcome migration email will be sent to the customer.
                                      </FieldInfoText>
                                    }
                                    { get(values, "welcome_email") === 'with_first_email' &&
                                      <FieldInfoText>
                                        The email is sent only once the customer receives any other regular XIOP email.
                                        <br/>
                                        This means the customer may not receive this welcome email for some time, for example until they send their first fax.
                                        <br/>
                                      </FieldInfoText>
                                    }
                                    { get(values, "welcome_email") === 'after_migration' &&
                                      <FieldInfoText>
                                        The welcome email will be sent as soon as migration is completed.
                                      </FieldInfoText>
                                    }
                                  </>
                                }
                                { migrated_at_least_once &&
                                  <>
                                    <Separator variant="h50" />
                                    <FieldInfoText>
                                      Retriggering a migration is safe, and will also automatically happen for customers who change their fax-from-emails after their first migration.
                                    </FieldInfoText>
                                  </>
                                }
                              </Col>
                            </Row>
                            <Separator variant="h30" />
                            <Row>
                              <Col md={LEFT_WIDTH}>
                                <BlueButton type="submit">
                                  { migrated_at_least_once &&  <span>Re-trigger migration</span> }
                                  { ! migrated_at_least_once && <span>Migrate customer</span> }
                                </BlueButton>
                              </Col>
                            </Row>
                          </Form>
                  )
              }}
            </Formik>
        )
    }

    renderJoomlaStatus() {
        const { customer_migration, joomla_status } = this.props
        return (
            <div>

              <LabelValue>
                <Trans>Joomla migration status</Trans>
                <div>
                  { joomla_status === 'inactive' && <div css={status_to_migrate}><Trans>First migration hasn't been triggered</Trans></div> }
                  { joomla_status === 'to_migrate' && <div css={status_to_migrate}><Trans>Customer on the Queue to be Migrated</Trans></div> }
                  { joomla_status === 'migration_in_progress' && <div css={status_to_migrate}><Trans>Customer in progress of migration</Trans></div> }
                  { joomla_status === 'partial_migration' && <div css={status_partial_migration}><Trans>Customer partially migrated</Trans></div> }
                  { joomla_status === 'migration_complete' && <div css={status_migration_complete}><Trans>Customer fully migrated</Trans></div> }
                  { !joomla_status && <div><Trans>Migration not initiated</Trans></div> }
                </div>
              </LabelValue>
              
              { joomla_status === 'migration_failed' &&
                <div>
                  <Trans>Migration Error</Trans>
                  <div css={status_migration_failed}>
                    <Trans>Migration Failed</Trans>
                  </div>
                  {customer_migration.error_message}
                </div>
              }

              { joomla_status &&
                <div>
                  <LabelValue>
                    <Trans>Joomla migration triggered at</Trans>
                    <Timestamp value={get(customer_migration, "created_at")} />
                  </LabelValue>

                  <LabelValue>
                    <Trans>Joomla last migrated at</Trans>
                    <Timestamp value={get(customer_migration, "joomla_migrated_at")} />
                  </LabelValue>

                  
                </div>
              }
            </div>
        )
    }

    renderSubscriptionsStatus = () => {
        const { customer_migration, customer_id } = this.props
        const status = get(customer_migration, "customer_subscriptions_migration_status")
        const error = get(customer_migration, "customer_subscriptions_error_message")
        return (
            <div>
              <LabelValue>
                <Trans>Subscription migration status</Trans>
                <div>
                  { status === 'inactive' && <div css={status_to_migrate}><Trans>Waiting for joomla migration to complete</Trans></div> }
                  { status === 'to_migrate' && <div css={status_to_migrate}><Trans>In queue to migrate</Trans></div> }
                  { status === 'migration_complete' && <div css={status_migration_complete}><Trans>Migrated</Trans></div> }
                  { status === 'migration_failed' && <div css={status_migration_failed}><Trans>Failed, will try again shortly</Trans></div> }
                  { !status && <div><Trans>Waiting for joomla migration to complete.</Trans></div> }
                </div>
              </LabelValue>

              { status &&
                <div>
                  <LabelValue>
                    <Trans>Subscriptions last migrated at</Trans>
                    <Timestamp value={get(customer_migration, "customer_subscriptions_migrated_at")} />
                  </LabelValue>

                  { error && 
                    <LabelValue>
                      <Trans>Subscriptions migration last error</Trans>
                      <Error>
                        {error}
                      </Error>
                    </LabelValue>
                  }
                </div>
              }
              
            </div>
        )
    }

    renderCustomerMigrationLogs() {
        const { customer_migration_logs, customer_migration_logs_is_loading } = this.props

        const headers = {
            created_at: { name: 'Created at' },
            note: { name: 'Note' }
        }
        
        return (
            <CommonTable
                is_loading={ customer_migration_logs_is_loading }
                empty_message={`There are no migration logs.`}
                headers={ headers }
                items={ customer_migration_logs }
                item_list={adminCustomerMigrationLogList}
                getCellValue={ this.getCustomerMigrationLogCellValue }
              />
        )
    }

    renderSyncStatus() {
        const { customer_migration, customer_id } = this.props
        return (
            <div>
              <LabelValue>
                <Trans>Re-synced at</Trans>
                <Timestamp value={get(customer_migration, "resynced_at")} />
                <FieldInfoText>
                  Re-synching happens automatically a day after migration, to ensure the latest customer data is synced.
                </FieldInfoText>
              </LabelValue>

              <LabelValue>
                <Trans>Second re-sync at</Trans>
                <Timestamp value={get(customer_migration, "second_resynced_at")} />
                <FieldInfoText>
                  Second re-synching happens automatically two days after migration, to ensure the latest customer data is synced.
                </FieldInfoText>
              </LabelValue>
              
            </div>
        )
    }

    renderGeneralStatus() {
        const { customer_migration, customer_id, when_to_send_welcome_email_options_by_id } = this.props
        return (
            <div>
              <LabelValue>
                <Trans>Migration last modified at</Trans>
                <Timestamp value={get(customer_migration, "modified_at")} />
              </LabelValue>

              <LabelValue>
                <Trans>When to send welcome email</Trans>
                { get(when_to_send_welcome_email_options_by_id, [get(customer_migration, 'when_to_send_welcome_email'), "name"]) }
              </LabelValue>
              
              <LabelValue>
                <Trans>Welcome email sent at</Trans>
                { get(customer_migration, 'welcome_email_sent_at') && 
                  <Timestamp value={get(customer_migration, "welcome_email_sent_at")} />
                }
                { !get(customer_migration, 'welcome_email_sent_at') && 
                  <span>No welcome email sent</span>
                }
              </LabelValue>
            </div>
        )
    }

    renderNonLegacyCustomer() {
        const { customer_migration, customer_id, joomla_status, is_loading } = this.props
        return (
            
            <div>
              <Row>
                <Col md={LEFT_WIDTH}>
                  This customer is not a legacy customer, migration does not apply.
                </Col>
              </Row>
              <Row>
                <AdminCustomerMigrationSubscriptions customer_id={customer_id} />
              </Row>

              { this.renderCommon() }
            </div>
        )
    }
    
    renderLegacyCustomer() {
        const { customer_migration, customer_id, joomla_status, is_loading } = this.props
        return (
            
            <div>
              <Row>
                <Col md={LEFT_WIDTH}>

                  <Card variant="white_wide_padding" title="Overall migration status">
                    <Separator variant="h10" />
                    { this.renderGeneralStatus() }
                  </Card>
                  <Card variant="white_wide_padding"title="Sync status">
                    <Separator variant="h10" />
                    { this.renderSyncStatus() }
                  </Card>
                  <Card variant="white_wide_padding"title="Joomla status">
                    <Separator variant="h10" />
                    { this.renderJoomlaStatus() }
                  </Card>
                  <Card variant="white_wide_padding"title="Subscriptions status">
                    <Separator variant="h10" />
                    { this.renderSubscriptionsStatus() }
                  </Card>

                  <Separator variant="h30" with_top_border={true} />
                  <AdminCustomerMigrationSubscriptions customer_id={customer_id} />

                </Col>
                <Col>
                  <Card variant="white_wide_padding" title="Actions">
                    <Separator variant="h10" />
                    { ! is_loading && this.renderMigrateForm()}
                  </Card>
                </Col>

              </Row>

              { this.renderCommon() }
              
              <Separator variant="h30" />
            </div>
        )
    }

    renderCommon() {
        return (
            <Row>
              <Col md="12">
                <Card variant="white_wide_padding"title="Data migration logs">
                  <Separator variant="h10" />
                  { this.renderCustomerMigrationLogs() }
                </Card>
              </Col>
            </Row>
        )
    }


    render() {
        const { customer } = this.props
        if ( get(customer, "is_legacy") ) {
            return this.renderLegacyCustomer()
        } else {
            return this.renderNonLegacyCustomer()
        }
    }
    
}

function mapStateToProps(state, props) {
    const { customer_id } = props

    const customer = customer_id && adminCustomerList.getObject(customer_id)
    const customer_migration = head(adminCustomerMigrationList.getVisibleObjects())
    const customer_migration_logs = adminCustomerMigrationLogList.getVisibleObjects()
    const joomla_status = get(customer_migration, "joomla_migration_status")

    return {
        customer,
        customer_id,
        customer_migration,
        customer_migration_logs,
        customer_migration_log_filter: adminCustomerMigrationLogList.getFilter(),
        joomla_status,
        customer_migration_logs_is_loading: adminCustomerMigrationLogList.isLoading(),
        when_to_send_welcome_email_options: adminWhenToSendWelcomeEmailOptions.getAsOptions(),
        when_to_send_welcome_email_options_by_id: adminWhenToSendWelcomeEmailOptions.getObjectsById(),
        is_loading: adminCustomerMigrationList.isLoading()
    }
}


export default withRouter(connect(mapStateToProps)(AdminCustomerMigration))

const status_to_migrate = css`
color: ${theme.colors.black};
`
const status_migration_in_progress = css`
color: ${theme.colors.logo_3};
`
const status_partial_migration = css`
color: ${theme.colors.green};
`
const status_migration_complete = css`
color: ${theme.colors.green};
`
const status_migration_failed = css`
color: ${theme.colors.error};
`

const customer_migration_log_note_style = css`
font-size: 10px;
`
