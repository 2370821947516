import { ItemList } from '../../orm'
import { download } from '../../actions/lib'

class AdminInvoiceList extends ItemList {
    getEntityKey() {
        return "admin/invoice"
    }

    download(invoice_id) {
        const url = `invoice/${invoice_id}/download/`
        return download(url)
    }

    downloadAsTwinfield(filter) {
        const url = `admin/invoice/download_twinfield/` 
        const params = null 
        const post_params = filter
        return download(url, params, post_params)
    }

}

export const adminInvoiceList = new AdminInvoiceList("admin_invoice__default")
