/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef } from 'react'
import { jsx, css } from '@emotion/react'
import { useField } from 'formik'
import moment from 'moment'
import { get, size, head } from 'lodash'
import { FormikFileUploadField } from './FileUploader'
import {showSuccess, showError} from '../../actions/Error'
import { Duration } from '../Duration'
import { Trans, Translation } from 'react-i18next'
import { default_theme as theme } from '../../emotion/theme'
import { Separator } from '../layout/Separator'
import FormError from './FormError'
import { BlueButton } from '../layout/BlueButton'
import { GrayButton } from '../layout/GrayButton'
import { ButtonBar } from '../layout/ButtonBar'
import { BlueLinkButton } from '../layout/BlueLinkButton'
import FormWarning from './FormWarning'
import NestedWrappingBusyMask from '../NestedWrappingBusyMask'
import Form from 'react-bootstrap/Form'
import loading_icon from '../../images/loading.gif'
import { fileInfoList } from '../../actions/file_info'
import ReactAudioPlayer from 'react-audio-player'

export const SUPPORTED_AUDIO_FILE_FORMATS = ".mp3, .wav, .aag, .m4a"

export const FormikAudioUploadField = ({ onUploaded, ...props }) => {

    const onFileUploaded = (file_info) => {
        onUploaded(file_info)
    }

    return (
        <>
          <FormikFileUploadField css_classes={ {container: file_uploader_container_style,
                                                dropzone_container: dropzone_container_style} }
                                 formik_props={null}
                                 file_types={SUPPORTED_AUDIO_FILE_FORMATS}
                                 upload_relative_url="file_info/"
                                 onFileUploaded={onFileUploaded}
                                 renderPlaceHolder={() => <Trans>Drop or click to select an audio file to upload</Trans>}
          />
        </>
    )

}

const file_uploader_container_style = css`
width: 100%;
height: 50px;
border: dotted 1px ${theme.colors.light_middle_grey};
background-color: ${theme.colors.light_grey};
border-radius: 4px;
padding: 10px;
display: flex;
align-items: center;
text-align: center;
`

const dropzone_container_style = css`
width: 100%;
`

const name_row_style = css`
display: flex;
`
