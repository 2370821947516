/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx } from '@emotion/react'
import { head, get } from 'lodash'
import { adminUserList } from '../actions/admin_user'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFilter from './AdminTableFilter'
import { AdminQuickLink } from './AdminQuickLink'
import { Link } from 'react-router-dom'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import { adminVoxboneIncomingFaxList } from '../actions/admin_voxbone_incoming_faxes'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { Button, Container } from 'react-bootstrap'

class AdminVoxboneIncomingFaxList extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminVoxboneIncomingFaxList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(adminVoxboneIncomingFaxList.fetchListIfNeeded())
    }

    getCellValue = (header_key, item, index) => {
        const { customer_id } = this.props
        switch( header_key ) {
            case 'created_at':
                return <Timestamp value={item.created_at} format="datetime" />
            case 'status':
                return <AdminQuickLink name="fax_item" value={item.fax_item} label={item.status} />
            case 'to_number':
                return <AdminQuickLink name="customer" value={item.to_customer} label={item.to_number} />
            default:
                return undefined
        }
    }

    render() {
        const { is_loading, faxes, headers } = this.props
        return (
            <AdminMainLayout title=''
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'voxbone_incoming_fax_list', label: 'Incoming voxbone faxes', url: '/admin/voxbone_incoming_faxes'}]}>
              <Container fluid>

                <AdminTableHeader title="Incoming faxes from voxbone">
                  <AdminTableFilter item_list={adminVoxboneIncomingFaxList} />
                </AdminTableHeader>
                
                <CommonTable
                    is_loading={ is_loading }
                    empty_message={`No faxes`}
                    headers={ headers }
                    items={ faxes }
                    item_list={adminVoxboneIncomingFaxList}
                    getCellValue={ this.getCellValue }
                />
              </Container>
            </AdminMainLayout>
            
        )
    }
    
}

function mapStateToProps(state, props) {
    const faxes = adminVoxboneIncomingFaxList.getVisibleObjects()
    return {
        is_loading: adminVoxboneIncomingFaxList.isLoading(),
        faxes,
        headers: {
            created_at: { name: 'Created at' },
            voxbone_ref: { name: 'Voxbone ref' },
            from_number: { name: 'From' },
            to_number: { name: 'To' },
            status: { name: 'Status' },
            last_error: { name: 'Error' }
        }
    }
}

export default connect(mapStateToProps)(AdminVoxboneIncomingFaxList)
