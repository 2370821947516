/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import AdminMainLayout from './AdminMainLayout'
import AdminFaxTraffic from './AdminFaxTraffic'
import { Container } from 'react-bootstrap'
import FaxNumbersCard from '../../components/FaxNumbersCard'
import { adminCustomerPhoneNumberList } from '../actions/admin_customer_phone_number'

class AdminFaxNumbersCard extends Component {

    componentDidMount() {
        const { dispatch, customer_id } = this.props
        if ( customer_id ) {
            dispatch(adminCustomerPhoneNumberList.updateListFilter({customer: customer_id,
                                                                    is_active: true,
                                                                    can_receive_faxes: true}))
        } else {
            dispatch(adminCustomerPhoneNumberList.updateListFilter({customer: null,
                                                                    can_receive_faxes: true}))
        }
        dispatch(adminCustomerPhoneNumberList.fetchListIfNeeded())
    }
    
    componentDidUpdate() {
        const { dispatch, customer_id, admin_fax_item_list_filter } = this.props
        if ( admin_fax_item_list_filter.customer !== customer_id  ) {
            dispatch(adminCustomerPhoneNumberList.updateListFilter({customer: customer_id || null,
                                                                    is_active: true,
                                                                    can_receive_faxes: true}))
        }
        if ( customer_id ) {
            dispatch(adminCustomerPhoneNumberList.ensureObjectLoaded(customer_id))
        }
        dispatch(adminCustomerPhoneNumberList.fetchListIfNeeded())
    }
    
    render() {
        const { customer_id, is_ready } = this.props
        return (
            <>
              { is_ready && <FaxNumbersCard item_list={adminCustomerPhoneNumberList} can_add={false} /> }
            </>
        )
    }
    
}

function mapStateToProps(state, props) {
    const { customer_id } = props
    const items = adminCustomerPhoneNumberList.getVisibleObjects()
    return {
        customer_id,
        items,
        admin_fax_item_list_filter: adminCustomerPhoneNumberList.getFilter(),
        is_ready: adminCustomerPhoneNumberList.isReady()
    }
}

export default connect(mapStateToProps)(AdminFaxNumbersCard)
