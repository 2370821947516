/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { map, has, get } from 'lodash'
import { ButtonBar } from '../../components/layout/ButtonBar'
import { WhiteButton } from '../../components/layout/WhiteButton'
import AdminMainLayout from './AdminMainLayout'
import CommonTable from '../../components/CommonTable'
import { PrettyValue } from '../../components/PrettyValue'
import CurrencyValue from '../../components/CurrencyValue'
import Pagination from '../../components/Pagination'
import Timestamp from '../../components/Timestamp'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { FormikInputField } from '../../components/form/InputField'
import { countryList } from '../../actions/country'
import { cityList } from '../../actions/city'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFooter from './AdminTableFooter'
import AdminTableFilter from './AdminTableFilter'
import CardInfoText from '../../components/layout/CardInfoText'
import { Table, Row, Col } from 'react-bootstrap'
import { Container, Button } from 'react-bootstrap'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { InlineIconBar } from '../../components/layout/InlineIconBar'
import { adminPhoneNumberPrefixList } from '../actions/admin_phone_number_prefix'
import { adminNumberTypes, adminPhoneNumberPrefixAllocationOptions } from '../actions/admin_dropdown_options'
import { Separator } from '../../components/layout/Separator'

class AdminPhoneNumberPrefixes extends Component {
 
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminPhoneNumberPrefixList.fetchListIfNeeded())
        
        dispatch(cityList.updatePaginationNumItemsPerPage(1000))
        dispatch(cityList.fetchListIfNeeded())
        dispatch(countryList.updatePaginationNumItemsPerPage(1000))
        dispatch(countryList.fetchListIfNeeded())
        
        dispatch(adminNumberTypes.fetchListIfNeeded())
        dispatch(adminPhoneNumberPrefixAllocationOptions.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch, filter } = this.props
        dispatch(adminPhoneNumberPrefixList.fetchListIfNeeded())
        dispatch(countryList.fetchListIfNeeded())
        dispatch(cityList.fetchListIfNeeded())
    }

    onEdit = (evt, item) => {
        const { history } = this.props
        history.push('/admin/number_prefix/' + item.id)
    }

    onAdd = () => {
        const { history } = this.props
        history.push('/admin/number_prefix/')
    }

    onFilter = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminPhoneNumberPrefixList.updateListFilter(filter_values))
    }

    onCountryChanged = (country_id) => {
        const { dispatch } = this.props
        dispatch(cityList.filterOnCountry(country_id))
    }

    getCellValue = (header_key, item, index) => {
        const { number_types_by_value, allocation_options_by_value } = this.props
        switch( header_key ) {
            case 'since':
                return <Timestamp value={item.since} format='date' />
            case 'last_active':
                return <Timestamp value={item.last_active} format='datetime' />
            case 'total':
                return <PrettyValue value={get(item, ['num_phone_numbers_by_status', 'total'])} />
            case 'available':
                return <PrettyValue value={get(item, ['num_phone_numbers_by_status', 'available'])} />
            case 'active':
                if ( item.active ) {
                    return "active" 
                } else {
                    return "disabled" 
                }
            case 'number_type':
                if ( item.number_type === 'geographic' ) {
                    return item.city_name
                } else {
                    return get(number_types_by_value, [item.number_type, "name"])
                }
            case 'allocation_method':
                return get(allocation_options_by_value, [item.allocation_method, "name"])
            case 'action':
                return (
                    <InlineIconBar>
                      <InlineIcon icon_name="eye" onClick={(evt) => this.onEdit(evt, item)} />
                    </InlineIconBar>
                )
            default:
                return undefined
        }
    }

    renderFilter = (formik_props) => {
        const { country_options, city_options, number_type_options } = this.props
        return (
            <Col md="12">
              <div css={filter_field_row_style}>
                <FormikDropdownField name="number_type"
                                     formik_props={formik_props}
                                     options={number_type_options}
                                     empty_selection_label="Any number prefix"
                                     placeholder="Number&nbsp;type" />
                <Separator variant="w5" />
                
                <FormikDropdownField name="country"
                                     formik_props={formik_props}
                                     options={country_options}
                                     on_change={this.onCountryChanged}
                                     empty_selection_label="Any country"
                                     placeholder="Country" />
                <Separator variant="w5" />
                <div css={city_dropdown_style}>
                  <FormikDropdownField name="city"
                                       formik_props={formik_props}
                                       options={city_options}
                                       empty_selection_label="Any city"
                                       placeholder="City" />
                </div>

                <Separator variant="w10" />
                
                <FormikInputField name="any_field"
                                  formik_props={formik_props}
                                  show_search_icon={true}
                                  placeholder="Prefix, country, city" />


                <Separator variant="w20" />
                <WhiteButton onClick={ this.onAdd }>
                  Add Number Prefix
                </WhiteButton>

              </div>
            </Col>
        )
    }

    render() {
        const {
            prefix_headers,
            prefixes,
            is_loading,
            prefix_filter
        } = this.props
        
        return (
            <AdminMainLayout active_key="numbers"
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'number_prefixes', label: 'Number prefixes', url: '/admin/number_prefixes'}]}>
              <AdminTableHeader title="Number prefixes">
                <div css={ css`width:100%;`}>
                  <AdminTableFilter updateOnChange={ this.onFilter }
                                    is_loading={ is_loading }
                                    filter={prefix_filter}
                                    renderFilter={ this.renderFilter } />
                </div>
                
              </AdminTableHeader>
              <Container fluid>
                <Row>
                  <Col md="6">
                    <CardInfoText>
                      Manage the number prefixes that are available for sale.
                      This can be any number prefix you want to offer in a product,
                      whether XOIP has these numbers in stock or not.
                    </CardInfoText>
                  </Col>
                </Row>
                <Separator variant="h10" />
                <CommonTable
                  is_loading={ is_loading }
                  empty_message="There are no phone number prefixes."
                  headers={ prefix_headers }
                  items={ prefixes }
                  item_list={adminPhoneNumberPrefixList}
                  getCellValue={ this.getCellValue }
                  sortOnHeader={ this.sortOnHeader }
                />
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const prefixes = adminPhoneNumberPrefixList.getVisibleObjects() || []

    const country_options = countryList.getAsOptionsWithPriority()
    const city_options = cityList.getAsOptions({show_prefix_in_label:true})
    const number_type_options = adminNumberTypes.getAsOptions()
    const allocation_options = adminPhoneNumberPrefixAllocationOptions.getAsOptions()

    return {
        prefixes,
        prefix_filter: adminPhoneNumberPrefixList.getFilter(),
        prefix_headers: {
            prefix: { name: "Prefix" },
            country_name: { name: "Country" },
            number_type: { name: 'Type' },
            total: { name: 'Total' },
            available: { name: 'Available' },
            active: { name: 'Status' },
            allocation_method: { name: 'Allocation method' },
            action: { name: '' }
        },
        number_type_options,
        number_types_by_value: adminNumberTypes.getObjectsById(),
        allocation_options,
        allocation_options_by_value: adminPhoneNumberPrefixAllocationOptions.getObjectsById(),
        country_options,
        city_options,
        is_loading: adminPhoneNumberPrefixList.isLoading() || adminNumberTypes.isLoading() || cityList.isLoading() || countryList.isLoading()
    }
}
export default withRouter(connect(mapStateToProps)(AdminPhoneNumberPrefixes))

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const filter_field_row_style = css`
display: flex;
`

const city_dropdown_style = css`
min-width: 200px;
`
