/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../emotion/theme'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { map, get, size, forEach } from 'lodash'
import {showSuccess, showError} from '../actions/Error'
import { handleSubmitResult } from '../actions/form'
import { Formik, Form } from 'formik'
import { Trans, Translation } from 'react-i18next'
import { BlueButton } from './layout/BlueButton'
import { Separator } from './layout/Separator'
import { customerSubscriptionManualReservationList } from '../actions/customer_subscription_manual_reservation'
import { FormikFileUploadField } from './form/FileUploader'
import { InlineIcon } from './layout/InlineIcon'
import { CustomerNotificationCard, CustomerNotificationCardHeader } from './layout/CustomerNotificationCard'
import { PhoneNumber } from './PhoneNumber'
import CardInfoText from './layout/CardInfoText'
import { globalSettingsList } from '../actions/settings'
import { FormikGeneralFormErrors } from './form/GeneralFormErrors'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
})


export const PortingNumberNotification = ({...props}) => {

    const dispatch = useDispatch()
    const reservations = customerSubscriptionManualReservationList.getVisibleObjects()
    const helpdesk_email = useSelector(() => globalSettingsList.getSetting("helpdesk_email"))
    const initial_values = {}
    
    useEffect( () => {
        async function fetchStaticObjects() {
            dispatch(globalSettingsList.ensureGlobalSettingsLoaded())
            dispatch(customerSubscriptionManualReservationList.updateListFilter({porting_pending_invoice_upload: true}))
            dispatch(customerSubscriptionManualReservationList.fetchListIfNeeded())
        }
        fetchStaticObjects()
    }, [])

    if ( size(reservations) === 0 ) {
        return null
    }

    const onSave = (values, formik_funcs, reservation) => {

        const on_ok = function(json) {
            dispatch(customerSubscriptionManualReservationList.invalidateList())
            dispatch(customerSubscriptionManualReservationList.fetchListIfNeeded())
            showSuccess(<Trans>Saved</Trans>)
        }
        values.id = reservation.id
        return dispatch(customerSubscriptionManualReservationList.saveObject(values))
            .then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }

    const onInvoiceFileUploaded = (formik_props, file_info) => {
        formik_props.setFieldValue("porting_invoice_file_info", file_info.id)
        formik_props.setFieldValue("porting_invoice_file_info_details", file_info)
    }

    const onRemoveInvoiceFile = (formik_props) => {
        formik_props.setFieldValue("porting_invoice_file_info", undefined)
        formik_props.setFieldValue("porting_invoice_file_info_details", undefined)
    }
    
    const renderInvoiceFilePlaceholder = () => {
        return (
            <div css={invoice_file_placeholder_style}>
              <Trans>Upload invoice</Trans>
            </div>
        )
    }

    const renderUploadForm = (reservation) => {

        return (
            <Formik initialValues={initial_values}
                    onSubmit={(values, formik_funcs) => onSave(values, formik_funcs, reservation)}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
            >
              {formik_props => {

                  const invoice_file = get(formik_props, ["values", "porting_invoice_file_info_details"], reservation.porting_invoice_file_info_details)
                  return (
                      <Form>
                        <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />

                        { get(invoice_file, "visible_filename") &&
                          <div css={invoice_filename_style}>
                            <InlineIcon icon_name="status-success" />
                            {invoice_file.visible_filename}
                            <Separator variant="w15" />
                            <div css={remove_invoice_file_style} onClick={() => onRemoveInvoiceFile(formik_props)}>
                              <InlineIcon icon_name="remove" />
                            </div>
                          </div>
                        }

                        { ! invoice_file &&
                          <>
                            <Separator variant="h5" />
                            <FormikFileUploadField name="porting_invoice_file_info"
                                                   css_classes={ {container: invoice_file_uploader_container_style,
                                                                  dropzone_container: dropzone_container_style} }
                                                   upload_relative_url="file_info/"
                                                   formik_props={formik_props}
                                                   onFileUploaded={(file_info) => onInvoiceFileUploaded(formik_props, file_info)}
                                                   renderPlaceHolder={renderInvoiceFilePlaceholder}
                            />
                          </>
                        }

                        { invoice_file &&
                          <BlueButton type="submit">
                            Submit
                          </BlueButton>
                        }
                        
                      </Form>
                  )
              }}
            </Formik>
        )
    }

    return (
        <>
          {map(reservations, (reservation) => (
              <>
                <CustomerNotificationCard is_active={true}>
                  <CustomerNotificationCardHeader>
                    <Trans>Upload the invoice of your telecom provider</Trans>

                    <CardInfoText>
                      <Trans i18nKey="porting_number_notification__you_have_requested_your_number"
                             values={{ number: reservation.phone_number,
                                       helpdesk_email: helpdesk_email}}
                             components={[<PhoneNumber value={reservation.number} />,
                                          <a css={helpdesk_menu__link} href={`mailto:${helpdesk_email}`}>{helpdesk_email}</a>]}
                             defaults={"You have requested your number <0></0> to be transferred to XOIP. In order to do that we need a copy of the latest invoice from your current telecom provider." +
                                       "Please upload the invoice below, or email it to <1>{{helpdesk_email}}</1>, with your phone number in the subject."}
                      />
                    </CardInfoText>

                    <Separator variant="h20" />

                    <Trans>Upload the latest invoice from your current telecom provider</Trans>

                    { renderUploadForm(reservation) }
                    
                  </CustomerNotificationCardHeader>
                </CustomerNotificationCard>
                <Separator variant="h30" />
              </>
          ))}
        </>
        
    )
    
}

const helpdesk_menu__link = css`
color: ${theme.colors.primary} !important;
`

const remove_invoice_file_style = css`
cursor: pointer;
margin-top: 5px;
`

const invoice_filename_style = css`
display: flex;
align-items: center;
margin-top: 10px;
margin-bottom: 20px;
`
        
const invoice_file_placeholder_style = css`
`
        
const dropzone_container_style = css`
width: 100%;
`
    
const invoice_file_uploader_container_style = css`
width: 180px;
height: 38px;
border: solid 1px ${theme.colors.light_middle_grey};
background-color: ${theme.colors.white};
border-radius: 4px;
padding: 1px 14.5px;
display: flex;
align-items: center;
text-align: center;
`
