/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { map, size } from 'lodash'
import { withRouter } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { default_theme as theme } from '../../emotion/theme'

class AdminTableHeader extends Component {

    render() {
        const { title, children, custom_height, custom_background, variant } = this.props

        const content = (
            <Row>
              <Col className="col-auto mr-auto">
                <h1>{title}</h1>
              </Col>
              <Col className="col-auto">
                <Row>
                  { children && !children.length && <div>{children}</div> }
                  { children && children.length > 1 && map(children, (child, index) => <div css={cell_style} key={index} >{child}</div>)}
                </Row>
              </Col>
            </Row>
        )
        
        if ( variant === "no_container" ) {
            return content
        }
        
        return (
            <Container fluid>
              {content}
            </Container>
        )
    }
}

function mapStateToProps(state, props) {
    const { children } = props
    return {
        children
    }
}
export default withRouter(connect(mapStateToProps)(AdminTableHeader))

const cell_style = css`
margin-left: 15px;
`
