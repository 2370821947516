/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { sortBy, map, get, filter, includes } from 'lodash'
import moment from 'moment'
import { Modal } from 'react-bootstrap'
import { InlineIcon } from './layout/InlineIcon'
import { confirmModal } from '../actions/ui'
import { Trans, Translation } from 'react-i18next'
import { FormikDropdownField, SemanticDropdownStandalone } from './form/Dropdown'
import { BlueButton } from './layout/BlueButton'
import { default_theme as theme } from '../emotion/theme'
import {showSuccess, showError} from '../actions/Error'
import { handleSubmitResult } from '../actions/form'
import { answeringMessageTypeChoices } from '../actions/dropdown_options'
import { LANGUAGES } from '../actions/language'
import { Separator } from './layout/Separator'
import { FormikAudioRecordingField } from './form/AudioRecordingField'
import { FormikAudioUploadField } from './form/AudioUploadField'
import { BlueLinkButton } from './layout/BlueLinkButton'
import ReactAudioPlayer from 'react-audio-player'
import radio_selected from '../images/radio_selected.svg'
import radio_unselected from '../images/radio_unselected.svg'
import { customerPhoneNumberList } from '../actions/customer_phone_number'
import { answeringMessageList } from '../actions/answering_message'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faMicrophoneAlt } from '@fortawesome/free-solid-svg-icons'

export const VoiceNumberGreetingSelector = ({ formik_props, field_name, customer_phone_number, onSelected, overrideAnsweringMessageList, ...props }) => {

    const dispatch = useDispatch()
    const answeringMessageItemList = overrideAnsweringMessageList || answeringMessageList
    const activeAnsweringMessage = get(formik_props.values, field_name)
    const answeringMessages = useSelector(() => sortBy(answeringMessageItemList.getVisibleObjects(), (am) => answeringMessageItemList.isStandardGreeting(am) ? 0 : 1 ))
    const [isUploading, setIsUploading] = useState(false)
    const [isRecording, setIsRecording] = useState(false)

    useEffect(() => {
        dispatch(answeringMessageItemList.updatePaginationNumItemsPerPage(100))
        dispatch(answeringMessageItemList.updateListFilter({customer: customer_phone_number.customer}))
        dispatch(answeringMessageItemList.fetchListIfNeeded())
    }, [])

    const onSelectAnsweringMessage = (answering_message) => {
        formik_props.setFieldValue(field_name, answering_message)
        onSelected()
    }

    const onUploaded = (file_info) => {
        onUploadedCommon({file_info, answering_message_type: 'uploaded_greeting', name: file_info.visible_filename})
        setIsUploading(false)
    }

    const onRecorded = ({fileInfo, name}) => {
        onUploadedCommon({file_info: fileInfo, answering_message_type: 'recorded_greeting', name: name})
        setIsRecording(false)
    }

    const onCancelRecordAndUploading = () => {
        setIsRecording(false)
        setIsUploading(false)
    }

    const onDeleteAnsweringMessage = (answering_message) => {

        const onConfirmed = () => {
            dispatch(answeringMessageItemList.deleteObject(answering_message.id)).then(() => {
                dispatch(answeringMessageItemList.invalidateList())
                dispatch(answeringMessageItemList.fetchListIfNeeded())
                dispatch(customerPhoneNumberList.invalidateObject(customer_phone_number.id))
                dispatch(customerPhoneNumberList.ensureObjectLoaded(customer_phone_number.id))

                if ( activeAnsweringMessage.id === answering_message.id ) {
                    formik_props.setFieldValue(field_name, null)
                }
                
            })
        }
        
        const text = (<Trans>Are you sure you want to delete this answering message?</Trans>)
        dispatch(confirmModal({text:text,
                               onConfirmed: onConfirmed}))
    }

    const onUploadedCommon = ({file_info, answering_message_type, name}) => {
        const on_ok = (json) => {
            showSuccess("Saved")
            dispatch(answeringMessageItemList.invalidateList())
            dispatch(answeringMessageItemList.fetchListIfNeeded())
            formik_props.setFieldValue(field_name, json)
        }
        
        const values = {answering_message_type,
                        customer: customer_phone_number.customer,
                        answering_message_file_info: file_info.id,
                        name: name || moment().toDate()}
        return dispatch(answeringMessageItemList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_props, on_ok}))
    }
    
    const renderAudioRecorder = () => {
        return (
            <>
              <Separator variant="h10" />

              <BlueButton onClick={() => setIsRecording(true)} extra_css={start_uploading_file_style} auto_disable={false}>
                <FontAwesomeIcon icon={faMicrophoneAlt} />
                <Separator variant="w10" />
                <Trans>Record new greeting</Trans>
              </BlueButton>

              {isRecording &&
               <FormikAudioRecordingField onRecorded={onRecorded}
                                          max_duration_seconds={30}
                                          onCancel={onCancelRecordAndUploading}
                                          formik_props={formik_props}
               />
              }
            </>
        )
    }

    const renderAudioUploader = () => {

        return (
            <>
              <Separator variant="h10" />

              <BlueButton onClick={() => setIsUploading(true)} extra_css={start_uploading_file_style} auto_disable={false}>
                <FontAwesomeIcon icon={faUpload} />
                <Separator variant="w10" />
                <Trans>Upload an audio file</Trans>
              </BlueButton>

              { isUploading &&

                <Modal show={true}
                       size="lg"
                       onHide={onCancelRecordAndUploading}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <Trans>Upload an audio file</Trans>
                    </Modal.Title>
                  </Modal.Header>
                  
                  <Modal.Body>
                    
                    <FormikAudioUploadField onUploaded={onUploaded}
                                            formik_props={formik_props}
                    />
                  </Modal.Body>
                </Modal>
              
              }
              
            </>
        )    
    }

    const renderStandardGreeting = (file_info_details) => {
        return (
            <>
              <Separator variant="h10" />
              <ReactAudioPlayer src={file_info_details.original_download_url} controls />
            </>
        )
    }

    const renderAnsweringMessage = (answering_message) => {

        const is_selected = get(activeAnsweringMessage, "id") === answering_message.id

        return (
            <div key={`${answering_message.id}_${answering_message.name}`}
                 css={answering_message_row_style}
                 >
              
              <div css={answering_message_radio_style} onClick={() => onSelectAnsweringMessage(answering_message)}>
                <img src={is_selected ? radio_selected : radio_unselected} />
              </div>

              <Separator variant="w15" />

              <div css={answering_message_name_style} onClick={() => onSelectAnsweringMessage(answering_message)}>
                {answering_message.name}
              </div>
              
              { answering_message.answering_message_file_info_details &&
                <>
                  <ReactAudioPlayer src={answering_message.answering_message_file_info_details.original_download_url} controls />
                </>
              }

              { ! answeringMessageItemList.isStandardGreeting(answering_message) && 
                <>
                  <Separator variant="w20" />

                  <div css={answering_message_delete_style}>
                    <InlineIcon icon_name="delete" variant="red" onClick={() => onDeleteAnsweringMessage(answering_message)} />
                  </div>
                </>
              }
              
            </div>
        )
    }
    
    return (
        (
            <div css={(isUploading || isRecording) ? container_disabled_style : null}>

              { ! includes(map(answeringMessages, (am) => am.answering_message_type), 'standard_greeting_en') &&
                renderAnsweringMessage(answeringMessageItemList.getStandardAnsweringMessageData({customer_phone_number, answering_message_type:'standard_greeting_en'}))
              }

              { ! includes(map(answeringMessages, (am) => am.answering_message_type), 'standard_greeting_nl') &&
                renderAnsweringMessage(answeringMessageItemList.getStandardAnsweringMessageData({customer_phone_number, answering_message_type:'standard_greeting_nl'}))
              }
              
              { map(answeringMessages, renderAnsweringMessage) }

              { renderAudioRecorder() }
              { renderAudioUploader() }
              
            </div>
        )
        
    )
    
}

const answering_message_type_style = css`
max-width: 300px;
`

const multivalue_col_style = css`
display: flex;
align-items: center;
width: 100%;
min-width:150px;
`

const answering_message_row_style = css`
display: flex;
align-items: center;
border-bottom: 1px solid ${theme.colors.very_light_grey};
padding-bottom: 5px;
margin-top: 5px;
flex-wrap: wrap;
`

const answering_message_radio_style = css`
cursor: pointer;
`

const start_uploading_file_style = css`
display: flex;
`

const answering_message_name_style = css`
min-width: 40%;
width: 40%;
`

const container_disabled_style = css`
opacity: 0.2;
`

const answering_message_delete_style = css`
`
