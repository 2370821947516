/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { jsx, css } from '@emotion/react'
import AdminMainLayout from './AdminMainLayout'
import AdminCommonCdrs from './AdminCommonCdrs'
import { Container, Button, Col, Row } from 'react-bootstrap'
import { adminCustomerCdrList } from '../actions/admin_cdr'

export const AdminCustomerCdrs = ({customer_id}) => {

    const dispatch = useDispatch()
    
    useEffect(() => {
        dispatch(adminCustomerCdrList.updateListFilter({customer:customer_id, any_field: null}))
        dispatch(adminCustomerCdrList.fetchListIfNeeded())
    }, [customer_id])
    
    return (
        <AdminCommonCdrs itemList={adminCustomerCdrList} />
    )
}
