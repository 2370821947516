/** @jsxImportSource @emotion/react */
import React, { Component, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { BaseButton } from './BaseButton'

export const WhiteButton = ({
  onClick,
  extra_css,
  size,
  children,
  ...props
}) => {
  return (
    <BaseButton
      variant="light"
      extra_css={[style, extra_css]}
      size={size}
      onClick={onClick}
      {...props}
    >
      {children}
    </BaseButton>
  )
}

const style = css`
  background-color: #fff;
  border: 1px solid #d1d1d6;
  cursor: pointer;
  font-size: 16px;
  color: #000;

  &:hover {
    background-color: #fff;
    border: 1px solid #d1d1d6;
  }
`
