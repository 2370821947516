/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../emotion/theme'
import yes_icon from '../images/boolean_yes.png'
import no_icon from '../images/boolean_no.png'
import unknown_icon from '../images/boolean_unknown.png'

class BooleanValue extends Component {

    render() {
        const { value } = this.props

        return (
            <div>
              { value === true && 
                <img src={ yes_icon } css={ icon } alt="Yes" />
              }
              { value === false && 
                <img src={ no_icon } css={ icon } alt="No" />
              }
              { value !== false && value !== true && 
                <img src={ unknown_icon } css={ icon } alt="Unknown" />
              }
            </div>
        )
    }
}
export default BooleanValue

const icon = css`
`
