/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
import { Trans, Translation } from 'react-i18next'
import i18n from '../i18n'
import { get, includes } from 'lodash'
import { isProd } from '../actions/ui'
import TokenSent from './TokenSent'
import WrappingBusyMask from './WrappingBusyMask'
import FaxByEmailVerification from './FaxByEmailVerification'
import LegacyRedirectLanding from './LegacyRedirectLanding'
import { NewSubscription } from './NewSubscription'
import { NewFaxSubscription } from './NewFaxSubscription'
import { NewVoiceSubscription } from './NewVoiceSubscription'
import { NoVoiceSubscriptions } from './NoVoiceSubscriptions'
import { NewAccountLanding } from './NewAccountLanding_Deprecated' // going away, keeping for a bit just to make sure, if reading after Oct 2021, delete
import PaySubscriptions from './PaySubscriptions'
import { SubscriptionUpgrade } from './SubscriptionUpgrade'
import { SubscriptionConfirmation } from './SubscriptionConfirmation'
import BuyCreditTopup from './BuyCreditTopup'
import Transactions from './Transactions'
import Invoices from './Invoices'
import MollieCreditPaymentComplete from './MollieCreditPaymentComplete'
import Login from './Login'
import { NewCustomerSignup } from './NewCustomerSignup'
import { auto_login, setPostAuthenticatedAction,
         getPostAuthenticatedAction, clearPostAuthenticatedAction,
         updateUserActiveLanguage } from '../actions/auth'
import AdminRouter from '../admin/components/AdminRouter'
import { ResellerRouter } from './ResellerRouter'
import CallHistory from './CallHistory'
import { VoiceNumbers } from './VoiceNumbers'
import { VoiceNumberCallPlanPage } from './VoiceNumberCallPlanPage'
import Subscriptions from './Subscriptions'
import Home from './Home'
import Profile from './Profile'
import ProfileEdit from './ProfileEdit'
import { Button, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { isLoggedIn, loggedInUser } from '../actions/auth'
import { globalSettingsList } from '../actions/settings'
import NonProdHeading from './NonProdHeading'
import SendAFaxDashboard from './SendAFaxDashboard'
import FaxSendingRates from './FaxSendingRates'

class AuthRouter extends Component {

    constructor(props) {
        super(props)
        this.state = {auto_logging_in: false,
                      auto_login_error_message: null}
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(globalSettingsList.ensureGlobalSettingsLoaded())
        if ( ! this.check_for_language() ) {
            this.check_for_auto_login()
            this.checkForPostAuthenticatedAction()
        }
    }

    componentDidUpdate(prevProps) {
        const { user, dispatch, is_logged_in, history, is_superuser, post_authenticated_action } = this.props

        if ( ! this.check_for_language() ) {
            this.check_for_auto_login()
            this.checkForPostAuthenticatedAction()
        }
        
        if (!prevProps.is_logged_in && is_logged_in) {
            if (is_superuser) {
                history.push('/admin/dashboard')
            } else {
                if ( post_authenticated_action.action === "go" ) {
                    history.push(post_authenticated_action.value)
                } else {
                    history.push('/')
                }
            }
        } else if (prevProps.is_logged_in && !is_logged_in) {
            history.push('/')
        } else if ( is_superuser && window.location.pathname === "/" ) {
            history.push('/admin/dashboard')
        }
    }
    
    checkForPostAuthenticatedAction() {
        const { history } = this.props
        const params = new window.URLSearchParams(window.location.search);
        const action = params.has("action") && params.get("action")
        const action_value = params.has("value") && params.get("value")
        if ( action ) {
            setPostAuthenticatedAction(action, action_value)
            params.delete("action")
            params.delete("value")
            history.push(window.location.pathname + "?" + params.toString())
        }
    }

    check_for_language() {
        const { history, dispatch } = this.props 
        const params = new window.URLSearchParams(window.location.search);
        const language_code = params.has('language') && params.get('language')

        if ( ! language_code ) {
            return false
        }
        params.delete("language")
        dispatch(updateUserActiveLanguage(language_code))
        history.push(window.location.pathname + "?" + params.toString())
        return true
    }
    
    check_for_auto_login() {
        const { history, dispatch, is_logged_in } = this.props
        const { auto_logging_in } = this.state
        const that = this
        const params = new window.URLSearchParams(window.location.search);
        const user_id = params.has("u") && params.get("u")
        const otp = params.has("o") && params.get("o")
            
        const on_done = (res) => {
            that.setState({auto_logging_in: false})
            if ( res.errors ) {
                that.setState({auto_login_error_message: res.errors})
            }
            params.delete("u")
            params.delete("o")
            history.push(window.location.pathname + "?" + params.toString())
        }
        
        if ( user_id && otp && !auto_logging_in ) {
            this.setState({auto_logging_in: true})
            dispatch(auto_login(user_id, otp)).then(on_done)
        }
    }

    handlePostAuthenticatedAction({action, value}) {
        const { history } = this.props
        clearPostAuthenticatedAction()
        if ( action === "go" ) {
            history.push(value)
        }
    }

    goLogin = () => {
        const { post_authenticated_action } = this.props
        const { auto_logging_in, auto_login_error_message } = this.state
        if ( ! post_authenticated_action.action && window.location.pathname !== "/" ) {
            setPostAuthenticatedAction("go", window.location.pathname + window.location.search)
        }
        return <Login auto_login_error_message={auto_login_error_message} />
    }

    render() {
        const { is_logged_in, is_prod, user, post_authenticated_action } = this.props
        const { auto_logging_in } = this.state

        if ( ! is_logged_in ) {
            return (
                <WrappingBusyMask is_loading={auto_logging_in}>
                  <div>
                    { ! is_prod && <NonProdHeading /> }
                    <Switch>
                      <Route path="/token" exact={ true } component={ TokenSent } />
                      <Route path="/fax_by_email_verification/:token/:action" component={ FaxByEmailVerification } />
                      <Route path="/signup" component={ NewCustomerSignup } />
                      <Route path="/" render={this.goLogin} />
                    </Switch>
                  </div>
                </WrappingBusyMask>
            )
        }

        if ( post_authenticated_action.action ) {
            this.handlePostAuthenticatedAction(post_authenticated_action)
        }
        return (
            <div>
              { ! is_prod && <NonProdHeading /> }

              <Switch>
                <Route path="/legacy_redirect" exact={ true } component={ LegacyRedirectLanding } />
                <Route path="/send_a_fax" exact={ true } component={ SendAFaxDashboard } />
                <Route path="/send_a_fax/:provisional_outgoing_fax_id" exact={ true } component={ SendAFaxDashboard } />
                <Route path="/fax_by_email_verification/:token" component={ FaxByEmailVerification } />
                <Route path="/admin" exact={ false } component={ AdminRouter } />
                { false && <Route path="/signup/landing" exact={ true } component={ NewAccountLanding } /> }
                <Route path="/subscriptions" exact={ true } component={ Subscriptions } />
                <Route path="/subscription/signup/:product_short_id" exact={ true } component={ NewFaxSubscription } />
                <Route path="/subscription/signup/" exact={ true } component={ NewSubscription } />
                <Route path="/subscription/fax/signup/:product_short_id" exact={ true } component={ NewFaxSubscription } />
                <Route path="/subscription/fax" exact={ true } component={ NewFaxSubscription } />
                <Route path="/subscription/voice" exact={ true } component={ NewVoiceSubscription } />
                <Route path="/subscription/voice/signup/:product_short_id" exact={ true } component={ NewVoiceSubscription } />
                <Route path="/profile/edit" exact={ true } component={ ProfileEdit } />
                <Route path="/profile" exact={ true } component={ Profile } />
                <Route path="/home" component={ Home } />
                <Route path="/voice/:subscription_id/call_plan" component={ VoiceNumberCallPlanPage } />
                <Route path="/voice" component={ VoiceNumbers } />
                <Route path="/voice_subscription" component={ NoVoiceSubscriptions } />
                <Route path="/buy_credit_topup" component={ BuyCreditTopup } />
                <Route path="/transactions" component={ Transactions } />
                <Route path="/invoices" component={ Invoices } />
                <Route path="/mollie_credit_payment_complete/:payment_id" component={ MollieCreditPaymentComplete } />
                <Route path="/fax_sending_rates" component={ FaxSendingRates } />
                <Route path="/pay_subscriptions/" component={ PaySubscriptions } />
                <Route path="/subscription_upgrade/:subscription_id" component={ SubscriptionUpgrade } />
                <Route path="/subscription_upgrade_voice/:subscription_id" render={(props) => <SubscriptionUpgrade {...props} filter="voice" />} />
                <Route path="/subscription_upgrade_fax/:subscription_id" render={(props) => <SubscriptionUpgrade {...props} filter="fax" />} />
                <Route path="/subscription_upgrade/" component={ SubscriptionUpgrade } />
		        <Route path="/subscription_confirmation/:subscription_id" component={ SubscriptionConfirmation } />

                <Route path="/reseller/" component={ ResellerRouter } />
                
                <Route path="/" exact={true} component={ Home } />
                <Route path="/" component={ Home } />
              </Switch>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const is_logged_in = isLoggedIn()
    let is_superuser = false
    const user = loggedInUser()
    if (is_logged_in) {
        is_superuser = user && user.is_superuser
    }
    
    return {
        user,
        is_logged_in,
        is_superuser,
        is_prod: isProd(),
        post_authenticated_action: getPostAuthenticatedAction()
    }  
}
  
export default withRouter(connect(mapStateToProps)(AuthRouter))
