/** @jsxImportSource @emotion/react */
import React, { useRef, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useField } from 'formik'
import FormError from './FormError'
import FormWarning from './FormWarning'
import { default_theme as theme } from '../../emotion/theme'
import { FormikTextarea } from './TextareaField'

export const FormikHtmlTextareaField = ({...props}) => {

    return (
	<FormikTextarea {...props} />
    )
    
}
