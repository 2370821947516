/** @jsxImportSource @emotion/react */
import React, { Component, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { Field, useField, FieldArray } from 'formik'
import { FormikInputField } from './InputField'
import { get, map, values, size, includes, slice, startsWith } from 'lodash'
import { Button, Col, Row } from 'react-bootstrap'
import { Separator } from '../layout/Separator'
import { FormikPhoneNumberInputField } from './PhoneNumberInputField'
import { BlueLinkButton } from '../layout/BlueLinkButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans, Translation } from 'react-i18next'
import { InlineIcon } from '../layout/InlineIcon'
import { Error } from '../layout/Error'

export const FormikMultiplePhoneNumberField = ({ formik_props, onChange, placeholder, name, label, field, autoCompleteLookup, type,
                                                 addButtonLabel, checked, errors, renderAdditionalPhoneNumberInfo, ...props }) => {

    if ( size(formik_props.values[name]) === 0 ) {
        formik_props.setFieldValue(name, [ {'number': null}])
    } 
    
    const new_index = size(formik_props.values)

    const onAddPhoneNumber = (arrayHelpers) => {
        arrayHelpers.insert(size(get(formik_props, ["values", name])), {'number': "0031"})
    }

    const onRemovePhoneNumber = (index, arrayHelpers) => {
        arrayHelpers.remove(index)
        if ( onChange ) {
            onChange()
        }
    }

    return (
        
        <Translation>{ (t) => (
            <FieldArray
              name={name}
              render={arrayHelpers => (
                  <div>
                    {map(get(formik_props, ["values", name], []), function(phone_number, index) {

                        let entered_phone_number = get(phone_number, "number")
                        if ( startsWith(entered_phone_number, "+") ) {
                            entered_phone_number = entered_phone_number.slice(1)
                        }
                        if ( !startsWith(entered_phone_number, "00") ) {
                            entered_phone_number = "00" + entered_phone_number
                        }
                        
                        const is_invalid = includes(get(errors, "destination_phone_numbers"), entered_phone_number)

                        return ( 
                            <div key={index} css={number_row}>
                              <div>
                                <FormikPhoneNumberInputField key={`${name}.${index}.number`}
                                                             formik_props={formik_props}
                                                             name={`${name}.${index}.number`}
                                                             onChange={onChange}
                                                             autoCompleteLookup={autoCompleteLookup}
                                                             placeholder={placeholder || t("Phone number")}
                                />
                              </div>
                              { renderAdditionalPhoneNumberInfo && renderAdditionalPhoneNumberInfo(phone_number) }                                                                          
                              <div css={icon_container}>
                                <Separator variant="w10" />
                                <InlineIcon icon_name="remove" size_variant="h16" onClick={() => onRemovePhoneNumber(index, arrayHelpers)} />
                                
                              </div>
                              { is_invalid &&
                                <div>
                                  <Error>
                                    <Trans>Invalid phone number</Trans>
                                  </Error>
                                  
                                </div>
                              }
                            </div>
                        )
                    }
                        )}
                    <BlueLinkButton onClick={() => onAddPhoneNumber(arrayHelpers)}>
                      <InlineIcon icon_name="add_no_circle" variant="blue" />
                      <Trans>{addButtonLabel || "Add a fax number"}</Trans>
                    </BlueLinkButton>
                  </div>
              )}
            />
        )}</Translation>
    )
}

const button_icon = css`
cursor: pointer;
margin-right: 5px;
`

const number_row = css`
margin-bottom: 10px;
align-items: baseline;
display: flex;
`

const icon_container = css`
display: flex;
height: 100%;
`
