/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { Trans, Translation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {showSuccess, showError} from '../actions/Error'
import { jsx, css } from '@emotion/react'
import { head, get } from 'lodash'
import { Link } from 'react-router-dom'
import { CardHeader } from './layout/CardHeader'
import { Modal } from 'react-bootstrap'
import BusyMask from './BusyMask'
import CurrencyValue from './CurrencyValue'
import WrappingBusyMask from './WrappingBusyMask'
import { BlueButton } from './layout/BlueButton'
import Timestamp from './Timestamp'
import { customerListForReseller } from '../actions/customer'
import Card from './layout/Card'
import CommonTable from './CommonTable'
import { InlineIcon } from './layout/InlineIcon'
import { InlineIconBar } from './layout/InlineIconBar'
import MainLayout from './MainLayout'
import { Col, Row, Container, Tabs, Tab, Button } from 'react-bootstrap'
import { default_theme as theme } from '../emotion/theme'
import { Separator } from './layout/Separator'
import { handleSubmitResult } from '../actions/form'

export const ResellerCustomers = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const [goingToLoginAsCustomer, setGoingToLoginAsCustomer] = useState(null)

    const customers = customerListForReseller.getVisibleObjects()
    const is_ready = customerListForReseller.isReady()
    const is_loading = customerListForReseller.isLoading()
    const is_creating_login = customerListForReseller.getIsSavingObject()

    const headers = {
        display_name: { name: "Name" },
        email: { name: 'Email' },
        contact_phone_number: { name: 'Phone' },
        created_at: { name: 'Since' },
        credit: { name: 'Credit' },
        action: { name: '' },
    }
    
    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(customerListForReseller.updateListFilter({exclude_self: true}))
            dispatch(customerListForReseller.fetchListIfNeeded())
        }
        fetchStaticObjects()
    }, [] )   

    const onView = (customer) => {
        setGoingToLoginAsCustomer(customer)
    }

    const onLoginAsCustomer = () => {
        const on_ok = (json) => {
            if ( json.status !== 'success' ) {
                showError(<Trans>Failed to login</Trans>)
            } else {
                window.location = json.auto_login_url
            }
        }
        return dispatch(customerListForReseller.createResellerAutoLoginUrl({customer: goingToLoginAsCustomer})).then(on_ok)
    }

    const renderCustomerLoginModal = () => {
        return (
            <Modal show={true}
                   size="lg"
                   onHide={() => setGoingToLoginAsCustomer(null)}>

              <Modal.Header closeButton>
                <Modal.Title>
                  <Trans>Customer login</Trans>
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>

                <WrappingBusyMask is_loading={is_creating_login}>
                  
                  <Trans>Click login now to login as</Trans>
                  {get(goingToLoginAsCustomer, "display_name")}
                  <br/>
                  <Trans>You will have to logout and login again as yourself again to return to this page</Trans>
                  <Separator variant="h20" />
                  
                  <BlueButton onClick={onLoginAsCustomer}>
                    <Trans>Login</Trans>
                  </BlueButton>
                </WrappingBusyMask>
                
              </Modal.Body>
            </Modal>
        )
    }
    
    const getCustomerCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'created_at':
                return (
                    <Timestamp value={item.created_at} format='date' />
                )
            case 'credit':
                return <CurrencyValue value={item.credits_excluding_vat_euros} />
            case 'action':
                return (
                    <InlineIconBar>
                      <InlineIcon icon_name="eye" onClick={(evt) => onView(item)} />
                    </InlineIconBar>
                )
            default:
                return undefined
        }
    }
    
    const setTab = (tab_name) => {
        history.push(`/reseller/${tab_name}`)
    }
    
    return (
        <Container fluid>
          <CommonTable
            is_loading={ !is_ready || is_loading }
            empty_message={<Trans>There are no customers</Trans>}
            headers={ headers }
            items={ customers }
            item_list={customerListForReseller}
            getCellValue={ getCustomerCellValue }
          />

          { goingToLoginAsCustomer && renderCustomerLoginModal() }
        </Container>
    )
    
}

