/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { forEach, head, get, map, keys, split, size } from 'lodash'
import { adminOutgoingFaxServiceProviderList } from '../actions/admin_outgoing_fax_service_provider'
import { adminOutgoingFaxServiceProviderTypes } from '../actions/admin_dropdown_options'
import { CardHeader } from '../../components/layout/CardHeader'
import { LanguageFlag } from '../../components/LanguageFlag'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import FieldInfoText from '../../components/layout/FieldInfoText'
import { Separator } from '../../components/layout/Separator'
import { Formik, Form, FieldArray, Field } from 'formik'
import Loading from '../../components/Loading'
import { FormikTextarea } from '../../components/form/TextareaField'
import { FormikInputField } from '../../components/form/InputField'
import { FormikDropdownField } from '../../components/form/Dropdown'
import AdminMainLayout from './AdminMainLayout'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import Card from '../../components/layout/Card'
import { Col, Row, Container, Navbar, Nav } from 'react-bootstrap'
import { Form as BootstrapForm } from 'react-bootstrap'
import * as Yup from 'yup'
import {showSuccess, showError} from '../../actions/Error'
import Button from 'react-bootstrap/Button'
import { BlueButton } from '../../components/layout/BlueButton'
import CardInfoText from '../../components/layout/CardInfoText'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { handleSubmitResult } from '../../actions/form'
import { LANGUAGES } from '../../actions/language'

const yup_shape = {
    'name': Yup.string().required("Required"),
}
const validationSchema = Yup.object().shape(yup_shape)

class AdminOutgoingFaxServiceProvider extends Component {

    componentDidMount() {
        const { dispatch, outgoing_fax_service_provider_id } = this.props
        dispatch(adminOutgoingFaxServiceProviderList.ensureObjectLoaded(outgoing_fax_service_provider_id))
        dispatch(adminOutgoingFaxServiceProviderTypes.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch, outgoing_fax_service_provider_id } = this.props
        dispatch(adminOutgoingFaxServiceProviderList.ensureObjectLoaded(outgoing_fax_service_provider_id))
    }

    onSave = (values, formik_funcs) => {
        const { history, onSubmit, dispatch, outgoing_fax_service_provider_id } = this.props

        const on_ok = function(json) {
            dispatch(adminOutgoingFaxServiceProviderList.invalidateList())
            showSuccess("Saved", "OutgoingFaxServiceProvider saved")

            if ( ! outgoing_fax_service_provider_id ) {
                history.push(`/admin/outgoing_fax_service_provider/${json.id}`)
            }
        }
        if ( outgoing_fax_service_provider_id ) {
            values.id = outgoing_fax_service_provider_id
            return dispatch(adminOutgoingFaxServiceProviderList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminOutgoingFaxServiceProviderList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }
    
    getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            default:
                return undefined
        }
    }

    renderForm(formik_props) {
        const { outgoing_fax_service_provider_type_options,
                outgoing_fax_service_provider_options } = this.props
        return (
            <div>
              <Row>
                <Col>

                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Provider type</BootstrapForm.Label>
                    <FormikDropdownField name="provider_type"
                                         formik_props={formik_props}
                                         options={outgoing_fax_service_provider_type_options}
                                         placeholder="Select provider type" />
                  </BootstrapForm.Group>

                  
                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Internal name</BootstrapForm.Label>
                    <FormikInputField name="name" placeholder="name" />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group>
                    <FormikCheckboxField
                        name="is_default"
                        label="Default"
                    />
                  </BootstrapForm.Group>
                  
                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Url for sending</BootstrapForm.Label>
                    <FormikInputField name="url_for_sending" place_holder="url_for_sending" />
                  </BootstrapForm.Group>
                  
                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Url for status check</BootstrapForm.Label>
                    <FormikInputField name="url_for_status_check" place_holder="Url for status check" />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Secret for sending</BootstrapForm.Label>
                    <FormikInputField name="secret_for_sending" place_holder="Secret for sending" />
                  </BootstrapForm.Group>
                  
                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Secret for status check</BootstrapForm.Label>
                    <FormikInputField name="secret_for_status_check" place_holder="Secret for status check" />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Failover server</BootstrapForm.Label>
                    <FormikDropdownField name="failover_outgoing_fax_service_provider"
                                         formik_props={formik_props}
                                         options={outgoing_fax_service_provider_options}
                                         placeholder="Select failover provider" />
                  </BootstrapForm.Group>

                  { get(formik_props, ["values", "failover_outgoing_fax_service_provider"]) &&
                   <BootstrapForm.Group>
                     <BootstrapForm.Label>Failover error response</BootstrapForm.Label>
                     <FieldInfoText>
                       This is a list of errors responses from the fax sending service that will allow failover from this server to the failover server configured above.
                       <br/>
                       Matching is case-insensitive and only a portion of one of these error codes needs to match the response.
                     </FieldInfoText>
                     <FormikTextarea rows={7}
                                     name="can_try_failover_on_these_response_codes"
                                     placeholder="Error responses triggering failover"
                     />
                   </BootstrapForm.Group>
                  }
                  
                </Col>
              </Row>
              <Row>
                <Col>
                  <BlueButton type="submit">Save</BlueButton>
                </Col>
              </Row>
            </div>
        )
    }

    render() {
        const { initial_values, is_loading, is_busy, outgoing_fax_service_provider, outgoing_fax_service_provider_id } = this.props
        const that = this
        const title = `${get(outgoing_fax_service_provider, ["name"], 'New outgoing_fax_service_provider')}`

        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           
                                           {name: 'outgoing_fax_service_providers',
                                            label: 'OutgoingFaxServiceProviders',
                                            url: '/admin/outgoing_fax_service_providers'},
                                           
                                           {name: 'outgoing_fax_service_provider',
                                            label: (
                                                <div css={breadcrumb_item}>
                                                  {get(outgoing_fax_service_provider, ["name"], 'New outgoing_fax_service_provider')}
                                                </div>
                                            ),
                                            url: `/admin/outgoing_fax_service_provider/${outgoing_fax_service_provider_id}`
                                           }
            ]}>
              <Container fluid>
                { is_busy && <BusyMask /> }
                { is_loading && <Loading /> }

                <CardHeader title={title} />
                <Separator variant="h30" />
                
                { ! is_loading && 
                  
                  <Formik
                      initialValues={initial_values}
                      onSubmit={that.onSave}
                      enableReinitialize={true}
                      validationSchema={validationSchema}
                      >
                    {formik_props => {
                         const { values } = formik_props
                         return (
                             <Form>
                               <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                               <Row>
                                 <Col>
                                   <Card>
                                     { that.renderForm(formik_props) }
                                   </Card>
                                 </Col>
                               </Row>
                             </Form>
                         )}
                    }
                  </Formik>
                }
              </Container>
            </AdminMainLayout>
        )
    }
    
}

function mapStateToProps(state, props) {
    const outgoing_fax_service_provider_id = get(props, ["match", "params", "outgoing_fax_service_provider_id"], null)
    const outgoing_fax_service_provider = adminOutgoingFaxServiceProviderList.getObject(outgoing_fax_service_provider_id)
    const outgoing_fax_service_provider_options = adminOutgoingFaxServiceProviderList.getAsOptions()
    return {
        outgoing_fax_service_provider_id,
        outgoing_fax_service_provider,
        is_loading: adminOutgoingFaxServiceProviderList.isLoading(),
        is_busy: adminOutgoingFaxServiceProviderList.getIsSavingObject(),
        initial_values: outgoing_fax_service_provider,
        outgoing_fax_service_provider_type_options: adminOutgoingFaxServiceProviderTypes.getAsOptions(),
        outgoing_fax_service_provider_options
    }
}

export default connect(mapStateToProps)(AdminOutgoingFaxServiceProvider)

const breadcrumb_item = css`
display: flex;
align-items: center;
`
