/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { jsx, css } from '@emotion/react'
import loading_icon from '../images/loading.gif'

export const LoadingIcon = ({}) => {

    return (
        <span css={container}>
          <img css={loading_icon_style} src={loading_icon} />
        </span>
    )
}


const container = css`
margin-left: 30px;
color: #000;
height: 30px;
`


const loading_icon_style = css`
height: 30px;
`
