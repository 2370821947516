/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { head, get } from 'lodash'
import { Link } from 'react-router-dom'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import CurrencyValue from '../../components/CurrencyValue'
import Timestamp from '../../components/Timestamp'
import { adminNumberRangeList } from '../actions/admin_number_range'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { Button, Container } from 'react-bootstrap'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFilter from './AdminTableFilter'

class AdminNumberRanges extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminNumberRangeList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(adminNumberRangeList.fetchListIfNeeded())
    }

    onEditNumberRange = (evt, item) => {
        const { history } = this.props
        history.push({
            pathname: '/admin/number_range/'+item.id
        })
    }

    onAddNumberRange = () => {
        const { history } = this.props
        history.push('/admin/number_range')
    }
    
    filterNumberRanges = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminNumberRangeList.updateListFilter(filter_values))
    }


    getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'cost_per_page_excluding_vat_euros':
                return (
                    <CurrencyValue value={item.cost_per_page_excluding_vat_euros} />
                )
            case 'country':
                return get(item, ["country", "name"])
            case 'action':
                return (
                    <Button onClick={(evt) => this.onEditNumberRange(evt, item)}>
                      Edit
                    </Button>
                )
                
            default:
                return undefined
        }
    }

    render() {
        const { is_loading, headers, number_ranges } = this.props
        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'number_ranges', label: 'Number Ranges', url: '/admin/number_ranges'}]}>
              <AdminTableHeader title="NumberRanges">
                <AdminTableFilter
                  updateOnChange={ this.filterNumberRanges }
                  form="ADMIN_NUMBER_RANGES_FILTER_FORM"
                />
                <Button variant="outline-primary" onClick={ this.onAddNumberRange }>
                  Add number range
                </Button>
              </AdminTableHeader>
              <CommonTable
                is_loading={ is_loading }
                empty_message={`There are no number ranges.`}
                headers={ headers }
                items={ number_ranges }
                item_list={adminNumberRangeList}
                getCellValue={ this.getCellValue }
              />
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const number_ranges = adminNumberRangeList.getVisibleObjects()
    return {
        number_ranges,
        is_loading: adminNumberRangeList.isLoading(),
        headers: {
            name: {name: 'Name'},
            start_number: {name: 'Start'},
            end_number: {name: 'End'},
            action: { name: '', column_size: 1 }
        }
    }
}

export default connect(mapStateToProps)(AdminNumberRanges)
