/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Trans, Translation } from 'react-i18next'
import { get } from 'lodash'
import classNames from 'classnames'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { FormikInputField } from '../../components/form/InputField'
import AdminTableFilter from './AdminTableFilter'
import { AdminTableFilterPopup } from './AdminTableFilterPopup'
import { jsx, css } from '@emotion/react'
import AdminMainLayout from './AdminMainLayout'
import { BlueButton } from '../../components/layout/BlueButton'
import { Row, Col, Container } from 'react-bootstrap'
import { Separator } from '../../components/layout/Separator'
import { CardHeader } from '../../components/layout/CardHeader'
import { adminUpgradePathList } from '../actions/admin_upgrade_path'
import { adminUpgradePathTypes } from '../actions/admin_dropdown_options'
import { AdminUpgradePathTable } from './AdminUpgradePathTable'

const IS_UPGRADED_OPTIONS = [ {value: true, label: 'Upgraded'},
                              {value: false, label: 'Not upgraded'},
                              {value: null, label: 'Upgrade and not upgraded'} ]

const READY_FOR_UPGRADE_OPTIONS = [ {value: true, label: 'Ready to upgrade'},
                                    {value: false, label: 'Not ready to upgrade'},
                                    {value: null, label: 'Ready and not ready'} ]

const SHOW_NOTIFICATION_OPTIONS = [ {value: true, label: 'Show notifications'},
                                    {value: false, label: 'Hidden notifications'},
                                    {value: null, label: 'Visible or hidden'} ]

export const AdminUpgradePaths = ({ ...props }) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const is_ready = useSelector(() => adminUpgradePathList.isReady())
    const upgrade_type_options = useSelector(() => adminUpgradePathTypes.getAsOptions())

    useEffect(() => {
        dispatch(adminUpgradePathList.fetchListIfNeeded())
        dispatch(adminUpgradePathTypes.fetchListIfNeeded())
    }, [])

    const onFilterChanged = (filter_values) => {
        dispatch(adminUpgradePathList.updateListFilter(filter_values))
        dispatch(adminUpgradePathList.fetchListIfNeeded())
    }

    const renderFilterFields = (formik_props) => {
        return (
            <AdminTableFilterPopup popup_fields={(
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <FormikDropdownField name="is_upgraded"
                                             formik_props={formik_props}
                                             options={IS_UPGRADED_OPTIONS}
                                             placeholder="Is upgraded"
                        />
                      </Col>
                    </Row>
                    
                    <Separator variant="h5" />                    
                    <Row>
                      <Col>
                        <FormikDropdownField name="ready_for_upgrade"
                                             formik_props={formik_props}
                                             options={READY_FOR_UPGRADE_OPTIONS}
                                             placeholder="Can upgrade"
                        />
                      </Col>
                    </Row>
                    <Separator variant="h5" />

                    <Row>
                      <Col>
                        <FormikDropdownField name="show_notification"
                                             formik_props={formik_props}
                                             options={SHOW_NOTIFICATION_OPTIONS}
                                             placeholder="Show notification to customer"
                        />
                      </Col>
                    </Row>
                    <Separator variant="h5" />

                    <Row>
                      <Col>
                        <FormikDropdownField name="upgrade_type"
                                             formik_props={formik_props}
                                             options={upgrade_type_options}
                                             placeholder="Type"
                        />
                      </Col>
                    </Row>
                    <Separator variant="h5" />

                  </Col>
                </Row>
            )}
                                   fixed_fields={(
                                       <Row>
                                         <Col>
                                           <FormikInputField name="any_field"
                                                             placeholder="Search"
                                                             show_search_icon={true}
                                           />
                                         </Col>
                                       </Row>
                                   )}
            />
        )
    }
    
    const renderFilter = () => {
        const that = this
        return (
            <div css={filter_style}>
              <AdminTableFilter updateOnChange={ onFilterChanged }
                                renderFilter={renderFilterFields}
              />
            </div>
        )
    }
    
    return (
        <AdminMainLayout active_key={'upgrade_paths'}
                         breadcrumbs={[{name: 'admin_home'},
                                       {name: 'upgrade_paths', label: 'Upgrade paths', url: '/admin/upgrade_paths'}]}>
              <Container fluid>
                <Row>
                  <Col md={12}>
                    <CardHeader left_child={null}
                                align_with_common_table={true}
                                title={<Trans>Upgrade Paths</Trans>}
                                right_child={
                                    (
                                        <>
                                          { renderFilter() }
                                        </>
                                    )
                                }
                    />
                    <Separator variant="h15" />

                    <AdminUpgradePathTable header_names={['customer_email', 'customer_first_name', 'customer_last_name', 'subscription_phone_number',
                                                          'upgrade_type', 'notify_customer', 'dismissed', 'seen_by_customer_at', 'ready_for_upgrade',
                                                          'is_upgraded', 'action_refresh']} />
                    
                    
                  </Col>
                </Row>
              </Container>
            </AdminMainLayout>
        )
    
}

const filter_style = css`
display: flex;
align-items: center;
`

const filter_date_picker_style = css`
margin-right: 2px;
`
