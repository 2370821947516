import { ItemList } from '../../orm'

class DropdownOptions extends ItemList {
    constructor(dropdown_name) {
        super(`admin_options__${dropdown_name}`)
        this.url = `admin/dropdown_options/${dropdown_name}`
    }

    getEntityKey() {
        return this.url
    }

}

export const adminNumberTypes = new DropdownOptions('number_types')
export const adminNumberPrefixTypes = new DropdownOptions('number_prefix_types')
export const adminTransactionTypes = new DropdownOptions('transaction_types')
export const adminPhoneNumberStatuses = new DropdownOptions('phone_number_statuses')
export const adminAsteriskIncomingFaxStatuses = new DropdownOptions('asterisk_incoming_fax_statuses')
export const adminOutgoingFaxServiceProviderTypes = new DropdownOptions('outgoing_fax_service_provider_types')
export const adminSyncLogTypes = new DropdownOptions('sync_log_types')
export const adminSyncLogStatuses = new DropdownOptions('sync_log_statuses')
export const adminAsteriskMappingOptions = new DropdownOptions('sync_asterisk_mapping_options')
export const adminPhoneNumberPrefixAllocationOptions = new DropdownOptions('phone_number_prefix_allocation_methods')
export const adminCustomerSubscriptionManualReservationOptions = new DropdownOptions('customer_subscription_manual_reservation_choices')
export const adminWhenToSendWelcomeEmailOptions = new DropdownOptions('when_to_send_welcome_email_choices')
export const adminCdrExternalRoutingInfoTypes = new DropdownOptions('cdr_external_routing_info_types')
export const adminOutboundEmailStatuses = new DropdownOptions('outbound_email_statuses')
export const adminUpgradePathTypes = new DropdownOptions('upgrade_path_types')
export const adminBulkCustomerMigrationStates = new DropdownOptions('bulk_customer_migration_states')
export const adminFaxTrafficChannelOptions = new DropdownOptions('fax_traffic_channel_options')
