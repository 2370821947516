/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../emotion/theme'
import { Trans, Translation } from 'react-i18next'
import CurrencyValue from './CurrencyValue'

export const NewSubscriptionCostSupplement = ({label, cost, cost_period}) => {

    return (
        <div css={row_style}>
          <Trans>{label}</Trans>
          <span css={price_style}> 
            +<CurrencyValue value={cost} use_span={true} />
            { cost_period &&
              <>
                /
                <Trans>{cost_period}</Trans>
              </>
            }
          </span>
        </div>
    )
}

const row_style = css`
font-weight: bold;
white-space: nowrap;
`        

const price_style = css`
width: 70px;
height: 25px;
margin: 22px 1px 13px 9px;
padding: 5px 7px 4px 4px;
border-radius: 4px;
border: solid 2px ${theme.colors.black};
white-space: nowrap;
`
