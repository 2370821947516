/** @jsxImportSource @emotion/react */
import React,{ useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { InlineIcon } from './layout/InlineIcon'
import { jsx, css } from '@emotion/react'
import { Trans, Translation } from 'react-i18next'
import { Separator } from './layout/Separator'
import { Row, Col, Container } from 'react-bootstrap'
import { head, filter, size, map } from 'lodash'
import { default_theme as theme } from '../emotion/theme'
import { upgradePathListExVat } from '../actions/upgrade_path'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

export const UpgradePathNotificationExVat = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const upgrade_path = useSelector(() => head(upgradePathListExVat.getVisibleObjects()))
    const is_loading = useSelector(() => upgradePathListExVat.isLoading())
    const should_show_notification = !is_loading && Boolean(upgrade_path)
    const is_saving = useSelector(() => upgradePathListExVat.getIsSavingObject())
    
    useEffect(() => {
        dispatch(upgradePathListExVat.updateListFilter({dismissed: false, upgrade_type:'xoip1450_change_to_exvat'}))
        dispatch(upgradePathListExVat.fetchListIfNeeded())
    }, [])

    const onHideNotification = () => {
        const on_ok = (json) => {
            dispatch(upgradePathListExVat.invalidateList())
            dispatch(upgradePathListExVat.fetchListIfNeeded())
        }
        upgrade_path.dismissed = true
        return dispatch(upgradePathListExVat.saveObject(upgrade_path)).then(on_ok)
    }

    const onDidRender = (upgrade_path) => {

        if ( Boolean(upgrade_path.seen_by_customer_at) ) {
            return
        }
        
        const on_ok = (json) => {
        }
        
        upgrade_path.seen_by_customer_at = moment().format()
        dispatch(upgradePathListExVat.saveObject(upgrade_path)).then(on_ok)
    }

    const renderNotification = () => {
        return (
            <div css={container_style}>

              <div css={dismiss_style} onClick={onHideNotification}>
                <FontAwesomeIcon icon={faTimesCircle} />
              </div>
              
              <Trans i18nKey="upgrade_path_xoip1450_change_to_exvat__para1">
                Please note: Our fax sending and call forwarding rates have been lowered because we have switched over to ex VAT pricing. Your credit balance has been adjusted proportionately to maintain your entitlement. 
              </Trans>
            </div>
        )
    }
    
    return (
        <>
          { should_show_notification && renderNotification() }
        </>
    )
    
}

const container_style = css`
background-color: #fff5cc;
width: 100%;
margin: 20px 0 10px;
padding: 20px 8px 21px 20px;
border-radius: 10px;
color: #000000;
position: relative;
`

const dismiss_style = css`
position: absolute;
top: 8px;
right: 8px;
cursor: pointer;
color: #b8b8bd;

&:hover {
color: ${theme.colors.secondary_blue};
}

`
