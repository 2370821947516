/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react'
import { jsx, css } from '@emotion/react'
import { get } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CardHeader } from './layout/CardHeader'
import MainLayout from './MainLayout'
import { Col, Row, Container, Tabs, Tab, Button } from 'react-bootstrap'
import { Separator } from './layout/Separator'
import { default_theme as theme } from '../emotion/theme'
import { ResellerCustomers } from './ResellerCustomers'

export const ResellerLanding = ({match}) => {

    const history = useHistory()
    const active_tab = get(match, ["params", "active_tab"], "customers")
    
    const setTab = (tab_name) => {
        history.push(`/reseller/${tab_name}`)
    }

    const renderTabContainer = () => {
        return (
            <Col>
              <Tabs defaultActiveKey={active_tab} id="tabs" onSelect={setTab} css={customer_submenu}>
                <Tab eventKey="customers" title={<Trans>Customers</Trans>}>
                  <Separator variant="h15" />
                  { active_tab === "customers" && <ResellerCustomers /> }
                </Tab>
              </Tabs>
            </Col>
        )
    }
    
    
    return (
        <MainLayout active_key="profile"
                    breadcrumbs={[{name: 'home'},
                                  {name: 'reseller', label: <Trans>Reseller</Trans>, url: '/reseller'}]}>
          <Container fluid>
            <CardHeader title="Reseller" />
            <Row>
              <Container fluid>
                <Separator variant="h30" />
                <Row>
                  { renderTabContainer() }
                </Row>
              </Container>
            </Row>
          </Container>
        </MainLayout>
    )
    
}

const customer_submenu = css`
a {
  margin-bottom: 7px !important;
  margin-right: 4px;
  border: none !important;
  border-radius: 4px; 
}
a:hover {
  background-color: ${theme.colors.very_light_grey};
  color: #0788ca;
}
a.active {
  background-color: #e5e5ea !important;
  color: #0788ca !important;
}
`

