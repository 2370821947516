import { ItemList } from '../../orm'

class AdminAsteriskRoutingLookupList extends ItemList {
    getEntityKey() {
        return "admin/asterisk_routing_lookup"
    }
}


export const adminAsteriskRoutingLookupList = new AdminAsteriskRoutingLookupList("admin_asterisk_routing_lookup__default")
