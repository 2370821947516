/** @jsxImportSource @emotion/react */
import { jsx, css, keyframes } from '@emotion/react'
import { default_theme as theme } from '../emotion/theme'
import React from 'react'

const LoadingNumberCard = () => {
  return (
    <div css={loading_card_container_style}>
      <div css={loading_card_header_style}></div>
      <div css={loading_card_body_style}>
        <div css={loading_number_style}></div>
        <div css={loading_name_style}></div>
      </div>
    </div>
  )
}

export default LoadingNumberCard

const pulsate = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`

const loading_card_container_style = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 105px;
  background-color: ${theme.colors.very_light_grey};
  border-radius: 8px;
  padding: 16px 16px;
  padding-top: 20px;
  animation: ${pulsate} 1.5s ease-in-out infinite;
`

const loading_card_header_style = css`
  background-color: ${theme.colors.light_grey};
  width: 60%;
  height: 1em;
  border-radius: 10px;
`

const loading_card_body_style = css`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
const loading_number_style = css`
  background-color: ${theme.colors.light_grey};
  width: 45%;
  height: 1em;
  border-radius: 10px;
`

const loading_name_style = css`
  background-color: ${theme.colors.light_grey};
  width: 80%;
  height: 1em;
  border-radius: 10px;
`
