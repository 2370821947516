import { ItemList } from '../../orm'

class AdminSyncLogList extends ItemList {
    getEntityKey() {
        return "admin/sync_log"
    }

    sync(values) {
        return this.saveNewObject(values)
    }
    
}

export const adminSyncLogList = new AdminSyncLogList("admin_sync_log__default")
