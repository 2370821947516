/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { default_theme as theme } from '../../emotion/theme'
import { jsx, css } from '@emotion/react'

export const NoodleHead = ({}) => {
    
    return (
        <div css={stacked_style}>
          <div css={[noodle_common_style, noodle_head_style]}>
            &nbsp;
          </div>
          <NoodleStem />
        </div>
    )

}

export const NoodleStem = ({}) => {
    
    return (
        <div css={[noodle_common_style, noodle_stem_style]}>
          &nbsp;
        </div>
    )

}

export const NoodleTail = ({}) => {
    
    return (
        <div css={stacked_style}>
          <NoodleStem />
          <div css={[noodle_common_style, noodle_tail_style]}>
            &nbsp;
          </div>
        </div>
    )

}

const noodle_common_style = css`
  border: solid 3px ${theme.colors.light_grey};
  background-color: ${theme.colors.light_grey};
`

const noodle_head_style = css`
  width: 24px;
  height: 24px;
  border-radius: 24px;
`

const noodle_stem_style = css`
  width: 3px;
  height: 100%;
  min-height: 24px;
`

const noodle_tail_style = css`
  width: 24px;
  height: 24px;
`

const stacked_style = css`
display: flex;
width: 100%;
justify-content: center;
flex-direction: column;
align-items: center;
`
