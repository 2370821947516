/** @jsxImportSource @emotion/react */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { Nav, Col, Row } from 'react-bootstrap'
import CardInfoText from './CardInfoText'
import { Trans, Translation } from 'react-i18next'

export const CardHeader = ({
  title,
  left_child,
  right_child,
  sub_text,
  align_with_common_table,
  ...props
}) => {
  return (
    <div
      css={
        align_with_common_table === true ? align_with_common_table_style : null
      }
    >
      <div css={container}>
        <Nav className="mr-auto" css={nav_style}>
          {title && (
            <div css={title_style}>
              <h1>
                <Trans>{title}</Trans>
              </h1>
            </div>
          )}
          {left_child}
        </Nav>

        <Nav css={nav_style}>{right_child}</Nav>
      </div>

      {sub_text && <CardInfoText>{sub_text}</CardInfoText>}
    </div>
  )
}

const container = css`
  display: flex;
  align-items: end;
  gap: 20px;

  /* Media query for tablet screens */
  @media (max-width: 480px) {
    flex-direction: column;
    gap: 10px;
    align-items: start;
  }
`

const title_style = css`
  margin-right: 20px;
`

const nav_style = css`
  display: flex;
  align-items: center;
  gap: 10px;

  /* Media query for tablet screens */
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    align-items: start;
  }
  /* Media query for phone screens */
  @media (max-width: 480px) {
    width: 100%;
    & > * {
      width: 100%;
    }
  }
`

const align_with_common_table_style = css`
  padding-right: 20px;
`
