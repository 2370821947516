/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx } from '@emotion/react'
import { head, get } from 'lodash'
import { adminUserList } from '../actions/admin_user'
import { Link } from 'react-router-dom'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import { adminIncomingFaxByEmailQueueList } from '../actions/admin_incoming_fax_by_email_queue'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { Button, Container } from 'react-bootstrap'

class AdminIncomingFaxEmails extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminIncomingFaxByEmailQueueList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(adminIncomingFaxByEmailQueueList.fetchListIfNeeded())
    }

    getCellValue = (header_key, item, index) => {
        const { customer_id } = this.props
        switch( header_key ) {
            case 'created_at':
                return <Timestamp value={item.created_at} format="datetime" />
            case 'last_attempt_at':
                return <Timestamp value={item.last_attempt_at} format="datetime" />
            default:
                return undefined
        }
    }

    render() {
        const { is_loading, emails, headers } = this.props
        return (
            <AdminMainLayout title='Incoming Fax Emails'
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'incoming_fax_emails', label: 'Incoming fax emails', url: '/admin/incoming_fax_emails'}]}>
              <Container fluid>
                <CommonTable
                    is_loading={ is_loading }
                    empty_message={`No emails`}
                    headers={ headers }
                    items={ emails }
                    item_list={adminIncomingFaxByEmailQueueList}
                    getCellValue={ this.getCellValue }
                />
              </Container>
            </AdminMainLayout>
            
        )
    }
    
}

function mapStateToProps(state, props) {
    const emails = adminIncomingFaxByEmailQueueList.getVisibleObjects()
    return {
        is_loading: adminIncomingFaxByEmailQueueList.isLoading(),
        emails,
        headers: {
            created_at: { name: 'Received at', column_size: 1 },
            customer_name: { name: 'Customer', column_size: 1 },
            status: { name: 'Status', column_size: 1 },
            outgoing_fax_queue_status_name: { name: 'Fax status', column_size: 1 },
            last_attempt_at: { name: "Processed at", column_size: 1 },
            last_error: { name: "Errors", column_size: 1 },
            to_addresses: { name: 'To', column_size: 1 },
            from_email: { name: 'From', column_size: 1 },
            from_name: { name: 'From name', column_size: 1 },
            subject: { name: 'Subject', column_size: 1 },
            num_attachments: { name: '# Attachments', column_size: 1 }
        }
    }
}

export default connect(mapStateToProps)(AdminIncomingFaxEmails)
