/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../emotion/theme'
import Card from './layout/Card'
import { Trans } from 'react-i18next'
import { CardHeader } from './layout/CardHeader'
import { InlineIcon } from './layout/InlineIcon'
import { WhiteButton } from './layout/WhiteButton'
import { BlueButton } from './layout/BlueButton'
import { BlueLinkButton } from './layout/BlueLinkButton'
import { Separator } from './layout/Separator'
import { Button, ButtonGroup, Row, Col } from 'react-bootstrap'
import CurrencyValue from './CurrencyValue'
import { customerList } from '../actions/customer'
import { molliePaymentList } from '../actions/mollie_payment'
import { topupList } from '../actions/topup'
import { globalSettingsList } from '../actions/settings'
import { getBestLanguageCode } from '../actions/language'
import { MobileOnly } from './layout/MobileOnly'
import { UpgradePathNotificationExVat } from './UpgradePathNotificationExVat'

class CreditInformationCard extends Component {
  componentDidMount() {
    const { dispatch } = this.props
    dispatch(customerList.ensureCustomerLoaded())
    dispatch(globalSettingsList.fetchListIfNeeded())
  }

  buyMoreCredit = () => {
    const { history, onLeavingPage } = this.props
    if (onLeavingPage) {
      onLeavingPage()
    }
    topupList.startBuyMoreCredit({ history })
  }

  onShowCreditHistory = () => {
    const { history } = this.props
    history.push('/transactions')
  }

  onShowFaxSendingRates = () => {
    const { history, external_info_urls } = this.props
    const url = get(external_info_urls, ['fax_rates', getBestLanguageCode()])
    window.open(url, '_blank')
  }

  onShowForwardingRates = () => {
    const { history, external_info_urls } = this.props
    const url = get(external_info_urls, [
      'call_forwarding_rates',
      getBestLanguageCode(),
    ])
    window.open(url, '_blank')
  }

  renderButton() {
    const { customer, is_ready } = this.props
    const credits_excluding_vat_euros = get(
      customer,
      'credits_excluding_vat_euros',
      null
    )
    const button_style =
      credits_excluding_vat_euros > 0
        ? credit_button_available_style
        : credit_button_empty_style
    return (
      <>
        <div css={credit_button_group}>
          {is_ready && credits_excluding_vat_euros !== null && (
            <Button
              variant="success"
              css={button_style}
              onClick={this.buyMoreCredit}
              auto_disable={false}
            >
              <div css={currency_button_content_style}>
                <CurrencyValue value={credits_excluding_vat_euros} />
              </div>
            </Button>
          )}
          <Separator variant="w10" />
          {is_ready &&
            credits_excluding_vat_euros !== null &&
            credits_excluding_vat_euros <= 0 && (
              <WhiteButton onClick={this.buyMoreCredit} auto_disable={false}>
                <Trans>Buy Credit</Trans>
              </WhiteButton>
            )}
          {is_ready &&
            credits_excluding_vat_euros !== null &&
            credits_excluding_vat_euros > 0 && (
              <WhiteButton onClick={this.buyMoreCredit} auto_disable={false}>
                <Trans>Buy Credit</Trans>
              </WhiteButton>
            )}
        </div>
      </>
    )
  }

  renderMini() {
    const { is_postpaid } = this.props
    if (is_postpaid) {
      return null
    }
    return this.renderButton()
  }

  renderMaxi() {
    const {
      customer,
      showFaxSendingRates,
      is_ready,
      show_quick_links,
      is_postpaid,
      is_prepaid,
      renderFooter,
    } = this.props

    const credits_excluding_vat_euros = get(
      customer,
      'credits_excluding_vat_euros',
      null
    )
    const button_style =
      credits_excluding_vat_euros > 0
        ? credit_button_available_style
        : credit_button_empty_style

    return (
      <div>
        {is_prepaid && (
          <>
            <MobileOnly>
              <Separator variant="h30" />
            </MobileOnly>
            <CardHeader
              title={<Trans>XOIP Credit</Trans>}
              left_child={null}
              right_child={null}
              sub_text={
                <Trans>
                  Credit is used for forwarding calls to external phone numbers
                  and for sending faxes.
                </Trans>
              }
            />

            <Card
              variant="white"
              with_thick_padding_above={show_quick_links == false}
              with_padding_below={show_quick_links !== false}
            >
              {this.renderButton()}

              {show_quick_links !== false && (
                <div css={credit_links_container_style}>
                  <Row>
                    <Col>
                      <BlueLinkButton
                        onClick={this.onShowCreditHistory}
                        auto_disable={false}
                      >
                        <div css={link_container_style}>
                          <Trans>Credit history</Trans>
                          {/* <Separator variant="w3" /> */}
                          <div css={chevron_container}>
                            <InlineIcon
                              icon_name="chevron-right"
                              size_variant="h14"
                              variant="blue"
                            />
                          </div>
                        </div>
                      </BlueLinkButton>
                    </Col>
                  </Row>
                  {showFaxSendingRates && (
                    <Row>
                      <Col>
                        <BlueLinkButton
                          onClick={this.onShowFaxSendingRates}
                          auto_disable={false}
                        >
                          <div css={link_container_style}>
                            <Trans>Fax sending rates</Trans>
                            {/* <Separator variant="w3" /> */}
                            <div css={chevron_container}>
                              <InlineIcon
                                icon_name="chevron-right"
                                size_variant="h14"
                                variant="blue"
                              />
                            </div>
                          </div>
                        </BlueLinkButton>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <BlueLinkButton
                        onClick={this.onShowForwardingRates}
                        auto_disable={false}
                      >
                        <div css={link_container_style}>
                          <Trans>Call forwarding rates</Trans>
                          <div css={chevron_container}>
                            <InlineIcon
                              icon_name="chevron-right"
                              size_variant="h14"
                              variant="blue"
                            />
                          </div>
                        </div>
                      </BlueLinkButton>
                    </Col>
                  </Row>
                </div>
              )}
              {renderFooter && (
                <>
                  <Separator variant="h10" />
                  {renderFooter()}
                </>
              )}
            </Card>
            <Separator variant="h20" />
          </>
        )}
      </div>
    )
  }

  render() {
    const { mini } = this.props

    if (mini == true) {
      return this.renderMini()
    } else {
      return this.renderMaxi()
    }
  }
}

function mapStateToProps(state, props) {
  const external_info_urls = globalSettingsList.getSetting('external_info_urls')
  const customer = customerList.getCustomer()
  const { showFaxSendingRates, renderFooter } = props
  return {
    customer,
    is_ready: customerList.isReady(),
    external_info_urls,
    is_postpaid: get(customer, 'has_active_postpaid_fax_sending_subscription'),
    is_prepaid: !get(customer, 'has_active_postpaid_fax_sending_subscription'),
    showFaxSendingRates: showFaxSendingRates !== false,
    renderFooter,
  }
}

export default withRouter(connect(mapStateToProps)(CreditInformationCard))

const mini = css`
  height: 36px;
  align-items: center;
  display: flex;
  cursor: pointer;
`

const mini_title = css`
  font-size: 10px;
`

const mini_value = css`
  font-size: 16px;
  line-height: 1.13;
  font-weight: 500;
`

const mini_credit_amount_common = css`
  color: #fff;
  padding-top: 4px;
  padding-bottom: 2px;
  border-radius: 4px;
  padding-left: 9px;
  padding-right: 9px;
  line-height: 14px;
  text-align: center;
`

const mini_credit_amount_available = css`
  background-color: ${theme.colors.dark_green};
`

const mini_credit_amount_empty = css`
  background-color: ${theme.colors.primary_red};
  border-color: ${theme.colors.primary_red};

  &:hover {
    border-color: ${theme.colors.primary_red};
    background-color: ${theme.colors.primary_red};
  }
`

const mini_buy_credit_button = css`
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
`

const mini_credit_amount_cell = css``

const mini_separator = css`
  width: 4px;
`

const credit_button_group = css`
  display: flex;
  margin-bottom: 20px;
`

const credit_button_available_style = css`
  background-color: ${theme.colors.dark_green};
  color: #fff;
  border-radius: 20px;
`

const credit_button_empty_style = css`
  background-color: ${theme.colors.primary_red};
  color: #fff;
  border-color: ${theme.colors.primary_red};
  border-radius: 20px;

  &:hover {
    border-color: ${theme.colors.primary_red};
    background-color: ${theme.colors.primary_red};
  }
`

const buy_more_button_style = css`
  background-color: #fff;
  border: 1px solid #d1d1d6;
  margin-left: 10px;

  &:hover {
    background-color: #fff;
  }
`

const currency_button_content_style = css`
  text-align: center;
`

const credit_links_container_style = css``

const chevron_container = css`
  margin-left: 2px;
`

const link_container_style = css`
  display: flex;
`
