/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../emotion/theme'
import MainLayout from './MainLayout'
import Card from './layout/Card'
import { CardHeader } from './layout/CardHeader'
import { InlineIcon } from './layout/InlineIcon'
import { WhiteButton } from './layout/WhiteButton'
import { BlueButton } from './layout/BlueButton'
import { BlueNonBoldLinkButton } from './layout/BlueNonBoldLinkButton'
import { Separator } from './layout/Separator'
import { Button, ButtonGroup, Row, Col } from 'react-bootstrap'
import { customerList } from '../actions/customer'

class BillingDetailsCard extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(customerList.ensureCustomerLoaded())
    }
    
    render() {
        const { customer } = this.props

        return (
            <div>

              <CardHeader title="Billing"
                          left_child={null}
                          right_child={null}
              />

              <Card variant="white">
                <div css={content}>
                  Billing details
                  <br/>
                  (tbd)
                </div>
              </Card>
            </div>
        )        
    }
    
}

function mapStateToProps(state, props) {
    return {
        customer: customerList.getCustomer()
    }
}

export default withRouter(connect(mapStateToProps)(BillingDetailsCard))

const content = css`
height: 168px;
`
