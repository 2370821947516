/** @jsxImportSource @emotion/react */
import React, {useEffect} from 'react'
import { connect, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"
import { useDispatch } from 'react-redux'
import { jsx, css } from '@emotion/react'
import {fromPairs, keys, filter, get, head, map, size, includes, replace} from 'lodash'
import { withRouter } from 'react-router-dom'
import { CardHeader } from '../../components/layout/CardHeader'
import {showSuccess, showError} from '../../actions/Error'
import Timestamp from '../../components/Timestamp'
import { Container, Row, Col } from 'react-bootstrap'
import ToolTip from '../../components/ToolTip'
import CommonTable from '../../components/CommonTable'
import { AdminQuickLink } from './AdminQuickLink'
import { confirmModal } from '../../actions/ui'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { Error } from '../../components/layout/Error'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { Separator } from '../../components/layout/Separator'
import { LANGUAGES } from '../../actions/language'
import Card from '../../components/layout/Card'
import { LabelValue } from '../../components/layout/LabelValue'
import { BlueButton} from '../../components/layout/BlueButton'
import { Trans, Translation } from 'react-i18next'
import { handleSubmitResult } from '../../actions/form'
import { adminUpgradePathTypes } from '../actions/admin_dropdown_options'
import { adminUpgradePathList } from '../actions/admin_upgrade_path'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment, faCommentSlash } from '@fortawesome/free-solid-svg-icons'
import { globalSettingsList } from '../../actions/settings'
import {default_theme as theme} from "../../emotion/theme"

export const AdminUpgradePathTable = ({customer_id, header_names}) => {

    const dispatch = useDispatch()
    const upgrade_paths = adminUpgradePathList.getVisibleObjects()
    const is_loading = adminUpgradePathList.isLoading() || adminUpgradePathList.getIsSavingObject()
    const upgrade_types_by_id = useSelector(() => adminUpgradePathTypes.getObjectsById())
    const upgradePathSettingsByType = useSelector(() => globalSettingsList.getSetting('upgrade_paths'))

    useEffect(() => {
        dispatch(globalSettingsList.fetchListIfNeeded())
        dispatch(adminUpgradePathTypes.fetchListIfNeeded())
    }, [])
    
    useEffect(() => {
        dispatch(adminUpgradePathList.updateListFilter({customer:customer_id}))
        dispatch(adminUpgradePathList.fetchListIfNeeded())
    }, [customer_id])

    const onRecalculateUpgradePath = (upgrade_path) => {
        const values = {id: upgrade_path.id,
                        action: 'refresh'}
        const on_ok = (json) => {
            adminUpgradePathList.invalidateList()
            adminUpgradePathList.fetchListIfNeeded()
        }
        dispatch(adminUpgradePathList.saveObject(values)).then(on_ok)
    }

    const onUnDismiss = (upgrade_path) => {
        const on_ok = function(json) {
            showSuccess("Saved", "Undismissed")
            dispatch(adminUpgradePathList.invalidateList())
            dispatch(adminUpgradePathList.fetchListIfNeeded())
        }
        upgrade_path.dismissed = false
        dispatch(adminUpgradePathList.saveObject(upgrade_path)).then((res) => handleSubmitResult({res, formik_funcs:{}, on_ok}))
    }
    
    const onDismiss = (upgrade_path) => {
        const on_ok = function(json) {
            showSuccess("Saved", "Dimissed")
            dispatch(adminUpgradePathList.invalidateList())
            dispatch(adminUpgradePathList.fetchListIfNeeded())
        }
        upgrade_path.dismissed = true
        dispatch(adminUpgradePathList.saveObject(upgrade_path)).then((res) => handleSubmitResult({res, formik_funcs:{}, on_ok}))
    }
    
    const headers = {
        customer_email: { name: 'Customer',
                          renderValue: (item) => <AdminQuickLink name="customer" value={item.customer} label={item.customer_email} />
                        },
        customer_first_name: { name: 'First name'},
        customer_last_name: { name: 'Last name'},
        subscription_phone_number: {name: 'Subscription'},
        upgrade_type: { name: 'Type',
                        renderValue: (item) => get(upgrade_types_by_id, [item.upgrade_type, "name"])
                      },
        notify_customer: { name: "Notify customer",
                           renderValue: (item) => <ToolTip info_icon={<FontAwesomeIcon icon={item.notify_customer ? faComment : faCommentSlash} />}>
                                                                            {get(upgradePathSettingsByType, [item.upgrade_type, "tooltips", "notify_customer"])}
                                                                          </ToolTip>
                         },
        dismissed: { name: "Customer visibility choice",
                     renderValue: (item) => <ToolTip info_icon={<FontAwesomeIcon icon={item.dismissed ? faCommentSlash : faComment} />}>
                                              {get(upgradePathSettingsByType, [item.upgrade_type, "tooltips", "dismissed"])}

                                              { item.dismissed && 
                                                <BlueButton onClick={() => onUnDismiss(item)}>Undismiss</BlueButton>
                                              }
                                              { !item.dismissed && 
                                                <BlueButton onClick={() => onDismiss(item)}>Dismiss</BlueButton>
                                              }
                                            </ToolTip>
                   },
        seen_by_customer_at: { name: "Seen at",
                               renderValue: (item) => <Timestamp value={item.seen_by_customer_at} format="datetime" />
                             },
        ready_for_upgrade: { name: "Can upgrade",
                             renderValue: (item) => <ToolTip info_icon={<InlineIcon icon_name={item.ready_for_upgrade ? "tick" : "cross"} />}>
                                                                              {get(upgradePathSettingsByType, [item.upgrade_type, "tooltips", "can_upgrade"])}
                                                                            </ToolTip>
                           },
        is_upgraded: { name: "Is upgraded",
                       renderValue: (item) => <> <ToolTip info_icon={item.is_upgraded ? <InlineIcon icon_name="tick" /> : <InlineIcon icon_name="cross" />}>
                                               {get(upgradePathSettingsByType, [item.upgrade_type, "tooltips", "is_upgraded"])}
                                             </ToolTip>
                                          </>
                     },
        action_refresh: { name: "Recalculate",
                          renderValue: (item) => <BlueButton onClick={() => onRecalculateUpgradePath(item)}>Recalculate</BlueButton>
                        }
    }

    const enabled_headers = fromPairs(map(header_names, (header_name) => [header_name, headers[header_name]]))
    
    return (
          <CommonTable
            is_loading={ is_loading }
            empty_message={`No upgrade paths.`}
            headers={ enabled_headers }
            items={ upgrade_paths }
            item_list={adminUpgradePathList}
          />
    )
    
}
