/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { default_theme as theme } from '../../emotion/theme'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { get, map, size, values } from 'lodash'
import { ButtonBar } from '../../components/layout/ButtonBar'
import Timestamp from '../../components/Timestamp'
import { confirmModal } from '../../actions/ui'
import { Trans, Translation } from 'react-i18next'
import { adminPhoneNumberPrefixSetList } from '../actions/admin_phone_number_prefix_set'
import { adminPhoneNumberPrefixListForSet } from '../actions/admin_phone_number_prefix'
import AdminPhoneNumberPrefixSelector from './AdminPhoneNumberPrefixSelector'
import { adminProductList } from '../actions/admin_product'
import AdminAssociatedProductList from './AdminAssociatedProductList'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormLabelValue } from '../../components/form/FormLabelValue'
import { FormikDropdownField } from '../../components/form/Dropdown'
import FieldInfoText from '../../components/layout/FieldInfoText'
import { PrettyValue } from '../../components/PrettyValue'
import { LabelValue } from '../../components/layout/LabelValue'
import { FormikInputField } from '../../components/form/InputField'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { GrayButton } from '../../components/layout/GrayButton'
import { BlueButton } from '../../components/layout/BlueButton'
import Loading from '../../components/Loading'
import AdminMainLayout from './AdminMainLayout'
import BusyMask from '../../components/BusyMask'
import {showSuccess, showError} from '../../actions/Error'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import { Col, Row, Container, Tabs, Tab, Button, Modal } from 'react-bootstrap'
import Card from '../../components/layout/Card'
import { CardHeader } from '../../components/layout/CardHeader'
import { Form as BootstrapForm } from 'react-bootstrap'
import { FormikTextarea } from '../../components/form/TextareaField'
import { BlueLinkButton } from '../../components/layout/BlueLinkButton'
import { PhoneNumber } from '../../components/PhoneNumber'
import { Separator } from '../../components/layout/Separator'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { handleSubmitResult } from '../../actions/form'
import { adminNumberTypes } from '../actions/admin_dropdown_options'
import * as Yup from 'yup'

const LEFT_FORM_WIDTH = 4
const RIGHT_FORM_WIDTH = 8

const validationSchema = Yup.object().shape({
    'name': Yup.string().required("Required")
})

class AdminNumber extends Component {

    constructor(props) {
        super(props)
        this.state = { is_selecting_phone_number_prefixes: false }
    }
    
    componentDidMount() {
        const { dispatch, prefix_set_id, prefix_set, product_ids } = this.props
        dispatch(adminPhoneNumberPrefixListForSet.updatePaginationNumItemsPerPage(10000))
        dispatch(adminPhoneNumberPrefixSetList.fetchListIfNeeded())
        dispatch(adminPhoneNumberPrefixSetList.ensureObjectLoaded(prefix_set_id))
        dispatch(adminNumberTypes.fetchListIfNeeded())

        if ( prefix_set && prefix_set.phone_number_prefixes ) {
            dispatch(adminPhoneNumberPrefixListForSet.ensureObjectsLoaded(prefix_set.phone_number_prefixes))
        }
        if ( product_ids ) {
            dispatch(adminProductList.ensureObjectsLoaded(product_ids))
        }
    }

    componentDidUpdate() {
        const { dispatch, prefix, prefix_set, prefix_set_id, city_filter, product_ids } = this.props
        dispatch(adminPhoneNumberPrefixSetList.ensureObjectLoaded(prefix_set_id))
        if ( prefix_set && prefix_set.phone_number_prefixes ) {
            dispatch(adminPhoneNumberPrefixListForSet.ensureObjectsLoaded(prefix_set.phone_number_prefixes))
        }
        if ( product_ids ) {
            dispatch(adminProductList.ensureObjectsLoaded(product_ids))
        }
    }

    onSave = (values, formik_funcs) => {
        const { history, dispatch, prefix_set_id } = this.props
        const that = this
        this.setState({sending: true})
        const on_ok = function(json) {
            that.setState({sending: false, res: JSON.stringify(json)})
            dispatch(adminPhoneNumberPrefixSetList.invalidateList())
            showSuccess("Saved", "Prefix set saved")
            if ( ! prefix_set_id ) {
                history.push(`/admin/number_prefix_set/${json.id}`)
            }
        }
        if ( prefix_set_id ) {
            values.id = prefix_set_id
            return dispatch(adminPhoneNumberPrefixSetList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminPhoneNumberPrefixSetList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    onDeletePrefixSet = () => {
        const { dispatch, history, prefix_set_id } = this.props
        const on_ok = function(json) {
            showSuccess("Deleted", "Prefix set has been deleted")
            history.push("/admin/number_prefix_sets")
        }

        const onConfirmed = () => {
            return dispatch(adminPhoneNumberPrefixSetList.deleteObject(prefix_set_id)).then(on_ok)
        }
        
        const text = (<Trans>Are you sure you want to delete this prefix set?</Trans>)
        return dispatch(confirmModal({text:text,
                                      onConfirmed: onConfirmed}))
        
    }

    onShowSelectPhoneNumberPrefixes = () => {
        this.setState({is_selecting_phone_number_prefixes: true})
    }

    onHideSelectPhoneNumberPrefixes = () => {
        this.setState({is_selecting_phone_number_prefixes: false})
    }

    onSelectedPhoneNumberPrefixes = (selected_prefix_ids, formik_props) => {
        formik_props.setFieldValue('phone_number_prefixes', selected_prefix_ids)
        this.onHideSelectPhoneNumberPrefixes()
    }
    
    renderPhoneNumberPrefixSelector(formik_props) {
        return (
            <Modal show={true}
                   size='xl'
                   onHide={this.onHideSelectPhoneNumberPrefixes}>
              <Modal.Header closeButton>
                <Modal.Title>
                  Select number prefixes
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <AdminPhoneNumberPrefixSelector onCancel={this.onHideSelectPhoneNumberPrefixes}
                                                initially_selected_prefix_ids={get(formik_props, ["values", "phone_number_prefixes"])}
                                                onSave={(selected_prefix_ids) => this.onSelectedPhoneNumberPrefixes(selected_prefix_ids, formik_props)} />
              </Modal.Body>
            </Modal>
        )
    }
        
    renderForm(formik_props) {
        const { prefix_set, prefixes_by_id } = this.props
        const phone_number_prefix_ids = get(formik_props, ["values", "phone_number_prefixes"])
        const phone_number_prefixes = map(phone_number_prefix_ids, (x) => prefixes_by_id[x])

        return (
            <Card variant="white_wide_padding">

              <Row>
                <Col md={LEFT_FORM_WIDTH} css={section_name_style}>
                  Name
                </Col>
                <Col md={RIGHT_FORM_WIDTH}>
                  <FormikInputField name="name" />
                </Col>
              </Row>

              <Separator variant="h40" />
              
              <Row>
                <Col md={LEFT_FORM_WIDTH} css={section_name_style}>
                  Number prefixes
                </Col>
                <Col md={RIGHT_FORM_WIDTH}>
                  <GrayButton onClick={this.onShowSelectPhoneNumberPrefixes}>
                    Select prefixes
                  </GrayButton>
                  <Separator variant="h15" />
                  {size(phone_number_prefixes) === 0 && "No prefixes selected"}
                  {map(phone_number_prefixes, (prefix, index) =>
                      <div>
                        <Row key={index}>
                          <Col md="4">
                            <PhoneNumber phone_number={ get(prefix, "prefix", "-") } />
                          </Col>
                          <Col md="4">
                            {get(prefix, "country_name", "-")}
                          </Col>
                          <Col md="4">
                            {get(prefix, "city_name", "-")}
                          </Col>
                        </Row>
                        <Separator variant="h5" />
                      </div>
                  )}
                </Col>
              </Row>

              <Row>
                <Col md={{offset:11}}>
                  <BlueButton type="submit">
                    Save
                  </BlueButton>
                </Col>
              </Row>
            </Card>
        )
    }

    renderAssociatedProducts() {
        const { products } = this.props
        return <AdminAssociatedProductList products={products} label="prefix set" />
    }
    
    render() {
        const { prefix_set_id, is_busy, is_loading, prefix_set, initial_values, product_ids } = this.props
        const { is_selecting_phone_number_prefixes } = this.state
        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'number_type', label: 'Number prefix sets', url: '/admin/number_prefix_sets'},
                                           {name: 'prefix', label: get(prefix_set, "name"), url: `/admin/number_prefix_set/${prefix_set_id}`}]}>
              { is_busy && <BusyMask /> }
              <WrappingBusyMask is_loading={is_loading}>
                { !is_loading &&
                  <Container fluid>
                    <Row>
                      <Col md="8">
                        <div css={header_style}>
                          <h1>
                            Prefix set: {prefix_set_id ? get(prefix_set, "name") : "New"}
                          </h1>
                          { prefix_set_id && size(product_ids) === 0 && 
                            <WhiteButton onClick={this.onDeletePrefixSet} extra_css={delete_prefix_set_button_style}>Delete prefix set</WhiteButton>
                          }
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="8">

                        <Formik
                          initialValues={initial_values}
                          onSubmit={this.onSave}
                          enableReinitialize={true}
                          validationSchema={validationSchema}
                        >
                          {formik_props => {
                              const { values } = formik_props
                              return (
                                  <Form>
                                    { is_selecting_phone_number_prefixes && this.renderPhoneNumberPrefixSelector(formik_props) }
                                    <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                                    <Row>
                                      <Col md="12">
                                        { this.renderForm(formik_props) }
                                      </Col>
                                    </Row>
                                  </Form>
                              )
                          }}
                        </Formik>
                        
                      </Col>

                      <Col md="4">
                        { this.renderAssociatedProducts() }
                      </Col>
                      
                    </Row>
                    
                    
                  </Container>
                }
              </WrappingBusyMask>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const prefix_set_id = get(props, ["match", "params", "phone_number_prefix_set_id"], null)
    const prefix_set = prefix_set_id && adminPhoneNumberPrefixSetList.getObject(prefix_set_id)
    const prefixes_by_id = adminPhoneNumberPrefixListForSet.getObjectsById()
    const product_ids = get(prefix_set, "products_in_order")
    const products = product_ids && adminProductList.getObjects(product_ids)
    
    return {
        prefix_set_id,
        prefix_set,
        prefixes_by_id,
        is_loading: adminPhoneNumberPrefixSetList.isLoading(),
        is_busy: adminPhoneNumberPrefixSetList.getIsSavingObject(),
        initial_values: prefix_set || {},
        product_ids,
        products
    }
}
export default withRouter(connect(mapStateToProps)(AdminNumber))

const header_style = css`
display: flex;
justify-content: space-between;
`

const usage_total_row_style = css`
font-size: 16px;
line-height: 18px;
display: flex;
align-items: baseline;
margin-bottom: 15px;
`

const usage_total_style = css`
font-weight: 500;
font-size: 18px;
margin-right: 5px;
`

const delete_prefix_set_button_style = css`
color: ${theme.colors.dark_middle_grey};
`

const section_name_style = css`
font-weight: 500;
font-size: 18px;
`
