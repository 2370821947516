/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { jsx, css, Global } from '@emotion/react'
import { default_theme as theme } from '../emotion/theme'
import LoadingOverlay from './layout/loading_overlay/LoadingOverlay'
import Loading from './Loading'
import { Trans } from 'react-i18next'
import loading_icon from '../images/loading.gif'

class NestedWrappingBusyMask extends Component {

    render() {
        const { children, is_loading } = this.props
        return (
            <LoadingOverlay active={is_loading}
                            spinner={<img css={loading_icon_style} src={loading_icon} alt="loading"/>}
                            text={null}
                            styles={{
                                wrapper: (base) => ({
                                    ...base,
                                    width: "100%",
                                }),
                                content: (base) => ({
                                    ...base,
                                    color: "#000"
                                }),
                                overlay: (base) => ({
                                    ...base,
                                    backgroundColor: theme.colors.very_light_grey,
                                    position: "absolute"
                                }),
                                spinner: (base) => ({
                                    ...base,
                                    borderRadius: "4px",
                                    backgroundColor: "transparent"
                                }),
                            }}
            >
              { children }
              { is_loading && <div>&nbsp;</div> }
            </LoadingOverlay>
        )
    }
}
export default NestedWrappingBusyMask

const loading_icon_style = css`
height: 20px;
`
