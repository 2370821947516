import { ItemList } from '../orm'
import { download } from './lib'

class InvoiceList extends ItemList {
    getEntityKey() {
        return "invoice"
    }

    download(invoice_id) {
        const url = `invoice/${invoice_id}/download/`
        return download(url)
    }

}

export const invoiceList = new InvoiceList("invoice__default")
