import { ItemList } from '../../orm'
import { download } from '../../actions/lib'

class AdminCustomerSubscriptionManualReservationList extends ItemList {
    getEntityKey() {
        return "admin/customer_subscription_manual_reservation"
    }

    downloadPortingInvoiceFile(id) {
        const url = `admin/customer_subscription_manual_reservation/${id}/download_porting_invoice_file/`
        return download(url)
    }

    uploadPortingInvoiceFile({id, file_info}) {
        return this.saveObject({id, porting_invoice_file_info: file_info})
    }

    onPortingComplete({id, new_number}) {
        return this.saveObject({id, new_number})
    }
    
}

export const adminCustomerSubscriptionManualReservationList = new AdminCustomerSubscriptionManualReservationList("admin_customer_subscription_manual_reservation__default")
export const adminCustomerSubscriptionManualReservationForSubscription = new AdminCustomerSubscriptionManualReservationList("admin_customer_subscription_manual_reservation_for_subscription__default")

export const invalidateAllAdminCustomerSubscriptionManualReservationList = () => {
    return async(dispatch, getState) => {
        dispatch(adminCustomerSubscriptionManualReservationList.invalidateList())
        dispatch(adminCustomerSubscriptionManualReservationForSubscription.invalidateList())
    }
}
