/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react'
import { jsx, css } from '@emotion/react'
import loading_img from '../../../../images/loading3.gif'

const DELAY_MILLISECONDS_BEFORE_SHOWING_SPINNER = 1000

const Spinner = ({ getStyles, cx }) => {

    const [ showTimer, setShowTimer ] = useState(false)

    useEffect(() => {
        setTimeout(function() {
            setShowTimer(true)
        }, DELAY_MILLISECONDS_BEFORE_SHOWING_SPINNER)
    })

    return (
        <>
          { showTimer &&
          <div className={cx('spinner', css(getStyles('spinner')))}>
            <img css={spinner_img_style} src={loading_img} />
          </div>
          }
        </>
    )
}

export default Spinner

const spinner_img_style = css`
  height: 50px;
`
