import { ItemList } from '../orm'
import { v4 as uuidv4 } from 'uuid'
import { head, filter } from 'lodash'

class BlockedNumberList extends ItemList {
    getEntityKey() {
        return "blocked_number"
    }

    tryGetBlockedPhoneNumber({phone_number_id, customer_subscription_id}) {
        return head(filter(this.getVisibleObjects(), (blocked_number) => blocked_number && (blocked_number.blocked_phone_number === phone_number_id && blocked_number.customer_subscription === customer_subscription_id)))
    }

    blockNumber({phone_number_id, subscription_id}) {
        return this.saveNewObject({blocked_phone_number: phone_number_id, customer_subscription: subscription_id})
    }

    unblockNumber({blocked_number_id}) {
        return this.deleteObject(blocked_number_id)
    }

    
}

export const blockedNumberList = new BlockedNumberList("blocked_number__default")
