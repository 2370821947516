/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { map, get, size, forEach } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import { NewSubscriptionCard } from './NewSubscriptionCard'
import { CardHeader } from './layout/CardHeader'
import { default_theme as theme } from '../emotion/theme'
import { Separator } from './layout/Separator'
import MainLayout from './MainLayout'
import { Row, Col, Container, Popover, OverlayTrigger, Button } from 'react-bootstrap'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    product: Yup.string().required("Required")
})

export const NewVoiceSubscription = ({...props}) => {
    const initial_product_short_id = get(props, ["match", "params", "product_short_id"])
    return (
        <MainLayout active_key="subscriptions"
                    breadcrumbs={[{name: 'home'},
                                  {name: 'subscriptions', label: 'Subscriptions', url: '/subscriptions'},
                                  {name: 'new_voice_subscription', label: 'Add a voice number', url: '/subscription/voice'}]}>
          <Container fluid>

            <Separator variant="h20" />

              <Row>
                <Col md={12}>
                  <CardHeader title="Add a voice number" />
                </Col>
              </Row>
            
            <NewSubscriptionCard initial_product_short_id={initial_product_short_id}
                                 product_filter={{can_receive_voice: true,
                                                 is_fax_related: null}} />
            
          </Container>
        </MainLayout>

    )
}
