/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { map, head, compact, filter, get, size } from 'lodash'
import { FormLabelValue } from '../../components/form/FormLabelValue'
import { FormikDropdownField } from '../../components/form/Dropdown'
import NestedWrappingBusyMask from '../../components/NestedWrappingBusyMask'
import { Col, Row, Container } from 'react-bootstrap'
import CardBlackInfoText from '../../components/layout/CardBlackInfoText'
import { NewSubscriptionCostSupplement } from '../../components/NewSubscriptionCostSupplement'
import { InlineIcon } from '../../components/layout/InlineIcon'
import CardInfoText from '../../components/layout/CardInfoText'
import Card from '../../components/layout/Card'
import ReactTimeout from 'react-timeout'
import { adminCustomerSubscriptionManualReservationOptions } from '../actions/admin_dropdown_options'
import { FormikFileUploadField } from '../../components/form/FileUploader'
import { default_theme as theme } from '../../emotion/theme'
import { PhoneNumber } from '../../components/PhoneNumber'
import { Separator } from '../../components/layout/Separator'
import { FormikRadioGroupField } from '../../components/form/RadioGroupField'
import { TraditionalRadioGroupField } from '../../components/form/TraditionalRadioGroupField'
import { SubscriptionPhoneNumberSelector } from '../../components/SubscriptionPhoneNumberSelector'
import { adminCustomerSubscriptionManualReservationForSubscription } from '../actions/admin_customer_subscription_manual_reservation'
import { adminCustomerSubscriptionForCustomerList } from '../actions/admin_customer_subscription'
import { confirmModal } from '../../actions/ui'
import { subscriptionNumberChoiceList } from '../../actions/subscription_number_choices'
import { phoneNumberPrefixList } from '../../actions/phone_number_prefix'
import { FormikInputField } from '../../components/form/InputField'
import { BlueButton } from '../../components/layout/BlueButton'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { RedButton } from '../../components/layout/RedButton'
import { showSuccess, showError } from '../../actions/Error'
import { Error } from '../../components/layout/Error'
import { handleSubmitResult } from '../../actions/form'
import { productCostCalculator } from '../../actions/product_cost_calculator'

export const NUMBER_SELECTION_TYPE_OPTIONS = [ {label: "Current", value: "default"},
                                               {label: "Select", value: "select"},
                                               {label: "Search", value: "search"} ]

const SEARCH_DELAY_MILLISECONDS = 1500

const AdminSubscriptionPhoneNumberSelector = ({customer, customer_subscription, product, formik_props, clearTimeout, onPaymentCostCalculated,  setTimeout, ...props }) => {

    const dispatch = useDispatch()

    const [manuallySearchingPhoneNumber, setManuallySearchingPhoneNumber] = useState(false)
    const [manuallySearchedPhoneNumber, setManuallySearchedPhoneNumber] = useState({phone_number: null,
                                                                                    available_for_reservation: false,
                                                                                    reserved: false})
    const [overridingReservationSearch, setOverridingReservationSearch] = useState(false)
    const [lastManuallySearchForPhoneNumberResult, setLastManuallySearchForPhoneNumberResult] = useState(null)
    const [searchTimeoutId, setSearchTimeoutId] = useState(null)
    const number_selection_type = get(formik_props, ["values", "number_selection_type"])
    const phone_number_prefixes = useSelector(() => phoneNumberPrefixList.isReady() ? phoneNumberPrefixList.getAllObjects() : null)
    
    const phone_number_selector_params = {extra_find_and_reserve_params: {customer: customer.id},
                                          renderSelectNumberByAdmin: () => renderSelectNumberByAdmin()}
    
    const [numberSelectionTypeOptions, setNumberSelectionTypeOptions] = useState(null)
    const reservation = useSelector(() => head(customer_subscription.id ? adminCustomerSubscriptionManualReservationForSubscription.getVisibleObjects() : null))
    const [onManuallyReservedNumber, setOnManuallyReservedNumber] = useState(null)
    const customer_subscription_manual_reservation_options = useSelector(()=>adminCustomerSubscriptionManualReservationOptions.getObjectsById())
    const [paymentCostCalculationId, setPaymentCostCalculationId] = useState(null)
    const paymentCostCalculation = paymentCostCalculationId && productCostCalculator.getObject(paymentCostCalculationId)
    const isCalculatingPaymentCost = productCostCalculator.getIsSavingObject()
    const porting_new_subscription = get(formik_props, ["values", "number_source"]) === "port"

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(adminCustomerSubscriptionManualReservationOptions.updatePaginationNumItemsPerPage(1000))
            dispatch(adminCustomerSubscriptionManualReservationOptions.fetchListIfNeeded())
        }
        fetchStaticObjects()
    }, [])

    useEffect(() => {
        onPaymentCostCalculated(paymentCostCalculation)
    }, [get(paymentCostCalculation, "id")])

    useEffect(() => {
        const values = Object.assign({}, formik_props.values)
        values.customer = customer.id
        values.product = product.id
        dispatch(productCostCalculator.calculatePaymentCost(values))
        setPaymentCostCalculationId(values.calculation_id)
        
    }, [get(formik_props.values, ["phone_number"]),
        get(formik_props.values, ["product"]),
        get(formik_props.values, ["number_source"]),
        get(formik_props.values, ["number_type"]),
        get(formik_props.values, ["number_is_allocated_by_admin"]),
        get(formik_props.values, ["allocation_method"]),
        get(formik_props.values, ["city"]),
        get(formik_props.values, ["country"]),
       ])
    
    useEffect(() => {
        const options = Object.assign([], NUMBER_SELECTION_TYPE_OPTIONS)
        if ( product.supports_number_porting ) {
            options.push({label: "Number porting", value: "port"})
        }
        setNumberSelectionTypeOptions(options)
        
    }, [product])
    
    useEffect(() => {
        const options = Object.assign([], NUMBER_SELECTION_TYPE_OPTIONS)
        if ( customer_subscription.was_number_ported ) {
            formik_props.setFieldValue("number_selection_type", "port")
        }
    }, [customer_subscription.status_name])

    useEffect(() => {
        if ( adminCustomerSubscriptionManualReservationForSubscription.getFilter().customer_subscription !== customer_subscription.id ) {
            dispatch(adminCustomerSubscriptionManualReservationForSubscription.updateListFilter({customer_subscription: customer_subscription.id, active: true}))
        }
        dispatch(adminCustomerSubscriptionManualReservationForSubscription.fetchListIfNeeded())
    }, [customer_subscription])
    
    const onManuallyReserveNumber = (formik_props) => {
        const onReserved = (json) => {
            if ( json.status == 'success' ) {
                showSuccess("Number reserved")
                const phone_number = json.phone_number
                setManuallySearchedPhoneNumber({phone_number: phone_number,
                                                available_for_reservation: false,
                                                reserved: true})
                formik_props.setFieldValue("phone_number", phone_number.id)
                setManuallySearchingPhoneNumber(false)

                if ( onManuallyReservedNumber ) {
                    onManuallyReservedNumber(phone_number)
                }
                
            } else {
                showError("Failed to reserve", json.error_msg)
            }
        }

        dispatch(subscriptionNumberChoiceList.reserveNumber({phone_number: manuallySearchedPhoneNumber.phone_number.number,
                                                             customer: customer.id,
                                                             product_id: product.id}))
            .then(onReserved)
    }

    const onSearchForNumber = (formik_props, phone_number) => {
        const onAdded = (json) => {
            setLastManuallySearchForPhoneNumberResult(json)
            if ( json.status == 'success' ) {
                const phone_number = json.phone_number
                setManuallySearchedPhoneNumber({phone_number: phone_number,
                                                available_for_reservation: true,
                                                reserved: false})
            }
        }
        dispatch(subscriptionNumberChoiceList.searchNumber({phone_number: phone_number, product_id: product.id})).then(onAdded)
    }

    const onSearchForNumberUpdated = (formik_props, el) => {
        const that = this
        const phone_number = el.target.value

        setManuallySearchedPhoneNumber({phone_number: null,
                                        available_for_reservation: false,
                                        reserved: false})
        
        if ( searchTimeoutId ) {
            clearTimeout(searchTimeoutId)
            setSearchTimeoutId(null)
        }
        setSearchTimeoutId(setTimeout(function() {
            onSearchForNumber(formik_props, phone_number)
        }, SEARCH_DELAY_MILLISECONDS))
    }

    const onPortingApprove = () => {
        formik_props.setFieldValue("phone_number", reservation.phone_number)
    }

    const onPortingUnapprove = () => {
        formik_props.setFieldValue("phone_number", undefined)
    }

    const onDownloadPortingInvoiceFile = () => {
        adminCustomerSubscriptionManualReservationForSubscription.downloadPortingInvoiceFile(reservation.id)
    }

    const onPortingInvoiceFileUploaded = (file_info) => {

        const on_ok = function(json) {
            adminCustomerSubscriptionManualReservationForSubscription.invalidateObject(reservation.id)
            adminCustomerSubscriptionManualReservationForSubscription.ensureObjectLoaded(reservation.id)
        }
        
        dispatch(adminCustomerSubscriptionManualReservationForSubscription.uploadPortingInvoiceFile({id: reservation.id,
                                                                                                     file_info: file_info.id}))
            .then((res) => handleSubmitResult({res, on_ok}))
    }

    const onRemovePortingInvoiceFile = () => {
        if ( ! window.confirm("Are you sure you want to delete this invoice file?" ) ) {
            return
        }
        dispatch(adminCustomerSubscriptionManualReservationForSubscription.uploadPortingInvoiceFile({id: reservation.id,
                                                                                                     file_info: null}))
            .then(() => {
                adminCustomerSubscriptionManualReservationForSubscription.invalidateObject(reservation.id)
                adminCustomerSubscriptionManualReservationForSubscription.ensureObjectLoaded(reservation.id)
            })
    }

    const onChosenNumberToPortSubscription = (phone_number) => {
        formik_props.setFieldValue("number_source", "port")
        formik_props.setFieldValue("phone_number", phone_number.id)
    }
        
    const onChooseNumberForPorting = () => {
        setManuallySearchingPhoneNumber(true)
        setOnManuallyReservedNumber(() => onChosenNumberToPortSubscription)
        formik_props.setFieldValue("setup_price_excluding_vat_euros", product.number_porting_setup_price_excluding_vat_euros)
    }

    const onUnChooseNumberForPorting = () => {
        formik_props.setFieldValue("number_source", undefined)
        formik_props.setFieldValue("phone_number", undefined)
        formik_props.setFieldValue("setup_price_excluding_vat_euros", product.setup_price_excluding_vat_euros)
        setManuallySearchedPhoneNumber(undefined)
    }

    const renderSelectNumberByAdmin = (res) => {
        return (
            <Card variant="white">
              <CardBlackInfoText>
                <Separator variant="h10" />
                A matching phone number cannot be chosen from a list, please use the Search tab to finalise this process.
                <br/>
                Possible reasons are:
                <ul>
                  <li>No number ranges overlap the number prefixes in this product</li>
                  <li>The matching prefix is specifically set to admin allocation</li>
                  <li>All numbers have been used up in the matching number ranges</li>
                  <li>An error occurred during reservation (you should have seen a red popup in this case)</li>
                </ul>
              </CardBlackInfoText>
            </Card>
        )
    }

    const renderInvoiceFilePlaceholder = () => {
        return (
            <div css={invoice_file_placeholder_style}>
              Upload invoice
            </div>
        )
    }
    
    const renderPortingPhoneNumberForNewSubscription = () => {

        return (
            <div>
              <Separator variant="w10" />
              { get(manuallySearchedPhoneNumber, ["phone_number", "number"]) &&
                <div>
                  Number to be ported: <PhoneNumber phone_number={get(manuallySearchedPhoneNumber, ["phone_number", "number"])} />
                  <br/>
                  The number will not be ported to the subscription yet, after saving this subscription you will be able to optionally upload an invoice and finalise the porting.
                  <Separator variant="h20" />
                  <WhiteButton onClick={onChooseNumberForPorting} auto_disable={false}>
                    Change number
                  </WhiteButton>
                  
                </div>
              }

              { ! get(formik_props, ["values", "phone_number"]) &&
                <>
                  <CardInfoText>
                    Choose a number that is to be ported.
                    <br/>
                  </CardInfoText>
                  <WhiteButton onClick={onChooseNumberForPorting} auto_disable={false}>
                    Choose number
                  </WhiteButton>
                </>
              }

              { get(formik_props, ["values", "number_source"]) === "port" &&
                <div>
                  <WhiteButton onClick={onUnChooseNumberForPorting} auto_disable={false}>
                    Un-select for porting
                  </WhiteButton>
                </div>
              }
              
            </div>
        )
    }

    const renderPortANumberOtherChoice = (formik_props) => {
        return (
            
            <div>
              <CardInfoText>
                This subscription is currently selected for porting, click below to re-enable other number options.
                <br/>
              </CardInfoText>
              <WhiteButton onClick={onUnChooseNumberForPorting}>
                Un-select for porting
              </WhiteButton>
            </div>
        )
    }

    const renderPortExistingNonPortedSubscription = (formik_props) => {
        return (
            <div>
              <CardInfoText>
                Converting existing subscriptions to number portability is not supported.
                <br/>
                Cancel this subscription and create a new one if required.
                <br/>
              </CardInfoText>
            </div>
        )
    }
    
    const renderPortANumber = (formik_props) => {

        const is_approved = get(formik_props, ["values", "phone_number"])

        if ( ! customer_subscription.id ) {
            return renderPortingPhoneNumberForNewSubscription()

        } else {
            return (
                <div>
                { customer_subscription.phone_number &&
                  <>
                    <CardInfoText>
                      This subscription has been ported.
                      <br/>
                      No further action is required.
                      <br/>
                    </CardInfoText>
                    Number <PhoneNumber phone_number={get(reservation, "number")} />
                  </>
                }
                  { ! customer_subscription.phone_number &&
                    <>
                      <CardInfoText>
                        This subscription requires a number porting into XOIP. Click 'approve' button to indicate that the porting is complete.
                        <br/>
                        The porting will only be finalised when saving this subscription.
                        <br/>
                        <br/>
                        If you do not approve this porting, then you can still save the subscription without affecting the porting status.
                        <br/>
                        <br/>
                        If you would like to allocate the number in a different way, change the allocation method with the options above.
                        Note that this will be an unusual circumstance and would need to be communicated with the customer.
                      </CardInfoText>
                      <div>
                        Phone number to port: <PhoneNumber phone_number={get(reservation, "number")} />
                      </div>

                    </>
                  }
                  
                  { reservation && 
                    <Row>
                      <Col md={4}>Customer invoice file</Col>
                      <Col>
                        { get(reservation, "porting_invoice_file_info") &&
                          <div css={invoice_file_row_style}>
                            <span>{reservation.porting_invoice_file_info_details.visible_filename}</span>
                            <Separator variant="w10" />
                            <WhiteButton onClick={() => onDownloadPortingInvoiceFile()}>
                              Download
                            </WhiteButton>
                            <Separator variant="w10" />
                            <div css={remove_invoice_file_style} onClick={() => onRemovePortingInvoiceFile()}>
                              <InlineIcon icon_name="remove" />
                            </div>
                          </div>
                        }

                        { !get(reservation, "porting_invoice_file_info") &&
                          <div>
                            <div>No invoice file has been uploaded yet</div>

                            <FormikFileUploadField name="porting_invoice_file_info"
                                                   css_classes={ {container: invoice_file_uploader_container_style,
                                                                  dropzone_container: dropzone_container_style} }
                                                   formik_props={null}
                                                   upload_relative_url="file_info/"
                                                   onFileUploaded={(file_info) => onPortingInvoiceFileUploaded(file_info)}
                                                   renderPlaceHolder={renderInvoiceFilePlaceholder}
                            />
                          </div>
                        }
                        
                      </Col>
                    </Row>
                  }

                  <Separator variant="h30" />

                  { ! customer_subscription.phone_number &&
                    <>
                      { !is_approved && 
                        <BlueButton onClick={onPortingApprove}>
                          Approve
                        </BlueButton>
                      }

                      { is_approved &&
                        <div>
                          Approved.
                          { ! customer_subscription.phone_number &&
                            <div>Save this subscription to complete the porting.</div>
                          }
                          <br/>
                          <WhiteButton onClick={onPortingUnapprove}>
                            Unapprove
                          </WhiteButton>
                        </div>
                      }
                    </>
                  }

                </div>
            )
        }
    }

    const renderSearchANumberModal = (formik_props) => {
        return (
            <Modal show={true}
                   size="lg"
                   onHide={() => setManuallySearchingPhoneNumber(false)} >

              <Modal.Header closeButton>
                <Modal.Title>
                  Reserve a number
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                
                Enter a number to reserve.

                <CardInfoText>
                  It must be allowed as part of this product based on number prefixes, but need not belong to any available number block.
                </CardInfoText>
                <Separator variant="h10" />
                <FormikInputField name="number_to_search_for"
                                  placeholder="Phone number"
                                  onChange={(el) => onSearchForNumberUpdated(formik_props, el)} />

                { lastManuallySearchForPhoneNumberResult && get(lastManuallySearchForPhoneNumberResult, "status") !== "success" &&
                  <Error>
                    <Separator variant="h10" />
                    {lastManuallySearchForPhoneNumberResult.original_number} is not available to reserve
                    <br/>
                    {lastManuallySearchForPhoneNumberResult.error_msg}
                  </Error>
                }
                
                { get(manuallySearchedPhoneNumber, "available_for_reservation") &&
                  (
                      <div>
                        <Separator variant="h10" />
                        <BlueButton onClick={() => onManuallyReserveNumber(formik_props)} auto_disable={false}>Reserve</BlueButton>
                      </div>
                  )
                }
              </Modal.Body>
            </Modal>
        )
    }

    const renderSelectANumber = (formik_props) => {
        const prefix = get(reservation, "phone_number_prefix_details")
        return (
            <>
              { prefix &&
                <CardInfoText>
                  A reservation exists for this number, therefore the customer is expecting a number of a certain type and prefix to be selected.
                  You can change this reservation 
                  <br/>

                  { ! overridingReservationSearch &&
                    <WhiteButton onClick={() => setOverridingReservationSearch(true)}>
                      Change reservation
                    </WhiteButton>
                  }
                  { overridingReservationSearch &&
                    <RedButton onClick={() => setOverridingReservationSearch(false)}>
                      Cancel changes
                    </RedButton>
                  }
                  
                  <Separator variant="h10" />
                </CardInfoText>
              }
            { (!prefix || overridingReservationSearch) && 
              <SubscriptionPhoneNumberSelector formik_props={formik_props}
                                               admin_based_params = {phone_number_selector_params}
                                               field_name="phone_number"
                                               customer_subscription={customer_subscription}
                                               product={product}/>
            }
            </>
        )
    }
    
    const renderSearchANumber = (formik_props) => {
        const manually_searched_number_is_selected = get(formik_props, ["values", "phone_number"]) === 
              get(manuallySearchedPhoneNumber, ["phone_number", "id"])

        return (
            <>
              <CardInfoText>
                Assign a specific phone number to the account. This is useful for the following cases:
                <ul>
                  <li>Where a new subscription is being created for a specific non-ported number</li>
                  <li>Where a new subscription for a particular prefix has been requested by the user (in this case you can also use the yellow box at the top of this page)</li>
                </ul>
              </CardInfoText>

              { manuallySearchedPhoneNumber.reserved && manually_searched_number_is_selected &&
                <>
                  <Separator variant="h10" />
                  <div>Reserved : {manuallySearchedPhoneNumber.phone_number.number}</div>
                </>
              }
              <Separator variant="h10" />
              <div>
                <BlueButton onClick={() => setManuallySearchingPhoneNumber(true)} auto_disable={false}>
                  { manually_searched_number_is_selected && "Select a different number" }
                  { !manually_searched_number_is_selected && "Select a number" }
                </BlueButton>
              </div>

            </>
        )
    }

    const renderCustomerSubscriptionManualReservation = () => {
        if (! reservation ) {
            return null
        }

        const prefix = get(reservation, "phone_number_prefix_details")
        
        return (
            <CardInfoText>
              A reservation exists for this subscription, which means a phone assignment of some type is pending.
              Both the type of assignment and the phone number itself can be changed here,
              but note that unless otherwise specified you will have to communicate these changes to the customer directly.
              <Separator variant="h20" />
              <Row>
                <Col md={3}>Reservation status</Col>
                <Col>{get(customer_subscription_manual_reservation_options, [reservation.status, "name"])}</Col>
              </Row>
              { reservation.phone_number_prefix &&
                <Row>
                  <Col md={3}>Prefix</Col>
                  <Col>
                    <Row>
                      <Col>
                        Number type: {get(prefix, "number_type", "na")}
                        { get(customer_subscription, "was_number_ported") &&
                          <span>&nbsp;(number porting)</span>
                        }
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        Country: {get(prefix, "country_name", "na")}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        City: {get(prefix, "city_name", "na")}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        Prefix: {get(prefix, "prefix", "na")}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        { get(prefix, "number_source") === "new" && <span>Assigning a number from XOIP intentory</span> }
                        { get(prefix, "number_source") === "porting" && <span>Porting an existing number: {get(prefix, "number")}</span> }
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }
              <Separator variant="h20" />
            </CardInfoText>
        )
    }

    const renderProductCost = () => {
        return (
            <NestedWrappingBusyMask is_loading={isCalculatingPaymentCost}>
              { paymentCostCalculation && paymentCostCalculation.total_supplement_cost_excluding_vat_euros > 0 && 
                <>
                  {map(paymentCostCalculation.supplements, (supplement_info) => (
                      <>
                        <Separator variant="h20" />
                        <NewSubscriptionCostSupplement label={supplement_info.label} cost={supplement_info.price_per_year_excluding_vat_euros} cost_period="year" />
                      </>
                  ))}
                </>
              }
            </NestedWrappingBusyMask>
        )
    }

    return (
        
        <div>
          { renderCustomerSubscriptionManualReservation() }

          <FormikRadioGroupField name="number_selection_type"
                                 formik_props={formik_props}
                                 options={numberSelectionTypeOptions} />

          { manuallySearchingPhoneNumber && renderSearchANumberModal(formik_props) }
          
          { number_selection_type === 'port' &&
            <>
              { customer_subscription.id &&
                <>
                  {customer_subscription.was_number_ported && renderPortANumber(formik_props) }
                  {!customer_subscription.was_number_ported && renderPortExistingNonPortedSubscription(formik_props) }
                </>
              }
              { !customer_subscription.id && renderPortingPhoneNumberForNewSubscription(formik_props) }
            </>
          }

          { number_selection_type !== 'port' &&
            <>
              { porting_new_subscription && renderPortANumberOtherChoice(formik_props) }

              { ! porting_new_subscription &&
                <>
                  
                  <Separator variant="20" />
                  { number_selection_type === 'default' &&
                    <div>
                      Current subscription number:
                      { customer_subscription.phone_number_number &&  <PhoneNumber phone_number={customer_subscription.phone_number_number} /> }
                      { !customer_subscription.phone_number_number &&  <span>No number</span> }
                      <Separator variant="h10" />
                    </div>
                  }
                  
                  { number_selection_type === 'select' && renderSelectANumber(formik_props) }

                  { number_selection_type === 'search' && renderSearchANumber(formik_props) }
                  
                </>
              }
              
            </>
          }

          { renderProductCost() }
          
        </div>
    )
}

export default ReactTimeout(AdminSubscriptionPhoneNumberSelector)
 

const invoice_file_row_style = css`
display: flex;
`

const invoice_file_placeholder_style = css`
`
        
const dropzone_container_style = css`
width: 100%;
`
    
const invoice_file_uploader_container_style = css`
width: 180px;
height: 38px;
border: solid 1px ${theme.colors.light_middle_grey};
background-color: ${theme.colors.white};
border-radius: 4px;
padding: 1px 14.5px;
display: flex;
align-items: center;
text-align: center;
`

const remove_invoice_file_style = css`
cursor: pointer;
margin-top: 5px;
`
