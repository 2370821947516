/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { default_theme as theme } from '../../emotion/theme'
import { head, get, map, keys, split, size } from 'lodash'
import { CardHeader } from '../../components/layout/CardHeader'
import { FormLabelValue } from '../../components/form/FormLabelValue'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { Separator } from '../../components/layout/Separator'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { Formik, Form, FieldArray, Field } from 'formik'
import Loading from '../../components/Loading'
import { FormikTextarea } from '../../components/form/TextareaField'
import { FormikInputField } from '../../components/form/InputField'
import AdminMainLayout from './AdminMainLayout'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import Card from '../../components/layout/Card'
import { Col, Row, Container, Navbar, Nav } from 'react-bootstrap'
import { Form as BootstrapForm } from 'react-bootstrap'
import * as Yup from 'yup'
import {showSuccess, showError} from '../../actions/Error'
import Button from 'react-bootstrap/Button'
import { BlueButton } from '../../components/layout/BlueButton'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { handleSubmitResult } from '../../actions/form'
import { cityList } from '../../actions/city'
import { countryList } from '../../actions/country'

const validationSchema = Yup.object().shape({
})

export const AdminCity = ({match}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const city_id = get(match, ["params", "city_id"], null)
    const city = useSelector(() => cityList.getObject(city_id))
    const country_options = useSelector(() => countryList.getAsOptions())
    const is_busy = useSelector(() => cityList.getIsSavingObject())
    const is_loading = useSelector(() => cityList.isLoading())
    const initial_values = Object.assign({}, city)

    useEffect(() => {
        dispatch(countryList.updatePaginationNumItemsPerPage(1000))
        dispatch(countryList.fetchListIfNeeded())
    }, [])
    
    useEffect(() => {
        dispatch(cityList.ensureObjectLoaded(city_id))
    }, [city_id])

    const onSave = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(cityList.invalidateList())
            showSuccess("Saved", "City saved")
            history.push(`/admin/city/${city_id}`)
        }
        if ( city_id ) {
            values.id = city_id
            return dispatch(cityList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(cityList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const renderForm = (formik_props) => {

        return (
            <Card variant="white_wide_padding">

              <FormLabelValue spacing="h10">
                Name
                <FormikInputField name="name" />
              </FormLabelValue>

              <FormLabelValue spacing="h10">
                Country
                <FormikDropdownField name="country"
                                     formik_props={formik_props}
                                     options={country_options}
                                     placeholder="Country" />
              </FormLabelValue>

              <Row>
                <Col>
                  <BlueButton type="submit">Save</BlueButton>
                </Col>
              </Row>
            </Card>
        )
    }

    return (
        <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                       {name: 'cities', label: 'Cities', url: '/admin/cities'},
                                       {name: 'city', label: city_id ? get(city, "name"): "New", url: `/admin/city/${get(city, "id")}`}]}>
          <Container fluid>
            { is_busy && <BusyMask /> }
            { is_loading && <Loading /> }

            <Separator variant="h30" />
            
            { ! is_loading && 
              <Formik
                initialValues={initial_values}
                onSubmit={onSave}
                enableReinitialize={true}
                validationSchema={validationSchema}
              >
                {formik_props => {
                    const { values } = formik_props
                    return (
                        <Form>
                          <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                          <Row>
                            <Col>
                              <Card>
                                { renderForm(formik_props) }
                              </Card>
                            </Col>
                          </Row>
                        </Form>
                    )}
                }
              </Formik>
            }
          </Container>
        </AdminMainLayout>
    )
}
