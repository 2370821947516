/** @jsxImportSource @emotion/react */
import React from 'react'
import { useParams } from 'react-router-dom'
import { VoiceNumberCallPlan } from '../../components/VoiceNumberCallPlan'
import { adminAnsweringMessageList } from '../actions/admin_answering_message'
import AdminMainLayout from './AdminMainLayout'
import { Container } from 'react-bootstrap'

export const AdminVoiceNumberCallPlan = ({...props}) => {

    const { subscription_id } = useParams()
    
    return (
        <AdminMainLayout title='Customer call plan'
                         breadcrumbs={[{name: 'admin_home'},
                                       {name: 'customer_call_plan_editor', label: 'Call plan', url: `/admin/voice/${subscription_id}/call_plan`}]}>
          <Container fluid >
            <VoiceNumberCallPlan subscription_id={subscription_id}
                                 editing_as_customer={false}
                                 overrideAnsweringMessageList={adminAnsweringMessageList}
                                 {...props}  />
          </Container>
        </AdminMainLayout>
    )
    
}
