/** @jsxImportSource @emotion/react */
import React, {Component} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'
import { jsx, css } from '@emotion/react'

export const PrettyValue = ({ value, ...props }) => {
    var formatter = new Intl.NumberFormat('en-NL')
    const formatted_value = formatter.format(Math.abs(value) || 0)
    return <span>{formatted_value}</span>
}
