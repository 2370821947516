/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { default_theme as theme } from '../emotion/theme'
import { Trans, Translation } from 'react-i18next'
import { size, get, filter } from 'lodash'
import { jsx, css } from '@emotion/react'
import { PendingAssignmentNumbersList } from './PendingAssignmentNumbersList'
import { Row, Col, Button, Modal, Container } from 'react-bootstrap'
import { globalSettingsList } from '../actions/settings'
import { Separator } from './layout/Separator'
import { CardHeader } from './layout/CardHeader'
import SubscriptionCard from './SubscriptionCard'
import { customerSubscriptionList } from '../actions/customer_subscription'
import { customerSubscriptionNumberPendingList } from '../actions/customer_subscription'
import { UpgradePathNotifications } from './UpgradePathNotifications'
import { customerList } from '../actions/customer'
import TryXOIPCard from './TryXOIPCard'
import LoadingNumberCard from './LoadingNumberCard'
import AddNumberCard from './AddNumberCard'
import FaxSubscriptionEditModal from './FaxSubscriptionEditModal'

export const Numbers = ({ value, can_add, ...props }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [showEditModal, setShowEditModal] = useState(false)
  const [hasSubscriptions, setHasSubscriptions] = useState(false)
  const [editingSubscription, setEditingSubscription] = useState(null)
  const customer_can_create_subscriptions = useSelector(
    () =>
      globalSettingsList.getSetting('customer_can_create_subscriptions') ===
      true
  )
  const is_ready = useSelector(() => customerSubscriptionList.isReady())
  const is_loading = useSelector(() => customerSubscriptionList.isLoading())
  const subscriptions = filter(
    useSelector(() => customerSubscriptionList.getVisibleObjects()),
    (x) => get(x, 'phone_number_number')
  )
  const customer = customerList.getCustomer()

  const hasActiveFaxSubscription = get(
    customer,
    'has_active_fax_in_subscription'
  )
  const hasActiveVoiceSubscription = get(
    customer,
    'has_active_voice_in_subscription'
  )
  const hasActiveVoiceAndFaxSubscription =
    hasActiveVoiceSubscription && hasActiveFaxSubscription

  useEffect(() => {
    dispatch(globalSettingsList.ensureGlobalSettingsLoaded())
    dispatch(
      customerSubscriptionList.updateListFilter({
        is_active: true,
      })
    )
    dispatch(customerSubscriptionList.fetchListIfNeeded())
    dispatch(
      customerSubscriptionNumberPendingList.filter_waiting_for_number_assignment(
        { can_receive_voice_or_fax: true }
      )
    )
  }, [])

  const onAddVoiceSubscription = () => {
    history.push('/subscription/voice')
  }
  const onAddFaxSubscription = () => {
    history.push('/subscription/fax')
  }

  const onEditFaxSubscription = (subscription) => {
    setEditingSubscription(subscription)
    setShowEditModal(true)
  }

  const handleModalClose = () => {
    setShowEditModal(false)
    setEditingSubscription(null)
  }

  const renderEmptyNumberList = () => {
    return (
      !is_loading && (
        <>
          <CardHeader
            left_child={null}
            align_with_common_table={true}
            title={<Trans>Get a XOIP number</Trans>}
            right_child={null}
          />
          <Separator variant="h15" />

          <div css={empty_list_style}>
            <TryXOIPCard
              hasActiveFaxSubscription={hasActiveFaxSubscription}
              hasActiveVoiceSubscription={hasActiveVoiceSubscription}
              onAddFaxSubscription={onAddFaxSubscription}
              onAddVoiceSubscription={onAddVoiceSubscription}
            />
          </div>
        </>
      )
    )
  }

  return (
    <Row>
      {subscriptions.length === 0 && !is_loading ? (
        renderEmptyNumberList()
      ) : (
        <>
          <Col
            md={
              !hasActiveVoiceSubscription && subscriptions.length > 0 ? 9 : 12
            }
          >
            <CardHeader
              left_child={null}
              align_with_common_table={true}
              title={<Trans>Your Numbers</Trans>}
              right_child={null}
            />
            <Separator variant="h15" />
            <div css={subscription_cards_list_style}>
              {is_loading ? (
                <div css={subscription_card_full_width_container_style}>
                  <LoadingNumberCard />
                </div>
              ) : (
                <>
                  {subscriptions.map((subscription) => (
                    <div
                      css={
                        hasActiveVoiceSubscription
                          ? subscription_card_full_width_container_style
                          : subscription_card_column_container_style
                      }
                      key={subscription.id}
                    >
                      <SubscriptionCard
                        subscription={subscription}
                        onEditFaxSubscription={onEditFaxSubscription}
                      />
                    </div>
                  ))}
                  <div
                    css={
                      hasActiveVoiceSubscription
                        ? subscription_card_full_width_container_style
                        : subscription_card_column_container_style
                    }
                  >
                    <AddNumberCard
                      hasActiveVoiceAndFaxSubscription={
                        hasActiveVoiceAndFaxSubscription
                      }
                      hasActiveFaxSubscription={hasActiveFaxSubscription}
                      hasActiveVoiceSubscription={hasActiveVoiceSubscription}
                      onAddFaxSubscription={onAddFaxSubscription}
                      onAddVoiceSubscription={onAddVoiceSubscription}
                    />
                  </div>
                </>
              )}
            </div>
            {size(customerSubscriptionNumberPendingList.getVisibleObjects()) >
              0 && (
              <>
                <Separator variant="h15" />
                <PendingAssignmentNumbersList
                  itemList={customerSubscriptionNumberPendingList}
                />
              </>
            )}
            <UpgradePathNotifications display_style="voice_numbers_bottom" />
            <Separator variant="h50" />
          </Col>
          {subscriptions.length > 0 && !hasActiveVoiceSubscription && (
            <Col md={3}>
              <div css={try_xoip_card_container}>
                <TryXOIPCard
                  hasActiveFaxSubscription={hasActiveFaxSubscription}
                  hasActiveVoiceSubscription={hasActiveVoiceSubscription}
                  onAddFaxSubscription={onAddFaxSubscription}
                  onAddVoiceSubscription={onAddVoiceSubscription}
                />
              </div>
            </Col>
          )}
        </>
      )}
      {showEditModal && editingSubscription && (
        <FaxSubscriptionEditModal
          show={showEditModal}
          subscription={editingSubscription}
          onClose={handleModalClose}
        />
      )}
    </Row>
  )
}

const subscription_cards_list_style = css`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 16px;
`

const subscription_card_full_width_container_style = css`
  max-width: 340px;
  flex-basis: 23%;

  /* Media query for pc screens */
  @media (max-width: 1024px) {
    max-width: 31%;
    flex-basis: 31%;
  }
  /* Media query for tablet screens */
  @media (max-width: 768px) {
    max-width: 48%;
    flex-basis: 48%;
  }
  /* Media query for phone screens */
  @media (max-width: 480px) {
    max-width: 100%;
    flex-basis: 100%;
  }
`
const subscription_card_column_container_style = css`
  flex-basis: 31.5%;

  /* Media query for tablet screens */
  @media (max-width: 1024px) {
    max-width: 48%;
    flex-basis: 48%;
  }
  /* Media query for tablet screens */
  @media (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
  }
`

const try_xoip_card_container = css`
  padding-top: 36px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    padding-top: 10px;
  }
`

const empty_list_style = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 36px;
  margin-bottom: 50px;

  & > * {
    margin-bottom: 0 !important;
    flex-basis: 30%;

    /* Media query for pc screens */
    @media (max-width: 1024px) {
      max-width: 46%;
      flex-basis: 46%;
    }
    /* Media query for tablet screens */
    @media (max-width: 768px) {
      max-width: 70%;
      flex-basis: 70%;
    }
    /* Media query for phone screens */
    @media (max-width: 480px) {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
  & > * > * {
    height: 100%;
  }
`
