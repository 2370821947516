/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { head, get, map, keys, split, size } from 'lodash'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { FormLabelValue } from '../../components/form/FormLabelValue'
import { adminCallForwardingCostStructureList } from '../actions/admin_call_forwarding_cost_structure'
import { adminCallForwardingCostStructureVariantList } from '../actions/admin_call_forwarding_cost_structure_variant'
import { adminOutgoingVoiceServiceProviderList } from '../actions/admin_outgoing_voice_service_provider'
import { CardHeader } from '../../components/layout/CardHeader'
import { LanguageFlag } from '../../components/LanguageFlag'
import { countryList } from '../../actions/country'
import { Separator } from '../../components/layout/Separator'
import { Formik, Form, FieldArray, Field } from 'formik'
import Loading from '../../components/Loading'
import { FormikTextarea } from '../../components/form/TextareaField'
import { FormikInputField } from '../../components/form/InputField'
import AdminMainLayout from './AdminMainLayout'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import Card from '../../components/layout/Card'
import { Col, Row, Container, Navbar, Nav } from 'react-bootstrap'
import { Form as BootstrapForm } from 'react-bootstrap'
import * as Yup from 'yup'
import {showSuccess, showError} from '../../actions/Error'
import Button from 'react-bootstrap/Button'
import CardInfoText from '../../components/layout/CardInfoText'
import FieldInfoText from  '../../components/layout/FieldInfoText'
import { BlueButton } from '../../components/layout/BlueButton'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { handleSubmitResult } from '../../actions/form'

const validationSchema = Yup.object().shape({
    cost_per_minute_excluding_vat_euros: Yup.number().required("Required").typeError("Must be a decimal Euros amount"),
    setup_cost_excluding_vat_euros: Yup.number().required("Required").typeError("Must be a decimal Euros amount")
})

class AdminCallForwardingCostStructure extends Component {

    componentDidMount() {
        const { dispatch, call_forwarding_cost_structure_id } = this.props
        dispatch(adminCallForwardingCostStructureList.ensureObjectLoaded(call_forwarding_cost_structure_id))
        dispatch(adminCallForwardingCostStructureVariantList.fetchListIfNeeded())
        dispatch(adminOutgoingVoiceServiceProviderList.fetchListIfNeeded())
        dispatch(countryList.updatePaginationNumItemsPerPage(1000))
        dispatch(countryList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch, call_forwarding_cost_structure_id } = this.props
        dispatch(adminCallForwardingCostStructureList.ensureObjectLoaded(call_forwarding_cost_structure_id))
    }

    onSave = (values, formik_funcs) => {
        const { history, onSubmit, dispatch, call_forwarding_cost_structure_id } = this.props

        const on_ok = function(json) {
            dispatch(adminCallForwardingCostStructureList.invalidateList())
            showSuccess("Saved", "Call Forwarding cost saved")

            if ( ! call_forwarding_cost_structure_id ) {
                history.push(`/admin/call_forwarding_cost_structure/${json.id}`)
            }
        }
        if ( call_forwarding_cost_structure_id ) {
            values.id = call_forwarding_cost_structure_id
            return dispatch(adminCallForwardingCostStructureList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminCallForwardingCostStructureList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }
    
    getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            default:
                return undefined
        }
    }

    renderForm(formik_props) {
        const { variant_options, outgoing_voice_service_provider_options, country_options } = this.props
        
        return (
            <div>
              <Row>
                <Col>
                  <FormLabelValue>
                    <span>Destination phone number prefix (leave blank for default costs)</span>
                    <FormikInputField name="destination_phone_number_prefix" placeholder="Destination phone number prefix" />
                  </FormLabelValue>

                  <FormLabelValue>
                    <span>Destination network name</span>
                    <FormikInputField name="destination_network_name" placeholder="Destination network" />
                  </FormLabelValue>

                  <FormLabelValue>
                    <span>Country</span>
                    <FormikDropdownField name="country"
                                         formik_props={formik_props}
                                         options={country_options}
                                         placeholder="Select country" />
                  </FormLabelValue>

                  
                  <FormLabelValue>
                    <span>Variant</span>
                    <FormikDropdownField name="variant"
                                         formik_props={formik_props}
                                         options={variant_options}
                                         empty_selection_label="Any variant"
                                         placeholder="Variant" />
                  </FormLabelValue>
                  
                  <FormLabelValue>
                    <span>Cost per minute (ex VAT euros)</span>
                    <FormikInputField name="cost_per_minute_excluding_vat_euros" placeholder="Cost per minute" />
                  </FormLabelValue>

                  <FormLabelValue>
                    !<span>Set up cost (ex VAT euros)</span>
                    <FormikInputField name="setup_cost_excluding_vat_euros" placeholder="Setup cost for the call" />
                  </FormLabelValue>


                  <FormLabelValue>
                    <span>Default Voice Service Provider</span>
                    <FieldInfoText>
                      This option can be overridden in the customer's subscription call plan (the routing json)
                      <br/>
                      If not overridden, and is set here to "Dial plan default", then the hard-coded provider of the Asterisk dial plan is used.
                    </FieldInfoText>
                    <FormikDropdownField name="outgoing_voice_service_provider"
                                         formik_props={formik_props}
                                         options={outgoing_voice_service_provider_options}
                                         empty_selection_label="Dial plan default"
                                         placeholder="Dial plan default"/>
                  </FormLabelValue>
                  
                  <FormLabelValue>
                    <span>Active (if false, then calls cannot be forwarded to these numbers)</span>
                    <FormikCheckboxField name="is_active" label="Active" />
                  </FormLabelValue>
                  
                </Col>
              </Row>
              <Row>
                <Col>
                  <BlueButton type="submit">Save</BlueButton>
                </Col>
              </Row>
            </div>
        )
    }

    render() {
        const { initial_values, is_loading, is_busy, call_forwarding_cost_structure, call_forwarding_cost_structure_id } = this.props
        const that = this
        const title = `${get(call_forwarding_cost_structure, ["destination_phone_number_prefix"], 'New call forwarding cost structure')}`

        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           
                                           {name: 'call_forwarding_cost_structures',
                                            label: 'CallForwardingCostStructures',
                                            url: '/admin/call_forwarding_cost_structures'},
                                           
                                           {name: 'call_forwarding_cost_structure',
                                            label: (
                                                <div css={breadcrumb_item}>
                                                  {get(call_forwarding_cost_structure, ["name"], 'New call forwarding cost')}
                                                </div>
                                            ),
                                            url: `/admin/call_forwarding_cost_structure/${call_forwarding_cost_structure_id}`
                                           }
            ]}>
              <Container fluid>
                { is_busy && <BusyMask /> }
                { is_loading && <Loading /> }

                <CardHeader title={title} />
                <Separator variant="h30" />
                
                { ! is_loading && 
                  <Formik
                      initialValues={initial_values}
                      onSubmit={that.onSave}
                      enableReinitialize={true}
                      validationSchema={validationSchema}
                      >
                    {formik_props => {
                         const { values } = formik_props
                         return (
                             <Form>
                               <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                               <Row>
                                 <Col>
                                   <Card>
                                     { that.renderForm(formik_props) }
                                   </Card>
                                 </Col>
                               </Row>
                             </Form>
                         )}
                    }
                  </Formik>
                }
              </Container>
            </AdminMainLayout>
        )
    }
    
}

function mapStateToProps(state, props) {
    const call_forwarding_cost_structure_id = get(props, ["match", "params", "call_forwarding_cost_structure_id"], null)
    const call_forwarding_cost_structure = adminCallForwardingCostStructureList.getObject(call_forwarding_cost_structure_id)
    const country_options = countryList.getAsOptionsWithPriority()
    return {
        call_forwarding_cost_structure_id,
        call_forwarding_cost_structure,
        is_loading: adminCallForwardingCostStructureList.isLoading(),
        is_busy: adminCallForwardingCostStructureList.getIsSavingObject(),
        initial_values: call_forwarding_cost_structure,
        variant_options: adminCallForwardingCostStructureVariantList.getAsOptions(),
        outgoing_voice_service_provider_options: adminOutgoingVoiceServiceProviderList.getAsOptions(),
        country_options
    }
}

export default connect(mapStateToProps)(AdminCallForwardingCostStructure)

const breadcrumb_item = css`
display: flex;
align-items: center;
`
