import { ItemList } from '../../orm'
import { download } from '../../actions/lib'

class AdminTransactionList extends ItemList {
    getEntityKey() {
        return "admin/transaction"
    }

    download(filter) {
        const url = `admin/transaction/download_excel/` 
        const params = null 
        const post_params = filter
        return download(url, params, post_params)
    }    
    
}

export const adminTransactionList = new AdminTransactionList("admin_transaction__default")
