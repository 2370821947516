import { ItemList } from '../orm'
import { filter, get } from 'lodash'
import { OutgoingFaxQueueList } from './outgoing_fax_queue'

class OutgoingFaxQueueProvisionalCosting extends OutgoingFaxQueueList {
    getEntityKey() {
        return "outgoing_fax_queue_provisional_costing"
    }

    updateProvisionalCosting(values) {
        // Saving a provisional fax also calculates the cost, so hijack the base functionality.
        return this.saveProvisional(values)
    }
    
}

export const outgoingFaxQueueProvisionalCosting = new OutgoingFaxQueueProvisionalCosting("outgoing_fax_queue_provisional_costing__default")
