/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { default_theme as theme } from '../emotion/theme'
import { Trans, Translation } from 'react-i18next'
import { size, get, filter } from 'lodash'
import classNames from 'classnames'
import { FreeMinutesDuration } from './FreeMinutesDuration'
import { WhiteButton } from './layout/WhiteButton'
import { InlineIcon } from './layout/InlineIcon'
import CreditInformationCard from './CreditInformationCard'
import { jsx, css } from '@emotion/react'
import MainLayout from './MainLayout'
import { PendingAssignmentNumbersCard } from './PendingAssignmentNumbersCard'
import { BlueLinkButton } from './layout/BlueLinkButton'
import { Row, Col, Container } from 'react-bootstrap'
import { globalSettingsList } from '../actions/settings'
import { Separator } from './layout/Separator'
import Card from './layout/Card'
import { CardHeader } from './layout/CardHeader'
import CardH2Text from './layout/CardH2Text'
import WrappingBusyMask from './WrappingBusyMask'
import CommonTable from './CommonTable'
import { PhoneNumber } from './PhoneNumber'
import { subscriptionVoiceList } from '../actions/customer_subscription'
import { customerSubscriptionNumberPendingList } from '../actions/customer_subscription'
import { UpgradePathNotifications } from './UpgradePathNotifications'
import { LowTrafficWarningWidget } from './LowTrafficWarningWidget'

export const VoiceNumbers = ({ value, can_add, ...props }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const active_key = 'voice'
  const customer_can_create_subscriptions = useSelector(
    () =>
      globalSettingsList.getSetting('customer_can_create_subscriptions') ===
      true
  )
  const is_ready = useSelector(() => subscriptionVoiceList.isReady())
  const is_loading = useSelector(() => subscriptionVoiceList.isLoading())
  const subscriptions = filter(
    useSelector(() => subscriptionVoiceList.getVisibleObjects()),
    (x) => get(x, 'phone_number_number')
  )

  const headers = {
    number: {
      name: 'Number',
      renderValue: (item) => {
        return (
          <div>
            <PhoneNumber phone_number={get(item, 'phone_number_number')} />
            <LowTrafficWarningWidget subscription={item} />
          </div>
        )
      },
    },
    name: {
      name: 'Name',
      renderValue: (item) =>
        get(item, ['customer_phone_number', 'phone_number_name']),
    },
    // forward_email: { name: <Trans>Voicemail destination</Trans>,
    //                  renderValue: (item) => get(item, ["customer_phone_number", "phone_number_email"])
    //                },
    min_left: {
      name: <Trans>Minutes left this month</Trans>,
      renderValue: (item) => renderMinLeft(item),
    },
    edit_call_plan: {
      name: '',
      td_style: action_cell_style,
      renderValue: (item) => {
        return (
          <InlineIcon
            variant="blue"
            icon_name="edit"
            onClick={() => onEditCallPlan(item)}
          />
        )
      },
    },
  }

  useEffect(() => {
    dispatch(globalSettingsList.ensureGlobalSettingsLoaded())
    dispatch(
      subscriptionVoiceList.updateListFilter({
        can_receive_voice: true,
        is_active: true,
      })
    )
    dispatch(subscriptionVoiceList.fetchListIfNeeded())
    dispatch(
      customerSubscriptionNumberPendingList.filter_waiting_for_number_assignment(
        { can_receive_voice_only: true }
      )
    )
  }, [])

  const onAddVoiceSubscription = () => {
    history.push('/subscription/voice')
  }

  const onEditCallPlan = (item) => {
    history.push(`/voice/${item.id}/call_plan`)
  }

  const renderMinLeft = (subscription) => {
    return (
      <div css={free_minutes_cell_container_style}>
        <FreeMinutesDuration
          minutes={
            subscription.remaining_monthly_free_voice_forwarding_minutes || 0
          }
          format="long"
        />
      </div>
    )
  }

  const renderFooter = () => {
    if (size(customerSubscriptionNumberPendingList.getVisibleObjects()) === 0) {
      return null
    }
    return (
      <PendingAssignmentNumbersCard
        itemList={customerSubscriptionNumberPendingList}
        render_inline={true}
      />
    )
  }

  const renderXoipCreditPanel = () => {
    return (
      <>
        <CreditInformationCard showFaxSendingRates={false} />
      </>
    )
  }

  return (
    <MainLayout active_key={active_key} breadcrumbs={[{ name: active_key }]}>
      <Container fluid>
        <UpgradePathNotifications display_style="voice_numbers" />

        <Row>
          <Col md={9}>
            <CardHeader
              left_child={null}
              align_with_common_table={true}
              title={<Trans>Voice Numbers</Trans>}
              right_child={
                <>
                  <WhiteButton onClick={onAddVoiceSubscription}>
                    <Trans>Add voice subscription</Trans>
                  </WhiteButton>
                </>
              }
            />
            <Separator variant="h15" />

            <CommonTable
              is_loading={is_loading}
              pagination_mode="auto"
              empty_message={`There are no voice subscriptions`}
              headers={headers}
              items={subscriptions}
              item_list={subscriptionVoiceList}
              renderFooter={renderFooter}
            />

            <UpgradePathNotifications display_style="voice_numbers_bottom" />
          </Col>

          <Col>{renderXoipCreditPanel()}</Col>
        </Row>
      </Container>
    </MainLayout>
  )
}

const helpdesk_contact_style = css``

const sub_heading_style = css`
  font-weight: bold;
  font-size: 18px;
`

const free_minutes_cell_container_style = css`
  display: flex;
  justify-content: start;
`

// !important needed because the build in <table> style takes over otherwise
const action_cell_style = css`
  vertical-align: middle !important;
`
