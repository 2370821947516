/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { head, get, filter } from 'lodash' 
import { withRouter } from 'react-router-dom'
import AdminCommonSubscriptions from './AdminCommonSubscriptions'
import { Container } from 'react-bootstrap'
import AdminMainLayout from './AdminMainLayout'
import { adminCustomerSubscriptionFullList } from '../actions/admin_customer_subscription'
import { adminSubscriptionStatusList } from '../actions/admin_subscription_status'
import { AdminQuickLink } from './AdminQuickLink'

class AdminSubscriptions extends Component {

    renderCustomerDisplayName = (item) => {

        return (
            <AdminQuickLink name="customer" value={item.customer} label={item.customer_display_name} />
        )
        
    }
    
    
    render() {
        const that = this
        
        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'subscriptions', label: 'Subscriptions', url: '/admin/subscriptions'}]}>
              <Container fluid>
                <AdminCommonSubscriptions subscriptionList={adminCustomerSubscriptionFullList}
                                          show_filter={true}
                                          supplementary_headers={{customer_display_name: { name: "Customer", renderValue: that.renderCustomerDisplayName}}}
                                          title="Subscriptions" />
              </Container>
            </AdminMainLayout>
        )
    }
    
}

function mapStateToProps(state, props) {
    return {
    }
}

export default withRouter(connect(mapStateToProps)(AdminSubscriptions))

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const price_style = css`
display: flex;
`
