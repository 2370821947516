/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/react'
import { useField } from 'formik'
import { map } from 'lodash'
import { default_theme as theme } from '../../emotion/theme'
import FormError from './FormError'
import FormWarning from './FormWarning'
import Form from 'react-bootstrap/Form'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import { RadioGroupFieldStandalone } from './RadioGroupFieldStandalone'

export const FormikRadioGroupField = ({ label, auto_scale, onChange, options, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const { formik_props } = props
    const [field, meta] = useField(props)
    const className = (meta && meta.touched && meta.error && error_class) || null
    props.options = null

    const onSelected = (value) => {
        formik_props.setFieldValue(field.name, value)
        if ( onChange ) {
            onChange(value)
        }
    }

    return (
        <div css={className}>
          <RadioGroupFieldStandalone onChange={onSelected} auto_scale={auto_scale} value={field.value} options={options} /> 
          <FormError meta={meta} />
          <FormWarning meta={meta} />
        </div>
    )
}

const error_class = css`
  border: 1px solid ${theme.colors.error};
`
