import { ItemList } from '../orm'
import cookie from 'react-cookies'
import { slice, uniqBy, map } from 'lodash'

class BlockedNumberAutoCompleteList extends ItemList {
    getEntityKey() {
        return "blocked_number_auto_complete"
    }

    lookupForNumber(partialPhoneNumber) {
        return async(dispatch, getState) => {
            dispatch(this.updatePaginationNumItemsPerPage(100))
            dispatch(this.updateListFilter({'source_number': partialPhoneNumber}))
            dispatch(this.fetchListIfNeeded())
        }
    }

    getResults() {
        const MAX_RESULTS = 10
        const results = slice(uniqBy(map(this.getVisibleObjects(), (autoCompleteResult) => { return {'number': autoCompleteResult.source_number,
                                                                                                     'last_call_at': autoCompleteResult.start_date_time}
        }), (result) => result.number), 0, MAX_RESULTS)
        return results
    }


}

export const blockedNumberAutoCompleteList = new BlockedNumberAutoCompleteList("blockednumberautocompletelist__default")
