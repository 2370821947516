/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css,  Global } from '@emotion/react'
import { connect } from 'react-redux'
import { size, head, get, map, keys, slice } from 'lodash'
import moment from 'moment'
import {showSuccess, showError} from '../../actions/Error'
import ReactTimeout from 'react-timeout'
import { Formik, Form, FieldArray, Field } from 'formik'
import Loading from '../../components/Loading'
import { BlueButton } from '../../components/layout/BlueButton'
import { adminUserList } from '../actions/admin_user'
import { Row, Col, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import Card from '../../components/layout/Card'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { adminAsteriskServerLogList, adminAsteriskServerLogRequestor } from '../actions/admin_asterisk_server_log'
import CommonTable from '../../components/CommonTable'
import { Button, Container } from 'react-bootstrap'
import * as Yup from 'yup'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { adminAsteriskMappingOptions } from '../actions/admin_dropdown_options'
import { Separator } from '../../components/layout/Separator'
import { FormikInputField } from '../../components/form/InputField'
import FieldInfoText from '../../components/layout/FieldInfoText'
import publicIp from 'react-public-ip'

const validationSchema = Yup.object().shape({
    // 'request_filter_phone_number_pattern': Yup.string().required("Required")
})

const AUTO_REFRESH_INTERVAL_MILLISECONDS = 60000

class AdminAsteriskServerLog extends Component {

    constructor(props) {
        super(props)
        this.state = { requesting_data_for_asterisk_server: null,
                       public_ip: null,
                       reloaded_at: null}
    }
    
    componentDidMount() {
        const { dispatch, asterisk_server_log, asterisk_server_mapping_options_filter, asterisk_server_log_id, setInterval } = this.props
        dispatch(adminAsteriskServerLogList.ensureObjectLoaded(asterisk_server_log_id))
        if ( asterisk_server_log && asterisk_server_mapping_options_filter.asterisk_server_name != asterisk_server_log.server_name ) {
            dispatch(adminAsteriskMappingOptions.updateListFilter({asterisk_server_name: asterisk_server_log.server_name}))
            dispatch(adminAsteriskMappingOptions.fetchListIfNeeded())
        }
        setInterval(this.onReload, AUTO_REFRESH_INTERVAL_MILLISECONDS)
    }

    componentDidUpdate(prev_props) {
        const { dispatch, asterisk_server_log, asterisk_server_log_id, asterisk_server_mapping_options_filter } = this.props
        dispatch(adminAsteriskServerLogList.ensureObjectLoaded(asterisk_server_log_id))
        if ( asterisk_server_log && asterisk_server_mapping_options_filter.asterisk_server_name != asterisk_server_log.server_name ) {
            dispatch(adminAsteriskMappingOptions.updateListFilter({asterisk_server_name: asterisk_server_log.server_name}))
            dispatch(adminAsteriskMappingOptions.fetchListIfNeeded())
        }
    }

    onShowNewDataPopup = (asterisk_server_log) => {
        this.setState({requesting_data_for_asterisk_server: asterisk_server_log})
    }

    onHideNewDataPopup = () => {
        this.setState({requesting_data_for_asterisk_server: null})
    }

    tryLoadPublicIp = async() => {
        const { public_ip } = this.state
        if ( ! public_ip ) {
            const ipv4 = await publicIp.v4() || "error: can't connect, go to https://www.whatismyip.com/";
            this.setState({public_ip: ipv4})
        }
    }
    
    onRequestNewData = (values, formik_funcs, asterisk_server_log) => {
        const { history, dispatch, asterisk_server_log_id } = this.props
        const that = this
        const on_ok = function(json) {
            if ( json.errors ) {
                showError("Failed", JSON.stringify(json.errors))
            } else {
                that.onHideNewDataPopup()
                dispatch(adminAsteriskServerLogList.invalidateObject(asterisk_server_log_id))
                showSuccess("Sent", "Data requested")
            }
        }
        return dispatch(adminAsteriskServerLogRequestor.requestNewData({server_name: asterisk_server_log.server_name, filter_values: values})).then(on_ok)
    }

    onReload = () => {
        const { dispatch, asterisk_server_log_id } = this.props
        dispatch(adminAsteriskServerLogList.invalidateObject(asterisk_server_log_id))
        dispatch(adminAsteriskServerLogList.ensureObjectLoaded(asterisk_server_log_id)).then( () => this.setState({reloaded_at: moment()}) )
    }

    renderSummary() {
        const { dispatch, asterisk_server_log } = this.props
        return (
            <Card variant="white_wide_padding" title="Version">
              <pre>{get(asterisk_server_log, "code_version")}</pre>

              { get(asterisk_server_log, "request_active", false) &&
                <div>Waiting for new data</div>
              }
              <div>
                Last updated at: <Timestamp value={get(asterisk_server_log, "last_update_at")} format="precise" />
              </div>

              <h2>Last request</h2>
              <div>
                <Button onClick={() => this.onShowNewDataPopup(asterisk_server_log)}>
                  { ! get(asterisk_server_log, "request_active", false) &&
                    <span>Request new data</span>
                  }
                  { get(asterisk_server_log, "request_active", false) &&
                    <span>Change request</span>
                  }
                </Button>
              </div>
              <Separator variant="h30" />
              
              { get(asterisk_server_log, "request_filter_phone_number_pattern") && 
                <div>
                  Phone number filter: { get(asterisk_server_log, "request_filter_phone_number_pattern", "No filter") }
                </div>
              }
              { get(asterisk_server_log, "request_white_list_ips") &&
                <div>
                  <div>
                    White list IP addresses: { get(asterisk_server_log, "request_white_list_ips", "No filter") }
                  </div>
                  <div>
                    with name : { get(asterisk_server_log, "request_white_listing_name", "not set") }
                  </div>
                </div>
              }
              { get(asterisk_server_log, "formatted_request_map_phone_numbers") &&
                <div>
                  Pending phone number mappings:
                  <Table bordered responsive css={table_style}>
                    <thead css={thead_style}>
                      <th>Number</th>
                      <th>Destination server</th>
                    </thead>
                    <tbody css={tbody_style}>
                      {map(asterisk_server_log.formatted_request_map_phone_numbers, (mapped_phone_number, index) => (
                          <tr key={index}>
                            <td>{mapped_phone_number.phone_number}</td>
                            <td>{mapped_phone_number.destination_asterisk_server}</td>
                          </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              }

            </Card>
        )
    }

    renderRequestNewDataPopup() {
        const { asterisk_server_log, asterisk_server_mapping_options } = this.props
        const { requesting_data_for_asterisk_server, public_ip } = this.state
        const initial_values = { request_filter_phone_number_pattern: get(asterisk_server_log, "request_filter_phone_number_pattern") }
        const that = this
        this.tryLoadPublicIp()
        return (
            <Modal show={true}
                   size="lg"
                   onHide={that.onHideNewDataPopup} >

              <Modal.Header closeButton>
                <Modal.Title>
                  Request data from asterisk server {requesting_data_for_asterisk_server.server_name}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                
                <Formik
                  initialValues={initial_values}
                  onSubmit={(values, formik_funcs) => this.onRequestNewData(values, formik_funcs, requesting_data_for_asterisk_server)}
                  enableReinitialize={true}
                  validationSchema={validationSchema}
                >
                  {formik_props => {
                      const { values } = formik_props
                      return (
                          <Form>
                            <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                            <Row>
                              
                            </Row>
                            <Separator variant="h20" />
                            <Row>
                              <Col>
                                <FieldInfoText>
                                  Filter redirects for including any part of this number
                                </FieldInfoText>
                                <FormikInputField name="request_filter_phone_number_pattern" placeholder="Phone number pattern" />
                                <Separator variant="h20" />
                              </Col>
                            </Row>
                            
                            <Separator variant="h30"/>
                            <Row>
                              <Col>
                                <FieldInfoText>
                                  Whitelist these comma separated IPs.
                                </FieldInfoText>
                                <FormikInputField name="request_white_list_ips" placeholder="White listed IPs" />
                              </Col>
                              <Col>
                                <FieldInfoText>
                                  Name for this whitelisting (to prevent duplicates)
                                </FieldInfoText>
                                <FormikInputField name="request_white_listing_name" placeholder="White listing name" />
                                <Separator variant="h10" />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FieldInfoText>
                                  (Your current public IP address is: {public_ip || "Loading..."})
                                </FieldInfoText>
                                <Separator variant="h20" />
                              </Col>
                            </Row>

                            <Separator variant="h30" />
                            <Row>
                              <Col span="12">
                                <FieldInfoText>
                                  Request asterisk server {get(asterisk_server_log, "server_name")} to redirect incoming calls on the number below to the specific asterisk server.
                                </FieldInfoText>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FieldInfoText>
                                  Phone number to map
                                </FieldInfoText>
                                <FormikInputField name="request_map_phone_number" placeholder="Phone number" />
                              </Col>
                              <Col>
                                <FieldInfoText>
                                  Asterisk server to handle this number
                                </FieldInfoText>
                                <FormikDropdownField options={asterisk_server_mapping_options}
                                                     formik_props={formik_props}
                                                     name="request_map_destination_asterisk_server" />
                                <Separator variant="h20" />
                              </Col>
                            </Row>

                            <Separator variant="h30" />
                            <Row>
                              <Col offset="10" span="2">
                                <BlueButton type="submit" auto_disable={false}>
                                  Send request
                                </BlueButton>
                              </Col>
                            </Row>
                            
                          </Form>
                      )
                  }}
                </Formik>

              </Modal.Body>
              
            </Modal> 
        )
        
    }

    // renderDbDump() {
    //     const { dispatch, asterisk_server_log } = this.props

    //     const dump = get(asterisk_server_log, "formatted_db_dump")
        
    //     return (
    //         <>
    //           {map(keys(dump), function(table_name) {
    //               const rows = dump[table_name]
    //               const headers = size(rows) === 0 ? [] : rows[0]
    //               return (
    //                   <Card variant="white_wide_padding" title={`Db: ${table_name}`}>

    //                     <Table bordered responsive css={table_style}>
    //                       <thead css={thead_style}>
    //                         {map(headers, (header) => <th css={th_style}>{header}</th>)}
    //                       </thead>
    //                       <tbody css={tbody_style}>
    //                         {map(slice(rows, 1), (row) =>
    //                              <tr>
    //                                {map(row, (cell) => <td>{cell}</td>)}
    //                              </tr>
    //                             )
    //                         }
    //                         {size(rows) <= 1 &&
    //                          <tr>
    //                            <td>No matches found</td>
    //                          </tr>
    //                         }
    //                       </tbody>
    //                     </Table>
    //                   </Card>
    //               )
    //           })}
    //         </>
    //     )
    // }

    // renderLog() {
    //     const { dispatch, asterisk_server_log } = this.props
    //     return (
    //         <Card variant="white_wide_padding" title="Recent log activity">
    //           { ! get(asterisk_server_log, "switch_log_file") &&
    //             <div>None</div>
    //           }
    //           <pre css={switch_log_file}>{get(asterisk_server_log, "switch_log_file")}</pre>
    //         </Card>
    //     )
    // }

    renderWhiteListedIpsLog() {
        const { dispatch, asterisk_server_log } = this.props
        return (
            <Card variant="white_wide_padding" title="White listed IPs">
              { ! get(asterisk_server_log, "white_listed_ips_log") &&
                <div>None</div>
              }
              <pre css={switch_log_file}>{get(asterisk_server_log, "white_listed_ips_log")}</pre>
            </Card>
        )
    }
    
    render() {
        const { is_loading, asterisk_server_log, asterisk_server_log_id } = this.props
        const { requesting_data_for_asterisk_server, reloaded_at } = this.state
        return (
            <AdminMainLayout title={`Asterisk Server Log for ${get(asterisk_server_log, "server_name")}`}
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'asterisk_server_logs', label: 'Asterisk server logs', url: '/admin/asterisk_server_logs'},
                                           {name: 'asterisk_server_log', label: get(asterisk_server_log, "server_name"), url: `/admin/asterisk_server_log/${asterisk_server_log_id}`}]}>
              <Container fluid>

                <Separator variant="h30" />
                <div css={reload_button_style}>
                  <Button onClick={() => this.onReload()}>
                    Check asterisk response (auto reloads every {AUTO_REFRESH_INTERVAL_MILLISECONDS/1000}s)
                  </Button>
                  <div css={reload_button_reloading}>
                    { is_loading && <Loading /> }
                  </div>
                  { ! is_loading && reloaded_at &&
                    <div css={reload_button_refreshed_row}>
                      Last refreshed at &nbsp;<Timestamp value={reloaded_at} format="short-time" />
                    </div> }
                </div>

                <>
                  { this.renderSummary() }
                  { this.renderWhiteListedIpsLog() }
                  { false && this.renderDbDump() }
                  { false && this.renderLog() }
                  { requesting_data_for_asterisk_server && this.renderRequestNewDataPopup() }
                </>
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const asterisk_server_log_id = get(props, ["match", "params", "asterisk_server_log_id"], null)
    const asterisk_server_log = adminAsteriskServerLogList.getObject(asterisk_server_log_id)
    return {
        is_loading: adminAsteriskServerLogList.isLoading(),
        asterisk_server_mapping_options: adminAsteriskMappingOptions.getAsOptions(),
        asterisk_server_mapping_options_filter: adminAsteriskMappingOptions.getFilter(),
        asterisk_server_log,
        asterisk_server_log_id
    }
}

export default connect(mapStateToProps)(ReactTimeout(AdminAsteriskServerLog))

const table_style = css`
max-height: 400px;
overflow: auto;
font-size: 12px;
`

const thead_style = css`
`

const th_style = css`
position: sticky; 
top: 0; 
`
 
const tbody_style = css`
`

const switch_log_file = css`
font-size: 12px;
`

const reload_button_style = css`
display: flex;
position: relative;
`

const reload_button_reloading = css`
position: absolute;
`

const reload_button_refreshed_row = css`
display: flex;
`
