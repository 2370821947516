import { ItemList } from '../../orm'
import { map } from 'lodash'

class AdminNumberList extends ItemList {
    getEntityKey() {
        return "admin/phone_number"
    }
}

export const adminNumberList = new AdminNumberList("admin_number_default")
