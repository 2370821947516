/** @jsxImportSource @emotion/react */
import React, { useRef, useState } from 'react'
import { jsx, css } from '@emotion/react'

export const HtmlText = ({value, ...props}) => {
    
    return (
	<div dangerouslySetInnerHTML={{ __html: value }} />
    )
}
