/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { Trans, Translation } from 'react-i18next'
import { Row, Col, Button, ButtonGroup } from 'react-bootstrap'
import { AbbreviatedSection } from '../../components/layout/AbbreviatedSection'
import { FreeMinutesDuration } from '../../components/FreeMinutesDuration'
import CommonTable from '../../components/CommonTable'
import JSONPretty from 'react-json-pretty'
import 'react-json-pretty/themes/monikai.css'
import { PhoneNumber } from '../../components/PhoneNumber'
import { AdminQuickLink } from './AdminQuickLink'
import Card from '../../components/layout/Card'
import { CardHeader } from '../../components/layout/CardHeader'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { BlueButton } from '../../components/layout/BlueButton'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikTextarea } from '../../components/form/TextareaField'
import { Separator } from '../../components/layout/Separator'
import { FormikInputField } from '../../components/form/InputField'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { InlineIconBar } from '../../components/layout/InlineIconBar'
import * as Yup from 'yup'
import {showSuccess, showError} from '../../actions/Error'
import { confirmModal } from '../../actions/ui'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { handleSubmitResult } from '../../actions/form'
import { faComment, faCommentSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const validationSchema = Yup.object().shape({
    phone_number_name: Yup.string().required("Name is required"),
    routing_as_json: Yup.string()
})

class AdminVoiceNumbersCardTable extends Component {

    constructor(props) {
        super(props)
        this.state = { number_json_being_edited: null,
                       is_number_being_added: false}
    }
    
    componentDidMount() {
        const { dispatch, item_list } = this.props
        dispatch(item_list.updatePaginationNumItemsPerPage(10))
        dispatch(item_list.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch, item_list } = this.props
        dispatch(item_list.fetchListIfNeeded())
    }

    startJsonEditingNumber = (number) => {
        this.setState({number_json_being_edited: number, is_number_being_added: false})
    }

    cancelJsonEditingNumber = () => {
        this.cancelAddingNumber()
    }

    startPrettyEditingNumber = ({customer_phone_number}) => {
        const { history } = this.props
        history.push(`/admin/voice/${customer_phone_number.customer_subscription}/call_plan`)
    }
    
    cancelAddingNumber = () => {
        this.setState({is_number_being_added: false, number_json_being_edited: null})
    }

    onSaveJsonNumber = (values, formik_funcs) => {
        const { dispatch, history, item_list } = this.props
        const { number_json_being_edited } = this.state
        const that = this
        const on_ok = function(json) {
            dispatch(item_list.invalidateList())
            that.cancelAddingNumber()
            showSuccess(<Trans>Saved</Trans>, <Trans>Voice number saved</Trans>)
        }
        if ( number_json_being_edited ) {
            values.id = number_json_being_edited.id
            return dispatch(item_list.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(item_list.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    renderMinLeft = (subscription) => {
        return (
            <div css={free_minutes_cell_container_style}>
              <FreeMinutesDuration minutes={subscription.remaining_monthly_free_voice_forwarding_minutes || 0} format="long" />
            </div>
        )
    }

    getCellValue = (header_key, item, index, row_index, formik_props) => {
        const { number_json_being_edited, is_number_being_added } = this.state
        const is_editing = (is_number_being_added === true && row_index === -1) || (number_json_being_edited && number_json_being_edited.id === item.id)
        const that = this

        switch( header_key ) {

            case 'number':
                return (
                    <div>
                      
                      <AdminQuickLink label={<PhoneNumber phone_number={item.number} />}
                                      name="customer_subscription"
                                      value={item.customer_subscription}
                                      customer={item.customer} />
                    </div>
                )

            case 'phone_number_name': 
                if ( is_editing ) {
                    return (
                        <Translation>{ (t) => (
                            <FormikInputField
                              name="phone_number_name"
                              type="text"
                              placeholder={t("Name")}
                            />
                        )}</Translation>
                    )
                } else {
                    return undefined
                }

            case 'routing_as_json': 
                if ( is_editing ) {
                    return (
                        <Translation>{ (t) => (
                            <FormikTextarea
                              name="routing_as_json"
                              placeholder={t("Routing json")}
                            />
                        )}</Translation>
                    )
                } else {
                    return (
                        <AbbreviatedSection render_collapsed={() =>
                                                              <div>Raw routing info</div>
                                                             }
                                            render_expanded_header={() =>
                                                                    <BlueButton onClick={() => that.startJsonEditingNumber(item)}>
                                                                      Edit json directly
                                                                    </BlueButton>
                                                                   }>
                          <JSONPretty data={item.routing_as_json} />
                        </AbbreviatedSection>
                    )
                }

            case 'product_name':
                return item.product_name
                
            case 'min_left':
                return this.renderMinLeft(item)

            case 'has_custom_answering_message':

                if ( get(item, ["answering_message", "is_active"], false) ) {
                    return get(item, ["answering_message", "answering_message_file_info", "original_filename"])
                } else {
                    return "None"
                }
            
            
            case 'action':
                if ( is_editing ) {
                    return (
                        <InlineIconBar>
                          <InlineIcon icon_name="save" variant="green" onClick={formik_props.submitForm} />
                          <InlineIcon icon_name="cancel" variant="red" onClick={this.cancelJsonEditingNumber} />
                        </InlineIconBar>
                        
                    )
                } else {
                    if ( ! is_editing ) {
                        return (
                            <InlineIconBar>
                              <InlineIcon icon_name="edit" onClick={() => this.startPrettyEditingNumber({customer_phone_number: item})}/>
                            </InlineIconBar>
                        )
                    }

                }

            default:
                return undefined
        }
    }

    renderTitle() {
        const { number_json_being_edited, is_number_being_added } = this.state
        return (
            <div>
              <CardHeader title={<Trans>Voice Numbers</Trans>}
                          left_child={null}
                          sub_text={<Trans>Incoming voices on these numbers will be routed as configured here.</Trans>}
              />

              <Separator variant="h10" />
            </div>
        )
    }

    render() {
        const { numbers, number_headers, is_loading, item_list } = this.props
        const { is_number_being_added, number_json_being_edited } = this.state
        let edit_row = null

        return (
            <Formik
              initialValues={(is_number_being_added && {}) || {number: get(number_json_being_edited, 'number'),
                                                               phone_number_name: get(number_json_being_edited, 'phone_number_name'),
                                                               routing_as_json: get(number_json_being_edited, 'routing_as_json')}}
                onSubmit={this.onSaveJsonNumber}
                validationSchema={validationSchema}
                enableReinitialize={true}
            >
              {formik_props => {
                   const { values } = formik_props
                   return (
                       <Card with_padding_below={false} title={this.renderTitle()}>

                         <Form>
                           <FormikGeneralFormErrors />
                           <CommonTable is_loading={ is_loading }
                                        empty_message={'There are no voice numbers on your account'}
                                        headers={number_headers}
                                        add_row={(is_number_being_added && {}) || null}
                                        edit_row={number_json_being_edited}
                                        items={numbers}
                                        item_list={item_list}
                                        pagination_mode="auto"
                                        getCellValue={ (header_key, item, index, row_index) => this.getCellValue(header_key, item, index, row_index, formik_props) }
                           />
                           <Separator variant="h40" />
                         </Form>
                       </Card>
                   )}
              }
            </Formik>
        )
    }
    
}

function mapStateToProps(state, props) {
    const item_list = props.item_list
    const numbers = item_list.getVisibleObjects()

    return {
        numbers,
        item_list: item_list,
        is_loading: item_list.isLoading(),
        number_headers: {
            number: { name: <Trans>Number</Trans>, column_size: 1 },
            phone_number_name: { name: <Trans>Name</Trans>, column_size: 1 },
            product_name: { name: <Trans>Product</Trans>, column_size: 1 },
            min_left: { name: <Trans>Minutes left this month</Trans> },
            routing_as_json: { name: <Trans>Routing (as json)</Trans>, column_size: 1 },
            action: { name: '', column_size: 1 },
        }
    }
}

export default withRouter(connect(mapStateToProps)(AdminVoiceNumbersCardTable))

const answering_message_button_style = css`
cursor: pointer;
`

const free_minutes_cell_container_style = css`
display: flex;
justify-content: start;
`
