/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { jsx, css } from '@emotion/react'
import MainLayout from './MainLayout'
import Card from './layout/Card'
import { CardHeader } from './layout/CardHeader'
import { Col, Row, Button } from 'react-bootstrap'

class QuickLinksCard extends Component {

    onSendAFax = () => {
        window.alert("Coming soon...")
    }

    comingSoon = () => {
        window.alert("Coming soon...")
    }

    renderLink(label, onClick) {
        return (
            <Row>
              <Col>
                <Button css={button_class} variant="light" onClick={onClick} block>
                  {label}
                </Button>
              </Col>
            </Row>
        )
    }
    
    render() {

        return null

        
        // return (
        //     <div>
        //       <CardHeader title={<Trans>Quick Links</Trans>} />
        //       <Card variant="white">

        //         <Button css={[button_class, button_class_primary]} variant="primary" onClick={this.onSendAFax} block>
        //           <Trans>Send a Fax</Trans>
        //         </Button>
                
        //         { this.renderLink(<Trans>Your Subscription</Trans>, this.comingSoon) }
        //         { this.renderLink(<Trans>Voice Numbers & Call Plans</Trans>, this.comingSoon) }
        //         { this.renderLink(<Trans>Fax Numbers</Trans>, this.comingSoon) }
        //         { this.renderLink(<Trans>Buy a Number</Trans>, this.comingSoon) }
        //         { this.renderLink(<Trans>Port your Number</Trans>, this.comingSoon) }
        //       </Card>
        //     </div>
        // )
    }
    
}

function mapStateToProps(state, props) {
    return {
    }
}

export default withRouter(connect(mapStateToProps)(QuickLinksCard))

const button_class = css`
text-align: left;
background-color: #ffffff;
color: #000;
`

const button_class_primary = css`
color: #fff;
font-weight: 500;
background-color: #0788ca;
margin-bottom: 15px;
`
