/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { map, get, size, forEach } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import { Formik, Form } from 'formik'
import { FormikGeneralFormErrors } from './form/GeneralFormErrors'
import { molliePaymentList } from '../actions/mollie_payment'
import { FormikRadioGroupField } from './form/RadioGroupField'
import WrappingBusyMask from './WrappingBusyMask'
import CurrencyValue from './CurrencyValue'
import { BlueButton } from './layout/BlueButton'
import Timestamp from './Timestamp'
import { default_theme as theme } from '../emotion/theme'
import { Separator } from './layout/Separator'
import BusyMask from './BusyMask'
import Card from './layout/Card'
import { showSuccess, showError } from '../actions/Error'
import CardInfoText from './layout/CardInfoText'
import CardBlackInfoText from './layout/CardBlackInfoText'
import {
  Row,
  Col,
  Container,
  Popover,
  OverlayTrigger,
  Button,
} from 'react-bootstrap'
import { phoneNumberIsValid } from './form/PhoneNumberInputField'
import { ProductCard } from './ProductCard'
import { productList } from '../actions/product'
import { subscriptionList } from '../actions/customer_subscription'
import { handleSubmitResult } from '../actions/form'
import {
  SubscriptionUpgradeCardForm,
  validationSchema,
} from './SubscriptionUpgradeCardForm'
import * as Yup from 'yup'

export const SubscriptionUpgradeCard = ({
  initial_product_short_id,
  product_filter,
  subscription,
  ...props
}) => {
  const dispatch = useDispatch()
  const [processingSubscription, setProcessingSubscription] = useState(false)

  const is_saving = useSelector(
    () =>
      subscriptionList.getIsSavingObject() ||
      molliePaymentList.getIsSavingObject() ||
      processingSubscription
  )
  const initial_values = {}
  const history = useHistory()

  const handlePaySubscription = (subscription) => {
    if (!subscription.amount_owing_including_vat_euros) {
      history.push(`/subscription_confirmation/${subscription.id}`)
    } else {
      const on_ok = (mollie_record) => {
        if (mollie_record.checkout_url) {
          molliePaymentList.rememberPendingMolliePaymentId(mollie_record.id)
          window.location = mollie_record.checkout_url
        }
      }

      molliePaymentList.rememberAsReturnUrl(
        `/subscription_confirmation/${subscription.id}`
      )
      dispatch(molliePaymentList.startPaySubscription({ subscription })).then(
        (res) => handleSubmitResult({ res, on_ok })
      )
    }
  }

  const onUpgradeSubscription = (values, formik_funcs) => {
    setProcessingSubscription(true)
    const on_ok = (json) => {
      dispatch(subscriptionList.invalidateList())
      dispatch(subscriptionList.fetchListIfNeeded())
      handlePaySubscription(json)
    }

    values.number_source = 'subscription_upgrade'
    values.based_on_subscription = subscription.id

    return dispatch(subscriptionList.saveNewObject(values)).then((res) =>
      handleSubmitResult({ res, formik_funcs, on_ok })
    )
  }

  return (
    <WrappingBusyMask is_loading={is_saving}>
      <Formik
        initialValues={initial_values}
        onSubmit={onUpgradeSubscription}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
        {(formik_props) => {
          return (
            <Form>
              <FormikGeneralFormErrors
                render={(msg) => (
                  <Row>
                    <Col>{msg}</Col>
                  </Row>
                )}
              />

              <SubscriptionUpgradeCardForm
                subscription={subscription}
                formik_props={formik_props}
                initial_product_short_id={initial_product_short_id}
              />
            </Form>
          )
        }}
      </Formik>
    </WrappingBusyMask>
  )
}
