import { ItemList } from '../orm'
import { handleSubmitResult } from './form'

class CustomerPhoneNumberForwardingList extends ItemList {
    getEntityKey() {
        return "customer_phone_number/check_is_valid_forwarding_number"
    }

    checkIsValid({number, customer_subscription}) {
        const values = {number,
                        customer_subscription: customer_subscription.id}
        return this.saveNewObject(values)
    }
    
}

export const customerPhoneNumberForwardingList = new CustomerPhoneNumberForwardingList("customer_phone_number_forwarding__default")
