/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'

export const AdminForceError = ({ ...props }) => {

    const x = null
    const y = x.z
    
    return (
        <div>
          This is a forced error page, for testing error handling.
        </div>
    )
}
