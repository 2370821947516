/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { head, get, filter } from 'lodash'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Trans, Translation } from 'react-i18next'
import AdminCommonSubscriptions from './AdminCommonSubscriptions'
import { WhiteButton } from '../../components/layout/WhiteButton'
import Timestamp from '../../components/Timestamp'
import { adminCustomerList } from '../actions/admin_customer'
import { adminCustomerSubscriptionList } from '../actions/admin_customer_subscription'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import AdminCustomerSubscription from './AdminCustomerSubscription'
import { adminSubscriptionStatusList } from '../actions/admin_subscription_status'
import { PAYMENT_TYPE_OPTIONS } from './AdminCustomerSubscription'

export const NEW_SUBSCRIPTION_ID = "new"

class AdminCustomerSubscriptions extends Component {

    componentDidMount() {
        const { dispatch, customer_id } = this.props
        dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
        dispatch(adminCustomerSubscriptionList.updateListFilter({customer_id: customer_id}))
        dispatch(adminCustomerSubscriptionList.fetchListIfNeeded())
        dispatch(adminSubscriptionStatusList.updatePaginationNumItemsPerPage(100))
        dispatch(adminSubscriptionStatusList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch, customer_id, customer_subscription_filter } = this.props
        if ( prev_props.customer_id != customer_id && customer_subscription_filter.customer_id != customer_id ) {
            dispatch(adminCustomerSubscriptionList.updateListFilter({customer_id: customer_id}))
        }
        dispatch(adminCustomerSubscriptionList.fetchListIfNeeded())
    }

    onAdd = () => {
        const { history, customer_id } = this.props
        history.push(`/admin/customer/${customer_id}/subscription/${NEW_SUBSCRIPTION_ID}`)
    }
    
    renderSubscriptions() {
        return (
            <AdminCommonSubscriptions subscriptionList={adminCustomerSubscriptionList}
                                      title="Subscriptions"
                                      show_filter={true}
                                      additional_header={( <div css={header}>
                                                             <WhiteButton variant="outline-primary" onClick={ this.onAdd }>
                                                               Add Subscription
                                                             </WhiteButton>
                                                           </div>
                                                         )}
            />
        )
    }

    renderEditSubscription() {
        const { customer_id, customer_subscription_id } = this.props
        return (
            <div>
              <AdminCustomerSubscription customer_id={customer_id} customer_subscription_id={customer_subscription_id} />
            </div>
        )
    }
    
    render() {
        const { is_ready, customer_subscription_id } = this.props
        return (
            <div>
              <WrappingBusyMask is_loading={!is_ready}>
                { customer_subscription_id && this.renderEditSubscription() }
                { !customer_subscription_id && this.renderSubscriptions() }
              </WrappingBusyMask>
            </div>
        )
    }
    
}

function mapStateToProps(state, props) {
    const { customer_id, customer_subscription_id } = props
    const customer = adminCustomerList.getObject(customer_id)
    const subscription_statuses = adminSubscriptionStatusList.getObjectsById()
    return {
        customer_id,
        customer_subscription_id,
        customer,
        is_ready: adminCustomerSubscriptionList.isReady(),
        subscription_statuses
    }
}

export default withRouter(connect(mapStateToProps)(AdminCustomerSubscriptions))

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const price_style = css`
display: flex;
`
