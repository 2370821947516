/** @jsxImportSource @emotion/react */
import React, { Component, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { Button } from 'react-bootstrap'
import { getGlobalHttpCounter } from '../../orm/http_adapter'

export const BaseButton = ({
  onClick,
  variant,
  extra_css,
  css_disabled_style,
  children,
  auto_disable,
  size,
  ...props
}) => {
  const [clicked_against_http_timer, setClickedAgainstHttpTimer] = useState(0)

  extra_css = extra_css || null
  variant = variant || 'default'
  size = size || 'default'

  const disabled =
    props.disabled ||
    (auto_disable !== false &&
      clicked_against_http_timer !== 0 &&
      getGlobalHttpCounter() === clicked_against_http_timer)

  const localOnClick = (evt) => {
    if (disabled) {
      evt.preventDefault()
      return false
    }
    setClickedAgainstHttpTimer(getGlobalHttpCounter())
    if (onClick) {
      evt.preventDefault()
      onClick()
    }
    return true
  }

  const size_style = size === 'xs' ? extra_small : null //smaller than the bootstrap sm sized button

  return (
    <Button
      variant={variant}
      size={size}
      css={[
        style,
        disabled ? css_disabled_style || disabled_style : null,
        size_style,
        extra_css,
      ]}
      onClick={localOnClick}
      {...props}
    >
      {children}
    </Button>
  )
}

const style = css`
  font-size: 16px;
`

const disabled_style = css`
  cursor: default;
  background-color: ${theme.colors.primary_dark};
`

const extra_small = css`
  height: 28px;
  font-size: 14px;
  display: flex;
  align-items: center;
`
