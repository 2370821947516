/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { Trans, Translation } from 'react-i18next'
import { Separator } from './layout/Separator'
import { Row, Col, Container } from 'react-bootstrap'
import CommonTable from './CommonTable'
import { head, size, map, filter, get } from 'lodash'
import CurrencyValue from './CurrencyValue'
import { default_theme as theme } from '../emotion/theme'
import { customerList } from '../actions/customer'
import Card from './layout/Card'
import NestedWrappingBusyMask from './NestedWrappingBusyMask'
import { molliePaymentList } from '../actions/mollie_payment'
import { BlueButton } from './layout/BlueButton'
import { WhiteLinkButton } from './layout/WhiteLinkButton'
import { OrangeLinkButton } from './layout/OrangeLinkButton'
import { ButtonBar } from './layout/ButtonBar'
import { upgradePathList } from '../actions/upgrade_path'
import { productListForUpgradePath } from '../actions/product'
import { globalSettingsList } from '../actions/settings'
import { InlineIcon } from './layout/InlineIcon'

export const UpgradePathNotificationUpsellFreeFaxSendingSub = ({
  upgrade_path,
  display_style,
  onHideNotification,
  onDidRender,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const is_loading = useSelector(() => upgradePathList.isLoading())
  const is_saving = useSelector(() => upgradePathList.getIsSavingObject())
  const fax_sending_products = useSelector(() =>
    productListForUpgradePath.getVisibleObjects()
  )
  const is_loading_products = useSelector(() =>
    productListForUpgradePath.isLoading()
  )
  const recommended_product = head(fax_sending_products)

  useEffect(() => {
    dispatch(
      productListForUpgradePath.updateListFilter({
        can_send_faxes: true,
        recommended: true,
      })
    )
    dispatch(
      productListForUpgradePath.updateListOrdering(
        'annual_subscription_price_excluding_vat_euros'
      )
    )
    dispatch(productListForUpgradePath.fetchListIfNeeded())
    dispatch(globalSettingsList.fetchListIfNeeded())
  }, [])

  const onGetASubscription = () => {
    history.push('/subscription/fax')
  }

  const renderRecommendedProduct = (upgrade_path) => {
    return (
      <div>
        {recommended_product && (
          <>
            <h3 css={h3_style}>
              <Trans>Fax Out</Trans>
            </h3>
            <div>
              <Trans>No minimum charge per fax</Trans>
            </div>
            <div>
              <CurrencyValue
                value={
                  recommended_product.minimum_outgoing_fax_cost_per_page_excluding_vat_euros
                }
                use_span={true}
                enable_inline_spacing_right={true}
              />
              <Trans>per page</Trans>
            </div>
            <div>
              <CurrencyValue
                value={
                  recommended_product.annual_subscription_price_excluding_vat_euros
                }
                use_span={true}
                enable_inline_spacing_right={true}
              />
              <Trans>per year</Trans>
            </div>
          </>
        )}
      </div>
    )
  }

  const render_for_send_a_fax_card = (upgrade_path) => {
    return (
      <>
        <Separator variant="h20" />
        <div css={container_style}>
          <Row>
            <Col>
              <div css={inline_header_row_style}>
                <span css={inline_header_style}>
                  <Trans i18nKey="upgrade_path_upsell_free_fax_sending_sub__send_fax__heading1">
                    Please note
                  </Trans>
                </span>
              </div>

              <Trans i18nKey="upgrade_path_upsell_free_fax_sending_sub__send_fax__para1">
                For fax sending with your free subscription, a minimum rate of
                €2 per fax applies. View our paid subscriptions for cheaper
                faxing.
              </Trans>

              {renderRecommendedProduct()}

              <Separator variant="h20" />
              <BlueButton onClick={onGetASubscription}>
                <Trans>See the fax subscriptions</Trans>
              </BlueButton>
            </Col>
          </Row>
        </div>
      </>
    )
  }

  if (display_style === 'send_a_fax_card') {
    onDidRender()
    return render_for_send_a_fax_card(upgrade_path)
  } else {
    return null
  }
}

const container_style = css`
  background-color: ${theme.colors.very_light_grey};
  width: 100%;
  margin-bottom: 30px;
  padding: 20px 20px 18px;
  border-radius: 4px;
  color: ${theme.colors.black};
`

const header_style = css`
  font-size: 20px;
  font-weight: bold;
`

const inline_header_row_style = css``

const inline_header_style = css`
  font-weight: bold;
`

const product_header_style = css`
  font-weight: bold;
`

const h3_style = css`
  margin-bottom: 0px;
`
