/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { size, map, has, get, join, union, flatten, uniq } from 'lodash'
import { ButtonBar } from '../../components/layout/ButtonBar'
import { WhiteButton } from '../../components/layout/WhiteButton'
import AdminMainLayout from './AdminMainLayout'
import CommonTable from '../../components/CommonTable'
import CurrencyValue from '../../components/CurrencyValue'
import Pagination from '../../components/Pagination'
import Timestamp from '../../components/Timestamp'
import { PhoneNumber } from '../../components/PhoneNumber'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { FormikInputField } from '../../components/form/InputField'
import { countryList } from '../../actions/country'
import { cityList } from '../../actions/city'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFooter from './AdminTableFooter'
import AdminTableFilter from './AdminTableFilter'
import CardInfoText from '../../components/layout/CardInfoText'
import { Table, Row, Col, Container, Button } from 'react-bootstrap'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { InlineIconBar } from '../../components/layout/InlineIconBar'
import { adminPhoneNumberPrefixSetList } from '../actions/admin_phone_number_prefix_set'
import { adminPhoneNumberPrefixListForSet } from '../actions/admin_phone_number_prefix'
import { adminNumberTypes } from '../actions/admin_dropdown_options'
import { Separator } from '../../components/layout/Separator'

class AdminPhoneNumberPrefixSets extends Component {

    componentDidMount() {
        const { dispatch, prefix_ids } = this.props
        dispatch(adminPhoneNumberPrefixListForSet.updatePaginationNumItemsPerPage(10000))
        dispatch(adminPhoneNumberPrefixSetList.fetchListIfNeeded())
        if ( prefix_ids ) {
            dispatch(adminPhoneNumberPrefixListForSet.ensureObjectsLoaded(prefix_ids))
        }
    }

    componentDidUpdate() {
        const { dispatch, filter, prefix_ids } = this.props
        dispatch(adminPhoneNumberPrefixSetList.fetchListIfNeeded())
        if ( prefix_ids ) {
            dispatch(adminPhoneNumberPrefixListForSet.ensureObjectsLoaded(prefix_ids))
        }
    }

    onEdit = (evt, item) => {
        const { history } = this.props
        history.push('/admin/number_prefix_set/' + item.id)
    }

    onAdd = () => {
        const { history } = this.props
        history.push('/admin/number_prefix_set/')
    }

    onFilter = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminPhoneNumberPrefixSetList.updateListFilter(filter_values))
    }

    onCountryChanged = (country_id) => {
        const { dispatch } = this.props
        dispatch(cityList.filterOnCountry(country_id))
    }

    getCellValue = (header_key, item, index) => {
        const { prefixes } = this.props
        const item_prefixes = map(item.phone_number_prefixes, (x) => prefixes[x])
        switch( header_key ) {
            case 'phone_number_prefixes':
                return <div css={phone_number_prefixes_style}>
                         {map(item_prefixes, (x, index) =>
                             <div>
                               <PhoneNumber phone_number={get(x, "prefix")} />
                               { index < size(item_prefixes)-1 && <span>,&nbsp;</span> }
                             </div>
                         )}
                       </div>
            case 'action':
                return (
                    <InlineIconBar>
                      <InlineIcon icon_name="edit" onClick={(evt) => this.onEdit(evt, item)} />
                    </InlineIconBar>
                )
            default:
                return undefined
        }
    }

    renderFilter = (formik_props) => {
        return (
            <Col md="12">
              <div css={filter_field_row_style}>
                <FormikInputField name="any_field"
                                  formik_props={formik_props}
                                  show_search_icon={true}
                                  placeholder="Search" />

                <Separator variant="w20" />
                <WhiteButton onClick={ this.onAdd }>
                  Add number prefix set
                </WhiteButton>
              </div>
            </Col>
        )
    }

    render() {
        const {
            prefix_set_headers,
            prefix_sets,
            is_loading,
            prefix_set_filter
        } = this.props
        
        return (
            <AdminMainLayout active_key="numbers"
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'number_type', label: 'Number prefix sets', url: '/admin/number_prefix_sets'}]}>
              <AdminTableHeader title="Number prefixe sets">
                <div css={ css`width:100%;`}>
                  <AdminTableFilter updateOnChange={ this.onFilter }
                                    is_loading={ is_loading }
                                    filter={prefix_set_filter}
                                    renderFilter={ this.renderFilter }/>
                </div>
                
              </AdminTableHeader>
              <Container fluid>
                <Row>
                  <Col md="6">
                    <CardInfoText>
                      Define sets of number prefixes that, when associated with a product,
                      determine from which number prefixes a subscription can be provisioned to the customer.
                    </CardInfoText>
                  </Col>
                </Row>
                <Separator variant="h10" />
                <CommonTable
                  is_loading={ is_loading }
                  empty_message="There are no phone number prefixe sets."
                  headers={ prefix_set_headers }
                  items={ prefix_sets }
                  item_list={adminPhoneNumberPrefixSetList}
                  getCellValue={ this.getCellValue }
                  sortOnHeader={ this.sortOnHeader }
                />
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const prefix_sets = adminPhoneNumberPrefixSetList.getVisibleObjects() || []
    const phone_number_prefix_arrays = map(prefix_sets, (x) => get(x, "phone_number_prefixes", []))
    const prefix_ids = uniq(flatten(phone_number_prefix_arrays))
    const prefixes = adminPhoneNumberPrefixListForSet.getObjectsById(prefix_ids)

    return {
        prefix_sets,
        prefix_set_filter: adminPhoneNumberPrefixSetList.getFilter(),
        prefix_set_headers: {
            name: { name: "Prefix" },
            phone_number_prefixes: { name: "Number prefixes" },
            action: { name: '' }
        },
        prefix_ids,
        prefixes,
        is_loading: adminPhoneNumberPrefixSetList.isLoading() || adminPhoneNumberPrefixListForSet.isLoading()
    }
}
export default withRouter(connect(mapStateToProps)(AdminPhoneNumberPrefixSets))

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const filter_field_row_style = css`
display: flex;
`

const phone_number_prefixes_style = css`
display: flex;
flex-wrap: wrap;
`
