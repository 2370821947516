/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { jsx, css, keyframes } from '@emotion/react'
import { useDispatch, useSelector } from 'react-redux'
import { filter, head, map, get, size, forEach } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import { Formik, Form } from 'formik'
import { FormikGeneralFormErrors } from './form/GeneralFormErrors'
import { StandaloneFatIconCheckboxField } from './form/CheckboxField'
import { molliePaymentList } from '../actions/mollie_payment'
import { NewSubscriptionCostSupplement } from './NewSubscriptionCostSupplement'
import { TermsAndConditionsField } from './TermsAndConditionsField'
import { FormikRadioGroupField } from './form/RadioGroupField'
import NestedWrappingBusyMask from './NestedWrappingBusyMask'
import CurrencyValue from './CurrencyValue'
import { BlueButton } from './layout/BlueButton'
import { GrayButton } from './layout/GrayButton'
import Timestamp from './Timestamp'
import { default_theme as theme } from '../emotion/theme'
import { Separator } from './layout/Separator'
import { showSuccess, showError } from '../actions/Error'
import CardInfoText from './layout/CardInfoText'
import CardBlackInfoText from './layout/CardBlackInfoText'
import {
  Row,
  Col,
  Container,
  Popover,
  OverlayTrigger,
  Button,
} from 'react-bootstrap'
import { NumberPortingForm } from './NumberPortingForm'
import { phoneNumberIsValid } from './form/PhoneNumberInputField'
import { ProductCard } from './ProductCard'
import { productList } from '../actions/product'
import { productCostCalculator } from '../actions/product_cost_calculator'
import { subscriptionList } from '../actions/customer_subscription'
import { handleSubmitResult } from '../actions/form'
import { globalSettingsList } from '../actions/settings'
import { customerList } from '../actions/customer'
import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  product: Yup.string().required('Required'),
})

export const SubscriptionUpgradeCardForm = ({
  formik_props,
  initial_product_short_id,
  subscription,
  ...props
}) => {
  const dispatch = useDispatch()
  const [processingSubscription, setProcessingSubscription] = useState(false)
  const is_loading = useSelector(
    () => !productList.isReady() || productList.isLoading()
  )
  const is_saving = useSelector(
    () =>
      subscriptionList.getIsSavingObject() ||
      molliePaymentList.getIsSavingObject() ||
      processingSubscription
  )
  const products = useSelector(() =>
    filter(
      productList.getVisibleObjects(),
      (product) => product.id !== subscription.product
    )
  )

  dispatch(globalSettingsList.ensureGlobalSettingsLoaded())
  const [selectedProductInfo, setSelectedProductInfo] = useState(null)
  const { values } = formik_props
  const can_calculate_payment_info = Boolean(selectedProductInfo)
  const [agreedTermsAndConditions, setAgreedTermsAndConditions] =
    useState(false)
  const [paymentCostCalculationId, setPaymentCostCalculationId] = useState(null)
  const paymentCostCalculation =
    paymentCostCalculationId &&
    productCostCalculator.getObject(paymentCostCalculationId)
  const isCalculatingPaymentCost = useSelector(() =>
    productCostCalculator.getIsSavingObject()
  )

  useEffect(() => {
    const product_filter = {}
    if (subscription.product_can_receive_voice) {
      product_filter.can_receive_voice = true
      product_filter.can_receive_faxes = null
    } else {
      product_filter.can_receive_voice = null
      product_filter.can_receive_faxes = true
    }
    dispatch(productList.updateListFilter(product_filter))
    dispatch(productList.fetchListIfNeeded())
  }, [get(subscription, 'id')])

  // useEffect(() => {
  //   forEach(products, (product) => {
  //     if (product.short_id === initial_product_short_id) {
  //       onChangeProduct(product)
  //     }
  //   })
  // }, [initial_product_short_id])

  useEffect(() => {
    if (can_calculate_payment_info) {
      const values = Object.assign({}, formik_props.values)
      dispatch(productCostCalculator.calculatePaymentCost(values))
      setPaymentCostCalculationId(values.calculation_id)
    } else {
      setPaymentCostCalculationId(null)
    }
  }, [can_calculate_payment_info, JSON.stringify(formik_props.values)])

  useEffect(() => {
    if (size(products) === 1) {
      onChangeProduct(head(products))
    }
  }, [size(products)])

  useEffect(() => {
    async function fetchStaticObjects() {
      if (
        get(productList.getFilter(), 'applicable_for_subscription_upgrade') !==
        subscription.id
      ) {
        dispatch(
          productList.updateListFilter({
            applicable_for_subscription_upgrade: subscription.id,
          })
        )
        dispatch(productList.fetchListIfNeeded())
      }

      dispatch(customerList.ensureCustomerLoaded())
    }
    fetchStaticObjects()
  }, [get(subscription, 'id')])

  const onChangeProduct = (product) => {
    formik_props.setFieldValue('product', product.id)
    setSelectedProductInfo(product)
  }

  const onAgreedTermsAndConditions = (checked) => {
    setAgreedTermsAndConditions(checked)
    formik_props.setFieldValue('agreed_to_terms_and_conditions', checked)
  }

  const renderProductSelectorCard = (product, index) => {
    const is_selected = get(selectedProductInfo, 'id') === product.id
    return (
      <ProductCard
        product={product}
        key={index}
        is_selected={is_selected}
        onSelect={() => onChangeProduct(product)}
      />
    )
  }

  const renderPaymentInfo = ({ paymentCostCalculation }) => {
    return (
      <div css={payment_info_style}>
        <Separator variant="h35" />

        {paymentCostCalculation.requires_payment_to_create && <h2>Payment</h2>}

        {paymentCostCalculation.requires_payment_to_create && (
          <Row>
            <Col md={12}>
              {map(
                paymentCostCalculation.payment_line_items,
                (payment_line_item) => (
                  <>
                    <Row
                      css={
                        payment_line_item.type === 'total'
                          ? your_payment_row_style
                          : null
                      }
                    >
                      <Col>
                        {payment_line_item.type === 'vat' && (
                          <>
                            {paymentCostCalculation.vat_percentage}%&nbsp;
                            <Trans>VAT</Trans>:
                          </>
                        )}
                        {payment_line_item.type !== 'vat' && (
                          <Trans>{payment_line_item.label}</Trans>
                        )}
                      </Col>
                      <Col>
                        <CurrencyValue
                          align_right={true}
                          value={payment_line_item.cost_euros}
                        />
                      </Col>
                    </Row>
                  </>
                )
              )}
            </Col>
          </Row>
        )}

        <Separator variant="h15" />

        <Separator variant="h30" />

        <TermsAndConditionsField
          renderCheckbox={() => (
            <StandaloneFatIconCheckboxField
              checked={agreedTermsAndConditions}
              onChange={onAgreedTermsAndConditions}
            />
          )}
        />
        <Separator variant="h30" />

        {agreedTermsAndConditions && !isCalculatingPaymentCost && (
          <BlueButton type="submit">
            {paymentCostCalculation.requires_payment_to_create && (
              <Trans>Pay now</Trans>
            )}
            {!paymentCostCalculation.requires_payment_to_create && (
              <Trans>Start your subscription</Trans>
            )}
          </BlueButton>
        )}
        {!agreedTermsAndConditions && !isCalculatingPaymentCost && (
          <GrayButton disabled={true} onClick={{}}>
            {paymentCostCalculation.requires_payment_to_create && (
              <Trans>Pay now</Trans>
            )}
            {!paymentCostCalculation.requires_payment_to_create && (
              <Trans>Start your subscription</Trans>
            )}
          </GrayButton>
        )}
      </div>
    )
  }

  return (
    <>
      <Separator variant="h5" />
      <div css={product_list_style}>
        {!is_loading &&
          map(products, (product, index) =>
            renderProductSelectorCard(product, index)
          )}
        {is_loading && <div css={loading}></div>}
      </div>

      <Separator variant="h10" />

      <Row>
        <Col md={6}>
          <Separator variant="h25" />
          {can_calculate_payment_info && (
            <NestedWrappingBusyMask is_loading={isCalculatingPaymentCost}>
              {paymentCostCalculation &&
                renderPaymentInfo({ paymentCostCalculation })}
            </NestedWrappingBusyMask>
          )}
        </Col>
      </Row>
    </>
  )
}

const product_list_style = css`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`

const payment_info_style = css``

const your_payment_row_style = css`
  font-weight: bold;
`

const pulsate = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`

const loading = css`
  width: 280px;
  height: 328px;
  border: 2px solid ${theme.colors.nearly_white};
  background-color: ${theme.colors.nearly_white};
  border-radius: 20px;
  animation: ${pulsate} 1.5s ease-in-out infinite;

  @media (max-width: 480px) {
    width: 100%;
    min-width: 100%;
  }
`
