/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css, Global } from '@emotion/react'
import { Button, Col } from 'react-bootstrap'
import { Modal as BootstrapModal } from 'react-bootstrap'

class Modal extends Component {

    render() {
        const {
            onCloseModal,
            children,
            title,
            title_icon,
            modal_size='normal'
        } = this.props


        let size = null
        if ( modal_size === 'large' ) {
            size = 'xl'
        } else if ( modal_size === 'small' ) {
            size = 's'
        } else if ( modal_size === 'fullscreen' ) {
            size = 'xl'
        }
        
        return (
            <BootstrapModal show={true}
                            size={size}
                            onHide={onCloseModal} >
              <BootstrapModal.Header closeButton css={modal_header_style}>
                <BootstrapModal.Title>
                  <Col>
                    { title }
                  </Col>
                </BootstrapModal.Title>
              </BootstrapModal.Header>

              <BootstrapModal.Body>
                <Global styles={global_styles} />
                {children}
              </BootstrapModal.Body>
              
            </BootstrapModal>
        )
        
    }
}
export default Modal

const global_styles = css`
.modal-content .close {
position: absolute;
top: 20px;
right: 30px;
}
`

const container = css`
position: relative;
`

const image_container = css`
overflow: auto;
`

const image_class = css`
max-height: 400px;
max-width: 400px;

`

const title_filename_style = css`
font-weight: 500;
`

const title_subheading_style = css`
font-weight: 400;
display: flex;
font-size: 16px;
`

const title_filename = css`
`

const custom_navigation = css`
position: relative;
margin: 0 50% 10px;
`

const modal_header_style = css`
display: block;
`
