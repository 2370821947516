/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import {showSuccess, showError} from '../../actions/Error'
import { Link } from 'react-router-dom'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import { confirmModal } from '../../actions/ui'
import { useDispatch } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { Formik, Form, Field } from 'formik'
import { PhoneNumber }  from '../../components/PhoneNumber'
import Timestamp from '../../components/Timestamp'
import { get, size, map } from 'lodash'
import FieldInfoText from '../../components/layout/FieldInfoText'
import { Separator } from '../../components/layout/Separator'
import { FormikInputField } from '../../components/form/InputField'
import { adminCustomerSubscriptionManualReservationList } from '../actions/admin_customer_subscription_manual_reservation'
import { adminCustomerSubscriptionList } from '../actions/admin_customer_subscription'
import Card from '../../components/layout/Card'
import { Col, Row, Container, Tabs, Tab, Button } from 'react-bootstrap'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { handleSubmitResult } from '../../actions/form'
import { BlueButton } from '../../components/layout/BlueButton'
import { WhiteButton } from '../../components/layout/WhiteButton'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    new_number: Yup.string().required("Required")
})

export const AdminPendingSubscriptionAllocations = ({}) => {
                                                
    const dispatch = useDispatch()
    const [editingSubscriptionNumber, setEditingSubscriptionNumber] = useState(null)
    const pending_subscription_infos = adminCustomerSubscriptionManualReservationList.getVisibleObjects()
    const is_saving = adminCustomerSubscriptionManualReservationList.getIsSavingObject()
    const [showAllPendingSubcriptionInfos, setShowAllPendingSubcriptionInfos] = useState(false)

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(adminCustomerSubscriptionManualReservationList.updateListFilter({requiring_admin_attention: true}))
            dispatch(adminCustomerSubscriptionManualReservationList.fetchListIfNeeded())
        }
        fetchStaticObjects()
    }, [])

    const onStartSetNow = (info) => {
        setEditingSubscriptionNumber(info)
    }

    const onStopSetNow = () => {
        setEditingSubscriptionNumber(null)
    }

    const onSaveSubscriptionNumber = (values, formik_funcs) => {
        let on_ok = function(json) {
            showSuccess("Saved", "Subscription number set")
            onStopSetNow()
            dispatch(adminCustomerSubscriptionManualReservationList.invalidateList())
            dispatch(adminCustomerSubscriptionManualReservationList.fetchListIfNeeded())
        }
        values.id = editingSubscriptionNumber.id
        dispatch(adminCustomerSubscriptionManualReservationList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }

    const onDownloadPortingInvoiceFile = (info) => {
        adminCustomerSubscriptionManualReservationList.downloadPortingInvoiceFile(info.id)
    }

    const onSetPortingComplete = (info) => {
        const on_ok = function(json) {
            showSuccess("Ported", "The subscription has been ported")
            dispatch(adminCustomerSubscriptionManualReservationList.invalidateList())
            dispatch(adminCustomerSubscriptionManualReservationList.fetchListIfNeeded())
        }

        const onConfirmed = () => {
            const values = { id: info.id,
                             new_number: info.number }
            return dispatch(adminCustomerSubscriptionManualReservationList.onPortingComplete(values)).then(on_ok)
        }
        
        const text = (
            <div>
              <FieldInfoText>
                Only activate this subscription if:
                <ul>
                  <li>The number is already legally ported into XOIP</li>
                  <li>The number is already allocated into CoolWave or another provider</li>
                  <li>The number is mapped to xoipnode1 and/or xoipnode2</li>
                </ul>
                <Separator variant="h10" />
                After this, the customer will receive a notification that their subscription has been activated.
              </FieldInfoText>
            </div>
        )
        return dispatch(confirmModal({text:text,
                                      onConfirmed: onConfirmed}))
    }

    const renderSetSubscriptionNumberModal = (info) => {
        return (
            <Modal show={true}
                   size="lg"
                   onHide={onStopSetNow} >

              <Modal.Header closeButton>
                <Modal.Title>
                  Set subscription phone number
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>

                <WrappingBusyMask is_loading={is_saving}>
                  
                  <Formik
                    initialValues={{}}
                    onSubmit={onSaveSubscriptionNumber}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                  >
                    {formik_props => {
                        const { values } = formik_props
                        return (
                            <Container>

                              <Form>
                                <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                                <Separator variant="h20" />

                                <Row>
                                  <Col>Customer</Col>
                                  <Col>{info.customer_display_name}</Col>
                                </Row>
                                <Row>
                                  <Col>Product</Col>
                                  <Col>{info.product_name}</Col>
                                </Row>
                                <Row>
                                  <Col>Created at</Col>
                                  <Col><Timestamp value={info.created_at} format='datetime' /></Col>
                                </Row>
                                <Row>
                                  <Col>Phone number prefix</Col>
                                  <Col>{info.phone_number_prefix_name}</Col>
                                </Row>

                                <Separator variant="h20" />
                                <Row>
                                  <Col>
                                    <FieldInfoText>
                                      Only set this phone number if:
                                      <ul>
                                        <li>The number is already allocated into CoolWave or some other provider</li>
                                        <li>The number is mapped to xoipnode1 or xoipnode2</li>
                                      </ul>
                                      <Separator variant="h10" />
                                      After this, the customer will be receive a notification that their subscription has been activated.
                                    </FieldInfoText>
                                  </Col>
                                </Row>
                                <Separator variant="h10" />
                                <Row>
                                  <Col>
                                    <FormikInputField name="new_number" placeholder="Phone number" />
                                  </Col>
                                </Row>
                                <Separator variant="h20" />
                                <Row>
                                  <Col>
                                    <BlueButton type="submit" auto_disable={false}>
                                      Save
                                    </BlueButton>
                                  </Col>
                                </Row>
                              </Form>
                            </Container>
                        )
                    }}
                  </Formik>

                </WrappingBusyMask>

              </Modal.Body>
              
            </Modal>
        )
    }

    const renderSubscriptionRequiringPortingCard = (info) => {
        return (
            <div css={pending_card_style}>
              <div css={pending_card__header}>Subscription requiring number porting</div>
              <Separator variant="h10" />
              <div>
                The following subscription requires a number porting into XOIP
              </div>
              <Separator variant="h15" />

              <Row>
                <Col md={6}>
                  <Row>
                    <Col>Customer</Col>
                    <Col>{info.customer_display_name}</Col>
                  </Row>
                  <Row>
                    <Col>Product</Col>
                    <Col>{info.product_name}</Col>
                  </Row>
                  <Row>
                    <Col>Created at</Col>
                    <Col><Timestamp value={info.created_at} format='datetime' /></Col>
                  </Row>
                  <Row>
                    <Col>Phone number to port</Col>
                    <Col><PhoneNumber phone_number={info.number} /></Col>
                  </Row>
                  <Row>
                    <Col>Customer invoice file</Col>
                    <Col>
                      { info.porting_invoice_file_info &&
                        <div css={invoice_file_row_style}>
                          <span>{info.porting_invoice_file_info_details.visible_filename}</span>
                          <Separator variant="w10" />
                          <WhiteButton onClick={() => onDownloadPortingInvoiceFile(info)}>
                            Download
                          </WhiteButton>
                        </div>
                      }
                      { !info.porting_invoice_file_info &&
                        <span>No invoice file has been uploaded yet</span>
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
              
              <Separator variant="h20" />
              <div css={button_bar_style}>
                <BlueButton onClick={() => onSetPortingComplete(info)}>
                  Porting complete
                </BlueButton>
                <Separator variant="w10" />
                <Link to={`/admin/customer/${info.customer}/subscription/${info.customer_subscription}`}>
                  Edit subscription
                </Link>
              </div>
            </div>
        )
    }

    const renderSubscriptionRequiringPhoneNumberCard = (info) => {
        return (
            <div css={pending_card_style}>
              <div css={pending_card__header}>Subscription manual phone number allocation</div>
              <Separator variant="h10" />
              <div>
                The following subscription requires a manual phone number allocation:
              </div>
              <Separator variant="h15" />

              <Row>
                <Col md={6}>
                  <Row>
                    <Col>Customer</Col>
                    <Col>{info.customer_display_name}</Col>
                  </Row>
                  <Row>
                    <Col>Product</Col>
                    <Col>{info.product_name}</Col>
                  </Row>
                  <Row>
                    <Col>Created at</Col>
                    <Col><Timestamp value={info.created_at} format='datetime' /></Col>
                  </Row>
                </Col>
              </Row>
              
              <Separator variant="h20" />
              <div css={button_bar_style}>
                <BlueButton onClick={() => onStartSetNow(info)}>
                  Set number now
                </BlueButton>
                <Separator variant="w10" />
                <Link to={`/admin/customer/${info.customer}/subscription/${info.customer_subscription}`}>
                  Edit subscription
                </Link>
              </div>
            </div>
        )
    }
    
    return (
        <>
          {  size(pending_subscription_infos) > 0 && 
             <Row>
               <Col>
                 {  map(pending_subscription_infos, function(info, index) {
                     const num_pending_subscriptions = size(pending_subscription_infos)
                     return (
                         <div key={index}>
                           { (showAllPendingSubcriptionInfos || index == 0) && 
                             <Card key={index}>
                               { info.number_source === 'new' && renderSubscriptionRequiringPhoneNumberCard(info) }
                               { info.number_source === 'port' && renderSubscriptionRequiringPortingCard(info) }
                             </Card>
                           }
                           
                           { !showAllPendingSubcriptionInfos && index == 1 &&
                             <>
                               <Button onClick={() => setShowAllPendingSubcriptionInfos(true)}>
                                 Show {num_pending_subscriptions-1} more pending subscriptions
                               </Button>
                               <Separator variant="h30" />
                             </>
                           }
                           { showAllPendingSubcriptionInfos && index === num_pending_subscriptions-1 &&
                             <>
                               <Button onClick={() => setShowAllPendingSubcriptionInfos(false)}>
                                 Hide pending subscriptions
                               </Button>
                               <Separator variant="h30" />
                             </>
                           }
                         </div>
                     )
                 })
                 }
               </Col>
             </Row>
          }
          { editingSubscriptionNumber && renderSetSubscriptionNumberModal(editingSubscriptionNumber) }
          
        </>
    )
}

const pending_card_style = css`
background-color: #fff3cd;
border: 1px solid #ffeeba;
padding: 20px;
color: #856404;
`

const pending_card__header = css`
font-size: 24px;
font-weight: 500;
`

const pending_card__row = css`
font-size: 18px;
font-weight: 500;
`

const invoice_file_row_style = css`
display: flex;
`

const button_bar_style = css`
display: flex;
align-items: center;
`
