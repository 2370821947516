import { ItemList } from '../orm'

class DropdownOptions extends ItemList {
    constructor(dropdown_name) {
        super(`options__${dropdown_name}`)
        this.url = `dropdown_options/${dropdown_name}`
    }

    getEntityKey() {
        return this.url
    }

}

export const numberTypes = new DropdownOptions('number_types')
export const numberPrefixTypes = new DropdownOptions('number_prefix_types')
export const cdrTypes = new DropdownOptions('cdr_types')
export const answeringMessageTypeChoices = new DropdownOptions('answering_message_type_choices')
export const vatTypeOptions = new DropdownOptions('vat_type_options')

