/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { head, get } from 'lodash'
import { Container, Row, Col } from 'react-bootstrap'
import { default_theme as theme } from '../emotion/theme'
import { Trans, Translation } from 'react-i18next'
import MainLayout from './MainLayout'
import CommonTable from './CommonTable'
import { CardHeader } from './layout/CardHeader'
import { Separator } from './layout/Separator'
import { faxCostStructureList } from '../actions/fax_cost_structure'
import WrappingBusyMask from './WrappingBusyMask'
import CurrencyValue from './CurrencyValue'
import { PhoneNumber } from './PhoneNumber'
import TableFilter from './layout/TableFilter'

class FaxSendingRates extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(faxCostStructureList.invalidateList())
        dispatch(faxCostStructureList.updatePaginationNumItemsPerPage(999))
        dispatch(faxCostStructureList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(faxCostStructureList.fetchListIfNeeded())
    }

    onFilterChanged = (filter_values) => {
        const { dispatch, itemList } = this.props
        dispatch(faxCostStructureList.updateListFilter(filter_values))
    }

    getCellValue = (header_key, item, index, row_index) => {

        switch( header_key ) {

            case 'country':
                return get(item, ["country", "name"])
            
            case 'rate_per_page':
                return <CurrencyValue value={item.cost_per_page_excluding_vat_euros} />

            case 'destination_phone_number_prefix':
                return <PhoneNumber phone_number={item.destination_phone_number_prefix} />
            
            default:
                return undefined
        }
        
    }

    renderTextualInfo() {
        return (
            <div>
              <CardHeader title="Fax sending rates" />

              <div css={info_style}>
                <Separator variant="h10" />
                <div css={readable_row_style}>
                  <Trans>To send a fax locally in the Netherlands costs</Trans>
                  <CurrencyValue enable_inline_spacing={true} value={0.30} /> <Trans>per page</Trans>.
                </div>
                <div css={readable_row_style}>
                  <Trans>A fax to a XOIP number costs</Trans> <CurrencyValue enable_inline_spacing={true} value={0.30} /> <Trans>per page</Trans>.
                </div>
                
                <Separator variant="h10" />
                
                <div css={readable_row_style}>
                  <Trans>A fax to 0800, 085 or 088 numbers costs</Trans> <CurrencyValue enable_inline_spacing={true} value={0.30} /> <Trans>per page</Trans>.
                </div>
                <div css={readable_row_style}>
                  <Trans>A fax to any other Dutch 08 number costs</Trans> <CurrencyValue enable_inline_spacing={true} value={1.80} /> <Trans>per page</Trans>.
                </div>
                <div css={readable_row_style}>
                  <Trans>A fax to a mobile fax machine costs</Trans> <CurrencyValue enable_inline_spacing={true} value={1.80} /> <Trans>per page</Trans>.
                </div>
                <div css={readable_row_style}>
                  <Trans>Rates for other countries are listed below</Trans>.
                </div>

                <Separator variant="h10" />
                
                <div css={readable_row_style}>
                  <Trans>
                    A failed fax is charged at the cost of one page. Pages taking longer than 60 seconds
                    to send may be charged as one page per 60 seconds. Failed attempts that use more than 60 seconds of
                    call time may be charged an additional cost of 1 page per 60 seconds.
                  </Trans>
                </div>
              </div>

            </div>
        )
    }

    renderRateTable() {
        const { is_loading, costs, headers } = this.props
        return (
            <Translation>{ (t) => (
                <Row>
                  <Col md="8">
                    <CardHeader title={<Trans>International rates</Trans>}
                                right_child={<TableFilter placeholder={t("Search country or prefix")}
                                                          label=""
                                                          updateOnChange={ this.onFilterChanged }
                                                          form="FAX_SENDING_RATES_FILTER_FORM"
                                             />
                                            }
                    />
                    
                    <CommonTable
                      is_loading={ is_loading }
                      pagination_mode="off"
                      empty_message={<Trans>There are no rates.</Trans>}
                      headers={ headers }
                      items={ costs }
                      item_list={faxCostStructureList}
                      getCellValue={ this.getCellValue }
                    />
                  </Col>
                </Row>
            )}</Translation>
        )
    }
    
    render() {
        const { costs, is_loading, is_ready } = this.props
        return (
            <MainLayout active_key="fax_sending_rates"
                        breadcrumbs={[{name: 'home'},
                                      {name: 'fax_sending_rates', label: 'Fax sending rates', url: '/fax_sending_rates'}]}>
              <Container fluid>
                <WrappingBusyMask is_loading={!is_ready}>

                  { this.renderTextualInfo() }
                  <Separator variant="h30" />
                  { this.renderRateTable() }
                  
                </WrappingBusyMask>
                
              </Container>
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const costs = faxCostStructureList.getVisibleObjects()
    return {
        is_ready: faxCostStructureList.isReady(),
        is_loading: faxCostStructureList.isLoading(),
        costs,
        headers: {
            country: { name: <Trans>Country</Trans>},
            destination_phone_number_prefix: { name: <Trans>Prefix</Trans>},
            rate_per_page: { name: <Trans>Rate (per page)</Trans>}
        }
    }
}
export default connect(mapStateToProps)(FaxSendingRates)

const info_style = css`
max-width: 611px;
color: ${theme.colors.dark_grey};
`

const readable_row_style = css`
display: flex;
`

