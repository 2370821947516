/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { default_theme as theme } from '../emotion/theme'
import { connect } from 'react-redux'
import { get, head } from 'lodash'
import { jsx, css } from '@emotion/react'
import { faxByEmailVerificationList } from '../actions/fax_by_email_verification'
import { Separator } from './layout/Separator'
import MainLayout from './MainLayout'
import Loading from './Loading'
import { Error } from './layout/Error'
import { InlineIcon } from './layout/InlineIcon'
import { Container, Button } from 'react-bootstrap'
import Card from './layout/Card'
import { CardHeader } from './layout/CardHeader'
import { Trans, Translation } from 'react-i18next'
import WrappingBusyMask from './WrappingBusyMask'
import { FaxVerificationExpiredMessage } from './FaxVerificationExpiredMessage'

const VERIFICATION_OBJECT_ID='default'

class FaxByEmailVerification extends Component {

    componentDidMount() {
        const { dispatch, token, action } = this.props
        dispatch(faxByEmailVerificationList.verify(token, action))
    }

    renderResult() {
        const { verification_result, is_loading } = this.props
        if ( ! verification_result ) {
            return null
        }

        return (
            <div>
              { ! is_loading &&
                <div>
                  { verification_result.status === 'approved' &&
                    <div>
                      <CardHeader title={<Trans>Verified</Trans>} />
                      <Separator variant="h20" />
                      <Trans>Your fax has been verified and will be processed shortly</Trans>.
                    </div>
                  }
                  { verification_result.status === 'cancelled' &&
                    <div>
                      <CardHeader title={<Trans>Cancelled</Trans>} />
                      <Separator variant="h20" />
                      <Trans>Your fax has been cancelled</Trans>.
                    </div>
                  }
                  { (verification_result.status === 'email_expired' ||
                     verification_result.status === 'wrong_status' ||
                     verification_result.status === 'missing_token' ||
                     verification_result.status === 'invalid_token' ||
                     verification_result.status === 'token_already_used') &&
                    
                    <div css={result_style}>
                      <CardHeader title={<Trans>Fax expired</Trans>} />
                      <Separator variant="h20" />
                      <FaxVerificationExpiredMessage />
                    </div>
                  }
                </div>
              }
            </div>
        )
    }
    
    render() {
        const { user, new_email, is_verifying } = this.props
        const that = this
        return (
            <MainLayout>
              <Container fluid>
                <Separator variant="h40" />
                <Card>
                  <WrappingBusyMask is_loading={is_verifying}>
                    { ! is_verifying && this.renderResult() }
                  </WrappingBusyMask>
                </Card>
              </Container>
            </MainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const token = get(props, ["match", "params", "token"], null)
    const action = get(props, ["match", "params", "action"], 'approve')
    const verification_result = faxByEmailVerificationList.getObject(VERIFICATION_OBJECT_ID)

    return {
        token,
        action,
        verification_result,
        is_verifying: faxByEmailVerificationList.isSaving() || ! verification_result || ! verification_result.id
    }
}
export default connect(mapStateToProps)(FaxByEmailVerification)

const result_style = css`
color: ${theme.colors.dark_grey};
max-width: 585px;
`
