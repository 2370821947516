/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { intersection, filter, size, map, head, get, includes } from 'lodash'
import { Link } from 'react-router-dom'
import CurrencyValue from './CurrencyValue'
import { BlueButton } from './layout/BlueButton'
import { WhiteButton } from './layout/WhiteButton'
import { BlackLinkButton } from './layout/BlackLinkButton'
import { GrayLinkButton } from './layout/GrayLinkButton'
import { SubscriptionAmount } from './SubscriptionAmount'
import { numberTypes } from '../actions/dropdown_options'
import { confirmModal } from '../actions/ui'
import { globalSettingsList } from '../actions/settings'
import { PendingAssignmentNumbersCard } from './PendingAssignmentNumbersCard'
import { customerSubscriptionNumberPendingList } from '../actions/customer_subscription'
import { default_theme as theme } from '../emotion/theme'
import MainLayout from './MainLayout'
import {
  Row,
  Col,
  Container,
  Popover,
  OverlayTrigger,
  Button,
} from 'react-bootstrap'
import { handleSubmitResult } from '../actions/form'
import { PhoneNumber } from './PhoneNumber'
import { showSuccess, showError } from '../actions/Error'
import Timestamp from './Timestamp'
import Card from './layout/Card'
import CommonTable from './CommonTable'
import { InlineIcon } from './layout/InlineIcon'
import { InlineIconBar } from './layout/InlineIconBar'
import { CardHeader } from './layout/CardHeader'
import TableHeader from './layout/TableHeader'
import TableFilter from './layout/TableFilter'
import { Separator } from './layout/Separator'
import { DatePicker } from './form/DatetimePicker'
import { Trans, Translation } from 'react-i18next'
import { customerSubscriptionList } from '../actions/customer_subscription'
import { customerSubscriptionListCanUpgrade } from '../actions/customer_subscription'

const SUBSCRIPTION_STATUS_MAPPING = {
  cancel_pending: { name: <Trans>Cancelled once expired</Trans> },
  cancelled: { name: <Trans>Cancelled</Trans> },
  active: { name: <Trans>Active</Trans> },
  expired: { name: <Trans>Expired</Trans> },
  suspended: { name: <Trans>Suspended</Trans> },
  needs_number: { name: <Trans>Number pending</Trans> },
  new: { name: <Trans>New</Trans> },
}

class Subscriptions extends Component {
  componentDidMount() {
    const { dispatch } = this.props
    dispatch(customerSubscriptionList.updateListFilter({ is_relevant: true }))
    dispatch(customerSubscriptionList.fetchListIfNeeded())
    dispatch(globalSettingsList.fetchListIfNeeded())
    dispatch(numberTypes.fetchListIfNeeded())
    dispatch(
      customerSubscriptionNumberPendingList.filter_waiting_for_number_assignment(
        { can_receive_voice_or_fax: true }
      )
    )
    dispatch(
        customerSubscriptionListCanUpgrade.updateListFilter({
            can_upgrade: true
      })
    )
  }

  componentDidUpdate(prev_props) {
    const { dispatch } = this.props
    dispatch(customerSubscriptionList.fetchListIfNeeded())
    dispatch(customerSubscriptionListCanUpgrade.fetchListIfNeeded())
  }

  onPaySubscription = (subscription) => {
    const { history } = this.props
    history.push('/pay_subscriptions/')
  }

  onChangeSubscription = (subscription) => {
    const { history } = this.props
    if (
      subscription.product_can_send_faxes &&
      !subscription.product_can_receive_faxes &&
      subscription.base_cost_per_period_excluding_vat_euros === 0
    ) {
      history.push('/subscription/fax')
    } else {
      history.push(`/subscription_upgrade/${subscription.id}`)
    }
  }

  onRequestCancel = (subscription) => {
    const { dispatch } = this.props
    const text = (
      <div>
        <Trans>Are you sure you want to cancel this subscription?</Trans>
        <Separator variant="h20" />

        {subscription.expires_at && (
          <Trans
            i18nKey="future_cancel_subscription_confirmation"
            values={{ expires_at: subscription.expires_at }}
            components={[
              <Timestamp
                value={subscription.expires_at}
                format="date_longmonth"
                use_span={true}
                emptyLabel="When inactive"
              />,
            ]}
            defaults="This subscription expires on <0>{{expires_at}}</0>. If you cancel now, you can continue to use this subscription until then."
          />
        )}

        <Trans></Trans>
      </div>
    )

    const on_ok = () => {
      dispatch(customerSubscriptionList.invalidateObject(subscription.id))
      dispatch(customerSubscriptionList.ensureObjectLoaded(subscription.id))
      showSuccess(
        <Trans>Subscription cancelled</Trans>,
        <Trans>Subscription cancellation pending</Trans>
      )
    }

    const onConfirmed = () => {
      dispatch(
        customerSubscriptionList.requestCancel({
          subscription_id: subscription.id,
        })
      ).then((res) =>
        handleSubmitResult({ res, formik_funcs: {}, on_ok: on_ok })
      )
    }
    dispatch(
      confirmModal({
        text: text,
        onConfirmed: onConfirmed,
        confirm_text: 'Yes, cancel',
        cancel_text: "No, don't cancel",
      })
    )
  }

  onCancelImmediately = (subscription) => {
    const { dispatch } = this.props
    const text = (
      <div>
        <Trans>Are you sure you want to cancel this subscription?</Trans>
        <Separator variant="h20" />

        <Trans
          i18nKey="immediate_cancel_subscription_confirmation"
          defaults="If you cancel now, this subscription will immediately become inactive."
        />

        <Trans></Trans>
      </div>
    )

    const on_ok = () => {
      dispatch(customerSubscriptionList.invalidateObject(subscription.id))
      dispatch(customerSubscriptionList.ensureObjectLoaded(subscription.id))
      showSuccess(
        <Trans>Subscription cancelled</Trans>,
        <Trans>Subscription cancelled</Trans>
      )
    }

    const onConfirmed = () => {
      dispatch(
        customerSubscriptionList.cancelImmediately({
          subscription_id: subscription.id,
        })
      ).then((res) =>
        handleSubmitResult({ res, formik_funcs: {}, on_ok: on_ok })
      )
    }
    dispatch(
      confirmModal({
        text: text,
        onConfirmed: onConfirmed,
        confirm_text: 'Yes, cancel',
        cancel_text: "No, don't cancel",
      })
    )
  }

  onAddFaxSubscription = () => {
    const { history } = this.props
    history.push('/subscription/fax')
  }

  onAddVoiceSubscription = () => {
    const { history } = this.props
    history.push('/subscription/voice')
  }

  renderPopupMenu = (item) => {
    const { subscription_ids_which_can_upgrade } = this.props

    // The cancel pending logic is because the server allows a
    // user to perform a cancel of a 'cancel_pending'
    // subscription, which will then cancel the subscription
    // immediately. We're not supporting that in the ui, but I
    // want to keep it open as an option.
    const in_cancel_pending = item.status_name === 'cancel_pending'
    const show_menu =
      item.can_request_cancel || (item.can_cancel && !in_cancel_pending)
    const can_upgrade = includes(subscription_ids_which_can_upgrade, item.id)
    const that = this
    if (!show_menu) {
      return null
    }

    const popover = (
      <Popover key={`popover_${item.id}`} id={`popup_menu_${item.id}`}>
        <Popover.Header as="h3"></Popover.Header>
        <Popover.Body>
          <div>
            {can_upgrade && (
              <BlackLinkButton onClick={() => that.onChangeSubscription(item)}>
                <Trans>Upgrade subscription</Trans>
              </BlackLinkButton>
            )}
            {item.can_request_cancel && (
              <BlackLinkButton onClick={() => that.onRequestCancel(item)}>
                <Trans>Cancel subscription</Trans>
              </BlackLinkButton>
            )}
            {item.can_cancel &&
              !in_cancel_pending &&
              !item.can_request_cancel && (
                <>
                  <Separator variant="h10" />
                  <BlackLinkButton
                    onClick={() => that.onCancelImmediately(item)}
                  >
                    <Trans>Cancel subscription</Trans>
                  </BlackLinkButton>
                </>
              )}
          </div>
        </Popover.Body>
      </Popover>
    )

    return (
      <div style={overlay_trigger_container_style}>
        <OverlayTrigger
          key={`overlay_trigger_${item.id}`}
          trigger="click"
          placement="bottom"
          rootClose={true}
          overlay={popover}
        >
          <Button css={action_button_style} variant="link">
            <InlineIcon icon_name="edit" variant="blue" />
          </Button>
        </OverlayTrigger>
      </div>
    )
  }

  renderUpgradeFromSubscription = (subscription) => {
    const { subscriptions } = this.props
    const from_subscription = head(
      filter(
        subscriptions,
        (sub) => sub.id === subscription.upgrade_from_subscription
      )
    )

    if (subscription.status_name === 'active') {
      return null
    }

    return (
      <div css={supplement_value_style}>
        {from_subscription && (
          <>
            <Trans>Upgrading from</Trans>
            &nbsp;
            {get(from_subscription, 'product_name')}
            &nbsp;
            <PhoneNumber
              phone_number={get(from_subscription, 'phone_number_number')}
            />
          </>
        )}
      </div>
    )
  }

  renderUpgradedToSubscription = (subscription) => {
    const { subscriptions } = this.props
    const to_subscription = head(
      filter(
        subscriptions,
        (sub) => sub.id === subscription.upgraded_to_subscription
      )
    )

    return (
      <div css={supplement_value_style}>
        {to_subscription && (
          <>
            <Trans>Upgrading to</Trans>
            &nbsp;
            {get(to_subscription, 'product_name')}
            &nbsp;
            <PhoneNumber
              phone_number={get(to_subscription, 'phone_number_number')}
            />
          </>
        )}
      </div>
    )
  }

  getCellValue = (header_key, item, index) => {
    const { customer_id, number_types_by_id } = this.props
    const that = this
    switch (header_key) {
      case 'product_name':
        return (
          <>
            <div>{item.product_name}</div>
            {map(item.supplement_number_types, (supplement, index) => (
              <div key={index} css={supplement_value_style}>
                {get(number_types_by_id, [supplement.number_type, 'name'])}
              </div>
            ))}
          </>
        )
      case 'number':
        return (
          <>
            <PhoneNumber phone_number={get(item, 'phone_number_number')} />
            {item.upgrade_from_subscription &&
              that.renderUpgradeFromSubscription(item)}
            {item.upgraded_to_subscription &&
              that.renderUpgradedToSubscription(item)}
          </>
        )
      case 'price':
        return (
          <>
            <div css={price_style}>
              <SubscriptionAmount subscription={item} />
            </div>
            {map(item.supplement_number_types, (supplement, index) => (
              <div key={index} css={supplement_value_style}>
                <SubscriptionAmount
                  subscription={item}
                  value_override={supplement.price_per_year_excluding_vat_euros}
                />
              </div>
            ))}
          </>
        )
      case 'billing_frequency':
        if (item.billing_frequency === 'monthly') {
          return 'Monthly'
        } else if (item.billing_frequency === 'annually') {
          return 'Yearly'
        } else {
          return item.billing_frequency
        }

      case 'created_at':
        return <Timestamp value={item.created_at} format="date" />
      case 'status_name':
        const status_info = get(
          SUBSCRIPTION_STATUS_MAPPING,
          item.status_name,
          null
        )
        let status_name
        if (!status_info) {
          status_name = item.status_name
        } else {
          status_name = status_info['name']
        }
        return (
          <div
            css={[
              item.is_overdue ? overdue_style : null,
              item.is_cancelled_or_cancelling ? cancelled_style : null,
            ]}
          >
            {status_name}
          </div>
        )
      case 'expires_at':
        return (
          <div
            css={[
              item.is_overdue ? overdue_style : null,
              item.is_cancelled_or_cancelling ? cancelled_style : null,
            ]}
          >
            {item.status_name === 'new' && <span />}

            {!includes(['new', 'needs_number'], item.status_name) && (
              <Timestamp
                value={item.expires_at}
                format="date"
                emptyLabel="When inactive"
              />
            )}
          </div>
        )
      case 'action':
        return (
          <InlineIconBar>
            {item.amount_owing_excluding_vat_euros > 0 && (
              <BlueButton onClick={() => that.onPaySubscription(item)}>
                Pay now
              </BlueButton>
            )}
            {that.renderPopupMenu(item)}
          </InlineIconBar>
        )
      default:
        return undefined
    }
  }

  renderFooter = () => {
    if (size(customerSubscriptionNumberPendingList.getVisibleObjects()) == 0) {
      return null
    }
    return (
      <PendingAssignmentNumbersCard
        itemList={customerSubscriptionNumberPendingList}
        render_inline={true}
      />
    )
  }

  render() {
    const {
      is_loading,
      headers,
      subscriptions,
      empty_message,
      customer_can_create_subscriptions,
    } = this.props
    return (
      <MainLayout
        active_key="subscriptions"
        enable_breadlinks={true}
        breadcrumbs={[
          { name: 'home' },
          {
            name: 'subscriptions',
            label: 'Subscriptions',
            url: '/subscriptions',
          },
        ]}
      >
        <Container fluid>
          <Separator variant="h20" />

          <Row>
            <Col md={12}>
              <CardHeader
                left_child={null}
                align_with_common_table={true}
                title={<Trans>Subscriptions</Trans>}
                right_child={
                  <>
                    {customer_can_create_subscriptions && (
                      <>
                        <WhiteButton onClick={this.onAddFaxSubscription}>
                          <Trans>Add fax subscription</Trans>
                        </WhiteButton>

                        <Separator variant="w10" />
                        <WhiteButton onClick={this.onAddVoiceSubscription}>
                          <Trans>Add voice subscription</Trans>
                        </WhiteButton>
                      </>
                    )}
                  </>
                }
              />

              <Separator variant="h15" />
              <CommonTable
                is_loading={is_loading}
                empty_message={empty_message}
                headers={headers}
                items={subscriptions}
                item_list={customerSubscriptionList}
                renderCell={this.renderCell}
                getCellValue={this.getCellValue}
                renderFooter={this.renderFooter}
              />
            </Col>
          </Row>
        </Container>
      </MainLayout>
    )
  }
}

function mapStateToProps(state, props) {
  const { empty_message } = props
  const subscriptions = customerSubscriptionList.getVisibleObjects()
  const subscription_ids_which_can_upgrade = map(
      customerSubscriptionListCanUpgrade.getAllObjects(),
      (x) => x.id
  )
  const customer_can_create_subscriptions =
    globalSettingsList.getSetting('customer_can_create_subscriptions') === true
  return {
    subscriptions,
    subscription_ids_which_can_upgrade,
    is_loading: customerSubscriptionList.isLoading(),
    headers: {
      product_name: { name: <Trans>Subscription</Trans> },
      number: { name: <Trans>Number</Trans> },
      price: { name: <Trans>Price</Trans> },
      billing_frequency: { name: <Trans>Cycle</Trans> },
      created_at: { name: <Trans>Created at</Trans> },
      status_name: { name: <Trans>Status</Trans> },
      expires_at: { name: <Trans>Expires at</Trans> },
      action: { name: '' },
    },
    empty_message: empty_message || <Trans>There are no subscriptions</Trans>,
    customer_can_create_subscriptions,
    number_types_by_id: numberTypes.getObjectsById(),
  }
}

export default connect(mapStateToProps)(Subscriptions)

const subscription_modal_currency_value_row_style = css`
  display: flex;
`

const header_right_child = css`
  display: flex;
  align-items: center;
`

const price_style = css`
  display: flex;
`

const overdue_style = css`
  color: ${theme.colors.warning_red};
`

const cancelled_style = css`
  color: ${theme.colors.dark_grey};
`

const supplement_value_style = css`
  color: ${theme.colors.middle_grey};
`

const overlay_trigger_container_style = css`
  position: relative;
`

const action_button_style = css`
  width: 50px;
  color: #b8b8bd;
  text-decoration: none;
`
