/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { ButtonBar } from '../../components/layout/ButtonBar'
import { Separator } from '../../components/layout/Separator'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { filter, head, get } from 'lodash'
import { Table, Row, Col } from 'react-bootstrap'
import { Tristate } from '../../components/form/Tristate'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { AdminTableFilterPopup } from './AdminTableFilterPopup'
import { FormikInputField } from '../../components/form/InputField'
import { Link } from 'react-router-dom'
import {showSuccess, showError} from '../../actions/Error'
import { confirmModal } from '../../actions/ui'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import CurrencyValue from '../../components/CurrencyValue'
import Timestamp from '../../components/Timestamp'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { adminCallForwardingCostStructureList } from '../actions/admin_call_forwarding_cost_structure'
import { adminCallForwardingCostStructureVariantList } from '../actions/admin_call_forwarding_cost_structure_variant'
import { adminOutgoingVoiceServiceProviderList } from '../actions/admin_outgoing_voice_service_provider'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { Button, Container } from 'react-bootstrap'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFilter from './AdminTableFilter'

class AdminCallForwardingCostStructures extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminCallForwardingCostStructureList.fetchListIfNeeded())
        dispatch(adminCallForwardingCostStructureVariantList.fetchListIfNeeded())
        dispatch(adminOutgoingVoiceServiceProviderList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(adminCallForwardingCostStructureList.fetchListIfNeeded())
    }

    onEditCallForwardingCostStructure = (evt, item) => {
        const { history } = this.props
        history.push({
            pathname: '/admin/call_forwarding_cost_structure/'+item.id,
        })
    }

    onAddCallForwardingCostStructure = () => {
        const { history } = this.props
        history.push('/admin/call_forwarding_cost_structure')
    }

    // onDeleteCallForwardingCostStructure = (item) => {
    //     const { dispatch, history } = this.props
    //     const on_ok = function(json) {
    //         showSuccess("Deleted", "Cost has been deleted")
    //     }

    //     const onConfirmed = () => {
    //         return dispatch(adminCallForwardingCostStructureList.deleteObject(item.id)).then(on_ok)
    //     }
        
    //     const text = <span>Are you sure you want to delete cost for {item.country_name} (variant {item.variant_name}) ?</span>
    //     return dispatch(confirmModal({text:text,
    //                                   onConfirmed: onConfirmed}))
        
    // }
    
    filterCallForwardingCostStructures = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminCallForwardingCostStructureList.updateListFilter(filter_values))
    }


    getCellValue = (header_key, item, index) => {
        const { outgoing_voice_service_providers } = this.props
        switch( header_key ) {
            case 'destination_phone_number_prefix':
                const v = item.destination_phone_number_prefix
                return v ? v : 'default'
            case 'cost_per_page_excluding_vat_euros':
                return (
                    <CurrencyValue value={item.cost_per_page_excluding_vat_euros} />
                )
            case 'action':
                return (
                    <ButtonBar>
                      <InlineIcon icon_name="eye" onClick={(evt) => this.onEditCallForwardingCostStructure(evt, item)} />
                      { false &&
                        <>
                          <Separator variant="w5" />
                          <InlineIcon icon_name="delete" onClick={(evt) => this.onDeleteCallForwardingCostStructure(item)} />
                        </>
                      }
                    </ButtonBar>
                )
            case 'is_active':
                return <>
                         { item.is_active === true && <InlineIcon icon_name='status-success' /> }
                         { item.is_active !== true && <InlineIcon icon_name='status-error' /> }
                       </>

            case 'outgoing_voice_service_provider':
                return get(head(filter(outgoing_voice_service_providers, (x) => x.id === item.outgoing_voice_service_provider)), "name", "Default")

            default:
                return undefined
        }
    }

    renderFilter = (formik_props) => {
        const { variant_options } = this.props
        return (

            <AdminTableFilterPopup popup_fields={(
                <>
                  <Row>
                    <Col md="12">

                      <FormikDropdownField name="variant"
                                           formik_props={formik_props}
                                           options={variant_options}
                                           empty_selection_label="Any variant"
                                           placeholder="Variant" />
                    </Col>
                  </Row>
                  <Separator variant="h20" />
                  <Row>
                    <Col>
                      <Tristate name="is_active"
                                placeholder="Enabled"
                                label_overrides={{true: 'Enabled', false: 'Disabled'}}
                                formik_props={formik_props}/>
                    </Col>
                  </Row>
                  <Separator variant="h20" />
                  <Row>
                    <Col>
                      <FormikInputField name="destination_network_name"
                                        formik_props={formik_props}
                                        placeholder="Network" />
                      
                    </Col>
                  </Row>
                </>
            )}
                                   fixed_fields={(
                                       <Row>
                                         <Col>
                                           <FormikInputField name="any_field"
                                                             formik_props={formik_props}
                                                             placeholder="Prefix, country" />
                                         </Col>
                                         <Col>
                                           <Button variant="outline-primary" onClick={ this.onAddCallForwardingCostStructure }>
                                             Add call forwarding cost
                                           </Button>
                                         </Col>
                                       </Row>
                                   )}
            />
        )
    }

    render() {
        const { is_loading, headers, call_forwarding_cost_structures } = this.props
        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'call_forwarding_cost_structures', label: 'Call forwarding costs', url: '/admin/call_forwarding_cost_structures'}]}>
              <Container fluid>
                <AdminTableHeader title="Call forwarding costs">
                  <AdminTableFilter updateOnChange={ this.filterCallForwardingCostStructures }
                                    form="ADMIN_CALL_FORWARDING_COST_STRUCTURES_FILTER_FORM"
                                    renderFilter={ this.renderFilter }
                  />
                  
                </AdminTableHeader>
                <CommonTable
                    is_loading={ is_loading }
                    empty_message={`There are no call forwarding costs.`}
                    headers={ headers }
                    items={ call_forwarding_cost_structures }
                    item_list={adminCallForwardingCostStructureList}
                    getCellValue={ this.getCellValue }
                />
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const call_forwarding_cost_structures = adminCallForwardingCostStructureList.getVisibleObjects()
    return {
        call_forwarding_cost_structures,
        is_loading: adminCallForwardingCostStructureList.isLoading(),
        headers: {
            destination_network_name: {'name': 'Network'},
            destination_phone_number_prefix: {name: 'Phone number prefix (longer prefixes take precedence)'},
            country_name: {name: 'Country'},
            cost_per_minute_excluding_vat_euros: { name: 'Cost per minute' },
            setup_cost_excluding_vat_euros: { name: 'Setup cost' },
            is_active: { name: 'Active' },
            variant_name: { name: 'Cost variant' },
            outgoing_voice_service_provider: { name: 'Provider' },
            action: { name: '', column_size: 1 },
        },
        variant_options: adminCallForwardingCostStructureVariantList.getAsOptions(),
        outgoing_voice_service_providers: adminOutgoingVoiceServiceProviderList.getVisibleObjects()
    }
}

export default connect(mapStateToProps)(AdminCallForwardingCostStructures)

const filter_field_row_style = css`
display: flex;
`
