/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx } from '@emotion/react'
import { PhoneNumber } from '../../components/PhoneNumber'
import { map, size, head, get } from 'lodash'
import {showSuccess, showError} from '../../actions/Error'
import { adminUserList } from '../actions/admin_user'
import { Link } from 'react-router-dom'
import { InlineIcon } from '../../components/layout/InlineIcon'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import { adminAsteriskRoutingLookupList } from '../actions/admin_asterisk_routing_lookup'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { Button, Container } from 'react-bootstrap'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFilter from './AdminTableFilter'

class AdminAsteriskRoutingLookups extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminAsteriskRoutingLookupList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(adminAsteriskRoutingLookupList.fetchListIfNeeded())
    }

    onShowRequest = (item) => {
        const { history } = this.props
        history.push(`/admin/asterisk_routing_lookup/${item.id}`)
    }

    onRequestNewData = (values) => {
        const { history, dispatch } = this.props
        const that = this
        const on_ok = function(json) {
            dispatch(adminAsteriskRoutingLookupList.invalidateList())
            showSuccess("Saved", "Routing lookup created")
        }
        return dispatch(adminAsteriskRoutingLookupList.requestNewData(values)).then(on_ok)
    }

    filterRoutingLookups = (values) => {
        const { dispatch } = this.props
        dispatch(adminAsteriskRoutingLookupList.updateListFilter(values))
        dispatch(adminAsteriskRoutingLookupList.fetchListIfNeeded(values))
    }

    onNewRoutingLookup = () => {
        const { history } = this.props
        history.push("/admin/asterisk_routing_lookup")
    }

    getCellValue = (header_key, item, index) => {
        const that = this
        const { customer_id } = this.props
        switch( header_key ) {
            case 'status':
                return (
                    <div>
                      { item.status === 'collecting' && <InlineIcon icon_name="status-in-progress" /> }
                      { item.status === 'cancelled' && <InlineIcon icon_name="cancel" /> }
                      { item.status === 'done' && <InlineIcon icon_name="status-success" /> }
                      {size(item.received_servers)} / {size(item.all_servers)}
                    </div>
                )
            break
            case 'phone_number':
                return <PhoneNumber phone_number={item.number} />
            case 'created_at':
                return <Timestamp value={item.created_at} format="datetime" />
            case 'action':
            return (
                <div>
                  <InlineIcon icon_name="eye" onClick={(evt) => that.onShowRequest(item)} />
                </div>
            )
            default:
                return undefined
        }
    }

    render() {
        const { is_loading, items, headers } = this.props
        return (
            <AdminMainLayout title='Asterisk Routing Lookup'
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'asterisk_routing_lookup', label: 'Asterisk routing lookup', url: '/admin/asterisk_routing_lookups'}]}>

                <AdminTableHeader>
                  <AdminTableFilter updateOnChange={ this.filterRoutingLookups } />
                  <Button variant="outline-primary" onClick={ this.onNewRoutingLookup }>
                    New routing lookup
                  </Button>
                </AdminTableHeader>
                
                <CommonTable
                    is_loading={ is_loading }
                    empty_message={`No lookups`}
                    headers={ headers }
                    items={ items }
                    item_list={adminAsteriskRoutingLookupList}
                    getCellValue={ this.getCellValue }
                />
            </AdminMainLayout>
            
        )
    }
    
}

function mapStateToProps(state, props) {
    const items = adminAsteriskRoutingLookupList.getVisibleObjects()
    return {
        is_loading: adminAsteriskRoutingLookupList.isLoading(),
        items,
        headers: {
            phone_number: {name: "Phone number"},
            requested_by_user_email: {name: "Requested by"},
            status: { name: 'Status' },
            created_at: { name: 'Created at' },
            action: { name: '' },
        }
    }
}

export default connect(mapStateToProps)(AdminAsteriskRoutingLookups)
