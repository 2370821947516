import { ItemList } from '../../orm'
import { map } from 'lodash'

class AdminPhoneNumberPrefixList extends ItemList {
    getEntityKey() {
        return "admin/phone_number_prefix"
    }

}

export const adminPhoneNumberPrefixList = new AdminPhoneNumberPrefixList("admin_phone_number_prefix_default")
export const adminPhoneNumberPrefixListForSet = new AdminPhoneNumberPrefixList("admin_phone_number_prefix_for_set_default")
