/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { jsx, css } from '@emotion/react';
import { Container } from 'react-bootstrap';
import MainLayout from './MainLayout';
import { UpgradePathNotifications } from './UpgradePathNotifications';
import { Numbers } from './Numbers';
import CallHistoryCard from './CallHistoryCard';

const Home = () => {
  return (
    <MainLayout>
      <Container fluid>
        <UpgradePathNotifications display_style="call_history" />

        <UpgradePathNotifications display_style="voice_numbers" />

        <Numbers />
        <CallHistoryCard />
      </Container>
    </MainLayout>
  );
};

export default Home;
