/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { head, get, map, keys, filter, union } from 'lodash'
import { adminUserList } from '../actions/admin_user'
import { Separator } from '../../components/layout/Separator'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormLabelValue } from '../../components/form/FormLabelValue'
import Loading from '../../components/Loading'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import CurrencyValue from '../../components/CurrencyValue'
import BooleanValue from '../../components/BooleanValue'
import { FormikInputField } from '../../components/form/InputField'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { FormikRadioGroupField } from '../../components/form/RadioGroupField'
import { adminCustomerList } from '../actions/admin_customer'
import { adminCustomerOutgoingFaxSettingsList } from '../actions/admin_customer_outgoing_fax_settings'
import { adminCustomerIncomingFaxByEmailSettingsList } from '../actions/admin_customer_incoming_fax_by_email_settings'
import { adminCustomerFaxFromEmailAddressList } from '../actions/admin_customer_fax_from_email_address'
import { FormikDatePicker } from '../../components/form/DatetimePicker'
import AdminFaxFromEmailAddressApprovalForm from './AdminFaxFromEmailAddressApprovalForm'
import CommonIncomingFaxByEmailSettingsFormFields from '../../components/CommonIncomingFaxByEmailSettingsFormFields'
import CommonFaxFromEmailAddresses from '../../components/CommonFaxFromEmailAddresses'
import { FormikDropdownField } from '../../components/form/Dropdown'
import Card from '../../components/layout/Card'
import { BlueButton } from '../../components/layout/BlueButton'
import { Col, Row, Container, Navbar, Nav, Button } from 'react-bootstrap'
import { Form as BootstrapForm } from 'react-bootstrap'
import * as Yup from 'yup'
import {showSuccess, showError} from '../../actions/Error'
import { handleSubmitResult } from '../../actions/form'
import { adminOutgoingFaxServiceProviderList } from '../actions/admin_outgoing_fax_service_provider'

import {
    INCOMING_FAX_BY_EMAIL_INITIAL_VALUES_DEFAULT,
    OUTGOING_FAX_INITIAL_VALUES_DEFAULT,
} from '../../actions/form_vars'

const validationSchema = Yup.object().shape({
})

class AdminCustomerFaxSettings extends Component {

    componentDidMount() {
        const { dispatch, customer_id, customer_subscription_id } = this.props
        dispatch(adminCustomerList.ensureObjectLoaded(customer_id))

        dispatch(adminCustomerFaxFromEmailAddressList.updateListFilter({customer: customer_id}))
        
        dispatch(adminCustomerOutgoingFaxSettingsList.updateListFilter({customer: customer_id}))
        dispatch(adminCustomerOutgoingFaxSettingsList.fetchListIfNeeded())
        
        dispatch(adminCustomerIncomingFaxByEmailSettingsList.updateListFilter({customer: customer_id}))
        dispatch(adminCustomerIncomingFaxByEmailSettingsList.fetchListIfNeeded())
        dispatch(adminOutgoingFaxServiceProviderList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch, customer_id, outgoing_fax_settings_filter, incoming_fax_by_email_settings_filter, fax_from_email_address_filter  } = this.props
        
        dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
        
        if ( prev_props.customer_id != customer_id && outgoing_fax_settings_filter.customer_id != customer_id ) {
            dispatch(adminCustomerOutgoingFaxSettingsList.updateListFilter({customer: customer_id}))
        }
        if ( prev_props.customer_id != customer_id && incoming_fax_by_email_settings_filter.customer_id != customer_id ) {
            dispatch(adminCustomerIncomingFaxByEmailSettingsList.updateListFilter({customer: customer_id}))
        }

        if ( prev_props.customer_id != customer_id && fax_from_email_address_filter.customer_id != customer_id ) {
            dispatch(adminCustomerFaxFromEmailAddressList.updateListFilter({customer: customer_id}))
        }
    }

    onSave = (values, formik_funcs) => {
        const { dispatch, history, incoming_fax_by_email_settings, outgoing_fax_settings, is_loading, customer_id } = this.props

        const that = this
        const on_ok = function() {
            dispatch(adminCustomerIncomingFaxByEmailSettingsList.invalidateList())
            dispatch(adminCustomerOutgoingFaxSettingsList.invalidateList())
            showSuccess("Saved", "Settings saved")
        }

        const save_incoming_settings = function() {
            const incoming_values = values.incoming
            incoming_values.customer = customer_id
            if ( incoming_fax_by_email_settings && incoming_fax_by_email_settings.id ) {
                incoming_values.id = incoming_fax_by_email_settings.id
                return dispatch(adminCustomerIncomingFaxByEmailSettingsList.saveObject(incoming_values))
                    .then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
            } else {
                return dispatch(adminCustomerIncomingFaxByEmailSettingsList.saveNewObject(incoming_values))
                    .then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
            }
        }

        const save_outgoing_settings = function() {
            const outgoing_values = values.outgoing
            outgoing_values.customer = customer_id
            if ( outgoing_fax_settings && outgoing_fax_settings.id ) {
                outgoing_values.id = outgoing_fax_settings.id
                return dispatch(adminCustomerOutgoingFaxSettingsList.saveObject(outgoing_values))
                    .then((res) => handleSubmitResult({res, formik_funcs, on_ok: save_incoming_settings}))
            } else {
                return dispatch(adminCustomerOutgoingFaxSettingsList.saveNewObject(outgoing_values))
                    .then((res) => handleSubmitResult({res, formik_funcs, on_ok: save_incoming_settings}))
            }
        }

        save_outgoing_settings()
    }

    renderApproveDeclineForm = (email_address) => {
        if ( ! email_address || ! email_address.id ) {
            return null
        }
        return (
            <div>
              <div>
                Change approval status of this email address:
              </div>
              <AdminFaxFromEmailAddressApprovalForm email_address={email_address} />
            </div>
        )
    }

    renderCreditEdgeCases(formik_props) {
        return (
            <Row>
              <Col>
                <Separator variant="h40" />
                <FormLabelValue>
                  The number of Euros this customer can go into the red on their credit balance and still be able to send faxes
                  <FormikInputField name="outgoing.credit_excluding_vat_euros_maximum_overrun_amount"
                                    formik_props={formik_props}
                                    placeholder="Euros" />
                </FormLabelValue>
                <Separator variant="h20" />
              </Col>
            </Row>
        )
    }

    renderOutgoingFaxServiceProvider(formik_props) {
        const { outgoing_fax_service_provider_options } = this.props
        return (
            <Row>
              <Col>
                <Separator variant="h40" />
                <FormLabelValue>
                  Outbound Fax Service Provider (optional)
                  <FormikDropdownField name="outgoing.outgoing_fax_service_provider"
                                       formik_props={formik_props}
                                       options={outgoing_fax_service_provider_options}
                                       placeholder="Choose outgoing fax service provider" />
                </FormLabelValue>
                <Separator variant="h20" />
              </Col>
            </Row>
        )
    }

    render() {
        const { initial_values, is_loading, is_busy, customer_id } = this.props

        return (
            <Row>
              <Col>
                <Formik
                    initialValues={initial_values}
                    onSubmit={this.onSave}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                >

                  {formik_props => {
                       const { values } = formik_props

                       return (
                           <Form>
                             <FormikGeneralFormErrors />
                             <CommonIncomingFaxByEmailSettingsFormFields formik_props={formik_props} />

                             { this.renderOutgoingFaxServiceProvider(formik_props) }
                             { this.renderCreditEdgeCases(formik_props) }
                             
                             <BlueButton type="submit">
                               Save
                             </BlueButton>
                             
                           </Form>
                       )
                  }}
                </Formik>
              </Col>
              <Col>
                <CommonFaxFromEmailAddresses customer_id={customer_id}
                                             faxFromEmailAddressList={adminCustomerFaxFromEmailAddressList}
                                             supplementary_form_render={this.renderApproveDeclineForm}
                />
              </Col>
            </Row>
        )
    }
}

function mapStateToProps(state, props) {
    const { customer_id } = props
    const customer = adminCustomerList.getObject(customer_id)

    const outgoing_fax_settings = head(adminCustomerOutgoingFaxSettingsList.getVisibleObjects())
    const incoming_fax_by_email_settings = head(adminCustomerIncomingFaxByEmailSettingsList.getVisibleObjects())
    
    return {
        customer_id,
        customer,
        is_loading: adminCustomerOutgoingFaxSettingsList.isLoading() ||
                    adminCustomerIncomingFaxByEmailSettingsList.isLoading(),
        is_busy: adminCustomerOutgoingFaxSettingsList.getIsSavingObject() || adminCustomerIncomingFaxByEmailSettingsList.getIsSavingObject(),
        incoming_fax_by_email_settings_filter: adminCustomerIncomingFaxByEmailSettingsList.getFilter(),
        outgoing_fax_settings_filter: adminCustomerOutgoingFaxSettingsList.getFilter(),
        fax_from_email_address_filter: adminCustomerFaxFromEmailAddressList.getFilter(),
        initial_values: { 'incoming': incoming_fax_by_email_settings || INCOMING_FAX_BY_EMAIL_INITIAL_VALUES_DEFAULT,
                          'outgoing': outgoing_fax_settings || OUTGOING_FAX_INITIAL_VALUES_DEFAULT },
        outgoing_fax_service_provider_options: union( [{label: 'default', value: null}], adminOutgoingFaxServiceProviderList.getAsOptions() )
    }
}

export default connect(mapStateToProps)(AdminCustomerFaxSettings)

const main = css`
background-color: ${theme.colors.card_background};
`
