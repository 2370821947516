import { ItemList } from '../../orm'
import { download } from '../../actions/lib'

class AdminCustomerReportList extends ItemList {
    getEntityKey() {
        return "admin/customer_report/generate"
    }

    createCreditsReport({filter}) {
        return this.saveNewObject({filter:filter})
    }

}

export const adminCustomerReportList = new AdminCustomerReportList("admin_customer_report__default")
