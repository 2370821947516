/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import AdminMainLayout from './AdminMainLayout'
import { Container } from 'react-bootstrap'
import AdminOutboundEmailList from './AdminOutboundEmailList'

class AdminOutboundEmails extends Component {

    render() {
        const { headers } = this.props
        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'outbound_emails', label: 'Outbound emails', url: '/admin/outbound_emails'}]}>
              <Container fluid >
                <AdminOutboundEmailList />
              </Container>
            </AdminMainLayout>
            
        )
    }
    
}

function mapStateToProps(state, props) {
    return {}
}

export default connect(mapStateToProps)(AdminOutboundEmails)
