/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { withRouter } from 'react-router-dom'
import { jsx, css, Global } from '@emotion/react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MobileOnly } from './layout/MobileOnly'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { clearAuthentication, loggedInUser } from '../actions/auth'
import { default_theme as theme } from '../emotion/theme'
import { Logo } from './layout/Logo'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Trans } from 'react-i18next'
import { customerList } from '../actions/customer'
import { globalSettingsList } from '../actions/settings'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import CreditBadge from './CreditBadge'
import CreditInformationPopup from './CreditInformationPopup'
import ProfileMenu from './ProfileMenu'
import ProfileMenuMobile from './ProfileMenuMobile'
import { BlueButton } from './layout/BlueButton'

class MenuTop extends Component {
  constructor(props) {
    super(props)
    this.state = { context_menu_open: false }
    this.onHideContextMenu = this.onHideContextMenu.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(customerList.ensureCustomerLoaded())
    dispatch(globalSettingsList.ensureGlobalSettingsLoaded())
  }

  onToggleContextMenu = (evt) => {
    evt.stopPropagation()
    const { context_menu_open } = this.state
    if (context_menu_open) {
      this.onHideContextMenu(evt)
    } else {
      this.onShowContextMenu(evt)
    }
    return false
  }

  onShowContextMenu = (evt) => {
    if (evt) {
      evt.stopPropagation()
    }
    this.setState({ context_menu_open: true })
  }

  onHideContextMenu = (evt) => {
    if (evt) {
      evt.stopPropagation()
    }
    this.setState({ context_menu_open: false })
  }

  onLogout = () => {
    const { dispatch, history } = this.props
    dispatch(clearAuthentication())
    history.push('/')
  }

  onSendFax = () => {
    const { history } = this.props
    history.push('/send_a_fax')
  }

  render() {
    const { customer, is_logged_in, is_reseller } = this.props
    const { context_menu_open } = this.state
    const active_key = this.props.active_key || 'home'

    const has_fax_in = customer.has_active_fax_in_subscription
    const has_fax_out = customer.has_active_fax_out_subscription

    return (
      <Container fluid css={container} className="menutop_container">
        <Global styles={global_styles} />
        <Row className="main_row">
          {context_menu_open && (
            <ProfileMenuMobile onHideContextMenu={this.onHideContextMenu} />
          )}
          <Col>
            <Navbar expand="lg">
              <div css={brand}>
                <Link to="/home">
                  <Logo />
                </Link>
              </div>
              <MobileOnly onClick={this.onToggleContextMenu}>
                <FontAwesomeIcon icon={faBars} />
              </MobileOnly>
              <Navbar.Collapse css={navbar_collapse}>
                <Nav
                  className="ml-auto right_menu custom_menu"
                  activeKey={active_key}
                >
                  {!is_logged_in && (
                    <Nav.Item>
                      <Link to="/" className="inactive">
                        <Trans>Login</Trans>
                      </Link>
                    </Nav.Item>
                  )}

                  {is_logged_in && is_reseller && (
                    <Nav.Item>
                      <Link
                        to="/reseller"
                        className={
                          (active_key === 'reseller' && 'active') || 'inactive'
                        }
                      >
                        <Trans>Customers</Trans>
                      </Link>
                    </Nav.Item>
                  )}

                  {is_logged_in && [
                    <Nav.Item key="sendFax">
                      <Link
                        to={!has_fax_out ? '/subscription/fax' : '/send_a_fax'}
                      >
                        Fax
                      </Link>
                    </Nav.Item>,
                    <NavDropdown
                      key="credit"
                      css={profile_dropdown_toggle}
                      title={<CreditBadge />}
                      eventKey="credit"
                    >
                      <CreditInformationPopup />
                    </NavDropdown>,

                    <NavDropdown
                      key="user"
                      css={profile_dropdown_toggle}
                      title={
                        <FontAwesomeIcon
                          icon={faUserCircle}
                          css={menu_icon_style}
                        />
                      }
                      eventKey="user"
                    >
                      <ProfileMenu />
                    </NavDropdown>,
                  ]}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Col>
        </Row>
      </Container>
    )
  }
}

function mapStateToProps(state, props) {
  const user = loggedInUser()
  const customer = customerList.getCustomer()
  globalSettingsList.ensureGlobalSettingsLoaded()
  return {
    email: user && user.email,
    is_logged_in: user && user.id,
    is_reseller: get(customer, 'is_reseller'),
    customer,
  }
}
export default withRouter(connect(mapStateToProps)(MenuTop))

const global_styles = css`
  .menutop_container .main_row {
    height: 46px;
    padding-top: 5px;
    width: 100%;
  }

  .menutop_container .navbar {
    padding-top: 12px;
    font-weight: 500;
  }

  .menutop_container .navbar-brand {
    padding-top: 0px;
  }

  .menutop_container .navbar.navbar-light {
    min-height: 28px;
    padding: 0px;
  }
  .menutop_container .navbar {
    margin-top: 4px;
    height: 28px;
  }

  .menutop_container .navbar-brand {
    padding-top: 7px;
  }

  .menutop_container .navbar-light .navbar-nav.left_menu a {
    color: rgba(0, 0, 0, 1);
    /*height: 57px;*/
    height: 25px;
    padding-bottom: 11px;
    padding-left: 4px;
    padding-right: 4px;
  }

  .menutop_container .navbar-light .navbar-nav.left_menu .nav-item:hover {
    border-radius: 4px;
    background-color: #e0ecfa;
    box-shadow: 0 0 0px 6px #e0ecfa;
  }

  .menutop_container .navbar-light .navbar-nav.custom_menu a:hover {
    text-decoration: none;
  }

  .menutop_container .navbar-light .navbar-nav.right_menu {
    align-items: center;
  }

  .menutop_container .navbar-light .navbar-nav.custom_menu a {
    color: rgba(0, 0, 0, 1);
  }

  .menutop_container .nav-item {
    margin-left: 23px;
    /*align-items: center;*/
    /*display: flex;*/
    line-height: 1.4;
  }

  .menutop_container .navbar-nav {
    height: 22px;
  }

  .dropdown-menu {
    padding: 10px;
  }
  .dropdown-item {
    margin-bottom: 2px;
    padding: 6px 0;
    height: 30px !important;
    border-radius: 4px;
  }

  .menutop_container .navbar .active {
    border-bottom: 3px solid #4178be;
    font-weight: 500;
    color: ${theme.colors.primary_blue} !important;
  }

  .menutop_container .navbar-brand {
    min-height: 0px;
  }

  .context_menu_modal {
    // margin-right: 20px;
  }
`

const container = css`
  background-color: #fff;
  padding-left: 30px;
  position: sticky;
  top: 0px;
  z-index: 10;
  border-bottom: 1px solid #e5e5ea;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
`

const brand = css`
  min-height: 0px;
`

const navbar_collapse = css`
  min-height: 38px;
  font-weight: 500;
`

const menu_icon_style = css`
  color: #b8b8bd;
  font-size: 28px;
`

const profile_dropdown_toggle = css`
  margin-left: 4px !important;

  a::after {
    display: none; /* hide the toggle carat */
  }

  &:hover {
    font-weight: 400;
  }
`

const popup_menu_style = css`
  padding-top: 16px;
  padding-bottom: 19px;
  min-width: 243px;
  padding-right: 20px;
  padding-left: 20px;
  position: absolute;
  right: 80px;
  top: -10px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.15);
  font-weight: 400;
`

const profile_menu_bottom_style = css`
  padding-left: 25px;
`

const profile_menu__name_style = css`
  color: ${theme.colors.menu_black};
  font-size: 16px;
  font-weight: 600;
`

const profile_menu__info_style = css`
  color: ${theme.colors.dark_grey};
  font-size: 14px;
  font-weight: 400;
`

const profile_menu__item = css`
  color: ${theme.colors.black};
  font-size: 16px;
  margin-top: 14px;
  a:hover {
    color: ${theme.colors.primary} !important;
  }
`

const support_menu__name_style = css`
  color: ${theme.colors.menu_black};
  font-size: 16px;
  font-weight: 600;
`

const support_container_style = css`
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
  padding-bottom: 0px;
`

const support_menu__section = css`
  margin-bottom: 20px;
`
const support_menu__heading = css`
  font-size: 14px;
  color: ${theme.colors.dark_grey};
  font-weight: 500;
`

const support_menu__value = css`
  font-weight: 500;
  font-size: 16px;
`

const support_menu__link = css`
  color: ${theme.colors.primary} !important;
`

const context_menu_modal = css`
  // margin-right: 20px;
`

const context_menu_modal_body = css`
  display: flex;
  flex-direction: column;
  padding: 0px;
`
const context_menu_modal_header = css`
  padding-left: 25px;
  padding-right: 25px;
`

const credit_container_style = css`
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
  padding-bottom: 0px;
  min-width: 300px;
`
const credit_menu__name_style = css`
  color: ${theme.colors.menu_black};
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
`

const credit_label = css`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 15px 25px;
  border-bottom: 1px solid ${theme.colors.light_middle_grey};
}
`

const support_menu_section_mobile = css`
  display: grid;
  grid-template-columns: min-content auto;
  gap: 4px;
  margin-top: 4px;
`

const context_menu_section = css`
  padding: 15px 25px;
  border-bottom: 1px solid ${theme.colors.light_middle_grey};
`
