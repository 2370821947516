/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import {
  min,
  cloneDeep,
  filter,
  get,
  isEqual,
  map,
  size,
  head,
  last,
} from 'lodash'
import { RowWithGutter } from './layout/RowWithGutter'
import MainLayout from './MainLayout'
import { Trans, Translation } from 'react-i18next'
import { Pluralize } from './Pluralize'
import Loading from './Loading'
import { default_theme as theme } from '../emotion/theme'
import { topupList } from '../actions/topup'
import FormikOnChange from './form/FormikAutoSave'
import { loggedInUser } from '../actions/auth'
import CurrencyValue from './CurrencyValue'
import CardParagraphSubHeading from './layout/CardParagraphSubHeading'
import {
  Container,
  Nav,
  Row,
  Col,
  Button,
  Accordion,
  Card as BootstrapCard,
} from 'react-bootstrap'
import WrappingBusyMask from './WrappingBusyMask'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikRadioGroupField } from './form/RadioGroupField'
import { Form as BootstrapForm } from 'react-bootstrap'
import Card from './layout/Card'
import { CallHistoryTitle } from './CallHistoryTitle'
import { outgoingFaxSettingsList } from '../actions/outgoing_fax_settings'
import { customerList } from '../actions/customer'
import { WhiteButton } from './layout/WhiteButton'
import { FormikInputField } from './form/InputField'
import { FormikSimpleCheckboxField } from './form/CheckboxField'
import FieldInfoText from './layout/FieldInfoText'
import { FormLabel } from './form/FormLabel'
import { CardHeader } from './layout/CardHeader'
import CardParagraphHeading from './layout/CardParagraphHeading'
import { BlueButton } from './layout/BlueButton'
import { Separator } from './layout/Separator'
import { SeparatorWithLine } from './layout/SeparatorWithLine'
import CustomerPhoneBook from './CustomerPhoneBook'
import { FormikMultiplePhoneNumberField } from './form/MultiplePhoneNumberField'
import { outgoingFaxQueueList } from '../actions/outgoing_fax_queue'
import { outgoingFaxQueueProvisionalCosting } from '../actions/outgoing_fax_queue_provisional_costing'
import { showSuccess, showError } from '../actions/Error'
import { FormikFileSetField } from './form/FileSetField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CreditInformationCard from './CreditInformationCard'
import { UpgradePathNotificationExVat } from './UpgradePathNotificationExVat'
import { BlueLinkButton } from './layout/BlueLinkButton'
import { AccordionCaretToggle } from './layout/AccordionCaretToggle'
import { FormikGeneralFormErrors } from './form/GeneralFormErrors'
import { UpgradePathNotifications } from './UpgradePathNotifications'
import { handleSubmitResult } from '../actions/form'
import * as Yup from 'yup'
import {
  ATTACH_FAX_TO_CONFIRMATION_EMAIL_OPTIONS,
  SEND_MULTIPLE_FILES_OPTIONS,
  OUTGOING_FAX_INITIAL_VALUES_DEFAULT,
} from '../actions/form_vars'

const validationSchema = Yup.object().shape({
  destination_phone_numbers: Yup.array().of(
    Yup.object({
      number: Yup.string().nullable().phoneNumber(),
    })
  ),
})

class SendAFaxCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show_fax_sent_msg: false,
      is_calculating: false,
      redirecting: false,
      prev_comparable_values: null,
    }
  }

  componentDidMount() {
    const { dispatch, user, provisional_outgoing_fax_id } = this.props

    if (!provisional_outgoing_fax_id) {
      this.checkSaveUpdateProvisionalFax({})
    } else {
      dispatch(outgoingFaxSettingsList.updateListFilter({ customer: user.id }))
      dispatch(outgoingFaxSettingsList.fetchListIfNeeded())
      dispatch(customerList.ensureCustomerLoaded())
      dispatch(
        outgoingFaxQueueList.ensureObjectLoaded(provisional_outgoing_fax_id)
      )
      this.checkFaxCanBeEdited()
    }
    dispatch(topupList.fetchListIfNeeded())
  }

  componentDidUpdate() {
    const { dispatch, provisional_outgoing_fax_id } = this.props
    if (!provisional_outgoing_fax_id) {
      this.checkSaveUpdateProvisionalFax({})
    } else {
      dispatch(customerList.ensureCustomerLoaded())
      dispatch(
        outgoingFaxQueueList.ensureObjectLoaded(provisional_outgoing_fax_id)
      )
      this.checkFaxCanBeEdited()
    }
    dispatch(topupList.fetchListIfNeeded())
  }

  checkFaxCanBeEdited() {
    const { history, provisional_outgoing_fax } = this.props
    if (
      provisional_outgoing_fax &&
      provisional_outgoing_fax.id &&
      provisional_outgoing_fax.status_name !== 'PROVISIONAL'
    ) {
      history.push('/send_a_fax/')
    }
  }

  onSendFax = (values, formik_funcs) => {
    const { resetForm } = formik_funcs
    const { dispatch, outgoing_fax_settings, provisional_outgoing_fax_id } =
      this.props
    const that = this
    const on_ok = (json) => {
      dispatch(outgoingFaxQueueList.invalidateList())

      if (
        outgoing_fax_settings &&
        outgoing_fax_settings.id &&
        values.set_as_default
      ) {
        dispatch(
          outgoingFaxSettingsList.invalidateObject(outgoing_fax_settings.id)
        )
        dispatch(outgoingFaxSettingsList.invalidateList())
      }
      dispatch(customerList.invalidateCustomer())
      dispatch(customerList.ensureCustomerLoaded())
      showSuccess(<Trans>Queued</Trans>, <Trans>Fax added to queue</Trans>)
      that.setState({ show_fax_sent_msg: true })
      resetForm()
      window.scroll(0, 0)
    }
    window.scroll(0, 0)
    values.id = provisional_outgoing_fax_id
    return dispatch(outgoingFaxQueueList.addToQueue(values)).then((res) =>
      handleSubmitResult({ res, formik_funcs, on_ok })
    )
  }

  onStartSendNewFax = () => {
    const { history } = this.props
    this.setState({ show_fax_sent_msg: false })
    history.push('/send_a_fax/')
  }

  onGotoFaxLog = () => {
    const { history } = this.props
    history.push('/home?direction=outgoing&type=fax')
  }

  onBuyMoreCredit = (formik_props) => {
    const { history } = this.props
    this.triggerSave(formik_props)
    topupList.startBuyMoreCredit({ history })
  }

  checkSaveUpdateProvisionalFax = (values, formik_funcs) => {
    const { dispatch, history, provisional_outgoing_fax_id } = this.props
    const { prev_comparable_values, redirecting } = this.state

    if (redirecting) {
      return
    }

    if (!provisional_outgoing_fax_id) {
      this.setState({ redirecting: true })
      this.saveUpdateProvisionalFax(values)
      return
    } else if (redirecting) {
      this.setState({ redirecting: false })
    }
    const comparable_values = Object.assign({}, values, {
      destination_phone_numbers: filter(
        get(values, ['destination_phone_numbers'], []),
        (x) => get(x, 'number') !== null
      ),
    })

    this.setState({ prev_comparable_values: comparable_values })
    if (
      prev_comparable_values !== null &&
      !isEqual(comparable_values, prev_comparable_values)
    ) {
      this.saveUpdateProvisionalFax(values)
    }
  }

  saveUpdateProvisionalFax = (values) => {
    const that = this
    const { dispatch, history, provisional_outgoing_fax_id } = this.props
    const { redirecting } = this.state
    if (redirecting) {
      return
    }
    values = values || {}

    const on_ok = (json) => {
      if (!provisional_outgoing_fax_id) {
        that.setState({ redirecting: true })
        history.replace(`/send_a_fax/${json.id}`)
      }
      that.setState({ is_calculating: false, redirecting: false })
    }
    that.setState({ is_calculating: true })
    values.id = provisional_outgoing_fax_id
    const formik_funcs = {}
    dispatch(outgoingFaxQueueList.saveProvisional(values)).then((res) =>
      handleSubmitResult({ res, formik_funcs, on_ok })
    )
  }

  updateProvisionalCostInformation = (values) => {
    const that = this
    const { dispatch, history, provisional_outgoing_fax_id } = this.props
    values = cloneDeep(values || {})
    const on_ok = (json) => {
      that.setState({ is_calculating: false })
    }
    that.setState({ is_calculating: true })
    values.id = provisional_outgoing_fax_id
    const formik_funcs = {}
    dispatch(
      outgoingFaxQueueProvisionalCosting.updateProvisionalCosting(values)
    ).then((res) => handleSubmitResult({ res, formik_funcs, on_ok }))
  }

  triggerSave = (formik_props) => {
    this.saveUpdateProvisionalFax(formik_props.values)
  }

  renderMoreOptionsFields(formik_props) {
    return (
      <div>
        <BootstrapForm.Group>
          <FormLabel>
            <Trans>Set a fax header</Trans>
          </FormLabel>
          <Row>
            <Col css={fixed_width_input}>
              <FormikInputField
                name="fax_header"
                type="text"
                auto_scale={true}
                formik_props={formik_props}
                placeholder={OUTGOING_FAX_INITIAL_VALUES_DEFAULT.fax_header}
              />
              <FieldInfoText>
                <Trans>Max</Trans> 32 <Trans>Characters</Trans>
              </FieldInfoText>
            </Col>
          </Row>
        </BootstrapForm.Group>

        <Separator variant="h20" />

        <Row>
          <Col css={fixed_width_input}>
            <BootstrapForm.Group>
              <FormLabel>
                <Trans>Send multiple files as</Trans>
              </FormLabel>
              <FormikRadioGroupField
                auto_scale={true}
                formik_props={formik_props}
                name="send_multiple_files_choice"
                options={SEND_MULTIPLE_FILES_OPTIONS}
              />
            </BootstrapForm.Group>
          </Col>
        </Row>

        <Separator variant="h20" />

        <Row>
          <Col css={fixed_width_input}>
            <BootstrapForm.Group>
              <FormLabel>
                <Trans>Attach the fax to your confirmation email</Trans>
              </FormLabel>
              <FormikRadioGroupField
                auto_scale={true}
                formik_props={formik_props}
                name="attach_fax_to_confirmation_email"
                options={ATTACH_FAX_TO_CONFIRMATION_EMAIL_OPTIONS}
              />
            </BootstrapForm.Group>
          </Col>
        </Row>

        <Separator variant="h20" />

        <Row>
          <Col css={fixed_width_input}>
            <BootstrapForm.Group>
              <FormikSimpleCheckboxField
                name="set_as_default"
                formik_props={formik_props}
                label={<Trans>Set as default fax settings</Trans>}
              />
            </BootstrapForm.Group>
          </Col>
        </Row>
      </div>
    )
  }

  renderMoreOptions(formik_props) {
    return (
      <Accordion>
        <AccordionCaretToggle eventKey="0" />
        <Accordion.Collapse eventKey="0">
          <div>{this.renderMoreOptionsFields(formik_props)}</div>
        </Accordion.Collapse>
      </Accordion>
    )
  }

  renderPhoneNumberForm(formik_props) {
    const { calculated_cost_result } = this.props
    return (
      <Translation>
        {(t) => (
          <FormikMultiplePhoneNumberField
            name="destination_phone_numbers"
            placeholder={`${t('eg.')} 00312081002`}
            errors={{
              destination_phone_numbers: get(
                calculated_cost_result,
                'invalid_phone_numbers'
              ),
            }}
            formik_props={formik_props}
          />
        )}
      </Translation>
    )
  }

  renderSuccessMessage() {
    const { customer } = this.props
    return (
      <div>
        <CardParagraphHeading>
          <Trans>
            Your fax is in the queue for sending and will be sent shortly.
          </Trans>
        </CardParagraphHeading>

        <Separator variant="h30" />

        <div css={success_button_bar_style}>
          <BlueButton onClick={this.onStartSendNewFax}>
            <Trans>Send another fax</Trans>
          </BlueButton>

          <Separator variant="w20" />
          <BlueLinkButton onClick={this.onGotoFaxLog}>
            <Trans>Go to</Trans>&nbsp;
            <CallHistoryTitle customer={customer} lowercase={true} />
          </BlueLinkButton>
        </div>
      </div>
    )
  }

  renderCreditWarning = (formik_props) => {
    const { customer, minimum_topup_excluding_vat_euros } = this.props
    const needs_credits =
      get(customer, 'credits_excluding_vat_euros', 0) <= 0 &&
      !get(customer, 'has_active_postpaid_fax_sending_subscription')

    if (!needs_credits) {
      return null
    }

    return (
      <div css={topup_warning_style}>
        <CardParagraphSubHeading>
          <Trans>Top up your XOIP balance.</Trans>
        </CardParagraphSubHeading>

        <Trans
          i18nKey="send_a_fax__in_order_to_send_a_fax"
          components={[
            <CurrencyValue
              value={minimum_topup_excluding_vat_euros}
              use_span={true}
            />,
          ]}
          defaults="In order to send a fax, you must first buy some credit. Top ups start from <0></0>."
        />
        <Separator variant="h15" />
        <BlueButton
          onClick={() => this.onBuyMoreCredit(formik_props)}
          auto_disable={false}
        >
          <Trans>Buy Credit</Trans>
        </BlueButton>
      </div>
    )
  }

  renderSendFaxButton(formik_props) {
    const { customer, calculated_cost_result } = this.props
    const { is_calculating } = this.state
    const calculated_cost_is_up_to_date = calculated_cost_result !== null
    const has_enough_credit =
      calculated_cost_result &&
      calculated_cost_result.status === 'success' &&
      calculated_cost_result.has_enough_credit === true

    const page_and_cost = (
      <div css={page_and_cost_style}>
        {calculated_cost_result && (
          <Pluralize singular="page" count={calculated_cost_result.num_pages} />
        )}
        &nbsp;
        {calculated_cost_result && (
          <CurrencyValue
            value={calculated_cost_result.cost_excluding_vat_euros}
          />
        )}
      </div>
    )

    return (
      <div>
        <div css={send_fax_button_row}>
          <RowWithGutter css={send_fax_button_row_style}>
            <Col>
              {calculated_cost_is_up_to_date && (
                <>
                  {!has_enough_credit && (
                    <BlueButton
                      css={send_fax_button_disabled_style}
                      extra_css={send_fax_button_style}
                    >
                      <Trans>Send Fax</Trans>
                    </BlueButton>
                  )}
                  {has_enough_credit && (
                    <BlueButton
                      type="submit"
                      disabled={!has_enough_credit}
                      css_disabled_style={send_fax_button_style}
                      extra_css={send_fax_button_style}
                    >
                      <Trans>Send Fax</Trans>
                    </BlueButton>
                  )}
                </>
              )}
              {is_calculating && (
                <WhiteButton
                  type="submit"
                  disabled={true}
                  extra_css={send_fax_button_style}
                >
                  <Loading />
                </WhiteButton>
              )}
              {!calculated_cost_is_up_to_date && !is_calculating && (
                <WhiteButton
                  type="submit"
                  disabled={true}
                  extra_css={send_fax_button_style}
                >
                  <Trans>Send Fax</Trans>
                </WhiteButton>
              )}

              {calculated_cost_result &&
                calculated_cost_result.status === 'success' && (
                  <div css={calculated_cost_result_style}>
                    {has_enough_credit && (
                      <div css={calculated_cost_success}>{page_and_cost}</div>
                    )}

                    {!has_enough_credit && (
                      <div css={calculated_cost_error}>
                        <div>{page_and_cost}</div>
                      </div>
                    )}
                  </div>
                )}
            </Col>
            <Col css={col_pull_right_style}>
              <div css={calculated_cost_error_buy_credit}>
                <CreditInformationCard
                  mini={true}
                  onLeavingPage={() => this.triggerSave(formik_props)}
                />
              </div>
            </Col>
            {calculated_cost_result &&
              calculated_cost_result.status !== 'success' &&
              calculated_cost_result.status !== 'incomplete' && (
                <div css={calculated_cost_error_because_dest_phone_numbers}>
                  <Pluralize
                    singular="page"
                    count={calculated_cost_result.num_pages}
                  />
                </div>
              )}
          </RowWithGutter>
        </div>

        <UpgradePathNotificationExVat />

        <Row>
          <Col>
            {calculated_cost_result &&
              calculated_cost_result.status === 'success' &&
              !has_enough_credit && (
                <div css={calculated_cost_error_info_text}>
                  <Separator variant="h5" />
                  <Trans>Top up your credit to send this fax</Trans>
                </div>
              )}
            {calculated_cost_result &&
              calculated_cost_result.status !== 'success' &&
              calculated_cost_result.status !== 'incomplete' && (
                <div css={calculated_cost_error_dest_phone_numbers}>
                  <Trans>Cannot send, please check the errors above</Trans>{' '}
                  &nbsp;
                </div>
              )}
          </Col>
        </Row>
      </div>
    )
  }

  render() {
    const { dispatch, initial_values, is_ready, is_sending } = this.props
    const { show_fax_sent_msg, is_calculating } = this.state
    const that = this

    return (
      <Formik
        initialValues={initial_values}
        enableReinitialize={true}
        onSubmit={this.onSendFax}
        validationSchema={validationSchema}
      >
        {(formik_props) => {
          return (
            <Form>
              <FormikOnChange
                onChange={(values) =>
                  this.updateProvisionalCostInformation(values)
                }
              />
              <CardHeader
                title={<Trans>Send a Fax</Trans>}
                left_child={null}
                right_child={null}
              />

              <FormikGeneralFormErrors />
              <WrappingBusyMask
                is_loading={!is_ready || (is_sending && !is_calculating)}
              >
                <Card variant="white_wide_padding" with_padding_below={false}>
                  {show_fax_sent_msg && this.renderSuccessMessage()}

                  {!show_fax_sent_msg && (
                    <div>
                      <CardParagraphHeading>
                        <Trans>
                          Add one or more pdf files from your computer
                        </Trans>
                      </CardParagraphHeading>

                      <FormikFileSetField
                        name="fax_file_info_set"
                        file_types=".pdf"
                        formik_props={formik_props}
                        dispatch={dispatch}
                      />

                      <SeparatorWithLine />

                      <CardParagraphHeading variant="no_top_margin">
                        <Trans>Send to fax number(s)</Trans>
                      </CardParagraphHeading>

                      {this.renderPhoneNumberForm(formik_props)}
                      <SeparatorWithLine />
                      {this.renderMoreOptions(formik_props)}
                      <Separator variant="h20" />

                      {this.renderCreditWarning(formik_props)}
                      {this.renderSendFaxButton(formik_props)}
                    </div>
                  )}
                </Card>

                <UpgradePathNotifications
                  display_style="send_a_fax_card"
                  show_even_if_dismissed={false}
                />
              </WrappingBusyMask>
            </Form>
          )
        }}
      </Formik>
    )
  }
}

function mapStateToProps(state, props) {
  const provisional_outgoing_fax_id = get(
    props,
    ['match', 'params', 'provisional_outgoing_fax_id'],
    null
  )
  const provisional_outgoing_fax =
    provisional_outgoing_fax_id &&
    outgoingFaxQueueList.getObject(provisional_outgoing_fax_id)

  const provisional_costing =
    provisional_outgoing_fax_id &&
    outgoingFaxQueueProvisionalCosting.getObject(provisional_outgoing_fax_id)
  const calculated_cost_result = get(
    provisional_costing,
    'provisional_cost_information'
  )

  const outgoing_fax_settings = head(
    outgoingFaxSettingsList.getVisibleObjects()
  )
  const topups = topupList.getVisibleObjects()
  const minimum_topup_excluding_vat_euros = min(
    map(topups, (x) => x.amount_excluding_vat_euros)
  )

  const fax_file_info_set = get(provisional_outgoing_fax, 'fax_file_info_set', {
    files: [{}],
  })
  if (
    (fax_file_info_set && size(get(fax_file_info_set, 'files')) === 0) ||
    get(last(get(fax_file_info_set, 'files')), 'id')
  ) {
    fax_file_info_set.files.push({})
  }

  return {
    provisional_outgoing_fax_id,
    customer: customerList.getCustomer(),
    user: loggedInUser(),
    is_sending: outgoingFaxQueueList.getIsSavingObject(),
    is_ready:
      provisional_outgoing_fax_id &&
      provisional_outgoing_fax &&
      provisional_outgoing_fax.id,
    provisional_outgoing_fax,
    outgoing_fax_settings,
    calculated_cost_result,
    minimum_topup_excluding_vat_euros,
    initial_values: {
      fax_file_info_set: fax_file_info_set,
      destination_phone_numbers: get(
        provisional_outgoing_fax,
        'destination_phone_numbers',
        []
      ),
      send_multiple_files_choice: get(
        provisional_outgoing_fax,
        'send_multiple_files_choice',
        get(
          outgoing_fax_settings,
          'send_multiple_files_choice',
          OUTGOING_FAX_INITIAL_VALUES_DEFAULT.send_multiple_files_choice
        )
      ),
      attach_fax_to_confirmation_email: get(
        provisional_outgoing_fax,
        'attach_fax_to_confirmation_email',
        get(
          outgoing_fax_settings,
          'attach_fax_to_confirmation_email',
          OUTGOING_FAX_INITIAL_VALUES_DEFAULT.attach_fax_to_confirmation_email
        )
      ),
      fax_header: get(
        provisional_outgoing_fax,
        'fax_header',
        get(
          outgoing_fax_settings,
          'fax_header',
          OUTGOING_FAX_INITIAL_VALUES_DEFAULT.fax_header
        )
      ),
    },
  }
}

export default withRouter(connect(mapStateToProps)(SendAFaxCard))

const main_card_title = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const heading_bar = css`
  display: flex;
  margin-left: 15px;
`

const nav_style = css`
  align-items: center;
`

const fixed_width_input = css`
  max-width: 450px;
`

const send_fax_button_row = css`
  display: flex;
  position: relative;
`

const calculated_cost_result_style = css`
  line-height: 18px;
  margin-left: 12px;
  width: 100%;
  display: flex;
  align-items: center;
`

const calculated_cost_error_buy_credit = css`
  /*position: absolute;*/
  right: 0px;
`

const calculated_cost_success = css`
  display: flex;
`

const calculated_cost_error = css`
  display: flex;
  color: ${theme.colors.warning_red};
  position: relative;
  width: 100%;
`

const calculated_cost_error_because_dest_phone_numbers = css`
  position: relative;
  width: 100%;
  color: ${theme.colors.middle_grey};
  line-height: 18px;
  margin-left: 12px;
`

const calculated_cost_error_dest_phone_numbers = css`
  color: ${theme.colors.warning_red};
`

const calculated_cost_error_info_text = css`
  color: ${theme.colors.middle_grey};
`

const page_and_cost_style = css`
  display: flex;
`

const send_fax_button_style = css(
  theme.mq({
    height: '38px',
    width: ['auto', '100%'],
  })
)

const send_fax_button_disabled_style = css`
  height: 38px;
  cursor: default;
  background-color: ${theme.colors.light_grey};
  border: 1px solid ${theme.colors.light_grey};
  color: ${theme.colors.dark_middle_grey};

  font-size: 16px;

  &:hover {
    background-color: ${theme.colors.light_grey};
    border: 1px solid ${theme.colors.light_grey};
    color: ${theme.colors.dark_middle_grey};
  }
`

const success_button_bar_style = css`
  display: flex;
`

const topup_warning_style = css`
  background-color: rgb(255, 204, 0, 0.2);
  border-radius: 4px;
  padding: 16px 9px 17px 17px;
  width: 100%;
  margin-bottom: 30px;
`

const send_fax_button_row_style = css`
  width: 100%;
  justify-content: space-between;
`

const col_pull_right_style = css`
  display: flex;
  justify-content: flex-end;
`
