/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get, filter, head } from 'lodash'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { Formik, Form, FieldArray, Field } from 'formik'
import { userList } from '../actions/user'
import { customerList } from '../actions/customer'
import CardInfoText from './layout/CardInfoText'
import { GrayButton } from './layout/GrayButton'
import { MultipleEmailsSupportedInfoWidget } from './MultipleEmailsSupportedInfoWidget'
import { FormikInputField } from './form/InputField'
import { FormikDropdownField } from './form/Dropdown'
import BusyMask from './BusyMask'
import NestedWrappingBusyMask from './NestedWrappingBusyMask'
import { countryList } from '../actions/country'
import { cityList } from '../actions/city'
import { Row, Col, Button } from 'react-bootstrap'
import { default_theme as theme } from '../emotion/theme'
import * as Yup from 'yup'
import FieldInfoText from './layout/FieldInfoText'
import { FormLabelValue } from './form/FormLabelValue'
import { BlueButton } from './layout/BlueButton'
import { WhiteButton } from './layout/WhiteButton'
import { ButtonBar } from './layout/ButtonBar'
import { Separator } from './layout/Separator'
import { FormikGeneralFormErrors } from './form/GeneralFormErrors'
import { Trans, Translation } from 'react-i18next'
import { handleSubmitResult } from '../actions/form'
import { vatList } from '../actions/vat'

const validationSchema = Yup.object().shape({
})

const LEFT_WIDTH = 3
const RIGHT_WIDTH = 6
const FIELD_RIGHT_WIDTH = 10

export const ProfileForm = ({customer, onSave, onCancel, renderAdditionalFields}) => {

    const dispatch = useDispatch()
    const initial_values = Object.assign({contact_email: '', company: {billing_address: {}}}, customer)
    const city_options = useSelector(() => cityList.getAsOptions({show_prefix_in_label:true}))
    const isValidatingVatNumber = useSelector(() => vatList.getIsSavingObject())
    const country_options = useSelector(() => countryList.getAsOptionsWithPriority())
    const countries = useSelector(() => countryList.getObjects())
    
    useEffect(() => {
        dispatch(countryList.updatePaginationNumItemsPerPage(1000))
        dispatch(cityList.updatePaginationNumItemsPerPage(1000))
        dispatch(countryList.fetchListIfNeeded())
        dispatch(cityList.fetchListIfNeeded())
    }, [])

    const onCountryChanged = (country_id) => {
        dispatch(cityList.filterOnCountry(country_id))
    }

    const onAddCity = (new_city_name, city_field_name, country_field_name, formik_props) => {
        const on_ok = (json) => {
            dispatch(cityList.invalidateList())
            dispatch(cityList.fetchListIfNeeded())
            formik_props.setFieldValue(city_field_name, json.id)
        }

        const country = get(formik_props.values, country_field_name)
        if ( country ) {
            const values = {name: new_city_name, country: country}
            dispatch(cityList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_props, on_ok}))
        }
    }

    const onValidateVatNumber = (formik_props, vat_number_field_name) => {
        const on_validated = (vatInfo) => {
            if ( vatInfo.is_valid ) {
                const country = head(filter(countries, (country) => country.two_digit_code == get(vatInfo, "country_code")))
                if ( country ) {
                    formik_props.setFieldValue("company.billing_address.country", country.id)
                }
            }
        }
        
        const vat_number = get(formik_props.values, vat_number_field_name)
        dispatch(vatList.validate({vat_number})).then(on_validated)
    }

    const renderValidatedVatInfo = (validated_vat_info) => {
        if ( ! validated_vat_info.id ) {
            return null
        }
        return (
            <div>
            { validated_vat_info.is_eu && ! validated_vat_info.is_nl &&
              <div>
                  <Trans i18nKey="validated_vat_info__eu_non_nl">
                      Valid European VAT nr outside the Netherlands, reversed charge mechanism applies.
                </Trans>
              </div>
            }
            { validated_vat_info.is_eu && validated_vat_info.is_nl &&
              <div>
                <Trans i18nKey="validated_vat_info__eu_nl">
                  This is a European VAT inside of Netherlands, VAT applies.
                </Trans>
              </div>
            }
            { ! validated_vat_info.is_eu &&
              <div>
                <Trans i18nKey="validated_vat_info__international">
                  This is an unknown or international VAT number, VAT applies.
                </Trans>
              </div>
            }
            </div>
        )
    }

    return (
        
        <Formik
          initialValues={initial_values}
          onSubmit={onSave}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          {formik_props => {
              const { values } = formik_props

              const vat_number_field_name = "company.vat_number"
              const vat_number = get(formik_props.values, vat_number_field_name)
              const validated_vat_info = vatList.getObject(vat_number)

              const is_vat_exempt = get(validated_vat_info, 'is_vat_exempt')
              const business_name_matches_vat = !is_vat_exempt || get(formik_props.values, "company.name") === validated_vat_info.business_name
              
              return (
                  <Translation>{ (t) => (
                      <Form>
                        <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                        <Row>
                          <Col md={LEFT_WIDTH} css={section_name_style}>
                            <Trans>Name</Trans>
                          </Col>
                          <Col md={RIGHT_WIDTH}>
                            <Row>
                              <Col md={FIELD_RIGHT_WIDTH}>
                                <FormLabelValue>
                                  <Trans>First name</Trans>
                                  <FormikInputField
                                    name="user.first_name"
                                    type="text"
                                    placeholder={t("First name")}
                                  />
                                </FormLabelValue>
                                <FormLabelValue>
                                  <Trans>Surname</Trans>
                                  <FormikInputField
                                    name="user.last_name"
                                    type="text"
                                    placeholder={t("Surname")}
                                  />
                                </FormLabelValue>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Separator variant="h50"/>
                        <Row>
                          <Col md={LEFT_WIDTH} css={section_name_style}>
                            <Trans>Contact details</Trans>
                          </Col>
                          <Col md={RIGHT_WIDTH}>
                            <Row>
                              <Col md={FIELD_RIGHT_WIDTH}>
                                <FormLabelValue>
                                  <Trans>Contact email</Trans>
                                  &nbsp;
                                  <MultipleEmailsSupportedInfoWidget />
                                  <FormikInputField
                                    name="contact_email"
                                    type="text"
                                    placeholder={t("Contact email")}
                                  />
                                </FormLabelValue>
                                <FormLabelValue>
                                  <Trans>Contact phone</Trans>
                                  <FormikInputField
                                    name="contact_phone_number"
                                    type="text"
                                    placeholder={t("Contact phone")}
                                  />
                                </FormLabelValue>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Separator variant="h50"/>

                        <NestedWrappingBusyMask is_loading={isValidatingVatNumber}>
                          
                          <Row>
                            <Col md={LEFT_WIDTH} css={section_name_style}>
                              <Trans>Company information</Trans>
                              <CardInfoText>
                                To display on the invoice
                              </CardInfoText>
                            </Col>

                            <Col md={RIGHT_WIDTH}>

                              <FormLabelValue>
                                <Trans>VAT number</Trans>
                                <FieldInfoText>
                                  <Trans>Include country code, eg NL123456789000</Trans>
                                </FieldInfoText>
                                <div>
                                  <Row>
                                    <Col md={FIELD_RIGHT_WIDTH}>
                                      
                                      <FormikInputField
                                        name={vat_number_field_name}
                                        type="text"
                                        placeholder={t("VAT number")}
                                      />
                                    </Col>
                                    <Col>
                                      <GrayButton onClick={() => onValidateVatNumber(formik_props, vat_number_field_name)}>
                                        <Trans>Check</Trans>
                                      </GrayButton>
                                    </Col>
                                  </Row>
                                  <div css={validated_vat_info_style}>
                                    {validated_vat_info && renderValidatedVatInfo(validated_vat_info) }
                                  </div>
                                </div>
                              </FormLabelValue>

                              <Row>
                                <Col md={FIELD_RIGHT_WIDTH}>
                                  
                                  <FormLabelValue>
                                    <Trans>Company name</Trans>
                                    <FormikInputField
                                      name="company.name"
                                      type="text"
                                      placeholder={t("Company name")}
                                    />
                                  </FormLabelValue>
                                  <FormLabelValue>
                                    <Trans>Address 1</Trans>
                                    <FormikInputField
                                      name="company.billing_address.street1"
                                      type="text"
                                      placeholder={t("Address 1")}
                                    />
                                  </FormLabelValue>
                                  <FormLabelValue>
                                    <Trans>Address 2</Trans>
                                    <FormikInputField
                                      name="company.billing_address.street2"
                                      type="text"
                                      placeholder={t("Address 2")}
                                    />
                                  </FormLabelValue>
                                  <FormLabelValue>
                                    <Trans>Postal code</Trans>
                                    <FormikInputField
                                      name="company.billing_address.postal_code"
                                      type="text"
                                      placeholder={t("Postal code")}
                                    />
                                  </FormLabelValue>
                                  <FormLabelValue>
                                    <Trans>Country</Trans>
                                    <FormikDropdownField name="company.billing_address.country"
                                                         formik_props={formik_props}
                                                         options={country_options}
                                                         on_change={onCountryChanged}
                                                         empty_selection_label={t("Country")}
                                                         placeholder={t("Country")} />
                                  </FormLabelValue>
                                  { get(formik_props, ["values", "company", "billing_address", "country"]) &&
                                    <FormLabelValue>
                                      <Trans>City</Trans>
                                      <FormikInputField
                                        name="company.billing_address.city_name"
                                        type="text"
                                        placeholder={t("City")}
                                      />
                                    </FormLabelValue>
                                  }
                                  
                                  <FormLabelValue>
                                    <Trans>Chamber of Commerce number</Trans>
                                    <FormikInputField
                                      name="company.chamber_of_commerce"
                                      type="text"
                                      placeholder={t("Chamber of commerce number")}
                                    />
                                  </FormLabelValue>
                                </Col>
                              </Row>
                            </Col>

                            
                          </Row>
                        </NestedWrappingBusyMask>
                        
                        <Separator variant="h50"/>

                        { renderAdditionalFields && renderAdditionalFields({formik_props, LEFT_WIDTH, RIGHT_WIDTH, section_name_style}) }

                        <Row>
                          <Col md={LEFT_WIDTH} css={section_name_style}>
                          </Col>
                          <Col md={RIGHT_WIDTH}>
                            <ButtonBar>
                              <BlueButton type="submit"><Trans>Save</Trans></BlueButton>
                              <WhiteButton onClick={onCancel}><Trans>Cancel</Trans></WhiteButton>
                            </ButtonBar>
                          </Col>
                        </Row>
                      </Form>
                  )}</Translation>
              )}
          }
        </Formik>
    )
}

const form_layout = css`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
padding-top: 20px;
`

const field_container = css`
width: 90%;
display: flex;
flex-direction: column;
`

const error_container = css`
p {
color: ${theme.colors.error};
}
`

const footer = css`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
width: 90%;
padding-top:20px;
padding-bottom:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`

const section_name_style = css`
font-weight: 500;
font-size: 18px;
`

const validated_vat_info_style = css`
font-size: 14px;
`
