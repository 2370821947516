/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css, Global } from '@emotion/react'
import { map, keys, size } from 'lodash'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import { Separator } from './layout/Separator'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { LinkContainer } from 'react-router-bootstrap'
import { default_theme as theme } from '../emotion/theme'
import { Trans } from 'react-i18next'


const DEFAULT_CRUMBS = [{'name': 'admin_home', 'label': 'Home', 'url':'/admin'},
                        {'name': 'home', 'label': 'Home', 'url':'/'},
]

class Breadcrumbs extends Component {

    render() {

        let { crumbs } = this.props

        if ( size(crumbs) === 0 ) {
            return null
        }
        
        map(crumbs, (crumb) => {
            map(DEFAULT_CRUMBS, function(default_crumb) {
                if ( default_crumb.name === crumb.name ) {
                    crumb.url = default_crumb.url
                    crumb.label = default_crumb.label
                    return
                }
            })
        })

        return (
            <div css={main} className="breadcrumbs_container">
              <Global styles={global_styles} />
              <Breadcrumb css={breadcrumb_container}>
                { map(crumbs, (crumb, index) =>
                    <LinkContainer key={index} to={crumb.url} exact>
                      <Breadcrumb.Item key={index} href={crumb.url}>
                        <Trans>{crumb.label}</Trans>
                      </Breadcrumb.Item>
                    </LinkContainer>
                )}
              </Breadcrumb>
            </div>
        )
        
    }

}

export default Breadcrumbs

const global_styles = css`

.breadcrumbs_container {
    background-color: #f7f7f8;
    /*height: 40px; */
    border-top: 1px solid #E5E5EA;
}

.breadcrumbs_container .breadcrumbs {
    background-color: #f7f7f8;
}

.breadcrumbs_container .breadcrumb {
    background-color: #f7f7f8;
    padding-left: 0px;
    margin-bottom: 40px;
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 10px;
}

.breadcrumbs_container .breadcrumb a {
    color: ${theme.colors.black};
    font-weight: 500;
}
.breadcrumbs_container .breadcrumb .active a {
    color: #8e8e93;
}

.breadcrumbs_container .breadcrumb a:hover {
    color: #8e8e93;
    text-decoration: none;
}

.horizontal_separator {
    height: 30px;
    border-top:  1px solid #e8e8ed;
}

`

const main = css`
`

const breadcrumb_container = css`
padding-left: 30px;
`

