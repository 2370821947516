import { ItemList } from '../../orm'
import { map } from 'lodash'

class AdminOutgoingFaxPhoneNumberPrefixList extends ItemList {
    getEntityKey() {
        return "admin/outgoing_fax_phone_number_prefix"
    }

}

export const adminOutgoingFaxPhoneNumberPrefixList = new AdminOutgoingFaxPhoneNumberPrefixList("admin_outgoing_fax_phone_number_prefix_default")
