/** @jsxImportSource @emotion/react */
import React from 'react'
import { useParams } from 'react-router-dom'
import { VoiceNumberCallPlan } from './VoiceNumberCallPlan'
import MainLayout from './MainLayout'
import { Row, Col, Container } from 'react-bootstrap'
export const VoiceNumberCallPlanPage = ({ ...props }) => {
  const { subscription_id } = useParams()

  return (
    <MainLayout
      active_key="voice_number_call_plan"
      enable_breadlinks={true}
      breadcrumbs={[{ name: 'voice_number_call_plan' }]}
    >
      <Container fluid>
        <VoiceNumberCallPlan
          subscription_id={subscription_id}
          editing_as_customer={true}
          {...props}
        />
      </Container>
    </MainLayout>
  )
}
