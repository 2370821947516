/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { map, has, get, replace, split } from 'lodash'
import { Modal } from 'react-bootstrap'
import {showSuccess, showError} from '../../actions/Error'
import { ButtonBar } from '../../components/layout/ButtonBar'
import FieldInfoText from '../../components/layout/FieldInfoText'
import { BlueButton } from '../../components/layout/BlueButton'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { BlueLinkButton } from '../../components/layout/BlueLinkButton'
import AdminMainLayout from './AdminMainLayout'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikInputField } from '../../components/form/InputField'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import CommonTable from '../../components/CommonTable'
import CurrencyValue from '../../components/CurrencyValue'
import Pagination from '../../components/Pagination'
import Timestamp from '../../components/Timestamp'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFooter from './AdminTableFooter'
import AdminTableFilter from './AdminTableFilter'
import { Row, Col, Table } from 'react-bootstrap'
import { Container, Button } from 'react-bootstrap'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { InlineIconBar } from '../../components/layout/InlineIconBar'
import { adminSyncLogList } from '../actions/admin_sync_log'
import { Separator } from '../../components/layout/Separator'
import * as Yup from 'yup'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { handleSubmitResult } from '../../actions/form'
import { globalSettingsList } from '../../actions/settings'
import { adminSyncLogTypes } from '../actions/admin_dropdown_options'

const SYNC_ACTIONS = [
    { label: 'Static data (eg states)', value: 'static' },
    { label: 'Single customer', value: 'customer' },
    //{ label: 'All customers', value: 'customers' },
]

const validationSchema = Yup.object().shape({
    'action': Yup.string().required("Required")
})

class AdminSyncLog extends Component {

    constructor(props) {
        super(props)
        this.state = {show_sync_form: false}
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminSyncLogList.fetchListIfNeeded())
        dispatch(globalSettingsList.ensureGlobalSettingsLoaded())
        dispatch(adminSyncLogTypes.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(adminSyncLogList.fetchListIfNeeded())
    }

    onShowSyncForm = () => {
        this.setState({show_sync_form: true})
    }

    onHideSyncForm = () => {
        this.setState({show_sync_form: false})
    }
    
    onSync = (values, formik_funcs) => {
        const { dispatch } = this.props
        const that = this
        let on_ok = function(json) {
            showSuccess("Sync", "Sync started")
            that.onHideSyncForm()
            dispatch(adminSyncLogList.invalidateList())
        }
        dispatch(adminSyncLogList.sync(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }

    getSyncLogCellValue = (header_key, item, index) => {
        const that = this
        switch( header_key ) {
            case 'description':
                return (
                    <div>
                      {item.description}
                      {item.extended_note && map(split(item.extended_note, "\n"), (x) => <div>{x}</div>)}
                    </div>
                )
            case 'created_at':
                return (
                    <Timestamp value={item.created_at} format='datetime' />
                )
            case 'modified_at':
                return (
                    <Timestamp value={item.modified_at} format='datetime' />
                )
            default:
                return undefined
        }
    }

    renderFilter = (formik_props) => {
        const { sync_log_types } = this.props
        return (
            <FormikDropdownField options={sync_log_types}
                                 placeholder="Filter"
                                 empty_selection_label="All"
                                 formik_props={formik_props} name="log_type" />
        )
    }

    onFilterSyncLogs = (values) => {
        const { dispatch } = this.props
        dispatch(adminSyncLogList.updateListFilter(values))
    }

    renderForm() {
        const { initial_values, forced_obfuscate, history } = this.props
        const { show_sync_form } = this.state

        if ( ! show_sync_form ) {
            return (
                <div>
                  <BlueLinkButton onClick={() => history.push("/admin/bulk_customer_migration_queue")}>
                    Bulk queue
                  </BlueLinkButton>
                  <WhiteButton onClick={this.onShowSyncForm}>
                    Trigger new sync...
                  </WhiteButton>
                </div>
            )
        }
        
        return (
            <Modal show={true}
                   size="lg"
                   onHide={this.onHideSyncForm} >

              <Modal.Header closeButton>
                <Modal.Title>
                  Sync Trigger
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                
                <Formik
                  initialValues={initial_values}
                  onSubmit={this.onSync}
                  enableReinitialize={true}
                  validationSchema={validationSchema}
                >
                  {formik_props => {
                      const { values } = formik_props
                      return (
                          <Form>
                            <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                            <Row>
                              <Col>
                                <FormikDropdownField options={SYNC_ACTIONS}
                                                     formik_props={formik_props}
                                                     name="action" />
                              </Col>
                            </Row>
                            <Separator variant="h20" />
                            <Row>
                              <Col>
                                { forced_obfuscate === true &&
                                  <span>Data will be obfuscated</span>
                                }
                                { forced_obfuscate === false &&
                                  <FormikCheckboxField label="Obfuscate" name="obfuscate" />
                                }
                              </Col>
                              <Separator variant="h10" />
                            </Row>
                            { get(values, "action") === 'customer' &&
                              <div>
                                <Row>
                                  <Col>
                                    <FormikInputField name="customer_email" placeholder="Email" />
                                  </Col>
                                </Row>

                                <Separator variant="h20" />
                                <Row>
                                  <Col>
                                    <FieldInfoText>
                                      If ticked, then only historical data will be synced. This is appropriate where the customer is already synced and only data like fax history etc are to be synced.
                                      <br/>
                                      If unticked, then all data is synced, which will over-ride current data like email forwarding addresses, subscription costs and other current data.
                                      <br/>
                                      For new customers, this option should be unticked.
                                    </FieldInfoText>
                                    <FormikCheckboxField label="Historical data only" name="historical_only" />
                                  </Col>
                                </Row>
                                { (forced_obfuscate || get(values, "obfuscate") === true) &&
                                  <div>
                                    <Separator variant="h10" />
                                    <Row>
                                      <Col>
                                        <FieldInfoText>
                                          Optional. Incoming fax phone number override. Affects any fax-in subscriptions of this customer.
                                        </FieldInfoText>
                                        <FormikInputField name="obfuscated_incoming_fax_phone_number_override" placeholder="Phone number" />
                                      </Col>
                                    </Row>
                                    <Separator variant="h10" />
                                    <Row>
                                      <Col>
                                        <FieldInfoText>
                                          Optional. Legacy reference override (accountid field). Useful for testing dev Joomla migration.
                                        </FieldInfoText>
                                        <FormikInputField name="obfuscated_customer_ref_override" placeholder="Account id" />
                                      </Col>
                                    </Row>
                                    
                                  </div>
                                }
                              </div>
                            }
                            <Separator variant="h10" />
                            <Row>
                              <Col>
                                <BlueButton type="submit" auto_disable={false}>
                                  Sync
                                </BlueButton>
                              </Col>
                            </Row>
                          </Form>
                      )
                  }}
                </Formik>

              </Modal.Body>
              
            </Modal>

        )
    }

    render() {
        const {
            headers,
            sync_logs,
            is_loading
        } = this.props
        
        return (
            <AdminMainLayout active_key="sync_logs"
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'sync_log', label: 'Legacy Production Synchronisation', url: '/admin/sync_log'}]}>
              <AdminTableHeader title="Sync Log">
                <div css={ css`width:100%;`}>
                  { this.renderForm() }
                </div>

                <div css={ css`width:100%;`}>
                  <AdminTableFilter updateOnChange={ this.onFilterSyncLogs }
                                    renderFilter={this.renderFilter}
                  />
                </div>
              </AdminTableHeader>
              <Container fluid>
                <CommonTable is_loading={ is_loading }
                             empty_message="There are no sync logs."
                             headers={ headers }
                             items={ sync_logs }
                             item_list={ adminSyncLogList}
                             getCellValue={ this.getSyncLogCellValue }
                             sortOnHeader={ this.sortOnHeader }
                />
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const sync_logs = adminSyncLogList.getVisibleObjects() || []
    const forced_obfuscate = globalSettingsList.getSetting('obfuscate_legacy_import')
    const sync_log_types = adminSyncLogTypes.getAsOptions()
    return {
        sync_logs,
        headers: {
            description: { name: "Description" },
            created_at: { name: 'Started' },
            modified_at: { name: 'Completed' },
            status:  { name: 'Status' },
            last_error:  { name: 'Error' },
            progress: { name: 'Progress' },
            server_name: { name: 'Server name' }
        },
        is_loading: adminSyncLogList.isLoading(),
        initial_values: {obfuscate: true},
        forced_obfuscate,
        sync_log_types
    }
}
export default withRouter(connect(mapStateToProps)(AdminSyncLog))

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`
