/** @jsxImportSource @emotion/react */
import React,{ useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { Timestamp } from './Timestamp'
import { Trans, Translation } from 'react-i18next'
import { Separator } from './layout/Separator'
import { Row, Col, Container } from 'react-bootstrap'
import CommonTable from './CommonTable'
import { head, size, map, filter, get } from 'lodash'
import CurrencyValue from './CurrencyValue'
import { TransValue } from './TransValue'
import { default_theme as theme } from '../emotion/theme'
import { customerList } from '../actions/customer'
import Card from './layout/Card'
import NestedWrappingBusyMask from './NestedWrappingBusyMask'
import { molliePaymentList } from '../actions/mollie_payment'
import { BlueButton } from './layout/BlueButton'
import { WhiteLinkButton } from './layout/WhiteLinkButton'
import { OrangeLinkButton } from './layout/OrangeLinkButton'
import { ButtonBar } from './layout/ButtonBar'
import { upgradePathList } from '../actions/upgrade_path'
import { productListForUpgradePath } from '../actions/product'
import { InlineIcon } from './layout/InlineIcon'
import { PhoneNumber } from './PhoneNumber'
import CardH2Text from './layout/CardH2Text'
import { customerSubscriptionUpgrade } from '../actions/customer_subscription'

export const UpgradePathNotificationTrialSubscription = ({upgrade_path, display_style, onDidRender}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const is_loading = useSelector(() => upgradePathList.isLoading())
    const is_saving = useSelector(() => upgradePathList.getIsSavingObject())
    const recommended_products = useSelector(() => productListForUpgradePath.getVisibleObjects())
    const recommended_product = head(recommended_products)
    const is_loading_products = useSelector(() => productListForUpgradePath.isLoading())
    const subscription = useSelector(() => customerSubscriptionUpgrade.getObject(upgrade_path.customer_subscription))
    const is_displaying_for_voice = display_style == "voice_numbers_bottom" && get(subscription, "product_can_receive_voice")
    const is_displaying_for_fax = false

    useEffect(() => {
        dispatch(customerSubscriptionUpgrade.ensureObjectLoaded(upgrade_path.customer_subscription))
    }, [upgrade_path.customer_subscription])

    useEffect(() => {
        if ( is_displaying_for_fax ) {
            dispatch(productListForUpgradePath.updateListFilter({can_receive_faxes: true, recommended: true}))
            
        } else if ( is_displaying_for_voice ) {
            dispatch(productListForUpgradePath.updateListFilter({can_receive_voice: true, recommended: true}))
        }
        
        dispatch(productListForUpgradePath.updateListOrdering("annual_subscription_price_excluding_vat_euros"))
        dispatch(productListForUpgradePath.fetchListIfNeeded())
    }, [subscription.id])
    
    const onUpgrade = () => {
        history.push(`/subscription_upgrade/${subscription.id}`)
    }
    
    const render_for_voice_numbers_bottom = (upgrade_path) => {
        return (
            <>
              <Separator variant="h20" />
              <div css={container_style}>
                <Row>
                  <Col>
                    <CardH2Text>
                      <Trans i18nKey="upgrade_path_trial_subscription__your_trial_period"
                             components={[<PhoneNumber phone_number={get(subscription, "phone_number_number")} />,
                                          <Timestamp value={get(subscription, "expires_at")} format="date_longmonth" use_span={true} />]}
                             defaults="Your trial period for <0></0> ends on <1></1>"
                      />
                    </CardH2Text>
                    
                    <Separator variant="h20" />

                    <Trans i18nKey="upgrade_path_upsell_free_fax_sending_sub__keep_your_number"
                           components={[<CurrencyValue value={get(recommended_product, "annual_subscription_price_excluding_vat_euros")} use_span={true} />,
                                        <TransValue value={get(recommended_product, "monthly_num_free_voice_forwarding_minutes")} />]}
                           defaults="Keep your number for <0></0> per year and enjoy <1></1> free minutes of forwarding per month."
                    />

                    <Separator variant="h20" />
                    <BlueButton onClick={onUpgrade}>
                      <Trans>Yes, I want to keep my number!</Trans>
                    </BlueButton>
                    
                  </Col>
                </Row>
              </div>
            </>
        )
    }

    if ( display_style === "voice_numbers_bottom" && is_displaying_for_voice ) {
        onDidRender()
        return render_for_voice_numbers_bottom(upgrade_path)
    } else {
        return null
    }
    
}

const container_style = css`
background-color: #fff5cc;
width: 100%;
margin-bottom: 30px;
padding: 20px;
border-radius: 10px;
color: ${theme.colors.black};
`
