/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import { default_theme as theme } from '../../emotion/theme'
import { difference, forEach, head, get, map, keys, split, size, filter } from 'lodash'
import { adminCustomerSubscriptionForMigrationList } from '../actions/admin_customer_subscription'
import {showSuccess, showError} from '../../actions/Error'
import { ButtonBar } from '../../components/layout/ButtonBar'
import { Separator } from '../../components/layout/Separator'
import Card from '../../components/layout/Card'
import Loading from '../../components/Loading'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import { BlueButton } from '../../components/layout/BlueButton'
import { Col, Row, Container, Navbar, Nav } from 'react-bootstrap'
import CardParagraphHeading from '../../components/layout/CardParagraphHeading'
import AdminVoxboneStatus from './AdminVoxboneStatus'
import { adminVoxboneStatusList } from '../actions/admin_voxbone_status'

const LEFT_WIDTH = "4"
const RIGHT_WIDTH = "8"

class AdminCustomerMigrationSubscriptions extends Component {

    componentDidMount() {
        const { customer_id, dispatch } = this.props
        dispatch(adminCustomerSubscriptionForMigrationList.updateListFilter({customer_id: customer_id,
                                                                 represents_active_subscription: true,
                                                                 with_phone_number_only: true}))
        dispatch(adminCustomerSubscriptionForMigrationList.updatePaginationNumItemsPerPage(1000))
        dispatch(adminCustomerSubscriptionForMigrationList.fetchListIfNeeded())
        this.prefilterVoxboneStatuses()
    }

    componentDidUpdate() {
        const { customer_id, dispatch, subscription_status_filter } = this.props
        if ( subscription_status_filter.customer_id !== customer_id ) {
            dispatch(adminCustomerSubscriptionForMigrationList.updateListFilter({customer_id: customer_id}))
        }
        dispatch(adminCustomerSubscriptionForMigrationList.fetchListIfNeeded())
        this.prefilterVoxboneStatuses()
    }

    prefilterVoxboneStatuses() {
        const { subscriptions, phone_number_ids, adminVoxboneStatusListFilter, dispatch } = this.props
        const has_differences = difference(adminVoxboneStatusListFilter.phone_numbers, phone_number_ids)
        if ( size(phone_number_ids) > 0 && (size(adminVoxboneStatusListFilter.phone_numbers) === 0 || size(has_differences) > 0) ) {
            dispatch(adminVoxboneStatusList.updateListFilter({phone_numbers: phone_number_ids}))
        }
        dispatch(adminVoxboneStatusList.fetchListIfNeeded())
    }
    
    renderSubscription(subscription) {
        return (
            <div key={subscription.id} >
              <CardParagraphHeading>
                {subscription.phone_number_number}
              </CardParagraphHeading>
              
              <Row>
                <Col md={LEFT_WIDTH}>
                  Product
                </Col>
                <Col md={RIGHT_WIDTH}>
                  {subscription.product_name}
                </Col>
              </Row>
              
              <Row>
                <Col md={LEFT_WIDTH}>
                  Voxbone
                </Col>
                <Col md={RIGHT_WIDTH}>
                  <AdminVoxboneStatus phone_number_id={subscription.phone_number} />
                </Col>
              </Row>
              
            </div>
        )
    }

    render() {
        const { subscriptions, is_loading, is_ready, phone_number_ids, adminVoxboneStatusListFilter } = this.props
        const can_render_subscriptions = size(subscriptions) > 0 && (size(phone_number_ids) === 0 || size(adminVoxboneStatusListFilter.phone_numbers)>0)
        
        return (
            <Card variant="white_wide_padding" title="Customer subscriptions">
              <WrappingBusyMask is_loading={is_loading || !is_ready}>
                <Separator variant="h20" />
                { (! subscriptions || size(subscriptions) === 0) && 
                    <div>
                      No subscriptions
                    </div>
                }
                { can_render_subscriptions && (
                    <div>
                      {map(subscriptions, (subscription) => this.renderSubscription(subscription)) }
                    </div>
                )}
                
              </WrappingBusyMask>
            </Card>
        )
    }
}

function mapStateToProps(state, props) {
    const { customer_id } = props
    const subscriptions = adminCustomerSubscriptionForMigrationList.getVisibleObjects()
    const phone_number_ids = map(subscriptions, (sub) => sub.phone_number)
    return {
        customer_id,
        subscriptions,
        subscription_status_filter: adminCustomerSubscriptionForMigrationList.getFilter(),
        is_ready: adminCustomerSubscriptionForMigrationList.isReady() && adminVoxboneStatusList.isReady(),
        is_loading: adminCustomerSubscriptionForMigrationList.isLoading(),
        adminVoxboneStatusListFilter: adminVoxboneStatusList.getFilter(),
        phone_number_ids
    }
}

export default connect(mapStateToProps)(AdminCustomerMigrationSubscriptions)

const breadcrumb_item = css`
display: flex;
align-items: center;
`

const fax_forwarding_url_style = css`
font-size: 12px;
`

const raw_status_check_result_style = css`
font-size: 12px;
`
