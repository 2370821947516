/** @jsxImportSource @emotion/react */
import React, {useEffect} from 'react'
import { connect, useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import {showSuccess, showError} from '../../actions/Error'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikInputField } from '../../components/form/InputField'
import { confirmModal } from '../../actions/ui'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { Error } from '../../components/layout/Error'
import { Separator } from '../../components/layout/Separator'
import Card from '../../components/layout/Card'
import { BlueButton} from '../../components/layout/BlueButton'
import { Trans, Translation } from 'react-i18next'
import { handleSubmitResult } from '../../actions/form'
import { adminUpgradePathList } from '../actions/admin_upgrade_path'
import { Button } from 'react-bootstrap'
import {default_theme as theme} from "../../emotion/theme"
import { AdminUpgradePathTable } from './AdminUpgradePathTable'

export const AdminCustomerUpgradePath = ({customer_id}) => {

    const dispatch = useDispatch()

    const onRecalculateUpgradePaths = () => {
        const on_ok = function(json) {
            showSuccess("Saved", "Upgrade paths refreshed")
            dispatch(adminUpgradePathList.invalidateList())
            dispatch(adminUpgradePathList.fetchListIfNeeded())
        }
        dispatch(adminUpgradePathList.recalculateForCustomer({customer_id})).then((res) => handleSubmitResult({res, formik_funcs:{}, on_ok}))
    }
    
    return (
        <>
          <BlueButton type="submit" onClick={onRecalculateUpgradePaths}>
            Recalculate upgrade paths
          </BlueButton>

          <Separator variant="h20" />

          <Card variant="white_wide_padding" title="Upgrade paths for this customer">
            <AdminUpgradePathTable customer_id={customer_id}
                                   header_names={['upgrade_type', 'subscription_phone_number', 'notify_customer', 'dismissed', 'seen_by_customer_at', 'ready_for_upgrade', 'is_upgraded']} />
          </Card>
          
        </>
        
    )
    
}
