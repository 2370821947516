/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { head, get } from 'lodash'
import { Link } from 'react-router-dom'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import CurrencyValue from '../../components/CurrencyValue'
import Timestamp from '../../components/Timestamp'
import { WhiteButton } from '../../components/layout/WhiteButton'
import AdminCommonTransactions from './AdminCommonTransactions'
import { adminTransactionList } from '../actions/admin_transaction'
import { adminCustomerList } from '../actions/admin_customer'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { Button, Container } from 'react-bootstrap'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { LanguageFlag } from '../../components/LanguageFlag'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFilter from './AdminTableFilter'
import { adminAggregatedTransactions } from '../actions/admin_aggregated_transactions'

class AdminCustomerTransactions extends Component {

    componentDidMount() {
        const { dispatch, customer_id } = this.props
        dispatch(adminTransactionList.updateListFilter({customer: customer_id}))
        dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
    }

    componentDidUpdate(prev_props) {
        const { dispatch, customer_id, admin_transaction_list_filter } = this.props
        if ( prev_props.customer_id !== customer_id || admin_transaction_list_filter.customer !== customer_id ) {
            dispatch(adminTransactionList.updateListFilter({customer: customer_id}))
            dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
        }
    }

    onAddTransaction = () => {
        const { history, customer_id } = this.props
        history.push(`/admin/customer/${customer_id}/create_transaction`)
    }
    
    render() {
        return (
            <AdminCommonTransactions transactionList={adminTransactionList}
                                     aggregatedTransactions={adminAggregatedTransactions}
                                     title="Transactions"
                                     additional_header={( <div css={ header }>
                                                            <WhiteButton variant="outline-primary" onClick={ this.onAddTransaction }>
                                                              Add Transaction
                                                            </WhiteButton>
                                                          </div>
                                                        )}
            />
        )
    }
}

function mapStateToProps(state, props) {
    const customer_id = get(props, ["match", "params", "customer_id"], null)
    const customer = customer_id && adminCustomerList.getObject(customer_id)
    return {
        customer_id,
        customer,
        is_loading: adminCustomerList.isLoading() || !customer || !customer.id,
        admin_transaction_list_filter: adminTransactionList.getFilter()
    }
}

export default withRouter(connect(mapStateToProps)(AdminCustomerTransactions))

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

