import { ItemList } from '../orm'
import { join, size, map, compact, uniqBy, filter, includes } from 'lodash'
import { numberPrefixTypes } from './dropdown_options'
import { convertCityPrefixToNationalFormat } from './city'

class PhoneNumberRedirectServerSyncLogList extends ItemList {
    getEntityKey() {
        return "asterisk/phone_number_redirect_sync_log_status/summary"
    }

    getSyncLogInfoId({customer_subscription_id}) {
        return customer_subscription_id
    }
    
    loadForSubscription({customer_subscription_id}) {
        return this.ensureObjectLoaded(this.getSyncLogInfoId({customer_subscription_id}))
    }

    reloadForSubscription({customer_subscription_id}) {
        return async(dispatch, getState) => {
            dispatch(this.invalidateObject(this.getSyncLogInfoId({customer_subscription_id})))
            dispatch(this.ensureObjectLoaded(this.getSyncLogInfoId({customer_subscription_id})))
        }
    }
    
    getForSubscription({customer_subscription_id}) {
        return this.getObject(this.getSyncLogInfoId({customer_subscription_id}))
    }

    
}

export const phoneNumberRedirectServerSyncLogList = new PhoneNumberRedirectServerSyncLogList("phone_number_redirect_server_sync_log")
