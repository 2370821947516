/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { head, get } from 'lodash'
import { Link } from 'react-router-dom'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import CurrencyValue from '../../components/CurrencyValue'
import Timestamp from '../../components/Timestamp'
import { adminTopupList } from '../actions/admin_topup'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { Button, Container } from 'react-bootstrap'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { LanguageFlag } from '../../components/LanguageFlag'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFilter from './AdminTableFilter'

class AdminTopups extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminTopupList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(adminTopupList.fetchListIfNeeded())
    }

    onEditTopup = (evt, item) => {
        const { history } = this.props
        history.push({
            pathname: '/admin/topup/'+item.id,
        })
    }

    onAddTopup = () => {
        const { history } = this.props
        history.push('/admin/topup')
    }

    getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'is_active':
                return (
                    <div>
                      { item.is_active === true && <InlineIcon icon_name='status-success' /> }
                      { item.is_active !== true && <InlineIcon icon_name='status-error' /> }
                    </div>
                )
            case 'amount_excluding_vat_euros':
                return (
                    <CurrencyValue value={item.amount_excluding_vat_euros} />
                )
            case 'action':
                return (
                    <Button onClick={(evt) => this.onEditTopup(evt, item)}>
                      Edit
                    </Button>
                )
                
            default:
                return undefined
        }
    }

    render() {
        const { is_loading, headers, topups } = this.props
        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'topups', label: 'Topups', url: '/admin/topups'}]}>
              <Container fluid>
                <AdminTableHeader title="Topups">
                  <AdminTableFilter
                      updateOnChange={ this.filterTopups }
                      form="ADMIN_TOPUPS_FILTER_FORM"
                  />
                  <Button variant="outline-primary" onClick={ this.onAddTopup }>
                    Add Topup
                  </Button>
                </AdminTableHeader>
                <CommonTable
                    is_loading={ is_loading }
                    empty_message={`There are no topups.`}
                    headers={ headers }
                    items={ topups }
                    item_list={adminTopupList}
                    renderCell={ this.renderCell }
                    getCellValue={ this.getCellValue }
                    sortOnHeader={ this.sortOnHeader }
                />
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const topups = adminTopupList.getVisibleObjects()
    return {
        topups,
        is_loading: adminTopupList.isLoading(),
        headers: {
            name: { name: 'Name', column_size: 1 },
            description: { name: 'Description', column_size: 1 },
            amount_excluding_vat_euros: { name: 'Amount', column_size: 1 },
            modified_at: { name: 'Modified', column_size: 1 },
            is_active: { name: 'Active', column_size: 1 },
            action: { name: '', column_size: 1 }
        }
    }
}

export default connect(mapStateToProps)(AdminTopups)
