/** @jsxImportSource @emotion/react */
import React, { useCallback, useState, useEffect } from 'react'
import { jsx, css, Global } from '@emotion/react'
import { useField } from 'formik'
import { Trans, Translation } from 'react-i18next'
import { size, map, get, startsWith, trimStart } from 'lodash'
import { default_theme as theme } from '../../emotion/theme'
import { debounce } from 'lodash'
import { Separator } from '../layout/Separator'
import Timestamp from '../Timestamp'
import FormError from './FormError'
import FormWarning from './FormWarning'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import * as Yup from 'yup'
import PhoneNumber from 'awesome-phonenumber'
import { PhoneNumber as ReadonlyPhoneNumber } from '../PhoneNumber'
import { ClassNames } from '@emotion/react'

const DELAY_MS = 500

export const phoneNumberIsValid = (raw_phone_number) => {
    if ( ! raw_phone_number ) {
        return true
    }
    raw_phone_number = "+" + trimStart(raw_phone_number, "0")
    var pn = new PhoneNumber( raw_phone_number, '' )
    const result = pn.isValid()
    return result
}


Yup.addMethod(Yup.string, "phoneNumber", function(args) {
    const message = get(args, "message", "Invalid phone number")
    return this.test("phoneNumber", message, phoneNumberIsValid)
})


export const FormikPhoneNumberInputField = ({ formik_props, onChange, onBlur, onlyCountries, placeholder, autoCompleteLookup, ...props }) => {
    const [field, meta] = useField(props)
    const [prettifiedValue, setPrettifiedValue] = useState()

    const [autoCompleteResults, setAutoCompleteResults] = useState(null)

    useEffect(() => {
        let v = field.value
        if ( startsWith(v, "00") ) {
            v = "+" + trimStart(v, "0")
        }
        setPrettifiedValue(v)

    }, [field.value])

    const onAutoCompleteResultsReceived = (results) => {
        setAutoCompleteResults(results)
    }
    
    const getE164Format = (value) => {
        value = "+" + trimStart(value, "0")
        var pn = new PhoneNumber( value, '' )
        if ( pn.isValid() ) {
            value = "00" + trimStart(pn.getNumber('e164'), "+")
        }
        return value
    }

    const onChooseAutoSelectResult = (result) => {
        forceValue(result.number)
        setAutoCompleteResults(null)
    }

    const localOnChange = (value) => {
        forceValue(value)
        if ( autoCompleteLookup ) {
            autoCompleteLookup({partialPhoneNumber: value, onDone: onAutoCompleteResultsReceived})
        }
    }

    const forceValue = (value) => {
        value = getE164Format(value)
        formik_props.setFieldValue(field.name, value)
        setPrettifiedValue(value) // hack

        if ( onChange ) {
            onChange(value)
        }
    }

    const onInputBlur = (el) => {
	const value = getE164Format(el.target.value)
        if ( onBlur ) {
            onBlur(value)
        }
    }

    const onComponentBlur = (event) => {
        const focussedOnChild = event.currentTarget.contains(event.relatedTarget)
        if ( ! focussedOnChild ) {
            // setAutoCompleteResults(null)
        }
    }

    const renderAutoCompleteResults = () => {
        if ( ! size(autoCompleteResults) ) {
            return null
        }
        return (
            <div css={autoCompleteResultsStyle}>
              { map(autoCompleteResults, (result, index) => 
                  <div key={index} css={autoCompleteResultRowStyle} onClick={() => onChooseAutoSelectResult(result)}>
                    <div css={autoCompleteResultRowNumberStyle}>
                      <ReadonlyPhoneNumber phone_number={result.number} />
                    </div>
                    <Separator variant="w5" />
                    <div css={autoCompleteResultRowInfoStyle}>
                      <Trans>Last call</Trans>&nbsp;
                      <Timestamp use_span={true} value={result.last_call_at} format="dateshort-time" />
                    </div>
                  </div>
                )}
            </div>
        )
    }

    return (
        <div css={container} onBlur={onComponentBlur}>
          <Global styles={global_styles} />
          <ClassNames>
            {({ css, cx }) => (
                <div css={phone_number_input_wrapper_style}>
                  <PhoneInput country={'nl'}
                              preferredCountries={['nl']}
                              onlyCountries={onlyCountries || []}
                              enableLongNumbers={true}
                              value={prettifiedValue}
                              placeholder={placeholder}
                              onChange={localOnChange}
		              onBlur={onInputBlur}
                              inputStyle={{ width: '100%' }}
                  />
                  { renderAutoCompleteResults() }
                </div>
            )}
          </ClassNames>
          <FormError meta={meta} />
          <FormWarning meta={meta} />
        </div>
    )
}

const global_styles = css`
.react-tel-input input.form-control {
  font-size: 16px;
}
`

const container = css`
`

const phone_number_input_wrapper_style = css`
position: relative;
`

const autoCompleteResultsStyle = css`
width: 200%;
background-color: #fff;
border: 1px solid ${theme.colors.very_light_grey};
position: absolute;
z-index:100;
`

const autoCompleteResultRowStyle = css`
margin-top: 10px;
margin-bottom: 5px;
margin-left: 50px;
display: flex;
cursor: pointer
`

const autoCompleteResultRowNumberStyle = css`
font-weight: bold;
`

const autoCompleteResultRowInfoStyle = css`
    color: ${theme.colors.dark_grey};
`
