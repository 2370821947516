import { ItemList } from '../orm'
import cookie from 'react-cookies'
import { molliePaymentList } from './mollie_payment'

class TopupList extends ItemList {
    getEntityKey() {
        return "topup"
    }

    startBuyMoreCredit({history, optionalReturnUrl}) {
        molliePaymentList.rememberAsReturnUrl(optionalReturnUrl)
        history.push("/buy_credit_topup")
    }
    
}


export const topupList = new TopupList("topup__default")
