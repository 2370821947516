/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { head, get } from 'lodash'
import { Link } from 'react-router-dom'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import CurrencyValue from '../../components/CurrencyValue'
import Timestamp from '../../components/Timestamp'
import AdminCommonTransactions from './AdminCommonTransactions'
import { adminTransactionList } from '../actions/admin_transaction'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { Button, Container } from 'react-bootstrap'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { LanguageFlag } from '../../components/LanguageFlag'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFilter from './AdminTableFilter'
import { adminAggregatedTransactions } from '../actions/admin_aggregated_transactions'

class AdminTransactions extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminTransactionList.clearListFilter())
    }
    
    render() {
        const { is_loading, headers, transactions } = this.props
        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'transactions', label: 'Transactions', url: '/admin/transactions'}]}>
              <Container fluid>
                <AdminCommonTransactions transactionList={adminTransactionList}
                                         title="Transactions"
                                         aggregatedTransactions={adminAggregatedTransactions}
                />
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    return {
    }
}

export default connect(mapStateToProps)(AdminTransactions)
