/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Trans, Translation } from 'react-i18next'
import { jsx, css } from '@emotion/react'
import { Row, Col, Container, Card } from 'react-bootstrap'
import { get, map } from 'lodash'
import Loading from './Loading'
import { CardHeader } from './layout/CardHeader'
import BusyMask from './BusyMask'
import CardInfoText from './layout/CardInfoText'
import { BlueButton } from './layout/BlueButton'
import { molliePaymentList } from '../actions/mollie_payment'
import { CreditTopupCard } from './CreditTopupCard'
import { topupList } from '../actions/topup'
import { Separator } from './layout/Separator'
import { UpgradePathNotifications } from './UpgradePathNotifications'
import MainLayout from './MainLayout'
import WrappingBusyMask from './WrappingBusyMask'
import { default_theme as theme } from '../emotion/theme'
import { getBestLanguageCode } from '../actions/language'
import { globalSettingsList } from '../actions/settings'
import { BlueInlineLink } from './layout/BlueInlineLink'


class BuyCreditTopup extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(topupList.fetchListIfNeeded())
	dispatch(globalSettingsList.fetchListIfNeeded())
    }

    on_view_more_details = () => {
	const { external_info_urls } = this.props
	window.open(get(external_info_urls, ["xoip_credit", getBestLanguageCode()]), "_blank")
	return false
    }
    
    render() {
        const { is_ready, topups } = this.props
        return (
            <MainLayout active_key="credit_topup"
                        enable_breadlinks={true}
                        breadcrumbs={[{name: 'home'},
                                      {name: 'credit', label: <Trans>Buy credit</Trans>, url: '/buy_credit_topup'}]}>
              <Container fluid>
                <Row>
                  <Col>
                    <CardHeader title={<Trans>Buy credit</Trans>} />
                    <CardInfoText>
                      <Trans>
                        Top up your credit balance. Credit is used for sending faxes and forwarding calls to
                        external phone numbers. You can use this credit immediately. There are no transaction costs
                      </Trans>.
                    </CardInfoText>

                    <UpgradePathNotifications display_style="topup" show_even_if_dismissed={true}
                                              header={<Separator variant="h40"/>}
                                              footer={<Separator variant="h30"/>}
                                              render_if_empty={() => <Separator variant="h50"/>}
                    />
                    
                    <WrappingBusyMask is_loading={!is_ready}>
                      <div css={topup_list_style}>
                        { map(topups, (topup, index) => <CreditTopupCard key={index} topup={topup} />) }
                      </div>
                    </WrappingBusyMask>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <CardInfoText>
                      <Trans i18nKey="buy_credit_topup__credits_remain_active_line1">
                          Your credits will remain valid as long as you remain an active subscriber.
                      </Trans>
		      <BlueInlineLink onClick={this.on_view_more_details}>
			  <Trans i18nKey="buy_credit_topup__more_details_link">
			      Check here for details
			  </Trans>
		      </BlueInlineLink>
                    </CardInfoText>
                  </Col>
                </Row>
                
              </Container>
            </MainLayout>
        )
    }
    
}

function mapStateToProps(state, props) {
    const { amount } = props
    const external_info_urls = globalSettingsList.getSetting('external_info_urls')
    const topups = topupList.getVisibleObjects()
    return {
        amount,
        topups,
	external_info_urls,
        is_ready: topupList.isReady()
    }
}

export default withRouter(connect(mapStateToProps)(BuyCreditTopup))

const topup_list_style = css`
display: flex;
flex-wrap: wrap;
margin-right: -30px;
`

const buy_credit_ul = css`
margin-left: 0px;
`
