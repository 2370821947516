/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'
import { jsx, css } from '@emotion/react'
import { useField } from 'formik'
import { default_theme as theme } from '../../emotion/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NestedWrappingBusyMask from '../NestedWrappingBusyMask'
import { Row, Col } from 'react-bootstrap'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Separator } from '../layout/Separator'
import { InlineIcon } from '../layout/InlineIcon'
import { InlineIconBar } from '../layout/InlineIconBar'
import { BlueLinkButton } from '../layout/BlueLinkButton'
import { BlueButton } from '../layout/BlueButton'
import { Trans, Translation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import { FormikGeneralFormErrors } from './GeneralFormErrors'

export const InlineInputField = ({ label, canEdit, renderReadOnlyValue, readOnlyValue, renderFormFields, formInitialValues, onSave, formButtonStyle, validationSchema,
                                   is_saving, startInEditingMode, renderRightSide, ...props }) => {
    
    const [isEditing, setIsEditing] = useState(startInEditingMode === true)

    formButtonStyle = formButtonStyle || "icons"
    canEdit = canEdit !== false

    useEffect(() => {
        setIsEditing(startInEditingMode)
    }, [startInEditingMode])
    
    const startEditing = () => {
        if ( canEdit ) {
            setIsEditing(true)
        }
    }
    
    const stopEditing = () => {
        setIsEditing(false)
    }

    const localOnSave = (values, formik_props) => {
        onSave({values, formik_props, onSaved: stopEditing})
    }

    const localRenderReadOnlyValue = () => {
        if ( renderReadOnlyValue ) {
            return renderReadOnlyValue({startEditing: startEditing,
                                        stopEditing: stopEditing})
        } else {
            return readOnlyValue
        }
    }
    
    const renderForm = () => {
        return (
            <Formik
              initialValues={formInitialValues}
              onSubmit={localOnSave}
              validationSchema={validationSchema}
              enableReinitialize={true}
            >

              {formik_props => {
                  const { values } = formik_props

                  return (
                      <NestedWrappingBusyMask is_loading={is_saving}>
                        <>
                          { formButtonStyle === "icons" &&
                            <Form css={[form_style, icons_form_style]}>
                              <FormikGeneralFormErrors />
                              <div css={[edit_style, icons_edit_style]}>
                                <div>
                                  {label}
                                  {label && <Separator variant="w3" />}
                                  {renderFormFields(formik_props)}
                                </div>

                                <div css={icons_buttonbar_style}>
                                  <BlueButton onClick={formik_props.submitForm} auto_disable={false}><Trans>Save</Trans></BlueButton>
                                  <Separator variant="w10" />
                                  <BlueLinkButton onClick={stopEditing}><Trans>Cancel</Trans></BlueLinkButton>
                                </div>
                              </div>
                            </Form>
                          }
                          
                          { formButtonStyle === "buttons" &&
                            <Form css={[form_style, buttons_form_style]}>
                              <FormikGeneralFormErrors />
                              <div css={[edit_style, buttons_edit_style]}>
                                <div>
                                  <div css={buttons_edit_form_fields_style}>
                                    {label}
                                    {label && <Separator variant="w3" />}
                                    {renderFormFields(formik_props)}
                                  </div>
                                </div>

                                <div css={buttons_buttonbar_style}>
                                  <BlueButton onClick={formik_props.submitForm} auto_disable={false}><Trans>Save</Trans></BlueButton>
                                  <Separator variant="w10" />
                                  <BlueLinkButton onClick={stopEditing}><Trans>Cancel</Trans></BlueLinkButton>
                                </div>
                              </div>
                            </Form>
                          }
                        </>
                      </NestedWrappingBusyMask>
                  )
              }}
              
            </Formik>        
        )
    }

    const renderReadOnly = () => {
        return (
            <>
              { formButtonStyle === "icons" &&
                <div css={[readonly_style, icons_readonly_style]} onClick={startEditing}>
                  {label}
                  {label && <Separator variant="w3" />}
                  {localRenderReadOnlyValue()}
                </div>
              }
              
              { formButtonStyle === "buttons" &&
                <div css={[readonly_style, buttons_readonly_style]}>
                  <div css={buttons_readonly_cell_style}>
                    {label}
                    {label && <Separator variant="w3" />}
                    {localRenderReadOnlyValue()}
                  </div>
                  { (canEdit || renderRightSide) &&
                    <div css={buttons_buttonbar_style}>
                      { renderRightSide &&
                        <>
                          {renderRightSide()}
                          <Separator variant="w20" />
                        </>
                      }
                      { canEdit && 
                        <BlueLinkButton onClick={startEditing}>
                          <Trans>Edit</Trans>
                        </BlueLinkButton>
                      }
                    </div>
                  }
                </div>
              }
            </>
        )
    }
    
    return (
        <>
          { isEditing && renderForm() }
          { ! isEditing && renderReadOnly() }
        </>
    )
    
}

const readonly_style = css`
display: flex;
flex-wrap: wrap;
align-items: center;
`

const icons_readonly_style = css`

padding: 8px;

&:hover {
background-color: ${theme.colors.light_grey};
cursor: pointer;
min-width: 350px;
}
`

const buttons_readonly_style = css`
display: flex;
justify-content: space-between;
width: 100%;
align-items: center;
`

const buttons_readonly_cell_style = css`
display: flex;
align-items: center;
flex-wrap: wrap;
`

const edit_style = css`
display: flex;
width: 100%;
justify-content: space-between;
`

const icons_edit_style = css`
display: flex;
justify-content: space-between;
width: 100%;
`

const buttons_edit_style = css`
width: 100%;
justify-content: space-between;
flex-wrap: wrap;
~`

const buttons_buttonbar_style = css`
justify-content: flex-end;
align-items: baseline;
/*text-align: right;*/
display: flex;
`

const icons_buttonbar_style = css`
justify-content: flex-end;
align-items: baseline;
text-align: right;
display: flex;
margin-left: 20px;
flex-wrap: wrap;
`

const buttons_edit_form_fields_style = css`
display: flex;
flex-wrap: wrap;
align-items: center;
width: 100%;
`

const form_style = css`
`

const icons_form_style = css`
`

const buttons_form_style = css`
width: 100%;
`

