/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { Trans, Translation } from 'react-i18next'
import { Button } from 'react-bootstrap'
import { default_theme as theme } from '../emotion/theme'
import { FormikGeneralFormErrors } from './form/GeneralFormErrors'
import { FormikInputField } from './form/InputField'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    password: Yup.string().required("Required")
})

class TokenLoginForm extends Component {
    
    render() {
        const { onSubmit, onCancelToken, error } = this.props

        return (
            <Formik
              initialValues={{}}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {formik_props => {
                  const { values } = formik_props
                  return (
                      <Form css={ form_layout } >
                        <Translation>{ (t) => (
                            <>
                              <FormikGeneralFormErrors />
                              
                              <div css={ error_container }>
                                { error && <p>{ error }</p>}
                              </div>
                              <div className="form-group" css={ field_container }>
                                <label css={ label }><Trans>Password or token</Trans></label>
                                <div>
                                  <FormikInputField
                                    name="password"
                                    type="password"
                                  />
                                </div>
                              </div>
                              <div css={ footer }>
                                <div>
                                  <Button variant="danger" onClick={ onCancelToken }><Trans>Cancel</Trans></Button>
                                </div>
                                <div>
                                  <Button type="submit"><Trans>Login</Trans></Button>
                                </div>
                              </div>

                            </>
                        )}</Translation>
                      </Form>
                  )
              }}
            </Formik>
        )
    }
}

function mapStateToProps(state, props) {
    const { onSubmit, onCancelToken } = props
    return {
        onSubmit,
        onCancelToken
    }
}
export default withRouter(connect(mapStateToProps)(TokenLoginForm))

const form_layout = css`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
margin: 20px;
`

const field_container = css`
width: 80%;
display:flex;
flex-direction: column;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 50px;
flex-direction: column;
color: ${theme.colors.error};
`

const footer = css`
display: flex;
flex-direction: row;
text-align: center;
justify-content: space-between;
width: 80%;
height: 100px;
padding-top:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`
