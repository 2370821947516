/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { size, map, head, get } from 'lodash'
import { Link } from 'react-router-dom'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import ToolTip from '../../components/ToolTip'
import BusyMask from '../../components/BusyMask'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { WhiteButton } from '../../components/layout/WhiteButton'
import CurrencyValue from '../../components/CurrencyValue'
import { Separator } from '../../components/layout/Separator'
import Timestamp from '../../components/Timestamp'
import { adminProductList } from '../actions/admin_product'
import { adminPhoneNumberPrefixSetList } from '../actions/admin_phone_number_prefix_set'
import { adminPhoneNumberPrefixList } from '../actions/admin_phone_number_prefix'
import Card from '../../components/layout/Card'
import { Row, Col, Button, Container } from 'react-bootstrap'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFilter from './AdminTableFilter'
import AdminProductForm from './AdminProductForm'
import { handleSubmitResult } from '../../actions/form'
import {showSuccess, showError} from '../../actions/Error'

const NEW_PRODUCT_ID = 'new'

class AdminProducts extends Component {

    componentDidMount() {
        const { dispatch, product_id } = this.props
        dispatch(adminProductList.updatePaginationNumItemsPerPage(1000))
        dispatch(adminProductList.fetchListIfNeeded())
        if ( product_id && product_id !== NEW_PRODUCT_ID ) {
            dispatch(adminProductList.ensureObjectLoaded(product_id))
        }
    }

    componentDidUpdate(prev_props) {
        const { dispatch, product_id } = this.props
        dispatch(adminProductList.fetchListIfNeeded())
        if ( product_id && product_id !== NEW_PRODUCT_ID ) {
            dispatch(adminProductList.ensureObjectLoaded(product_id))
        }
    }

    onEditProduct = (evt, item) => {
        const { history } = this.props
        history.push({
            pathname: '/admin/product/'+item.id
        })
    }

    onAddProduct = () => {
        const { history } = this.props
        history.push(`/admin/product/${NEW_PRODUCT_ID}`)
    }

    onSaveProduct = (values, formik_funcs) => {
        const { dispatch, history, product_id } = this.props
        let on_ok = function(json) {
            dispatch(adminPhoneNumberPrefixSetList.invalidateAllObjects())
            dispatch(adminPhoneNumberPrefixList.invalidateAllObjects())
            
            if ( product_id === NEW_PRODUCT_ID ) {
                showSuccess("Saved", "Product created")
                history.push(`/admin/product/${json.id}`)
                dispatch(adminProductList.invalidateList())
            } else {
                showSuccess("Saved", "Product updated")
            }
        }

        if ( product_id === NEW_PRODUCT_ID ) {
            return dispatch(adminProductList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            values.id = product_id
            return dispatch(adminProductList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }
    
    onCancelSavingProduct = () => {
        const { history } = this.props
        history.push(`/admin/products/`)
    }

    onSelectProduct = (product) => {
        const { history } = this.props
        history.push(`/admin/product/${product.id}`)
    }

    renderProductRow = (product, index) => {
        const { product_id } = this.props
        return (
            <div key={index}
                 onClick={() => this.onSelectProduct(product)}
                 css={[product_row_style, product_id===product.id ? product_row_selected_style : null]}>

              <div>
                {product.name}
              </div>
              <div css={icon_row_style}>
                { product.default_for_new_customers && <ToolTip info_icon={<InlineIcon icon_name='add_no_circle' variant="blue" />}>Default for new customers</ToolTip> }
                { product.supports_number_porting && <ToolTip info_icon={<InlineIcon icon_name='number_port' variant="blue" />}>Supports number porting</ToolTip> }
                { product.creatable_by_customer && <ToolTip info_icon={<InlineIcon icon_name='tick' variant="blue" />}>Creatable by customers </ToolTip> }
                { product.legacy_ref && <ToolTip info_icon={<InlineIcon icon_name='legacy' variant="blue" />}>Is a legacy product</ToolTip> }
                { product.recommended && <ToolTip info_icon={<InlineIcon icon_name='star' variant="blue" />}>Recommended</ToolTip> }
                { product.is_trial && <ToolTip info_icon={<InlineIcon icon_name='trial' variant="blue" />}>Trial</ToolTip> }
              </div>
            </div>
        )
    }

    renderProductList() {
        const { products, is_ready } = this.props
        const that = this
        return (
            <div>
              <AdminTableHeader title="Products" variant="no_container">
                <WhiteButton onClick={ this.onAddProduct } auto_disable={false}>
                  Add product
                </WhiteButton>
              </AdminTableHeader>
              { is_ready && size(products) === 0 &&
                <div>No products</div>
              }
              <Separator variant="h10" />
              {map(products, (product, index) => that.renderProductRow(product, index)) }
            </div>
        )
    }

    renderProductEdit() {
        const { product_id } = this.props
        const is_new = product_id && product_id === NEW_PRODUCT_ID
        const title = is_new ? "Add product" : "Edit product"
        return (
            <div>
              <AdminTableHeader title={title} variant="no_container" />
              { ! product_id &&
                <div>Select a product, or click new, to start editing</div>
              }
              { product_id &&
                <AdminProductForm product_id={is_new ? null : product_id}
                                  onSave={this.onSaveProduct}
                                  onCancel={this.onCancelSavingProduct}
                />
              }
            </div>
        )
    }
    
    render() {
        const { is_loading, is_ready, headers, products } = this.props
        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'products', label: 'Products', url: '/admin/products'}]}>
              <Container fluid>
                <WrappingBusyMask is_loading={!is_ready}>
                  <Row>
                    <Col md="4">
                      { this.renderProductList() }
                    </Col>
                    <Col md="8">
                      { this.renderProductEdit() }
                    </Col>
                  </Row>
                </WrappingBusyMask>
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const products = adminProductList.getVisibleObjects()
    const product_id = get(props, ["match", "params", "product_id"], null)
    return {
        products,
        product_id,
        is_loading: adminProductList.isLoading(),
        is_ready: adminProductList.isReady()
    }
}

export default connect(mapStateToProps)(AdminProducts)

const product_row_style = css`
margin-bottom: 2px;
padding-left: 15px;
padding-top: 12px;
padding-bottom: 12px;
background-color: #fff;
cursor: pointer;
display: flex;
justify-content: space-between;
`

const product_row_selected_style = css`
background-color: ${theme.colors.very_light_grey};
`

const icon_row_style = css`
display: flex;
`
