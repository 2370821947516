import { ItemList } from '../orm'
import { get, size } from 'lodash'

class NewCustomerSignupSendEmailVerificationRequest extends ItemList {
    getEntityKey() {
        return "new_user/send_verification_code"
    }
}

export const newCustomerSignupSendEmailVerificationRequest = new NewCustomerSignupSendEmailVerificationRequest("new_customer_signup_send_email_verification__default")



class NewCustomerSignupVerifyEmailToken extends ItemList {
    getEntityKey() {
        return "new_user/check_verification_code"
    }
}

export const newCustomerSignupVerifyEmailToken = new NewCustomerSignupVerifyEmailToken("new_customer_signup_verify_email_token__default")


class NewCustomerSignupCreateCustomer extends ItemList {
    getEntityKey() {
        return "new_user/create_customer"
    }
}

export const newCustomerSignupCreateCustomer = new NewCustomerSignupCreateCustomer("new_customer_signup_create_customer__default")



