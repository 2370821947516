import { ItemList } from '../../orm'

class AdminOutboundEmailList extends ItemList {
    getEntityKey() {
        return "admin/outbound_email"
    }

    resendEmail({id, to_address}) {
        return this.saveObject({id: id,
                                action: 'resend',
                                to_address})
    }
}

export const adminOutboundEmailList = new AdminOutboundEmailList("admin_outbound_email__default")
