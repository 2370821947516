/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx } from '@emotion/react'
import { head, get } from 'lodash'
import { adminUserList } from '../actions/admin_user'
import { Link } from 'react-router-dom'
import CurrencyValue from '../../components/CurrencyValue'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFilter from './AdminTableFilter'
import BusyMask from '../../components/BusyMask'
import { AdminQuickLink } from './AdminQuickLink'
import Timestamp from '../../components/Timestamp'
import { adminIncomingMolliePaymentList } from '../actions/admin_mollie_payments'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { Button, Container } from 'react-bootstrap'

class AdminMolliePayments extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminIncomingMolliePaymentList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(adminIncomingMolliePaymentList.fetchListIfNeeded())
    }

    getCellValue = (header_key, item, index) => {
        const { customer_id } = this.props
        switch( header_key ) {
            case 'created_at':
                return <Timestamp value={item.created_at} format="datetime" />
            case 'amount_excluding_vat_euros':
                return <CurrencyValue value={item.amount_excluding_vat_euros} />
            case 'paid_at':
                return <Timestamp value={item.paid_at} format="datetime" />
            case 'transaction':
                return <AdminQuickLink name='transaction' value={item.transaction} />
            default:
                return undefined
        }
    }

    render() {
        const { is_loading, faxes, headers } = this.props
        return (
            <AdminMainLayout title=''
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'mollie_payments', label: 'Mollie payments', url: '/admin/mollie_payments'}]}>
              <Container fluid>
                <AdminTableHeader title="Mollie payments">
                  <AdminTableFilter item_list={adminIncomingMolliePaymentList} />
                </AdminTableHeader>                
                <CommonTable
                  is_loading={ is_loading }
                    empty_message={`No faxes`}
                    headers={ headers }
                    items={ faxes }
                    item_list={adminIncomingMolliePaymentList}
                    getCellValue={ this.getCellValue }
                />
              </Container>
            </AdminMainLayout>
            
        )
    }
    
}

function mapStateToProps(state, props) {
    const faxes = adminIncomingMolliePaymentList.getVisibleObjects()
    return {
        is_loading: adminIncomingMolliePaymentList.isLoading(),
        faxes,
        headers: {
            short_ref: { name: 'Id' },
            created_at: { name: 'created_at' },
            mollie_ref: {name: "Mollie ref"},
            transaction: {name: "Transaction"},
            amount_excluding_vat_euros: { name: 'Amount (euros)' },
            mollie_status: {name: 'Mollie status' },
            error_message: {name: 'Error'},
            paid_at: {name: "Paid at"},
            payment_reason: {name: "Payment reason"}
        }
    }
}

export default connect(mapStateToProps)(AdminMolliePayments)
