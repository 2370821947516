/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { head, get } from 'lodash'
import { Link } from 'react-router-dom'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import CurrencyValue from '../../components/CurrencyValue'
import Timestamp from '../../components/Timestamp'
import { adminOutgoingFaxServiceProviderList } from '../actions/admin_outgoing_fax_service_provider'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { Button, Container } from 'react-bootstrap'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { LanguageFlag } from '../../components/LanguageFlag'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFilter from './AdminTableFilter'

class AdminOutgoingFaxServiceProviders extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminOutgoingFaxServiceProviderList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(adminOutgoingFaxServiceProviderList.fetchListIfNeeded())
    }

    onEditOutgoingFaxServiceProvider = (evt, item) => {
        const { history } = this.props
        history.push({
            pathname: '/admin/outgoing_fax_service_provider/'+item.id,
        })
    }

    onAddOutgoingFaxServiceProvider = () => {
        const { history } = this.props
        history.push('/admin/outgoing_fax_service_provider')
    }

    getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'action':
                return (
                    <Button onClick={(evt) => this.onEditOutgoingFaxServiceProvider(evt, item)}>
                      Edit
                    </Button>
                )
            case 'is_default':
                return item.is_default ? <InlineIcon icon_name="status-success"/> : ""
            default:
                return undefined
        }
    }

    render() {
        const { is_loading, headers, topups } = this.props
        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'outgoing_fax_service_providers', label: 'Outgoing Fax Service Providers', url: '/admin/outgoing_fax_service_providers'}]}>
              <Container fluid>
                <AdminTableHeader title="Outgoing Fax Service Providers">
                  <AdminTableFilter
                      updateOnChange={ this.filterOutgoingFaxServiceProviders }
                      form="ADMIN_TOPUPS_FILTER_FORM"
                  />
                  <Button variant="outline-primary" onClick={ this.onAddOutgoingFaxServiceProvider }>
                    Add OutgoingFaxServiceProvider
                  </Button>
                </AdminTableHeader>
                <CommonTable
                    is_loading={ is_loading }
                    empty_message={`There are no providers.`}
                    headers={ headers }
                    items={ topups }
                    item_list={adminOutgoingFaxServiceProviderList}
                    renderCell={ this.renderCell }
                    getCellValue={ this.getCellValue }
                    sortOnHeader={ this.sortOnHeader }
                />
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const topups = adminOutgoingFaxServiceProviderList.getVisibleObjects()
    return {
        topups,
        is_loading: adminOutgoingFaxServiceProviderList.isLoading(),
        headers: {
            name: { name: 'Name'},
            is_default: { name: 'Default'},
            provider_type: { name: 'Type'},
            url_for_sending: { name: 'Url'},
            failover_outgoing_fax_service_provider_name: {name: "Failover Provider"},
            action: { name: ''}
        }
    }
}

export default connect(mapStateToProps)(AdminOutgoingFaxServiceProviders)
