/** @jsxImportSource @emotion/react */
import React, { Component, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../emotion/theme'
import CurrencyValue from './CurrencyValue'
import { Trans, Translation } from 'react-i18next'

export const SubscriptionAmount = ({ subscription, value_override }) => {

    return (
        <div css={style}>
          { subscription.billing_frequency === 'monthly' &&
            !<>
              <CurrencyValue value={value_override || subscription.cost_per_period_excluding_vat_euros} /><Trans>/mth (ex. VAT)</Trans>
            </>
          }
          { subscription.billing_frequency === 'annually' &&
            <>
              <CurrencyValue value={value_override || subscription.cost_per_period_excluding_vat_euros} /><Trans>/year (ex. VAT)</Trans>
            </>
          }
        </div>
    )

}


const style = css`
display: flex;
`
