import { ItemList } from '../orm'
import { forEach, map, unionBy, compact, get, size, head, filter, split, join, includes } from 'lodash'
import { handleSubmitResult } from './form'
import { answeringMessageList } from './answering_message'
import { customerList } from './customer'
import { blockedNumberList } from './blocked_number'

const CALL_RINGING_CALL_STRATEGIES = ['ringall', 'hunt']

class CustomerPhoneNumberList extends ItemList {
    getEntityKey() {
        return "customer_phone_number"
    }

    isEditable({customer_phone_number}) {
        return true
    }

    getAllDestinations({customer_phone_number, cluster_type, customer}) {
        const res = []
        const clusters = get(this.getRoutingConfig({customer_phone_number}), ["routing", 0, "clusters"], [])
        map(clusters, function(cluster) {
            map(cluster.destinations, (destination) => destination && res.push(destination))
        })
        return res
    }
    
    getEnabledDestinations({customer_phone_number, cluster_type, customer}) {
        const cluster = this.getCluster({customer_phone_number, cluster_type})
        let destinations = filter(cluster.destinations, (destination) => destination && destination.enabled)
        if ( size(destinations) === 0 ) {
            destinations = [ { number: get(customer, "contact_phone_number", "00"),
                               ring_duration_seconds: 10,
                               ring_sequence: 1,
                               _default_auto_created: true } ]
        }
        return destinations
    }

    getBlockedNumbers({customer_phone_number}) {
        return get(this.getGlobalOptions({customer_phone_number}), "blocked_numbers", [])
    }

    setBlockedNumbers({customer_phone_number, blocked_numbers}) {
        return async(dispatch, getState) => {
            const global_options = this.getGlobalOptions({customer_phone_number})
            global_options.blocked_numbers = blocked_numbers
            this.setGlobalOptions({customer_phone_number, global_options})
            dispatch(blockedNumberList.invalidateList())
        }
    }

    getOfficeHoursEnabled({customer_phone_number}) {
        // returns true if the concept of office hours is enabled for this phone number.
        const in_office_cluster = this.getInOfficeHoursCluster({customer_phone_number})

        if ( ! in_office_cluster.enabled ) {
            return false
        }

        const clusters = get(this.getRoutingConfig({customer_phone_number}), ["routing", 0, "clusters"], [])
        return size(clusters) > 1
    }

    setOfficeHoursEnabled({customer_phone_number, enabled}) {

        if ( enabled === true ) {
            const in_office_cluster = this.getInOfficeHoursCluster({customer_phone_number})
            in_office_cluster.enabled = true
            this.setInOfficeHoursCluster({customer_phone_number, cluster: in_office_cluster})

            const out_of_office_cluster = this.getOutOfOfficeHoursCluster({customer_phone_number})
            out_of_office_cluster.enabled = true
            this.setOutOfOfficeHoursCluster({customer_phone_number, cluster: out_of_office_cluster})
            
        } else if ( enabled === false ) {
            const in_office_cluster = this.getInOfficeHoursCluster({customer_phone_number})
            in_office_cluster.enabled = false
            this.setInOfficeHoursCluster({customer_phone_number, cluster: in_office_cluster})

            const out_of_office_cluster = this.getOutOfOfficeHoursCluster({customer_phone_number})
            out_of_office_cluster.enabled = true
            this.setOutOfOfficeHoursCluster({customer_phone_number, cluster: out_of_office_cluster})
        }
        
        const values = {id: customer_phone_number.id,
                        routing_as_json: customer_phone_number.routing_as_json}
        return this.saveObject(values)
    }

    getOfficeHours({customer_phone_number}) {
        const cluster = this.getInOfficeHoursCluster({customer_phone_number})
        return {applicable_days: compact(split(cluster.applicable_days, ",")),
                start_time: cluster.start_time,
                end_time: cluster.end_time,
                timezone: cluster.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone}
    }

    isSinglePhoneNumberDestinationEnabled({customer_phone_number, cluster_type}) {
        const cluster = this.getCluster({customer_phone_number, cluster_type})
        return includes(CALL_RINGING_CALL_STRATEGIES,  cluster.ring_strategy)
    }

    getVoiceMailEnabled({customer_phone_number, cluster_type}) {
        const cluster = this.getCluster({customer_phone_number, cluster_type})
        return cluster.no_answer_action === 'voicemail'
    }

    setVoiceMailEnabled({customer_phone_number, cluster_type, enabled}) {

        let default_voice_action
        if ( enabled ) {
            default_voice_action = "voicemail"
        } else {
            default_voice_action = "hangup"
        }

        // // this sets the default action for calls where the cluster can't be resolved, eg not enough credit, wrong destination etc etc
        // // removed because we're moving more towards clusters where at least one cluster always matches
        // const global_options = this.getGlobalOptions({customer_phone_number})
        // global_options.default_voice_action = default_voice_action
        // this.setGlobalOptions({customer_phone_number, global_options})

        // this sets the default action for calls that get routed but are hung up with no answer
        const cluster = this.getCluster({customer_phone_number, cluster_type})
        cluster.no_answer_action = default_voice_action
        const is_call_forwarding_enabled = this.isSinglePhoneNumberDestinationEnabled({customer_phone_number, cluster_type})

        if ( ! is_call_forwarding_enabled ) {
            if ( enabled ) {
                cluster.ring_strategy = 'voicemail'
            } else {
                cluster.ring_strategy = 'hangup'
            }
        }
        
        this.setCluster({customer_phone_number, cluster_type, cluster})
        
        const values = {id: customer_phone_number.id,
                        routing_as_json: customer_phone_number.routing_as_json}
        return this.saveObject(values)
    }

    getRingStrategy({customer_phone_number, cluster_type}) {
        const cluster = this.getCluster({customer_phone_number, cluster_type})
        return cluster.ring_strategy || "ringall"
    }

    disableSendCallTo({customer_phone_number, cluster_type}) {
        const cluster = this.getCluster({customer_phone_number, cluster_type})

        if ( this.getVoiceMailEnabled({customer_phone_number, cluster_type}) ) {
            cluster.ring_strategy = 'voicemail'
        } else {
            cluster.ring_strategy = 'hangup'
        }
        this.setCluster({customer_phone_number, cluster_type, cluster})
    }

    enableSendCallTo({customer_phone_number, cluster_type}) {
        const cluster = this.getCluster({customer_phone_number, cluster_type})

        const sendCallToOption = this.getForwardingListTypeOption({customer_phone_number, cluster_type})
        if ( sendCallToOption === 'phone_number' ) {
            cluster.ring_strategy = 'ringall'
        } else if ( sendCallToOption === 'call_list' ) {
            if ( cluster.previously_enabled_call_list_ring_strategy === 'hunt' ) {
                cluster.ring_strategy = 'hunt'
            } else {
                cluster.ring_strategy = 'ringall'
            }
        }
        this.setCluster({customer_phone_number, cluster_type, cluster})
    }

    getEmptyDestination({customer_phone_number}) {
        return {number: get(customer_phone_number, ["customer", "contact_phone_number"], "00"),
                ring_duration_seconds: 10,
                ring_sequence: 1,
                enabled: true}
    }
    
    setForwardingListTypeOption({customer_phone_number, cluster_type, forwarding_list_type}) {
        const cluster = this.getCluster({customer_phone_number, cluster_type})

        if ( size(cluster.destinations) === 0 ) {
            cluster.destinations = [this.getEmptyDestination({customer_phone_number})]
        }

        if ( forwarding_list_type === "phone_number" ) {
            map(cluster['destinations'], (destination, index) => {
                if ( index === 0 ) {
                    destination.enabled = true
                } else {
                    destination.enabled = false
                }
            })  
        } else if ( forwarding_list_type === "call_list" ) {
            map(cluster['destinations'], (destination, index) => {
                destination.enabled = true
            })  
        } else {
            console.error(`Unknown send_call_to option: ${forwarding_list_type}`)
        }

        cluster.forwarding_list_type = forwarding_list_type
        this.setCluster({customer_phone_number, cluster_type, cluster})
    }

    getForwardingListTypeOption({customer_phone_number, cluster_type}) {
        const cluster = this.getCluster({customer_phone_number, cluster_type})
        return cluster.forwarding_list_type
    }
    
    isCallForwardingEnabled({customer_phone_number, cluster_type}) {
        const cluster = this.getCluster({customer_phone_number, cluster_type})
        return includes(CALL_RINGING_CALL_STRATEGIES,  cluster.ring_strategy)
    }
    
    setForwardingPhoneNumbers({customer_phone_number, cluster_type,  destinations, caller_id_type, ring_strategy}) {
        const cluster = this.getCluster({customer_phone_number, cluster_type})
        cluster.no_answer_action = get(this.getGlobalOptions({customer_phone_number}), "default_voice_action")

        cluster.ring_strategy = ring_strategy || 'ringall'
        if (includes(CALL_RINGING_CALL_STRATEGIES, cluster.ring_strategy)) {
            cluster.previously_enabled_call_list_ring_strategy = cluster.ring_strategy
        }

        cluster.destinations = unionBy(destinations, cluster.destinations, 'number')
        forEach(cluster.destinations, (destination, index) => {
            if (destination) {
                destination.ring_sequence = index
            }
        })
        
        this.setCluster({customer_phone_number, cluster_type, cluster})

        const global_options = this.getGlobalOptions({customer_phone_number})
        global_options.caller_id_type = caller_id_type
        this.setGlobalOptions({customer_phone_number, global_options})
        return customer_phone_number.routing_as_json
    }

    setRouting({customer_phone_number}) {
        let config = this.getRoutingConfig({customer_phone_number})
        if ( ! config ) {
            config = {}
        }
        if ( ! config.routing ) {
            config.routing = []
        }
        if ( size(config.routing) === 0 ) {
            config.routing.push({name: "default",
                                 phone_number: customer_phone_number.phone_number_number
                                })
        }
        return config
    }

    getActiveAnsweringMessage({customer_phone_number, cluster_type}) {
        const cluster = this.getCluster({customer_phone_number, cluster_type})
        return get(cluster, "answering_message")
    }

    setActiveAnsweringMessage({customer_phone_number, cluster_type, answering_message}) {
        const cluster = this.getCluster({customer_phone_number, cluster_type})
        cluster.answering_message = answering_message
        this.setCluster({customer_phone_number, cluster_type, cluster})
    }
    
    getActiveVoiceForwardingEmail({customer_phone_number, cluster_type}) {
        const cluster = this.getCluster({customer_phone_number, cluster_type})
        return get(cluster, "forward_voicemail_to_email")
    }

    setActiveVoiceForwardingEmail({customer_phone_number, cluster_type, forward_voicemail_to_email}) {
        const cluster = this.getCluster({customer_phone_number, cluster_type})
        cluster.forward_voicemail_to_email = forward_voicemail_to_email
        this.setCluster({customer_phone_number, cluster_type, cluster})
    }
    
    getActiveVoiceForwardingName({customer_phone_number, cluster_type}) {
        const cluster = this.getCluster({customer_phone_number, cluster_type})
        return get(cluster, "forward_voicemail_to_name")
    }

    setActiveVoiceForwardingName({customer_phone_number, cluster_type, forward_voicemail_to_name}) {
        const cluster = this.getCluster({customer_phone_number, cluster_type})
        cluster.forward_voicemail_to_name = forward_voicemail_to_name
        this.setCluster({customer_phone_number, cluster_type, cluster})
    }
    
    getCluster({customer_phone_number, cluster_type}) {
        if ( cluster_type === 'default' ) {
            return this.getOutOfOfficeHoursCluster({customer_phone_number})
        } else if ( cluster_type == 'out_of_office' ) {
            return this.getOutOfOfficeHoursCluster({customer_phone_number})
        } else if ( cluster_type === 'in_office' ) {
            return this.getInOfficeHoursCluster({customer_phone_number})
        } else {
            console.error(`Unknown cluster type: ${cluster_type}`)
            return null
        }
    }

    setCluster({customer_phone_number, cluster_type, cluster}) {
        if ( cluster_type === 'default' ) {
            this.setOutOfOfficeHoursCluster({customer_phone_number, cluster})
        } else if ( cluster_type == 'out_of_office' ) {
            this.setOutOfOfficeHoursCluster({customer_phone_number, cluster})
        } else if ( cluster_type === 'in_office' ) {
            this.setInOfficeHoursCluster({customer_phone_number, cluster})
        } else {
            console.error(`Unknown cluster type: ${cluster_type}`)
        }
    }

    setOfficeHours({customer_phone_number, applicable_days, start_time, end_time, timezone}) {
        const new_values = {applicable_days: join(applicable_days,","),
                            start_time,
                            end_time,
                            timezone}
        const officeHoursCluster = Object.assign({}, this.getInOfficeHoursCluster({customer_phone_number}), new_values)
        this.setInOfficeHoursCluster({customer_phone_number, cluster: officeHoursCluster})
    }
    
    getInOfficeHoursCluster({customer_phone_number}) {
        // for out-of-office hours clusters we by convention use the time_and_days-enabled cluster
        const clusters = get(this.getRoutingConfig({customer_phone_number}), ["routing", 0, "clusters"], [])
        const cluster = head(filter(clusters, (x) => get(x, "time_and_days_enabled") === true))
        if ( cluster ) {
            return cluster
        }

        return this._createEmptyCluster({customer_phone_number,
                                         defaults: {time_and_days_enabled: true,
                                                    enabled: false,
                                                    start_time: '09:00',
                                                    end_time: '17:00',
                                                    applicable_days: '1,2,3,4,5'}})
    }

    setInOfficeHoursCluster({customer_phone_number, cluster}) {
        const outOfOfficeCluster = this.getOutOfOfficeHoursCluster({customer_phone_number})
        const config = this.setRouting({customer_phone_number})
        config.routing[0].clusters = [outOfOfficeCluster, cluster]
        this.setRoutingConfig({customer_phone_number, config})
    }

    getOutOfOfficeHoursCluster({customer_phone_number}) {
        // for out-of-office hours clusters we fall back on the default cluster
        const clusters = get(this.getRoutingConfig({customer_phone_number}), ["routing", 0, "clusters"], [])
        let cluster = head(filter(clusters, (x) => get(x, "time_and_days_enabled") === false))
        if ( cluster ) {
            return cluster
        }
        return this._createEmptyCluster({customer_phone_number,
                                         defaults: {time_and_days_enabled: false,
                                                    enabled: true}})
    }

    _createEmptyCluster({customer_phone_number, defaults}) {
        return Object.assign({time_and_days_enabled: false,
                              enabled: true,
                              forward_voicemail_to_email: customer_phone_number.phone_number_email || get(customerList.getCustomer(), "contact_email"),
                              forward_voicemail_to_name: customer_phone_number.phone_number_name || get(customerList.getCustomer(), "display_name"),
                              answering_message: answeringMessageList.getStandardAnsweringMessageData({customer_phone_number, answering_message_type:'standard_greeting_nl'})
                             },
                             defaults)
    }

    setOutOfOfficeHoursCluster({customer_phone_number, cluster}) {
        const inOfficeCluster = this.getInOfficeHoursCluster({customer_phone_number})
        const config = this.setRouting({customer_phone_number})
        config.routing[0].clusters = [cluster, inOfficeCluster]
        this.setRoutingConfig({customer_phone_number, config})
    }

    getGlobalOptions({customer_phone_number}) {
        const res = get(this.getRoutingConfig({customer_phone_number}), ["global"])
        if (! res ) {
            return {}
        }
        return res
    }

    setGlobalOptions({customer_phone_number, global_options}) {
        let config = this.getRoutingConfig({customer_phone_number})
        if ( ! config ) {
            config = {}
        }
        config.global = global_options
        this.setRoutingConfig({customer_phone_number, config})
    }
    
    getRoutingConfig({customer_phone_number}) {
        if (! customer_phone_number.routing_as_json ) {
            return null
        }
        return JSON.parse(customer_phone_number.routing_as_json)
    }

    setRoutingConfig({customer_phone_number, config}) {
        customer_phone_number.routing_as_json = JSON.stringify(config)
    }
    
}

export const customerPhoneNumberList = new CustomerPhoneNumberList("customer_phone_number__default")
