/** @jsxImportSource @emotion/react */
import React, {Component} from 'react'
import {connect} from 'react-redux'
import { default_theme as theme } from '../emotion/theme'
import { Duration } from './Duration'
import { jsx, css } from '@emotion/react'

const FREE_MINUTES_ORANGE_THRESHOLD = 5
const FREE_MINUTES_RED_THRESHOLD = 0

export const FreeMinutesDuration = ({minutes, container_style, format}) => {

    const rounded_minutes = Math.round(minutes)
    
    return (
        <>
          { rounded_minutes > FREE_MINUTES_ORANGE_THRESHOLD &&
            <div css={[container_style, free_minutes_box_style, free_minutes_blue_style]}>
              <Duration seconds={rounded_minutes*60} hide_seconds_if_empty={true} format={format} />
            </div>
          }
          { rounded_minutes <= FREE_MINUTES_ORANGE_THRESHOLD && minutes > FREE_MINUTES_RED_THRESHOLD &&
            <div css={[container_style, free_minutes_box_style, free_minutes_orange_style]}>
              <Duration seconds={rounded_minutes*60} hide_seconds_if_empty={true} format={format} />
            </div>
          }
          { rounded_minutes <= FREE_MINUTES_RED_THRESHOLD &&
            <div css={[container_style, free_minutes_box_style, free_minutes_red_style]}>
              <Duration seconds={rounded_minutes*60} hide_seconds_if_empty={true} format={format} />
            </div>
          }
        </>
    )
}


const free_minutes_box_style = css`
border-radius: 19px;
padding: 4px 9px 4px 8px;
display: flex;
align-items: center;
color: #ffffff;
width: fit-content;
border: 1px solid transparent;
`

const free_minutes_blue_style = css`
background-color: ${theme.colors.secondary_blue};
`

const free_minutes_orange_style = css`
background-color: ${theme.colors.primary_orange};
`

const free_minutes_red_style = css`
background-color: ${theme.colors.warning_red};
`
