/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { molliePaymentList } from '../actions/mollie_payment'
import WrappingBusyMask from './WrappingBusyMask'
import { upgradePathList } from '../actions/upgrade_path'

class MollieCreditPaymentComplete extends Component {

    componentDidMount() {
        const { history, dispatch } = this.props
        molliePaymentList.tryReturn(history)
        dispatch(upgradePathList.invalidateList())
    }
    
    render() {
        return (
            <WrappingBusyMask is_loading={true} >
            </WrappingBusyMask>
        )
    }

    
}

function mapStateToProps(state, props) {
    return {}
}


export default withRouter(connect(mapStateToProps)(MollieCreditPaymentComplete))
