/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { default_theme as theme } from '../../emotion/theme'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { get, map, size, keys } from 'lodash'
import { ButtonBar } from '../../components/layout/ButtonBar'
import Timestamp from '../../components/Timestamp'
import { confirmModal } from '../../actions/ui'
import { Trans, Translation } from 'react-i18next'
import { adminOutgoingFaxPhoneNumberPrefixList } from '../actions/admin_outgoing_fax_phone_number_prefix'
import { adminOutgoingFaxServiceProviderList } from '../actions/admin_outgoing_fax_service_provider'
import { adminProductList } from '../actions/admin_product'
import { countryList } from '../../actions/country'
import { cityList } from '../../actions/city'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormLabelValue } from '../../components/form/FormLabelValue'
import { FormikDropdownField } from '../../components/form/Dropdown'
import FieldInfoText from '../../components/layout/FieldInfoText'
import { PrettyValue } from '../../components/PrettyValue'
import { LabelValue } from '../../components/layout/LabelValue'
import { FormikInputField } from '../../components/form/InputField'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { GrayButton } from '../../components/layout/GrayButton'
import Loading from '../../components/Loading'
import AdminMainLayout from './AdminMainLayout'
import BusyMask from '../../components/BusyMask'
import Modal from '../../components/Modal'
import {showSuccess, showError} from '../../actions/Error'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import { Col, Row, Container, Tabs, Tab, Button } from 'react-bootstrap'
import Card from '../../components/layout/Card'
import { CardHeader } from '../../components/layout/CardHeader'
import { Form as BootstrapForm } from 'react-bootstrap'
import { FormikTextarea } from '../../components/form/TextareaField'
import { BlueLinkButton } from '../../components/layout/BlueLinkButton'
import { PhoneNumber } from '../../components/PhoneNumber'
import { Separator } from '../../components/layout/Separator'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import AdminAssociatedProductList from './AdminAssociatedProductList'
import { handleSubmitResult } from '../../actions/form'
import { adminPhoneNumberStatuses, adminNumberTypes } from '../actions/admin_dropdown_options'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    'destination_prefix': Yup.string().required("Required"),
})

class AdminOutgoingFaxPhoneNumberPrefix extends Component {

    componentDidMount() {
        const { dispatch, prefix_id } = this.props
        dispatch(adminOutgoingFaxPhoneNumberPrefixList.fetchListIfNeeded())
        dispatch(adminOutgoingFaxPhoneNumberPrefixList.ensureObjectLoaded(prefix_id))
        dispatch(adminOutgoingFaxServiceProviderList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch, prefix_id } = this.props
        dispatch(adminOutgoingFaxPhoneNumberPrefixList.ensureObjectLoaded(prefix_id))
        dispatch(adminOutgoingFaxServiceProviderList.fetchListIfNeeded())
    }

    onSave = (values, formik_funcs) => {
        const { history, dispatch, prefix_id } = this.props
        const that = this
        const on_ok = function(json) {
            showSuccess("Saved", "Number prefix saved")
            dispatch(adminOutgoingFaxPhoneNumberPrefixList.invalidateList())
            if ( ! prefix_id ) {
                history.push(`/admin/outgoing_fax_number_prefix/${json.id}`)
            }
        }
        if ( prefix_id ) {
            values.id = prefix_id
            return dispatch(adminOutgoingFaxPhoneNumberPrefixList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminOutgoingFaxPhoneNumberPrefixList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    onDeletePrefix = () => {
        const { dispatch, history, prefix_id } = this.props
        const on_ok = function(json) {
            showSuccess("Deleted", "Prefix has been deleted")
            dispatch(adminOutgoingFaxPhoneNumberPrefixList.invalidateList())
            history.push("/admin/outgoing_fax_number_prefixes")
        }

        const onConfirmed = () => {
            return dispatch(adminOutgoingFaxPhoneNumberPrefixList.deleteObject(prefix_id)).then(on_ok)
        }
        
        const text = (<Trans>Are you sure you want to delete this prefix?</Trans>)
        return dispatch(confirmModal({text:text,
                                      onConfirmed: onConfirmed}))
        
    }

    renderForm(formik_props) {
        const { number, outgoing_fax_service_provider_options } = this.props
        return (
            <Card variant="white_wide_padding">
              <FormLabelValue spacing="h10">
                Prefix (must start with 00)
                <FormikInputField name="destination_prefix" />
              </FormLabelValue>

              <FormLabelValue spacing="h10">
                Provider
                <FormikDropdownField name="outgoing_fax_service_provider"
                                     formik_props={formik_props}
                                     options={outgoing_fax_service_provider_options}
                                     on_change={this.onCountryChanged}
                                     placeholder="Select provider" />
              </FormLabelValue>

              <GrayButton type="submit">
                Save
              </GrayButton>
              
            </Card>
        )
    }

    render() {
        const { prefix_id, is_busy, is_loading, prefix, initial_values, product_ids } = this.props
        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'number_prefixes', label: 'Outgoing fax number prefixes', url: '/admin/outgoing_fax_number_prefixes'},
                                           {name: 'prefix', label: get(prefix, "destination_prefix"), url: `/admin/outgoing_fax_number_prefix/${prefix_id}`}]}>
              { is_busy && <BusyMask /> }
              <WrappingBusyMask is_loading={is_loading}>
                <Container fluid>

                  <Row>
                    <Col md="8">
                      <div css={header_style}>
                        <h1>
                          { prefix_id &&
                            <div>
                              { get(prefix, "destination_prefix")} - {get(prefix, "outgoing_fax_service_provider_name")}
                            </div>
                          }
                          { !prefix_id && "New prefix" }
                        </h1>
                        { prefix_id && 
                          <WhiteButton onClick={this.onDeletePrefix} extra_css={delete_number_button_style}>Delete number prefix</WhiteButton>
                        }
                      </div>
                    </Col>
                    
                  </Row>
                  
                  <Formik
                    initialValues={initial_values}
                    onSubmit={this.onSave}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                  >
                    {formik_props => {
                        const { values } = formik_props
                        return (
                            <Form>
                              <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                              <Row>
                                <Col md="4">
                                  { this.renderForm(formik_props) }
                                </Col>
                              </Row>
                            </Form>
                        )
                    }}
                  </Formik>
                </Container>
              </WrappingBusyMask>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const prefix_id = get(props, ["match", "params", "outgoing_fax_number_prefix_id"], null)
    const prefix = prefix_id && adminOutgoingFaxPhoneNumberPrefixList.getObject(prefix_id)
    const is_loading = prefix_id && adminOutgoingFaxPhoneNumberPrefixList.isLoading()
    
    return {
        prefix_id,
        prefix,
        number_type_options: adminNumberTypes.getAsOptions(),
        phone_number_statuses: adminPhoneNumberStatuses.getObjectsById(),
        city_filter: cityList.getFilter(),
        is_loading,
        is_busy: adminOutgoingFaxPhoneNumberPrefixList.getIsSavingObject(),
        initial_values: prefix || {prefix: ''},
        outgoing_fax_service_provider_options: adminOutgoingFaxServiceProviderList.getAsOptions()
    }
}
export default withRouter(connect(mapStateToProps)(AdminOutgoingFaxPhoneNumberPrefix))

const header_style = css`
display: flex;
justify-content: space-between;
`

const delete_number_button_style = css`
color: ${theme.colors.dark_middle_grey};
`
