import { ItemList } from '../../orm'
import { includes, get, union } from 'lodash'

class AdminVoxboneStatusList extends ItemList {
    getEntityKey() {
        return "voxbone/status"
    }

    checkStatus(phone_number_id) {
        return this.saveNewObject({phone_number:phone_number_id})
    }

    forwardToThisServer(phone_number_id) {
        return this.saveNewObject({phone_number:phone_number_id, action: 'forward_faxes_to_this_server'})
    }

    filterOnPhoneNumber(phone_number_id) {
        return async(dispatch, getState) => {
            const filter = this.getFilter()
            if ( ! includes(get(filter, "phone_numbers"), phone_number_id) ) {
                dispatch(this.updateListFilter({phone_numbers: union(get(filter, "phone_numbers", []), [phone_number_id])}))
                dispatch(this.fetchListIfNeeded())
            }
        }
    }
    
}

export const adminVoxboneStatusList = new AdminVoxboneStatusList("admin_voxbone_status__default")
export const adminCustomerVoxboneStatusList = new AdminVoxboneStatusList("admin_customer_voxbone_status__default")

