/** @jsxImportSource @emotion/react */
import React, { Component, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { Field, useField, FieldArray } from 'formik'
import { filter, get, map, values, size } from 'lodash'
import { FormikFileUploadField } from './FileUploader'
import { Trans } from 'react-i18next'
import { fileInfoSetList } from '../../actions/file_info_set'
import { Button, Card, Accordion } from 'react-bootstrap'
import MediaPreviewPopup from '../MediaPreviewPopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faPlusCircle, faEye } from '@fortawesome/free-solid-svg-icons'
import { InlineIcon } from '../layout/InlineIcon'
import { handleSubmitResult } from '../../actions/form'

export const FormikFileSetField = ({ file_types, onChange, formik_props, dispatch, file_set_id, name, label, field, type, checked, ...props }) => {

    if ( ! formik_props.values[name] ) {
        formik_props.setFieldValue(name, {files:[{}]})
    } 

    const new_index = size(formik_props.values)
    const supported_file_types = file_types || ".jpg, .png, .jpeg, .pdf"
    const upload_relative_url = 'file_info/'

    if ( size(formik_props.values[name]) === 0 ) {
        formik_props.setFieldValue(name, {"files": [{}] })
    }

    const onRemoveFile = (evt, index, arrayHelpers) => {
        evt.preventDefault()
        arrayHelpers.remove(index)
        if ( onChange ) {
            onChange()
        }
    }

    const onFileUploaded = (file_info, index, arrayHelpers) => {
        const on_ok = (file_info_set) => {
            formik_props.setFieldValue(name, file_info_set)
            arrayHelpers.insert(size(get(formik_props, ["values", name, "files"])), {})
            if ( onChange ) {
                onChange()
            }
        }

        const files = get(formik_props, ["values", name, "files"])
        files[index] = file_info
        dispatch(fileInfoSetList.saveNewObject({files: files})).then((res) => handleSubmitResult({res, on_ok}))
    }

    const renderUploadedFile = (file_number, file, arrayHelpers) => {
        return (
            <div css={[msg_style, uploaded_msg_style]}>
              <div css={uploaded_file_label}>
                <div css={[upload_msg_style__icon, upload_msg_style__number_label]}>
                  {file_number}
                </div>
                <div css={upload_file_label__text}>
                  {file.visible_filename}
                </div>
              </div>
              <div css={uploaded_file_actions}>
                <MediaPreviewPopup file={file}
                                   render_preview_trigger={(onShowPreview) => (
                                       <div css={[clickable_awesome_icon, uploaded_msg_style__icon]}
                                            onClick={onShowPreview}>
                                         <InlineIcon icon_name="eye" size_variant="h16" />
                                       </div>
                                   )}
                />
                <div css={[clickable_awesome_icon, uploaded_msg_style__last_icon]} onClick={(evt) => onRemoveFile(evt, file_number-1, arrayHelpers)}>
                  <InlineIcon icon_name="remove" size_variant="h16" />
                </div>
                
              </div>
            </div>
        )
    }
    
    const renderPlaceHolder = (file_number, file) => {
        return (
            <div css={[msg_style, upload_msg_style]}>
              <div css={uploaded_file_label}>
                <div css={[upload_msg_style__icon, upload_msg_style__number_label]}>
                  <InlineIcon icon_name="add" />
                </div>
                <div css={upload_file_label__text}><Trans>Click or drag here to upload a file</Trans></div>
              </div>
            </div>
        )
    }
    
    return (
        <FieldArray
            name={`${name}.files`}
            render={arrayHelpers => (
                <div>
                  {map(get(formik_props, ["values", name, "files"], []), function(file, index) {
                       return (
                           <div key={index} css={file_row}>
                             { file && file.id && renderUploadedFile(index+1, file, arrayHelpers) }
                             { (!file || !file.id) && 
                               <FormikFileUploadField name={`${name}.files.${index}`}
                                                           upload_relative_url={upload_relative_url}
                                                           file_types={supported_file_types}
                                                           formik_props={formik_props}
                                                           file_number={index+1}
                                                           renderPlaceHolder={() => renderPlaceHolder(index+1, file)}
                                                           onFileUploaded={(file_info) => onFileUploaded(file_info, index, arrayHelpers)}
                               />
                             }
                           </div>
                       )
                   }
                  )}
                </div>
            )}
        />
    )
}

const file_header = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const clickable_awesome_icon = css`
cursor: pointer;
`

const file_card_header = css`
display: flex;
width: 100%;

`

const file_row = css`
display: flex;
width: 100%;
align-items: center;
color: #000;
/*height: 40px;*/
margin-bottom: 10px;


`

const msg_style = css`
display: flex;
text-align: center;
justify-content: space-between;
width: 100%;
border-radius: 4px;
padding-left: 13px;
padding-right: 14px;
padding-top: 8px;
height: 40px;
`

const uploaded_msg_style = css`
background-color: ${theme.colors.very_light_grey};
`

const upload_msg_style = css`
border: 1px dashed #979797;
background-color: ${theme.colors.nearly_white};
`

const upload_msg_style__icon = css`
margin-right: 10px;
color: #b8b8bd;
`

const upload_msg_style__number_label = css`
width: 15px;
text-align: right;
`

const uploaded_msg_style__icon = css`
margin-right: 9px;
color: #b8b8bd;
`

const uploaded_msg_style__last_icon = css`
margin-right: 0px;
color: #b8b8bd;
`

const upload_field_container = css`
display: flex;
width: 100%;
`

const uploaded_file_label = css`
display: flex;
text-overflow: hidden;
position: relative;
text-align: left;
width: 90%;
`

const upload_file_label__text = css`
left: 40px;
overflow: hidden;
width: 100%;
text-overflow: ellipsis;
white-space: nowrap;
`

const uploaded_file_actions = css`
display: flex;
`

