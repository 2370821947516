/** @jsxImportSource @emotion/react */
import React, { Component, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'

export const BlackLinkButton = ({ onClick, children, ...props }) => {

    return (
        <div css={style} onClick={onClick}>
          {children}
        </div>
    )

}

const style = css`
background-color: #fff;
cursor: pointer;
font-size: 16px;
color: #000;

&:hover {
background-color: #fff;
}
`
