/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { has, get } from 'lodash'
import { ButtonBar } from '../../components/layout/ButtonBar'
import AdminMainLayout from './AdminMainLayout'
import { FormikDatePicker } from '../../components/form/DatetimePicker'
import CommonTable from '../../components/CommonTable'
import { adminPhoneNumberStatuses, adminNumberTypes } from '../actions/admin_dropdown_options'
import { adminPhoneNumberProviderList } from '../actions/admin_phone_number_provider'
import { WhiteButton } from '../../components/layout/WhiteButton'
import CurrencyValue from '../../components/CurrencyValue'
import Pagination from '../../components/Pagination'
import Timestamp from '../../components/Timestamp'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFooter from './AdminTableFooter'
import AdminTableFilter from './AdminTableFilter'
import Table from 'react-bootstrap/Table'
import { countryList } from '../../actions/country'
import { cityList } from '../../actions/city'
import { Container, Button, Col, Row } from 'react-bootstrap'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { FormikInputField } from '../../components/form/InputField'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { InlineIconBar } from '../../components/layout/InlineIconBar'
import { adminNumberList } from '../actions/admin_number'
import { Separator } from '../../components/layout/Separator'
import { adminPhoneNumberPrefixList } from '../actions/admin_phone_number_prefix'

class AdminNumbers extends Component {
 
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminNumberList.fetchListIfNeeded())
        dispatch(adminPhoneNumberPrefixList.fetchListIfNeeded())
        dispatch(countryList.updatePaginationNumItemsPerPage(1000))
        dispatch(cityList.updatePaginationNumItemsPerPage(1000))
        dispatch(countryList.fetchListIfNeeded())
        dispatch(cityList.fetchListIfNeeded())
        dispatch(adminPhoneNumberStatuses.fetchListIfNeeded())
        dispatch(adminNumberTypes.fetchListIfNeeded())
        dispatch(adminPhoneNumberProviderList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(adminNumberList.fetchListIfNeeded())
        dispatch(countryList.fetchListIfNeeded())
        dispatch(cityList.fetchListIfNeeded())
        dispatch(countryList.fetchListIfNeeded())
        dispatch(cityList.fetchListIfNeeded())
    }

    onEdit = (evt, item) => {
        const { history } = this.props
        history.push('/admin/number/' + item.id)
    }

    onAdd = () => {
        const { history } = this.props
        history.push('/admin/number/')
    }

    filterNumber = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminNumberList.updateListFilter(filter_values))
    }

    onCountryChanged = (country_id) => {
        const { dispatch } = this.props
        dispatch(cityList.filterOnCountry(country_id))
    }

    getNumberCellValue = (header_key, item, index) => {
        const { status_options_by_id, number_types_by_id } = this.props
        switch( header_key ) {
            case 'created_at':
                return <Timestamp value={item.created_at} format='date' />
            case 'last_active':
                return <Timestamp value={item.last_active} format='datetime' />
            case 'status':
                return get(status_options_by_id, [item.status, "name"])
            case 'number_type':
                return get(number_types_by_id, [item.number_type, "name"])
            case 'action':
                return (
                    <InlineIconBar>
                      <InlineIcon icon_name="edit" onClick={(evt) => this.onEdit(evt, item)} />
                    </InlineIconBar>
                )
            default:
                return undefined
        }
    }

    renderFilter = (formik_props) => {
        const { country_options, city_options, status_options, provider_options } = this.props
        return (
            <Col md="12">
              <div css={filter_field_row_style}>
                <FormikInputField name="number_prefix"
                                  placeholder="Number&nbsp;prefix" />
                <Separator variant="w5" />
                
                <FormikDropdownField name="country"
                                     formik_props={formik_props}
                                     options={country_options}
                                     on_change={this.onCountryChanged}
                                     empty_selection_label="Any country"
                                     placeholder="Country" />
                <Separator variant="w5" />
                
                <FormikDropdownField name="city"
                                     formik_props={formik_props}
                                     options={city_options}
                                     empty_selection_label="Any city"
                                     placeholder="City" />
                <Separator variant="w5" />

                <FormikDropdownField name="status"
                                     formik_props={formik_props}
                                     options={status_options}
                                     empty_selection_label="Any status"
                                     placeholder="Status" />
                <Separator variant="w5" />

                <FormikDropdownField name="provider"
                                     formik_props={formik_props}
                                     options={provider_options}
                                     empty_selection_label="Any provider"
                                     placeholder="Provider" />
                <Separator variant="w5" />

                
                <FormikDatePicker name="created_at_from" formik_props={formik_props} />
                <Separator variant="w5" />
                
                <FormikInputField name="any_field"
                                  formik_props={formik_props}
                                  show_search_icon={true}
                                  placeholder="Search" />


                <Separator variant="w20" />
                <WhiteButton onClick={ this.onAdd }>
                  Add Number
                </WhiteButton>

              </div>
            </Col>
        )
    }

    render() {
        const {
            number_headers,
            numbers,
            is_loading,
            number_filter
        } = this.props
        
        return (
            <AdminMainLayout active_key="numbers"
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'numbers', label: 'Numbers', url: '/admin/numbers'}]}>
              <AdminTableHeader title="Numbers">
                <div css={ css`width:100%;`}>
                  <AdminTableFilter updateOnChange={ this.filterNumber }
                                    is_loading={ is_loading }
                                    filter={number_filter}
                                    renderFilter={ this.renderFilter }
                  />
                  { false && 
                    <div css={ header }>
                      <Button variant="outline-primary" onClick={ this.onAdd }>
                        Add Number
                      </Button>
                    </div>
                  }
                </div>
              </AdminTableHeader>
              <Separator variant="h10" />
              <Container fluid>
                <CommonTable is_loading={ is_loading }
                             empty_message="There are no numbers."
                             headers={ number_headers }
                             items={ numbers }
                             getCellValue={ this.getNumberCellValue }
                             sortOnHeader={ this.sortOnHeader }
                             item_list={adminNumberList}
                />
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const numbers = adminNumberList.getVisibleObjects() || []
    const country_options = countryList.getAsOptionsWithPriority()
    const city_options = cityList.getAsOptions({show_prefix_in_label:true})
    const initial_filter_values =  Object.assign({ created_at_from: null }, adminNumberList.getFilter())
    
    return {
        numbers,
        number_filter: initial_filter_values,
        number_headers: {
            number: { name: "Number" },
            country_name: { name: "Country" },
            number_type: { name: 'Type' },
            status: { name: 'Status' },
            created_at: { name: 'Since' },
            last_active: { name: 'Last active' },
            provider_name: { name: 'Provider' },
            action: { name: 'Edit' }
        },
        is_loading: adminNumberList.isLoading(),
        country_options,
        city_options,
        status_options: adminPhoneNumberStatuses.getAsOptions(),
        status_options_by_id: adminPhoneNumberStatuses.getObjectsById(),
        provider_options: adminPhoneNumberProviderList.getAsOptions(),
        number_types_by_id: adminNumberTypes.getObjectsById(),
        city_filter: cityList.getFilter()
    }
}
export default withRouter(connect(mapStateToProps)(AdminNumbers))

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const filter_field_row_style = css`
display: flex;
`
