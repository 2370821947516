/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { head, get } from 'lodash'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import AdminMainLayout from './AdminMainLayout'
import { FormikDropdownField } from '../../components/form/Dropdown'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import { FormikInputField } from '../../components/form/InputField'
import CurrencyValue from '../../components/CurrencyValue'
import Timestamp from '../../components/Timestamp'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { CardHeader } from '../../components/layout/CardHeader'
import AdminTableFilter from './AdminTableFilter'
import { Separator } from '../../components/layout/Separator'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { GrayButton } from '../../components/layout/GrayButton'
import { DatePicker } from '../../components/form/DatetimePicker'
import { Button, Container, Popover, OverlayTrigger } from 'react-bootstrap'
import { adminTransactionTypes } from '../actions/admin_dropdown_options'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { LanguageFlag } from '../../components/LanguageFlag'
import AdminTableHeader from './AdminTableHeader'
import CommonTransactionTotals from '../../components/CommonTransactionTotals'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import { adminResellerList } from '../actions/admin_reseller'
import { Col, Row } from 'react-bootstrap'
import { AdminTableFilterPopup } from './AdminTableFilterPopup'

class AdminCommonTransactions extends Component {

    constructor(props) {
        super(props)
        this.state = {show_totals: false}
    }
    
    componentDidMount() {
        const { dispatch, transactionList, aggregatedTransactions } = this.props
        dispatch(transactionList.fetchListIfNeeded())
        dispatch(aggregatedTransactions.ensureTotalsLoaded())
        dispatch(adminTransactionTypes.fetchListIfNeeded())
        dispatch(adminResellerList.updatePaginationNumItemsPerPage(1000))
        dispatch(adminResellerList.fetchListIfNeeded())
        dispatch(adminTransactionTypes.updatePaginationNumItemsPerPage(1000))
        dispatch(adminTransactionTypes.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch, transactionList, aggregatedTransactions } = this.props
        dispatch(transactionList.fetchListIfNeeded())
        dispatch(aggregatedTransactions.ensureTotalsLoaded())
        dispatch(adminResellerList.fetchListIfNeeded())
    }

    onViewTransaction = (evt, item) => {
        const { history } = this.props
        history.push({
            pathname: '/admin/transaction/'+item.id
        })
    }

    onDownload = () => {
        const { filter, transactionList } = this.props
        transactionList.download(filter)
    }

    onShowTotals = () => {
        this.setState({show_totals: true})
    }

    onHideTotals = () => {
        this.setState({show_totals: false})

        // workaround: see https://stackoverflow.com/questions/38467848/react-bootstrap-how-to-manually-close-overlaytrigger
        document.body.click()
    }
    
    onFilterChanged = (filter_values) => {
        const { dispatch, transactionList, aggregatedTransactions } = this.props
        dispatch(transactionList.updateListFilter(filter_values))
        dispatch(aggregatedTransactions.updateListFilter(filter_values))
    }

    onDateFromChanged = (value) => {
        const { dispatch, transactionList, aggregatedTransactions } = this.props
        dispatch(transactionList.updateListFilter({'executed_at_from': value.format()}))
        dispatch(aggregatedTransactions.updateListFilter({'executed_at_from': value.format()}))
    }

    onDateToChanged = (value) => {
        const { dispatch, transactionList, aggregatedTransactions } = this.props
        dispatch(transactionList.updateListFilter({'executed_at_to': value.format()}))
        dispatch(aggregatedTransactions.updateListFilter({'executed_at_to': value.format()}))
    }

    onResellerFilterChanged = (value) => {
        const { dispatch, transactionList, aggregatedTransactions } = this.props
        dispatch(transactionList.updateListFilter({'reseller': value}))
        dispatch(aggregatedTransactions.updateListFilter({'reseller': value}))
    }
    
    getCellValue = (header_key, item, index) => {
        const that = this

        // Transactions can go either way round, but we would like to present them from the customer's perspective
        const swap_debtor_and_creditor = item.is_credit_to_customer &&  item.amount_excluding_vat_euros < 0
        
        switch( header_key ) {
            case 'executed_at':
                return (
                    <Timestamp value={item.executed_at} format='datetime' />
                )

            case 'transaction_type':
                return (
                    <span>{get(adminTransactionTypes.getObject(item.transaction_type), "name")}</span>
                )
            case 'amount_excluding_vat_euros':
                return (
                    <CurrencyValue value={ item.invert_to_make_customer_the_creditor ? -1*item.amount_excluding_vat_euros : item.amount_excluding_vat_euros } />
                )

            case 'amount_including_vat_euros':
                return (
                    <CurrencyValue value={ item.invert_to_make_customer_the_creditor ? -1*item.amount_including_vat_euros : item.amount_including_vat_euros } />
                )

            case 'debtor_display_name':
                return item.invert_to_make_customer_the_creditor ? item.creditor_display_name : item.debtor_display_name

            case 'creditor_display_name':
                return item.invert_to_make_customer_the_creditor ? item.debtor_display_name : item.creditor_display_name
            
            case 'action':
                return (
                    <InlineIcon icon_name="eye" onClick={(evt) => that.onViewTransaction(evt, item)}/>
                )
            default:
                return undefined
        }
    }

    renderFilterFields = (formik_props) => {
        const that = this
        const { reseller_options, transaction_type_options } = this.props
        return (
            <AdminTableFilterPopup popup_fields={(
                <Row>
                  <Col md="12">
                    <Row>
                      <Col md="5">
                        <DatePicker extra_css={filter_date_picker_style} onChange={this.onDateFromChanged} placeholder="From date" />
                      </Col>
                      <Col md="5">
                        <DatePicker extra_css={filter_date_picker_style} onChange={this.onDateToChanged} placeholder="To date" />
                      </Col>
                    </Row>
                    <Separator variant="h10" />
                    <Row>
                      <Col>
                        <FormikDropdownField name="transaction_type"
                                             formik_props={formik_props}
                                             options={transaction_type_options}
                                             placeholder="Type"
                        />

                      </Col>
                    </Row>
                    <Separator variant="h10" />
                    <Row>
                      <Col>
                        <FormikDropdownField name="reseller"
                                             formik_props={formik_props}
                                             options={reseller_options}
                                             placeholder="Reseller"
                                             extra_css={reseller_filter_style}
                        />

                      </Col>
                    </Row>

                    <Separator variant="h10" />
                    <Row>
                      <Col>
                        <WhiteButton onClick={this.onDownload} auto_disable={false}>
                          <div css={download_button_style}>
                            <InlineIcon icon_name="download" onClick={that.onDownload} />
                            <Separator variant="w5" />
                            Excel
                          </div>
                        </WhiteButton>
                      </Col>
                    </Row>
                    
                  </Col>
                </Row>
            )}
                                   fixed_fields={(
                                       <Row>
                                         <Col>
                                           <FormikInputField name="any_field"
                                                             placeholder="Search"
                                                             show_search_icon={true}
                                           />
                                         </Col>
                                       </Row>
                                   )}
            />
        )
    }
    
    renderFilter() {
        const that = this
        return (
            <AdminTableFilter updateOnChange={ that.onFilterChanged }
                              renderFilter={that.renderFilterFields}
            />
        )
    }

    render() {
        const { is_loading, is_ready, headers, transactions, title, transactionList, additional_header } = this.props
        const that = this
        return (
            <div>
              <CardHeader title={title || "Transactions"}
                          right_child={<div css={right_child_style}>
                                         { that.renderFilter() }
                                         { additional_header }                                       
                                       </div>} />

              <WrappingBusyMask is_loading={!is_ready}>
                <CommonTable
                  is_loading={ is_loading }
                  empty_message={`There are no transactions.`}
                  headers={ headers }
                  items={ transactions }
                  item_list={transactionList}
                  renderCell={ this.renderCell }
                  getCellValue={ this.getCellValue }
                  sortOnHeader={ this.sortOnHeader }
                />
              </WrappingBusyMask>
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    const { transactionList, aggregatedTransactions } = props
    const transactions = transactionList.getVisibleObjects()
    return {
        transactions,
        is_loading: transactionList.isLoading(),
        is_ready: transactionList.isReady(),
        filter: transactionList.getFilter(),
        totals: aggregatedTransactions.getTotals(),
        headers: {
            short_ref: { name: "Id"},
            external_ref: {name: "Mollie ref"},
            executed_at: { name: "When"},
            transaction_type: { name: "Type"},
            description: { name: "Description"},
            amount_excluding_vat_euros: { name: "Amount (exVAT)"},
            amount_including_vat_euros: { name: "Amount (incVAT)"},
            debtor_display_name: { name: "From"},
            creditor_display_name: { name: "To"},
            action: { name: ''}
        },
        reseller_options: adminResellerList.getAsOptions({empty_option_label:'All resellers'}),
        transaction_type_options: adminTransactionTypes.getAsOptions({empty_option_label:'All types'})
    }
}

export default withRouter(connect(mapStateToProps)(AdminCommonTransactions))

const download_button_style = css`
display: flex;
justify-content: space-between;
`

const filter_style = css`
display: flex;
align-items: center;
`

const any_field_filter_style = css`
input { min-width: 200px; }
`

const filter_date_picker_style = css`
margin-right: 2px;
`

const right_child_style = css`
display: flex;
`

const totals_popover_style = css`
width: unset;
max-width: 375px;
`

const totals_buttons_container_style = css`
display: flex;
justify-content: flex-end;
`

const reseller_filter_style = css`
width: 400px;
`
