import { ItemList } from '../../orm'

class AdminCustomerPhoneNumberList extends ItemList {
    getEntityKey() {
        return "admin/customer_phone_number"
    }
}

export const adminCustomerPhoneNumberList = new AdminCustomerPhoneNumberList("admin_customer_phone_number_default")
export const adminCustomerVoicePhoneNumberList = new AdminCustomerPhoneNumberList("admin_customer_voice_phone_number_default")
