/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { CardHeader } from '../../components/layout/CardHeader'
import { Container } from 'react-bootstrap'
import { adminInvoiceList } from '../actions/admin_invoice'
import { adminCustomerList } from '../actions/admin_customer'
import AdminInvoiceTable from './AdminInvoiceTable'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { Separator } from '../../components/layout/Separator'

class AdminInvoices extends Component {

    componentDidMount() {
        const { dispatch, customer_id } = this.props
        if ( customer_id ) {
            dispatch(adminInvoiceList.updateListFilter({customer:customer_id}))
        } else {
            dispatch(adminInvoiceList.updateListFilter({customer: undefined}))
        }
    }
    
    componentDidUpdate() {
        const { dispatch, customer_id, admin_invoice_list_filter } = this.props
        if ( admin_invoice_list_filter.customer !== customer_id  ) {
            if ( ! customer_id ) {
                dispatch(adminInvoiceList.updateListFilter({customer: undefined}))
            } else {
                dispatch(adminInvoiceList.updateListFilter({customer:customer_id}))
            }
        }
        if ( customer_id ) {
            dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
        }
    }

    onDownloadAsTwinfield = () => {
        const { admin_invoice_list_filter } = this.props
        adminInvoiceList.downloadAsTwinfield(admin_invoice_list_filter)
    }
    
    render() {
        const that = this
        return (
            <div>
              <AdminInvoiceTable itemList={adminInvoiceList}
                                 additional_filters={<div>
                                   <WhiteButton onClick={this.onDownloadAsTwinfield} auto_disable={false}>
                                     <div css={download_button_style}>
                                       <InlineIcon icon_name="download" onClick={that.onDownloadAsTwinfield} />
                                       <Separator variant="w5" />
                                                           Twinfield
                                     </div>
                                   </WhiteButton>
                                 </div>}
              />
            </div>
            
        )
    }
    
}

function mapStateToProps(state, props) {
    const { customer_id } = props
    const customer = customer_id && adminCustomerList.getObject(customer_id)
    return {
        customer_id,
        customer,
        admin_invoice_list_filter: adminInvoiceList.getFilter()
    }
}

export default connect(mapStateToProps)(AdminInvoices)

const header_right_child = css`
display: flex;
`

const download_button_style = css`
display: flex;
justify-content: space-between;
`
