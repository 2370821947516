/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { has, get } from 'lodash'
import { ButtonBar } from '../../components/layout/ButtonBar'
import {showSuccess, showError} from '../../actions/Error'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { BlueButton } from '../../components/layout/BlueButton'
import { AdminTableFilterPopup } from './AdminTableFilterPopup'
import { Modal } from 'react-bootstrap'
import AdminMainLayout from './AdminMainLayout'
import CommonTable from '../../components/CommonTable'
import CardInfoText from '../../components/layout/CardInfoText'
import CurrencyValue from '../../components/CurrencyValue'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { FormikDatePicker } from '../../components/form/DatetimePicker'
import Pagination from '../../components/Pagination'
import { Separator } from '../../components/layout/Separator'
import Timestamp from '../../components/Timestamp'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFooter from './AdminTableFooter'
import AdminTableFilter from './AdminTableFilter'
import { FormikInputField } from '../../components/form/InputField'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { Tristate } from '../../components/form/Tristate'
import Table from 'react-bootstrap/Table'
import { adminResellerList } from '../actions/admin_reseller'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { InlineIconBar } from '../../components/layout/InlineIconBar'
import { adminCustomerList } from '../actions/admin_customer'
import { adminCustomerReportList } from '../actions/admin_customer_report'
import { Container, Button, Col, Row } from 'react-bootstrap'

class AdminCustomers extends Component {

    constructor(props) {
        super(props)
        this.state = {show_reports_modal: false,
                      report_filter: null}
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminCustomerList.fetchListIfNeeded())
        dispatch(adminResellerList.updatePaginationNumItemsPerPage(1000))
        dispatch(adminResellerList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(adminCustomerList.fetchListIfNeeded())
    }

    onEdit = (evt, item) => {
        const { history } = this.props
        history.push('/admin/customer/' + item.id)
    }

    onAdd = () => {
        const { history } = this.props
        history.push('/admin/customer_profile/')
    }

    onShowReports = (formik_props) => {
        this.setState({show_reports_modal: true,
                       report_filter: formik_props.values})
    }

    onHideReports = () => {
        this.setState({show_reports_modal: false})
    }
    
    onCreateCreditsReport = (formik_props) => {
        const { dispatch, history } = this.props
        const { report_filter } = this.state
        dispatch(adminCustomerReportList.createCreditsReport({filter:report_filter}))
        this.onHideReports()
        showSuccess("Generating", "Report generation in progress")
        history.push("/admin/generated_reports")
    }

    filterCustomer = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminCustomerList.updateListFilter(filter_values))
    }

    sortOnHeader = (key) => {
        const { dispatch, customer_filter } = this.props
        if (key !== 'action' && key !== 'admin_email') {
            let sort_by = key
            if (has(customer_filter, 'order_by') && customer_filter['order_by'] === key) {
                sort_by = '-' + key
            }
            dispatch(adminCustomerList.updateListFilter({'order_by': sort_by}))
        }
    }
    
    getCustomerCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'created_at':
                return (
                    <Timestamp value={item.created_at} format='date' />
                )
            case 'display_name':
                return <span>{item.user.display_name}</span>
            case 'phone_number':
                return <span>{get(item, ["billing_address", "preferred_contact_telephone"], "-")}</span>
            case 'email':
                return <span>{item.user.email}</span>
            case 'credit':
                return <CurrencyValue value={item.credits_excluding_vat_euros} />
            case 'payment_timing_method':
                return <span>?</span>
            case 'is_active':
                if ( item.user.is_active ) {
                    return <InlineIcon icon_name="tick" variant="green" />
                } else {
                    return <InlineIcon icon_name="cross" variant="red" />
                }
            case 'action':
                return (
                    <InlineIconBar>
                      <InlineIcon icon_name="edit" onClick={(evt) => this.onEdit(evt, item)} />
                    </InlineIconBar>
                )
            default:
                return undefined
        }
    }

    renderReportsModal = () => {
        const that = this
        const { report_filter } = this.state
        return (
            <Modal show={true}
                   onHide={that.onHideReports} >
              <Modal.Header closeButton>
                <Modal.Title>
                  Customer Reports
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>

                <CardInfoText>
                  These reports only include customers based on the currently active filter. If there is no filter then all customers are included.
                  <br/><br/>
                  The active filter is:

                  <pre>
                    {JSON.stringify(report_filter)}
                  </pre>
                </CardInfoText>

                <BlueButton onClick={ that.onCreateCreditsReport } auto_disable={false}>
                  Credits report
                </BlueButton>
                
              </Modal.Body>
            </Modal>
        )
    }

    renderFilter = (formik_props) => {
        const { reseller_options } = this.props
        return (
            <AdminTableFilterPopup popup_fields={(
                <Row>
                  
                  <Col md="12">

                    <Row>
                      <Col md="5">
                        <FormikDatePicker name="created_after" placeholder="Created after" can_clear={true} formik_props={formik_props} />
                      </Col>
                      <Col md="5">
                        <FormikDatePicker name="created_before" placeholder="Created before" can_clear={true} formik_props={formik_props} />
                      </Col>
                    </Row>
                    <Separator variant="h5" />
                    <Row>
                      <Col>
                        <Tristate name="is_active"
                                  placeholder="Is active"
                                  label_overrides={{true: 'Active', false: 'Cancelled'}}
                                  formik_props={formik_props}/>
                      </Col>
                    </Row>
                    <Separator variant="h5" />
                    <Row>
                      <Col>
                        <Tristate name="has_any_active_subscriptions"
                                  placeholder="Subscriptions"
                                  label_prefix="Has active subs:"
                                  tooltip={"Filters on customers having subscriptions which are active or in the process of aquiring a phone number"}
                                  formik_props={formik_props}/>
                      </Col>
                    </Row>
                    <Separator variant="h5" />
                    <Row>
                      <Col>
                        
                        <FormikDropdownField name="reseller"
                                             options={reseller_options}
                                             placeholder="Reseller"
                                             extra_css={reseller_filter_style}
                                             formik_props={formik_props} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
            )}
                                   fixed_fields={(
                                       <Row>
                                         <Col>
                                           <FormikInputField name="any_field"
                                                             placeholder="Search"
                                                             show_search_icon={true}
                                           />
                                         </Col>
                                         <Col>
                                           <WhiteButton onClick={ this.onAdd }>
                                             Add Customer
                                           </WhiteButton>
                                         </Col>
                                         <Col>
                                           <WhiteButton onClick={ () => this.onShowReports(formik_props) } auto_disable={false} >
                                             Reports
                                           </WhiteButton>
                                         </Col>
                                       </Row>
                                   )}
            />
        )
    }

    render() {
        const {
            customer_headers,
            customers,
            is_loading,
            customer_filter,
        } = this.props
        const { show_reports_modal } = this.state
        
        return (
            <AdminMainLayout active_key="customers"
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'customers', label: 'Customers', url: '/admin/customers'}]}>
              <AdminTableHeader title="Customers">
                <div>
                  <AdminTableFilter renderFilter={this.renderFilter}
                                    updateOnChange={ this.filterCustomer }
                                    form="ADMIN_CUSTOMER_TABLE_FILTER_FORM"
                  />
                </div>
              </AdminTableHeader>
              <Container fluid>
                <CommonTable is_loading={ is_loading }
                             empty_message="There are no customers."
                             headers={ customer_headers }
                             items={ customers }
                             item_list={adminCustomerList}
                             getCellValue={ this.getCustomerCellValue }
                             sortOnHeader={ this.sortOnHeader }
                />
                { show_reports_modal && this.renderReportsModal() }
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const customers = adminCustomerList.getVisibleObjects() || []
    return {
        customers,
        customer_filter: adminCustomerList.getFilter(),
        customer_headers: {
            short_id: { name: "Acc Id" },
            display_name: { name: "Name" },
            email: { name: 'Email' },
            phone_number: { name: 'Phone' },
            created_at: { name: 'Since' },
            credit: { name: 'Credit' },
            payment_timing_method: { name: 'Post/Pre' },
            reseller_name: { name: 'Reseller' },
            is_active: {name: 'Active' },
            action: { name: '' },
        },
        is_loading: adminCustomerList.isLoading(),
        reseller_options: adminResellerList.getAsOptions({empty_option_label:'All resellers'})
    }
}
export default withRouter(connect(mapStateToProps)(AdminCustomers))

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const reseller_filter_style = css`
width: 400px;
`

