/** @jsxImportSource @emotion/react */
import React,{ useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { jsx, css } from '@emotion/react'
import { Trans, Translation } from 'react-i18next'
import { Separator } from './layout/Separator'
import { Row, Col, Container } from 'react-bootstrap'
import { head, filter, size, map } from 'lodash'
import { default_theme as theme } from '../emotion/theme'
import { upgradePathList } from '../actions/upgrade_path'
import { UpgradePathNotificationUpsellFreeFaxSendingSub } from './UpgradePathNotificationUpsellFreeFaxSendingSub'
import { UpgradePathNotificationFreeToPaidUpsellVoice } from './UpgradePathNotificationFreeToPaidUpsellVoice'
import { UpgradePathNotificationTrialSubscription } from './UpgradePathNotificationTrialSubscription'
import { UpgradePathNotificationFreeToPaidUpsellBasedOnProduct } from './UpgradePathNotificationFreeToPaidUpsellBasedOnProduct'

export const UpgradePathNotifications = ({display_style, show_even_if_dismissed, header, footer, render_if_empty}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const upgrade_paths = useSelector(() => upgradePathList.getVisibleObjects())
    const is_saving = useSelector(() => upgradePathList.getIsSavingObject())
    const is_loading = useSelector(() => upgradePathList.isLoading())

    const upgradePathsNotificationMinimumOutgoingFaxCostEuros1Sept2021 = filter(upgrade_paths, (up) => up.upgrade_type === 'minimum_outgoing_fax_cost_euros_1Sept2021')
    const upgradePathsNotificationUpsellFreeFaxSendingSub = filter(upgrade_paths, (up) => up.upgrade_type === 'upsell_min_sending_cost_fax_sending_subscriptions_to_paid_for')
    const upgradePathsNotificationFreeToPaidUpsellVoice = filter(upgrade_paths, (up) => up.upgrade_type === 'free_to_paid_upsell_voice')
    const upgradePathsNotificationTrialSubscription = filter(upgrade_paths, (up) => up.upgrade_type === 'trial_subscription')
    const upgradePathsNotificationFreeToPaidUpsellBasedOnProduct = filter(upgrade_paths, (up) => up.upgrade_type === 'free_to_paid_based_on_product')
    
    
    useEffect(() => {
        if (! show_even_if_dismissed) {
            dispatch(upgradePathList.updateListFilter({dismissed: false}))
        } else {
            dispatch(upgradePathList.updateListFilter({dismissed: null}))
        }
        dispatch(upgradePathList.fetchListIfNeeded())
        
    }, [])

    const onHideNotification = (upgrade_path) => {
        const on_ok = (json) => {
            dispatch(upgradePathList.invalidateList())
            dispatch(upgradePathList.fetchListIfNeeded())
        }
        upgrade_path.dismissed = true
        return dispatch(upgradePathList.saveObject(upgrade_path)).then(on_ok)
    }

    const onDidRender = (upgrade_path) => {

        if ( Boolean(upgrade_path.seen_by_customer_at) ) {
            return
        }
        
        const on_ok = (json) => {
        }
        
        upgrade_path.seen_by_customer_at = moment().format()
        dispatch(upgradePathList.saveObject(upgrade_path)).then(on_ok)
    }
    
    if ( is_loading || size(upgrade_paths) === 0 ) {
        return (render_if_empty && render_if_empty()) || null
    }

    return (
        <div>
          {header || null}

          {size(upgradePathsNotificationFreeToPaidUpsellVoice) > 0 &&
           <UpgradePathNotificationFreeToPaidUpsellVoice upgrade_path={head(upgradePathsNotificationFreeToPaidUpsellVoice)}
                                                         display_style={display_style}
                                                         onHideNotification={onHideNotification}
                                                         onDidRender={() => onDidRender(head(upgradePathsNotificationFreeToPaidUpsellVoice))}/>
          }
          
          {map(upgradePathsNotificationUpsellFreeFaxSendingSub, (upgrade_path) => 
              <UpgradePathNotificationUpsellFreeFaxSendingSub upgrade_path={upgrade_path}
                                                              display_style={display_style}
                                                              onHideNotification={onHideNotification}
                                                              onDidRender={() => onDidRender(upgrade_path)}/>
          )
          }

          {size(upgradePathsNotificationTrialSubscription) > 0 &&
           <UpgradePathNotificationTrialSubscription upgrade_path={head(upgradePathsNotificationTrialSubscription)}
                                                     display_style={display_style}
                                                     onHideNotification={onHideNotification}
                                                     onDidRender={() => onDidRender(head(upgradePathsNotificationTrialSubscription))}/>
          }

          {size(upgradePathsNotificationFreeToPaidUpsellBasedOnProduct) > 0 &&
           <UpgradePathNotificationFreeToPaidUpsellBasedOnProduct upgrade_path={head(upgradePathsNotificationFreeToPaidUpsellBasedOnProduct)}
                                                                  display_style={display_style}
                                                                  onHideNotification={onHideNotification}
                                                                  onDidRender={() => onDidRender(head(upgradePathsNotificationFreeToPaidUpsellBasedOnProduct))}/>           
          }
          
          {footer || null}
        </div>
    )
    
}

const container_style = css`
background-color: ${theme.colors.dark_green};
width: 100%;
margin-bottom: 30px;
padding: 20px 20px 18px;
border-radius: 10px;
color: ${theme.colors.white};
`

const header_style = css`
font-size: 20px;
font-weight: bold;
`

const inline_header_row_style = css`
`

const inline_header_style = css`
font-weight: bold;
`
