/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { head, get } from 'lodash'
import { Link } from 'react-router-dom'
import { adminFaxItemList } from '../actions/admin_fax_item'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import { adminResellerList } from '../actions/admin_reseller'
import { FaxItemDetailsModal } from '../../components/FaxItemDetailsModal'
import { AdminQuickLink } from './AdminQuickLink'
import BusyMask from '../../components/BusyMask'
import CurrencyValue from '../../components/CurrencyValue'
import { PhoneNumber  } from '../../components/PhoneNumber'
import Timestamp from '../../components/Timestamp'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { LanguageFlag } from '../../components/LanguageFlag'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFilter from './AdminTableFilter'
import { AdminCdrDetailsModal } from './AdminCdrDetailsModal'
import { AdminTableFilterPopup } from './AdminTableFilterPopup'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { FormikDatePicker } from '../../components/form/DatetimePicker'
import { Separator } from '../../components/layout/Separator'
import { Container, Button, Col, Row } from 'react-bootstrap'
import { FormikInputField } from '../../components/form/InputField'
import { cdrTypes } from '../../actions/dropdown_options'
import { VoiceDetailsModal } from '../../components/VoiceDetailsModal'

class AdminCommonCdrs extends Component {

    constructor(props) {
        super(props)
        this.state = {showing_cdr_item_details: null,
                      show_fax_details: null,
                      show_voice_message: null}
    }
    
    componentDidMount() {
        const { dispatch, itemList } = this.props
        dispatch(itemList.fetchListIfNeeded())
        dispatch(cdrTypes.updatePaginationNumItemsPerPage(1000))
        dispatch(cdrTypes.fetchListIfNeeded())
        dispatch(adminResellerList.updatePaginationNumItemsPerPage(1000))
        dispatch(adminResellerList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch, itemList } = this.props
        dispatch(itemList.fetchListIfNeeded())
    }

    onShowItemDetails = (item) => {
        const { dispatch, itemList } = this.props
        dispatch(itemList.invalidateObject(item.id))
        dispatch(itemList.ensureObjectLoaded(item.id))
        this.setState({showing_cdr_item_details: item})
    }

    onHideItemDetails = () => {
        this.setState({showing_cdr_item_details: null})
    }

    onShowFaxDetails = (fax_id) => {
        this.setState({show_fax_details: fax_id})
    }

    onHideFaxDetails = () => {
        this.setState({show_fax_details: null})
    }

    onShowVoiceMessageItemDetails = (cdr_id) => {
        this.setState({show_voice_message: cdr_id})
    }

    onHideVoiceMessageItemDetails = () => {
        this.setState({show_voice_message: null})
    }
    
    onDownload = () => {
        const { filter, itemList } = this.props
        itemList.download(filter)
    }
    
    getCellValue = (header_key, item, index) => {
        const { cdr_types } = this.props
        const that = this
        switch( header_key ) {
            case 'cdr_type':
                return (
                    <div css={cdr_type_row}>
                      {get(cdr_types, [item.cdr_type, "name"], item.cdr_type)}
                      { item.incoming_fax_item &&
                        <>
                          <Separator variant="w10"/>
                          <InlineIcon icon_name="fax" size_variant="h16" variant="blue" onClick={() => that.onShowFaxDetails(item.incoming_fax_item)} />
                        </>
                      }
                      { item.cdr_type === "to_hylafax" &&
                        <>
                          <Separator variant="w10"/>
                          <InlineIcon icon_name="fax" size_variant="h16" variant="blue" onClick={() => that.onShowItemDetails(item)} />
                        </>
                      }
                      { item.incoming_voice_message &&
                        <>
                          <Separator variant="w10"/>
                          <InlineIcon icon_name="voice_message" size_variant="h16" variant="blue" onClick={() => that.onShowVoiceMessageItemDetails(item.id)} />
                        </>
                      }
                    </div>
                )
            case 'source_number':
                return <PhoneNumber phone_number={item.source_number} />
            case 'destination_number':
                return <PhoneNumber phone_number={item.destination_number} />
            case "customer_email":
                return <AdminQuickLink name="customer" value={item.customer} label={item.customer_email} />
            case 'final_number':
                return <PhoneNumber phone_number={item.final_number} />
            case 'start_datetime':
                return <Timestamp value={item.start_datetime} format="datetime" />
            case 'end_datetime':
                return <Timestamp value={item.end_datetime} format="datetime" />
            case 'total_cost_excluding_vat_euros':
                return <CurrencyValue value={item.total_cost_excluding_vat_euros} />
            case 'action':
                return (
                    <InlineIcon icon_name="info" size_variant="h16" variant="blue" onClick={() => that.onShowItemDetails(item)} />
                )
                
            default:
                return undefined
        }
    }

    filterCdrs = (filter_values) => {
        const { dispatch, itemList } = this.props
        dispatch(itemList.updateListFilter(filter_values))
    }

    renderFilter = (formik_props) => {
        const { reseller_options, cdr_type_options } = this.props
        const that = this
        return (
            <AdminTableFilterPopup popup_fields={(
                <Row>
                  
                  <Col md="12">
                    <Row>
                      <Col md="5">
                        <FormikDatePicker name="start_datetime_after" placeholder="Start after" can_clear={true} formik_props={formik_props} />
                      </Col>
                      <Col md="5">
                        <FormikDatePicker name="start_datetime_before" placeholder="Start before" can_clear={true} formik_props={formik_props} />
                      </Col>
                    </Row>
                    <Separator variant="h20" />

                    <FormikDropdownField name="cdr_type"
                                         formik_props={formik_props}
                                         options={cdr_type_options}
                                         empty_selection_label="Any type"
                                         placeholder="Cdr Type" />
                    
                    <Separator variant="h10" />

                    <Row>
                      <Col>
                        <FormikDropdownField name="reseller"
                                             options={reseller_options}
                                             placeholder="Reseller"
                                             extra_css={reseller_filter_style}
                                             formik_props={formik_props} />
                      </Col>
                    </Row>

                    <Separator variant="h5" />
                    
                    <Separator variant="w5" />
                    <WhiteButton onClick={this.onDownload} auto_disable={false}>
                      <div css={download_button_style}>
                        <Separator variant="w5" />
                        Excel
                      </div>
                    </WhiteButton>
                  </Col>
                  
                </Row>
            )}
                                   fixed_fields={(
                                       <Row>
                                         <Col>
                                           <FormikInputField name="any_field"
                                                             placeholder="Search"
                                                             show_search_icon={true}
                                           />
                                         </Col>
                                       </Row>
                                   )}
            />
        )
    }

    render() {
        const { is_loading, headers, cdrs, itemList } = this.props
        const { showing_cdr_item_details, show_fax_details, show_voice_message } = this.state

        return (
            <>
              <AdminTableHeader title="Cdrs">
                <AdminTableFilter renderFilter={this.renderFilter}
                                  updateOnChange={ this.filterCdrs }
                                  form="ADMIN_CDRS_FILTER_FORM"
                />
              </AdminTableHeader>
              <CommonTable
                is_loading={ is_loading }
                empty_message={`There are no cdrs.`}
                headers={ headers }
                items={ cdrs }
                item_list={itemList}
                renderCell={ this.renderCell }
                getCellValue={ this.getCellValue }
                sortOnHeader={ this.sortOnHeader }
              />

              { showing_cdr_item_details &&
                <AdminCdrDetailsModal cdrList={itemList}
                                      cdr_id={showing_cdr_item_details.id}
                                      onClose={this.onHideItemDetails} />
              }

              { show_fax_details && <FaxItemDetailsModal fax_item_id={show_fax_details}
                                                         faxItemList={adminFaxItemList}
                                                         onClose={this.onHideFaxDetails} />
              }
              { show_voice_message && 
                <VoiceDetailsModal cdrList={itemList}
                                   cdr_id={show_voice_message}
                                   onClose={this.onHideVoiceMessageItemDetails} />
              }
            </>
        )
    }
}

function mapStateToProps(state, props) {
    const { itemList } = props
    const cdrs = itemList.getVisibleObjects()
    const cdr_types = cdrTypes.getObjectsById()
    return {
        cdrs,
        cdr_types,
        is_loading: itemList.isLoading(),
        filter: itemList.getFilter(),
        headers: {
            short_id: { name: 'Id'},
            cdr_type: { name: 'Type'},
            start_datetime: { name: 'Start' },
            // end_datetime: { name: 'End' },
            total_cost_excluding_vat_euros: { name: 'Cost (euros, ex VAT)'},
            customer_email: { name: 'Customer email'},
            customer_name: { name: 'Customer name'},
            source_number: { name: 'From number'},
            destination_number: { name: 'To number'},
            final_number: { name: 'Forwarded to'},
            duration_seconds: { name: 'Duration (s)' },
            server_name: { name: 'Server'},
            action: { name: '' },
            itemList
        },
        reseller_options: adminResellerList.getAsOptions({empty_option_label:'All resellers'}),        
        cdr_type_options: cdrTypes.getAsOptions()
    }
}

export default connect(mapStateToProps)(AdminCommonCdrs)

const download_button_style = css`
display: flex;
justify-content: space-between;
`

const cdr_type_row = css`
display: flex;
`

const reseller_filter_style = css`
width: 400px;
`

