/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { Button } from 'react-bootstrap'

export const InputWithButton = ({ input, button, extra_css, ...props }) => {

    return (
        <div css={[container, extra_css]}>
          {input}
          <div css={button_style}>
            {button}
          </div>
        </div>
    )

}

const container = css`
display: flex;

`

const button_style = css`
margin-left: 2px;
`
