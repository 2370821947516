/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../emotion/theme'
import MainLayout from './MainLayout'
import { Container, Row, Col, Button, ButtonGroup } from 'react-bootstrap'
import CommonTable from './CommonTable'
import Card from './layout/Card'
import { map, head, get } from 'lodash'
import { InlineIconBar } from './layout/InlineIconBar'
import { InlineIcon } from './layout/InlineIcon'
import { BlueLinkButton } from './layout/BlueLinkButton'
import * as Yup from 'yup'
import {showSuccess, showError} from '../actions/Error'
import { confirmModal } from '../actions/ui'
import Loading from './Loading'
import CommonFaxFromEmailAddressForm from './CommonFaxFromEmailAddressForm'
import { faxFromEmailAddressVerify, faxFromEmailAddressList } from '../actions/fax_from_email_address'
import FieldInfoText from './layout/FieldInfoText'
import { Separator } from './layout/Separator'

const validationSchema = Yup.object().shape({
})

class CommonFaxFromEmailAddresses extends Component {

    constructor(props) {
        super(props)
        this.state = { record_being_edited: null,
                       is_record_being_added: false}
    }
    
    componentDidMount() {
        const { dispatch, faxFromEmailAddressList, customer_id } = this.props
        if ( customer_id ) {
            dispatch(faxFromEmailAddressList.updateListFilter({customer: customer_id}))
        }
        dispatch(faxFromEmailAddressList.fetchListIfNeeded())
        this.checkVerification()
    }

    componentDidUpdate(prev_props) {
        const { dispatch, faxFromEmailAddressList } = this.props
        dispatch(faxFromEmailAddressList.fetchListIfNeeded())
    }

    checkVerification() {
        const { dispatch } = this.props
        const query_from_URL_parameters = new URLSearchParams(window.location.search)
        const verification_token = query_from_URL_parameters.get('verification_token', null)
        const verification_id = query_from_URL_parameters.get('verification_id', null)
        
        if ( verification_token && verification_id ) {
            dispatch(faxFromEmailAddressVerify.verify({verification_id: verification_id, verification_token: verification_token})).then( (json) => {
                if ( json.status === 'success' ) {
                    dispatch(faxFromEmailAddressList.invalidateObject(verification_id))
                    dispatch(faxFromEmailAddressList.ensureObjectLoaded(verification_id))
                    showSuccess(<Trans>Success</Trans>, <Trans>Email address validated</Trans>)
                } else {
                    showError(<Trans>Failure</Trans>, <Trans>Email address not validated</Trans>)
                }
            })
        }
    }

    startEditingRecord = (number) => {
        this.setState({record_being_edited: number, is_record_being_added: false})
    }

    cancelEditingRecord = () => {
        this.cancelAddingRecord()
    }
    
    startAddingRecord = () => {
        const { dispatch } = this.props
        const that = this
        this.setState({is_record_being_added:true})
    }

    cancelAddingRecord = () => {
        this.setState({is_record_being_added: false, record_being_edited: null})
    }

    onDeleteRecord = (address) => {
        const { dispatch, faxFromEmailAddressList, customer_id } = this.props
        function onConfirmed() {
            dispatch(faxFromEmailAddressList.deleteObject(address.id)).then(() => {
                dispatch(faxFromEmailAddressList.invalidateList())
            })
        }
        dispatch(confirmModal({text:`Are you sure you want to delete ${address.email_address} ?`,
                               onConfirmed: onConfirmed}))
    }

    renderApproveDecline(address) {
        return (
            <div>
            </div>
        )
    }

    renderAddress(address, index) {
        const { record_being_edited, is_record_being_added } = this.state
        const is_editing = (is_record_being_added === true && index === -1) || (record_being_edited && record_being_edited.id === address.id)
        return (
            <div key={index}>
              { is_editing && this.renderAddressForm(address) }
              { ! is_editing && (
                    <Container fluid>
                      <Row css={address_row}>
                        <Col md="10">
                          <Row>
                            <Col css={address_row_header}>
                              {address.email_address}

                              { address.is_verified !== true &&
                                (
                                    <div css={address_row_pending_approval}>
                                      <Trans>pending verification</Trans>
                                      <div>
                                        <Trans>
                                          Please check your email inbox for a verification link.
                                        </Trans>
                                      </div>
                                    </div>
                                )
                              }
                              
                              { address.is_verified && address.is_approved == null &&
                                (
                                    <div css={address_row_pending_approval}>
                                      - <Trans>pending approval</Trans>
                                    </div>
                                )
                              }
                              { address.is_verified && address.is_approved == false &&
                                (
                                    <div css={address_row_rejected}>
                                      - <Trans>rejected</Trans>
                                      ({address.rejection_reason || <Trans>please contact support for clarification</Trans>})
                                    </div>
                                )
                              }
                            </Col>
                          </Row>
                          <Row>
                            <Col css={address_row_content}>
                              <Trans>Fax header</Trans>: {address.fax_header}
                            </Col>
                          </Row>
                        </Col>
                        <Col css={address_actions}>
                          <InlineIconBar>
                            <InlineIcon icon_name="edit" onClick={() => this.startEditingRecord(address)} />
                            <InlineIcon icon_name="remove" onClick={() => this.onDeleteRecord(address)} />
                          </InlineIconBar>
                        </Col>
                      </Row>
                    </Container>
              )}
            </div>
        )
    }

    renderAddressForm(address, index) {
        const { faxFromEmailAddressList, customer_id, supplementary_form_render } = this.props
        return (
            <Container fluid>
              <Row css={address_row}>
                <Col md="10">
                  <Row>
                    <CommonFaxFromEmailAddressForm key={index}
                                                   customer_id={customer_id}
                                                   address={address}
                                                   faxFromEmailAddressList={faxFromEmailAddressList}
                                                   onCancel={this.cancelEditingRecord} />
                  </Row>
                  { supplementary_form_render &&
                    <Row>
                      <Col>
                        { supplementary_form_render(address) }
                      </Col>
                    </Row>
                  }
                  
                </Col>
              </Row>
            </Container>
        )
    }

    render() {
        const { addresses, is_loading } = this.props
        const { is_record_being_added, record_being_edited } = this.state

        return (
            <div>
              { is_loading && <Loading /> }
              { map(addresses, (address, index) => this.renderAddress(address, index)) }

              { is_record_being_added && this.renderAddressForm(null) }

              <FieldInfoText>
                <Trans>You can allow any email address from an entire domain by using a wildcard, like this</Trans>:
                '*@mydomain.com'.
                <br/>
                <Trans>Valid domains must first be approved by XOIP</Trans>.
              </FieldInfoText>
              <Separator variant="h10" />
              
              { record_being_edited === null && is_record_being_added === false &&
                <BlueLinkButton onClick={this.startAddingRecord}>
                  <InlineIcon icon_name="add_no_circle" variant="blue" />
                  <Trans>Add a valid email address</Trans>
                </BlueLinkButton>
              }
            </div>
        )
    }
    
}

function mapStateToProps(state, props) {

    const { faxFromEmailAddressList, customer_id, can_approve_decline, supplementary_form_render } = props
    const addresses = faxFromEmailAddressList.getVisibleObjects()

    return {
        customer_id, // optional param
        faxFromEmailAddressList,
        addresses,
        is_loading: faxFromEmailAddressList.isLoading(),
        can_approve_decline,
        supplementary_form_render
    }
}

export default connect(mapStateToProps)(CommonFaxFromEmailAddresses)

const address_row = css`
background-color: #efeff4;
padding-top: 10px;
padding-bottom: 10px;
border-radius: 4px;
margin-bottom: 10px;
position: relative;
`

const address_row_header = css(theme.mq({
    fontSize: "16px",
    lineHeight: "18px",
    fontWeight: "normal",
    display: ["flex", "block"]
}))

const address_row_content = css`
font-size: 16px;
line-height: 18px;
color: #727272;
margin-top: 5px;
`

const address_row_pending_approval = css`
color: ${theme.colors.warning_red};
margin-left: 5px;
`

const address_row_rejected = css`
color: ${theme.colors.warning_red};
margin-left: 5px;
`

const address_actions = css(theme.mq({
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    position: "absolute",
    right: ["10px", "0px"],
    top: ["22px", "10px"]
}))

const address_action  = css`
margin-left: 10px;
color: #b8b8bd;
cursor: pointer;
`
