/** @jsxImportSource @emotion/react */
import React, {useState} from 'react'
import { Row, Col, Container, Card } from 'react-bootstrap'
import { default_theme as theme } from '../emotion/theme'
import { jsx, css } from '@emotion/react'
import { useDispatch } from 'react-redux'
import WrappingBusyMask from './WrappingBusyMask'
import CurrencyValue from './CurrencyValue'
import { Separator } from './layout/Separator'
import { Trans, Translation } from 'react-i18next'
import { BlueButton } from './layout/BlueButton'
import { molliePaymentList } from '../actions/mollie_payment'
import { handleSubmitResult } from '../actions/form'

export const CreditTopupCard = ({topup, purchaseLabel, selectedLabel, onSelected, isSelected, style, selectedStyle, renderButton}) => {

    const [navigatingToMollie, setNavigatingToMollie] = useState(false)
    const is_saving = molliePaymentList.getIsSavingObject()
    const dispatch = useDispatch()
    
    const onMakePayment = () => {
        if ( onSelected ) {
            onSelected(topup)
        } else {
            setNavigatingToMollie(true)
            const on_ok = function(mollie_record) {
                if ( mollie_record.checkout_url ) {
                    molliePaymentList.rememberPendingMolliePaymentId(mollie_record.id)
                    window.location = mollie_record.checkout_url
                }
            }
            dispatch(molliePaymentList.startBuyTopup(topup)).then((res) => handleSubmitResult({res, on_ok}))
        }
    }
    
    return (
        <Card css={[mini_card_style, style, isSelected && selectedStyle]} onClick={() => onSelected && onSelected(topup)}>
          <WrappingBusyMask>
            <div css={amount_style}>
              <CurrencyValue value={topup.amount_excluding_vat_euros} />
            </div>

            { Boolean(topup.vat_percentage) &&
              <div css={vat_amount_style}>
                <CurrencyValue value={topup.amount_including_vat_euros} />&nbsp;
                <Trans>inc.</Trans>&nbsp;{topup.vat_percentage}%&nbsp;<Trans>VAT</Trans>
              </div>
            }
            
            <Separator variant="h20" />

            <div css={buy_button_style}>
              { renderButton && renderButton(isSelected) }
              { ! renderButton && 
                <BlueButton onClick={() => onMakePayment()} disabled={navigatingToMollie}>
                  { isSelected && <Trans>{selectedLabel || "Selected"}</Trans> }
                  { !isSelected && <Trans>{purchaseLabel || "Purchase"}</Trans> }
                </BlueButton>
              }
            </div>
            
          </WrappingBusyMask>
          
        </Card>
    )
    
}

const mini_card_style = css`
width: 220px;
box-shadow: 0 2px 7px 0 rgba(0,0,0,0.2);
background-color: #fff;
padding-left: 15px;
padding-right: 15px;
margin-right: 30px;
position: relative;
margin-bottom: 30px;
`


const amount_style = css`
margin-top: 40px;
font-size: 24px;
line-height: 18px;
text-align: center;
`

const vat_amount_style = css`
font-size: 14px;
color: ${theme.colors.dark_grey};
margin-top: 11px;
display: flex;
justify-content: center;
`

const full_description_style = css`
font-size: 18px;
margin-top: 24px;
margin-bottom: 22px;
text-align: center;
`

const buy_button_style = css`
text-align: center;
margin: auto;
margin-bottom: 44px;
`


