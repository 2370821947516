/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { isEmpty } from 'lodash'
import { Formik, Form, FieldArray, Field } from 'formik'
import { adminUserList } from '../actions/admin_user'
import { Button } from 'react-bootstrap'
import { FormikInputField } from '../../components/form/InputField'
import FormToggleButton from '../../components/FormToggleButton'
import BusyMask from '../../components/BusyMask'
import * as Yup from 'yup'
import { default_theme as theme } from '../../emotion/theme'
import { yupPhoneNumberValidator } from '../../components/form/Validators'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'

const validationSchema = Yup.object().shape({
    'first_name': Yup.string().required("First name is required"),
    'last_name': Yup.string().required("Last name is required"),
    'email': Yup.string().required("Email is required"),
})

class AdminUserForm extends Component {
   
    componentDidMount() {
        const { dispatch, user } = this.props
        if (user) {
            dispatch(adminUserList.ensureObjectLoaded(user.id))
        }
    }

    render() {
        const { onSave, initial_values, onCancelUser, error, is_busy } = this.props

        return (
            <Formik
                initialValues={initial_values}
                onSubmit={onSave}
                enableReinitialize={true}
                validationSchema={validationSchema}
            >
              {formik_props => {
                   const { values } = formik_props
                   return (
                       <Form>
                         <div css={ form_layout }>
                           { is_busy && <BusyMask /> }
                           <FormikGeneralFormErrors />
                           <div className="form-group" css={ field_container }>
                             <label css={ label }>Name</label>
                             <div>
                               <FormikInputField
                                   name="first_name"
                                   type="text"
                                   placeholder="First name"
                               />
                             </div>
                           </div>
                           <div className="form-group" css={ field_container }>
                             <label css={ label }>Surname</label>
                             <div>
                               <FormikInputField
                                   name="last_name"
                                   type="text"
                                   placeholder="Last name"
                               />
                             </div>
                           </div>
                           <div className="form-group" css={ field_container }>
                             <label css={ label }>Phone number</label>
                             <div>
                               <FormikInputField
                                   name="phone_number"
                                   type="text"
                                   placeholder="Phone number"
                               />
                             </div>
                           </div>
                           <div className="form-group" css={ field_container }>
                             <label css={ label }>Email Address</label>
                             <div>
                               <FormikInputField
                                   name="email"
                                   type="text"
                                   placeholder="Email"
                               />
                             </div>
                           </div>
                           <div css={ footer }>
                             <div>
                               <Button variant="danger" onClick={ onCancelUser }>
                                 Cancel
                               </Button>
                             </div>
                             <div>
                               <Button type="submit">Save</Button>
                             </div>
                           </div>
                         </div>
                       </Form>
                   )
              }}
            </Formik>
        )
    }
}

function mapStateToProps(state, props) {
    const { onSave, user_id, onCancelUser } = props
    const user = adminUserList.getObject(user_id) || {}
    const initial_values = user
    return {
        onSave,
        onCancelUser,
        user,
        initial_values,
        is_busy: adminUserList.isLoading()
    }
}
export default withRouter(connect(mapStateToProps)(AdminUserForm))

const form_layout = css`
width: 90%;
display: flex;
flex-direction: column;
align-items: center;
padding-top: 20px;
`

const field_container = css`
width: 100%;
display: flex;
flex-direction: column;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 30px;
flex-direction: column;
padding-top: 20px;

p {
margin: 0;
padding: 0;
color: ${theme.colors.error};
}
`

const footer = css`
display: flex;
flex-direction: row;
text-align: center;
justify-content: space-between;
width: 100%;
height: 100px;
padding-top:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`
