/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { get } from 'lodash'
import Card from '../../components/layout/Card'
import { CardHeader } from '../../components/layout/CardHeader'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { Separator } from '../../components/layout/Separator'
import { Button, ButtonGroup, Row, Col } from 'react-bootstrap'
import { BlueLinkButton } from '../../components/layout/BlueLinkButton'
import { BlueButton } from '../../components/layout/BlueButton'
import CurrencyValue from '../../components/CurrencyValue'
import { adminCustomerList } from '../actions/admin_customer'
import { Trans, Translation } from 'react-i18next'

class AdminCreditInformationCard extends Component {

    componentDidMount() {
        const { dispatch, customer_id } = this.props
        dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
    }
    
    buyMoreCredit = () => {
        const { history, customer_id } = this.props
        history.push(`/admin/customer/${customer_id}/create_transaction`)
    }

    onViewCustomerCreditHistory = () => {
        const { history, customer_id } = this.props
        history.push(`/admin/customer/${customer_id}/transactions`)
    }

    renderButton() {
        const { customer, is_loading } = this.props
        const credits_excluding_vat_euros = get(customer, "credits_excluding_vat_euros", null)
        const button_style = credits_excluding_vat_euros > 0 ? credit_button_available_style : credit_button_empty_style
        if ( is_loading ) {
            return null
        }
        return (
            <div css={credit_button_group}>
              { credits_excluding_vat_euros !== null && 
                <Button variant="success" css={button_style} onClick={this.buyMoreCredit}>
                  <div css={currency_button_content_style}>
                    <CurrencyValue value={credits_excluding_vat_euros} />
                  </div>
                </Button>
              }
              <Separator variant="w10" />
              { credits_excluding_vat_euros !== null && credits_excluding_vat_euros <= 0 && 
                <BlueButton onClick={this.buyMoreCredit} auto_disable={false}>
                  <Trans>Add Credit</Trans>
                </BlueButton>
              }
              { credits_excluding_vat_euros !== null && credits_excluding_vat_euros > 0 && 
                <WhiteButton onClick={this.buyMoreCredit} auto_disable={false}>
                  <Trans>Add Credit</Trans>
                </WhiteButton>
              }
            </div>
        )
    }
    
    // renderCreditButtons() {
    //     const { customer } = this.props
    //     return (
    //         <div>

    //           <CardHeader title="XOIP Credit"
    //                       left_child={null}
    //                       right_child={null}
    //                       sub_text="Credit is used for sending faxes and forwarding calls to external phone numbers."
    //           />

    //           <Card variant="white">
    //             <div css={credit_button_group}>
    //               <Button variant="success" css={credit_button_style} onClick={this.buyMoreCredit}>
    //                 <CurrencyValue value={get(customer, "credits_excluding_vat_euros", "-")} />
    //               </Button>
    //               <Separator variant="w10" />
    //             </div>

    //             <div>
    //               <Row>
    //                 <Col>
    //                   <BlueLinkButton onClick={this.onViewCustomerCreditHistory}>
    //                     Credit History
    //                   </BlueLinkButton>
    //                 </Col>
    //               </Row>
    //             </div>
    //           </Card>
    //         </div>
    //     )
    // }
    
    render() {
        return this.renderButton()
    }
    
}

function mapStateToProps(state, props) {
    const { customer_id } = props
    const customer = adminCustomerList.getObject(customer_id)
    return {
        customer_id,
        customer,
        is_loading: adminCustomerList.isLoading()
    }
}

export default withRouter(connect(mapStateToProps)(AdminCreditInformationCard))

const mini = css`
height: 36px;
align-items: center;
display: flex;
`

const mini_title = css`
font-size: 10px;
`

const mini_value = css`
font-size: 16px;
line-height: 1.13;
font-weight: 500;
`

const mini_credit_amount = css`
background-color: #28a745;
color: #fff;
padding-top:4px;
padding-bottom: 2px;
border-radius: 4px;
padding-left: 9px;
padding-right: 9px;
`

const mini_buy_credit_button = css`
cursor: pointer;
padding-left: 9px;
padding-right: 9px;

`

const mini_credit_amount_cell = css`
`

const mini_separator = css`
width: 4px;
`

const credit_button_group = css`
display: flex;
margin-bottom: 20px;
`

const credit_button_style = css`
background-color: #28a745;
color: #fff;
`

const buy_more_button_style = css`
background-color: #fff;
border: 1px solid #d1d1d6;
margin-left: 10px;

&:hover {
background-color: #fff;
}
`

const internal_collapsable = css`
margin-top: 5px;
`

const credit_button_available_style = css`
background-color: ${theme.colors.dark_green};
color: #fff;
border-radius: 20px;
`

const credit_button_empty_style = css`
background-color: ${theme.colors.primary_red};
color: #fff;
border-color: ${theme.colors.primary_red};
border-radius: 20px;

&:hover {
    border-color: ${theme.colors.primary_red};
    background-color: ${theme.colors.primary_red};
}

`

const currency_button_content_style = css`
text-align: center;
`
