import { ItemList } from '../../orm'
import { map } from 'lodash'

class AdminCallForwardingCostStructureVariantList extends ItemList {
    getEntityKey() {
        return "admin/call_forwarding_cost_structure_variant"
    }


}

export const adminCallForwardingCostStructureVariantList = new AdminCallForwardingCostStructureVariantList("admin_call_forwarding_cost_structure_variant")
