/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import AdminMainLayout from './AdminMainLayout'
import AdminFaxTraffic from './AdminFaxTraffic'
import { Container } from 'react-bootstrap'

class AdminFaxLogs extends Component {

    render() {

        const { fax_item_id } = this.props
        
        return (
            <AdminMainLayout active_key="logs"
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'fax_logs', label: 'Fax Logs', url: '/admin/fax_logs'}]}>
              <Container fluid>
                <AdminFaxTraffic />
              </Container>
            </AdminMainLayout>
            
        )
    }
    
}

export default AdminFaxLogs
