/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/react'

class ImagePreview extends Component {
    
    render() {
        const {imageFile} = this.props
        const {name, original_download_url} = imageFile
        // const preview = URL.createObjectURL(imageFile.original_download_url)
        return (
            <div css={image_container}>
              <img src={original_download_url} alt={name} css={image} />
            </div>
        )
    }
}
export default ImagePreview;

const image_container = css`
overflow: hidden;
`

const image = css`
max-width: 100%;
max-height: 100%;
`
