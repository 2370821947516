/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import AdminMainLayout from './AdminMainLayout'
import AdminInvoices from './AdminInvoices'
import { Container } from 'react-bootstrap'

class AdminInvoiceLogs extends Component {

    render() {
        return (
            <AdminMainLayout active_key="logs"
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'invoices', label: 'Invoices', url: '/admin/invoices'}]}>
              <Container fluid>
                <AdminInvoices />
              </Container>
            </AdminMainLayout>
            
        )
    }
    
}

export default AdminInvoiceLogs
