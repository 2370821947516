/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Trans, Translation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import MainLayout from './MainLayout'
import { Row, Col, Button, ButtonGroup } from 'react-bootstrap'
import { globalSettingsList } from '../actions/settings'
import CommonTable from './CommonTable'
import Card from './layout/Card'
import { customerPhoneBookNumberList } from '../actions/customer_phone_book_number'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikInputField } from './form/InputField'
import * as Yup from 'yup'
import {showSuccess, showError} from '../actions/Error'
import { confirmModal } from '../actions/ui'

const validationSchema = Yup.object().shape({
    'number': Yup.string().required("Number is required"),
    'email': Yup.string().email('Email must be valid').required("Email is required"),
    'name': Yup.string().required("Name is required")
})
const NUMBER_TYPE = 'fax'

class CustomerPhoneBookCard extends Component {

    constructor(props) {
        super(props)
        this.state = { number_being_edited: null,
                       is_number_being_added: false}
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(customerPhoneBookNumberList.updateListFilter({number_type:NUMBER_TYPE}))
        dispatch(customerPhoneBookNumberList.fetchListIfNeeded())
        dispatch(globalSettingsList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(customerPhoneBookNumberList.fetchListIfNeeded())
    }

    startEditingNumber = (number) => {
        this.setState({number_being_edited: number, is_number_being_added: false})
    }

    cancelEditingNumber = () => {
        this.cancelAddingNumber()
    }
    
    startAddingNumber = () => {
        this.setState({is_number_being_added: true, number_being_edited: null})
    }

    cancelAddingNumber = () => {
        this.setState({is_number_being_added: false, number_being_edited: null})
    }

    onDeleteNumber = (number) => {
        const { dispatch } = this.props
        function onConfirmed() {
            dispatch(customerPhoneBookNumberList.deleteObject(number.id)).then(() => {
                dispatch(customerPhoneBookNumberList.invalidateList())
            })
        }
        dispatch(confirmModal({text:`Are you sure you want to delete ${number.number} for ${number.name} ?`,
                               onConfirmed: onConfirmed}))
    }

    onSelectNumber = (number) => {
        const { onSelected } = this.props
        onSelected(number)
    }

    onSaveNumber = (values, setSubmitting, setFieldError) => {
        const { dispatch, customer_id, history } = this.props
        const { number_being_edited } = this.state
        const that = this
        const on_done = function(json) {
            dispatch(customerPhoneBookNumberList.invalidateList())
            that.cancelAddingNumber()
            showSuccess("Saved", "Number saved")
        }
        values.number_type = NUMBER_TYPE
        if ( number_being_edited ) {
            values.id = number_being_edited.id
            return dispatch(customerPhoneBookNumberList.saveObject(values, on_done))
        } else {
            return dispatch(customerPhoneBookNumberList.saveNewObject(values, on_done))
        }
    }

    getCellValue = (header_key, item, index, row_index) => {
        const { customer_id, onSelected } = this.props
        const { number_being_edited, is_number_being_added } = this.state
        const is_editing = (is_number_being_added === true && row_index === -1) || (number_being_edited && number_being_edited.id === item.id)

        switch( header_key ) {

            case 'number':
                if ( is_editing ) {
                    return (
                        <FormikInputField
                            name="number"
                            type="text"
                            placeholder="Number"
                        />
                    )
                } else {
                    return undefined
                }

            case 'name': 
                if ( is_editing ) {
                    return (
                        <FormikInputField
                            name="name"
                            type="text"
                            placeholder="Name"
                        />
                    )
                } else {
                    return undefined
                }

            case 'email': 
                if ( is_editing ) {
                    return (
                        <FormikInputField
                            name="email"
                            type="email"
                            placeholder="Email"
                        />
                    )
                } else {
                    return undefined
                }

            case 'action':
                if ( is_editing ) {
                    return (
                        <Button variant="outline_warning" onClick={this.cancelEditingNumber}>
                          Cancel
                        </Button>
                    )
                } else {
                    if ( ! is_editing ) {
                        return (
                            <ButtonGroup>
                              <Button variant="outline_secondary" onClick={() => this.startEditingNumber(item)}>
                                Edit
                              </Button>
                              <Button variant="outline_warning" onClick={() => this.onDeleteNumber(item)}>
                                Delete
                              </Button>
                              { onSelected && 
                                <Button variant="outline_warning" onClick={() => this.onSelectNumber(item)}>
                                  Select
                                </Button>
                              }
                            </ButtonGroup>
                        )
                    }

                }

            default:
                return undefined
        }
    }

    renderTitle() {
        const { customer_can_create_subscriptions } = this.props
        const { number_being_edited, is_number_being_added } = this.state
        return (
            <div>
              <div className="float-right">
                { customer_can_create_subscriptions && number_being_edited === null && is_number_being_added === false &&
                  <Button variant="light" onClick={this.startAddingNumber}>
                    <Trans>Add Number</Trans>
                  </Button>
                }
                { (number_being_edited !== null || is_number_being_added === true) &&
                  <ButtonGroup>
                    <Button variant="danger" onClick={this.cancelEditingNumber}>
                      <Trans>Cancel Edit</Trans>
                    </Button>
                    <Button type="submit" variant="primary">
                      <Trans>Save</Trans>
                    </Button>
                  </ButtonGroup>
                }
              </div>
            </div>
        )
    }

    render() {
        const { numbers, number_headers, is_loading } = this.props
        const { is_number_being_added, number_being_edited } = this.state
        let edit_row = null

        return (
            <div>
              Component not being used for now, you shouldn't be seeing this message.
            </div>
        )

        return (
            <Formik
                initialValues={(is_number_being_added && {}) || number_being_edited}
                onSubmit={(values, { setSubmitting, setFieldError }) => {this.onSaveNumber(values, setSubmitting, setFieldError)}}
                validationSchema={validationSchema}
                enableReinitialize={true}
            >
              {props => {
                   const { values } = props
                   return (
                       <Card title={this.renderTitle()}>

                         <Form>
                           <CommonTable is_loading={ is_loading }
                                        empty_message={'There are no numbers in your phone book'}
                                        headers={number_headers}
                                        add_row={(is_number_being_added && {}) || null}
                                        edit_row={number_being_edited}
                                        items={numbers}
                                        item_list={customerPhoneBookNumberList}
                                        getCellValue={ this.getCellValue }
                           />
                           { (is_number_being_added === true || number_being_edited !== null) && 
                             <Button type="submit">
                               Save
                             </Button>
                           }
                         </Form>
                       </Card>
                   )}
              }
            </Formik>
        )
    }
    
}

function mapStateToProps(state, props) {
    const numbers = customerPhoneBookNumberList.getVisibleObjects()
    const customer_can_create_subscriptions = globalSettingsList.getSetting("customer_can_create_subscriptions") === true

    return {
        numbers,
        is_loading: customerPhoneBookNumberList.isLoading(),
        number_headers: {
            number: { name: <Trans>Number</Trans>, column_size: 1 },
            name: { name: <Trans>Name</Trans>, column_size: 1 },
            email: { name: <Trans>Forward fax to</Trans>, column_size: 1 },
            action: { name: '', column_size: 1 }
        },
        customer_can_create_subscriptions
    }
}

export default withRouter(connect(mapStateToProps)(CustomerPhoneBookCard))
