import { ItemList } from '../../orm'
import { post } from '../../lib/http.js'

class AsteriskAuthTokenList extends ItemList {
    getEntityKey() {
        return "admin/asterisk_auth_token"
    }

    sendTestFax(values) {
        const url = "asterisk/fax_received"
        const state = null
        return post(state, url, values)
    }

    sendLegacyForwardedEmail(values) {
        const url = "asterisk/incoming_legacy_forwarded_email"
        const state = null
        return post(state, url, values)
    }
}

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })
}


export const asteriskAuthTokenList = new AsteriskAuthTokenList("asterisk_auth_token__default")
