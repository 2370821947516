/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx } from '@emotion/react'
import { head, get } from 'lodash'
import { adminUserList } from '../actions/admin_user'
import { Link } from 'react-router-dom'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import { adminReleaseNoteList } from '../actions/admin_release_note'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { Button, Container } from 'react-bootstrap'
import { InlineIcon } from '../../components/layout/InlineIcon'

class AdminReleaseNotes extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminReleaseNoteList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(adminReleaseNoteList.fetchListIfNeeded())
    }

    getCellValue = (header_key, item, index) => {
        const { customer_id } = this.props
        switch( header_key ) {
            case 'created_at':
                return <Timestamp value={item.created_at} format="datetime" />
            default:
                return undefined
        }
    }

    render() {
        const { is_loading, emails, headers } = this.props
        return (
            <AdminMainLayout title='Release notes'
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'release_notes', label: 'Release notes', url: '/admin/release_notes'}]}>
              <Container fluid >
                <CommonTable
                    is_loading={ is_loading }
                    empty_message={`No release notes`}
                    headers={ headers }
                    items={ emails }
                    item_list={adminReleaseNoteList}
                    getCellValue={ this.getCellValue }
                />
              </Container>
            </AdminMainLayout>
            
        )
    }
    
}

function mapStateToProps(state, props) {
    const emails = adminReleaseNoteList.getVisibleObjects()
    return {
        is_loading: adminReleaseNoteList.isLoading(),
        emails,
        headers: {
            created_at: {name: 'Date'},
            issue_number: {name: 'Issue'},
            header: {name: 'Title'},
            content: {name: 'Notes'}
        }
    }
}

export default connect(mapStateToProps)(AdminReleaseNotes)
