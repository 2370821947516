import { ItemList } from '../../orm'
import { map } from 'lodash'

class AdminPhoneNumberProviderList extends ItemList {
    getEntityKey() {
        return "admin/phone_number_provider"
    }

}

export const adminPhoneNumberProviderList = new AdminPhoneNumberProviderList("admin_phone_number_provider_default")
