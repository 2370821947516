/** @jsxImportSource @emotion/react */
import React, { Component, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Trans, Translation } from 'react-i18next'

export const CheckboxButton = ({ isSelected, unSelectedSuffix, ...props }) => {

    return (
        <div css={check_container_style}>
	  { isSelected !== true &&
	    <div css={[check_common_style, unchecked_style]}>
	      <Trans>Select</Trans>&nbsp;
	    {unSelectedSuffix || null}
	    </div>
	  }
	  { isSelected === true &&
	    <div css={[check_common_style, checked_style]}>
	      <FontAwesomeIcon icon={faCheckCircle}  />
	    &nbsp;
	    <Trans>Selected</Trans>
	    </div>
	  }	
	</div>
    )

}

const check_container_style = css`
width: 100%;
`

const check_common_style = css`
padding: 5px;
border-radius: 3px;
width: 100%;
text-align: center;
`

const unchecked_style = css`
background-color: ${theme.colors.white};
border: 1px solid ${theme.colors.primary_blue};
color: ${theme.colors.primary_blue}
`

const checked_style = css`
background-color: ${theme.colors.primary_orange};
border: 1px solid ${theme.colors.primary_orange};
color: ${theme.colors.white}
`


