import { ItemList } from '../../orm'
import { download } from '../../actions/lib'

class AdminCdrList extends ItemList {
    getEntityKey() {
        return 'admin/cdr'
    }

    download(filter) {
        const url = `admin/cdr/download_excel/` 
        const params = null 
        const post_params = filter
        return download(url, params, post_params)
    }

    requestLog(cdr_id) {
        const values = {id: cdr_id, action: 'request_log'}
        return this.saveObject(values)
    }
}

export const adminCdrList = new AdminCdrList('admin_cdr__default')
export const adminCustomerCdrList = new AdminCdrList('admin_customer_cdr__default')
