/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { get } from 'lodash'
import Timestamp from '../../components/Timestamp'
import { adminCustomerList } from '../actions/admin_customer'
import { ProfileForm } from '../../components/ProfileForm'
import Loading from '../../components/Loading'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import AdminMainLayout from './AdminMainLayout'
import AdminCustomerForm from './AdminCustomerForm'
import BusyMask from '../../components/BusyMask'
import Modal from '../../components/Modal'
import {showSuccess, showError} from '../../actions/Error'
import { Col, Row, Container, Tabs, Tab } from 'react-bootstrap'
import Card from '../../components/layout/Card'
import { CardHeader } from '../../components/layout/CardHeader'
import AdminCustomerSubscriptions from './AdminCustomerSubscriptions'
import { Separator } from '../../components/layout/Separator'
import { handleSubmitResult } from '../../actions/form'
import { Trans, Translation } from 'react-i18next'
import { FormLabelValue } from '../../components/form/FormLabelValue'

class AdminCustomerProfile extends Component {

    componentDidMount() {
        const { dispatch, customer_id } = this.props
        dispatch(adminCustomerList.fetchListIfNeeded())
        dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
    }

    componentDidUpdate() {
        const { dispatch, customer } = this.props
        dispatch(adminCustomerList.fetchListIfNeeded())
    }

    onSaveCustomerProfile = (values, formik_funcs) => {
        const { dispatch, history, customer_id } = this.props
        let on_ok = function(json) {
            if (! customer_id ) {
                showSuccess("Saved", "Account created")
                history.push(`/admin/customer/${json.id}`)
            } else {
                showSuccess("Saved", "Account saved")
            }
        }

        if ( customer_id ) {
            values.id = customer_id
            return dispatch(adminCustomerList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminCustomerList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    onSaveAdminSpecificForm = (values, formik_funcs) => {
        const { dispatch, customer_id, history } = this.props
        const that = this
        values.active = values.status ? values.status.value : true
        const on_ok = function(json) {
            dispatch(adminCustomerList.invalidateList())
            showSuccess("Saved", "Customer saved")

            if (! customer_id ) {
                history.push(`/admin/customer/${json.id}`)
            }

        }
        if ( customer_id ) {
            values.id = customer_id
            return dispatch(adminCustomerList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminCustomerList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    renderAdditionalCustomerFormFields = ({formik_props, left_width, right_width, section_name_style}) => {
        const { customer_id } = this.props
        if ( customer_id ) {
            return null
        }
        return (
            <div>
              <Row>
                <Col md={left_width} css={section_name_style}>
                  <Trans>New customer actions</Trans>
                </Col>
                <Col md={right_width}>
                  <FormLabelValue>
                    <Trans>Send welcome email</Trans>
                    <FormikCheckboxField name="send_welcome_email" formik_props={formik_props} />
                  </FormLabelValue>
                </Col>
              </Row>
            </div>
        )
    }

    renderAdminSpecificCustomerForm() {
        const { customer_id, customer } = this.props
        return (
          <div>
            <Card variant="white_wide_padding">
              { (!customer_id || customer.id) && 
                <AdminCustomerForm
                  onSubmit={ this.onSaveAdminSpecificForm }
                  customer_id={ customer_id }
                />
              }
            </Card>
          </div>
        )
    }

    renderCustomerProfileForm() {
        const { customer } = this.props
        return (
            <ProfileForm customer={customer}
                         renderAdditionalFields={this.renderAdditionalCustomerFormFields}
                         onSave={this.onSaveCustomerProfile} />
        )
    }

    render() {
        const { customer_id, is_busy, is_loading, customer, breadcrumbs } = this.props

        const left_child = (
            <div css={customer_title_style}>
              {get(customer, ["user", "display_name"], "New customer")}
              <Separator variant="w10" />
              { customer && 
                <div css={customer_title_since_style}>
                  Since&nbsp;
                  <Timestamp value={get(customer, ["user", "created_at"])} format="date"/>
                </div>
              }
            </div>
        )
        
        const customer_card_title = <CardHeader left_child={left_child} />
                
        return (
            <AdminMainLayout breadcrumbs={breadcrumbs}>
              { is_busy && <BusyMask /> }
              { is_loading && <Loading /> }
              { !is_loading &&
                <Container fluid>
                  <Row>
                    <Col className="col-md-8">
                      <CardHeader title={customer_card_title} />
                      <Card variant="white_wide_padding">
                        { this.renderCustomerProfileForm() }
                      </Card>
                    </Col>
                    { customer_id && 
                      <Col className="col-md-4">
                        <CardHeader title={<div>&nbsp;</div>} />
                        { this.renderAdminSpecificCustomerForm() }
                      </Col>
                    }
                  </Row>
                </Container>
              }
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const customer_id = get(props, ["match", "params", "customer_id"], null)
    const customer = customer_id && adminCustomerList.getObject(customer_id)

    const breadcrumbs = [{name: 'admin_home'},
                         {name: 'customers', label: 'Customers', url: '/admin/customers'}]
    if ( customer_id ) {
        breadcrumbs.push({name: 'customer', label: `${get(customer, ["user", "display_name"], '')} ${get(customer, ["short_id"], '')}`, url: `/admin/customer/${customer_id}`})
        breadcrumbs.push({name: 'customer_profile', label: "profile", url: `/admin/customer_profile/${customer_id}`})
    } else {
        breadcrumbs.push({name: 'customer_profile', label: "New customer", url: `/admin/customer_profile`})
    }

    return {
        customer_id,
        customer,
        breadcrumbs,
        is_loading: adminCustomerList.isLoading() || (customer_id && (!customer || !customer.id)),
        is_busy: adminCustomerList.getIsSavingObject()
    }
}
export default withRouter(connect(mapStateToProps)(AdminCustomerProfile))

const customer_title_style = css`
display: flex;
`

const customer_title_since_style = css`
font-size: 16px;
display: flex;
align-items: flex-end;
padding-bottom: 3px;
font-weight: normal;
`
