/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { size, get } from 'lodash'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import MainLayout from './MainLayout'
import { Trans, Translation } from 'react-i18next'
import { globalSettingsList } from '../actions/settings'
import { Row, Col, Button, ButtonGroup } from 'react-bootstrap'
import CommonTable from './CommonTable'
import Card from './layout/Card'
import { CardHeader } from './layout/CardHeader'
import { WhiteButton } from './layout/WhiteButton'
import { customerPhoneNumberList } from '../actions/customer_phone_number'
import { Formik, Form, FieldArray, Field } from 'formik'
import { Separator } from './layout/Separator'
import { FormikInputField } from './form/InputField'
import { InlineIcon } from './layout/InlineIcon'
import { InlineIconBar } from './layout/InlineIconBar'
import * as Yup from 'yup'
import {showSuccess, showError} from '../actions/Error'
import { confirmModal } from '../actions/ui'
import { FormikGeneralFormErrors } from './form/GeneralFormErrors'
import { MultipleEmailsSupportedInfoWidget } from './MultipleEmailsSupportedInfoWidget'
import { handleSubmitResult } from '../actions/form'

const emailSchema = Yup.array().of(Yup.string().email())

const validationSchema = Yup.object().shape({
    'phone_number_email': Yup.string()
                             .required("Email is required")
                             .test(
                                 "Validate Email",
                                 "Email must be valid",
                                 (value) => {
                                     const emails = value.split(",").map((email) => email.trim())
                                     return emailSchema.isValidSync(emails)
                             }),
    'phone_number_name': Yup.string().required("Name is required")
})


class FaxNumbersCard extends Component {

    constructor(props) {
        super(props)
        this.state = { number_being_edited: null,
                       is_number_being_added: false}
    }
    
    componentDidMount() {
        const { dispatch, item_list } = this.props
        dispatch(item_list.updateListFilter({active: true, can_receive_faxes: true}))
        dispatch(item_list.updatePaginationNumItemsPerPage(10))
        dispatch(item_list.fetchListIfNeeded())
        dispatch(globalSettingsList.fetchListIfNeeded())        
    }

    componentDidUpdate(prev_props) {
        const { dispatch, item_list } = this.props
        dispatch(item_list.fetchListIfNeeded())
    }

    startEditingNumber = (number) => {
        this.setState({number_being_edited: number, is_number_being_added: false})
    }

    cancelEditingNumber = () => {
        this.cancelAddingNumber()
    }
    
    onAddNewFaxSubscription = () => {
        const { history } = this.props
        history.push('/subscription/fax')
    } 

    cancelAddingNumber = () => {
        this.setState({is_number_being_added: false, number_being_edited: null})
    }

    // onDeleteNumber = (number) => {
    //     const { dispatch } = this.props
    //     function onConfirmed() {
    //         dispatch(customerPhoneNumberList.deleteObject(number.id)).then(() => {
    //             dispatch(customerPhoneNumberList.invalidateList())
    //         })
    //     }
    //     dispatch(confirmModal({text:<span><Trans>Are you sure you want to delete</Trans> ${number.number} for ${number.phone_number_name} ?</span>,
    //                            onConfirmed: onConfirmed}))
    // }

    onSaveNumber = (values, formik_funcs) => {
        const { dispatch, history, item_list } = this.props
        const { number_being_edited } = this.state
        const that = this
        const on_ok = function(json) {
            dispatch(item_list.invalidateList())
            that.cancelAddingNumber()
            showSuccess(<Trans>Saved</Trans>, <Trans>Fax number saved</Trans>)
        }
        if ( number_being_edited ) {
            values.id = number_being_edited.id
            return dispatch(item_list.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(item_list.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    getCellValue = (header_key, item, index, row_index, formik_props) => {
        const { number_being_edited, is_number_being_added } = this.state
        const is_editing = (is_number_being_added === true && row_index === -1) || (number_being_edited && number_being_edited.id === item.id)

        switch( header_key ) {

            case 'number':
                return undefined

            case 'phone_number_name': 
                if ( is_editing ) {
                    return (
                        <Translation>{ (t) => (
                            <FormikInputField
                              name="phone_number_name"
                              type="text"
                              placeholder={t("Name")}
                            />
                        )}</Translation>
                    )
                } else {
                    return undefined
                }

            case 'phone_number_email': 
                if ( is_editing ) {
                    return (
                        <>
                          <Translation>{ (t) => (
                              <FormikInputField
                                  name="phone_number_email"
                                  type="email"
                                  placeholder={t("Email")}
                              />
                              )}</Translation>
                        </>
                    )
                } else {
                    return undefined
                }

            case 'action':
                if ( is_editing ) {
                    return (
                        <InlineIconBar>
                          <MultipleEmailsSupportedInfoWidget />
                          <InlineIcon icon_name="save" variant="green" onClick={formik_props.submitForm} />
                          <InlineIcon icon_name="cancel" variant="red" onClick={this.cancelEditingNumber} />
                        </InlineIconBar>
                        
                    )
                } else {
                    if ( ! is_editing ) {
                        return (
                            <InlineIconBar>
                              <InlineIcon icon_name="edit" onClick={() => this.startEditingNumber(item)}/>
                              { false && <InlineIcon icon_name="delete" onClick={() => this.onDeleteNumber(item)} /> }
                            </InlineIconBar>
                        )
                    }

                }

            default:
                return undefined
        }
    }

    renderTitle() {
        const { customer_can_create_subscriptions, can_add } = this.props
        return (
            <div>
              <CardHeader title={<Trans>Fax Numbers</Trans>}
                          left_child={null}
                          right_child={
                              (
                                  <>
                                    { can_add !== false && customer_can_create_subscriptions &&
                                      <WhiteButton onClick={this.onAddNewFaxSubscription}>
                                        <Trans>Add Number</Trans>
                                      </WhiteButton>
                                    }
                                  </>
                              )
                          }
                          sub_text={<Trans>Incoming faxes on these numbers will be forwarded as a pdf attachment to an email address you choose.</Trans>}
              />

              <Separator variant="h10" />
            </div>
        )
    }

    render() {
        const { numbers, number_headers, is_loading, item_list } = this.props
        const { is_number_being_added, number_being_edited } = this.state
        let edit_row = null

        return (
            <Formik
              initialValues={(is_number_being_added && {}) || {number: get(number_being_edited, 'number'),
                                                               phone_number_name: get(number_being_edited, 'phone_number_name'),
                                                               phone_number_email: get(number_being_edited, 'phone_number_email')}}
                onSubmit={this.onSaveNumber}
                validationSchema={validationSchema}
                enableReinitialize={true}
            >
              {formik_props => {
                   const { values } = formik_props
                   return (
                       <Card with_padding_below={false} inner_css={{}} title={this.renderTitle()}>

                         { size(numbers) > 0 && 
                           <Form>
                             <FormikGeneralFormErrors />
                             <CommonTable is_loading={ is_loading }
                                          empty_message={'There are no fax numbers on your account'}
                                          headers={number_headers}
                                          add_row={(is_number_being_added && {}) || null}
                                          edit_row={number_being_edited}
                                          items={numbers}
                                          item_list={item_list}
                                          pagination_mode="auto"
                                          getCellValue={ (header_key, item, index, row_index) => this.getCellValue(header_key, item, index, row_index, formik_props) }
                             />
                           </Form>
                         }
                       </Card>
                   )}
              }
            </Formik>
        )
    }
    
}

function mapStateToProps(state, props) {
    const { can_add } = props
    const item_list = props.item_list || customerPhoneNumberList
    const numbers = item_list.getVisibleObjects()
    const customer_can_create_subscriptions = globalSettingsList.getSetting("customer_can_create_subscriptions") === true

    return {
        can_add,
        numbers,
        item_list: item_list,
        is_loading: item_list.isLoading(),
        number_headers: {
            number: { name: <Trans>Number</Trans>, column_size: 1 },
            phone_number_name: { name: <Trans>Name</Trans>, column_size: 1 },
            phone_number_email: { name: <Trans>Forward fax to</Trans>, column_size: 1 },
            action: { name: '', column_size: 1 }
        },
        customer_can_create_subscriptions
    }
}

export default withRouter(connect(mapStateToProps)(FaxNumbersCard))
