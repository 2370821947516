/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import AdminMainLayout from './AdminMainLayout'
import AdminVoiceNumbersCardTable from './AdminVoiceNumbersCardTable'
import { adminCustomerVoicePhoneNumberList } from '../actions/admin_customer_phone_number'

class AdminVoiceNumbersCard extends Component {

    componentDidMount() {
        const { dispatch, customer_id } = this.props
        if ( customer_id ) {
            dispatch(adminCustomerVoicePhoneNumberList.updateListFilter({customer: customer_id, can_receive_voice: true, 'is_active': true}))
        } else {
            dispatch(adminCustomerVoicePhoneNumberList.updateListFilter({customer: null, can_receive_voice: true, 'is_active': true}))
        }
        dispatch(adminCustomerVoicePhoneNumberList.fetchListIfNeeded())
    }
    
    componentDidUpdate() {
        const { dispatch, customer_id, admin_voice_item_list_filter } = this.props
        if ( admin_voice_item_list_filter.customer !== customer_id ) {
            dispatch(adminCustomerVoicePhoneNumberList.updateListFilter({customer: customer_id || null}))
        }
        if ( customer_id ) {
            dispatch(adminCustomerVoicePhoneNumberList.ensureObjectLoaded(customer_id))
        }
        dispatch(adminCustomerVoicePhoneNumberList.fetchListIfNeeded())
    }
    
    render() {
        const { customer_id, is_ready } = this.props
        return (
            <>
            { is_ready && <AdminVoiceNumbersCardTable item_list={adminCustomerVoicePhoneNumberList} /> }
            </>
        )
    }
    
}

function mapStateToProps(state, props) {
    const { customer_id } = props
    const items = adminCustomerVoicePhoneNumberList.getVisibleObjects()
    return {
        customer_id,
        items,
        admin_voice_item_list_filter: adminCustomerVoicePhoneNumberList.getFilter(),
        is_ready: adminCustomerVoicePhoneNumberList.isReady()
    }
}

export default connect(mapStateToProps)(AdminVoiceNumbersCard)
