/** @jsxImportSource @emotion/react */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { PhoneNumber } from './PhoneNumber'
import { Separator } from './layout/Separator'
import { Timestamp } from './Timestamp'
import { Trans, Translation } from 'react-i18next'

export const SubscriptionOptionWithExpiryDate = ({subscription}) => {

    return (
        <div css={existing_subscription_option_style}>
          { subscription.phone_number_number && <PhoneNumber phone_number={subscription.phone_number_number} /> }
	  { ! subscription.phone_number_number && subscription.product_name }
          { subscription.expires_at &&
            <>
              <Separator variant="w5" />
              - <Trans>expires</Trans>
              <Separator variant="w5" />
              <Timestamp value={subscription.expires_at} format="date_longmonth" />
            </>
          }
        </div>
    )
}


const existing_subscription_option_style = css`
display: flex;
`

