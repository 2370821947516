import { ItemList } from '../../orm'
import { map } from 'lodash'

class AdminNumberRangeList extends ItemList {
    getEntityKey() {
        return "admin/number_range"
    }

}

export const adminNumberRangeList = new AdminNumberRangeList("admin_number_range_default")
