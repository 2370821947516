/** @jsxImportSource @emotion/react */
import React from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/react'
import { Trans } from 'react-i18next'
import { getBestLanguageCode } from '../actions/language'
import { customerList } from '../actions/customer'
import { CardHeader } from './layout/CardHeader'
import Card from './layout/Card'
import CardParagraphHeading from './layout/CardParagraphHeading'
import { BlueButton } from './layout/BlueButton'
import inbox_en from '../images/inbox_en.png'
import inbox_nl from '../images/inbox_nl.png'

const FaxInPromoCard = () => {
  const history = useHistory()

  const onAddFaxSubscription = () => {
    history.push('/subscription/fax')
  }

  return (
    <div>
      <CardHeader
        title={
          <Trans i18nKey={'faxin_promo_header'}>
            Receive faxes in your email
          </Trans>
        }
        left_child={null}
        right_child={null}
      />
      <Card variant="white" with_padding_below={false}>
        <CardParagraphHeading>
          <Trans i18nKey={'faxin_promo_subheader'}>
            Retire your fax-machine. Save time, frustration and paper.
          </Trans>
        </CardParagraphHeading>
        <div css={content_container}>
          <div css={content}>
            <div css={text}>
              <p>
                <strong>
                  <Trans i18nKey={'faxin_promo_usp1_header'}>
                    Get your own fax number.
                  </Trans>
                </strong>{' '}
                <Trans i18nKey={'faxin_promo_usp1_text'}>
                  A local or national (084) number, on which you can receive
                  faxes.
                </Trans>
              </p>
              <p>
                <strong>
                  <Trans i18nKey={'faxin_promo_usp2_header'}>
                    Receive faxes in your email
                  </Trans>
                </strong>{' '}
                <Trans i18nKey={'faxin_promo_usp2_text'}>
                  They arrive as pdf-files. No more hardware needed.
                </Trans>
              </p>
              <p>
                <strong>
                  <Trans i18nKey={'faxin_promo_usp3_header'}>
                    Online dashboard
                  </Trans>
                </strong>{' '}
                <Trans i18nKey={'faxin_promo_usp3_text'}>
                  with a list of all your in and outgoing faxes.
                </Trans>
              </p>
              <p>
                <strong>
                  <Trans i18nKey={'faxin_promo_usp4_header'}>
                    Privacy protected
                  </Trans>
                </strong>{' '}
                <Trans i18nKey={'faxin_promo_usp4_text'}>
                  No fax remains on XOIPs servers longer than needed.
                </Trans>
              </p>
            </div>
            <BlueButton onClick={onAddFaxSubscription}>
              <Trans>See the fax subscriptions</Trans>
            </BlueButton>
          </div>
          <div css={graphic}>
            {getBestLanguageCode() === 'en' ? (
              <img src={inbox_en} alt="" css={inbox} />
            ) : (
              <img src={inbox_nl} alt="" css={inbox} />
            )}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default FaxInPromoCard

const content_container = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 25px;
  margin-bottom: -20px;
  margin-right: -20px;
  & > div {
    flex: 1;
  }
`
const content = css`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  margin-bottom: 30px;
`
const text = css`
  & > p {
    margin-bottom: 0.6em;
  }
  & > p > span {
    font-weight: 500;
  }
`
const graphic = css`
  position: relative;
  overflow: hidden;
  border-bottom-right-radius: 8px;
`

const inbox = css`
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1);
  width: auto;
  height: auto;
  max-width: none;
`
