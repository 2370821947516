/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import {showSuccess, showError} from '../../actions/Error'
import { jsx, css } from '@emotion/react'
import { useDispatch, useSelector } from 'react-redux'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { confirmModal } from '../../actions/ui'
import FieldInfoText from '../../components/layout/FieldInfoText'
import { get, map, join } from 'lodash'
import { Modal } from 'react-bootstrap'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import { PhoneNumber } from '../../components/PhoneNumber'
import { Duration } from '../../components/Duration'
import { Trans } from 'react-i18next'
import { Separator } from '../../components/layout/Separator'
import { Timestamp } from '../../components/Timestamp'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { cdrTypes } from '../../actions/dropdown_options'
import CurrencyValue from '../../components/CurrencyValue'
import { AdminQuickLink } from './AdminQuickLink'
import { adminCdrExternalRoutingInfoTypes } from '../actions/admin_dropdown_options'
import { adminCdrList } from '../actions/admin_cdr'

export const AdminCdrDetailsModal = ({cdr_id, cdrList, onClose}) => {

    const dispatch = useDispatch()
    const cdr = useSelector(() => cdrList.getObject(cdr_id))
    const cdr_types = useSelector(() => cdrTypes.getObjectsById())
    const cdr_external_routing_info_types = useSelector(() => adminCdrExternalRoutingInfoTypes.getObjectsById())

    useEffect(() => {
        dispatch(cdrTypes.fetchListIfNeeded())
        dispatch(adminCdrExternalRoutingInfoTypes.fetchListIfNeeded())
    }, [])
    
    useEffect(() => {
        dispatch(cdrList.ensureObjectLoaded(cdr_id))
    }, [cdr_id])

    const onRequestLog = () => {
        const on_ok = function(json) {
            showSuccess("Requested", "The log has been requested")
        }

        const onConfirmed = () => {
            return dispatch(adminCdrList.requestLog(cdr.id)).then(on_ok)
        }
        
        const text = (
            <div>
              A request will be made to the {cdr.server_name} to fetch the logs for this cdr from disk.
              <br/>
              This request can take a few minutes, refresh your browser to check for the results.
            </div>
        )
        return dispatch(confirmModal({text:text,  onConfirmed: onConfirmed}))
    }

    const onRefresh = () => {
        dispatch(adminCdrList.invalidateObject(cdr.id))
        dispatch(adminCdrList.ensureObjectLoaded(cdr.id))
    }

    const renderFreeMinutes = () => {
        const log = cdr.customer_subscription_free_minutes_log
        return (
            <>
              { ! log &&
                <div css={summary_row_style}>
                  No free minutes
                </div>
              }
              { log &&
                <div css={summary_row_style}>
                  <Trans>Num free minutes</Trans>:&nbsp;
                  <div><Duration seconds={log.num_minutes*60} /></div>
                </div>
              }
              <Separator variant="h10" />
            </>
        )
    }

    const renderContent = () => {
        return (
            <div>
              
              <div css={summary_row_style}>
                <Trans>Received</Trans>:&nbsp;
                <Timestamp value={cdr.created_at} format="at" />
              </div>

              <div css={summary_row_style}>
                <Trans>Handled by server </Trans> {cdr.server_name}
              </div>
              
              <div css={summary_row_style}>
                <Trans>CDR started at</Trans>:&nbsp;
                <Timestamp value={cdr.start_datetime} format="at" />
              </div>
              <Separator variant="h10" />
              
              <div css={summary_row_style}>
                <Trans>Type</Trans>:&nbsp;
                {get(cdr_types, [cdr.cdr_type, "name"], cdr.cdr_type)}
              </div>
              <Separator variant="h10" />

              <div css={summary_row_style}>
                <Trans>Asterisk supplementary routing info</Trans>:&nbsp;
                { cdr.external_routing_info &&
                  get(cdr_external_routing_info_types, [cdr.external_routing_info, "name"], cdr.external_routing_info)
                }
                { ! cdr.external_routing_info && <span>No extra info about internal asterisk routing</span> }
              </div>
              <Separator variant="h10" />
              
              <div css={summary_row_style}>
                <Trans>From</Trans>:&nbsp;
                <PhoneNumber phone_number={cdr.source_number} />
              </div>
              <Separator variant="h10" />

              <div css={summary_row_style}>
                <Trans>To</Trans>:&nbsp;
                <PhoneNumber phone_number={cdr.destination_number} />
              </div>
              <Separator variant="h10" />

              <div css={summary_row_style}>
                <Trans>Forwarded to</Trans>:&nbsp;
                <PhoneNumber phone_number={cdr.final_number} />
              </div>
              <Separator variant="h10" />

              <div css={summary_row_style}>
                <Trans>Duration</Trans>:&nbsp;
                <Duration seconds={cdr.duration_seconds} />
              </div>
              <Separator variant="h10" />

              <div css={summary_row_style}>
                <Trans>External reference</Trans>:&nbsp;
                {cdr.external_ref || "-"}
              </div>
              <Separator variant="h10" />

              <div css={summary_row_style}>
                <Trans>Sync reference</Trans>:&nbsp;
                {cdr.external_unique_ref || "-"}
              </div>
              <Separator variant="h10" />

              <div css={summary_row_style}>
                <Trans>Setup cost</Trans>:&nbsp;
                <CurrencyValue value={cdr.setup_cost_excluding_vat_euros} />
              </div>
              <Separator variant="h10" />
              
              <div css={summary_row_style}>
                <Trans>Total cost</Trans>:&nbsp;
                <CurrencyValue value={cdr.total_cost_excluding_vat_euros} />
              </div>
              <Separator variant="h10" />

              <div css={summary_row_style}>
                <Trans>Cost per minute</Trans>:&nbsp;
                <CurrencyValue value={cdr.cost_per_minute_excluding_vat_euros} />
              </div>
              <Separator variant="h10" />

              { cdr.debit_transaction &&
                <>
                  <div css={summary_row_style}>
                    <Trans>Transaction ref</Trans>:&nbsp;
                    <AdminQuickLink name="transaction" value={cdr.debit_transaction} label="debit transaction" />
                  </div>
                  <Separator variant="h10" />
                </>
              }

              { cdr.cdr_type === "forwarded" && renderFreeMinutes() }
              
              { cdr.server_log_lines &&
                <>
                  <hr/>
                  <h3>This is an extract from the asterisk server logs for this cdr. </h3>
                  <FieldInfoText>
                    Note: Because the log file can recycle identifiers, these logs might include information from other cdrs on previous days, check timestamps in each line if there is doubt.
                  </FieldInfoText>
                  <Separator variant="h20" />
                  
                  <p css={server_log_lines_style}
                     dangerouslySetInnerHTML={{ __html: join(map(cdr.server_log_lines, (line) => `${line}\n`), "\n") } }
                  />
                  
                  <WhiteButton onClick={onRequestLog}>Request log again</WhiteButton>
                  <WhiteButton onClick={onRefresh}>Refresh</WhiteButton>
                </>
              }
              { !cdr.server_log_lines &&
                <>
                  <WhiteButton onClick={onRequestLog}>Request log</WhiteButton>
                  <WhiteButton onClick={onRefresh}>Refresh</WhiteButton>
                </>
              }

            </div>
        )
    }

    return (
        <Modal show={true}
               size="lg"
               onHide={onClose} >

          <WrappingBusyMask is_loading={cdrList.isLoading()}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Trans>CDR details</Trans> : {cdr.short_id}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              { renderContent() }
            </Modal.Body>
          </WrappingBusyMask>
          
        </Modal>
    )
    
}

const summary_row_style = css`
display: flex;
align-items: center;
`

const error_item_container = css`
margin-left: 10px;
`

const server_log_lines_style = css`
font-size: 9px;
white-space: pre-line;
`
