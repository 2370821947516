import { ItemList } from '../orm'
import cookie from 'react-cookies'

class UpgradePathList extends ItemList {
    getEntityKey() {
        return "upgrade_path"
    }

    collapseUpgradePathNotificationFreeToPaidUpsell() {
        cookie.save('UpgradePathNotificationFreeToPaidUpsellCollapsed', "1", { path: '/'})
    }

    expandUpgradePathNotificationFreeToPaidUpsell() {
        cookie.save('UpgradePathNotificationFreeToPaidUpsellCollapsed', "0", { path: '/'})
    }

    isUpgradePathNotificationFreeToPaidUpsellCollapsed() {
        return cookie.load("UpgradePathNotificationFreeToPaidUpsellCollapsed") === "1"
    }

    filterCustomerSubscriptionListForUpgrades({customerSubscriptionItemList}) {
        return (dispatch, getState) => {
            dispatch(customerSubscriptionItemList.updatePaginationNumItemsPerPage(1000))
            dispatch(customerSubscriptionItemList.updateListFilter({has_upgrade_path_for_upgrade: true}))
            dispatch(customerSubscriptionItemList.fetchListIfNeeded())
        }
    }

    
}

export const upgradePathList = new UpgradePathList("upgrade_path__default")
export const upgradePathListExVat = new UpgradePathList("upgrade_path__ex_vat")
