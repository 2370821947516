/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { Trans, Translation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { filter, unionBy, size, get, head, map } from 'lodash'
import { DropdownStandalone } from './form/Dropdown'
import Card from './layout/Card'
import { upgradePathList } from '../actions/upgrade_path'
import { productList } from '../actions/product'
import { SubscriptionOptionWithExpiryDate } from './SubscriptionOptionWithExpiryDate'
import { PhoneNumber } from './PhoneNumber'
import NestedWrappingBusyMask from './NestedWrappingBusyMask'
import { globalSettingsList } from '../actions/settings'
import * as Yup from 'yup'
import MainLayout from './MainLayout'
import {
  Row,
  Col,
  Container,
  Popover,
  OverlayTrigger,
  Button,
} from 'react-bootstrap'
import { CardHeader } from './layout/CardHeader'
import { Separator } from './layout/Separator'
import {
  customerSubscriptionUpgrade,
  customerSubscriptionList,
} from '../actions/customer_subscription'
import { SubscriptionUpgradeCard } from './SubscriptionUpgradeCard'

export const validationSchema = Yup.object().shape({})

export const SubscriptionUpgrade = ({ ...props }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { subscription_id } = useParams()
  const subscription = useSelector(
    () => subscription_id && customerSubscriptionList.getObject(subscription_id)
  )
  const subscriptions = useSelector(() =>
    unionBy(
      customerSubscriptionUpgrade.getObjects(),
      [subscription],
      (sub) => sub.id
    )
  )
  const isLoading = useSelector(() => customerSubscriptionUpgrade.isLoading())

  const initial_values = {}
  const helpdesk_email = useSelector(() =>
    globalSettingsList.getSetting('helpdesk_email')
  )

  // useEffect(() => {
  //   dispatch(
  //     upgradePathList.filterCustomerSubscriptionListForUpgrades({
  //       customerSubscriptionItemList: customerSubscriptionUpgrade,
  //     })
  //   )
  //   dispatch(globalSettingsList.ensureGlobalSettingsLoaded())
  // }, [])

  useEffect(() => {
    if (subscription_id) {
      dispatch(customerSubscriptionList.ensureObjectLoaded(subscription_id))
    } else if (size(subscriptions) > 0) {
      const defaultSubscription = head(subscriptions)
      history.push(`/subscription_upgrade/${defaultSubscription.id}`)
    }
  }, [subscriptions, subscription_id])

  return (
    <MainLayout
      active_key="subscription_upgrade"
      enable_breadlinks={true}
      breadcrumbs={[
        { name: 'home' },
        {
          name: 'subscription_upgrade',
          label: 'Subscription Upgrade',
          url: '/subscription_upgrade',
        },
      ]}
    >
      <Container fluid>
        <Separator variant="h20" />

        <Row>
          <Col md={12}>
            <CardHeader title="Change your subscription" />
          </Col>
        </Row>

        <Card variant="white_wide_and_top_padding" with_padding_below={false}>
          <div css={upgrade_from_to}>
            Upgrade{' '}
            <strong>
              {subscription.phone_number_number && (
                <PhoneNumber phone_number={subscription.phone_number_number} />
              )}
            </strong>{' '}
            from <strong>{subscription.product_name}</strong> to:
          </div>

          <Separator variant="h40" />

          <SubscriptionUpgradeCard
            subscription={subscription}
            force_phone_number={subscription.phone_number}
            additional_create_values={{ upgrade_subscription: subscription_id }}
          />
        </Card>
      </Container>
    </MainLayout>
  )
}

const upgrade_from_to = css`
  font-size: 1.2em;
`
