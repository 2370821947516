/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { head, get } from 'lodash'
import { Link } from 'react-router-dom'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import CurrencyValue from '../../components/CurrencyValue'
import Timestamp from '../../components/Timestamp'
import { adminFaxCostStructureVariantList } from '../actions/admin_fax_cost_structure_variant'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { Button, Container } from 'react-bootstrap'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFilter from './AdminTableFilter'

class AdminFaxCostStructureVariants extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminFaxCostStructureVariantList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(adminFaxCostStructureVariantList.fetchListIfNeeded())
    }

    onEditFaxCostStructureVariant = (evt, item) => {
        const { history } = this.props
        history.push({
            pathname: '/admin/fax_cost_structure_variant/'+item.id
        })
    }

    onAddFaxCostStructureVariant = () => {
        const { history } = this.props
        history.push('/admin/fax_cost_structure_variant')
    }
    
    filterFaxCostStructureVariants = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminFaxCostStructureVariantList.updateListFilter(filter_values))
    }


    getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'action':
                return (
                    <Button onClick={(evt) => this.onEditFaxCostStructureVariant(evt, item)}>
                      Edit
                    </Button>
                )
                
            default:
                return undefined
        }
    }

    render() {
        const { is_loading, headers, fax_cost_structure_variants } = this.props
        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'fax_cost_structure_variants', label: 'Fax Cost Variants', url: '/admin/fax_cost_structure_variants'}]}>
              <Container fluid>
                <AdminTableHeader title="Fax Cost Structure Variants">
                  <AdminTableFilter
                      updateOnChange={ this.filterFaxCostStructureVariants }
                      form="ADMIN_FAX_COST_STRUCTURE_VARIANTS_FILTER_FORM"
                  />
                  <Button variant="outline-primary" onClick={ this.onAddFaxCostStructureVariant }>
                    Add fax cost variant
                  </Button>
                </AdminTableHeader>
                <CommonTable
                    is_loading={ is_loading }
                    empty_message={`There are no fax cost variants.`}
                    headers={ headers }
                    items={ fax_cost_structure_variants }
                    item_list={adminFaxCostStructureVariantList}
                    getCellValue={ this.getCellValue }
                />
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const fax_cost_structure_variants = adminFaxCostStructureVariantList.getVisibleObjects()
    return {
        fax_cost_structure_variants,
        is_loading: adminFaxCostStructureVariantList.isLoading(),
        headers: {
            name: {name: 'Name'},
            inherits_from_name: {name: 'Based on'},
            action: { name: '', column_size: 1 }
        }
    }
}

export default connect(mapStateToProps)(AdminFaxCostStructureVariants)
