/** @jsxImportSource @emotion/react */
import React,{ useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { Trans, Translation } from 'react-i18next'
import { Separator } from './layout/Separator'
import { Row, Col, Container } from 'react-bootstrap'
import { head, size, map, filter, get } from 'lodash'
import { default_theme as theme } from '../emotion/theme'
import { BlueButton } from './layout/BlueButton'
import { upgradePathList } from '../actions/upgrade_path'
import { customerSubscriptionUpgradePathListFreeToPaidBasedOnProduct } from '../actions/customer_subscription'

export const UpgradePathNotificationFreeToPaidUpsellBasedOnProduct = ({upgrade_path, display_style, onHideNotification, onDidRender}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const is_loading = useSelector(() => upgradePathList.isLoading())
    const is_saving = useSelector(() => upgradePathList.getIsSavingObject())

    useEffect(() => {
        dispatch(upgradePathList.filterCustomerSubscriptionListForUpgrades({customerSubscriptionItemList: customerSubscriptionUpgradePathListFreeToPaidBasedOnProduct}))
        
    }, [])

    const onMoveToANewSubscription = () => {
        history.push(`/subscription_upgrade/${upgrade_path.customer_subscription}`)
    }
    
    const render_for_voice_subscriptions = (upgrade_path) => {
        return (
            <div css={container_style}>

              <div>
                <Separator variant="h10" />
              </div>

              <h3 css={h3_style}>
                <Trans i18nKey="upgrade_path_free_to_paid_based_on_product__title">
                  Upgrade to Voice Pro
                </Trans>:
              </h3>

              <div>
                <Trans i18nKey="upgrade_path_free_to_paid_based_on_product__minutes">
                  Get 100 minutes of free forwarding within the Netherlands
                </Trans>
              </div>

              <div>
                <Trans i18nKey="upgrade_path_free_to_paid_based_on_product__cost">
                  Only €49 per year
                </Trans>
              </div>

              <div>
                <Separator variant="h20" />
              </div>
              
              <div>
                <BlueButton onClick={onMoveToANewSubscription}>
                  <Trans i18nKey="upgrade_path_free_to_paid_based_on_product__yes_button">
                    Yes, I want to upgrade to Voice Pro
                  </Trans>
                </BlueButton>
              </div>

            </div>
        )
    }

    if ( display_style === "voice_numbers_bottom" ) {
        onDidRender()
        return render_for_voice_subscriptions(upgrade_path)
    } else {
        return null
    }
    
}

const container_style = css`
background-color: #fff5cc;
width: 100%;
margin-bottom: 40px;
padding: 20px 20px 18px;
border-radius: 10px;
color: ${theme.colors.black};
`

const h3_style = css`
font-weight: bold;
font-size: 18px;
`

const dismiss_button_container_style = css`
`

