/** @jsxImportSource @emotion/react */
import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { head, get, isEqual } from 'lodash'
import MainLayout from './MainLayout'
import FormikOnChange from './form/FormikAutoSave'
import { Row, Col, Button, Accordion } from 'react-bootstrap'
import { WhiteButton } from './layout/WhiteButton'
import { InputWithButton } from './layout/InputWithButton'
import Card from './layout/Card'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormLabel } from './form/FormLabel'
import { FormikInputField } from './form/InputField'
import { CardHeader } from './layout/CardHeader'
import { FormikRadioGroupField } from './form/RadioGroupField'
import { loggedInUser } from '../actions/auth'
import FieldInfoText from './layout/FieldInfoText'
import { Form as BootstrapForm } from 'react-bootstrap'
import { incomingFaxByEmailSettingsList } from '../actions/incoming_fax_by_email_settings'
import { outgoingFaxSettingsList } from '../actions/outgoing_fax_settings'
import {showSuccess, showError} from '../actions/Error'
import { Separator } from './layout/Separator'
import { SeparatorWithLine } from './layout/SeparatorWithLine'
import CardParagraphSubHeading from './layout/CardParagraphSubHeading'
import CardInfoText from './layout/CardInfoText'
import { InlineIcon } from './layout/InlineIcon'
import CommonIncomingFaxByEmailSettingsFormFields from './CommonIncomingFaxByEmailSettingsFormFields'
import { BlueLinkButton } from './layout/BlueLinkButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormikGeneralFormErrors } from './form/GeneralFormErrors'
import { handleSubmitResult } from '../actions/form'
import * as Yup from 'yup'
import radio_selected from '../images/radio_selected.svg'
import radio_unselected from '../images/radio_unselected.svg'
import {
    ATTACH_FAX_TO_CONFIRMATION_EMAIL_OPTIONS,
    SEND_MULTIPLE_FILES_OPTIONS,
    INCOMING_FAX_BY_EMAIL_INITIAL_VALUES_DEFAULT,
    OUTGOING_FAX_INITIAL_VALUES_DEFAULT,
} from '../actions/form_vars'

const validationSchema = Yup.object().shape({
    'fax_header': Yup.string().nullable().max(32, "Maximum of 32 characters")
})

class DefaultIncomingFaxByEmailSettingsForm extends Component {

    constructor(props) {
        super(props)
        this.state = {form_got_dirty_at_least_once: false}
    }
    
    componentDidMount() {
        const { dispatch, user } = this.props
        dispatch(incomingFaxByEmailSettingsList.updateListFilter({customer:user.id}))
        dispatch(incomingFaxByEmailSettingsList.fetchListIfNeeded())
        dispatch(outgoingFaxSettingsList.updateListFilter({customer:user.id}))
        dispatch(outgoingFaxSettingsList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(incomingFaxByEmailSettingsList.fetchListIfNeeded())
        dispatch(outgoingFaxSettingsList.fetchListIfNeeded())
    }

    checkAutoSave = (values, formik_props) => {
        const { initial_values } = this.props
        const { form_got_dirty_at_least_once } = this.state
        if ( form_got_dirty_at_least_once || ! isEqual(initial_values, values) ) {
            formik_props.submitForm()
            this.setState({form_got_dirty_at_least_once: true})
        }
    }

    onSaveIncomingFaxByEmailSettings = (values, formik_funcs) => {
        const { dispatch, history, incoming_fax_by_email_settings, outgoing_fax_settings, is_loading } = this.props

        const that = this
        const on_ok = function(json) {
            dispatch(incomingFaxByEmailSettingsList.invalidateList())
            dispatch(outgoingFaxSettingsList.invalidateList())
        }

        const save_incoming_settings = function() {
            const incoming_values = values.incoming
            if ( incoming_fax_by_email_settings && incoming_fax_by_email_settings.id ) {
                incoming_values.id = incoming_fax_by_email_settings.id
                return dispatch(incomingFaxByEmailSettingsList.saveObject(incoming_values))
                    .then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
            } else {
                return dispatch(incomingFaxByEmailSettingsList.saveNewObject(incoming_values))
                    .then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
            }
        }

        const save_outgoing_settings = function() {
            const outgoing_values = values.outgoing
            if ( outgoing_fax_settings && outgoing_fax_settings.id ) {
                outgoing_values.id = outgoing_fax_settings.id
                return dispatch(outgoingFaxSettingsList.saveObject(outgoing_values))
                    .then((res) => handleSubmitResult({res, formik_funcs, on_ok: save_incoming_settings}))
            } else {
                return dispatch(outgoingFaxSettingsList.saveNewObject(outgoing_values, save_incoming_settings))
                    .then((res) => handleSubmitResult({res, formik_funcs, on_ok: save_incoming_settings}))
            }
        }
        save_outgoing_settings()
    }

    render() {
        const { initial_values } = this.props
        const that = this
        
        return (
            <div>
              <Formik
                  initialValues={initial_values}
                  onSubmit={this.onSaveIncomingFaxByEmailSettings}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
              >

                {formik_props => {
                     const { values } = formik_props

                     return (
                         <Form>
                           <FormikGeneralFormErrors />
                           <FormikOnChange onChange={(values) => this.checkAutoSave(values, formik_props)} />
                           <CommonIncomingFaxByEmailSettingsFormFields formik_props={formik_props} />
                         </Form>
                     )
                }}
                
              </Formik>
            </div>
        )
    }
    
}

function mapStateToProps(state, props) {
    const incoming_fax_by_email_settings = head(incomingFaxByEmailSettingsList.getVisibleObjects())
    const outgoing_fax_settings = head(outgoingFaxSettingsList.getVisibleObjects())
    return {
        user: loggedInUser(),
        initial_values: { 'incoming': incoming_fax_by_email_settings || INCOMING_FAX_BY_EMAIL_INITIAL_VALUES_DEFAULT,
                          'outgoing': outgoing_fax_settings || OUTGOING_FAX_INITIAL_VALUES_DEFAULT },
        is_loading: incomingFaxByEmailSettingsList.isLoading() || outgoingFaxSettingsList.isLoading()
                                                                                                
    }
}

export default withRouter(connect(mapStateToProps)(DefaultIncomingFaxByEmailSettingsForm))

const security_option_row = css`
cursor: pointer;
`

const fixed_width_input = css`
max-width: 450px;
`

const radio_col_style = css`
display: flex;
`
