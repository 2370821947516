import { ItemList } from '../../orm'

class AdminCustomerSubscriptionList extends ItemList {
    getEntityKey() {
        return "admin/customer_subscription"
    }

    requestCancel({subscription_id, send_email_to_customer}) {
        return this.saveObject({id: subscription_id, action: 'request_cancel', action_send_email_to_customer: send_email_to_customer})
    }

    cancelImmediately({subscription_id, send_email_to_customer}) {
        return this.saveObject({id: subscription_id, action: 'cancel_immediately', action_send_email_to_customer: send_email_to_customer})
    }

    createSubscriptionUpgradeUrl({subscription_id}) {
        return this.saveObject({id: subscription_id, action: 'create_subscription_upgrade_url'})
    }
    
}

export const adminCustomerSubscriptionList = new AdminCustomerSubscriptionList("admin_customer_subscription__default")
export const adminCustomerSubscriptionForCustomerList = new AdminCustomerSubscriptionList("admin_customer_subscription_for_customer__default")
export const adminCustomerSubscriptionForMigrationList = new AdminCustomerSubscriptionList("admin_customer_subscription_for_migration__default")
export const adminCustomerSubscriptionFullList = new AdminCustomerSubscriptionList("admin_customer_full_subscription__default")

export const invalidateAllAdminCustomerSubscriptionLists = () => {
    return async(dispatch, getState) => {
        dispatch(adminCustomerSubscriptionList.invalidateList())
        dispatch(adminCustomerSubscriptionForCustomerList.invalidateList())
        dispatch(adminCustomerSubscriptionForMigrationList.invalidateList())
        dispatch(adminCustomerSubscriptionFullList.invalidateList())
    }
}
