/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { BlueButton } from './layout/BlueButton'
import { WhiteButton } from './layout/WhiteButton'
import { jsx, css } from '@emotion/react'
import { Separator } from './layout/Separator'
import { default_theme as theme } from '../emotion/theme'
import { phoneNumberRedirectServerSyncLogList } from '../actions/phone_number_redirect_server_sync_log'
import { Container, Row, Col } from 'react-bootstrap'
import Timestamp from './Timestamp'
import { InlineIcon } from './layout/InlineIcon'
import JSONPretty from 'react-json-pretty'
import 'react-json-pretty/themes/monikai.css'

const REFRESH_INTERVAL_MILLISECONDS=10000

export const PhoneNumberRedirectServerSyncLog = ({customer_subscription_id}) => {

    const dispatch = useDispatch()
    const sync_log = useSelector(() => phoneNumberRedirectServerSyncLogList.getForSubscription({customer_subscription_id}))
    const [jsonVisible, setJsonVisible] = useState(false)
    
    useEffect(() => {
        return dispatch(phoneNumberRedirectServerSyncLogList.loadForSubscription({customer_subscription_id}))
    }, [customer_subscription_id])

    useEffect(() => {
        const intervalId = window.setInterval(() => {
            dispatch(phoneNumberRedirectServerSyncLogList.reloadForSubscription({customer_subscription_id}))
        }, REFRESH_INTERVAL_MILLISECONDS)

        return () => window.clearInterval(intervalId)
        
    }, [])
    
    const onRefresh = () => {
        return dispatch(phoneNumberRedirectServerSyncLogList.reloadForSubscription({customer_subscription_id}))
    }

    const showJson = () => {
        setJsonVisible(true)
    }

    const hideJson = () => {
        setJsonVisible(false)
    }
    
    return (

        <div css={container_style}>

          <div css={info_row_style}>
            Sync status for this subscription (as of <Separator variant="w5" /><Timestamp value={get(sync_log, "refreshed_at")} />): 

            <Separator variant="w10" />
            
            { get(sync_log, ["totals", "num_pending"]) === 0 && <InlineIcon icon_name="tick" variant="green" /> }
            { get(sync_log, ["totals", "num_pending"]) > 0 && <InlineIcon icon_name="cross" variant="red" /> }
          </div>
          
          <BlueButton onClick={onRefresh} auto_disable={false}>
            Re-check status
          </BlueButton>

          <Separator variant="w10" />
          
          { ! jsonVisible &&
            <WhiteButton onClick={showJson} auto_disable={false}>
              Show synchronisation details
            </WhiteButton>
          }

          { jsonVisible &&
            <>
              <WhiteButton onClick={hideJson} auto_disable={false}>
                Hide synchronisation details
              </WhiteButton>
              <JSONPretty data={sync_log} />
            </>
          }
          
        </div>
    )
    
}


const container_style = css`
padding-bottom: 50px;
border-bottom: 1px solid #000000;
margin-bottom: 20px;
`

const info_row_style = css`
display: flex;
`
