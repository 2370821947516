/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/react'
import { get, map, size, values } from 'lodash'
import { default_theme as theme } from '../../emotion/theme'
import { Separator } from '../../components/layout/Separator'
import Card from '../../components/layout/Card'
import FieldInfoText from '../../components/layout/FieldInfoText'

export default class AdminAssociatedProductList extends Component {

    render() {

        const { products, label } = this.props
        
        return (
            <Card variant="margin_top">
              Products associated with this {label}
              <FieldInfoText>
                You cannot delete a {label} when it is in use by a product.
              </FieldInfoText>

              <Separator variant="h10" />
              { size(products) === 0 &&
                <div>
                  No products
                </div>
              }
              <div>
                {map(products, (product) => (
                    <div>
                      {product.name}
                      <Separator variant="h5" />
                    </div>
                ))}
              </div>
            </Card>
        )
        
    }

}
