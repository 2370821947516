/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { map, get, remove, includes, size, tail, slice, last } from 'lodash'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../emotion/theme'
import MainLayout from './MainLayout'
import CommonTable from './CommonTable'
import { confirmModal } from '../actions/ui'
import { VoiceDetailsModal } from './VoiceDetailsModal'
import { Trans } from 'react-i18next'
import { Duration } from './Duration'
import { customerList } from '../actions/customer'
import { blockedNumberList } from '../actions/blocked_number'
import { Container, Navbar, Nav, Col, Row } from 'react-bootstrap'
import WrappingBusyMask from './WrappingBusyMask'
import Timestamp from './Timestamp'
import { PhoneNumber } from './PhoneNumber'
import { Modal } from 'react-bootstrap'
import { handleSubmitResult } from '../actions/form'
import { InlineIcon } from './layout/InlineIcon'
import { InlineIconBar } from './layout/InlineIconBar'
import { cdrTypes } from '../actions/dropdown_options'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import {showSuccess, showError} from '../actions/Error'

class VoiceTraffic extends Component {

    constructor(props) {
        super(props)
        this.state = { showing_voice_item_details: null }
    }

    componentDidMount() {
        const { dispatch, cdrList } = this.props
        dispatch(cdrList.fetchListIfNeeded())
        dispatch(customerList.ensureCustomerLoaded())
        dispatch(cdrTypes.updatePaginationNumItemsPerPage(1000))
        dispatch(cdrTypes.fetchListIfNeeded())
        dispatch(blockedNumberList.updatePaginationNumItemsPerPage(1000))
        dispatch(blockedNumberList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch, cdrList } = this.props
        dispatch(cdrList.fetchListIfNeeded())
    }
    
    onShowItemDetails = (item) => {
        const { dispatch, cdrList } = this.props
        dispatch(cdrList.invalidateObject(item.id))
        dispatch(cdrList.ensureObjectLoaded(item.id))
        this.setState({showing_voice_item_details: item})
    }
    
    onHideItemDetails = (item) => {
        this.setState({showing_voice_item_details: null})
    }

    onBlockNumber = ({cdr}) => {
        const { dispatch, customer } = this.props

        const source_number = get(cdr, ["source_number"])
        const source_number_id = get(cdr, ["source_phone_number"])
        const destination_number = get(cdr, ["destination_number"])
        const destination_number_id = get(cdr, ["destination_phone_number"])
        const customer_subscription_id = get(cdr, 'customer_subscription')

        const on_ok = (res) => {
            showSuccess("Number blocked")
            dispatch(blockedNumberList.invalidateList())
            dispatch(blockedNumberList.fetchListIfNeeded())
        }

        const onConfirmed = () => {
            dispatch(blockedNumberList.blockNumber({phone_number_id: source_number_id,
                                                    subscription_id: customer_subscription_id}))
                .then((res) => handleSubmitResult({res, on_ok}))
            
        }
        
        const heading = (
            <Trans>Block number</Trans>
        )

        const text = (
            <div>
              <b>
                <Trans i18nKey="voice_traffic__block_from_calling"
                       values={{source_number: source_number,
                                destination_number: destination_number}} 
                       components={[<PhoneNumber phone_number={source_number} enable_inline_spacing={true} />,
                                    <PhoneNumber phone_number={destination_number} enable_inline_spacing={true} />]}
                       defaults="Block <0></0> from calling <1></1>"
                />
              </b>
              <br/>
              <b><Trans>Are you sure?</Trans></b>
              <br/>
              <div css={you_can_always_style}>
                <Trans i18nKey="voice_traffic__you_can_always_unblock"
                       values={{source_number: source_number,
                                destination_number: destination_number}} 
                       components={[<PhoneNumber phone_number={destination_number} enable_inline_spacing={true} />]}
                       defaults="You can always unblock in the call plan of <0></0>"
                />
              </div>
            </div>
        )

        const confirm_text = "Yes, block this number"
        const cancel_text = "No, keep receiving calls"
        
        dispatch(confirmModal({text,
                               heading,
                               confirm_text,
                               cancel_text,
                               onConfirmed: onConfirmed}))
        
    }
    
    onUnblockNumber = ({cdr, blocked_number}) => {
        const { dispatch, customer } = this.props
        const source_number = get(cdr, ["source_number"])
        const source_number_id = get(cdr, ["source_phone_number"])
        const destination_number = get(cdr, ["destination_number"])
        const destination_number_id = get(cdr, ["destination_phone_number"])
        const customer_subscription_id = get(cdr, 'customer_subscription')

        const on_ok = (res) => {
            showSuccess("Number unblocked")
        }

        const onConfirmed = () => {
            dispatch(blockedNumberList.unblockNumber({blocked_number_id: blocked_number.id}))
                .then((res) => handleSubmitResult({res, on_ok}))
        }
        
        const heading = (
            <Trans>Unblock number</Trans>
        )

        const text = (
            <div>
              <b>
                <Trans i18nKey="voice_traffic__are_you_sure_you_want_to_allow"
                       values={{source_number: source_number,
                                destination_number: destination_number}} 
                       components={[<PhoneNumber phone_number={source_number} enable_inline_spacing={true} />,
                                    <PhoneNumber phone_number={destination_number} enable_inline_spacing={true} />]}
                       defaults="Are you sure you want to allow <0></0> to call <1></1> again?"
                />
              </b>
              <br/>
            </div>
        )

        const confirm_text = "Yes, unblock this number"
        const cancel_text = "No, keep blocking"
        dispatch(confirmModal({text:text,
                               heading: heading,
                               confirm_text,
                               cancel_text,
                               onConfirmed: onConfirmed}))
        
    }
    
    renderOutgoingVoiceQueueItemId(voice_queue_item) {
        return (
            <div key={voice_queue_item.id}>
              {voice_queue_item.short_id}
            </div>
        )
    }

    getRowStyle = (item) => {
        const cdr = item
        const source_number_id = get(cdr, ["source_phone_number"])
        const customer_subscription_id = get(item, 'customer_subscription')
        const blockedPhoneNumber = blockedNumberList.tryGetBlockedPhoneNumber({phone_number_id: source_number_id,
                                                                               customer_subscription_id})
        const isBlocked = Boolean(blockedPhoneNumber)
        if ( ! isBlocked ) {
            return ban_disabled_row_style
        }
    }
    
    getVoiceCellValue = (header_key, item, index) => {
        const { customer_id, cdr_types } = this.props
        const cdr = item
        let display_name = null
        switch( header_key ) {
            case 'start_datetime':
                return (
                    <Timestamp value={cdr.start_datetime} format="dateshort-time" />
                )
            case 'duration_seconds':
                return (
                    <Duration seconds={cdr.duration_seconds} />
                )
            case 'source':

                const source_number_id = get(cdr, ["source_phone_number"])
                const customer_subscription_id = get(cdr, 'customer_subscription')
                const blockedPhoneNumber = blockedNumberList.tryGetBlockedPhoneNumber({phone_number_id: source_number_id,
                                                                                       customer_subscription_id}) 
                const isBlocked = Boolean(blockedPhoneNumber)
                
                return (
                    <div css={source_number_style}>
                      <PhoneNumber phone_number={get(cdr, ["source_number"], "Unknown")} />

                      { isBlocked &&
                        <span data-ban-icon-type="blocked" >
                          <FontAwesomeIcon icon={faBan} css={ban_enabled_style} onClick={() => this.onUnblockNumber({cdr: cdr, blocked_number: blockedPhoneNumber})} />
                        </span>
                      }
                      { customer_subscription_id && !isBlocked &&
                        <span data-ban-icon-type="unblocked" >
                          <FontAwesomeIcon icon={faBan} css={ban_disabled_style} onClick={() => this.onBlockNumber({cdr: cdr})} />
                        </span>
                      }
                    </div>
                )
            case 'destination':
                return (
                    <PhoneNumber phone_number={get(cdr, ["destination_number"], "Unknown")} />
                )
            case 'forwarded_to':
                switch(cdr.cdr_type) {
                    case "voice_message":
                        return (
                            <div css={cell_row_style}>
                              <InlineIcon icon_name="voice_message" />
                              {get(cdr, ["incoming_voice_message", "voice_message_sent_to_email_address"])}
                            </div>
                        )
                    case "forwarded":
                        return (
                            <div css={cell_row_style}>
                              <InlineIcon icon_name="phone" />
                              <PhoneNumber phone_number={get(cdr, "final_number")} />
                            </div>
                        )
                    case "immediate_hangup":
                        return (
                            <div css={cell_row_style}>
                              <InlineIcon icon_name="phoneslash" />
                              <Trans>Hung up</Trans>
                            </div>
                        )
                    default:
                        return get(cdr_types, [cdr.cdr_type, "name"], cdr.cdr_type)
                }
            // case 'action':
            //     return (
            //         <div css={action_inner_style}>
            //           <InlineIcon icon_name="info" size_variant="h16" variant="blue" onClick={() => this.onShowCdrDetails(cdr)} />
            //         </div>
            //     )
            default:
                return undefined
        }
    }

    renderCustomerNotFullyMigratedMessage() {
        return (
            <div>
              <Trans>While we migrate your account data, yesterday's calls may not show up here yet. Please check back tomorrow.</Trans>
            </div>
        )
    }
    
    render() {
        const { is_ready, is_loading, voice_headers, cdrs, cdrList, migration_from_legacy_in_progress } = this.props
        const { showing_voice_item_details } = this.state
        return (
            <div>
              <WrappingBusyMask is_loading={!is_ready}>
                <CommonTable is_loading={ is_loading }
                             empty_message={`There are no matching calls`}
                             headers={ voice_headers }
                             items={Object.assign([], cdrs)}
                             item_list={cdrList}
                             sub_header_message={migration_from_legacy_in_progress ? this.renderCustomerNotFullyMigratedMessage() : ""}
                             getCellValue={ this.getVoiceCellValue }
                             getRowStyle={ this.getRowStyle }
                />
              </WrappingBusyMask>
              { showing_voice_item_details &&
                <VoiceDetailsModal cdrList={cdrList}
                                   cdr_id={showing_voice_item_details.id}
                                   onClose={this.onHideItemDetails} />
              } 
            </div>
        )
    }
    
}


function mapStateToProps(state, props) {

    const { cdrList, voice_headers } = props
    const cdrs = cdrList.getVisibleObjects()
    const customer = customerList.getCustomer()
    const migration_from_legacy_in_progress = get(customer, "migration_from_legacy_in_progress", false)
    const cdr_types = cdrTypes.getObjectsById()
    
    return {
        cdrs,
        cdr_types,
        voice_headers: voice_headers || {
            start_datetime: { name: <Trans>Date/Time</Trans>, td_style: start_datetime_cell_style },
            duration_seconds: { name: <Trans>Duration</Trans>, td_style: duration_cell_style },
            source: { name: <Trans>From</Trans>, td_style: from_cell_style },
            destination: { name: <Trans>To</Trans>, td_style: to_cell_style },
            forwarded_to: { name: <Trans>Forwarded to</Trans>, td_style: to_cell_style },
            // action: { name: '', td_style: action_style },
        },
        migration_from_legacy_in_progress,
        is_ready: cdrList.isReady(),
        is_loading: cdrList.isLoading()
    }
}

export default withRouter(connect(mapStateToProps)(VoiceTraffic))

const filename_field_style = css`
`

const start_datetime_cell_style = css`
vertical-align: top;
width: 150px;
`

const from_cell_style = css`
vertical-align: top;
width: 15%;
`

const duration_cell_style = css`
vertical-align: top;
width: 15%;
`

const to_cell_style = css`
vertical-align: top;
width: 15%;
`

const action_style = css`
vertical-align: top;
width: 20px;
`

const action_inner_style = css`
margin-top: 3px;
`

const cell_row_style = css`
display: flex;
align-items: center;
`

const ban_disabled_row_style = css`
&[data-ban-icon-type="unblocked"] {
    color: ${theme.colors.dark_red};
}
`

const ban_disabled_style = css`
color: ${theme.colors.light_grey};
&:hover {
    cursor: pointer;
    color: ${theme.colors.dark_red};
}
`

const ban_enabled_style = css`
color: ${theme.colors.dark_red};
&:hover {
    cursor: pointer;
}
`

const you_can_always_style = css`
color: ${theme.colors.gray1};
`

const source_number_style = css`
display: flex;
justify-content: space-between;
`
