/** @jsxImportSource @emotion/react */
import React from 'react'
import { default_theme as theme } from '../emotion/theme'
import { jsx, css } from '@emotion/react'
import { get } from 'lodash'
import { Trans } from 'react-i18next'
import { globalSettingsList } from '../actions/settings'
import { getBestLanguageCode } from '../actions/language'
import { GrayLinkButton } from './layout/GrayLinkButton'

const Footer = () => {
  const external_info_urls = globalSettingsList.getSetting('external_info_urls')
  const helpdesk_email = globalSettingsList.getSetting('helpdesk_email')
  const helpdesk_phone = globalSettingsList.getSetting('helpdesk_phone')

  const openExternalLink = (key) => {
    const url = get(external_info_urls, [key, getBestLanguageCode()])
    if (url) {
      window.open(url, '_blank')
    }
  }

  return (
    <div css={footer_container}>
      <div css={footer_columns}>
        <div>
          <div css={column_header}>XOIP</div>
          <div>
            <GrayLinkButton
              extraCss={footer_link}
              onClick={() => openExternalLink('xoip_home')}
              auto_disable={false}
            >
              <Trans>Home</Trans>
            </GrayLinkButton>

            <GrayLinkButton
              extraCss={footer_link}
              onClick={() => openExternalLink('about_us')}
              auto_disable={false}
            >
              <Trans>About</Trans>
            </GrayLinkButton>
          </div>
        </div>

        <div>
          <div css={column_header}>
            <Trans>Products</Trans>
          </div>
          <div>
            <GrayLinkButton
              extraCss={footer_link}
              onClick={() => openExternalLink('voice')}
              auto_disable={false}
            >
              <Trans>Voice</Trans>
            </GrayLinkButton>

            <GrayLinkButton
              extraCss={footer_link}
              onClick={() => openExternalLink('fax')}
              auto_disable={false}
            >
              <Trans>Fax</Trans>
            </GrayLinkButton>

            <GrayLinkButton
              extraCss={footer_link}
              onClick={() => openExternalLink('pricing')}
              auto_disable={false}
            >
              <Trans>Pricing & Rates</Trans>
            </GrayLinkButton>
          </div>
        </div>

        <div>
          <div css={column_header}>Support</div>
          <div>
            <GrayLinkButton
              extraCss={footer_link}
              onClick={() => openExternalLink('support')}
              auto_disable={false}
            >
              <Trans>Support Pages & FAQ</Trans>
            </GrayLinkButton>
            <div css={footer_link}>
              <span css={footer_link_label}>
                <Trans>Email</Trans>
              </span>
              <span>
                <a href={`mailto:${helpdesk_email}`}>{helpdesk_email}</a>
              </span>
            </div>
            <div>
              <span css={footer_link_label}>
                <Trans>Call</Trans>
              </span>
              <span>{helpdesk_phone}</span>
            </div>
          </div>
        </div>
      </div>
      <div css={footer_bottom}>
        <div>&copy; 2019 XOIP</div>
        &middot;
        <GrayLinkButton
          extraCss={footer_link}
          onClick={() => openExternalLink('terms_and_conditions')}
          auto_disable={false}
        >
          <Trans>Service Agreement</Trans>
        </GrayLinkButton>
        &middot;
        <GrayLinkButton
          extraCss={footer_link}
          onClick={() => openExternalLink('privacy_policy')}
          auto_disable={false}
        >
          <Trans>Privacy Policy</Trans>
        </GrayLinkButton>
        &middot;
        <GrayLinkButton
          extraCss={footer_link}
          onClick={() => openExternalLink('data_processing_agreement')}
          auto_disable={false}
        >
          <Trans>Data Processing Agreement</Trans>
        </GrayLinkButton>
      </div>
    </div>
  )
}

export default Footer

const footer_container = css`
  font-size: 14px;
  color: ${theme.colors.dark_grey};
  padding: 36px 20% 16px;
  background: ${theme.colors.white};
`
const footer_columns = css`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 36px;
`
const footer_bottom = css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
`

const column_header = css`
  font-size: 16px;
  font-weight: 600;
`
const footer_link = css`
  font-size: 14px;
  color: ${theme.colors.dark_grey};
`
const footer_link_label = css`
  margin-right: 4px;
`
