/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import WrappingBusyMask from './WrappingBusyMask'
import OutgoingFaxItemDetails from './OutgoingFaxItemDetails'
import IncomingFaxItemDetails from './IncomingFaxItemDetails'
import { Trans } from 'react-i18next'

export const FaxItemDetailsModal = ({fax_item_id, faxItemList, onClose}) => {

    const dispatch = useDispatch()
    const fax_item = useSelector(() => faxItemList.getObject(fax_item_id))
    
    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(faxItemList.ensureObjectLoaded(fax_item))
        }
        fetchStaticObjects()
    }, [fax_item_id])

    return (
        <Modal show={true}
               size="lg"
               onHide={onClose} >

          <WrappingBusyMask is_loading={faxItemList.isLoading()}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Trans>Fax job details</Trans> : {fax_item.short_id}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              { fax_item.direction === "outgoing" && <OutgoingFaxItemDetails fax_item_id={fax_item_id} /> }
              { fax_item.direction !== "outgoing" && <IncomingFaxItemDetails fax_item_id={fax_item_id} /> }
            </Modal.Body>
          </WrappingBusyMask>
          
        </Modal>
    )
    
}
