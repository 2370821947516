/** @jsxImportSource @emotion/react */
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { jsx, css, Global } from '@emotion/react'
import { get } from 'lodash'
import { default_theme as theme } from '../emotion/theme'
import { clearAuthentication, loggedInUser } from '../actions/auth'
import { customerList } from '../actions/customer'

const ProfileMenuContent = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const customer = customerList.getCustomer()

  const onLogout = () => {
    dispatch(clearAuthentication())
    history.push('/')
  }

  return (
    <div>
      <div css={link_list}>
        <div css={profile_menu_item}>
          <Link to="/">
            <Trans i18nKey={'menu_link_to_home'}>Numbers and Traffic</Trans>
          </Link>
        </div>
        <div css={profile_menu_item}>
          <Link to="/profile">
            <Trans>Account</Trans>
          </Link>
        </div>
        <div css={profile_menu_item}>
          <Link to="/subscriptions">
            <Trans>
              <Trans>Subscriptions</Trans>
            </Trans>
          </Link>
        </div>
        {get(customer, 'has_any_prepaid_subscriptions', true) && (
          <div css={profile_menu_item}>
            <Link to="/invoices">
              <Trans>Invoices</Trans>
            </Link>
          </div>
        )}
        <div css={profile_menu_item}>
          <Link to="/transactions">
            <Trans>Credit history</Trans>
          </Link>
        </div>
        <div css={profile_menu_item}>
          <Link to="" onClick={() => onLogout()}>
            <Trans>Log out</Trans>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ProfileMenuContent

const link_list = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const profile_menu_item = css`
  color: ${theme.colors.black};
  font-size: 16px;
  a:hover {
    color: ${theme.colors.primary} !important;
  }
`
