/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx } from '@emotion/react'
import { head, get } from 'lodash'
import {showSuccess, showError} from '../../actions/Error'
import { adminUserList } from '../actions/admin_user'
import { Link } from 'react-router-dom'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import { adminAsteriskServerLogList, adminAsteriskServerLogRequestor } from '../actions/admin_asterisk_server_log'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { Button, Container } from 'react-bootstrap'

class AdminAsteriskServerLogs extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminAsteriskServerLogList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(adminAsteriskServerLogList.fetchListIfNeeded())
    }

    onGotoServer = (server) => {
        const { history } = this.props
        history.push(`/admin/asterisk_server_log/${server.id}`)
    }

    onRequestNewData = (server) => {
        const { history, dispatch } = this.props
        const that = this
        const on_ok = function(json) {
            dispatch(adminAsteriskServerLogList.invalidateList())
            showSuccess("Saved", "Data requested")
        }
        return dispatch(adminAsteriskServerLogRequestor.requestNewData(server.server_name)).then(on_ok)
    }

    getCellValue = (header_key, item, index) => {
        const that = this
        const { customer_id } = this.props
        switch( header_key ) {
            case 'request_active':
                return item.request_active ? "Requested data..." : ""
            case 'last_update_at':
                return <Timestamp value={item.last_update_at} format="datetime" />
            case 'code_version':
                return <pre>{item.code_version}</pre>
            case 'action':
                return (
                    <div>
                      <Button onClick={() => that.onGotoServer(item)}>
                        Open
                      </Button>
                    </div>
                )
            default:
                return undefined
        }
    }

    render() {
        const { is_loading, faxes, headers, history } = this.props
        return (
            <AdminMainLayout title='Asterisk Server Logs'
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'asterisk_server_logs', label: 'Asterisk server logs', url: '/admin/asterisk_server_logs'}]}>
              <Container fluid>

                <CommonTable
                    is_loading={ is_loading }
                    empty_message={`No servers`}
                    headers={ headers }
                    items={ faxes }
                    item_list={adminAsteriskServerLogList}
                    getCellValue={ this.getCellValue }
                />
              </Container>
            </AdminMainLayout>
            
        )
    }
    
}

function mapStateToProps(state, props) {
    const faxes = adminAsteriskServerLogList.getVisibleObjects()
    return {
        is_loading: adminAsteriskServerLogList.isLoading(),
        faxes,
        headers: {
            server_name: { name: 'Server name' },
            request_active: { name: 'Request active' },
            last_update_at: { name: 'Last updated' },
            code_version: { name: 'Version' },
            action: { name: '' },
        }
    }
}

export default connect(mapStateToProps)(AdminAsteriskServerLogs)
