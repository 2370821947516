/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { forEach, head, get, map, keys, split, size } from 'lodash'
import { adminTopupList } from '../actions/admin_topup'
import { CardHeader } from '../../components/layout/CardHeader'
import { LanguageFlag } from '../../components/LanguageFlag'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { Separator } from '../../components/layout/Separator'
import { Formik, Form, FieldArray, Field } from 'formik'
import Loading from '../../components/Loading'
import { FormikTextarea } from '../../components/form/TextareaField'
import { FormikInputField } from '../../components/form/InputField'
import { MultilingualField } from '../../components/form/MultilingualField'
import AdminMainLayout from './AdminMainLayout'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import Card from '../../components/layout/Card'
import { Col, Row, Container, Navbar, Nav } from 'react-bootstrap'
import { Form as BootstrapForm } from 'react-bootstrap'
import * as Yup from 'yup'
import {showSuccess, showError} from '../../actions/Error'
import Button from 'react-bootstrap/Button'
import { BlueButton } from '../../components/layout/BlueButton'
import CardInfoText from '../../components/layout/CardInfoText'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { handleSubmitResult } from '../../actions/form'
import { LANGUAGES } from '../../actions/language'

const yup_shape = {
    'name': Yup.string().required("Required"),
    'amount_excluding_vat_euros': Yup.number().required("Required").typeError("Must be euros"),
    'estimated_num_fax_pages': Yup.number().required("Required").typeError("Must be a number"),
    'estimated_num_minutes_of_forwarded_calls': Yup.number().required("Required").typeError("Must be a number")
}
forEach(LANGUAGES, ({language_code, language_name}) => {
    yup_shape[`description_on_invoice_${language_code}`] = Yup.string().required("Required")
})
const validationSchema = Yup.object().shape(yup_shape)

class AdminTopup extends Component {

    componentDidMount() {
        const { dispatch, topup_id } = this.props
        dispatch(adminTopupList.ensureObjectLoaded(topup_id))
    }

    componentDidUpdate(prev_props) {
        const { dispatch, topup_id } = this.props
        dispatch(adminTopupList.ensureObjectLoaded(topup_id))
    }

    onSave = (values, formik_funcs) => {
        const { history, onSubmit, dispatch, topup_id } = this.props

        const on_ok = function(json) {
            dispatch(adminTopupList.invalidateList())
            showSuccess("Saved", "Topup saved")

            if ( ! topup_id ) {
                history.push(`/admin/topup/${json.id}`)
            }
        }
        if ( topup_id ) {
            values.id = topup_id
            return dispatch(adminTopupList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminTopupList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }
    
    getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            default:
                return undefined
        }
    }

    renderForm(formik_props) {

        return (
            <div>
              <Row>
                <Col>
                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Internal name</BootstrapForm.Label>
                    <FormikInputField name="name" placeholder="name" />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group>
                    <FormikCheckboxField
                        name="is_active"
                        label="Active"
                    />
                  </BootstrapForm.Group>
                  
                  <BootstrapForm.Group>
                    <MultilingualField label="Description shown on customer invoice" name="description_on_invoice" render_component={({name}) =>
                        <FormikInputField name={name} place_holder="Invoice description" />} />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Amount (ex VAT, Euros)</BootstrapForm.Label>
                    <FormikInputField name="amount_excluding_vat_euros" place_holder="Amount (Euros)" />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Estimated number of fax pages</BootstrapForm.Label>
                    <FormikInputField name="estimated_num_fax_pages" place_holder="Num faxes" />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Estimated number of forwarded voice calls</BootstrapForm.Label>
                    <FormikInputField name="estimated_num_minutes_of_forwarded_calls" place_holder="Num voice calls" />
                  </BootstrapForm.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <BlueButton type="submit">Save</BlueButton>
                </Col>
              </Row>
            </div>
        )
    }

    render() {
        const { initial_values, is_loading, is_busy, topup, topup_id } = this.props
        const that = this
        const title = `${get(topup, ["name"], 'New topup')}`

        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           
                                           {name: 'topups',
                                            label: 'Topups',
                                            url: '/admin/topups'},
                                           
                                           {name: 'topup',
                                            label: (
                                                <div css={breadcrumb_item}>
                                                  {get(topup, ["name"], 'New topup')}
                                                </div>
                                            ),
                                            url: `/admin/topup/${topup_id}`
                                           }
            ]}>
              <Container fluid>
                { is_busy && <BusyMask /> }
                { is_loading && <Loading /> }

                <CardHeader title={title} />
                <Separator variant="h30" />
                
                { ! is_loading && 
                  
                  <Formik
                      initialValues={initial_values}
                      onSubmit={that.onSave}
                      enableReinitialize={true}
                      validationSchema={validationSchema}
                      >
                    {formik_props => {
                         const { values } = formik_props
                         return (
                             <Form>
                               <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                               <Row>
                                 <Col>
                                   <Card>
                                     { that.renderForm(formik_props) }
                                   </Card>
                                 </Col>
                               </Row>
                             </Form>
                         )}
                    }
                  </Formik>
                }
              </Container>
            </AdminMainLayout>
        )
    }
    
}

function mapStateToProps(state, props) {
    const topup_id = get(props, ["match", "params", "topup_id"], null)
    const topup = adminTopupList.getObject(topup_id)
    return {
        topup_id,
        topup,
        is_loading: adminTopupList.isLoading(),
        is_busy: adminTopupList.getIsSavingObject(),
        initial_values: topup,
    }
}

export default connect(mapStateToProps)(AdminTopup)

const breadcrumb_item = css`
display: flex;
align-items: center;
`
