/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { jsx, css,  Global } from '@emotion/react'
import { connect } from 'react-redux'
import { sortBy, size, head, get, map, keys, slice } from 'lodash'
import {showSuccess, showError} from '../../actions/Error'
import Loading from '../../components/Loading'
import { PhoneNumber } from '../../components/PhoneNumber'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { BlueButton } from '../../components/layout/BlueButton'
import { Row, Col, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import Card from '../../components/layout/Card'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import { adminAsteriskRoutingLookupList } from '../actions/admin_asterisk_routing_lookup'
import { Button, Container } from 'react-bootstrap'
import * as Yup from 'yup'
import { Separator } from '../../components/layout/Separator'
import FieldInfoText from '../../components/layout/FieldInfoText'

const WIDTH_LEFT = 4

class AdminAsteriskRoutingLookup extends Component {

    componentDidMount() {
        const { dispatch, asterisk_routing_lookup_id } = this.props
        dispatch(adminAsteriskRoutingLookupList.ensureObjectLoaded(asterisk_routing_lookup_id))
    }

    componentDidUpdate(prev_props) {
        const { dispatch, asterisk_routing_lookup_id } = this.props
        dispatch(adminAsteriskRoutingLookupList.ensureObjectLoaded(asterisk_routing_lookup_id))
    }

    renderUnacknowledgedServer(server_name, index) {

        const title = (
            <h2>
              {server_name}
              <InlineIcon icon_name="status-waiting-input" />
            </h2>
        )
        
        return (
            <div key={index}>
              <Card variant="white" title={title}>
                <div>Waiting for server to acknowledge this request</div>
              </Card>
            </div>
        )
    }

    renderTableResponse = (data) => {

        return (
            <div>
              {map(data, (data_row, index) => (
                  <div key={index}>
                    {map(sortBy(keys(data_row)), function(header, index) {
                        return (
                            <Row key={index}>
                              <Col md={WIDTH_LEFT}>{header}</Col>
                              <Col>{data_row[header]}</Col>
                            </Row>
                        )
                    })}
                    <Separator variant="h20" />
                  </div>
              ))}
            </div>
        )
    }
    
    renderResponse = (response, index) => {

        const that = this
        
        const title = (
            <h2>
              {response.server_name}
              { response.response_received_at && <InlineIcon icon_name="status-success" /> }
              { ! response.response_received_at && <InlineIcon icon_name="status-in-progress" /> }
            </h2>
        )
        
        return (
            <div key={index}>
              <Card variant="white" title={title}>

                { ! response.response_received_at &&
                  <div>
                    Waiting for a response from the server
                    <Separator variant="h10" />
                  </div>
                }
                
                { response.created_at &&
                  <>
                    <Row>
                      <Col md={WIDTH_LEFT}>
                        This request for information was sent to asterisk at
                      </Col>
                      <Col>
                        { response.created_at && <Timestamp value={response.created_at} /> }
                      </Col>
                    </Row>

                    <Separator variant="h10" />
                  </>
                }

                { response.response_received_at &&
                  <>
                    <Row>
                      <Col md={WIDTH_LEFT}>
                        This information was received from asterisk at
                      </Col>
                      <Col>
                        { response.response_received_at && <Timestamp value={response.response_received_at} /> }
                        { ! response.response_received_at && <Timestamp value={response.response_received_at} /> }
                      </Col>
                    </Row>

                    <Separator variant="h10" />
                    
                    <Row>
                      <Col md={WIDTH_LEFT}>
                        Mapping (takes precedence over redirect)
                      </Col>
                      <Col>
                        {that.renderTableResponse(response.mapping)}
                      </Col>
                    </Row>

                    <Row>
                      <Col md={WIDTH_LEFT}>
                        Redirect
                      </Col>
                      <Col>
                        {that.renderTableResponse(response.redirect)}
                      </Col>
                    </Row>

                    <Separator variant="h10" />
                    
                    <Row>
                      <Col md={WIDTH_LEFT}>
                        Recent CDRs
                      </Col>
                      <Col>
                        {that.renderTableResponse(response.recent_incoming_cdrs)}
                      </Col>
                    </Row>

                    <Row>
                      <Col md={WIDTH_LEFT}>
                        Recent incoming faxes
                      </Col>
                      <Col>
                        {that.renderTableResponse(response.recent_incoming_faxes)}
                      </Col>
                    </Row>

                    <Row>
                      <Col md={WIDTH_LEFT}>
                        Recent incoming voice messages
                      </Col>
                      <Col>
                        {that.renderTableResponse(response.recent_incoming_voice_messages)}
                      </Col>
                    </Row>
                    
                    <Separator variant="h10" />
                  </>
                }
                
              </Card>
            </div>
        )
        
    }

    renderJoomlaSyncLog() {
        const { asterisk_routing_lookup } = this.props
        const sync_log = get(asterisk_routing_lookup, "most_recent_joomla_sync_log")
        return (
            <Card variant="white">
              <h2>Last sync to Joomla</h2>

              { ! get(asterisk_routing_lookup, "base_lookup_data_fetched") && <div>Waiting for sync log data...</div> }

              { get(asterisk_routing_lookup, "base_lookup_data_fetched") &&
                <>
                  
                  { ! sync_log && <div>There are no sync logs</div>}

                  { sync_log && (
                      <>
                        <Row>
                          <Col md={WIDTH_LEFT}>
                            Created at
                          </Col>
                          <Col>
                            { <Timestamp value={sync_log.created_at} /> }
                          </Col>
                        </Row>
                        <Row>
                          <Col md={WIDTH_LEFT}>
                            Customer email
                          </Col>
                          <Col>
                            {sync_log.customer_email }
                          </Col>
                        </Row>
                        <Row>
                          <Col md={WIDTH_LEFT}>
                            Status
                          </Col>
                          <Col>
                            {sync_log.status }
                          </Col>
                        </Row>
                        <Row>
                          <Col md={WIDTH_LEFT}>
                            Description
                          </Col>
                          <Col>
                            {sync_log.description }
                          </Col>
                        </Row>
                        <Row>
                          <Col md={WIDTH_LEFT}>
                            Extended note
                          </Col>
                          <Col>
                            {sync_log.extended_note }
                          </Col>
                        </Row>
                      </>
                  )}
                </>
              }
            </Card>
        )
    }

    renderRoutingImage() {
        const { asterisk_routing_lookup } = this.props
        if ( ! asterisk_routing_lookup.routing_image ) {
            return null
        }
        return (
            <Card variant="white">
              <h2>Inter server routing</h2>

              <div dangerouslySetInnerHTML={{ __html: atob(asterisk_routing_lookup.routing_image) }} />
            </Card>
        )
    }

    renderSubscription() {
        const { asterisk_routing_lookup } = this.props
        const subscription = get(asterisk_routing_lookup, "in_use_by_customer_subscription")
        return (
            <Card variant="white">
              <h2>Active subscription</h2>

              { ! get(asterisk_routing_lookup, "base_lookup_data_fetched") && <div>Waiting for subscription data...</div> }
              
              { get(asterisk_routing_lookup, "base_lookup_data_fetched") &&
                <>
                  { ! subscription && <div>There are no active subscriptions for this number</div>}

                  { subscription && (
                      <>
                        <Row>
                          <Col md={WIDTH_LEFT}>
                            Name
                          </Col>
                          <Col>
                            {subscription.customer_display_name }
                          </Col>
                        </Row>
                        <Row>
                          <Col md={WIDTH_LEFT}>
                            Email
                          </Col>
                          <Col>
                            {subscription.customer_email }
                          </Col>
                        </Row>
                      </>
                  )}
                </>
              }
            </Card>
        )
    }

    render() {
        const { is_loading, asterisk_routing_lookup, asterisk_routing_lookup_id } = this.props

        const title = (
            <h2>
              Details
              
              { asterisk_routing_lookup.status === 'collecting' && <InlineIcon icon_name="status-in-progress" /> }
              { asterisk_routing_lookup.status === 'cancelled' && <InlineIcon icon_name="status-expired" /> }
              { asterisk_routing_lookup.status === 'done' && <InlineIcon icon_name="status-success" /> }
            </h2>
        )
        
        return (
            <AdminMainLayout title={<span>Asterisk Routing Lookup for <PhoneNumber phone_number={get(asterisk_routing_lookup, "number")} /></span>}
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'asterisk_routing_lookups', label: 'Asterisk routing lookups', url: '/admin/asterisk_routing_lookups'},
                                           {name: 'asterisk_routing_lookup', label: <PhoneNumber phone_number={get(asterisk_routing_lookup, "number")} />, url: `/admin/asterisk_routing_lookup/${asterisk_routing_lookup_id}`}]}>
              <Container fluid>

                <Card variant="white" title={title}>
                  <Row>
                    <Col md={WIDTH_LEFT}>
                      Status
                    </Col>
                    <Col md={WIDTH_LEFT}>
                      {asterisk_routing_lookup.status}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={WIDTH_LEFT}>
                      Requested by
                    </Col>
                    <Col md={WIDTH_LEFT}>
                      {asterisk_routing_lookup.requested_by_user_email}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={WIDTH_LEFT}>
                      Requested at
                    </Col>
                    <Col md={WIDTH_LEFT}>
                      <Timestamp value={asterisk_routing_lookup.created_at} />
                    </Col>
                  </Row>

                  <Separator variant="h25" />

                  <Row>
                    <Col>
                      There are {size(asterisk_routing_lookup.all_servers)} servers.
                      <br/>
                      Waiting for acknowledgement from {size(asterisk_routing_lookup.unacknowledged_servers)} servers.
                      <br/>
                      Waiting on responses from {size(asterisk_routing_lookup.waiting_for_servers)} servers.
                      <br/>
                      Received responses from {size(asterisk_routing_lookup.received_servers)} servers.
                      <br/>
                    </Col>
                  </Row>

                </Card>

                { this.renderRoutingImage() }
                { this.renderSubscription() }
                { this.renderJoomlaSyncLog() }
                
                { map(get(asterisk_routing_lookup, "asterisk_responses"), this.renderResponse) }

                { map(get(asterisk_routing_lookup, "unacknowledged_servers"), this.renderUnacknowledgedServer) }
                
              </Container>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const asterisk_routing_lookup_id = get(props, ["match", "params", "asterisk_routing_lookup_id"], null)
    const asterisk_routing_lookup = adminAsteriskRoutingLookupList.getObject(asterisk_routing_lookup_id)
    return {
        is_loading: adminAsteriskRoutingLookupList.isLoading(),
        asterisk_routing_lookup,
        asterisk_routing_lookup_id
    }
}

export default connect(mapStateToProps)(AdminAsteriskRoutingLookup)
