import { ItemList } from '../orm'
import { download } from './lib'

class TransactionList extends ItemList {
    getEntityKey() {
        return "transaction"
    }

    download(filter) {
        const url = `transaction/download_excel/` 
        const params = null 
        const post_params = filter
        return download(url, params, post_params)
    }
    
}

export const transactionList = new TransactionList("transaction__default")
