/** @jsxImportSource @emotion/react */
import React,{ useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useSelector, useDispatch } from 'react-redux'
import { productListForUpgradePathFreeToPaid } from '../actions/product'
import { get, map, filter } from 'lodash'
import CurrencyValue from './CurrencyValue'
import { RadioGroupFieldStandalone } from './form/RadioGroupFieldStandalone'
import { globalSettingsList } from '../actions/settings'
import { Trans, Translation } from 'react-i18next'
import { default_theme as theme } from '../emotion/theme'
import { getBestLanguageCode } from '../actions/language'
import { Separator } from './layout/Separator'

const PRODUCT_TYPE_OPTIONS = [ {value: "voice", label: "Voice subscriptions"},
                               {value: "fax", label: "Fax subscriptions"}
                             ]


export const UpgradePathWidgetProductTable = ({defaultProductType}) => {

    const dispatch = useDispatch()
    const [selectedProductType, setSelectedProductType] = useState(defaultProductType || "fax")
    const fax_receiving_products = useSelector(() => filter(productListForUpgradePathFreeToPaid.getVisibleObjects(), (x) => x.can_receive_faxes === true))
    const voice_receiving_products = useSelector(() => filter(productListForUpgradePathFreeToPaid.getVisibleObjects(), (x) => x.can_receive_voice === true))
    const external_info_urls = useSelector(() => globalSettingsList.getSetting('external_info_urls'))

    useEffect(() => {
        dispatch(productListForUpgradePathFreeToPaid.updateListFilter({filter_can_receive_voice_or_fax: true}))
        dispatch(productListForUpgradePathFreeToPaid.updateListOrdering("annual_subscription_price_excluding_vat_euros"))
        dispatch(productListForUpgradePathFreeToPaid.fetchListIfNeeded())
    }, [])

    useEffect(() => {
	dispatch(globalSettingsList.fetchListIfNeeded())
    }, [])
    
    const onChangeSelectedProductType = (new_product_type) => {
        setSelectedProductType(new_product_type)
    }
    
    const renderVoiceProducts = () => {

	const url = get(external_info_urls, ["pricing", getBestLanguageCode()])
	
        return (
            <>
              <table css={table_style}>
                <thead css={thead_style}>
                  <th css={th_style}></th>
                  { map(voice_receiving_products, (product) => <th css={th_style}>{product.name}</th>) }
                </thead>
                <tbody>
                  <tr>
                    <td css={td_style}><Trans>Per year</Trans></td>
                    { map(voice_receiving_products, (product) => <td css={[td_style, td_value_style]}><CurrencyValue value={product.annual_subscription_price_excluding_vat_euros} /></td>) }
                  </tr>
                  <tr>
                    <td css={td_style}><Trans>Free forwarding</Trans></td>
                    { map(voice_receiving_products, (product) =>
                          <td css={[td_style, td_value_style]}>
                            { product.monthly_num_free_voice_forwarding_minutes > 0 &&
                              <span>{product.monthly_num_free_voice_forwarding_minutes}&nbsp;<Trans>minutes</Trans></span>
                            }
                            { ! product.monthly_num_free_voice_forwarding_minutes > 0 && <span>-</span> }
                          </td>
                         )}
                  </tr>
                  <tr>
                    <td css={td_style}><Trans>Extra minutes</Trans></td>
                    { map(voice_receiving_products, (product) =>
                          <td css={[td_style, td_value_style]}>
                            <div css={td_row_style}>
                              <CurrencyValue value={product.minimum_outgoing_voice_cost_per_minute_excluding_vat_euros} />&nbsp;/<Trans>min.</Trans>
                            </div>
                          </td>
                         )}
                  </tr>
                </tbody>
              </table>

              <div css={info_table_footer_style}>
                <Trans i18nKey="upgrade_path__for_more_details">
                  For more details, visit
                </Trans>
                &nbsp;
                <a href={url}><Trans>pricing</Trans></a>
              </div>
            </>
        )
    }

    const renderFaxProducts = () => {

	const url = get(external_info_urls, ["pricing", getBestLanguageCode()])
	
        return (
            <>
              <table css={table_style}>
                <thead css={thead_style}>
                  <th css={th_style}></th>
                  { map(fax_receiving_products, (product) => <th css={th_style}>{product.name}</th>) }
                </thead>
                <tbody>
                  <tr>
                    <td css={td_style}><Trans>Per year</Trans></td>
                    { map(fax_receiving_products, (product) => <td css={[td_style, td_value_style]}><CurrencyValue value={product.annual_subscription_price_excluding_vat_euros} /></td>) }
                  </tr>
                  <tr>
                    <td css={td_style}><Trans>Receive faxes</Trans></td>
                    { map(fax_receiving_products, (product) =>
                          <td css={[td_style, td_value_style]}>
                            { product.max_num_pages_per_incoming_fax && <>{product.max_num_pages_per_incoming_fax } <Trans>pages</Trans></> }
                          </td>
                         )
                    }
                  </tr>
                  <tr>
                    <td css={td_style}><Trans>Sending faxes</Trans></td>
                    { map(fax_receiving_products, (product) =>
                          <td css={[td_style, td_value_style]}>
                            <div css={td_row_style}>
                              <CurrencyValue value={product.minimum_outgoing_fax_cost_per_page_excluding_vat_euros} />&nbsp;/<Trans>page</Trans>
                            </div>
                            <div css={td_row_style}>
                              { product.minimum_outgoing_fax_cost_excluding_vat_euros > 0 &&
                                <>
                                  <CurrencyValue value={product.minimum_outgoing_fax_cost_excluding_vat_euros} />&nbsp;<Trans>minimum</Trans>
                                </>
                              }
                              { ! product.minimum_outgoing_fax_cost_excluding_vat_euros && 
                                <Trans>No minimum</Trans>
                              }
                            </div>
                          </td>
                         )}
                  </tr>
                </tbody>
              </table>

              <div css={info_table_footer_style}>
                <Trans i18nKey="upgrade_path__for_more_details">
                  For more details, visit
                </Trans>
                &nbsp;
                <a href={url}><Trans>pricing</Trans></a>
              </div>
            </>
        )
    }

    return (
        <div css={products_pane_style}>
          <div css={product_type_selector_style}>
            <RadioGroupFieldStandalone name="product_type"
                                       value={selectedProductType}
                                       onChange={onChangeSelectedProductType}
                                       options={PRODUCT_TYPE_OPTIONS}
            />
          </div>

          <Separator variant="h10" />

          { selectedProductType === "voice" && renderVoiceProducts() }
          { selectedProductType === "fax" && renderFaxProducts() }
          
          <table className="table table-bordered">
            
          </table>
          
        </div>
    )

}

const table_style = css`
width: 100%;
`

const thead_style = css`
font-weight: bold;
`

const th_style = css`
padding-top: 10px;
padding-bottom: 8px;
border-bottom: 1px solid ${theme.colors.primary_blue};
font-size: 18px;
`


const td_style = css`
padding-top: 10px;
padding-bottom: 8px;
border-bottom: 1px solid ${theme.colors.primary_blue};
`

const td_value_style = css`
font-weight: bold;
`

const td_row_style = css`
display: flex;
`

const info_table_footer_style = css`
font-size: 14px;
`


const products_pane_style = css`
background-color: ${theme.colors.white};
width: 100%;
margin-bottom: 30px;
padding: 20px 20px 18px;
border-radius: 10px;
`

const product_type_selector_style = css`
display: flex;
justify-content: center;
`

