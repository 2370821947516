import { ItemList } from '../../orm'

class AdminCustomerFaxFromEmailAddressList extends ItemList {
    getEntityKey() {
        return "admin/customer_fax_from_email_address"
    }
}

export const adminCustomerFaxFromEmailAddressList = new AdminCustomerFaxFromEmailAddressList("admin_customer_fax_from_email_address__default")

export const adminCustomerFaxFromEmailAddressListForApproval = new AdminCustomerFaxFromEmailAddressList("admin_customer_fax_from_email_address__for_approval")
