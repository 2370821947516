/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { get, map, size, union } from 'lodash'
import { Link } from 'react-router-dom'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { ButtonBar } from '../../components/layout/ButtonBar'
import Timestamp from '../../components/Timestamp'
import { Trans, Translation } from 'react-i18next'
import { adminNumberList } from '../actions/admin_number'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormLabelValue } from '../../components/form/FormLabelValue'
import { SubscriptionAmount } from '../../components/SubscriptionAmount'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { LabelValue } from '../../components/layout/LabelValue'
import AdminVoxboneStatus from './AdminVoxboneStatus'
import Loading from '../../components/Loading'
import AdminMainLayout from './AdminMainLayout'
import BusyMask from '../../components/BusyMask'
import Modal from '../../components/Modal'
import {showSuccess, showError} from '../../actions/Error'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import { Col, Row, Container, Tabs, Tab, Button } from 'react-bootstrap'
import Card from '../../components/layout/Card'
import { countryList } from '../../actions/country'
import { cityList } from '../../actions/city'
import { CardHeader } from '../../components/layout/CardHeader'
import { Form as BootstrapForm } from 'react-bootstrap'
import { FormikTextarea } from '../../components/form/TextareaField'
import { FormikInputField } from '../../components/form/InputField'
import { BlueLinkButton } from '../../components/layout/BlueLinkButton'
import { BlueButton } from '../../components/layout/BlueButton'
import { PhoneNumber } from '../../components/PhoneNumber'
import { Separator } from '../../components/layout/Separator'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { handleSubmitResult } from '../../actions/form'
import { adminPhoneNumberStatuses, adminNumberTypes } from '../actions/admin_dropdown_options'
import { adminPhoneNumberProviderList } from '../actions/admin_phone_number_provider'
import { adminOutgoingFaxServiceProviderList } from '../actions/admin_outgoing_fax_service_provider'

import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
})


class AdminNumber extends Component {

    componentDidMount() {
        const { dispatch, number_id } = this.props

        dispatch(cityList.updatePaginationNumItemsPerPage(1000))
        dispatch(cityList.fetchListIfNeeded())
        dispatch(countryList.updatePaginationNumItemsPerPage(1000))
        dispatch(countryList.fetchListIfNeeded())

        dispatch(adminNumberList.updatePaginationNumItemsPerPage(10))
        dispatch(adminNumberList.fetchListIfNeeded())
        dispatch(adminNumberList.ensureObjectLoaded(number_id))
        dispatch(adminPhoneNumberStatuses.fetchListIfNeeded())
        dispatch(adminNumberTypes.fetchListIfNeeded())
        dispatch(adminPhoneNumberProviderList.fetchListIfNeeded())
        dispatch(adminOutgoingFaxServiceProviderList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch, number, number_id } = this.props
        dispatch(adminNumberList.ensureObjectLoaded(number_id))
        dispatch(countryList.fetchListIfNeeded())
        dispatch(cityList.fetchListIfNeeded())
    }

    onSave = (values, formik_funcs) => {
        const { history, dispatch, number_id } = this.props
        const that = this
        this.setState({sending: true})
        const on_ok = function(json) {
            that.setState({sending: false, res: JSON.stringify(json)})
            dispatch(adminNumberList.invalidateList())
            showSuccess("Saved", "Number saved")
            if ( ! number_id ) {
                history.push(`/admin/number/${json.id}`)
            }
        }
        if ( number_id ) {
            values.id = number_id
            return dispatch(adminNumberList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminNumberList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    onCountryChanged = (country_id) => {
        const { dispatch } = this.props
        dispatch(cityList.filterOnCountry(country_id))
    }

    renderSubscriptionsInfo() {
        const { number } = this.props
        if ( ! number ) {
            return null
        }
        return (
            <Card variant="white_wide_padding" title="In use by">
              <Separator variant="h10" />
              { ! number.in_use_by_customer_subscription && "Not in use" }
              { number.in_use_by_customer_subscription && (
                  <div>
                    <div css={in_use_by_customer_subscription_row_style}>
                      {number.in_use_by_customer_subscription.customer_display_name} &nbsp;-&nbsp;
                      {number.in_use_by_customer_subscription.customer_email}
                    </div>
                    <div css={in_use_by_customer_subscription_row_style}>
                      {number.in_use_by_customer_subscription.product_name} &nbsp;-&nbsp; 
                      <SubscriptionAmount subscription={number.in_use_by_customer_subscription} />
                    </div>
                    <Separator variant="h10" />
                    <div css={in_use_by_customer_subscription_row_style}>
                      <Link to={`/admin/customer/${number.in_use_by_customer_subscription.customer}`}>
                        Open customer details &nbsp;
                        <InlineIcon icon_name="chevron-right" variant="blue" />
                      </Link>
                    </div>
                  </div>
              )}
            </Card>
        )
    }

    renderActionsInfo() {
        const { number } = this.props
        if ( ! number ) {
            return null
        }
        return (
            <div>
              <AdminVoxboneStatus phone_number_id={get(number, "id")} />
            </div>
        )
    }

    renderNumberInfo() {
        const { number } = this.props
        if ( ! number ) {
            return null
        }
        return (
            <Row>
              <Col>
                <LabelValue>
                  Type
                  {number.number_type}
                </LabelValue>
              </Col>
              <Col>
                <LabelValue>
                  Country
                  {number.country_name}
                </LabelValue>
              </Col>
              <Col>
                <LabelValue>
                  City
                  {number.city_name}
                </LabelValue>
              </Col>
              <Col>
                <LabelValue>
                  Provider
                  {number.provider_name}
                </LabelValue>
              </Col>
            </Row>
        )
    }

    renderForm() {
        const { number, number_id, initial_values, status_options, number_type_options,
                country_options, city_options, provider_options, outgoing_fax_service_provider_options } = this.props
        return (
            <Formik
              initialValues={initial_values}
              onSubmit={this.onSave}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {formik_props => {
                  const { values } = formik_props
                  return (
                      <Form>
                        <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />

                        { ! number_id && 
                          <Row>
                            <Col md="4">
                              <FormLabelValue>
                                <span>Number</span>
                                <FormikInputField type="text" 
                                                  name="number"
                                />
                              </FormLabelValue>
                            </Col>
                          </Row>
                        }
                        
                        <Row>
                          <Col md="4">
                            <FormLabelValue>
                              <span>Status</span>
                              <FormikDropdownField formik_props={formik_props} 
                                                   type="text" 
                                                   name="status" 
                                                   options={status_options} 
                                
                              />
                            </FormLabelValue>
                          </Col>
                          <Col md="8">
                            <FormLabelValue>
                              <span>Comment</span>
                              <FormikTextarea name="comment" rows={4} place_holder="An optional comment about this number" />
                            </FormLabelValue>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <FormLabelValue>
                              Type
                              <FormikDropdownField formik_props={formik_props} 
                                                   type="text" 
                                                   name="number_type" 
                                                   options={number_type_options} 
                              />
                            </FormLabelValue>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <FormLabelValue>
                              Country
                              <FormikDropdownField name="country"
                                                   formik_props={formik_props}
                                                   options={country_options}
                                                   on_change={this.onCountryChanged}
                                                   placeholder="Country" />
                            </FormLabelValue>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <FormLabelValue>
                              City
                              <FormikDropdownField name="city"
                                                   formik_props={formik_props}
                                                   options={city_options}
                                                   empty_selection_label="Any city"
                                                   placeholder="City" />
                            </FormLabelValue>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <FormLabelValue>
                              Provider
                              <FormikDropdownField name="provider"
                                                   formik_props={formik_props}
                                                   options={provider_options}
                                                   placeholder="Provider" />
                            </FormLabelValue>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <FormLabelValue>
                              Outbound Fax Service Provider
                              <FormikDropdownField name="outgoing_fax_service_provider"
                                                   formik_props={formik_props}
                                                   options={outgoing_fax_service_provider_options}
                                                   placeholder="Choose outgoing fax service provider" />

                              {number && number.outgoing_fax_service_provider_auto_set_at &&
                               <Row>
                                 <Col>This provider was set automatically during failover, at</Col>
                                 <Col><Timestamp value={number.outgoing_fax_service_provider_auto_set_at} /></Col>
                               </Row>
                              }
                              
                            </FormLabelValue>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <BlueButton type="submit" auto_disable={false}>
                              Save
                            </BlueButton>
                          </Col>
                        </Row>
                      </Form>
                  )
              }}
            </Formik>
        )
    }

    render() {
        const { number_id, is_busy, is_loading, number } = this.props
        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'numbers', label: 'Numbers', url: '/admin/numbers'},
                                           {name: 'number', label: get(number, "number"), url: `/admin/number/${number_id}`}]}>
              { is_busy && <BusyMask /> }
              <WrappingBusyMask is_loading={is_loading}>
                { !is_loading &&
                  <Container fluid>
                    <h1>
                      { number_id && <PhoneNumber phone_number={ get(number, "number") } /> }
                      { ! number_id && "New number" }
                    </h1>
                    <Row>
                      <Col md="6">
                        <Card variant="white_wide_padding">
                          { this.renderNumberInfo() }
                          <Separator variant="h50" />
                          { this.renderForm() }
                        </Card>
                      </Col>
                      <Col md="6">
                        { this.renderSubscriptionsInfo() }
                        { this.renderActionsInfo() }
                      </Col>
                    </Row>

                  </Container>
                }
              </WrappingBusyMask>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const number_id = get(props, ["match", "params", "phone_number_id"], null)
    const number = number_id && adminNumberList.getObject(number_id)

    return {
        number_id,
        number,
        is_loading: adminNumberList.isLoading(),
        is_busy: adminNumberList.getIsSavingObject(),
        initial_values: number || {status: 'available'},
        status_options: adminPhoneNumberStatuses.getAsOptions(),
        number_type_options: adminNumberTypes.getAsOptions(),
        country_options: countryList.getAsOptionsWithPriority(),
        city_options: cityList.getAsOptions({show_prefix_in_label:true}),
        provider_options: union( [{label: 'None', value: null}], adminPhoneNumberProviderList.getAsOptions()),
        outgoing_fax_service_provider_options: union( [{label: 'default', value: null}], adminOutgoingFaxServiceProviderList.getAsOptions() )

    }
}
export default withRouter(connect(mapStateToProps)(AdminNumber))

const in_use_by_customer_subscription_row_style = css`
display: flex;
`
