/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { get, map, size } from 'lodash'
import { withRouter } from 'react-router-dom'
import { CardHeader } from '../../components/layout/CardHeader'
import { Container, Row, Col } from 'react-bootstrap'
import { adminInvoiceList } from '../actions/admin_invoice'
import Timestamp from '../../components/Timestamp'
import { adminResellerList } from '../actions/admin_reseller'
import CardInfoText from '../../components/layout/CardInfoText'
import { adminCustomerList } from '../actions/admin_customer'
import InvoiceTable from '../../components/InvoiceTable'
import { SeparatorWithLine } from '../../components/layout/SeparatorWithLine'
import {showSuccess, showError} from '../../actions/Error'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikInputField } from '../../components/form/InputField'
import { FormikTextarea } from '../../components/form/TextareaField'
import { confirmModal } from '../../actions/ui'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { Separator } from '../../components/layout/Separator'
import AdminCreditInformationCard from './AdminCreditInformationCard'
import { LANGUAGES } from '../../actions/language'
import Card from '../../components/layout/Card'
import { LabelValue } from '../../components/layout/LabelValue'
import { GrayButton } from '../../components/layout/GrayButton'
import { BlueButton} from '../../components/layout/BlueButton'
import { Trans, Translation } from 'react-i18next'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { handleSubmitResult } from '../../actions/form'
import { NotificationForm } from '../../components/NotificationForm'
import { Button } from 'react-bootstrap'
import * as Yup from 'yup'

const LEFT_PANEL__LEFT_WIDTH = 5
const LEFT_PANEL__RIGHT_WIDTH = 7
const RIGHT_PANEL__LEFT_WIDTH = 4
const RIGHT_PANEL__RIGHT_WIDTH = 8

const validationSchema = Yup.object().shape({
})

class AdminCustomerDetails extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminResellerList.updatePaginationNumItemsPerPage(1000))
        dispatch(adminResellerList.fetchListIfNeeded())
    }
    
    onEdit = () => {
        const { history, customer_id } = this.props
        history.push(`/admin/customer_profile/${customer_id}`)
    }
    
    onSendPasswordResetEmail = () => {
        const { dispatch, customer_id } = this.props
        const on_ok = function(json) {
            showSuccess("Reset", "Reset email sent")
        }

        if (! window.confirm("Are you sure you want to send a password reset email to the customer?" ) ) {
            return
        }
        
        dispatch(adminCustomerList.sendPasswordResetEmail(customer_id)).then((res) => handleSubmitResult({res, on_ok}))
    }

    onSendWelcomeEmail = () => {
        const { dispatch, customer_id } = this.props
        const on_ok = function(json) {
            showSuccess("Sent", "Welcome email sent")
        }

        if (! window.confirm("Are you sure you want to send a welcome email to the customer?" ) ) {
            return
        }
        
        dispatch(adminCustomerList.sendWelcomeEmail(customer_id)).then((res) => handleSubmitResult({res, on_ok}))
    }

    onCancelAccount = () => {
        const { dispatch, customer_id } = this.props

        let send_email_to_customer = false
        const onToggleSendEmailToCustomer = (evt) => {
            send_email_to_customer = evt.target.checked
        }
        
        const on_ok = function(json) {
            showSuccess("Cancelled", "Account has been cancelled")
        }

        const onConfirmed = () => {
            return dispatch(adminCustomerList.cancelAccount({customer_id: customer_id,
                                                             send_email_to_customer: send_email_to_customer}))
                .then((res) => handleSubmitResult({res, on_ok}))
        }

        const text = (
            <div>
              <div>
                <Trans>Are you sure you want to cancel this account?</Trans>
              </div>
              <SeparatorWithLine variant="h10" />
              <div>
                <label>
                  Send cancellation email to customer &nbsp;
                  <input type="checkbox"
                         value={send_email_to_customer}
                         onChange={onToggleSendEmailToCustomer}
                  />
                </label>
                <br/>
              </div>
            </div>
        )
        
        return dispatch(confirmModal({text:text, onConfirmed: onConfirmed}))
    }

    onSave = (values, formik_funcs) => {
        const { dispatch, customer_id } = this.props
        const that = this

        const on_ok = function(json) {
            showSuccess("Saved", "Customer profile updated")
            dispatch(adminCustomerList.invalidateCustomer())
            dispatch(adminCustomerList.ensureCustomerLoaded())
        }
        values.id = customer_id
        return dispatch(adminCustomerList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }
    
    renderCustomerActions() {
        const { customer_id, customer, language_options, reseller_options } = this.props
        return (
            <Card variant="white_wide_padding">
              <Formik
                  onSubmit={this.onSave}
                  initialValues={customer}
                  enableReinitialize={true}
                  validationSchema={validationSchema}
              >
                {formik_props => {
                     const { values } = formik_props
                     return (
                         <Form>
                           <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                           
                           <Row>
                             <Col md={RIGHT_PANEL__LEFT_WIDTH} css={section_name_style}>
                               <Trans>Credit</Trans>
                             </Col>
                             <Col md={RIGHT_PANEL__RIGHT_WIDTH}>
                               <AdminCreditInformationCard customer_id={customer_id} />
                             </Col>
                           </Row>

                           <Separator variant="h30" />
                           
                           <Row>
                             <Col md={RIGHT_PANEL__LEFT_WIDTH} css={section_name_style}>
                               <Trans>Reseller</Trans>
                             </Col>
                             <Col md={RIGHT_PANEL__RIGHT_WIDTH}>
                               <FormikDropdownField name="reseller" options={reseller_options} formik_props={formik_props} />
                             </Col>
                           </Row>
                           
                           <Separator variant="h30" />
                           
                           <Row>
                             <Col md={RIGHT_PANEL__LEFT_WIDTH} css={section_name_style}>
                               <Trans>Language</Trans>
                             </Col>
                             <Col md={RIGHT_PANEL__RIGHT_WIDTH}>
                               <FormikDropdownField name="user.language_code" options={language_options} formik_props={formik_props} />
                             </Col>
                           </Row>

                           <Separator variant="h30" />
                           
                           <Row>
                             <Col md={RIGHT_PANEL__LEFT_WIDTH} css={section_name_style}>
                               <Trans>Account login</Trans>
                             </Col>
                             <Col md={RIGHT_PANEL__RIGHT_WIDTH}>
                               {get(customer, ["user", "email"])}
                               <Separator variant="h20" />
                               <FormikInputField name="user.email" placeholder="Enter new login email address" />
                               <Separator variant="h20" />
                               <BlueButton type="submit">
                                 Save changes
                               </BlueButton>
                             </Col>
                           </Row>

                           <Separator variant="h40" />
                           
                           <Row>
                             <Col md={RIGHT_PANEL__LEFT_WIDTH} css={section_name_style}>
                               <Trans>Password reset</Trans>
                             </Col>
                             <Col md={RIGHT_PANEL__RIGHT_WIDTH}>
                               <Button variant="outline-danger" onClick={this.onSendPasswordResetEmail}>
                                 <Trans>Send password reset email</Trans>
                               </Button>
                             </Col>
                           </Row>

                           <Separator variant="h40" />
                           
                           <Row>
                             <Col md={RIGHT_PANEL__LEFT_WIDTH} css={section_name_style}>
                               <Trans>Email</Trans>
                             </Col>
                             <Col md={RIGHT_PANEL__RIGHT_WIDTH}>
                               <CardInfoText>
                                 { customer.welcome_email_sent_at && 
                                   <div>A welcome email was sent at <Timestamp value={customer.welcome_email_sent_at} /></div>
                                 }
                                 { ! customer.welcome_email_sent_at && 
                                   <div>No welcome email has been sent</div>
                                 }
                                 { get(customer, ["user", "has_set_password_at_least_once"]) &&
                                   <div>The customer's password has been set the password at least once</div>
                                 }
                                 { !get(customer, ["user", "has_set_password_at_least_once"]) &&
                                   <div>The customer's password has never been set</div>
                                 }
                                 { !get(customer, ["user", "has_usable_password"]) &&
                                   <div>The customer will need to set a password after login</div>
                                 }
                                 { get(customer, ["user", "has_usable_password"]) &&
                                   <div>The customer's password is valid</div>
                                 }
                               </CardInfoText>
                               <Button variant="outline-secondary" onClick={this.onSendWelcomeEmail}>
                                 <Trans>Send welcome email</Trans>
                               </Button>
                             </Col>
                           </Row>

                           <Separator variant="h40" />

                           <Row>
                             <Col md={RIGHT_PANEL__LEFT_WIDTH} css={section_name_style}>
                               <Trans>Login</Trans>
                             </Col>
                             <Col md={RIGHT_PANEL__RIGHT_WIDTH}>
                               <CardInfoText>
                                 { customer.user.first_login_at &&
                                   <>
                                   <div>The customer's first login was on <Timestamp value={customer.user.first_login_at} /></div>
                                   <div>The customer's most recent login was on <Timestamp value={customer.user.last_login_at} /></div>
                                   </>
                                 }
                                 { ! customer.user.first_login_at && 
                                   <div>The customer has never logged in</div>
                                 }
                               </CardInfoText>
                             </Col>
                           </Row>

                           <Row>
                             <Col>
                               <Separator variant="h20" />
                               <NotificationForm customer={customer} left_width={RIGHT_PANEL__LEFT_WIDTH} itemList={adminCustomerList} />
                             </Col>
                           </Row>
                           
                           { customer.user.is_active &&
                             <>
                             <Separator variant="h40" />
                             <Row>
                               <Col md={RIGHT_PANEL__LEFT_WIDTH} css={section_name_style}>
                                 <Trans>Cancel account</Trans>
                               </Col>
                               <Col md={RIGHT_PANEL__RIGHT_WIDTH}>
                                 <Button variant="outline-danger" onClick={this.onCancelAccount}>
                                   <Trans>Cancel account</Trans>
                                 </Button>
                               </Col>
                             </Row>
                             </>
                           }
                           
                           
                         </Form>
                     )
                }}
              </Formik>
            </Card>
        )
    }

    renderCustomerCard() {
        const { customer_id, customer } = this.props
        const user = customer.user

        return (
            <div>
              <Card variant="white_wide_padding">
                <Row css={edit_button_row_style}>
                  <Col md={LEFT_PANEL__LEFT_WIDTH} css={section_name_style}>
                    <Trans>Name</Trans>
                  </Col>
                  <Col md={LEFT_PANEL__RIGHT_WIDTH}>
                    <LabelValue>
                      <Trans>Name</Trans>
                      {user.display_name}
                    </LabelValue>
                  </Col>
                  <Col md="1" offset="11" css={edit_button_style}>
                    <GrayButton onClick={this.onEdit}>
                      <div css={edit_button_content_style}>
                        <InlineIcon icon_name="edit" variant="black"/>
                        <Separator variant="w5" />
                        <Trans>Edit</Trans>
                      </div>
                    </GrayButton>
                  </Col>
                </Row>
                
                <Separator variant="h10"/>
                <Row>
                  <Col md={LEFT_PANEL__LEFT_WIDTH} css={section_name_style}>
                    <Trans>Contact details</Trans>
                  </Col>
                  <Col md={LEFT_PANEL__RIGHT_WIDTH}>
                    <Row>
                      <Col>
                        <LabelValue>
                          <Trans>Email</Trans>
                          {customer.contact_email}
                        </LabelValue>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <LabelValue>
                          <Trans>Phone</Trans>
                          {customer.contact_phone_number}
                        </LabelValue>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Separator variant="h10"/>
                <Row>
                  <Col md={LEFT_PANEL__LEFT_WIDTH} css={section_name_style}>
                    <Trans>Company information</Trans>
                  </Col>
                  <Col md={LEFT_PANEL__RIGHT_WIDTH}>
                    <Row>
                      <Col md={LEFT_PANEL__RIGHT_WIDTH}>
                        <LabelValue>
                          <Trans>Company name</Trans>
                          {get(customer, ["company", "name"])}
                        </LabelValue>
                        
                        <LabelValue>
                          <Trans>Address</Trans>
                          <div>
                            <div>
                              {get(customer, ["company", "billing_address", "street1"])}
                            </div>
                            <div>
                              {get(customer, ["company", "billing_address", "street2"])}
                            </div>
                            <div css={address_single_line_style}>
                              {get(customer, ["company", "billing_address", "postal_code"])}
                              <Separator variant="w10" />
                              {get(customer, ["company", "billing_address", "city_name"])}
                            </div>
                            <div>
                              {get(customer, ["company", "billing_address", "country_name"])}
                            </div>
                          </div>
                        </LabelValue>
                        
                        <LabelValue>
                          <Trans>VAT</Trans>
                          {get(customer, ["company", "vat_number"])}
                        </LabelValue>
                        
                        <LabelValue>
                          <Trans>Chamber of Commerce</Trans>
                          {get(customer, ["company", "chamber_of_commerce"])}
                        </LabelValue>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                { this.renderNotesForm() }
                
              </Card>
            </div>
        )
    }

    renderNotesForm() {
        const { customer } = this.props
        return (
            <Formik
              onSubmit={this.onSave}
              initialValues={customer}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {formik_props => {
                  const { values } = formik_props

                  return (
                      <>
                        <Form>
                          <Row>
                            <Col css={section_name_style}>
                              <Trans>Notes</Trans>
                              <Separator variant="h10" />
                              <FormikTextarea name="internal_note"
                                              placeholder="Your text here..."
                                              formik_props={formik_props} />
                            </Col>
                          </Row>
                          <Row>
                            <BlueButton type="submit">
                              Save
                            </BlueButton>
                          </Row>
                      </Form>
                      </>
                  )
              }}
            </Formik>
        )
    }
    
    render() {
        const that = this
        return (
            <Row>
              <Col md={6}>
                { this.renderCustomerCard() }
              </Col>
              <Col md={6}>
                { this.renderCustomerActions() }
              </Col>                                

            </Row>
        )
    }
    
}

function mapStateToProps(state, props) {
    const { customer_id } = props
    const customer = customer_id && adminCustomerList.getObject(customer_id)
    const language_options = map(LANGUAGES, function(language) { return {label: language.language_name, value: language.language_code} })
    const reseller_options = adminResellerList.getAsOptions()

    
    return {
        customer_id,
        customer,
        admin_invoice_list_filter: adminInvoiceList.getFilter(),
        language_options,
        reseller_options,
        initial_values: {language_code: get(customer, "language_code"),
                         email: get(customer, "email")}
    }
}

export default withRouter(connect(mapStateToProps)(AdminCustomerDetails))

const header_right_child = css`
display: flex;
`

const download_button_style = css`
display: flex;
justify-content: space-between;
`


const section_name_style = css`
font-weight: 500;
font-size: 18px;
`

const address_single_line_style = css`
display: flex;
`

const edit_button_content_style = css`
display: flex;
align-items: center;
`

const edit_button_style = css`
position: absolute;
right: 40px;
`

const edit_button_row_style = css`
position: relative;
`

