/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, size } from 'lodash'
import ReactTimeout from 'react-timeout'
import ReactDOM from 'react-dom'
import { jsx, css, Global } from '@emotion/react'
import PDFViewer from 'pdf-viewer-reactjs'
import { base64FileInfoList } from '../actions/file_info'
import WrappingBusyMask from './WrappingBusyMask'
import { Error } from './layout/Error'

const RETRY_TIMEOUT_MILLISECONDS = 1000

class PdfPreview extends Component {

    constructor(props) {
        super(props)
        this.state = { refresh_timer: null }
    }
    
    componentDidMount() {
        const { dispatch, pdf_file } = this.props
        dispatch(base64FileInfoList.updateListFilter({version: 'fax'}))
        dispatch(base64FileInfoList.ensureObjectLoaded(pdf_file.id))
        this.refreshFile()
    }

    componentDidUpdate() {
        this.refreshFile()
    }

    refreshFile() {
        const { pdf_file, content, dispatch, clearTimeout, setTimeout } = this.props
        const { refresh_timer } = this.state
        const that = this

        if ( refresh_timer ) {
            return
        }

        if ( ! get(content, "id") || size(get(content, "base64")) > 0 ) {
            return
        }

        const timer = setTimeout(() => {
            const { pdf_file, content, dispatch, clearTimeout, setTimeout } = that.props
            clearTimeout(refresh_timer)
            that.setState({refresh_timer: null})
            if ( size(get(content, "base64")) == 0 ) {
                dispatch(base64FileInfoList.invalidateObjects([pdf_file.id]))
                dispatch(base64FileInfoList.ensureObjectLoaded(pdf_file.id))
            }
        },
                                 RETRY_TIMEOUT_MILLISECONDS)

        that.setState({refresh_timer: timer})
    }
    
    render() {
        const { content, is_loading, last_error, renderNavigation } = this.props
        const { refresh_timer } = this.state
        return (
            <div css={container}>
              <WrappingBusyMask is_loading={is_loading}>
                <Global styles={global_styles} />
                { last_error && <Error>{last_error}</Error> }
                { ! last_error && size(get(content, "base64")) > 0 && ! refresh_timer && 
                  <PDFViewer
                      document={{
                          // url: pdf_file.original_download_url,
                          base64: content.base64
                      }}
                      navigation={renderNavigation}
                      navbarOnTop
                      hideRotation
                      css="pdfviewer_wrapper"
                      canvasCss="pdfviewer_canvas"
                  />
                }
              </WrappingBusyMask>
            </div>
        )
    }

}

function mapStateToProps(state, props) {
    const { pdf_file } = props
    const content = base64FileInfoList.getObject(pdf_file.id)

    return {
        pdf_file,
        content,
        is_loading: base64FileInfoList.isLoading() || !(content && content.id && content.base64) && base64FileInfoList.getLastError() === null,
        last_error: base64FileInfoList.getLastError()
    }
}

export default connect(mapStateToProps)(ReactTimeout(PdfPreview))

const global_styles = css`

`

const container = css`
min-height: 300px;
`
