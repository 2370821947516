/** @jsxImportSource @emotion/react */
import React,{ useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { get, map, filter } from 'lodash'
import { Trans, Translation } from 'react-i18next'
import { default_theme as theme } from '../emotion/theme'
import { InlineIcon } from './layout/InlineIcon'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

export const MultipleEmailsSupportedInfoWidget = ({}) => {

    const tooltipId = `tooltip_MultipleEmailsSupportedInfoWidget`
    
    const tooltip = (
        <Tooltip id={tooltipId}>
          <Trans>Multiple recipients possible. Use comma (,) to separate email addresses.</Trans>
        </Tooltip>
    )

    return (
        <>
          <OverlayTrigger overlay={tooltip}>
            <span css={icon_wrapper_style}>
              <FontAwesomeIcon icon={faInfoCircle} css={icon_style} />
            </span>
          </OverlayTrigger>
        </>
    )

}

const icon_wrapper_style = css`
font-size: 14px;
margin-right: 3px;
cursor: help;
`

const icon_style = css`
color: ${theme.colors.gray1};
`
