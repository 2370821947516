/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { map, get, remove, includes, size, tail, slice, last } from 'lodash'
import { jsx, css } from '@emotion/react'
import MainLayout from './MainLayout'
import CommonTable from './CommonTable'
import { FaxItemDetailsModal } from './FaxItemDetailsModal'
import { Trans } from 'react-i18next'
import { customerList } from '../actions/customer'
import { Container, Navbar, Nav, Col, Row } from 'react-bootstrap'
import WrappingBusyMask from './WrappingBusyMask'
import OutgoingFaxItemDetails from './OutgoingFaxItemDetails'
import IncomingFaxItemDetails from './IncomingFaxItemDetails'
import Timestamp from './Timestamp'
import { PhoneNumber } from './PhoneNumber'
import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InlineIcon } from './layout/InlineIcon'
import { InlineIconBar } from './layout/InlineIconBar'

const FAX_STATES_OK = [ "DELIVERED", "DELIVERY_SUCCESS", 'processed', 'processed_by_asterisk', 'LEGACY_SUBMITTED' ]
const FAX_STATES_PENDING = [ "SUBMITTED", "NEW", "FAX_REQ_SENT", "PREPARING", 'processing' ]
const FAX_STATUS_WAITING_INPUT = ["SEND_PENDING_VERIFICATION"]
const FAX_STATUS_EXPIRED = ["EXPIRED"]
const FAX_STATES_FAILED = [ "INTERNAL_FAILURE", "DELIVERY_FAILED", "CANCELLED", 
                            "FE_FROM_ADDRESS", "FE_BAD_TOKEN", "FE_BAD_DESTINATION", "FE_BAD_ATTACHMENT",
                            "INVALID_INCOMING_EMAIL", 'error', 'soft_failure_with_fax', 'soft_failure_without_fax' ]


const NUM_FILENAME_CHARACTERS_BEFORE_ELLIPSES = 5
const NUM_FILENAME_CHARACTERS_AFTER_ELLIPSES = 5

class FaxTraffic extends Component {

    constructor(props) {
        super(props)
        this.state = { showing_fax_item_details: null }
    }

    componentDidMount() {
        const { dispatch, faxItemList } = this.props
        dispatch(faxItemList.fetchListIfNeeded())
        dispatch(customerList.ensureCustomerLoaded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch, faxItemList } = this.props
        dispatch(faxItemList.fetchListIfNeeded())
    }
    
    onShowItemDetails = (item) => {
        const { dispatch, faxItemList } = this.props
        dispatch(faxItemList.invalidateObject(item.id))
        dispatch(faxItemList.ensureObjectLoaded(item.id))
        this.setState({showing_fax_item_details: item})
    }
    
    onHideItemDetails = (item) => {
        this.setState({showing_fax_item_details: null})
    }

    renderIconForStatus(status_name, index) {
        return (
            <div key={index}>
              {includes(FAX_STATES_OK, status_name) && <InlineIcon icon_name="status-success" />}
              {includes(FAX_STATES_PENDING, status_name) && <InlineIcon icon_name="status-in-progress" />}
              {includes(FAX_STATES_FAILED, status_name) && <InlineIcon icon_name="status-error" />}
              {includes(FAX_STATUS_WAITING_INPUT, status_name) && <InlineIcon icon_name="status-waiting-input" />}
              {includes(FAX_STATUS_EXPIRED, status_name) &&  <InlineIcon icon_name="status-expired" />}
              
              {!includes(FAX_STATES_OK, status_name) &&
               !includes(FAX_STATES_PENDING, status_name) &&
               !includes(FAX_STATES_FAILED, status_name) &&
               !includes(FAX_STATUS_WAITING_INPUT, status_name) &&
               !includes(FAX_STATUS_EXPIRED, status_name) &&
               <span>{status_name}</span>
              }
            </div>
        )
    }

    ellipsifyFilename(filename) {
        if ( this.props.ellipsify_filename_enabled !== true ) {
            return filename
        }
        if ( size(filename) < NUM_FILENAME_CHARACTERS_BEFORE_ELLIPSES+NUM_FILENAME_CHARACTERS_AFTER_ELLIPSES ) {
            return filename
        }
        const parts = filename.split('.')
        let extension
        let filename_excluding_extension

        if ( size(parts) > 0 ) {
            extension = last(parts)
            filename_excluding_extension = slice(parts, 0, size(parts)-1).join(".")
        } else {
            extension = ""
            filename_excluding_extension = parts[0]
        }

        return filename_excluding_extension.slice(0, NUM_FILENAME_CHARACTERS_BEFORE_ELLIPSES) +
            "..." +
            filename_excluding_extension.slice(size(filename_excluding_extension)-NUM_FILENAME_CHARACTERS_BEFORE_ELLIPSES) +
            "." + extension
    }
    
    renderFaxQueueItemFilename(fax_queue_item) {
        const file_info = fax_queue_item.fax_file
        if ( ! file_info ) {
            return ""
        }
        let full_filename = file_info.visible_filename
        full_filename = this.ellipsifyFilename(full_filename)
        
        const num_extra_files = file_info.derived_from_num_files - 1
        if ( num_extra_files > 0 ) {
            full_filename += ` + ${num_extra_files} file`
            if ( num_extra_files > 2 ) {
                full_filename += "s"
            }
        }

        return (
            <div css={filename_cell_inner_style} key={fax_queue_item.id}>
              {full_filename}
            </div>
        )
    }

    renderOutgoingFaxQueueItemId(fax_queue_item) {
        return (
            <div key={fax_queue_item.id}>
              {fax_queue_item.short_id}
            </div>
        )
    }

    renderOutgoingFaxQueueNumPages(fax_queue_item) {
        return (
            <div key={fax_queue_item.id}>
              {get(fax_queue_item, ["fax_file", "page_count"])}
            </div>
        )
    }

    renderCell = (header_key, item) => {
        switch( header_key ) {
            case 'filename':
                if ( item.direction === "outgoing" ) {
                    return map(get(item, ["outgoing_fax_queue_items"]), (f) => this.renderFaxQueueItemFilename(f))
                } else if ( item.direction === "incoming" ) {
                    return (
                        <div>
                          {includes(FAX_STATES_OK, get(item, "status")) && (
                              <div>
                                <Trans>Sent to</Trans>:&nbsp;
                                {get(item, ["incoming_fax", "destination_forwarding_email"])}
                              </div>
                          )}
                        </div>
                    )
                } else {
                    return ("Unknown direction : " + item.direction)
                }
            case 'item_id':
                if ( item.direction === "outgoing" ) {
                    return map(get(item, ["outgoing_fax_queue_items"]), (f) => this.renderOutgoingFaxQueueItemId(f))
                } else if ( item.direction === "incoming" ) {
                    return <div>{item.incoming_fax.short_ref}</div>
                }
            case 'num_pages':
                if ( item.direction === "outgoing" ) {
                    return map(get(item, ["outgoing_fax_queue_items"]), (f) => this.renderOutgoingFaxQueueNumPages(f))
                } else if ( item.direction === "incoming" ) {
                    return <div>{get(item, ["incoming_fax", "fax_file", "page_count"])}</div>
                }
            case 'channel':
                if ( item.direction === "outgoing" ) {
                    return map(get(item, ["outgoing_fax_queue_items"]), (f) =>
                               <div key={f.id}>
                                 {get(f, ["attempts", 0, "outgoing_fax_service_provider_name"])}
                               </div>
                              )
                } else {
                    return item.incoming_fax.source
                }
            default: 
                return undefined
        }
    }
    
    getFaxCellValue = (header_key, item, index) => {
        const { customer_id } = this.props
        let display_name = null
        switch( header_key ) {
            case 'direction':
                return (
                    <div>
                      { item.direction === "outgoing" && <InlineIcon icon_name="outgoing" size_variant="h16" variant="black" />}
                      { item.direction === "incoming" && <InlineIcon icon_name="incoming" size_variant="h16" variant="black" />}
                    </div>
                )
            case 'created_at':
                return (
                    <Timestamp value={item.created_at} format="dateshort-time" />
                )
            // case 'filename':
            //     return (
            //         <div css={filename_cell_style}>
            //           {map(get(item, ["outgoing_fax_queue_items"]), (f) => this.renderFaxQueueItemFilename(f))}
            //         </div>
            //     )
            case 'fax_from':
                display_name = get(item, ["from_customer", "display_name"], null)
                const fax_triggered_from_email_address = get(item, ["outgoing_fax_queue", "fax_triggered_from_email_address"], null)
                return (
                    <div>
                      { fax_triggered_from_email_address && fax_triggered_from_email_address }
                      { ! fax_triggered_from_email_address && display_name !== null && display_name }
                      { ! fax_triggered_from_email_address && display_name === null && <PhoneNumber phone_number={get(item, ["from_phone_number", "number"], "Unknown")} /> }
                    </div>
                )
            case 'fax_to':
                display_name = get(item, ["to_customer", "display_name"], null)
                return (
                    <div>
                      { display_name !== null &&
                        <div>
                          {display_name}
                          &nbsp; (<PhoneNumber phone_number={get(item, ["to_phone_number", "number"], "Unknown")} />)
                        </div>
                      }
                      { display_name === null &&
                        <PhoneNumber phone_number={get(item, ["to_phone_number", "number"], "Unknown")} />
                      }
                    </div>
                )

            case 'status':
                return (
                    <div css={status_inner_style}>
                      { item.direction === "outgoing" && 
                        map(get(item, ["outgoing_fax_queue_items"]), (f, index) => this.renderIconForStatus(f.status_name, index))
                      }
                      { item.direction === "incoming" &&
                        this.renderIconForStatus(get(item, ["incoming_fax", "status"]), 0)
                      }
                    </div>
                )
            case 'action':
                return (
                    <div css={action_inner_style}>
                      <InlineIcon icon_name="info" size_variant="h16" variant="blue" onClick={() => this.onShowItemDetails(item)} />
                    </div>
                )
            default:
                return undefined
        }
    }

    renderCustomerNotFullyMigratedMessage() {
        return (
            <div>
              <Trans>While we migrate your account data, yesterday's faxes may not show up here yet. Please check back tomorrow.</Trans>
            </div>
        )
    }
    
    render() {
        const { is_ready, is_loading, fax_headers, faxes, faxItemList, migration_from_legacy_in_progress, postRenderCell } = this.props
        const { showing_fax_item_details } = this.state
        return (
            <div>
              <WrappingBusyMask is_loading={!is_ready}>
                <CommonTable is_loading={ is_loading }
                             empty_message={`There are no matching faxes`}
                             headers={ fax_headers }
                             items={Object.assign([], faxes)}
                             item_list={faxItemList}
                             renderCell={this.renderCell}
                             sub_header_message={migration_from_legacy_in_progress ? this.renderCustomerNotFullyMigratedMessage() : ""}
                             getCellValue={ this.getFaxCellValue }
                             postRenderCell={postRenderCell}
                />
              </WrappingBusyMask>
              { showing_fax_item_details && <FaxItemDetailsModal fax_item_id={showing_fax_item_details.id} faxItemList={faxItemList} onClose={this.onHideItemDetails} /> } 
            </div>
        )
    }
    
}


function mapStateToProps(state, props) {

    const { faxItemList, fax_headers, postRenderCell } = props
    const faxes = faxItemList.getVisibleObjects()
    const customer = customerList.getCustomer()
    const migration_from_legacy_in_progress = get(customer, "migration_from_legacy_in_progress", false)
    
    return {
        faxes,
        fax_headers: fax_headers || {
            direction: { name: '', td_style: direction_cell_style },
            created_at: { name: <Trans>Date/Time</Trans>, td_style: created_at_cell_style },
            fax_from: { name: <Trans>From</Trans>, td_style: from_cell_style },
            fax_to: { name: <Trans>To</Trans>, td_style: to_cell_style },
            filename: { name: <Trans>File</Trans>, td_style: filename_cell_style },
            status: { name: '', td_style: status_style },
            action: { name: '', td_style: action_style }
        },
        migration_from_legacy_in_progress,
        is_ready: faxItemList.isReady(),
        is_loading: faxItemList.isLoading(),
        postRenderCell
    }
}

export default withRouter(connect(mapStateToProps)(FaxTraffic))

const filename_field_style = css`
`

const complex_filename_style = css`
display: flex;
`

const fax_queue_item_filename_style = css`
display: flex;
justify-content: space-between;
`

const fax_queue_item_filename_filename_style = css`
max-width: 100%;
`


const fax_queue_item_filename_status_style = css`
/*margin-left: 15px;*/
text-align: center;
width: 16px;
`

const filename_cell_style = css`
max-width: 1px;
width: auto;			
`

const filename_cell_inner_style = css`
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
`

const direction_cell_style = css`
vertical-align: top;
width: 20px;
`

const created_at_cell_style = css`
vertical-align: top;
width: 150px;
`

const from_cell_style = css`
vertical-align: top;
width: 15%;
`

const to_cell_style = css`
vertical-align: top;
width: 15%;
`

const status_style = css`
vertical-align: top;
width: 20px;
`

const status_inner_style = css`
text-align: center;
`

const action_style = css`
vertical-align: top;
width: 20px;
`

const action_inner_style = css`
margin-top: 3px;
`
