import { ItemList } from '../orm'

class CustomerSubscriptionList extends ItemList {
    getEntityKey() {
        return "subscription"
    }

    requestCancel({subscription_id}) {
        return this.saveObject({id: subscription_id, action: 'request_cancel'})
    }

    cancelImmediately({subscription_id}) {
        return this.saveObject({id: subscription_id, action: 'cancel_immediately'})
    }

    payWithCredits({subscription_id}) {
        return this.saveObject({id: subscription_id, action: 'pay_with_credits'})
    }

    filter_waiting_for_number_assignment({can_receive_faxes_only, can_receive_voice_only, can_receive_voice_or_fax}) {
        const that = this
        return async(dispatch, getState) => {
            const filter = {is_waiting_for_number_assignment: true}
            if ( can_receive_faxes_only ) {
                filter.can_receive_faxes = true
                filter.can_receive_voice = null
            }
            if ( can_receive_voice_only ) {
                filter.can_receive_voice = true
                filter.can_receive_faxes = null
            }
            if ( can_receive_voice_or_fax ) {
                filter.can_receive_voice_or_fax = true
                filter.can_receive_voice = null
                filter.can_receive_faxes = null
            }
            
            dispatch(that.updateListFilter(filter))
            dispatch(that.fetchListIfNeeded())
        }
    }

}


export const subscriptionList = new CustomerSubscriptionList("subscription__default")
export const subscriptionVoiceList = new CustomerSubscriptionList("subscription_voice__default")
export const customerSubscriptionList = new CustomerSubscriptionList("customer_subscription__default")
export const customerSubscriptionPayableList = new CustomerSubscriptionList("customer_subscription__payable")
export const customerSubscriptionNumberPendingList = new CustomerSubscriptionList("customer_subscription__number_pending")
export const customerSubscriptionUpgradePathList = new CustomerSubscriptionList("customer_subscription__upgrade_path")
export const customerSubscriptionUpgrade = new CustomerSubscriptionList("customer_subscription_upgrade")
export const customerSubscriptionUpgradePathListFreeFaxToPaid = new CustomerSubscriptionList("customer_subscription__upgrade_path_free_fax_to_paid")
export const customerSubscriptionUpgradePathListFreeVoiceToPaid = new CustomerSubscriptionList("customer_subscription__upgrade_path_free_voice_to_paid")
export const customerSubscriptionUpgradePathListFreeToPaidBasedOnProduct = new CustomerSubscriptionList("customer_subscription__upgrade_path_free_voice_to_paid")
export const customerSubscriptionListCanUpgrade = new CustomerSubscriptionList("customer_subscription__can_upgrade")
