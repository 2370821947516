/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { get } from 'lodash'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../emotion/theme'
import CurrencyValue from './CurrencyValue'
import { customerList } from '../actions/customer'
import { globalSettingsList } from '../actions/settings'

const CreditBadge = ({ size }) => {
  const dispatch = useDispatch()
  const customer = customerList.getCustomer()
  const credits_excluding_vat_euros = get(
    customer,
    'credits_excluding_vat_euros',
    null
  )
  const is_prepaid = get(customer, 'has_any_prepaid_subscriptions')

  useEffect(() => {
    dispatch(globalSettingsList.ensureGlobalSettingsLoaded())
  }, [])

  const sizeStyle = size === 'sm' ? small : size === 'xs' ? extra_small : null

  return (
    <>
      {is_prepaid && credits_excluding_vat_euros !== null && (
        <div
          css={[
            credits_excluding_vat_euros > 0
              ? credit_button_available_style
              : credit_button_empty_style,
            sizeStyle,
          ]}
        >
          <div css={currency_button_content_style}>
            <CurrencyValue value={credits_excluding_vat_euros} />
          </div>
        </div>
      )}
    </>
  )
}

export default CreditBadge

const credit_button_available_style = css`
  display: inline-flex;
  align-items: center;
  background-color: ${theme.colors.dark_green};
  color: #fff;
  border-radius: 20px;
  padding: 4px 8px;
`

const credit_button_empty_style = css`
  display: inline-flex;
  align-items: center;
  background-color: ${theme.colors.primary_red};
  color: #fff;
  border-color: ${theme.colors.primary_red};
  border-radius: 20px;
  padding: 4px 8px;

  &:hover {
    border-color: ${theme.colors.primary_red};
    background-color: ${theme.colors.primary_red};
  }
`
const currency_button_content_style = css`
  font-size: 14px;
  text-align: center;
`

const small = css`
  padding: 0.25rem 0.5rem;
`

const extra_small = css`
  height: 28px;
`
