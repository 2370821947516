/** @jsxImportSource @emotion/react */
import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom"
import { useDispatch } from 'react-redux'
import { jsx, css } from '@emotion/react'
import {get, head, map, size, includes, replace} from 'lodash'
import { withRouter } from 'react-router-dom'
import { CardHeader } from '../../components/layout/CardHeader'
import { Container, Row, Col } from 'react-bootstrap'
import Timestamp from '../../components/Timestamp'
import CommonTable from '../../components/CommonTable'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import FieldInfoText from '../../components/layout/FieldInfoText'
import { InlineIconBar } from '../../components/layout/InlineIconBar'
import {showSuccess, showError} from '../../actions/Error'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikInputField } from '../../components/form/InputField'
import { confirmModal } from '../../actions/ui'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { Error } from '../../components/layout/Error'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { Separator } from '../../components/layout/Separator'
import { LANGUAGES } from '../../actions/language'
import Card from '../../components/layout/Card'
import { LabelValue } from '../../components/layout/LabelValue'
import { BlueButton} from '../../components/layout/BlueButton'
import { Trans, Translation } from 'react-i18next'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { handleSubmitResult } from '../../actions/form'
import { adminResellerList } from '../actions/admin_reseller'
import { adminCustomerList } from '../actions/admin_customer'
import { adminCustomerListForReseller } from '../actions/admin_customer'
import { Button } from 'react-bootstrap'
import * as Yup from 'yup'
import {default_theme as theme} from "../../emotion/theme"

const LEFT_WIDTH = 6

export const AdminCustomerReseller = ({customer_id}) => {

    const dispatch = useDispatch()
    const history = useHistory()

    const customer = adminCustomerList.getObject(customer_id)
    const reseller_id = get(customer, "customer_as_reseller")
    const reseller = reseller_id && adminResellerList.getObject(reseller_id)
    const initial_values = reseller_id ? reseller : {}
    const customers_for_reseller = adminCustomerListForReseller.getVisibleObjects()
    const is_loading_reseller_customers = adminCustomerListForReseller.isLoading()

    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
            dispatch(adminCustomerListForReseller.updateListFilter({reseller: customer_id}))
        }
        fetchStaticObjects()
    }, [customer_id])
    
    useEffect(() => {
        async function fetchStaticObjects() {
            dispatch(adminResellerList.ensureObjectLoaded(reseller_id))
        }
        fetchStaticObjects()
    }, [reseller_id])
    
    const onSave = (values, formik_funcs) => {

        const on_ok = function(json) {
            showSuccess("Saved", "Reseller information saved")
            dispatch(adminResellerList.invalidateList())

            if ( customer_id ) {
                dispatch(adminCustomerList.invalidateObject(customer_id))
            }
            dispatch(adminResellerList.fetchListIfNeeded())
        }
        values.customer = customer_id
        if ( reseller_id ) {
            values.id = reseller_id
            return dispatch(adminResellerList.saveObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        } else {
            return dispatch(adminResellerList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
        }
    }

    const onViewResellerCustomer = (item) => {
        history.push('/admin/customer/' + item.id)
    }

    const getResellerCustomersCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'action':
                return (
                    <InlineIconBar>
                      <InlineIcon icon_name="edit" onClick={(evt) => onViewResellerCustomer(item)} />
                    </InlineIconBar>
                )
                
            default:
                return undefined
        }
    }

    const renderResellerCustomers = () => {

        const headers = {
            short_id: { name: "Acc Id" },
            display_name: { name: "Name" },
            email: { name: 'Email' },
            phone_number: { name: 'Phone' },
            created_at: { name: 'Since' },
            action: { name: '', column_size: 1 }
        }
        
        return (
            <Card variant="white_wide_padding" title="Customers for this reseller">
              <CommonTable
                is_loading={ is_loading_reseller_customers }
                empty_message={`This reseller has no customers.`}
                headers={ headers }
                items={ customers_for_reseller }
                item_list={adminCustomerListForReseller}
                getCellValue={ getResellerCustomersCellValue }
              />
            </Card>
        )
    }

    const renderResellerForm = (formik_props) => {
        return (
            <div>
              <Separator variant="h50" />
              { reseller_id && 
                  <>
                    <Row>
                      <Col md={LEFT_WIDTH}>
                        Reseller information
                      </Col>
                      <Col>
                        <FieldInfoText>
                          This is the legacy information for this reseller, it's superceded by the customer information, shown here for interest.
                        </FieldInfoText>

                        <div>Name: {reseller.name || "n/a"}</div>
                        <Separator variant="h10" />
                        
                        <div>Code: {reseller.code || "n/a"}</div>
                        <Separator variant="h10" />
                        
                        <div>Email: {reseller.reseller_email || "n/a"}</div>
                        <Separator variant="h10" />

                        <div>Username: {reseller.username || "n/a"}</div>
                        <Separator variant="h10" />

                      </Col>
                    </Row>
                  </>
              }
            </div>
        )
        
    }
    
    const renderForm = (formik_props) => {
        return (
            <div>
              <Row>
                <Col md={LEFT_WIDTH}>
                  Reseller
                </Col>
                <Col>
                  <FieldInfoText>
                    If ticked, then this customer can act as a reseller and will see extra menu items relating to reseller functionality.
                  </FieldInfoText>
                  <FormikCheckboxField label="Active as reseller" name="active" />
                </Col>
              </Row>
              { get(formik_props, ["values", "active"]) && renderResellerForm(formik_props) }
            </div>
        )
    }
    
    return (
        <>
          <Formik
            initialValues={initial_values}
            onSubmit={onSave}
            enableReinitialize={true}
          >
            {formik_props => {
                const { values } = formik_props
                return (
                    <Card variant="white_wide_padding">
                      <Form>
                        <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                        { renderForm(formik_props) }

                        <BlueButton type="submit">
                          Save
                        </BlueButton>
                        
                      </Form>
                    </Card>
                )
            }}
          </Formik>

          <Separator variant="h20" />

          { get(reseller, "active") && renderResellerCustomers() }
        </>
        
    )
    
}
